// 통합
export const XR_MAIN = '/xrbook';
export const SIGN_UP = '/xrbook/signUp';


export const LOGIN ='/xrbook/login'
export const PASSWORD_FORGET = '/xrbook/pw_forget';
export const PASSWORD_RESET = '/xrbook/pw_reset';


export const ADMIN ='/xrbook/admin';

export const ADMIN_STATISTICS ='/xrbook/admin/statisticsPage';
export const ADMIN_PROGRAMS ='/xrbook/admin/programPage';
export const ADMIN_SALES ='/xrbook/admin/salesPage';
export const ADMIN_XR_MAKING ='/xrbook/admin/xrMakingPage';
export const ADMIN_CONTENTS ='/xrbook/admin/contentsPage';
export const ADMIN_MANAGEMENT ='/xrbook/admin/managementPage';
export const ADMIN_APP_IMAGES ='/xrbook/admin/appImagesPage';
export const ADMIN_REWVIEWS ='/xrbook/admin/reviewsPage';
export const ADMIN_INQUIRIES ='/xrbook/admin/inquiriesPage';
export const ADMIN_EVENT ='/xrbook/admin/eventPage';
export const ADMIN_DIGITAL_BOOK ='/xrbook/admin/AdminDigitalbookPage';
export const ADMIN_READING ='/xrbook/admin/AdminReadingPage';
export const ADMIN_COUPON ='/xrbook/admin/CreateCouponsPage';
export const ADMIN_FACILITATOR ='/xrbook/admin/FacilitatorPage';

export const USER ='/xrbook/userPage';
export const ORDER ='/xrbook/orderPage'
export const APP_DOWNLOAD ='/xrbook/app_download'
export const APP_DOWNLOAD_TEST ='/xrbook/qrcode'


// 디지털북 기업회원
export const XRBOOK_NONAUTH ='/digitalBook/class'
export const XRBOOK_NONAUTH_LOGIN ='/digitalBook/class/home/login'
export const XRBOOK_NONAUTH_HOME ='/digitalBook/class/home'
export const XRBOOK_NONAUTH_WRITING ='/digitalBook/class/book/writing'
export const XRBOOK_NONAUTH_CHECK ='/digitalBook/class/book/check'
export const XRBOOK_NONAUTH_COVER ='/digitalBook/class/book/cover'
export const XRBOOK_NONAUTH_REVIEW ='/digitalBook/class/book/review'

// 디지털북 학교 회원
export const XRBOOK_INSTITUTEAUTH ='/digitalBook/institute'
export const XRBOOK_INSTITUTEAUTH_LOGIN ='/digitalBook/institute/User/login'

export const XRBOOK_INSTITUTEAUTH_HOME ='/digitalBook/institute/home'
export const XRBOOK_INSTITUTEAUTH_MAIN ='/digitalBook/institute/main'
export const XRBOOK_INSTITUTEAUTH_WRITING ='/digitalBook/institute/book/writing'
export const XRBOOK_INSTITUTEAUTH_CHECK ='/digitalBook/institute/book/check'
export const XRBOOK_INSTITUTEAUTH_COVER ='/digitalBook/institute/book/cover'
export const XRBOOK_INSTITUTEAUTH_REVIEW ='/digitalBook/institute/book/review'

export const XRBOOK_AUTH  ='/digitalBook/self'
export const XRBOOK_REVIEW  ='/digitalBook/review'
export const XRBOOK_COVER  ='/digitalBook/cover'
export const XRBOOK_CHECK  ='/digitalBook/check'

export const XRBOOK_CHECK_ADMINURL  ='/digitalBook/Admin'
export const XRBOOK_CHECK_ADMIN  ='/digitalBook/Admin/:bookId'

export const ORDER_DIGITALBOOK ='/digitalBook/example/:bookId'



//landing
export const PROGRAM_APPLIEDPAGE = '/xrbook/ClassroomProgram_applied'
export const ONLINE_APPLIEDPAGE = '/xrbook/OnlineProgram_applied'
export const XRMAKING_APPLIEDPAGE = '/xrbook/xrMaking_requested'
export const SALES_SUCCESSPAGE = '/xrbook/purchase_completed'
export const PW_RESETPAGE = '/xrbook/pw_reset_page'
export const EMAIL_VERIFIEDPAGE = '/account/email_verified'

//약관
export const SERVICE_CENTER ='/xrbook/customer_service';
export const GUIDE ='/xrbook/guide';
export const TERMS ='/xrbook/termsOfService';
export const POLICIES ='/xrbook/privacyPolicies';
export const RENTCONTRACT ='/xrbook/rentContract';

// 어린 왕자 전용 The Little Prince
export const TLP = '/xrbook/tlp'
export const TLP_MAIN = '/xrbook/tlp/main'
export const TLP_MAIN_INTRO = '/xrbook/tlp/main#INTRO'
export const TLP_MAIN_CLASSROOM_LEARNING = '/xrbook/tlp/main#THE_LITTLE_PRINCE'
export const TLP_MAIN_SELF_LEARNING = '/xrbook/tlp/main#LEARNING_PROGRAM'
export const TLP_MAIN_CONTACT = '/xrbook/tlp/main#CONTACT'
export const TLP_MAIN_XR_SERVICE = '/xrbook/tlp/main#XR_SERVICE'
export const TLP_MAIN_PHOTO = '/xrbook/tlp/main#GALLERY'
export const TLP_MAIN_REVIEW = '/xrbook/tlp/main#REVIEW'
export const TLP_MAIN_FAQ = '/xrbook/tlp/main#FAQ'
export const TLP_MAIN_EVENT = '/xrbook/tlp/main#EVENT'

export const TLP_XR_MAKING_SERVICE = '/xrbook/tlp/XR_MAKING_SERVICE'
export const TLP_EVENT = '/xrbook/tlp/EVENT'



//강사용 영상페이지
export const TLP_FACILITATORPAGE = '/xrbook/tlp/facilitator'


export const TLP_ANIMATIONPAGE ='/video/tlp/chapter'
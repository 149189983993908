import React, { Component, useState, useEffect } from 'react'
import { withFirebase } from '../../Firebase'
import PageGalleryItem from './PageGalleryItem'
import GalleryItem from './GalleryItem'
import $ from 'jquery'

class ChapterItem extends Component {
    state = {
        sharingPageList : [],
        isModalOpen : false,
        modalIdx:0,
        index : 0,
        type:''
    }
    componentDidMount(){
        this.getSharingPageList()
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.chapter !== this.props.chapter){
            this.getSharingPageList()
        }
    }
    getSharingPageList =()=> {
        let {chapter} = this.props
        // console.log(chapter)
        if(chapter){
        this.props.firebase.sharingPages().where('chapter','==', chapter).orderBy('createdAt', "desc").limit(30).get()
        .then(result => {
            var lastVisible = result.docs[result.docs.length-1];
            var sharingPageList = []
            result.forEach(res => {
                var listData = res.data()
                var sharingPageId = res.id
                sharingPageList.push({sharingPageId, ...listData})
            })
            this.setState({sharingPageList, lastVisible})
        })
        } else {
            this.props.firebase.ARappActivitys().orderBy('createdAt', "desc").limit(30).get()
            .then(result => {
                var lastVisible = result.docs[result.docs.length-1];
                var sharingPageList = []
                result.forEach(res => {
                    var listData = res.data()
                    var sharingPageId = res.id
                    sharingPageList.push({sharingPageId, ...listData})
                })
            //     let list = [{page:'https://firebasestorage.googleapis.com/v0/b/xr-book.appspot.com/o/tlp%2Fdigitalbook%2Fadmin%2F200806105232_%EC%96%B4%EB%A6%B0%EC%99%95%EC%9E%90_%EB%88%84%EB%81%BC.jpg?alt=media&token=ff0f9066-5c80-4002-8ba8-74c14360b23b',
            // isShowing:true, createdAt : '200911123456', mybookId :'', authUserUid : 'appAuth', authUserName:'박원정',
            //  authUserEmail:'wj.park@thehrder.com', type:'appImage', chapter: 2}]
                this.setState({sharingPageList, lastVisible})
            })
        }
    }
    getNextSharingPageList =()=> {
        let {chapter} = this.props
        let scrollPointX = window.scrollX
        let scrollPointY = window.scrollY
        let {sharingPageList, lastVisible} = this.state
        if(lastVisible !==undefined){
            if(chapter){
                this.props.firebase.sharingPages().where('chapter','==', chapter).orderBy('createdAt', "desc").startAfter(lastVisible).limit(20).get()
                .then(result => {
                    var lastVisible = result.docs[result.docs.length-1];
                    result.forEach(res => {
                        var listData = res.data()
                        var sharingPageId = res.id
                        sharingPageList.push({sharingPageId, ...listData})
                    })
                    this.setState({sharingPageList, lastVisible})
                    window.scrollTo(scrollPointX, scrollPointY)
                })
            } else {
                this.props.firebase.ARappActivitys().orderBy('createdAt', "desc").startAfter(lastVisible).limit(20).get()
                .then(result => {
                    var lastVisible = result.docs[result.docs.length-1];
                    result.forEach(res => {
                        var listData = res.data()
                        var sharingPageId = res.id
                        sharingPageList.push({sharingPageId, ...listData})
                    })
                    this.setState({sharingPageList, lastVisible})
                    window.scrollTo(scrollPointX, scrollPointY)
                })
            }
    } else {
        alert('최신 데이터 입니다.')
    }
    }
    updateTotalNum (chapNum){
        let statisticsData = `Totalchapter${chapNum}`
        this.props.firebase.tlpStatistics().update({
            [statisticsData] : this.props.firebase.fieldValue.increment(-1)
        })
      }

    deleteGalleyItem = (sharingPageId, id) => {
        let {chapter} = this.props
        let {sharingPageList} = this.state
        let tempList = sharingPageList.slice()
        let result = window.confirm('해당 이미지를 삭제 하시겠습니까?')
        if(result){
            if(chapter){
            this.props.firebase.sharingPage(sharingPageId).delete()
            .then(()=>{
                this.updateTotalNum(chapter)
                tempList.splice(id, 1)
                this.setState({
                    sharingPageList : tempList
                })
            })
            } else {
                this.props.firebase.ARappActivity(sharingPageId).delete()
            .then(()=>{
                tempList.splice(id, 1)
                this.setState({
                    sharingPageList : tempList
                })
            })
            }
        }
}
onPropsChange = (name, value) => {
    this.setState({
        [name] : value
    })
}
onUrlChange = id => {
    this.setState({
      showPhotos : id
    })
  }
  
  setSlide = index => {
    this.setState({index})
  
  }
  modalOpen = (id, status, type) => {
    if(status === true){
        $('html, body').css("overflow", "hidden");
        
    } else {
        $('html, body').css("overflow", "inherit")
    }
    this.setState({isModalOpen: status, modalIdx : id, type})
  }
    render() {
        let {sharingPageList, index, isModalOpen, modalIdx, type} = this.state
        let {totalNum, chapter} = this.props
        // console.log(this.state)
        return (
            <React.Fragment>
                {chapter ? <p>chapter{chapter} 이미지 현황 총 {totalNum}건</p>
                : <p>AR app Activity 이미지 현황 총 {totalNum}건</p>}
                <div className="appImagesGroup">
                    {sharingPageList.length>0 ? sharingPageList.map((c, id)=> {
                        let {sharingPageId, type, isShowing
                        } = c
                    
                        return(
                            <React.Fragment key={id} >
                                
                            {/* {type === 'digitalbook'? */}
                                <GalleryItem item={c} idx={id} isShowing={isShowing} chapter={chapter}
                                deleteGalleyItem={this.deleteGalleyItem} modalOpen={this.modalOpen}/>
                            {/* :
                            <img />} */}
                            </React.Fragment>

                        )
                    }): <span>공유된 이미지가 없습니다.</span>}
                
                </div>
                <button className="viewMoreListBtn fullBtn" onClick={()=>this.getNextSharingPageList()}>시간순 20개 더보기</button>
                <div>
                    {isModalOpen && <GalleryModal urlList={sharingPageList} modalIdx={modalIdx}
                    initialType={type} modalOpen={this.modalOpen}/>}
                </div>
            </React.Fragment>
        )
    }
}
export default withFirebase(ChapterItem)

const GalleryModal = props => {
    let { urlList, modalIdx, modalOpen, initialType} = props
    const [idx, setidx] = useState(modalIdx)
    const [type, settype] = useState(initialType)
    const [name, setname] = useState('')
    useEffect(() => {
      setidx(modalIdx)
      settype(urlList[modalIdx].type)
      setname(urlList[modalIdx].authUserName)
    }, [modalIdx])
    useEffect(() => {
        settype(urlList[idx].type)
        setname(urlList[idx].authUserName)
        if(urlList[idx].type !=='digitalbook'){
            $('PageGalleryItem').hide()
        } else {
            $('PageGalleryItem').show()
        }
      }, [idx])
    let listLeng = urlList.length
    return(
        <div className="galleryPopFrame adminGallPopFrame disabledDrag">
          <div className="galleryPopBg adminGallPopBg"  onClick={()=>modalOpen(0,false)}></div>
          <div className="galleryPopBox adminGallPopBox">
              {type === 'digitalbook' ?
              <React.Fragment>
                <div className="galleryPopImage adminGallPopImage ChapterPopImage">
                    <div className='PageGalleryItem preventDrag'>
                        <PageGalleryItem pageItem={urlList[idx]['page']}/>
                    </div>
                </div>
                {/* <span className="galleryPopPrev" style={idx===0 ? {color:"rgb(255,255,255, 0.2)"}: null} onClick={()=> idx >0 ? setidx(idx-1) : setidx(idx)}>&#60;</span>
                <span className="galleryPopNext" style={idx===listLeng-1 ? {color:"rgb(255,255,255, 0.2)"}: null} onClick={()=> idx < listLeng-1 ? setidx(idx+1) : setidx(idx)}>&#62;</span>
                <span className="galleryPopDel adminGallPopDel" onClick={()=>modalOpen(0,false)}>{name} ×</span> */}
              </React.Fragment>
              :
              <React.Fragment>
                <div className="galleryPopImage galleryPopImage2 adminGallPopImage arActivityPopImage" 
                style={{display:'flex', justifyContent:'center'}}
                //   style={{backgroundImage : "url("+urlList[idx].page+")"}}
                  >
                      <img src={urlList[idx].page}/>
                    {/* <div className="transparentCover"></div> */}
                  </div>
              </React.Fragment>
              }
            <span className="galleryPopPrev" style={idx===0 ? {color:"rgb(255,255,255, 0.2)"}: null} onClick={()=> idx >0 ? setidx(idx-1) : setidx(idx)}>&#60;</span>
            <span className="galleryPopNext" style={idx===listLeng-1 ? {color:"rgb(255,255,255, 0.2)"}: null} onClick={()=> idx < listLeng-1 ? setidx(idx+1) : setidx(idx)}>&#62;</span>
            <span className="galleryPopDel adminGallPopDel" onClick={()=>modalOpen(0,false)}>
                <span>{name}</span> 
                <span>×</span>
            </span>
          </div>
        </div>
    )
  }
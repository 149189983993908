import React, { Component } from 'react'
import {withFirebase} from '../../../Firebase'
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import * as ROUTES from '../../../../constants/routes'
import dayjs from 'dayjs'
import { getTimeString, getTimeDayjs } from '../../../helpers'

class RefundPayment extends Component {
    state = {
        isRefund:this.props.isRefund || '',
        detailShow:'none'
    }
    editrefundList = (itemId, refundId) => {
        let result = window.confirm('실제 환불 절차가 진행되었나요?')
        if (result){
        this.props.firebase.paidDigitalBook(itemId).set({status:20}, {merge:true})
        .then(()=>{
            this.props.firebase.tlpRefundItem(refundId).set({isRefund:true, isRefundAt: getTimeString()}, {merge:true})
            .then(()=> {
                alert('적용되었습니다.')
               this.setState({isRefund:true})
            })
        })
        .catch(err=> alert('잠시 후 다시 시도해 주세요\n'+err))
        }
    } 
    onToggle = () => {
        const {detailShow} = this.state;
        if(detailShow === 'none'){
            this.setState({
            detailShow : 'show'
        })} else if(detailShow === 'show'){
            this.setState({
            detailShow : 'none'
        })}
    }
    render() {
        let {RefundPayment} = this.props
        let {isRefund} = this.state
        const { detailShow
        } = this.state
        const { receipt_id, refundId, price, username, reason, itemId, refundAt, paidAt, phone, email, itemName,  method, method_name, bankname, cancelAccount, isRefundAt } = RefundPayment
            var isrefundAtFormatted2 = getTimeDayjs(refundAt)
            var ispaidAtFormatted2 = getTimeDayjs(paidAt)
            var isisRefundAtFormatted2 = getTimeDayjs(isRefundAt)
            let priceFormatted = price.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        return (
            <React.Fragment>
                <ul className="salesListCont salesList7Cont">
                    <li>{username}</li>
                    <li>{itemName}</li>
                    <li>{priceFormatted}</li>
                    <li>{isrefundAtFormatted2}</li>
                    <li>{isRefundAt !=='' ? isisRefundAtFormatted2 : '-'}</li>
                    <li>
                        {isRefund ? '환불 완료' : <button onClick={()=>this.editrefundList(itemId, refundId)}>환불 확인</button>}
                        <div onClick={this.onToggle}>
                        {detailShow === "none"
                        ? `상세보기`
                        : detailShow === "show"
                        ? `상세닫기`
                        : `상세정보`}
                        </div>
                    </li>
                </ul>  
                <ul className={`${detailShow} detailBox`}>
                    <li>
                        <span>연락처</span>
                        <span>{email}</span>
                        <span>{phone}</span>
                    </li>
                    <li>
                        <span>결제 유형</span>
                        <span>{method==='card'? '카드 결제' : method==='vbank'? '가상계좌' :method==='bank'? '계좌이체' : '확인 요망' }</span>
                    </li>
                    <li>
                        <span>결제 방법</span>
                        <span>{method==='card'? <a href='https://admin.bootpay.co.kr/receipt' target='_blank'>PG사 사이트 취소(에러로그확인)</a> : method==='vbank'? '현금 환불' :method==='bank'? '현금 환불' : '확인 요망' } 후 체크</span>
                    </li>
                    <li>
                        <span>결제 일자</span>
                        <span>{ispaidAtFormatted2}</span>
                    </li>
                    <li>
                        <span>환불 사유</span>
                        <span>{reason}</span>
                    </li>
                    <li>
                        <span>receipt_id</span>
                        <span>{isRefundAt !=='' ? isisRefundAtFormatted2 : receipt_id}</span>
                    </li>
                </ul>
            </React.Fragment>       
        )
    }
}
export default compose(withRouter,withFirebase)(RefundPayment)
import React, { Component } from 'react'
import {withFirebase} from '../../../Firebase'
import {Link} from 'react-router-dom'
import dayjs from 'dayjs'
import VrEventPayment from './VrEventPayment'

class VrEvent extends Component {
    state = {
        vrEventpaymentList : [],
    }
    componentDidMount(){
        this.getvrEventpaymentList()
    }
    getvrEventpaymentList =()=> {
            this.props.firebase.tlpPayments().where('anyVrEvent','==', true).orderBy('params.createdAt', "desc").limit(20).get()
            .then(result => {
                var lastVisibleVr = result.docs[result.docs.length-1];
                var vrEventpaymentList = []
                result.forEach(res => {
                    var listData = res.data()
                    var vrEventpaymentId = res.id
                    vrEventpaymentList.push({vrEventpaymentId, ...listData})
                })
                this.setState({vrEventpaymentList, lastVisibleVr})
            })
        }
        getNextvrEventpaymentList =()=> {
            let scrollPointX = window.scrollX
            let scrollPointY = window.scrollY
            let {vrEventpaymentList, lastVisibleVr} = this.state
            if(lastVisibleVr !==undefined){
            this.props.firebase.tlpPayments().where('anyVrEvent','==', true).orderBy('params.createdAt', "desc").startAfter(lastVisibleVr).limit(20).get()
            .then(result => {
                var lastVisibleVr = result.docs[result.docs.length-1];
                result.forEach(res => {
                    var listData = res.data()
                    var vrEventpaymentId = res.id
                    vrEventpaymentList.push({vrEventpaymentId, ...listData})
                })
                this.setState({vrEventpaymentList, lastVisibleVr})
                window.scrollTo(scrollPointX, scrollPointY)
            })
        } else {
                alert('최신 데이터 입니다.')
            }
        
        
    }
    render() {
        let {myBookList, vrEventpaymentList} = this.state
        let {totalNum} = this.props
        // console.log(this.state.lastVisible)
        return (
            <React.Fragment>
                <p>기기구매 결제 내역 총 {totalNum}건</p>
                <ul className="salesListCate salesList6Cate">
                    <li>결제자</li>
                    <li>연락처</li>
                    <li>이메일</li>
                    <li>수량</li>
                    <li>결제일자</li>
                    <li>비고</li>
                </ul>
                {vrEventpaymentList.length>0 ? vrEventpaymentList.map(c=> {
                    const {vrEventStatus, params, vrEventpaymentId} = c
                    let itemList = params.itemList
                    let vrEventList = itemList.filter(a=> a.vrEvent)
                    let vrEventListLeng = vrEventList.length
                return (
                    <React.Fragment key={vrEventpaymentId}>
                        <VrEventPayment vrEventpayment={c} vrEventStatus={vrEventStatus} vrEventList={vrEventList}
                        vrEventListLeng={vrEventListLeng} params={c.params}/>
                    </React.Fragment>
                    )
                })
                : <div>기기구매 현황이 없습니다.</div>}
                <button className="viewMoreListBtn" onClick={()=>this.getNextvrEventpaymentList()}>시간순 20개 더보기</button>
            </React.Fragment>
        )
    }
}
export default withFirebase(VrEvent)
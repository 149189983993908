import React, { Component } from 'react'
import './NotFound.css'
import notFound from '../../images/notFound.png';

class NotFound extends Component {
    componentDidMount(){
        try {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        } catch (error) {
          window.scrollTo(0, 0);
        };
    }
    render() {
        return (
            <div className="eventBg">
                <div className="eventFrame">
                        <div className="noEvent">
                        {/*<img className="noEventImg" src={notFound} alt="notFound" />*/}
                        <img className="notFoundImg" src={notFound} alt="notFound" />
                        <p>
                          <span>페이지를 찾을 수 없습니다.</span><br />
                          입력하신 주소가 정확한지 다시 한번 확인해 주시기 바랍니다.
                        </p>  
                      </div>                     
                </div>
            </div>
        )
    }
}
export default NotFound
import * as firebase from "firebase";

export const askForPermissioToReceiveNotifications = async () => {
    // try {
    //   const messaging = firebase.messaging();  
    //   await messaging.requestPermission();
    //   const token = await messaging.getToken();

    //   if(!localStorage.getItem("xrbook-token")){
    //     localStorage.setItem('xrbook-token', token);
    // } else {
    //       const preToken = localStorage.getItem("xrbook-token")
    //       if (token !== preToken) localStorage.setItem('xrbook-token', token)
    //       // console.log(token)
    //       // console.log(preToken)
    // }
    //   return token;
    // } catch (error) {
    //   console.error(error);
    // }
  }

  

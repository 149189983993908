import React, { Component } from 'react'
import {withFirebase} from '../../../Firebase'
import dayjs from 'dayjs'

class PaymentList extends Component {
    state = {
        isPaidOpen:false,
        paidList : [],
        username :  this.props.params !==undefined ?  this.props.params['username'] : '',
        phone :  this.props.params !==undefined ?  this.props.params['phone'] : '',

    }
    componentDidMount(){
        this.props.firebase.user(this.props.params['uid']).get()
        .then(res=>{
            let userData = res.data()
            this.setState({
                username : userData.username,
                phone : userData.number
            })
        })
    }
    getPaidList = (order_id) => {
        let {isPaidOpen} = this.state
        if(!isPaidOpen) {
            this.props.firebase.paidDigitalBooks().where('order_id', "==", order_id).get()
        .then(result => {
            var paidList = []
            result.forEach(res => {
                var listData = res.data()
                var paidListId = res.id
                paidList.push({paidListId, ...listData})
            })
            this.setState({paidList, isPaidOpen:true})
        })
        .catch(err=> alert('잠시 후 다시 시도해 주세요\n'+err))
        } else {
            this.setState({isPaidOpen:false})
        }
        
    }
    render() {
        let {isPaidOpen, paidList, username, phone } = this.state
        let {selfPaymentList} = this.props
        const {name, params, remain_price, order_id, pid, receipt_url} = selfPaymentList
        let {totalPrice, createdAt, email, couponInfo, itemList} = params
            var year = createdAt.substr(0,2)
            let yearInt = parseInt(year)
            var mon = createdAt.substr(2,2)
            let monInt = parseInt(mon)
            var date = createdAt.substr(4,2)
            let dateInt = parseInt(date)
        let today = new Date().setHours(0,0,0,0)
        let today1 = dayjs(today)
        let today2 = today1.format('YYYYMMDD')
        // sysdate.setDa( sysdate.getHours() + 9 )
        var createdAfterPeriod = dayjs(new Date(2000+yearInt, monInt-1, dateInt))
        var createdAfterPeriod2 = createdAfterPeriod.format('YYYYMMDD')
        let cancelBtn = true
        if(parseInt(today2)<=parseInt(createdAfterPeriod2)){
            cancelBtn = true
        } else {
            cancelBtn = false
        }
    let refundPrice = remain_price !==undefined ? `${(totalPrice-remain_price <0 ? totalPrice : totalPrice-remain_price).toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}원`: ''
    const userPrice = totalPrice ==='' ? 0 : totalPrice.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        return (
            <React.Fragment>
            <ul className="salesListCont salesList2Cont" key={pid}>
                <li>{username}</li>
                <li>{phone}</li>
                <li>{email}</li>
                <li onClick={()=>this.getPaidList(order_id)}>{name}</li>
                <li><a href={receipt_url} target='_blank'>{userPrice}원</a></li>
                <li>20{year}.{mon}.{date}</li>
                {/* <li>{itemList ? itemList.length : 0}</li> */}
                <li>{couponInfo? `사용` : `미사용`}</li>
                <li>{refundPrice}</li>
            </ul>
            {isPaidOpen && 
                <div>
                <div>* 결제자가 결제 시 작성한 프로그램 대상 정보</div>
                {paidList && paidList.length>0 ?
                <React.Fragment>
                    <div className="salesListDetailGroup">
                        <table>
                            <thead>
                                <tr className="salesListDetailCate">
                                    <th>수신인</th>
                                    <th>연락처</th>
                                    <th>이메일</th>
                                    <th>프로그램 명</th>
                                    <th>북코드</th>
                                    <th>인쇄 권수</th>
                                    <th>기기 체험</th>
                                    <th>상품 가격(할인)</th>
                                    <th>결제 상태</th>
                                </tr>
                            </thead>
                            <tbody>
                                {paidList.map(c => {
                                    let {paidListId, rName, rNumber, rEmail, bookcode, published, vrRent, vrEvent, price,
                                         discountAmount, status, item_name} = c
                                         
                                return (
                                <tr className="salesListDetailCont" key={paidListId}>
                                    <td>{rName}</td>
                                    <td>{rNumber}</td>
                                    <td>{rEmail}</td>
                                    <td>{item_name}</td>
                                    <td>{bookcode}</td>
                                    <td>{published > 0 ? published : '미신청'}</td>
                                    <td>{vrRent ? '기기 체험' : vrEvent? '구매' : '체험 불포함'}</td>
                                    <td>{price!==undefined ? `${price.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}` : ''}{discountAmount!==undefined ? `(${discountAmount.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')})` : ''}</td>
                                    <td>{status === 20 ? '취소 완료': status === 1 ? '결제 완료' :status === -30 ? '취소 신청' :'관리자 문의'}</td>
                                </tr>
                                )})
                                }
                            </tbody>
                        </table>
                    </div>
                </React.Fragment>
                :<div>코드를 등록한 사람이 없습니다.</div>}
                </div>}
            </React.Fragment>
        )
    }
}
export default withFirebase(PaymentList)
import React, { Component } from "react";
import ProgramApplied from "./ProgramApplied";
import "./index.css";
class ProgramListApplied extends Component {
  static defaultProps = {
    programList: [
      {
        authUserId: "",
        code: "",
        codeAt: "",
        contents: "",
        createdAt: "",
        email: "",
        groupTitle: "",
        manager: "",
        memo: "",
        number: 0,
        page: "",
        participants: "",
        price: 0,
        programInfo: "",
        progress: "",
        progressAt: "",
        selectedDays: [],
        uid: "",
      },
    ],
    oneditList: () => console.warn("oneditList not defined"),
    onGetList: () => console.warn("onGetList not defined"),
  };
  componentDidMount() {
    const { onGetList } = this.props;
    onGetList("applied");
  }
  render() {
    const { programList, oneditList, onGetList } = this.props;
    const list =
      programList.length > 0
        ? programList.map((info) => (
            <ProgramApplied
              key={info.uid}
              appliedList={info}
              onGetList={onGetList}
              oneditList={oneditList}
            />
          ))
        : "프로그램 신청 현황이 없습니다.";
    return (
      <React.Fragment>
        <p>프로그램 신청현황, 총 : {programList.length}건</p>
        <ul className="programListCate">
          <li>사용자명</li>
          <li>회사/학교명</li>
          <li>참여인원</li>
          <li>교육장소</li>
          <li>프로그램명</li>
          <li>진행 상황</li>
          <li>일자</li>
          <li>비고</li>
        </ul>
        {list}
      </React.Fragment>
    );
  }
}
export default ProgramListApplied;

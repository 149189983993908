import React, { Component } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import './PasswordForget.css'


const PasswordForgetPage = () => (
  <div className="pwForgetBg">
    <div className="pwForgetFrame">
      <h1>비밀번호 재설정</h1>
      <PasswordForgetForm />
    </div>
  </div>
);

const INITIAL_STATE = {
  email: '',
  username:'',
  error: null,
};

class PasswordForgetFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { email, username } = this.state;
    this.props.firebase.users().where('email','==', email).get()
    .then(result=> {
        result.forEach((doc) => {
            var childData = doc.data();
            if (childData.username === username){
                        this.props.firebase
                        .doPasswordReset(email)
                        .then(() => {
                          // this.setState({ ...INITIAL_STATE});
                          this.props.history.push(ROUTES.PW_RESETPAGE);
                        })
                        .catch(error => {
                          if (error.code === 'auth/expired-action-code') {
                            error.message = '유효기간이 만료된 코드입니다.';
                          } else if (error.code === 'auth/invalid-action-code') {
                            error.message = '이미 사용되었거나 잘못된 코드입니다.';
                          } else if (error.code === 'auth/user-disabled') {
                            error.message = '탈퇴 혹은 비활성화 된 계정의 코드입니다.';
                          } else if (error.code === 'auth/user-not-found') {
                            error.message = '유효한 사용자가 아닙니다.';
                          } else if (error.code === 'auth/invalid-email') {
                            error.message = '유효한 메일 주소가 아닙니다.';
                          } else if(error.code)  {
                            error.message = `잠시 후 다시 시도해주세요.`
                          }
                          this.setState({error});
                          // console.log(error)
              });
            } else {
              this.setState({error : {message : `입력한 정보를 다시 확인해주세요.`}})
            }
          }
      )
    })
    .catch(error => this.setState({ error }))
    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value.trim() });
  };

  render() {
    const { email, username, error } = this.state;

    const isInvalid = email === '' || username === '';

    return (
      <form className="inputGroup" onSubmit={this.onSubmit}>
        <input
          name="email"
          value={this.state.email}
          onChange={this.onChange}
          type="text"
          placeholder="아이디 : address@example.com"
        />
        <input
          name="username"
          value={this.state.username}
          onChange={this.onChange}
          type="text"
          placeholder="회원 가입시 입력한 이름"
        />

        {error && <p>{error.message}</p>}

        <button disabled={isInvalid} type="submit">
          비밀번호 재설정 메일 발송
        </button>

        
      </form>
    );
  }
}

const PasswordForgetLink = () => (
  <p className="pwForgetLink">
    <Link to={ROUTES.PASSWORD_FORGET}>비밀번호 재설정</Link>
  </p>
);

export default PasswordForgetPage;

const PasswordForgetForm = compose(
  withFirebase,
  withRouter
)(PasswordForgetFormBase);

export { PasswordForgetForm, PasswordForgetLink };

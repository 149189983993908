import React, { Component } from 'react'
import { withFirebase } from '../../Firebase';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { getTimeDayjs } from '../../helpers'
import './InstituteAuth.css'

class InstituteAuthProfessor extends Component {
    state = {
      userList : [],
      showList: [],
      userInfoList : [],
      userHistoryList: [],
      order:'등록순',
      show : 'info',
      btnShow : false
    }
    componentDidMount(){
      let instituteAuthReader = JSON.parse(sessionStorage.getItem('instituteAuthReader'))

        let subjectName =
        instituteAuthReader.subjectName !=='' ?
        instituteAuthReader.subjectName.indexOf('_')>-1 ?
        instituteAuthReader.subjectName.split('_') :
        [instituteAuthReader.subjectName]
        :false
        if(['P', 'A'].indexOf(instituteAuthReader.type >-1)){
          
          this.getUserList(subjectName)
        } else {
            this.props.propsChange()
        }

      
    }
    getUserList = (subjectNameList) => {
      if(subjectNameList){
      this.props.firebase.instituteAuthReaders()
      .where('type','==', 'S')
      .where('subjectName', 'in', subjectNameList)
      .get()
      .then(result => {
        var userList = []
        var userInfoList = []
        result.forEach(res => {
            var listData = res.data()
            var uid = res.id
            userInfoList.push({uid, username : listData.username, studentId:listData.studentId, subjectName:listData.subjectName})
            userList.push({uid, ...listData})
        })
        this.setState({
          userList,
          userInfoList,
          showList:userList.sort((a,b)=>a['subjectName']<b['subjectName'] ? -1 : a['subjectName']>b['subjectName'] ? 1 : 0),
          btnShow : true
        })
          
      })
    } else {
      alert('담당 과목이 등록되지 않았습니다.')
      this.props.propsChange()
    }
    }
    getUserHistory = () => { 
      let userHistoryList = this.state.userInfoList.slice()
      // if(userInfoList.length === 0)
      for(let i in userHistoryList){  
            let {uid} = userHistoryList[i]
            let userHistoryItem = []
            this.props.firebase.instituteHistory(uid).once('value').then((snapshot) => {
              var listData = snapshot.val();
              if(listData !== null){
              for (const [key, value] of Object.entries(listData)) {
                // console.log(key, value)
                for(let j in value){
                      if(value[j].type ==='play'){
                        userHistoryItem[value[j].videoName] = key
                      // userHistoryItem.push([value[j].videoName, key])
                    }
              }
              // const filteredItem = userHistoryItem.reduce((t,a) => {
              //   //최신날짜만 string
              //   if (t[a[0]]){ 
              //     if(t[a[0]].indexOf(a[1]) <0){
              //     t[a[0]] = a[1]
              //     // 날짜 전체 리스트 t[a[0]] = [...t[a[0]], a[1]]
              //   }
              //   } else { 
              //     t[a[0]] = a[1]
              //     } 
              //     userHistoryList[i] = {...userHistoryList[i], ...filteredItem}
              //     return t
              //   },{})      
              }
              userHistoryList[i] = {...userHistoryList[i], ...userHistoryItem}
              // console.log(userHistoryList[i])
            }        
        })
        .then(()=>{
            if(i*1 === userHistoryList.length-1){
                    this.setState({
                      userHistoryList : userHistoryList.sort((a,b)=>a['subjectName']<b['subjectName'] ? -1 : a['subjectName']>b['subjectName'] ? 1 : 0),
                      show : 'history'
                  })
              }
        })
        
        }
          }

    render() {
      let { userList, show, order, showList, userHistoryList, userInfoList, btnShow } = this.state
      // console.log(userHistoryList)
       return (
            <>
            {show === 'info' ? 
                <div className="userListFrame" style={{textAlign:"center"}}>
                    <div style={{display:'flex', marginBottom : '1vw'}}>
                      {btnShow && <button onClick={()=>this.getUserHistory()}>시청기록</button>}
                    </div>
                  {userList.length > 0 && (
                      <table border="1">
                        <thead>
                          <tr>
                            <th>순번</th>
                            <th>이름</th>
                            <th>학번</th>
                            <th>과목명</th>
                            <th>
                              비밀번호
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {userList.map((c, id) => {
                            return (
                              <tr key={id}>
                                <td>{id + 1}</td>
                                <td>{c.username}</td>
                                <td>{c.studentId}</td>
                                <td>{c.subjectName}</td>
                                <td>{c.userPw}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                </div>
                :
                <div className="userListFrame" style={{textAlign:"center"}}>
                  <div style={{display:'flex', marginBottom : '1vw'}}>
                  <button onClick={()=>this.setState({show : 'info'})}>계정 정보</button>
                  <span style={{lineHeight:'3vw', marginLeft:'1vw'}}>
                    (P : 프롤로그, Ch : 챕터, 360: 360도 영상, E : 에필로그, 기재된 날짜는 최신 열람 날짜입니다.)
                  </span>
                  </div>
                  
                  {userHistoryList.length > 0 && (
                      <table border="1">
                        <thead>
                          <tr>
                            <th>순번</th>
                            <th>이름</th>
                            <th>학번</th>
                            <th>과목명</th>
                            <th>P</th>
                            <th>Ch1 360</th>
                            <th>Ch2 360</th>
                            <th>Ch3 360</th>
                            <th>Ch4 360</th>
                            <th>Ch1</th>
                            <th>Ch2</th>
                            <th>Ch3</th>
                            <th>Ch4</th>
                            <th>E</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userHistoryList.map((c, id) => {
                            // console.log(c)
                            return (
                              <tr key={id}>
                                <td>{id + 1}</td>
                                <td>{c["username"]}</td>
                                <td>{c["studentId"]}</td>
                                <td>{c["subjectName"]}</td>
                                <td>{c["Prologue[성인용]"] !== undefined ? getTimeDayjs(c["Prologue[성인용]"]) : "X"}</td>
                                <td>{c["Chapter1-360"] !== undefined ? getTimeDayjs(c["Chapter1-360"]) : "X"}</td>
                                <td>{c["Chapter2-360"] !== undefined ? getTimeDayjs(c["Chapter2-360"]) : "X"}</td>
                                <td>{c["Chapter3-360"] !== undefined ? getTimeDayjs(c["Chapter3-360"]) : "X"}</td>
                                <td>{c["Chapter4-360"] !== undefined ? getTimeDayjs(c["Chapter4-360"]) : "X"}</td>
                                <td>{c["Chapter1"] !== undefined ? getTimeDayjs(c["Chapter1"]) : "X"}</td>
                                <td>{c["Chapter2"] !== undefined ? getTimeDayjs(c["Chapter2"]) : "X"}</td>
                                <td>{c["Chapter3"] !== undefined ? getTimeDayjs(c["Chapter3"]) : "X"}</td>
                                <td>{c["Chapter4"] !== undefined ? getTimeDayjs(c["Chapter4"]) : "X"}</td>
                                <td>{c["Epilogue[성인용]"] !== undefined ? getTimeDayjs(c["Epilogue[성인용]"]) : "X"}</td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    )}
                </div>
                }
            </>
        )
    }
}
export default compose(
    withRouter,
    withFirebase,
  )(InstituteAuthProfessor);

import React, { Component } from 'react'
import VideoList from './VideoList'
import {withAuthorization} from '../Session'
import * as ROLES from '../../constants/roles'
import './index.css'
import Spinner from '../Utils/Spinner'
import { getTimeString } from '../helpers'

class Facilitator extends Component {
    state = {
        
        showType:'loading'
      };
      componentDidMount(){
        let authUserId= JSON.parse(localStorage.getItem('authUser')).uid
        this.props.firebase.user(authUserId).get()
        .then(res=>{
            let result = res.data()
            let selectDays = result.selectedDays !== undefined ? result.selectedDays.sort((a,b)=> a-b) : []
            let sysdate = getTimeString()
            let showType = 'inValid'
            for(var i in selectDays){
              let day = selectDays[i]
              day *= 1
              if(day - 120000 <= sysdate && sysdate < day + 120000){
                showType = 'valid'
              }
          }
          this.setState({
            showType
          })
        })
        .catch((err)=>{
            console.log(err)
            this.setState({
                showType : 'inValid' 
            })
        })
      }
    render() {
        let {showType} = this.state
        return (
            <div className='FacilitatorPage'>
                 {showType === 'loading' ?
                <div className='FacilitatorWrapper'>
                <Spinner msg='접근 가능 날짜를 확인 중입니다.'/>
                </div>
                :showType === 'valid' ?
                <VideoList marginTop={true}/>
                : showType === 'inValid' ?
                <div className='FacilitatorWrapper'>
                    <div  className='FacilitatorHeadTitle'>
                    지정된 강의 날짜가 아닙니다.
                    </div>
                </div>
                 : <div>정보를 알아오는데 실패했습니다.</div>
                } 
                
            </div>
        )
    }
}
const condition = authUser =>
    authUser && authUser.roles[ROLES.MEMBER] !== ROLES.MEMBER;

export default withAuthorization(condition)(Facilitator)
import React, {useState, useEffect} from 'react';
import {withFirebase} from '../../Firebase'
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import * as ROUTES from '../../../constants/routes'
import BootPay from "bootpay-js"
import dayjs from 'dayjs'

let today = dayjs(new Date())
let expiredate = today.add(3,'day')
let account_expire_at = expiredate.format('YYYY-MM-DD')
let purchased_at = today.format('YYYY-MM-DD HH:mm:ss')
const RequestPay = props => {
	useEffect(() => {
		try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    };
		// console.log(props)
	  }, [])

	const {payReqData} = props
    // const [order_id, setOrder_id] = useState('')
    // const [uid, setuid] = useState(payReqData.uid)
    const couponInfo = payReqData.couponInfo
	function onClickPayment(){
		// console.log(couponInfo)
		const {totalPrice, itemList, username, email, number, addr, uid, createdAt, certificationInfo,
			imgUrl1, imgUrl2, discountPrice, anyVrRent, anyVrEvent, cart
		} = payReqData
		let vrRentStatus = anyVrRent ? 'ready' : false
		props.firebase.tlpPayments().add({reqPrice:totalPrice, reqCert : certificationInfo, imgUrl1, imgUrl2, anyVrRent, anyVrEvent, vrRentStatus})
		.then(res => {
		let order_id = res.id
		let totalprice = totalPrice
		if(totalPrice<1000){
			alert('최소 주문금액 1,000원으로 결제합니다.')
			totalprice = 1000
		}
		
		const itemListLeng = itemList.length
  
		/* 1. 가맹점 식별하기 */
  
		/* 2. 결제 데이터 정의하기 */
		const {title, target, published, period}=itemList[0]
		const data = {
			price: totalprice,
			application_id: process.env.REACT_APP_WEB_ID,
			name: `${title} ${target}대상${published>0 ? '(출판)' : ''} - ${period}일 등 총 ${itemListLeng}개 품목`,
			pg: 'kcp',
			method: '',
			show_agree_window: 1,
			items: itemList,
			user_info: {
				uid : uid,
				username: username,
				email: email,
				addr: addr,
				phone: number
			},
			order_id: order_id,
			params: {uid: uid, username: username, email: email, addr: addr, phone: number, discountPrice:discountPrice,
				createdAt: createdAt, totalPrice:totalprice, itemList:itemList, couponInfo:couponInfo, type:'SELF' },
			extra: {
				vbank_result: 1, 
				quota: '0,2,3'
			},
			account_expire_at : account_expire_at
		}
		// console.log(data)
		/* 4. 결제 창 호출하기 */

		BootPay.request(data)
		.error(function (data) {
			alert('잠시 후 다시 시도 해주세요.\n'+data.msg)
			//결제 진행시 에러가 발생하면 수행됩니다.
			// console.log('error');
			// console.log(data);

			// if(couponInfo){ 
			// 	props.firebase.promotionCode(couponId).set({isUsed:uid},{merge:true})
			// 	// console.log('canceled')
			// 	couponInfo['isUsed'] = ''
			//   }
		}).cancel(function (data) {
			//결제가 취소되면 수행됩니다.
			alert('결제가 취소되었습니다.')
			// console.log('cancel');
			// console.log(data);
			props.firebase.tlpPayment(order_id).delete()
			// if(couponInfo){
			// 	for(let i in couponInfo){
					
			// 	props.firebase.promotionCode(couponInfo[i].cid).update({
			// 		ResCount : props.firebase.fieldValue.increment(couponInfo[i].used)
			// 	})}
			// }
		}).ready(async (readyData) => {
			// 가상계좌 입금 계좌번호가 발급되면 호출되는 함수입니다.
			// console.log('ready');
			// console.log(readyData);
			for(let i in couponInfo){
				let useCoupon = await props.firebase.promotionCode(couponInfo[i].cid).update({
				ResCount : props.firebase.fieldValue.increment(-couponInfo[i].used)
			})
			}
			await props.firebase.tlpPayment(readyData.order_id).set({readyData},{merge:true})
			.then(()=> {
				// console.log(readyData)
					let { bankname, account, expiredate} = readyData
					let smsNumber = number.split('-').join("")
					let expireddate = expiredate.split(' ')[0]
					let readyContent = `https://www.xrbook.net 가상 계좌 정보: ${bankname} ${account} 기한 : ${expireddate}`
					let data = {
						type:'SMS',
						contentType:"COMM",
						subject:'XR BOOK 메세지입니다.',
						content: readyContent,
						to: smsNumber,
					}
					let result = window.confirm(`가상계좌 정보를 문자로 받으시겠습니까?`)
					if(result){
						fetch("/api/v1/sendSMS/messages", {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json; charset=utf-8'
						},
						body : JSON.stringify(data)
					})
					.then(res=> {
						if(res.status === 200){
						alert('문자 전송이 완료되었습니다.')
						props.history.push(ROUTES.SALES_SUCCESSPAGE)
						}
					})
					.catch(err=>alert(err))
				} else {
					props.history.push(ROUTES.SALES_SUCCESSPAGE)
				}
					
	})
		.catch(err=> alert('결제 도중 문제가 발생했습니다. + '+err))
		}).confirm(async function (data) {
			var enable = true;
			//쿠폰 재 확인 - 유효기간, 사용여부 등
			//결제가 실행되기 전에 수행되며, 주로 재고를 확인하는 로직이 들어갑니다.
			//주의 - 카드 수기결제일 경우 이 부분이 실행되지 않습니다.
			// console.log('confirm');
			// console.log(data);
			// console.log(couponInfo);
			if(couponInfo){
				for(let i in couponInfo){
					let useCoupon = await props.firebase.promotionCode(couponInfo[i].cid).update({
					ResCount : props.firebase.fieldValue.increment(-couponInfo[i].used)
				})
				}
				await BootPay.transactionConfirm(data);
			} else {
				await BootPay.transactionConfirm(data);
			}
			 // 재고 수량 관리 로직 혹은 다른 처리
			// if (enable) {
			// 	BootPay.transactionConfirm(data);
				
			// } else {
			// 	BootPay.removePaymentWindow();
				
			// }
		}).close(function (data) {
			// 결제창이 닫힐때 수행됩니다. (성공,실패,취소에 상관없이 모두 수행됨)
			// console.log('close');
			// console.log(data); //undefined
			// props.history.push(ROUTES.SALES_SUCCESSPAGE)
		}).done(function (checkData) {
			//결제가 정상적으로 완료되면 수행됩니다
			//비즈니스 로직을 수행하기 전에 결제 유효성 검증을 하시길 추천합니다.
			// console.log('done');
			// console.log(checkData);
			alert('결제가 완료되었습니다.')
			props.history.push(ROUTES.SALES_SUCCESSPAGE)
		});
		
		})
		.catch((err)=> {
		  alert('다시 시도해주세요.')
		  console.log(err)
		})
	}	
	function makeFeedback () {
		// const data = {
		// 	price: totalPrice,
		// 	application_id: process.env.REACT_APP_WEB_ID,
		// 	name: `${title} ${target}대상${published>0 ? '(출판)' : ''} - ${period}일 등 총 ${itemListLeng}개 품목`,
		// 	pg: 'kcp',
		// 	method: '',
		// 	show_agree_window: 1,
		// 	items: itemList,
		// 	user_info: {
		// 		uid : uid,
		// 		username: username,
		// 		email: email,
		// 		addr: addr,
		// 		phone: number
		// 	},
		// 	order_id: order_id,
		// 	params: {uid: uid, username: username, email: email, addr: addr, phone: number, discountPrice:discountPrice,
		// 		createdAt: createdAt, totalPrice:totalPrice, itemList:itemList, couponInfo:couponInfo, type:'SELF' },
		// 	extra: {
		// 		vbank_result: 1, 
		// 		quota: '0,2,3'
		// 	},
		// 	account_expire_at : account_expire_at
		// }

		let feedback = {
			"receipt_id": "5f87a5d5878a5600438602df",
			"pg_name": "KCP",
			"method": "card",
			"method_name": "0원 결제",
			"receipt_url": "",
			"private_key": "H/1yBlsDY9ev/YN8GxpPqDAq5fQg8Ie8afFjlcZRb4g=",
			"unit": "krw",
			"purchased_at": purchased_at,
			"status": 1
		  }
	}
	//버튼 활성화 , 비활성 화 클래스네임
	const active = props.isValid ? 'disable'  : 'able'
	let {onInvalid} = props
    return (
      <div>
      {props.isItemListValid ? <button className={`${active}`} onClick={onClickPayment}>결제하기</button>
	  : <button style={{backgroundColor : 'gray'}} onClick={onInvalid}>결제하기</button>}
      </div>
    );
}

export default compose(
    withRouter,
    withFirebase,
  )(RequestPay);
import React, { Component } from 'react'
import {withFirebase} from '../../../Firebase'
import { getTimeString, getTimeDayjs } from '../../../helpers'
import RefundedList from './RefundedList'
import RefundPayment from './RefundPayment'
import dayjs from 'dayjs'

class Refund extends Component {
    state = {
        refundList : [],
        refundDetailList:[],
        lastVisible:{},
        
    }
    componentDidMount(){
        this.getrefundList()
    }
    getrefundList =()=> {
        let today = getTimeString()
            this.props.firebase.tlpRefundItems().where('isRefund','==', false).orderBy('refundAt', "desc").get()
            .then(result => {
                var refundList = []
                result.forEach(res => {
                    var listData = res.data()
                    var refundId = res.id
                    refundList.push({refundId, ...listData})
                })
                this.setState({refundList})
            })
        }
        getrefundDetailList =()=> {
            let today = getTimeString()
                this.props.firebase.tlpRefundItems().where('isRefund','==', true).orderBy('refundAt', "desc").limit(20).get()
                .then(result => {
                    var lastVisible = result.docs[result.docs.length-1];
                    var refundDetailList = []
                    result.forEach(res => {
                        var listData = res.data()
                        var refundId = res.id
                        refundDetailList.push({refundId, ...listData})
                    })
                    this.setState({refundDetailList, lastVisible})
                    if(refundDetailList.length<1) alert('환불 완료된 건수가 없습니다.')
                })
                .catch(()=>{
                    alert('환불 완료된 건수가 없습니다.')
                })
            }
        getNextrefundDetailList =()=> {
            let scrollPointX = window.scrollX
            let scrollPointY = window.scrollY
            let {refundDetailList, lastVisible} = this.state
            if(lastVisible !==undefined){
            this.props.firebase.tlpRefundItems().where('isRefund','==', true).orderBy('refundAt', "desc").startAfter(lastVisible).limit(20).get()
            .then(result => {
                var lastVisible = result.docs[result.docs.length-1];
                result.forEach(res => {
                    var listData = res.data()
                    var refundId = res.id
                    refundDetailList.push({refundId, ...listData})
                })
                this.setState({refundDetailList, lastVisible})
                window.scrollTo(scrollPointX, scrollPointY)
            })
        } else  {
                alert('마지막 데이터 입니다.')
            }
    }
    
    render() {
        let {refundList, refundDetailList} = this.state
        let {totalNum} = this.props
        // console.log(refundList)
        return (
            <React.Fragment>
                <p>프로그램 환불/주문취소 총 {totalNum}건</p>
                    <ul className="salesListCate salesList7Cate">
                        <li>결제자</li>
                        {/* <li>연락처</li>
                        <li>이메일</li> */}
                        <li>신청 프로그램</li>
                        <li>환불 요청 금액</li>
                        {/* <li>결제 유형</li>
                        <li>결제 방법</li>
                        <li>결제 일자</li>
                        <li>환불 사유</li> */}
                        <li>환불 신청 일자</li>
                        <li>환불 완료 일자</li>
                        <li>환불 상태</li>
                        <li>
                        </li>
                    </ul>
                    {refundList.length>0 ? refundList.map(c=> {
                        let {isRefund} = c
                        return (
                            <div className="salesListFrame" key={c.refundId}>
                                <RefundPayment RefundPayment={c} isRefund={isRefund}/>
                            </div>
                            )
                        })
                        : <div>환불 신청 현황이 없습니다.</div>}
                        {refundDetailList && refundDetailList.map(c=> {
                         return (
                             <div className="salesListFrame" key={c.refundId}>
                                 <RefundedList refundDetailList={c}/>
                            </div>
                                )
                            })
                        }
                         {refundDetailList.length<1 ?<button className="viewMoreListBtn" onClick={()=>this.getrefundDetailList()}>환불 종료 건 20건 보기</button>
                          : <button className="viewMoreListBtn" onClick={()=>this.getNextrefundDetailList()}>환불 종료 건 20건 더보기</button>}
                        
            </React.Fragment>
        )
    }
}
export default withFirebase(Refund)
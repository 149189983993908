import React, { Component } from 'react'
import { withFirebase } from '../../../Firebase'
import * as ROUTES from '../../../../constants/routes';
import VimeoIframe from '../../../Utils/VimeoIframe'
import './intro.css'
import './introRes.css'


class VideoSection extends Component {
    state={ 
        videoUrl:'',
    }
    componentDidMount(){
        this.getIntroInfo()
    }
    getIntroInfo = () => {
        this.props.firebase.mainSection('video').get()
        .then(res => {
            const result = res.data()
            this.setState(result)
        })
        .catch(err=> {
            window.location.reload()
        })
    }
    render() {
        let { videoUrl } = this.state
        const vimeoUrl = `https://player.vimeo.com/video/432756890?autoplay=1&loop=1&autopause=0&title=0&byline=0&portrait=0`
        return (
            <div className="actVideoOutFrame">
                <div className="actVideoInFrame">
                    <iframe title='intro'
                            src={videoUrl}
                            frameBorder="0"
                            allow="autoplay; fullscreen"  allowFullScreen
                    ></iframe>
                    {/* <span className="blackCover"></span> */}
                </div>
            </div>
        )
    }
}
export default withFirebase(VideoSection )


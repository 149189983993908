import React, { Component } from 'react'
import * as PRICE from '../../constants/price'

class VrRent extends Component {
    
    render() {
        let {headShow} = this.props
        let priceFormatted = PRICE.VR_DEPOSIT.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        return (
            <div className="RentalGuideFrame">
                <h5 style={headShow ? {}:{display:'none'}}>기기관련 규정</h5>
                <ul className="rentalPolicyPopCont">
                    <li>
                        <h6>용어 정의</h6>
                        <span>
                        결제자(이하 '결제자')는 프로그램 및 옵션을 선택, 결제한 사람을 칭합니다.<br/>
                        프로그램 대상(이하 '사용자')이란 결제자가 결제 전 각 프로그램 등록 코드의 받는 이로 작성한 사람을 칭합니다.
                        </span>
                    </li>
                
                    <li>
                        <h6>취소 환불</h6>
                        <span>
                        1. XR BOOK 사이트의 온라인 러닝 프로그램을 지원하기 위한 서비스로 별도의 구매 상품이 아닌 프로그램에 포함되어 있는 서비스입니다. 
                        따라서 이미 결제한 프로그램에 포함된 기기 체험 서비스만을 별도로 취소할 수 없습니다.<br/> 
                        2. 기기체험 서비스 이용을 원치 않는 경우, '결제자'가 해당 프로그램을 결제 취소 후 기기 체험이 포함되어 있지 않은 프로그램으로 재결제해야 합니다.<br/>
                        &nbsp;&nbsp;&nbsp;<span>* 결제 취소는 결제일로부터 7일 이내 수강코드 등록 이전일 경우만 가능합니다.</span>
                        </span>
                    </li>
                    <li>
                        <h6>수령 / 교환</h6>
                        <span>
                        1. 기기 체험의 경우 결제 완료 후 '결제자' 계정 이름과 첨부한 신분증, 통장사본의 소유주 이름을 확인합니다.<br/>
                        2. 확인이 완료되면 '사용자'와 체험기간을 조정하고 '사용자'의 배송지로 선불 발송됩니다.<br/>
                        3. '사용자'는 제품 수령 후, 제품 상태 확인의 의무가 있으며, 확인 후 체험 진행하시면 됩니다.<br/>
                        &nbsp;&nbsp;&nbsp;<span>* 기기를 배송받은 경우 이상 시 수령 직후 바로 고객 센터에 연락해주셔야 교환 등의 처리가 가능합니다</span><br/>
                        &nbsp;&nbsp;&nbsp;<span>* 단, 물품 수령 당일 이후 제품의 불량 등의 사실을 통보하지 않으면, 사용자의 과실로 추정합니다.</span><br/>
                        &nbsp;&nbsp;&nbsp;<span>* 낙상, 충격과 같은 사용자 과실에 대해선, 고객에게 수리비용 100% 및 수리기간 내의 손해배상이 청구됩니다.</span>
                        </span>
                    </li>
                    <li>
                        <h6>반납 / 연체</h6>
                        <span>
                        1. '사용자'는 배송 완료된 시점부터 성실한 상품 관리 및 반납의 의무가 있습니다.<br/>
                        2. 상품 및 구성품을 모두 확인 후 배송 중 상품 및 구성품의 파손을 방지하기 위해 수령했던 상자에 잘 포장해 반납하여 주시기 바랍니다.<br/>
                        3. 체험 기간은 '사용자'의 주소로 상품 및 구성품 배송 완료 시점부터 프로그램 종료일까지로 프로그램 종료일 다음날 배송이 출발되어야 합니다.<br/>
                        4. 반납 전 상품 및 구성품의 온전한 상태, 포장된 박스 내부를 사진으로 촬영한 이미지 파일을 메일로 보내주십시오.
                        <br/><br/>
                        &nbsp;&nbsp;&nbsp;받는 이 : <a href="mailto:xrbook@thehrder.com?subject=이름-이메일-연락처">xrbook@thehrder.com</a><br/>
                        &nbsp;&nbsp;&nbsp;제목 : XR BOOK 사이트 상품 및 구성품 반납(사용자이름-이메일-연락처 꼭 기재 요망)
                        <br/><br/>
                        5. 이미지 파일을 메일로 보내지 않고 택배로 보내신 상품 및 구성품은 반납 후 제품 확인 시 고장 및 손상의 책임은 '사용자'에게 있습니다.<br/>
                        {/* 6. 기간 내 반납 절차가 진행되지 않으면 지연될 날만큼 연체료가 보증금에서 제외되고 추가연체료 발생시 '결제자'에게 부과됩니다.<br/> */}
                        {/* &nbsp;&nbsp;&nbsp;<span>* 3만원/7일(프로그램사용과 기기체험 포함입니다.)</span><br/> */}
                        6. 체험 기간 이내에 반납하더라도 남은 일수에 대한 비용은 환급되지 않습니다.<br/>
                        7. 프로그램 수강 기간을 연장하면 체험기간도 연장됩니다. 관련된 사항은 고객센터로 문의하시기 바랍니다.<br/>
                        &nbsp;&nbsp;&nbsp;<a href="tel:1599-1432"><span>* 더에이치알더 고객센터) 1599-1432</span></a>
                        </span>
                    </li>
                    <li>
                        <h6>분실 / 파손</h6>
                        <span>
                        1. 체험 물품 전체 혹은 일부가 분실 또는 파손된 경우, 비용이 '사용자'에게 우선 청구됩니다.<br/>
                        {/* &nbsp;&nbsp;&nbsp;<span>* 상황에 따라 '결제자'에게 환급될 보증금의 일부 또는 전체금액이 분실 및 파손 비용으로 충당될 수 있습니다.</span><br/> */}
                        &nbsp;&nbsp;&nbsp;<span>* 상품과 구성품(박스, 내부 고정 물품 등) 모두 해당됩니다.</span><br/>
                        2.'사용자'는 상품 및 구성품 반납 시 받을 때와 같은 상태의 기기 상태/구성을 확인해야 하고 전체 혹은 일부의 분실 또는 파손 시 반드시 더에이치알더(주)에게 알려야 합니다.   
                        </span>
                    </li>
                    {/* <li>
                        <h6>보증금</h6>
                        <span>
                        1. 체험 진행시 임대 보증금({priceFormatted})이 발생합니다.<br/>
                        2. 보증금은 계약 종료 후 반환 됩니다.<br/>
                        &nbsp;&nbsp;&nbsp;<span>* 무통장 입금/계좌이체의 경우 결제시 제출한 통장사본의 계좌로 환급/카드 결제시 부분취소</span><br/>
                        3. 물품의 훼손/구성품 분실 시 정비비가 발생할 수 있으며, 상황에 따라 비용 차감 후 보증금이 지급될 수 있습니다.<br/>
                        4. 물품의 정비비가 보증금을 초과하는 경우 추가발생비용을 '사용자' 또는 '결제자'에게 요청할 수 있습니다.
                        </span>
                    </li> */}
                    {/* <li>
                        <h6>구매</h6>
                        <span>
                            구매 기기 현황은 [마이페이지]의 [결제 내역 및 이용 현황] 메뉴에서 [나의 On-line Learning 이용 현황]의 [기기결제현황] 버튼을 클릭하여 나의 구매 완료한 기기의 배송 상태 확인할 수 있습니다. 수강 코드를 입력한 후 배송지 정보를 입력해야 기기 배송이 시작됩니다. 사용자의 배송지 입력 실수로 인한 피해 부담은 전적으로 사용자에게 있습니다. (배송비는 기기 구매 가격에 포함되어 있습니다.)
                            <br/>※배송지 입력 시 올바른 주소 정보를 입력하고 제출 전 한번 더 확인하여 배송지 오류를 방지해 주세요.
                        </span>
                    </li> */}
                </ul>
            </div>
        )
    }
}
export default VrRent
import React, { Component } from 'react'
import {withFirebase} from '../../Firebase'
import dayjs from 'dayjs'
import { getTimeString } from '../../helpers'
let today = dayjs(new Date)
let todayString = `${Number(getTimeString())-10000}`
let purchased_at = today.format("YYYY-MM-DD HH:mm:ss")

class CreatedPaidData extends Component {
  state = {
    pw : '',
    msg: '코드 생성을 위한 패스워드를 입력하세요.',
    show:false,
    digitalbookList:[],
        title: "어린 왕자, 나를 만나다.",
        target: "청소년",
        period: '',
        published: 0,
        vrRent: false,
        digitalBookID: "",
        rName: "",
        rNumber1: "",
        rNumber2: "",
        rNumber3: "",
        rEmail: "",
        isEmailValid: true,

        bookTarget:'성인',
        period:'7',
        chapter : [true, true, true, true]
  }
  componentDidMount(){
    this.getdigitalbookList()
  }
  getdigitalbookList = ()=> {
    this.props.firebase.libraries().get()
    .then(result=>{
      let digitalbookList = []
      result.forEach((doc) => {
          var digitalBookID = doc.id
          var listData= doc.data();
          digitalbookList.push({digitalBookID, ...listData})
          })
          this.setState({digitalbookList})
  })
    .catch(err=> alert(`잠시 후 다시 시도 해주세요.\n에러 : ${err}`) )
  }
  makeid=(length)=> {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }
    createCode = ()=>  {
      let {bookTarget, period, published, digitalBookID, vrRent, rName, rNumber1, rNumber2, rNumber3, rEmail, title, chapter} = this.state
        let data = {
          chapterLimit : chapter,
          bookcode: this.makeid(6),
          bookcodeAt : todayString,
          receipt_id: "admin_created",
          pg: "kcp",
          pg_name: "KCP",
          method: "vbank",
          method_name: "가상계좌",
          receipt_url: "",
          application_id: "admin_created",
          name: `${title} ${bookTarget}대상 - ${period}일 등 총 1개 품목`,
          order_id: "admin_created",
          params: {
            uid: "FxfzDEtmJDZ8kCDVMUkA7GvLfGc2",
            username: "관리자",
            email: "xrbook@thehrder.com",
            addr: "",
            phone: "010-0000-0000",
            discountPrice: 0,
            createdAt: todayString,
            totalPrice: 0,
            itemList: [
              {
              item_name: `${title} ${bookTarget}대상 - ${period}일`,
              qty: 1,
              unique: this.makeid(25),
              price: 0,
              itemPrice: 0,
              cat1: "Online",
              cat2: bookTarget,
              cat3: "self",
              programType: "Online",
              processType: "self",
              title,
              target: bookTarget,
              period: Number(period),
              published,
              vrRent,
              vrEvent: false,
              digitalBookID,
              createdAt: todayString,
              periodAmount: 0,
              printPrice: 0,
              rentPrice: 0,
              eventPrice: 0,
              originPrice: 0,
              discountAmount: 0,
              site: "tlp",
              id: 0,
              rName,
              rNumber : `${rNumber1}-${rNumber2}-${rNumber3}`,
              rEmail,
              isEmailValid: true
            }
          ],
          couponInfo: [],
          type: "SELF"
        },
        payment_data: {},
        unit: "krw",
        purchased_at,
        price: 0,
        status: 1
        }
        // console.log(data)
        fetch("/api/v1/payments/feedback", {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(data)
      })
      .then(response => { response.json()
        if(response.status === 200){
          alert('성공')
        } else {
          console.log('?')
        }
        })
      .catch(err => alert(`다시 시도해주세요. \n오류가 계속되면 관리자에게 문의해주세요.\n에러 : ${err}`))
      
      }
      onStateChange = (e) => {
        this.setState({
          [e.target.name] : e.target.value.trim()
        })
      }
      onPropsChange = (name, value) => {
        this.setState({
          [name] : value
        })
      }
      onStateAllChange = (data) => {
        this.setState(data)
      }
      arrayChange = (idx) => {
        this.setState(state=>{
          let arr = state.chapter
          arr[idx] = !arr[idx]
          return {
            chapter : arr
          }
        })
      }
    render() {
      let {pw, show, msg, rName, rEmail, rNumber1, rNumber2, rNumber3, vrRent, period, published, digitalbookList,
        digitalBookID, bookTarget, title, chapter } = this.state 
        // console.log(todayString)
         return (
            <div className='sendMsgContFrame'>
              <div className="sendMsgPopTitle">
                <h5>테스트용 코드 생성</h5> 
                <span onClick={()=>this.props.setshowSecret(false)}>X</span>
              </div>
              {show ?
              <div>
                받는이: <input type='text' name='rName' value={rName} onChange={(e)=>this.onStateChange(e)}/><br/>
                받는이메일:<input type='text' name='rEmail' value={rEmail} onChange={(e)=>this.onStateChange(e)}/><br/>
                받는이번호:<input type='text' name='rNumber1' value={rNumber1} onChange={(e)=>this.onPropsChange('rNumber1', e.target.value.replace(/[^0-9]/g,""))}/>
                -<input type='text' name='rNumber2' value={rNumber2} onChange={(e)=>this.onPropsChange('rNumber2', e.target.value.replace(/[^0-9]/g,""))}/>
                -<input type='text' name='rNumber3' value={rNumber3} onChange={(e)=>this.onPropsChange('rNumber3', e.target.value.replace(/[^0-9]/g,""))}/><br/>
                디지털북 : 
                        <select name='digitalBookID' value={`${digitalBookID}__${bookTarget}__${title}`}
                        onChange={(e)=>this.setState({ digitalBookID: e.target.value.split('__')[0], bookTarget: e.target.value.split('__')[1], title: e.target.value.split('__')[2] })} style={{width:'100%'}}>
                          <option value=''>디지털북 없음</option>
                          {digitalbookList && digitalbookList.map(c=>{
                              let { digitalBookID, bookTarget, bookTitle }  = c
                              return(
                                  <option key={digitalBookID} value={`${digitalBookID}__${bookTarget}__${bookTitle}`}>{bookTarget ==='미입력' || bookTarget === undefined ? `` : `(${bookTarget}) `}{bookTitle ==='' ? `이름 없음` : bookTitle}
                                  </option>
                              )
                          })}             
                      </select><br/>
                기기체험 <input type='checkbox' name='vrRent' value={vrRent} checked={vrRent === true} onChange={()=>this.onPropsChange('vrRent', true)}/>포함
                <input type='checkbox' name='vrRent' value={vrRent} checked={vrRent === false} onChange={()=>this.onPropsChange('vrRent', false)}/>미포함
                <br/>
                앱오픈
                <div style={{display:'flex'}}>
                  <span style={{display:'flex'}}>
                    <input type='checkbox' checked={chapter[0]} onChange={()=>this.arrayChange(0)}/>
                    <span onClick={()=>this.arrayChange(0)}>ch.1</span>
                  </span>
                  <span style={{display:'flex'}}>
                    <input type='checkbox' checked={chapter[1]} onChange={()=>this.arrayChange(1)}/>
                    <span onClick={()=>this.arrayChange(1)}>ch.2</span>
                  </span>
                  <span style={{display:'flex'}}>
                    <input type='checkbox' checked={chapter[2]} onChange={()=>this.arrayChange(2)}/>
                    <span onClick={()=>this.arrayChange(2)}>ch.3</span>
                  </span>
                  <span style={{display:'flex'}}>
                    <input type='checkbox' checked={chapter[3]} onChange={()=>this.arrayChange(3)}/>
                    <span onClick={()=>this.arrayChange(3)}>ch.4</span>
                  </span>
                </div>
                <br/>
                기간 <input type='number' min={1} max={50} name='period' value={period} onChange={(e)=>this.onStateChange(e)}/><br/>
                인쇄 <input type='published' min={1} max={5} name='published' value={published} onChange={(e)=>this.onStateChange(e)}/><br/>

                 <button onClick={()=>this.createCode()}>생성</button>
              </div>
              :<div>
                <input type='text' name='pw' value={pw} onChange={(e)=>this.onStateChange(e)}/>
                <button onClick={()=> pw ==='testtest' ? this.setState({show : true}) : this.setState({msg : '개발자 전용 기능입니다.'})}>입력</button>
                <div style={{color:'#c56e6e'}}>{msg}</div>
                </div>}
            </div>
        )
    }
}
export default withFirebase (CreatedPaidData)
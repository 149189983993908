import React from 'react';

const VimeoIframeEx = props => {
    const {vimeoUrl, vimeowidth, vimeoheight} = props
    let vimeoWidth = `${vimeowidth}` || '200px'
    let vimeoHeight = `${vimeoheight}` || '200px'
    return (
        <div style={{width:vimeoWidth, height:vimeoHeight}}>
            <iframe src={vimeoUrl}
                    frameBorder="0"
                    allow="autoplay; fullscreen"  allowFullScreen
            ></iframe>
        </div>
    );
};

export default VimeoIframeEx;
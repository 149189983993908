import React from "react";
import RBCarousel from "react-bootstrap-carousel";
import "react-bootstrap-carousel/dist/react-bootstrap-carousel.css";
import { withFirebase } from '../../../Firebase'
import './Review.css'
import './ReviewRes.css'
import $ from "jquery";
import MainSwiperReview from '../MainSwiperReview'
import { getTimeString } from "../../../helpers";
// const styles = { height: 400, width: "100%" };
// const icon_glass = <span className="fa fa-glass" />;
// const icon_music = <span className="fa fa-music" />;

class Review extends React.PureComponent {
  state={
    reviewList:[],
    lastVisible:{}
  }
  componentDidMount(){
    this.getReviewList()
}
  getReviewList =()=> {
    this.props.firebase.reviews().where('show','==',true).orderBy('createdAt', "desc").limit(30).get()
    .then(result => {
        var lastVisible = result.docs[result.docs.length-1];
        var reviewList = []
        result.forEach(res => {
            var listData = res.data()
            var reviewId = res.id
            reviewList.push({reviewId, ...listData})
        })
        this.setState({reviewList, lastVisible})
    })
}
getNextReviewList =()=> {
  let {chapter} = this.props
  let scrollPointX = window.scrollX
  let scrollPointY = window.scrollY
  let {reviewList, lastVisible} = this.state
  if(lastVisible !==undefined){
  this.props.firebase.reviews().where('show','==',true).orderBy('createdAt', "desc").startAfter(lastVisible).limit(20).get()
  .then(result => {
      var lastVisible = result.docs[result.docs.length-1];
      result.forEach(res => {
          var listData = res.data()
          var reviewId = res.id
          reviewList.push({reviewId, ...listData})
      })
      this.setState({reviewList, lastVisible})
      window.scrollTo(scrollPointX, scrollPointY)
  })
} else {
  alert('최신 데이터 입니다.')
}
}
  render() { 
    const {reviewList} = this.state
    // let reviewList = [{
    // reviewTitle:'여기에 보이는 건 껍데기에 지나지 않아. 가장 중요한 것은 눈에 보이지 않아',
    //  review:'본 프로그램은 VR을 통해 어린 왕자를 직접 만나고,<br />어린 왕자와의 대화를 통해 이루어지는 나와 우리에 대한 성찰을 기록하고,<br />그 결실을 나만의 어린 왕자 북으로 만드는 과정입니다.<br />이 책에 실린 질문들은 당신이 한 번 즈음 생각해 보았을 내용들입니다．<br />이 여정에서 하나하나 써 내려가는 나만의 글은<br />나로 하여금 시야를 넓히고 확장시켜 삶의 의미를 발견하게 해 줄 것입니다.<br />이 특별한 여정을 경험하고 느낀 생각을 공유하여,<br />보다 많은 사람들이 삶의 의미를 되찾고 행복한 삶을 살 수 있도록 소개해주세요.',
    //  createdAt: '210205123456',
    // //  getTimeString(),
    //  username:'박원정',
    //  programTitle:'어린 왕자, 나를 만나다'}]
    return (
      <div className="reviewBg" > 
        <div className="reviewBgWarp" > 
          <div className="reviewImage"></div>
          <div className="reviewOutFrame"> 
            
            <div className="reviewInFrame">
              <ul className="reviewTextBox">
                <li className="contTitleText">Review</li>
                <li className="sectMenuText">내가 만난 어린 왕자를<br/>이야기합니다.</li>
              </ul>
            
              <div className="ReviewContBox">
              <MainSwiperReview slideList={reviewList} idName='reviewSlide'/>
              </div>      
              
            </div>

          </div>
        </div>
      </div>
    );
  }
}
export default withFirebase(Review)
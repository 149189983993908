import React, {useState, useEffect} from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../../Session';
import * as ROLES from '../../../constants/roles';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import ProgramContents from './ProgramContents/index'
import SalesContents from './SalesContents/index'
import XrMakingContents from './XrMakingContents'
import BrochureUpload from './BrochureUpload'
import FileUpload from './FileUpload'
import VrCodeInput from './VrCodeInput.jsx'
import AppUrlInput from './AppUrlInput'
import './AdminContents.css'
import $ from "jquery";


const AdminContentsPage = props => {
  const [one, onOne] = useState('contents_show');
  const [two, onTwo] = useState('contents_hidden');
  const [three, onThree] = useState('contents_hidden');
  const [show, onShow] = useState('program');
  const [brochure, onbrochure] = useState('');
  const [xrbookBrochureByTheHRDer, onxrbookBrochureByTheHRDer] = useState('');
  const [theLittlePrinceByTheHRDer, ontheLittlePrinceByTheHRDer] = useState('');
  const [xrbook_test_apk, onxrbook_test_apk] = useState('https://firebasestorage.googleapis.com/v0/b/xr-book.appspot.com/o/tlp%2FtestAppApk%2Fxrbook_test_apk?alt=media&token=b265b036-3954-4947-b15d-9cca770e75f6');
  const [digitalbookList, ondigitalbookList] = useState([]);
  function First() {
    onShow('program');
    onOne('contents_show');
    onTwo('contents_hidden');
    onThree('contents_hidden');
    $(".adminContentsFrame>div>.dataTabBtnGroup div").css({"border":"1px solid #dedede", "color":"#aaaaaa"});
    $(".adminContentsFrame>div>.dataTabBtnGroup div").eq(0).css({"border-bottom":0,"color":"#000000" });    
  }
  function Second() {
    onShow('sales');
    onOne('contents_hidden');
    onTwo('contents_show');
    onThree('contents_hidden');
    $(".adminContentsFrame>div>.dataTabBtnGroup div").css({"border":"1px solid #dedede", "color":"#aaaaaa"});
    $(".adminContentsFrame>div>.dataTabBtnGroup div").eq(1).css({"border-bottom":0,"color":"#000000" });
  }
  function Third() {
    onShow('xrMaking');
    onOne('contents_hidden');
    onTwo('contents_hidden');
    onThree('contents_show');
    $(".adminContentsFrame>div>.dataTabBtnGroup div").css({"border":"1px solid #dedede", "color":"#aaaaaa"});
    $(".adminContentsFrame>div>.dataTabBtnGroup div").eq(2).css({"border-bottom":0,"color":"#000000" });
  }
  useEffect(() => {
    getUrl()
  }, [brochure, theLittlePrinceByTheHRDer, xrbookBrochureByTheHRDer])
  useEffect(() => {
    getdigitalbookList()
  }, [])
  function urlUpdate(name, url){
    const urlList = {}
    urlList[name] = url
    props.firebase.mainSection('intro').set( urlList ,{merge : true})
    .catch(err=> alert(`잠시 후 다시 시도 해주세요.\n에러 : ${err}`) )
  }
  function getUrl(){
    props.firebase.mainSection('intro').get()
    .then(res => {
      let {} = res.data()
      onbrochure(res.data().xrbookByTheHRDer)
      ontheLittlePrinceByTheHRDer(res.data().theLittlePrinceByTheHRDer)
      onxrbookBrochureByTheHRDer(res.data().xrbookBrochureByTheHRDer)
      // onxrbook_test_apk(res.data().xrbook_test_apk)
    })
    .catch(err=> alert(`잠시 후 다시 시도 해주세요.\n에러 : ${err}`) )
  }
  function getdigitalbookList(){
    props.firebase.libraries().get()
    .then(result=>{
      let digitalbookList = []
      result.forEach((doc) => {
          var bid = doc.id
          var listData= doc.data();
          digitalbookList.push({bid, ...listData})
          })
          ondigitalbookList(digitalbookList)
  })
    .catch(err=> alert(`잠시 후 다시 시도 해주세요.\n에러 : ${err}`) )
  }
  

  return (
    <div className="adminContentsFrame">
        <h3>데이터 관리</h3>
        <AppUrlInput/>
        <VrCodeInput/>
        <div>
          <h5>파일 업로드</h5>
          <div className="fileUploadGroup">
           <div>
              <p>통합 프로그램 소개서 (PDF)</p>
              <BrochureUpload onUpdate={urlUpdate} name='xrbookBrochureByTheHRDer'/>
              <CopyToClipboard text={xrbookBrochureByTheHRDer}>
                <span>URL 복사</span>
              </CopyToClipboard>
              <a href={xrbookBrochureByTheHRDer} target='_blank' download="XRBOOK소개서(통합)_더에이치알더">현재 업로드된 통합 브로셔 보기</a>
            </div>
            <div>
              <p>XR BOOK 소개서 (PDF)</p>
              <BrochureUpload onUpdate={urlUpdate} name='xrbookByTheHRDer'/>
              <a href={brochure} target='_blank' download="어린왕자소개서_더에이치알더">현재 업로드된 XR book 브로셔 보기</a>
            </div>
            <div>
              <p>어린 왕자 프로그램 소개서 (PDF)</p>
              <BrochureUpload onUpdate={urlUpdate} name='theLittlePrinceByTheHRDer'/>
              <a href={theLittlePrinceByTheHRDer} target='_blank' download="XRBOOK소개서_더에이치알더">현재 업로드된 어린 왕자 브로셔 보기</a>
            </div>
            <div>
              <p>체험용 APK</p>
              <FileUpload onUpdate={urlUpdate} name='xrbook_test_apk'/>
              <a href={xrbook_test_apk} target='_blank' download="XR_BOOK_체험용">현재 업로드된 파일 보기</a>
            </div>
          </div>
        </div>
        <div>
          <h5>프로그램 및 서비스 데이터 등록/수정/삭제</h5>
          {/* 탭 부분 */}
          <div className="dataTabBtnGroup">
            <div className={`${one}`} onClick={()=>First()}>Off-line Learning Program</div>
            <div className={`${two}`} onClick={()=>Second()}>On-line Learning Program</div>
            <div className={`${three}`} onClick={()=>Third()}>XR Making Book Service</div>
          </div>
          {/* 탭에서 선택된 내용 부분 */}
          <div className="dataTabContGroup">
            {show === 'program' ? <ProgramContents digitalbookList={digitalbookList}/> :
            show === 'sales' ? <SalesContents digitalbookList={digitalbookList} /> :
            show === 'xrMaking' ? <XrMakingContents /> : null}
          </div>
        </div>
    </div>

  )};
  
  const condition = authUser =>
  authUser && authUser.roles[ROLES.MEMBER] === ROLES.ADMIN;
  
  export default compose(
    withEmailVerification,
    withAuthorization(condition),
  )(AdminContentsPage);
import dayjs from 'dayjs'

const getTimeString = () => {

        let sysdate = new Date()
            function date_to_str(format)
                {
                    var fullyear = format.getFullYear().toString();
                    var year = fullyear.substr(2,2)
                    var month = format.getMonth() + 1;
                    if(month<10) month = '0' + month;
                    var date = format.getDate();
                    if(date<10) date = '0' + date;
                    var hour = format.getHours();
                    if(hour<10) hour = '0' + hour;
                    var min = format.getMinutes();
                    if(min<10) min = '0' + min;
                    var sec = format.getSeconds();
                    if(sec<10) sec = '0' + sec;
                    return `${year}${month}${date}${hour}${min}${sec}`;
                }
                sysdate = date_to_str(sysdate);
            return sysdate
            }
export const getTimeDayjs = (timeString) => {
        let sysdate = timeString ? timeString : getTimeString()

        var year = sysdate.substring(0,2)
        let yearInt = parseInt(year)
        var mon = sysdate.substring(2,4)
        let monInt = parseInt(mon)
        var date = sysdate.substring(4,6)
        let dateInt = parseInt(date)
        var isCreatedAtFormatted= dayjs(new Date(2000+yearInt, monInt-1, dateInt))
        var isCreatedAtFormatted2 = isCreatedAtFormatted.format('YYYY-MM-DD')
            return isCreatedAtFormatted2
            }
export const getTimeDayjsOrigin = (timeString) => {
                let sysdate = timeString ? timeString : getTimeString()
        
                var year = sysdate.substring(0,2)
                let yearInt = parseInt(year)
                var mon = sysdate.substring(2,4)
                let monInt = parseInt(mon)
                var date = sysdate.substring(4,6)
                let dateInt = parseInt(date)
                var isCreatedAtFormatted= dayjs(new Date(2000+yearInt, monInt-1, dateInt))
                var isCreatedAtFormatted2 = isCreatedAtFormatted
                    return isCreatedAtFormatted2
                    }

export const getSelectDays = (timeString) => {
    let sysdate = timeString ? timeString : getTimeString()
        
    var year = sysdate.substr(0, 2);
            year = parseInt(year);
            var mon = sysdate.substr(2, 2);
            mon = parseInt(mon);
            var date = sysdate.substr(4, 2);
            date = parseInt(date);

            var dateFormat = new Date(2000 + year, mon - 1, date, 12, 0, 0);
        return dateFormat
        }

import React, { Component, useEffect, useState } from 'react'
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../../Session';
import {getTimeDayjs, getTimeString} from '../../helpers'
import Spinner from '../../Utils/Spinner'
import './AdminInquiries.css'
import $ from 'jquery'

class AdminInquiriesPage extends Component {
    state = {
        inquiryList : [],
        showModal: false,
        modalData : {},
        Totalinquiry : 0,
        lastVisible:{}
    }
    componentDidMount(){
        this.getTotalinquiryCount()
        this.getInquiries()
    }
    componentDidUpdate(prevProps, prevState){
        if(!prevState.showModal&&this.state.showModal){
            $('html, body').css("overflow", "hidden");
        } else if(prevState.showModal&& !this.state.showModal) {
            $('html, body').css("overflow", "inherit")
        }
    }
    getTotalinquiryCount = () => {
        this.props.firebase.tlpStatistics().get()
        .then(res => {
            let result = res.data()
            this.setState(result)
        })
        .catch((err)=> console.log(err))
    }
    getInquiries = () => {
        this.props.firebase.inquiries().orderBy('sendAt', 'desc').limit(20).get()
        .then(result => {
            var inquiryList = []
            var lastVisible = result.docs[result.docs.length-1];
            result.forEach(res => {
                var listData = res.data()
                var InquiryId = res.id
                inquiryList.push({InquiryId, ...listData})
            })
            this.setState({inquiryList, lastVisible})
        })
        .catch((err)=> console.log(err))
    }
    getNextInquiries =()=> {
        let scrollPointX = window.scrollX
        let scrollPointY = window.scrollY
        let {inquiryList, lastVisible} = this.state
        if(lastVisible !==undefined){
            this.props.firebase.inquiries().orderBy('sendAt', 'desc').startAfter(lastVisible).limit(20).get()
        .then(result => {
            var lastVisible = result.docs[result.docs.length-1];
            result.forEach(res => {
                var listData = res.data()
                var InquiryId = res.id
                inquiryList.push({InquiryId, ...listData})
            })
            this.setState({inquiryList, lastVisible})
            window.scrollTo(scrollPointX, scrollPointY)
        })
      } else {
        alert('최신 데이터 입니다.')
      }
      }
    showModal = data => {
        const modalData = !this.state.showModal? data : {}
        this.setState({
            showModal : !this.state.showModal,
            modalData : modalData
        })
    }
    propsChange = (data, type, text, time) => {
        let inquiryList = this.state.inquiryList.slice()
        let index = inquiryList.indexOf(data)
        if(type ==='temp'){
            inquiryList[index].tempReply = text
            inquiryList[index].tempReplyAt = time
        } else if (type === 'send'){
            inquiryList[index].tempReply = text
            inquiryList[index].tempReplyAt = time
            inquiryList[index].reply = text
            inquiryList[index].replyAt = time
        }
        this.setState({
            inquiryList,
            showModal : false,
            // modalData : {}
        })
    }
    render() {
        const { inquiryList, modalData, showModal,Totalinquiry } = this.state
        const inquiryLeng = inquiryList.length
        return (
            <div className="adminInquiriesFrame">
                <h3>비회원 문의 관리</h3>
                <p>문의 진행 상황 : 총 {Totalinquiry}건</p>
                <div className="inquiriesBxGroup">
                    <table>
                        <thead>
                            <tr className="inquiriesCateBx">
                                <th>사용자명</th>
                                <th>문의일자</th>
                                <th>문의유형</th>
                                <th>문의내용</th>
                                {/* <th>메일</th> */}
                                <th>연락처</th>
                                <th>진행상황</th>
                                <th>일자</th>
                                <th>비고</th>
                            </tr>
                        </thead>
                        <tbody>
                            {inquiryLeng>0 ? inquiryList.map(c=> {
                                    const created = getTimeDayjs(c.sendAt)
                                    const category = c.category ==='program' ? 'Off-line Learning Program'
                                    : c.category ==='self' ? 'On-line Learning Program'
                                    : c.category ==='xrBook' ? 'Learning Program Development'
                                    : c.category ==='xrService' ? 'User Experience Kit Development'
                                    : c.category ==='introduction' ? '도입문의'
                                    : c.category ==='preview' ? '시연문의'
                                    : c.category ==='partnership' ? '제휴문의'
                                    : c.category ==='others' ? '기타문의'
                                    : '일반 문의'
                                    const status = c.replyAt === '' ? '답변 대기' : '답변 완료'
                                    const editData = getTimeDayjs(c.replyAt)
                                    const editInquiries = c.inquiries.replace(/(<br>|<br\/>|<br \/>)/g, '\n')
                                    const isAuth = c.authUserId === 'nonAuth' ? '비회원'
                                    : c.authUserId.length >10 ? '회원' : ''
                                return(
                                    <tr className="inquiriesBx" key={c.InquiryId} style={{border:'1px solid black'}}>
                                        {/* 사용자명, 문의일자, 문의유형, 문의내용, 메일, 연락처, 진행상황, 일자, 비고 */}
                                        <td><span>{c.name}</span></td>
                                        <td><span>{created}</span></td>
                                        <td><span>{c.category}</span></td>
                                        <td><span className="inquireContText"onClick={()=> this.showModal(c)}>{editInquiries}</span></td>
                                        {/* <td><span>{c.email}</span></td> */}
                                        <td><span>{c.number}</span></td>
                                        <td><span>{status}</span></td>
                                        <td><span>{c.replyAt === '' ? '' : editData}</span></td>
                                        <td><span>{isAuth}</span></td>
                                    </tr>
                                )
                            })
                            : <tr><td colSpan={9} style={{textAlign:"center"}}>문의사항이 없습니다.</td></tr>}
                        </tbody>
                    </table>
                    <button className="viewMoreListBtn" onClick={()=>this.getNextInquiries()}>시간순 20개 더보기</button>
                </div>
            {/* 모달창 */}
                {showModal && <Modal data={modalData} showModal={this.showModal} firebase={this.props.firebase} propsChange={this.propsChange} />}
            </div>
        )
    }
}

const Modal = props => {
const {data, showModal, firebase, propsChange} = props
const [replyData, setReplyData] = useState(data && data.tempReply ? data.tempReply : '')
const [changed, setChanged] = useState(replyData)
const [loading, setloading] = useState(false)
const formattedReplyData = replyData.replace(/(<br>|<br\/>|<br \/>)/g, '\n')
// const category = data.category ==='program' ? 'Off-line Learning Program'
//                 : data.category ==='self' ? 'On-line Learning Program'
//                 : data.category ==='xrBook' ? 'Learning Program Development'
//                 : data.category ==='xrService' ? 'User Experience Kit Development'
//                 : data.category ==='introduction' ? '도입문의'
//                 : data.category ==='preview' ? '시연문의'
//                 : data.category ==='partnership' ? '제휴문의'
//                 : data.category ==='others' ? '기타문의'
//                 : '일반 문의'
const createdY = parseInt(data.sendAt/10000000000)
const createdM = parseInt(data.sendAt/100000000)%100
const month = createdM <10 ? '0'+createdM.toString() : createdM
const createdD = parseInt(data.sendAt/1000000)%100
const date = createdD <10 ? '0'+createdD.toString() : createdD
const isInvalid = replyData === '' ? true 
: false
useEffect(() => {
    setReplyData(data.tempReply ? data.tempReply : '')
}, [data])
function isCancel (){
    var result = window.confirm('팝업 창을 닫으시겠습니까?')
    if(result){
        showModal()
    } 
} 
function isTempReply(){
    const tempReplyAt = getTimeString()
    firebase.inquiry(data.InquiryId).set({
        tempReplyAt,
        tempReply : replyData 
    },{merge:true})
    .then(()=>  propsChange(data, 'temp', replyData, tempReplyAt))
    .catch(err=> alert('다시 시도해 주세요. \n에러 : '+err))
}
function isReply(){
    var result = window.confirm('작성한 답변이 기재된 메일로 전송됩니다.\n전송하시겠습니까?')
    if(result){
        saveReply()
    }
}
function saveReply(){
    const replyAt = getTimeString()
    setloading(true)
    firebase.inquiry(data.InquiryId).set({
        replyAt,
        reply : replyData,
        tempReplyAt : replyAt,
        tempReply : replyData
    },{merge:true})
    .then(()=> sendEmail())
    .catch(err=> alert('다시 시도해 주세요. \n에러 : '+err))
}
function sendEmail() {
    const {name, email} = props.data;
    const dataM = { username : name, email: email, reply:replyData }
         fetch("/api/v1/replyInquiries", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(dataM)
        })
        .then(response => { response.json()
            .then((res) => {
                
              if(res.msg === 'success') {
              alert('문의 사항에 관한 회신을 전송했습니다.')
              propsChange(data, 'temp', replyData, getTimeString())
            } else {
              alert('다시 시도해주세요. \n오류가 계속되면 관리자에게 문의해주세요.')
            //   console.log(res)
            }
          })
          .catch(err => alert(`다시 시도해주세요. \n오류가 계속되면 관리자에게 문의해주세요.\n에러 : ${err}`))
          })
        .catch(err => alert(`다시 시도해주세요. \n오류가 계속되면 관리자에게 문의해주세요.\n에러 : ${err}`))
        
}
    return(
        <div className="inquireDetailsBg" key={data.InquiryId}>
            {!loading ?
            <React.Fragment>
                <div className="inquireDetailsBx">
                    <button onClick={isCancel}>×</button>
                    <div className="inqirerInfo">    
                        <div>{data.name}</div>
                        <div>{data.email}</div>
                        <div>{data.number}</div>
                        <div>{data.category}</div>
                    </div>
                    <div className="inquireContents">
                        <div>연락 가능 시간 : {data.time}</div>
                        <div>{data.inquiries && data.inquiries.split('<br />').map((c, id)=> {
                            return(
                            <span key={id}>{c}<br/></span>
                            )})}
                        </div>
                        <div>20{createdY}-{month}-{date}</div>
                    </div>
                    <textarea value={formattedReplyData} placehoder="사용자 문의에 대한 답변을 작성해 주세요" onChange={e => setReplyData(e.target.value.replace(/(?:\r\n|\r|\n)/g, '<br />'))}/>
                    <div className="btnGroup">
                        <button onClick={isCancel} >취소</button>
                        <button onClick={isTempReply} disabled={isInvalid}>임시저장</button>
                        <button onClick={isReply} disabled={isInvalid}>메일 전송</button>
                    </div>
                </div>
            </React.Fragment>
        : 
        <div className="inquireDetailsBx">
                <Spinner msg='작성한 답변을 메일로 전송 중입니다.'/>    
        </div>
        }
        </div>
)
}
const condition = authUser =>
authUser

export default compose(
withEmailVerification,
withAuthorization(condition),
)(AdminInquiriesPage);
import React, { Component } from 'react'
import {withFirebase} from '../../../Firebase'
import * as PRICE from '../../../../constants/price'
import {Link} from 'react-router-dom'
import dayjs from 'dayjs'
import {getTimeDayjs} from '../../../helpers'
import PaidRentList from './PaidRentList'

class VrRentPayment extends Component {
    state = {
        vrRentStatus : this.props.vrRentStatus,
        imgUrl:'',
        deposit : 1,
        vrRentpaidOpen: false,
        vrRentpaidList : [],
        username :  this.props.params !==undefined ?  this.props.params['username'] : '',
        phone :  this.props.params !==undefined ?  this.props.params['phone'] : '',
    }
    componentDidMount(){
        this.props.firebase.user(this.props.params['uid']).get()
        .then(res=>{
            let userData = res.data()
            this.setState({
                username : userData.username,
                phone : userData.number
            })
        })
    }
    editvrRentPaymentList = (vrRentpaymentId) => {
        let {vrRentStatus, phone} = this.state
        // console.log(vrRentpaymentId, vrRentStatus)
        this.props.firebase.tlpPayment(vrRentpaymentId).set({vrRentStatus, number:phone}, {merge:true})
        .then(()=> alert('적용되었습니다.'))
        .catch(err=> alert('잠시 후 다시 시도해 주세요\n'+err))
    } 
    returnDeposit = (mybookId) => {
        let {deposit} = this.state
        let depositFormatted = (deposit*PRICE.VR_DEPOSIT).toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        let depositPrice = parseInt(deposit)
        let first = window.confirm(`보증금 반환 처리는 1회만 가능합니다.\n대여된 모든 기기의 보증금 반환금액을 확인, 결제방법에 맞는 반환이 진행하세요.\n${depositFormatted}원을 반환 완료하셨습니까?`)
        if(first){
            // 결제취소시 통보데이터 확인
            this.props.firebase.tlpPayment(mybookId).set({returnDeposit : depositPrice}, {merge:true})
            .then(()=> alert('적용되었습니다.'))
            .catch(err=> alert('잠시 후 다시 시도해 주세요\n'+err))
        }
    }
    getPaidRentList = (order_id, codeUser) => {
        let {vrRentpaidOpen} = this.state
        if(!vrRentpaidOpen) {
            this.props.firebase.mybooks().where('order_id','==',order_id).where('vrRent','==', true).get()
        .then(result => {
            var vrRentpaidList = []
            result.forEach(res => {
                var listData = res.data()
                var vrRentpaidId = res.id
                vrRentpaidList.push({vrRentpaidId, ...listData})
            })
            this.setState({vrRentpaidList, vrRentpaidOpen:true})
        })
        .catch(err=> alert('잠시 후 다시 시도해 주세요\n'+err))
        } else {
            this.setState({vrRentpaidOpen:false})
        }
        
    }
    render() {
        let { vrRentpayment, vrRentListLeng} = this.props

        let {vrRentStatus, vrRentpaidList, vrRentpaidOpen, username, phone, deposit} = this.state
        // console.log(vrRentpayment)
        // let deposit = vrRentListLeng
        let depositPriceFormatted = PRICE.VR_DEPOSIT.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        let depositFormatted = deposit.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        // 보증금 환급 가능한 최대 액 구해서 막기, 보증금도 input에서 조정가능하도록해
        const {params, imgUrl1, imgUrl2, vrRentpaymentId, returnDeposit, order_id, codeUser, method, payment_data  } = vrRentpayment
        let {createdAt, email} = params
            var iscreatedAtFormatted2 = getTimeDayjs(createdAt)
            let returnDepositFormatted = deposit.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        return (
            <div className="salesListFrame">
                <ul className="salesListCont salesList5Cont">
                    <li>{username}</li>
                    <li>{phone}</li>
                    <li>{email}</li>
                    <li>{vrRentListLeng}</li>
                    <li>{iscreatedAtFormatted2}</li>
                    <li><a href={imgUrl1} target='_blank' download>신분증</a>, <a href={imgUrl2} target='_blank' download>통장</a></li>
                    <li>
                    <select value={vrRentStatus} onChange={(e)=>this.setState({vrRentStatus : e.target.value})}>
                        <option value='ready'>인증 확인 필요</option>
                        <option value='shipment'>대여 가능</option>
                        <option value='finish'>반납 완료</option>  
                    </select>
                    </li>
                    <li>
                        {/* <input type='text' value={depositPriceFormatted} readOnly/>&nbsp;&nbsp; x &nbsp;&nbsp; <input type='number' min = '1' value={depositFormatted} onChange={(e)=>this.setState({deposit : e.target.value.replace(/[^0-9]/g,"")})}/>
                    {returnDeposit ? <span disabled>{returnDepositFormatted}원 환급 완료</span>:<button onClick={()=>this.returnDeposit(vrRentpaymentId)}>보증금 환급처리</button>} */}
                    </li>
                    <li>
                        
                        <button onClick={()=>this.editvrRentPaymentList(vrRentpaymentId)}>적용</button>
                        <button onClick={()=>this.getPaidRentList(order_id, codeUser)}>대여리스트</button>
                    </li>
                </ul>
                {vrRentpaidOpen && 
                <div>
                {vrRentpaidList &&vrRentpaidList.length>0 ?
                <React.Fragment>
                    <div className="salesListDetailGroup">
                        <div style={{marginBottom:'1vw'}}>결제 방법 : {method==='card'? '카드 결제' : method==='vbank'? '가상계좌' :method==='bank'? '계좌이체' : '확인 요망' }
                        &nbsp; / 주문 번호 : {payment_data.receipt_id}
                        &nbsp; / 주문 일시 :  {payment_data.p_at}</div>
                        <table>
                            <thead>
                                <tr className="salesListDetailCate">
                                    <th>사용자</th>
                                    <th>연락처</th>
                                    <th>이메일</th>
                                    <th>배송지</th>
                                    <th>체험 기간</th>
                                    <th>반납일자</th>
                                    <th>체험 상태</th>
                                    <th>적용</th>
                                    <th>문자</th>
                                </tr>
                            </thead>
                            {vrRentpaidList.map(c=>{
                                return(
                                    <React.Fragment key={c.vrRentpaidId}>
                                        <PaidRentList vrRentpaidList={c}/>
                                    </React.Fragment>
                                )
                            })}
                        </table>
                    </div>
                </React.Fragment>
                :<p>코드를 등록한 사람이 없습니다.</p>}
                </div>}
            </div>
        )
    }
}
export default withFirebase(VrRentPayment)

import React, { Component } from 'react'
import VideoUpload from './VideoUpload'
import ProgressBar from 'react-bootstrap/ProgressBar'

class SlideTextItem extends Component {
    state={
        editing : false,
        category:this.props.item.category,
        show:this.props.item.show,
        text1:this.props.item.text1,
        text2:this.props.item.text2,
        item : this.props.item,
        totalBytes:0,
        bytesTransferred:0

    }
    onStatetoggle = name => {
        this.setState({
            [name] : !this.state.name
        })
    }
    onStateChange = (name, value) => {
        this.setState({
            [name] : value
        })
    }
    onSubmit = () => {
        const {category, show, text1, text2} = this.state
        const {onEdit, idx} = this.props
        const data = {category, show, text1, text2}
        this.setState({item : data, editing:false
        })
        onEdit(data, idx)
    }

    render() {
        const {category, show, text1, text2, item, editing, totalBytes, bytesTransferred} = this.state
        const {onDelete, onEdit, idx} = this.props
        const progress = isNaN(bytesTransferred/totalBytes) ? 0 : parseInt((bytesTransferred/totalBytes) * 100)
        const formattedText = text1.replace(/(<br>|<br\/>|<br \/>)/g, '\n')
        const isShow = show ? 'O' : 'X'
        return (
            <div  style={{display:'flex', flexDirection:'row'}}>
                            { !editing ?
                            <React.Fragment>
                            <div>카테고리 : {category}</div>
                            <div style={{width : '400px'}}>
                                <div>{text1 && text1.split('<br />').map((c, id)=> {
                                        return(
                                        <span key={id}>{c}<br/></span>
                                    )})}</div>
                                <div>{text2}</div>
                            </div>
                            {category==='FAQ' && <div>메인 노출 여부 : {isShow}</div>}
                            <button onClick={()=>this.onStatetoggle('editing')}>수정</button>
                            </React.Fragment>
                            :
                            <React.Fragment>
                            <select name='category' value={category} onChange={e=>this.onStateChange('category', e.target.value)}>
                                <option value=''>카테고리</option>
                                <option value='배송'>배송</option>
                                <option value='반품/교환'>반품/교환</option>
                                <option value='취소'>취소</option>
                                <option value='FAQ'>FAQ</option>
                            </select>
                            <div style={{width : '400px'}}>
                            <textarea name='text1' value={formattedText} 
                                onChange={e=> this.setState({text1: e.target.value.replace(/(?:\r\n|\r|\n)/g, '<br />')}) }/>
                            <input type = 'text' name='text2' value={text2} onChange={e=> this.onStateChange('text2', e.target.value )}/>
                            </div>
                            {category==='FAQ' &&
                                <input type='checkbox' name='show' value={show} onChange={()=>this.onStatetoggle('show')}/>
                            }
                            <button onClick={()=>this.onSubmit()}>저장</button>
                            </React.Fragment>
                            }
                            <button onClick={()=> onDelete(item)}>X</button>
            </div>
        )
    }
}
export default SlideTextItem
import React, { Component } from 'react';
import * as ROUTES from '../../constants/routes';
import {Link} from 'react-router-dom'
import ARappImg from '../images/ARappImg.png'
import VRappImg from '../images/VRappImg.png'
import android from '../images/android.png'
import ios from '../images/ios.png'
import './AppDownloadPage.css'
import {withFirebase} from '../Firebase'

class AppDownloadPage extends Component {
    state = {
        ar_android: '', ar_ios: '', vr_android: '', vr_ios: '',
    }
    componentDidMount(){
        this.getAppUrl()
    }
    getAppUrl =()=> {
        this.props.firebase.appUrl().get()
        .then(result=>{
                var listData= result.data();
                this.setState(listData)
            
        })
  }
    render() {
        let {ar_android, ar_ios, vr_android, vr_ios} = this.state
        return (
            <div>
                <div className="downloadheight">
                    <h3>
                        가지고 계신 핸드폰에 따라<br/>
                        아이콘 우측 버튼을 눌러 다운 받아주세요
                    </h3>
                    <div className="downloadAR">
                        <h4>1. AR 앱 </h4>
                        <div className="container">
                            <div className="downloadleft">
                                <img src={ARappImg} alt='downloadimg'/>
                            </div>
                            <div className="downloadright">
                                <p> 안드로이드 사용자 </p>
                                <a href={ar_android}><img src={android} alt='downloadimg'/></a>
                                <p>아이폰 사용자 </p>
                                <a href={ar_ios}><img src={ios} alt='downloadimg'/></a>
                            </div> 
                        </div>
                        <Link className="downloadmethod onlymobile" to={{pathname: `${ROUTES.GUIDE}`,state : { tab : "Manual"}}} >AR 앱 사용 방법</Link>   
                    </div>
                    <div className="containerline"></div>
                    <div className="downloadVR">
                        <h4>2. VR 앱(카드보드 용)</h4>
                        <div className="container">
                            <div className="downloadleft">
                                <img src={VRappImg} alt='downloadimg'/>
                            </div>
                            <div className="downloadright">
                                <p> 안드로이드 사용자 </p>
                                <a href={vr_android}><img src={android} alt='downloadimg'/></a>
                                <p>아이폰 사용자 </p>
                                <a href={vr_ios} onClick={()=>alert('ios는 준비중입니다.')}><img src={ios} alt='downloadimg'/></a>
                            </div> 
                        </div>
                        <Link className="downloadmethod onlymobile" to={{pathname: `${ROUTES.GUIDE}`,state : { tab : "Manual"}}} >VR 기기/앱 사용 방법</Link>    
                    </div>
                </div>
                <Link className="downloadmethod pc" to={{pathname: `${ROUTES.GUIDE}`,state : { tab : "Manual"}}} >VR 기기 / AR App 사용 방법</Link>
            </div>
        );
    }
}

export default withFirebase(AppDownloadPage);
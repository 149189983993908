import React, { Component } from 'react'
import {withFirebase} from '../Firebase'
import Spinner from '../Utils/Spinner'
import {isEmail, getTimeString} from '../helpers'

class SelfPaymentReceiver extends Component {
    state = {
        rEmail: this.props.rEmail,
    }
    componentDidMount(){
        if(isEmail(this.props.rEmail)){
            this.setState({isEmailValid : true})
        }else if(!isEmail(this.props.rEmail)) {
            this.setState({isEmailValid : false})
        }
    }
    editEmail = (e) => {
        let value = e.target.value
        if(isEmail(value)){
            this.setState({
                rEmail : value,
                isEmailValid : true
            })
        }else if(!isEmail(value)) {
            this.setState({
                rEmail : value,
                isEmailValid : false
            })
        }
    }
    sendEmail = () => {
        this.props.onUpdate('loading', true)
        let {rEmail} = this.state
        let {username, email, rName, rNumber, item_name, bookcode, bookcodeAt,published, vrRent, period,
         itemId, status
        } = this.props.selfPaymentItem
        // console.log(itemId)
        let data = {username, email, rName, rEmail, rNumber, item_name, bookcode, bookcodeAt,
            published, vrRent, period}
        this.props.firebase.paidDigitalBook(itemId).set({rEmail, codeMailAt : getTimeString()},{merge:true})
        .then(()=>{
            fetch("/api/v1/sendCode", {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json; charset=utf-8'
                },
                body : JSON.stringify(data)
            }).then(response => { response.json()
            .then((res) => {
                if(res.msg === 'success') {
                    alert('입력하신 메일로 코드 재전송 메일을 보냈습니다.')
              } else {
                alert('다시 시도해주세요.\n'+res.msg)
              }
              window.location.reload()
            })
            })  
            .catch(err=>console.log(err))
        })
            
    }
    render() {
        let {selfPaymentItem} = this.props
        let {rEmail, isEmailValid} = this.state
        let {rName, rNumber, codeStatus, status} = selfPaymentItem
        return (
            <React.Fragment>
                <ul  className="payHistoryFrame">
                    <li>{rName}</li>
                    <li>{rNumber}</li>
                    <li>{codeStatus ? `${rEmail}` : 
                    <input type='text' value={rEmail} onChange={(e)=>this.editEmail(e)}
                    className={
                        rEmail !==''?
                        isEmailValid ===undefined ? `empty` :
                        !isEmailValid  && isEmailValid !==undefined ? `texting`: `finishing`
                    : null }
                    />
                    }</li>
                    <li>{codeStatus ?<button disabled>코드 등록 완료</button>
                    : status === 1 ?<button onClick={()=>this.sendEmail()} disabled={!isEmailValid}>이메일 수정&재전송</button>
                :status === 20 ? <button disabled>취소된 결제</button> : <button disabled>관리자 문의</button>}</li>
                </ul>
            </React.Fragment>
        )
    }
}
export default withFirebase(SelfPaymentReceiver)
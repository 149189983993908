import React, { Component } from 'react'
import XrMakingApplied from './XrMakingApplied'
class XrMakingListApplied extends Component {
    static defaultProps = {
        programList: [
          {
            authUserId: "",
            contents: "",
            createdAt: "",
            email: "",
            groupTitle: "",
            manager: "",
            memo: "",
            number: 0,
            page: "",
            progress: "",
            progressAt: "",
            uid: ""}
        ],
        oneditList: () => console.warn('oneditList not defined'),
        onGetList: () => console.warn('onGetList not defined'),
      }
    componentDidMount (){
        const {onGetList} = this.props
        onGetList('applied')
    }
    render() {
        const {xrMakingList, oneditList, onGetList} = this.props
        const list = xrMakingList.length >0 ? 
        xrMakingList.map(
            info =>
              <XrMakingApplied
                key={info.uid}
                appliedList={info}
                onGetList={onGetList}
                oneditList={oneditList}
              />
          ) :
          'XR BOOK 제작 신청 현황이 없습니다.'
        return (
            <div>
            <h3>XR BOOK 제작 신청 현황 총 : {xrMakingList.length}건</h3>
            {list}
            </div>  
        );
    }
}
export default XrMakingListApplied
import React, { Component } from 'react'
import { Switch, Route, Link, Redirect } from 'react-router-dom'
import {withFirebase} from '../../Firebase'
import * as ROUTES from '../../../constants/routes'
import TLPNavigationInstitute from '../../TLP/TLPNavigation/TLPNavigationInstitute'
import TLPNavigationInstituteNon from '../../TLP/TLPNavigation/TLPNavigationInstituteNon'
import InstituteAuthLogin from './InstituteAuthLogin'
import InstituteAuthMain from './InstituteAuthMain'
import NonAuthMain from './InstituteAuthMain'
import InstituteAuthReader from './InstituteAuthReader'
import InstituteUserCover from './InstituteUserCover'
import InstituteUserReview from './InstituteUserReview'
import InstituteUserCheck from './Check/InstituteUserCheck'

import NotFound from '../../TLP/NotFound'
import './InstituteAuth.css'
import logo from '../images/XRBOOKLogo.png'

class NonAuth extends Component {
    state = {
    }
    render() {
        return (
            <div className="pageContainer">
            <Switch>
                <Route exact path={ROUTES.XRBOOK_INSTITUTEAUTH} render ={()=>(<Redirect to={ROUTES.XRBOOK_INSTITUTEAUTH_LOGIN}/>)}/>
                <Route
                path="/digitalBook/Institute/User/(.+)"
                render ={()=>(
                <div>
                    <TLPNavigationInstituteNon/>
                    <Switch>
                        <Route exact path={ROUTES.XRBOOK_INSTITUTEAUTH_LOGIN} component={InstituteAuthLogin} />
                        <Route path ='*' component={NotFound} />
                    </Switch>
                </div>
                )}/>
                <Route
                path="/digitalBook/Institute/(.+)"
                render ={()=>(
                <div>
                    <TLPNavigationInstitute/>
                    <Switch>
                        {/* <Route exact path={ROUTES.XRBOOK_INSTITUTEAUTH_LOGIN} component={InstituteAuthLogin} /> */}
                        <Route path={ROUTES.XRBOOK_INSTITUTEAUTH_HOME} component={InstituteAuthLogin} />
                        <Route path={ROUTES.XRBOOK_INSTITUTEAUTH_MAIN} component={InstituteAuthMain} />
                        <Route path ='*' component={NotFound} />
                    </Switch>
                </div>
                )}/>
                {/* <Route
                    path="/digitalBook/Institute/book/(.+)"
                    render ={()=>(
                <Switch>
                    <Route exact path={ROUTES.XRBOOK_INSTITUTEAUTH_WRITING} component={InstituteAuthReader} />
                    <Route path={ROUTES.XRBOOK_INSTITUTEAUTH_COVER} component={InstituteUserCover} />
                    <Route path={ROUTES.XRBOOK_INSTITUTEAUTH_REVIEW} component={InstituteUserReview} />
                    <Route path={ROUTES.XRBOOK_INSTITUTEAUTH_CHECK} component={InstituteUserCheck} />
                    <Route path ='*' component={NotFound} />
                </Switch>
                )}/> */}
                <Route path ='*' component={NotFound} />
            </Switch>
            </div>
        )
    }
}
export default withFirebase(NonAuth)

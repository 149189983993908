import React, { Component } from "react";
import {getTimeDayjs} from '../../../../helpers'
import "./index.css";

export default class ProgramListApplied extends Component {
  state = {
    appliedList: this.props.appliedList,
    detailShow: "none",
  };
  onListStatechange = (e, list) => {
    const targetName = e.target.name;
    const targetValue = e.target.value;
    if (targetName === "memo") {
      list[targetName] = targetValue.replace(/(?:\r\n|\r|\n)/g, "<br />");
    } else {
      list[targetName] = targetValue;
    }
    this.setState({
      appliedList: list,
    });
  };
  onStateChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  editProgramInfo = (uid) => {
    const { appliedList } = this.state;
    const { oneditList } = this.props;
    oneditList(uid, appliedList, "applied");
  };
  onToggle = () => {
    const { detailShow } = this.state;
    if (detailShow === "none") {
      this.setState({
        detailShow: "show",
      });
    } else if (detailShow === "show") {
      this.setState({
        detailShow: "none",
      });
    }
  };
  render() {
    const { appliedList, detailShow } = this.state;
    // console.log(appliedList)
    const {
      title,
      progressAt,
      memo,
      contents,
      manager,
      groupTitle,
      participants,
      progress,
      email,
      number,
      uid,
      target,
      location,
      vrRent,
      tabletRent,
      book,
      type,
    } = appliedList;
    const programTitle = `${title} : ${target}대상`;
    const isProgressAt = getTimeDayjs(progressAt)
    const enteredMemo = memo.replace(/<br\s*[\/]?>/gi, `\n`);
    const vrRental = vrRent ? "오큘러스 고" : "";
    const tabletRental = tabletRent ? (vrRent ? " / 태블릿" : "태블릿") : "";
    const rental = vrRental === "" && tabletRental === "" ? "안함" : "";
    return (
      <div className="programListFrame">
        <ul className="programListCont">
          <li>{manager}</li>
          <li>{groupTitle}</li>
          <li>{participants}</li>
          <li>{location}</li>
          <li>{programTitle}</li>
          <li>
            <select
              name="progress"
              value={progress}
              onChange={(e) => this.onListStatechange(e, appliedList)}
            >
              <option value="applied">신청 완료</option>
              <option value="consulting">담당자 배정</option>
              <option value="finished">교육 종료</option>
              <option value="canceled">취소</option>
            </select>
          </li>
          <li>
            {isProgressAt}
          </li>
          <li>
            <div onClick={this.onToggle}>
              {detailShow === "none"
                ? `상세보기`
                : detailShow === "show"
                ? `상세닫기`
                : `상세정보`}
            </div>
            <button onClick={() => this.editProgramInfo(uid)}>적용</button>
          </li>
        </ul>

        <ul className={`${detailShow} detailBox`}>
          <li>
            <span>연락처</span>
            <span>{email}</span>
            <span>{number}</span>
          </li>
          <li>
            <span>신청 내용</span>
            <span>교재({book})</span>
            <span>
              기기대여({vrRental} {tabletRental} {rental} )
            </span>
            <span>요청사항({type})</span>
          </li>
          <li className="ReguireText">
            {contents &&
              contents.split("<br />").map((c, id) => {
                return (
                  <span key={id}>
                    {c}
                  </span>
                );
              })}
          </li>
          <li className="manegeText">
            <span>담당자/상세내용</span>
            <textarea
              name="memo"
              value={enteredMemo}
              onChange={(e) => this.onListStatechange(e, appliedList)}
            />
          </li>
        </ul>
      </div>
    );
  }
}

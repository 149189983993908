import React, { Component } from 'react'
import {withFirebase} from '../../../Firebase'
import * as ROUTES from '../../../../constants/routes'
import {Link} from 'react-router-dom'
import dayjs from 'dayjs'
import {getTimeDayjs} from '../../../helpers'
import SendSMSModal from '../../SendSMSModal'

class Published extends Component {
    state = {
        publishedStatus : this.props.publishedStatus,
        detailShow:'none',
        phone : '',
        isModalOpen : false,
        receiver: '',
        authBookid:'',
        codeUser : this.props.codeUser !==undefined ?  this.props.codeUser : '',
        number: this.props.myBookList !==undefined ?  this.props.myBookList.number : '',
    }
    componentDidMount(){
        this.props.firebase.user(this.props.codeUser).get()
        .then(res=>{
            let userData = res.data()
            this.setState({
                number : userData.number
            })
        })
    }
    editMybookList = (mybookId) => {
        let {publishedStatus, number} = this.state
        // console.log(mybookId, publishedStatus)
        this.props.firebase.mybook(mybookId).set({publishedStatus, number}, {merge:true})
        .then(()=> alert('적용되었습니다.'))
        .catch(err=> alert('잠시 후 다시 시도해 주세요\n'+err))
    } 
    onToggle = () => {
        const {detailShow} = this.state;
        if(detailShow === 'none'){
            this.setState({
            detailShow : 'show'
        })} else if(detailShow === 'show'){
            this.setState({
            detailShow : 'none'
        })}
    }
    onPropsChange = (type, phone, receiver, authBookid) => {
        // console.log(type, phone, receiver, authBookid)
        if(type && phone !== undefined){
            this.setState({
                phone,
                isModalOpen : true,
                receiver: receiver,
                authBookid
        })
        } else if(!type && phone !== undefined) {
            this.setState({
                phone : '',
                isModalOpen : false,
                receiver: '',
                authBookid:''
        })
    } else if( phone === undefined) {
        alert('해당 유저가 번호를 입력하지 않았거나 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.\n오류가 계속되면 개발자에게 문의하세요.')
        this.setState({
            phone : '',
            isModalOpen : false,
            receiver: '',
            authBookid:''
    })
    }
    }
    render() {
        let { myBook} = this.props
        const { detailShow, publishedStatus, isModalOpen, phone, receiver, authBookid , number
        } = this.state
        const {username, rName, rNumber, email, rEmail, item_name, published, bookcode,
            publishedCover, bookData, isFinishedAt, userAddress, mybookId, codeUserName } = myBook
            var isFinishedAtFormatted2 = getTimeDayjs(isFinishedAt)
        return (
            <div className="salesListFrame">
                <ul  className="salesListCont salesList4Cont" key={mybookId}>
                    <li>
                        <span>{username}</span>
                        <span>{username !==rName ? `(${rName})` : null}</span>
                    </li>
                    <li>
                    {/* <span><a href={`https://www.xrbook.net/digitalBook/admin/${mybookId}`} target='_blank'>{item_name}</a></span> */}
                        <Link to={{pathname: `${ROUTES.XRBOOK_CHECK_ADMINURL}/${mybookId}`, 
                        // state: {
                        //     bookData : bookData, bookTitle : bookData['bookTitle'], title : `${item_name} - 저자 : ${username}${username !==rName ? `(${rName})` : null}`
                        // }
                        }} > 
                        {/* `http://localhost:3000/digitalBook/admin/${mybookId}` */}
                        {item_name}</Link>
                    </li>
                    <li>{published}</li>
                    <li>{!publishedCover ? '미 선택' :'선택 완료'}</li>
                    <li>
                        <div className="sendMsgBtn" 
                            onClick={()=>this.onPropsChange(true, number, `${codeUserName}${codeUserName !==rName ? `(${rName})` : null}`, mybookId)}
                            >문자 전송
                        </div>
                    </li>
                    <li>
                        <select value={publishedStatus} onChange={(e)=>this.setState({publishedStatus : e.target.value})}>
                            <option value='ready'>인쇄 준비 중</option>
                            <option value='noCover'>커버 요청</option>
                            <option value='printing'>인쇄 중</option>
                            <option value='shipment'>배송 중</option>
                            <option value='finish'>배송 완료</option>  
                        </select>
                        <button onClick={()=>this.editMybookList(mybookId)}>적용</button>
                        <div onClick={this.onToggle}>
                        {detailShow === "none"
                        ? `상세보기`
                        : detailShow === "show"
                        ? `상세닫기`
                        : `상세정보`}
                        </div>
                    </li>
                </ul>
                <ul className={`${detailShow} detailBox`}>
                    <li>
                        <span>연락처</span>
                        <span>{number}{number !==rNumber ? `(${rNumber})` : null}</span>
                        <span>{email}{email !==rEmail ? `(${rEmail})` : null}</span>
                    </li>
                    <li>
                        <span>프로그램 완료</span>
                        <span>{isFinishedAtFormatted2}</span>
                    </li>
                    <li>
                        <span>북코드</span>
                        <span>{bookcode}</span>
                    </li>
                    <li>
                        <span>배송지</span>
                        <span>{userAddress}</span>
                    </li>
                </ul>
                {isModalOpen && <SendSMSModal number = {phone} onUpdate = {this.onPropsChange} receiver={receiver} mybookId={authBookid}/>}
            </div>
        )
    }
}
export default withFirebase(Published)
import React, { Component } from 'react'
import {withFirebase} from '../../Firebase'
import { getTimeDayjs } from '../../helpers'
import EventApplicant from './EventApplicant'
class EventApplicantList extends Component {
    state = {
        applicantList : []
    }
    componentDidMount(){
        this.getApplicantList()
    }
    getApplicantList = () => {
        this.props.firebase.tlpEvents().onSnapshot(
            {includeMetadataChanges:true},
            result => {
                let applicantList = []
                result.forEach((doc) => {
                    var uid = doc.id
                    var listData= doc.data()                  
                    applicantList.push({uid, ...listData})
                })
                applicantList = applicantList.sort((a,b) => b.createdAt -a.createdAt)
                this.setState({applicantList})
              }
        )
        // .catch(()=> window.location.reload())
    }
    render() {
        let {applicantList} = this.state
        // console.log(this.state)
        return (
            <div className="eventApplicantListTable">
                <table border='1'>
                    <thead>
                        <tr>
                            <th></th>
                            <th>구분</th>
                            <th>이름</th>
                            <th>소속/직책</th>
                            <th>전화번호</th>
                            {/* <th>신청경로</th> */}
                            <th>주소</th>
                            <th>신청날짜</th>
                            <th>대여/반납 예정 날짜</th>
                            <th>대여/반납 상태</th>
                            <th>문자 발송</th>
                        </tr>
                    </thead>
                    <tbody>
                    {applicantList.length>0 && applicantList.map((c, id)=> {
                        return(
                            <tr key={id}>
                                <EventApplicant applicantList={c} idx={id}/>
                            </tr>
                        )
                    })}
                    </tbody>
                </table>
            </div>
        )
    }
}

export default withFirebase(EventApplicantList)
import React, {Component} from 'react';
import * as ROUTES from '../../constants/routes';
import { withAuthorization } from '../Session';
import './Landing.css'
import './LandingResp.css'

class PasswordResetPage extends Component {
  componentDidUpdate() {
      try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    };
      const authUser = localStorage.getItem('authUser')
    if(authUser) this.props.history.push(ROUTES.TLP_MAIN)
    }
  render() {
  return(
    <div className="landingBg" >
      <ul className="landingFrame landingFramePC">
        <li className="pwResetTitle">비밀번호 재설정을 위한 메일이 전송되었습니다.</li>
        <li className="landingTxt">최대 3~5분이 소요될 수 있으며 메일이 도착하지 않았다면 스팸 편지함을 확인해 주세요.</li>
        <li className="landingTxt">도착한 메일을 확인하여 비밀번호를 재설정해 주세요.</li>
      </ul>
      <ul className="landingFrame landingFrameMo">
        <li className="pwResetTitle">비밀번호 재설정을 위한 메일이 전송되었습니다.</li>
        <li className="landingTxt">최대 3~5분이 소요될 수 있으며 메일이 도착하지 않았다면 스팸 편지함을 확인해 주세요.
        도착한 메일을 확인하여 비밀번호를 재설정해 주세요.</li>
      </ul>
    </div>
  )}
  }

export default PasswordResetPage
import React, {useState, useEffect} from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../../Session';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import * as ROLES from '../../../constants/roles';
import {getTimeString} from '../../helpers'
import EventManagement from './EventManagement'
import EventApplicantList from './EventApplicantList'
import ImagePopupAdmin from './ImagePopupAdmin'
import './AdminEvent.css'
import $ from "jquery";
import dayjs from 'dayjs'
import 'dayjs/locale/ko'

const AdminEventPage = props => {
  const [one, onOne] = useState('contents_show');
  const [two, onTwo] = useState('contents_hidden');
  const [three, onThree] = useState('contents_hidden');
  const [show, onShow] = useState('eventManagement');
  function First() {
    onShow('eventManagement');
    onOne('contents_show');
    onTwo('contents_hidden');
    onThree('contents_hidden')
    $(".adminContentsFrame>div>.dataTabBtnGroup div").css({"border":"1px solid #dedede", "color":"#aaaaaa"});
    $(".adminContentsFrame>div>.dataTabBtnGroup div").eq(0).css({"border-bottom":0,"color":"#000000" });    
  }
  function Second() {
    onShow('EventApplicantList');
    onOne('contents_hidden');
    onTwo('contents_show');
    onThree('contents_hidden')
    $(".adminContentsFrame>div>.dataTabBtnGroup div").css({"border":"1px solid #dedede", "color":"#aaaaaa"});
    $(".adminContentsFrame>div>.dataTabBtnGroup div").eq(1).css({"border-bottom":0,"color":"#000000" });
  }
  function Third() {
    onShow('ImagePopUp');
    onOne('contents_hidden');
    onTwo('contents_hidden');
    onThree('contents_show')
    $(".adminContentsFrame>div>.dataTabBtnGroup div").css({"border":"1px solid #dedede", "color":"#aaaaaa"});
    $(".adminContentsFrame>div>.dataTabBtnGroup div").eq(2).css({"border-bottom":0,"color":"#000000" });
  }
  useEffect(() => {
}, [])
let today = dayjs(new Date())
let todaySubtractMonth = today.add(-1, 'month').format('YYYY-MM-DD')
let todaySubtractMonthString = getTimeString(`${todaySubtractMonth}T00:00:00`)
let formattedtoday = parseInt(today.format('YYMMDD'))
        function TempMsg (type, randomCode) {
            return () => {
            switch (type) {
                case 'copiedCP':
                NotificationManager.info(`쿠폰 코드(${randomCode})가 복사되었습니다.`, 'COPIED', 1500, () => {
                    alert('callback');
                });
                break;
            }
            };
        };
  return (
    <div className="adminContentsFrame">
        <div>
          {/* 탭 부분 */}
          <div className="dataTabBtnGroup">
            <div className={`${one}`} onClick={()=>First()} style={{width:"33%"}}>이벤트 오픈 관리</div>
             <div className={`${two}`} onClick={()=>Second()} style={{width:"33%"}}>이벤트 신청 관리</div>
             <div className={`${three}`} onClick={()=>Third()} style={{width:"33%"}}>팝업창 관리</div>
          </div>
          {/* 탭에서 선택된 내용 부분 */}
          <div className="dataTabContGroup" style={{border:"none"}}>
            {show === 'eventManagement' ? <EventManagement/>
            : show === 'EventApplicantList' ?
            <EventApplicantList/>
            : show === 'ImagePopUp' ? 
            <ImagePopupAdmin/>
            :null}
            <NotificationContainer/>
            </div> 
        </div>
    </div>

  )};
  
  const condition = authUser =>
  authUser && authUser.roles[ROLES.MEMBER] === ROLES.ADMIN;
  
  export default compose(
    withEmailVerification,
    withAuthorization(condition),
  )(AdminEventPage);
import React, { useState, useEffect } from "react";
import { compose } from "recompose";
import { withAuthorization, withEmailVerification } from "../../Session";
import { getTimeString } from "../../helpers";
import SalesListApplied from "./SalesListApplied";
import TlpPayments from "./TlpPayments";
import MyBook from "./MyBook/index";
import Publishing from "./Publishing";
import VrRental from "./vrRental";
import VrEvent from './vrEvent'
import Refund from './Refund'
import CreatedPaidData from './CreatedPaidData'
import ServerTest from './ServerTest'
import ArappLogin from './ArappLogin'
import UserPage from './UserPage.jsx'
import "./AdminSales.css";
import $ from "jquery";

const AdminSalesPage = (props) => {
  const [one, onOne] = useState("adminProgram_show");
  const [two, onTwo] = useState("adminProgram_hidden");
  const [three, onThree] = useState("adminProgram_hidden");
  const [four, onFour] = useState("adminProgram_hidden");
  const [five, onFive] = useState("adminProgram_hidden");
  const [six, onSix] = useState("adminProgram_hidden");
  const [seven, onSeven] = useState("adminProgram_hidden");
  const [show, onShow] = useState("applied");
  const [programList, setProgramList] = useState([]);
  const [lastVisible, setlastVisible] = useState("");
  const [statistics, setstatistics] = useState({});

  const [showSecret, setshowSecret] = useState(false);
  const [showCalendarModal, setshowCalendarModal] = useState(false);
  const [searchUid, setsearchUid] = useState('')
  const [searchUserInfo, setsearchUserInfo] = useState({})
  function First() {
    onShow("applied");
    onOne("adminProgram_show");
    onTwo("adminProgram_hidden");
    onThree("adminProgram_hidden");
    onFour("adminProgram_hidden");
    onFive("adminProgram_hidden");
    onSix("adminProgram_hidden");
     onSeven("adminProgram_hidden");
     setsearchUid('')
     setsearchUserInfo({})
    $(".adminSalesFrame>div>.slpTabBtnGroup div").css({
      border: "1px solid #dedede",
      color: "#aaaaaa",
    });
    $(".adminSalesFrame>div>.slpTabBtnGroup div")
      .eq(0)
      .css({ "border-bottom": 0, color: "#000000" });
  }
  function Second() {
    onShow("payment");
    onOne("adminProgram_hidden");
    onTwo("adminProgram_show");
    onThree("adminProgram_hidden");
    onFour("adminProgram_hidden");
    onFive("adminProgram_hidden");
    onSix("adminProgram_hidden");
    onSeven("adminProgram_hidden");
    setsearchUid('')
    setsearchUserInfo({})
    $(".adminSalesFrame>div>.slpTabBtnGroup div").css({
      border: "1px solid #dedede",
      color: "#aaaaaa",
    });
    $(".adminSalesFrame>div>.slpTabBtnGroup div")
      .eq(1)
      .css({ "border-bottom": 0, color: "#000000" });
  }
  function Third() {
    onShow("codeUser");
    onOne("adminProgram_hidden");
    onTwo("adminProgram_hidden");
    onThree("adminProgram_show");
    onFour("adminProgram_hidden");
    onFive("adminProgram_hidden");
    onSix("adminProgram_hidden");
    onSeven("adminProgram_hidden");
    setsearchUid('')
    setsearchUserInfo({})
    $(".adminSalesFrame>div>.slpTabBtnGroup div").css({
      border: "1px solid #dedede",
      color: "#aaaaaa",
    });
    $(".adminSalesFrame>div>.slpTabBtnGroup div")
      .eq(2)
      .css({ "border-bottom": 0, color: "#000000" });
  }
  function Fourth() {
    onShow("printOut");
    onOne("adminProgram_hidden");
    onTwo("adminProgram_hidden");
    onThree("adminProgram_hidden");
    onFour("adminProgram_show");
    onFive("adminProgram_hidden");
    onSix("adminProgram_hidden");
    onSeven("adminProgram_hidden");
    setsearchUid('')
    setsearchUserInfo({})
    $(".adminSalesFrame>div>.slpTabBtnGroup div").css({
      border: "1px solid #dedede",
      color: "#aaaaaa",
    });
    $(".adminSalesFrame>div>.slpTabBtnGroup div")
      .eq(3)
      .css({ "border-bottom": 0, color: "#000000" });
  }
  function Fifth() {
    onShow("rental");
    onOne("adminProgram_hidden");
    onTwo("adminProgram_hidden");
    onThree("adminProgram_hidden");
    onFour("adminProgram_hidden");
    onFive("adminProgram_show");
    onSix("adminProgram_hidden");
    onSeven("adminProgram_hidden");
    setsearchUid('')
    setsearchUserInfo({})
    $(".adminSalesFrame>div>.slpTabBtnGroup div").css({
      border: "1px solid #dedede",
      color: "#aaaaaa",
    });
    $(".adminSalesFrame>div>.slpTabBtnGroup div")
      .eq(4)
      .css({ "border-bottom": 0, color: "#000000" });
  }
  // function Seventh() {
  //   onShow("vrEvent");
  //   onOne("adminProgram_hidden");
  //   onTwo("adminProgram_hidden");
  //   onThree("adminProgram_hidden");
  //   onFour("adminProgram_hidden");
  //   onFive("adminProgram_hidden");
  //   onSix("adminProgram_hidden");
  // onSeven("adminProgram_show");
  // setsearchUid('')
  // setsearchUserInfo({})
  // onCheckAlert('tlpvrEvent')
  //   $(".adminSalesFrame>div>.slpTabBtnGroup div").css({
  //     border: "1px solid #dedede",
  //     color: "#aaaaaa",
  //   });
  //   $(".adminSalesFrame>div>.slpTabBtnGroup div")
  //     .eq(5)
  //     .css({ "border-bottom": 0, color: "#000000" });
  // }
  function Sixth() {
    onShow("canceled");
    onOne("adminProgram_hidden");
    onTwo("adminProgram_hidden");
    onThree("adminProgram_hidden");
    onFour("adminProgram_hidden");
    onFive("adminProgram_hidden");
    onSix("adminProgram_show");
    onSeven("adminProgram_hidden");
    setsearchUid('')
    setsearchUserInfo({})
    onCheckAlert('tlpSalesCancel')
    $(".adminSalesFrame>div>.slpTabBtnGroup div").css({
      border: "1px solid #dedede",
      color: "#aaaaaa",
    });
    $(".adminSalesFrame>div>.slpTabBtnGroup div")
      .eq(4)
      .css({ "border-bottom": 0, color: "#000000" });
  }


  const [alertList, setalertList] = useState([])
  useEffect(() => {
    getAlert()
  }, [])
  function getAlert() {
    //체크!!
    props.firebase.alert('tlpvrEvent').onSnapshot(
      {includeMetadataChanges:true},
      (doc)=>{     
        var alertData= doc.data();
        if(alertData.checkedAt === '0'){
          setalertList(oldArray => [...oldArray, alertData.type])}
    })
    props.firebase.alert('tlpSalesCancel').onSnapshot(
      {includeMetadataChanges:true},
      (doc)=>{     
        var alertData= doc.data();
        if(alertData.checkedAt === '0'){
          setalertList(oldArray => [...oldArray, alertData.type])}
    })
  }

  function onCheckAlert (data) {
      props.firebase.alert(data).set({
      checkedAt : 'checked'
    },{merge:true})
  const idx = alertList.indexOf(data)
  if (idx >-1){ alertList.splice(idx, 1)}
    
  }
  function getProgramList() {
    const newList = [];
    props.firebase.tlpSales().orderBy("createdAt", "desc").limit(20).get()
      .then((result) => {
        var lastVisible = result.docs[result.docs.length - 1];
        result.forEach((doc) => {
          var uid = doc.id;
          var listData = doc.data();
          // console.log(listData)
          var selectedDays = [];
          if (listData["selectedDays"] !== []) {
            listData["selectedDays"].map((c) => {
              var year = c.substr(0, 2);
              year = parseInt(year);
              var mon = c.substr(2, 2);
              mon = parseInt(mon);
              var date = c.substr(4, 2);
              date = parseInt(date);

              var dateFormat = new Date(2000 + year, mon - 1, date, 12, 0, 0);
              selectedDays.push(dateFormat);
            });
            listData["selectedDays"] = selectedDays;
          }
          newList.push({ uid, ...listData });
        });
        setlastVisible(lastVisible);
        setProgramList(newList);
      });
  }
  function getNewProgramList() {
    let scrollPointX = window.scrollX;
    let scrollPointY = window.scrollY;
    const newList = [];
    if (lastVisible !== undefined) {
      props.firebase.tlpSales().orderBy("createdAt", "desc").startAfter(lastVisible).limit(20).get()
        .then((result) => {
          var lastVisible = result.docs[result.docs.length - 1];
          result.forEach((doc) => {
            var uid = doc.id;
            var listData = doc.data();
            // console.log(listData)
            var selectedDays = [];
            if (listData["selectedDays"] !== []) {
              listData["selectedDays"].map((c) => {
                var year = c.substr(0, 2);
                year = parseInt(year);
                var mon = c.substr(2, 2);
                mon = parseInt(mon);
                var date = c.substr(4, 2);
                date = parseInt(date);

                var dateFormat = new Date(2000 + year, mon - 1, date, 12, 0, 0);
                selectedDays.push(dateFormat);
              });
              listData["selectedDays"] = selectedDays;
            }
            newList.push({ uid, ...listData });
          });
          setlastVisible(lastVisible);
          setProgramList((list) => list.concat(newList));
          window.scrollTo(scrollPointX, scrollPointY);
        });
    } else {
      alert("최신 데이터 입니다.");
    }
  }
  function editProgramInfo(uid, appliedList) {
    if (appliedList["selectedDays"] !== []) {
      const list = [];
      appliedList["selectedDays"].map((c) => {
        var DaysData = getTimeString(c);
        list.push(DaysData);
        // console.log(DaysData)
      });
      appliedList["selectedDays"] = list;
    }
    appliedList["progressAt"] = getTimeString();
    const authUserId = appliedList["authUserId"];
    const programListKey = `${appliedList["createdAt"]}_${appliedList["page"]}`;
    const programList = {};
    programList[programListKey] = appliedList;
    // console.log(appliedList)
    props.firebase
      .tlpSale(uid)
      .set(appliedList, { merge: true })
      .then(() => alert("수정되었습니다."))
      .catch((err) => alert("잠시 후에 다시 시도해 주세요.\n에러 : " + err));
  }
  function getTotalNum() {
    props.firebase
      .tlpStatistics()
      .get()
      .then((res) => {
        let data = res.data();
        setstatistics(data);
        // console.log(data)
      });
  }
  useEffect(() => {
    getTotalNum();
  }, []);
  function searchUser (){
    props.firebase.user(searchUid).get()
      .then((res) => setsearchUserInfo({...res.data(), uid:res.id}))
      .catch((err) => alert("잠시 후에 다시 시도해 주세요.\n에러 : " + err));
  }
  // console.log(searchUserInfo)
  return (
    <div className="adminSalesFrame">
      <h3>On-line Learning 조회 &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
        <span style={{color:'white'}} onDoubleClick={()=>setshowSecret(true)}>코드생성</span>
        <span><ServerTest/></span>
        <span style={{color:'white'}} onDoubleClick={()=>onShow('user')}>U</span>
        <span style={{color:'white'}} onDoubleClick={()=>setshowCalendarModal(true)}>AR앱(only)</span>
      </h3>
      {showSecret &&
      <span className='sendMsgContBg'>
        <CreatedPaidData setshowSecret={setshowSecret}/>
      </span>}
      {showCalendarModal &&
      <span className='sendMsgContBg'>
        <ArappLogin setshowSecret={setshowCalendarModal}/>
      </span>}
      <div>
        {/* 탭 부분 */}
        <div className="slpTabBtnGroup">
          <div className={`${one}`} onClick={() => First()}>
            이러닝 문의
          </div>
          <div className={`${two}`} onClick={() => Second()}>
            결제 내역
          </div>
          <div className={`${three}`} onClick={() => Third()}>
            북 등록 사용자 관리
          </div>
          <div className={`${four}`} onClick={() => Fourth()}>
            인쇄/배송
          </div>
          {/* <div className={`${five}}`} onClick={() => Fifth()}>
            기기 체험
          </div>
          <div className={`${five}}`} onClick={() => Seventh()}>
            기기구매
            {alertList && alertList.includes('tlpvrEvent') && <span style={{color:"blue"}}> N</span>}
          </div> */}
          <div className={`${six}}`} onClick={() => Sixth()}>
            환불/주문 취소
            {alertList && alertList.includes('tlpSalesCancel') && <span style={{color:"blue"}}> N</span>}
          </div>
        </div>
        {/* 탭에서 선택된 내용 부분 */}
        <div className="slpTabContGroup">
          {show === "applied" ? (
            <SalesListApplied
              onGetList={getProgramList}
              oneditList={editProgramInfo}
              programList={programList}
              totalNum={statistics.Totalsales || 0}
              getNewProgramList={getNewProgramList}
            />
          ) : show === "payment" ? (
            <TlpPayments totalNum={statistics.Totalpayment || 0} />
          ) : show === "codeUser" ? (
            <MyBook totalNum={statistics.Totalmybook || 0} />
          ) : show === "printOut" ? (
            <Publishing totalNum={statistics.Totalpublished || 0} />
          ) : show === "rental" ? (
            <VrRental totalNum={statistics.TotalvrRent || 0} />
          ) : show === "vrEvent" ? (
            <VrEvent totalNum={statistics.TotalvrEvent || 0} />
          ) : show === 'canceled' ? <Refund totalNum={statistics.Totalrefund || 0}/> :
          show === 'user' ? 
          <>
          <input type='text' placeholder='searchUid' value={searchUid} name='searchUid' onChange={(e)=>setsearchUid(e.target.value)}/><span onClick={()=>searchUser()}>찾기</span>
         { searchUserInfo.uid !==undefined && 
        //  <div>좀</div>
          <UserPage authUser={searchUserInfo} totalNum={statistics.Totalrefund || 0}/>
          }
          </>
           :
          null}
        </div>
      </div>
    </div>
  );
};

const condition = (authUser) => authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(AdminSalesPage);

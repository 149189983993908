import React, { Component } from 'react'
import SalesContentInput from './SalesContentInput'
import SalesEntered from './SalesEntered'
import { withFirebase } from '../../../Firebase'
import {isNumeric} from '../../../helpers'


class SalesContents extends Component {
    state = {
        programList : [],
        vrEventList:{},
        vrEventPrice:0,
        vrEventCapa:0,
        vrEventTitle:'',
        vrEventRatio:'',
        vrEventRestCapa:0
    }
    componentDidMount(){
        this.getProgramList()
        this.getvrEventList()
    }
    getProgramList =()=>{
        this.props.firebase.sales().orderBy('order').get()
        .then(result => {
            var programList = []
            result.forEach(res => {
                var listData = res.data()
                var pid = res.id
                programList.push({pid, ...listData})
            })
            this.setState({programList})
        })
    }
    getvrEventList = () => {
        this.props.firebase.sale('vrEvent').onSnapshot(
          {includeMetadataChanges:true},
          (result)=>{
            var vrEventList = {}
            var listData = result.data()
            var pid = result.id
            vrEventList={pid, ...listData}
            this.setState({vrEventRestCapa: vrEventList.vrEventRestCapa})
          }
        )
      }
    savevrEventList =()=>{
        let { vrEventPrice, vrEventTitle, vrEventCapa,vrEventRatio } = this.state
        let data = { vrEventPrice:Number(vrEventPrice),
                    vrEventTitle,
                    vrEventCapa: Number(vrEventCapa),
                    vrEventRestCapa: Number(vrEventCapa),
                    vrEventRatio:Number(vrEventRatio) }
        this.props.firebase.sale('vrEvent').set(data)
        .then(() => {
            alert('기기 구매 이벤트 정보가 저장되었습니다.')
        })
    }
    deleteProgram =(program)=>{
        const {programList} = this.state;
    
            const pid = program.pid
            const title = program.title
            // console.log(pid, title)
            this.props.firebase.sale(pid).delete()
            .then(()=> alert(`${title}이 삭제되었습니다.`))
            .then(()=> {
                var newList = programList.slice()
                var idx = newList.indexOf(program)
                if (idx >-1) newList.splice(idx, 1)
                this.setState({programList : newList})
            })
            .catch(err=> alert('잠시 후 다시 시도해 주세요.\nerror: '+err))
        }

    render() {
        const {programList, vrEventPrice, vrEventTitle, vrEventCapa, vrEventRatio, vrEventRestCapa} = this.state;
        let {digitalbookList} = this.props
        const formattervrEventPrice = vrEventPrice && vrEventPrice.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        const formattervrEventCapa = vrEventCapa && vrEventCapa.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        const formattervrEventRatio = vrEventRatio && vrEventRatio.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        const formattervrEventTotalPrice = isNumeric(vrEventPrice) && isNumeric(vrEventRatio) ? (vrEventPrice*(100-vrEventRatio)/100).toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') : ''
        return (
            <div>
                {/* <div>기기 이벤트 정보 (대상 별 허용여부는 각각 선택 가능)</div>
                <h4>기기 구매 관련</h4>
                <div>
                주문페이지 내 이벤트 옵션 이름
                <input type='text' name='vrEventTitle' value={vrEventTitle} placeholder='이벤트 이름' onChange={e => this.setState({vrEventTitle : e.target.value})}/>
                </div>
                <div>
                이벤트 가격 (할인 적용 전)
                <input type='text' name='vrEventPrice' value={formattervrEventPrice} placeholder='0(원)' onChange={e => this.setState({vrEventPrice : e.target.value.replace(/[^0-9]/g,"")})}/>
                </div>
                <div>
                이벤트 할인율(할인율 없을 경우 0)
                <input type='text' name='vrEventRatio' value={formattervrEventRatio} placeholder='0%' onChange={e => this.setState({vrEventRatio : e.target.value.replace(/[^0-9]/g,"")})}/>
                </div>
                <div>할인된 가격 {formattervrEventTotalPrice}원</div>
                <div>
                선착순 제한 (선착순 아닌 경우 0)
                <input type='text' name='vrEventCapa' value={formattervrEventCapa} placeholder='0 대' onChange={e => this.setState({vrEventCapa : e.target.value.replace(/[^0-9]/g,"")})}/>
                </div>
                <div>현재 {vrEventRestCapa}대 남음(결제 전 포함)</div>
                <div onClick={()=>this.savevrEventList()}>이벤트 저장</div> */}
            {/* 개별 프로그램 등록 */}
            {programList&& programList.map((c, id)=>
            <div key={c.pid}  className="contentsDataGroup selfContData">
                <SalesContentInput getProgramList={this.getProgramList} deleteProgram={this.deleteProgram} programList={c} digitalbookList={digitalbookList} idx={id}
                // vrEventPrice={vrEventPrice}
                />
            </div>)
             }
            {/* 등록된 프로그램 삭제 */}
            {/* <div><SalesEntered getProgramList={this.getProgramList} deleteProgram={this.deleteProgram} programList={programList}/></div> */}
        </div>
        )
    }
}
export default withFirebase(SalesContents);
import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import { getTimeString } from '../helpers'
import './Eventicon.css'

class EventIcon extends Component {
    state={
        eventList:[]
    }
    componentDidMount(){
        this._ismounted = true;
        if(this._ismounted){
        this.getBanner()
    }   
    }
    componentWillUnmount() {
        this._ismounted = false;
     }
    getBanner = () => {
        const Today = parseInt(getTimeString()/1000000)
        const {onUpdate} = this.props
        this.props.firebase.banners().orderBy('createdAt','desc').limit(1).get()
        .then((result) => {
          var eventList = [];
          result.forEach((doc) => {
              var childData = doc.data();
              if(childData.startDate-Today<1 && Today-childData.endDate<1){
              eventList.push(childData)
            } 
          })
          this.setState({eventList})
        //  this.setState({})
      })
      .catch(function(err) {
          console.log(err)
      });
      }
    render() {
        const {eventList} = this.state
        // console.log(eventList)
        return (
            eventList.length >0 &&
                <div className="eventIcon" key={eventList[0].createdAt}>                     
                    <Link to={ROUTES.TLP_EVENT} >
                        <img src={eventList[0].iconUrl} alt="eventImg" />
                    </Link>
                </div>
           
        )
    }
}
export default withFirebase(EventIcon)


import React, {useState} from 'react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Virtual, Autoplay } from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';
import dayjs from 'dayjs'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);
SwiperCore.use([Virtual]);

const MainSwiper = props => {
    // console.log(props)
    let {slideList, idName} = props
    const [selectedIndex, setselectedIndex] = useState(0)
    // const slideList = Array.from({ length: 10 }).map((el, index) => `Slide ${index + 1}`);    
    return (
        <div className="sectInFrame">
        {/* 슬라이드 */}
           <Swiper className="swiperFrame"
           id={idName}
                // spaceBetween={50}
                slidesPerView={1}
                navigation
                onSlideChange={(slide) => setselectedIndex(slide.activeIndex)}
                // autoplay={{delay:5000}}
                // loop={true}
                >
                {slideList && slideList.map((c, id) => {
                    let {reviewId, reviewTitle, username ,createdAt, review, programTitle
                    }  = c
                    var year = createdAt.substr(0,2)
                    let yearInt = parseInt(year)
                     var mon = createdAt.substr(2,2)
                     let monInt = parseInt(mon)
                     var date = createdAt.substr(4,2)
                     let dateInt = parseInt(date)
                 var isCreatedAtFormatted= dayjs(new Date(2000+yearInt, monInt-1, dateInt))
                 var isCreatedAtFormatted2 = isCreatedAtFormatted.format('YYYY-MM-DD') 
                    return(
                    <SwiperSlide className="reviewContFrame" key={id}>
                        <div className="reviewTextGroup"> 
                            <p className="reviewTitle">{reviewTitle}</p>
                            <p className="content contentPc">{review && review.split('<br />').map((c, id)=> {
                                return(
                                            <span key={id}>{c}<br/></span>
                                        )})}
                            </p>
                            <p className="content contentMobile">{(review).replace(/<br\s*[\/]?>/gi, " ")}</p>
                            <p className="writer">{isCreatedAtFormatted2}."『{programTitle}』 프로그램 " 후기,{username}</p>
                        </div>
                    </SwiperSlide>
                    );
                })}
                </Swiper>
        </div>
    );
};

export default MainSwiper;
import React, { Component, Fragment } from 'react'
import {withFirebase} from '../../../Firebase'
import ImageUploadNR from '../../../Utils/ImageUploadNR'
import DigitalbookPageList from './DigitalbookPageList'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { TwitterPicker, CirclePicker } from 'react-color';
import SunEditor from 'suneditor-react';
import { faChessBoard } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import '../../../../constants/suneditor.min.css';
import './DigitalbookToolPage.css'
import $ from "jquery";

class DigitalbookPageTool extends Component {
    state={
        pageList : [{userSection:[], pageTitle:'', pageHeader:'hidden', chapter:0, pageBgImgUrl:'',pageBgImgColor:'white'}],
        pageId:0,
        showPageHeaderList:false
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.digitalbook !== this.props.digitalbook && this.props.digitalbook !==[]){
            this.setState({
                pageList : this.props.digitalbook,
                pageId:0,

            })
        } else if (prevProps.digitalbook !== this.props.digitalbook && this.props.digitalbook ===[]){
            this.setState({
                pageList : [{userSection:[], pageTitle:'', pageHeader:'hidden', chapter:0, pageBgImgUrl:'',pageBgImgColor:'white'}],
                pageId:0,
            })
        }
        if(prevState.pageId !== this.state.pageId){
            let {pageList, pageId} = this.state
           this.setState({
            pageTitle:  pageList[pageId].pageTitle,
            pageBgImgUrl:  pageList[pageId].pageBgImgUrl,
            pageBgImgColor: pageList[pageId].pageBgImgColor,
            pageHeader: pageList[pageId].pageHeader,
            userSection:  pageList[pageId].userSection,
           })
        }
}

    onStateChange = e => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }
    onPropsChange = (name, value) => {
    this.setState({
        [name] : value
    })
    }
    handleChange = (e) => {
        if (["textTitle", "writingValue", "srcUrl", "srcUrl2", "contentWidth", "contentHeight",
         "textJustify", "textDirection", "editorBgColor", "rowsLimit"].includes(e.target.className) ) {
          let currentPageList = this.state.pageList.slice()
          currentPageList[this.state.pageId].userSection[e.target.dataset.id][e.target.className] = e.target.value
          this.setState({
               pageList: currentPageList, 
            })
        } else if(["textarea"].includes(e.target.className)) {
            let currentPageList = this.state.pageList.slice()
          currentPageList[this.state.pageId].userSection[e.target.dataset.id]['textarea'] = e.target.value.replace(/(?:\r\n|\r|\n)/g, '<br />')
          this.setState({
               pageList: currentPageList, 
            })
        }
        else if(["isSafari"].includes(e.target.className)) {
            console.log(e.target.value)
            let currentPageList = this.state.pageList.slice()
            currentPageList[this.state.pageId].userSection[e.target.dataset.id]["isSafari"] = !currentPageList[this.state.pageId].userSection[e.target.dataset.id]["isSafari"]
            this.setState({
                 pageList: currentPageList, 
              })
        }
      }
      editorColorChange = (color, e, dataset, className) => {
        //   console.log(color)
        e.preventDefault();
        let currentPageList = this.state.pageList.slice()
        currentPageList[this.state.pageId].userSection[dataset][className] = color.hex
        this.setState({
             pageList: currentPageList, 
          })
      }
      colorChange = (color, event) => {
        event.preventDefault();
        let currentPageList = this.state.pageList.slice()
        currentPageList[this.state.pageId]['pageBgImgColor'] = color.hex
        this.setState({pageList : currentPageList})
      }

    editPageOption = (className, value) => {
        let currentPageList = this.state.pageList.slice()
        currentPageList[this.state.pageId][className] = value
        this.setState({pageList : currentPageList})
    }
    editImageUrl = (className, srcUrl, idx) => {
        let currentPageList = this.state.pageList.slice()
        currentPageList[this.state.pageId].userSection[idx][className] = srcUrl
        this.setState({pageList : currentPageList})
    }
    userSectionChange = ( className, contents, idx,) => {
        let currentPageList = this.state.pageList.slice()
        currentPageList[this.state.pageId].userSection[idx][className] = contents
        this.setState({pageList : currentPageList});
      }

      onDeleteBgimg = () => {
        if(this.state.pageList[this.state.pageId].pageBgImgUrl !==''){
            let decodeURI = decodeURIComponent(this.state.pageList[this.state.pageId].pageBgImgUrl).split('tlp/digitalbook/admin/')[1].split('?alt=')[0]
            let currentPageList = this.state.pageList.slice()
          currentPageList[this.state.pageId].pageBgImgUrl = ""
            this.props.firebase.storage.ref(`tlp/digitalbook/admin/`).child(decodeURI)
            .delete()
            .then(()=>{
                this.setState({
                    pageList : currentPageList
                })
                alert('배경이미지가 삭제되었습니다.')
            })
            .catch(err => console.log(err))
        }
    }
    onDeleteimg = (idx) => {
        let currentPageList = this.state.pageList.slice()
        let ImageUrl = currentPageList[this.state.pageId].userSection[idx].srcUrl
        
        if(ImageUrl !==''){
            let decodeURI = decodeURIComponent(ImageUrl).split('tlp/digitalbook/admin/')[1].split('?alt=')[0]
            let currentPageList = this.state.pageList.slice()
          currentPageList[this.state.pageId].srcUrl = ""
            // this.props.firebase.storage.ref(`tlp/digitalbook/admin/`).child(decodeURI)
            // .delete()
            // .then(()=>{
                this.setState({
                    pageList : currentPageList
                })
        //         alert('이미지가 삭제되었습니다.')
        //     })
        //     .catch(err => console.log(err))
        }
    }

    addTextEditor = (e) => {
        let currentPageList = this.state.pageList.slice()
        currentPageList[this.state.pageId].userSection.push({textTitle:"", type:'textEditor',editorBgColor:'transparent'})
        this.setState({pageList : currentPageList});
        this.showPageHeaderList('hide')
      }
    addImage = (e) => {
        let currentPageList = this.state.pageList.slice()
        currentPageList[this.state.pageId].userSection.push({textTitle:"", type:'image', srcUrl:"", contentWidth:'20',
        textJustify:'flex-start', textDirection:'row', textarea:''})
        this.setState({pageList : currentPageList});
        this.showPageHeaderList('hide')
      }
    
    addVideo = (e) => {
        let currentPageList = this.state.pageList.slice()
        currentPageList[this.state.pageId].userSection.push({textTitle:"", type:'video', srcUrl:'', srcUrl2:'', contentWidth:'20',
        textJustify:'flex-start', textDirection:'row', isSafari: false})
        this.setState({pageList : currentPageList});
        this.showPageHeaderList('hide')
      }
    addInputText = (e) => {
        let currentPageList = this.state.pageList.slice()
        currentPageList[this.state.pageId].userSection.push({textTitle:"", type:'inputText', writingValue:''})
        this.setState({pageList : currentPageList});
        this.showPageHeaderList('hide')
      }
    addTextArea = (e) => {
        let currentPageList = this.state.pageList.slice()
        currentPageList[this.state.pageId].userSection.push({textTitle:"", type:'textarea', writingValue:'',
                                                             rowsLimit:'10', rowsCount:'', textCount:'',})
        this.setState({pageList : currentPageList});
        this.showPageHeaderList('hide')
      }
    addDrawingTool = (e) => {
        let currentPageList = this.state.pageList.slice()
        currentPageList[this.state.pageId].userSection.push({textTitle:"", type:'drawingTool', writingValue:'',imgUrl:'', contentHeight:"30", bgColor:'white' })
        this.setState({pageList : currentPageList});
        this.showPageHeaderList('hide')
      }
      addEmptySpace = (e) => {
        let currentPageList = this.state.pageList.slice()
        currentPageList[this.state.pageId].userSection.push({type:'emptySpace'})
        this.setState({pageList : currentPageList});
        this.showPageHeaderList('hide')
      }
    handleSubmit = (e) => { 
        e.preventDefault()
     }
     addPage = () => {
        let currentPageId = this.state.pageId
        let addPageId = currentPageId>0 ? currentPageId+1 : 1
        let addPage = {userSection:[], pageTitle:'입력 안함', pageHeader:'hidden', pageBgImgUrl:'',pageBgImgColor:'white'}
        let tempPageList = this.state.pageList.slice()
        tempPageList.splice(currentPageId+1, 0, addPage)
        this.setState({
            pageList : tempPageList,
            pageId : this.state.pageId +=1
                })
     }
     copyPage = () => {
         let { pageList, pageId } = this.state
         let tempPageList = pageList.slice()
        let currentPage = Object.assign({},tempPageList[pageId])
        currentPage.pageTitle = currentPage.pageTitle+'-복사본'
        tempPageList.splice(pageId+1, 0, currentPage)
        this.setState({
            pageList : tempPageList,
            pageId : this.state.pageId +=1
                })
     }
     deletePage = () => {
        let { pageList, pageId } = this.state
        let tempPageList = pageList.slice()
       let currentPage = Object.assign({},tempPageList[pageId])
       currentPage.pageTitle = currentPage.pageTitle+'-복사본'
       tempPageList.splice(pageId+1, 0, currentPage)
       this.setState({
           pageList : tempPageList,
           pageId : this.state.pageId +=1
               })
    }
     deletePageList = () => {
        let result =  window.confirm('작성하신 모든 페이지를 삭제하시겠습니까?')
        if(result){
            this.setState({
                pageList : [{userSection:[], pageTitle:'', pageHeader:'hidden', chapter:0, pageBgImgUrl:'',pageBgImgColor:'white'}],
                pageId : 0
            })
        }
        
     }
     setNewBook = () => {
         let { pageList } = this.state
         let result = window.confirm('제작 중인 디지털북을 저장하시겠습니까?')
         if(result){
        this.props.onSubmitDigitalbook(pageList)
        }   
        this.setState({
            pageList : [{userSection:[], pageTitle:'', pageHeader:'hidden', chapter:0, pageBgImgUrl:'',pageBgImgColor:'white'}],
            pageId:0
         })
         this.props.onSetData('')
         
     }
     onPageOrder = (type, idx) => {
        let tempPageList = this.state.pageList.slice()
        let pageListLeng = tempPageList.length
        if (type == 'up' && idx > 0) {
            let copyList = tempPageList.splice(idx, 1)
            tempPageList.splice(idx - 1, 0, copyList[0])
        } else if (type == 'down' && idx < pageListLeng -1) {
            let copyList = tempPageList.splice(idx, 1)
            tempPageList.splice(idx + 1, 0, copyList[0])
        } else if (type === 'delete') {
            if(idx === this.state.pageId){
                this.setState({pageId : this.state.pageId -=1})
            }
            tempPageList.splice(idx, 1)
        }
        this.onPropsChange('pageList', tempPageList)
    }
    showPageHeaderList = (type) => {
        if(type === 'show'){
            // $(".makingPageFrame .setOutput1>div.output1Frame").css("overflow","hidden");   
            $(".makingPageFrame .setOutput1>div.output1Frame").addClass("scrollbarHide");         
            $(".makingPageFrame .setOutput1>div.output1Frame").removeClass("scrollbarShow");
            // $('headingTextFrame').show()
            this.setState({
                showPageHeaderList : true
            })
        } else if(type === 'hide'){
            $(".makingPageFrame .setOutput1>div.output1Frame").addClass("scrollbarShow");
            $(".makingPageFrame .setOutput1>div.output1Frame").removeClass("scrollbarHide");   
            // $(".makingPageFrame .setOutput1>div.output1Frame").css({"overflow":"auto", "overflow-x":"hidden"});
            // $('headingTextFrame').hide()
            this.setState({
                showPageHeaderList : false
            })
        }
    }
    render() {
        const {pageList, pageId, showPageHeaderList} = this.state
        const { userSection, pageTitle, pageHeader, pageBgImgColor, pageBgImgUrl, chapter} = pageList ? pageList[pageId] : {userSection:[], pageTitle:'', pageHeader:'hidden', chapter:0, pageBgImgUrl:'',pageBgImgColor:'white'}
        const buttonLists= [
            'font', 'fontSize', 'formatBlock', 'align', 'fontColor', 'hiliteColor', 'bold', 'underline', 'italic', 'strike', 'removeFormat',
            'indent','outdent', 'horizontalRule', 'lineHeight','paragraphStyle', 'save'
        ]
        let pageListLeng = this.state.pageList.length
        // os56e6_0924 - ch1
        return (
            <React.Fragment>
                <div className="setPageTitle">
                    <p>페이지 제목</p>
                    <input type='text' name='pageTitle' value={pageTitle}
                        onChange={e=> this.editPageOption('pageTitle',e.target.value)}/>
                </div>
                <div className="setIndex">
                    <p>목차 선택</p>
                    <div>
                        <div><input type='checkbox' name='pageHeader' checked={pageHeader === 'header1'} onChange={()=> this.editPageOption('pageHeader','header1')}/><span>대단원</span></div>
                        <div><input type='checkbox' name='pageHeader' checked={pageHeader === 'header2'} onChange={()=> this.editPageOption('pageHeader','header2')}/><span>중단원</span></div>
                        <div><input type='checkbox' name='pageHeader' checked={pageHeader === 'header3'} onChange={()=> this.editPageOption('pageHeader','header3')}/><span>소단원</span></div>
                        <div><input type='checkbox' name='pageHeader' checked={pageHeader === 'hidden'} onChange={()=> this.editPageOption('pageHeader','hidden')}/><span>표시안함</span></div>
                    </div>
                </div>
                <div className="setIndex2">
                    <p>챕터 선택</p>
                    <div>
                        <div><input type='checkbox' name='chapter' checked={chapter === 0} onChange={()=> this.editPageOption('chapter',0)}/><span>챕터없음</span></div>
                        <div><input type='checkbox' name='chapter' checked={chapter === 1} onChange={()=> this.editPageOption('chapter',1)}/><span>Chp 1</span></div>
                        <div><input type='checkbox' name='chapter' checked={chapter === 2} onChange={()=> this.editPageOption('chapter',2)}/><span>Chp 2</span></div>
                        <div><input type='checkbox' name='chapter' checked={chapter === 3} onChange={()=> this.editPageOption('chapter',3)}/><span>Chp 3</span></div>
                        <div><input type='checkbox' name='chapter' checked={chapter === 4} onChange={()=> this.editPageOption('chapter', 4)}/><span>Chp 4</span></div>
                    </div>
                </div>
              
                <div className="setBg">
                    <p>배경 설정</p>
                    <div className="setBgContent">
                        <div className="bgColorFrame">
                            <TwitterPicker
                            triangle ={'hide'}
                            // colors = {['#ffffff', '#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF'] }
                            colors = {['#ffffff', '#ffe2c3', '#fff9c3', '#c7ffd5', '#c7daff', '#e5c7ff', '#ffbdcc', '#ABB8C3'] }
                            color = {pageBgImgColor}
                            width ={'100%'}
                            onChange={ this.colorChange } />
                        </div>
                        <div className="bgImageFrame">
                            <ImageUploadNR
                                    onUpdate={this.editPageOption}
                                    itemName={'pageBgImgUrl'}
                                    fileName={'digitalbook/admin/'}
                                    />
                            {/* <button onClick={()=>this.onDeleteBgimg()}>배경이미지 삭제</button> */}
                        </div>
                    </div>
                </div>
                <div className="setPage">
                    <p>책 구성</p>
                    <div className="setPageFrame">
                        <div className="setPageContent">
                            <button onClick={()=>this.addPage()}>빈 페이지 추가</button>
                            <button onClick={()=>this.copyPage()}>현재 페이지 복사</button>
                            <button onClick={()=>this.onPageOrder('delete', this.state.pageId)}>현재 페이지 삭제</button>
                            <button onClick={()=>this.deletePageList()}>전체 삭제</button>
                            <button onClick={()=>this.props.setPreview(pageList)}>미리보기</button>
                            {showPageHeaderList ? <button onClick={()=>this.showPageHeaderList('hide')}>목차 닫기</button>
                            :<button onClick={()=>this.showPageHeaderList('show')}>목차 보기</button>}
                            <button onClick={()=>this.props.onSubmitDigitalbook(pageList)}>디지털북 저장</button>
                            <button onClick={()=>this.setNewBook()}>새로 만들기</button>
                        </div>
                        {/* <div className="setPagePager">
                            <button disabled={this.state.pageId===0 ? true : false} onClick={()=> this.setState({pageId : this.state.pageId -=1})}>&#60;</button>
                            <span>{pageId} / {pageList.length-1}</span>
                            <button disabled={this.state.pageId===this.state.pageList.length-1 ? true : false} onClick={()=> this.setState({pageId : this.state.pageId +=1})}>&#62;</button>
                        </div> */}
                    </div>
                </div>
                <div className="setCont">
                    <p>페이지 구성</p>
                    <div className="setContFrame">
                        <div className="setPageContent">
                            <button onClick={this.addTextEditor}>텍스트 에디터</button>
                            <button onClick={this.addImage}>이미지</button>
                            <button onClick={this.addVideo}>동영상</button>
                            <button onClick={this.addInputText}>한 줄 입력 창</button>
                            <button onClick={this.addTextArea}>긴 줄 입력 창</button>
                            <button onClick={this.addDrawingTool}>드로잉 창</button>
                            <button onClick={this.addEmptySpace}>공백 한줄</button> 
                        </div>
                        <div className="setPagePager">
                                <button disabled={this.state.pageId===0 ? true : false} onClick={()=> this.setState({pageId : this.state.pageId -=1})}>&#60;</button>
                                <span>{pageId} / {pageList.length-1}</span>
                                <button disabled={this.state.pageId===this.state.pageList.length-1 ? true : false} onClick={()=> this.setState({pageId : this.state.pageId +=1})}>&#62;</button>
                        </div>
                    </div>
                </div>
                <div className="setOutput1 ">
                    <div>
                        <div className="output1Frame scrollbarShow" style={{backgroundColor: pageBgImgColor}}>
                                {/* <form onSubmit={this.handleSubmit} >                             */}
                                {
                                userSection && userSection.map((val, idx)=> {
                                    let catId = `cat-${idx}`, ageId = `age-${idx}`
                                    return (
                                    <React.Fragment key={idx}>
                                        {val.type === 'textEditor' ? 
                                        <div className="bookContFrame textEditorFrame">
                                            <div className="textEditorBg">
                                                <p>원하는 배경 색상이나 #컬러코드(HTML기준)를 박스 안에 입력해주세요</p>
                                                <div>
                                                    <div>
                                                        <input type='checkbox' name={catId} value="transparent" data-id={idx} className="editorBgColor" 
                                                            checked={userSection[idx].editorBgColor==='transparent'}
                                                            onChange={this.handleChange}  />
                                                        <span>transparent</span>
                                                    </div>
                                                    <div>
                                                        <input type='checkbox' name={catId} value="orange" data-id={idx} className="editorBgColor" 
                                                            checked={userSection[idx].editorBgColor==='orange'}
                                                            onChange={this.handleChange}  />
                                                        <span>orange</span>
                                                    </div>
                                                    <div>
                                                        <input type='checkbox' name={catId} value="blue" data-id={idx} className="editorBgColor" 
                                                            checked={userSection[idx].editorBgColor==='blue'}
                                                            onChange={this.handleChange}  />
                                                        <span>blue</span>
                                                    </div>
                                                    <div>
                                                        <input type='checkbox' name={catId} value="green" data-id={idx} className="editorBgColor" 
                                                            checked={userSection[idx].editorBgColor==='green'}
                                                            onChange={this.handleChange}  />
                                                        <span>green</span>
                                                    </div>
                                                    <input type='text' value={userSection[idx].editorBgColor} data-id={idx} className="editorBgColor" onChange={this.handleChange} />
                                                </div>
                                            </div>
                                            <div style={{border: '1px solid #dadada', backgroundColor:val.editorBgColor}}>
                                                <SunEditor onChange={(contents)=>this.userSectionChange('textTitle', contents, idx)} lang="ko"
                                                    height = 'auto'
                                                    setOptions={{
                                                        defaultStyle:'font-size:1.6rem',
                                                        lineHeights : [
                                                            {text: '0.5', value: 0.5},
                                                            {text: '0.7', value: 0.7},
                                                            {text: '0.85', value: 0.85},
                                                            {text: '1', value: 1},
                                                            {text: '1.15', value: 1.15},
                                                            {text: '1.5', value: 1.5},
                                                            {text: '2', value: 2}
                                                        ],
                                                        fontSize:[1.0, 1.1, 1.2, 1.4, 1.6, 1.8, 2.0, 2.4, 2.8, 3.2, 3.6, 4.0, 4.4, 4.8, 5.4, 6.0, 6.6, 7.2, 8.0],
                                                        fontSizeUnit:'rem',
                                                        buttonList:[buttonLists],
                                                        resizingBar : false,
                                                        callBackSave : (contents)=>this.userSectionChange('textTitle', contents, idx)
                                                    }}
                                                    setContents={val.textTitle}
                                                    name="my-editor" />
                                                </div>
                                        </div>
                                        : val.type === 'textarea' ? 
                                        <div className="bookContFrame textareaFrame">
                                            <input
                                                type="text"
                                                name={catId}
                                                data-id={idx}
                                                id={catId}
                                                value={userSection[idx].textTitle} 
                                                className="textTitle"
                                                onChange={this.handleChange} 
                                                placeholder="질문을 작성해주세요(긴 답변 칸)"
                                            />
                                            <select name={catId} data-id={idx} value={userSection[idx].rowsLimit} className="rowsLimit" onChange={this.handleChange}>
                                                <option value='5'>5줄 입력 창</option>
                                                <option value='10'>10줄 입력 창</option>
                                                <option value='15'>15줄 입력 창</option>
                                                <option value='20'>20줄 입력 창</option>
                                            </select>
                                        </div>
                                        : val.type === 'image' ? 
                                        <div className="bookContFrame imageFrame">
                                            <div>
                                                <ImageUploadNR
                                                onUpdate={this.userSectionChange}
                                                itemName={'srcUrl'}
                                                fileName={'digitalbook/admin/'}
                                                idx={idx}
                                                />
                                                {/* <button onClick={()=>this.onDeleteimg(idx)}>이미지 삭제</button> */}
                                            </div>
                                            <div>
                                                <div>
                                                    <p>크기</p>
                                                    <input
                                                        type="number"
                                                        min='1'
                                                        max='100'
                                                        name={catId}
                                                        data-id={idx}
                                                        // id={catId}
                                                        value={userSection[idx].contentWidth >100 ? 100 : userSection[idx].contentWidth} 
                                                        className="contentWidth"
                                                        onChange={this.handleChange} 
                                                    />
                                                    <p>%</p>
                                                </div>
                                                <select name={catId} data-id={idx} value={userSection[idx].textJustify} className="textJustify" onChange={this.handleChange}>
                                                    <option value='flex-end'>{userSection[idx].textDirection ==='row' ? '오른쪽 정렬' : '왼쪽 정렬'}</option>
                                                    <option value='center'>중앙 정렬</option>
                                                    <option value='space-between'>중앙 띄우기</option>
                                                    <option value='space-around'>양쪽 띄우기</option>
                                                    <option value='flex-start'>{userSection[idx].textDirection ==='row' ? '왼쪽 정렬' : '오른쪽 정렬'}</option>
                                                </select>
                                                <select name={catId} data-id={idx} value={userSection[idx].textDirection} className="textDirection" onChange={this.handleChange}>
                                                    <option value='row'>그림-텍스트 순</option>
                                                    <option value='row-reverse'>텍스트-그림 순</option>
                                                </select>
                                            </div>
                                            <textarea
                                                placeholder='주석'
                                                name={catId}
                                                data-id={idx}
                                                // id={catId}
                                                value={userSection[idx].textarea.replace(/(<br>|<br\/>|<br \/>)/g, '\n')} 
                                                className="textarea"
                                                onChange={this.handleChange} 
                                            />
                                        </div>
                                        : val.type === 'video' ? 
                                        <div className="bookContFrame videoFrame">
                                            <div>
                                            <input type="text"
                                                    name={catId}
                                                    data-id={idx}
                                                    // id={catId}
                                                    value={userSection[idx].srcUrl2} 
                                                    className="srcUrl2"
                                                    onChange={this.handleChange}
                                                    placeholder="(360도 영상 제외)우선 재생 영상 URL"
                                                    />
                                                
                                                <input
                                                    type="number"
                                                    min='1'
                                                    max='100'
                                                    name={catId}
                                                    data-id={idx}
                                                    // id={catId}
                                                    value={userSection[idx].contentWidth >100 ? 100 : userSection[idx].contentWidth} 
                                                    className="contentWidth"
                                                    onChange={this.handleChange} 
                                                    />
                                                <span>%</span>
                                                <select name={catId} data-id={idx} value={userSection[idx].textJustify} className="textJustify" onChange={this.handleChange}>
                                                    <option value='flex-start'>왼쪽 정렬</option>
                                                    <option value='center'>중앙 정렬</option>
                                                    <option value='flex-end'>오른쪽 정렬</option>
                                                </select>
                                            </div>
                                            <div>
                                            <input
                                                    type="text"
                                                    name={catId}
                                                    data-id={idx}
                                                    // id={catId}
                                                    value={userSection[idx].srcUrl} 
                                                    className="srcUrl"
                                                    onChange={this.handleChange}
                                                    placeholder="360도 영상 URL (IOS 시청 X)"
                                                    />
                                            <input type='checkbox'
                                                    name={catId}
                                                    data-id={idx}
                                                    value={userSection[idx].isSafari} 
                                                    checked={userSection[idx].isSafari} 
                                                    className="isSafari"
                                                    onChange={this.handleChange} />
                                            </div>
                                            <p>※ 동영상 컨트롤 관련 설정은 영상 링크에 적용해 주세요.(FullScreen은 기본 제공)<br />(ex. https://player.vimeo.com/video/{'고유주소?autoplay=1&loop=1&muted=1'} <br />*주의 : autoplay의 경우 사용자의 디지털북 열람 시작부터 모든 영상이 시작 됩니다.)</p>
                                        {/* 유튜브는 임베드 URL로 올려야합니다.  https://www.youtube.com/embed/O32zT0ZqSCs */}
                                        </div>
                                        : val.type === 'emptySpace' ? 
                                        <div className="bookContFrame emptySpaceFrame">
                                            빈 공간입니다.
                                        </div>
                                        : val.type === 'drawingTool' ? 
                                        <div className="bookContFrame drawingToolFrame">
                                            <input
                                            type="text"
                                            name={catId}
                                            data-id={idx}
                                            // id={catId}
                                            value={userSection[idx].textTitle} 
                                            className="textTitle"
                                            onChange={this.handleChange}
                                            placeholder="질문을 작성해주세요(그리기 칸)"
                                            />
                                            <div>
                                                <span>드로잉 창 비율 (H/W)</span>
                                                <select name={catId} data-id={idx} value={userSection[idx].contentHeight} className="contentHeight" onChange={this.handleChange}>
                                                    <option value='15'>15%</option>
                                                    <option value='30'>30%</option>
                                                    <option value='50'>50%</option>
                                                    <option value='70'>70%</option>
                                                </select>
                                            </div>
                                        </div>
                                        :
                                        <div className="bookContFrame inputTextFrame">
                                            {/* <span>{`질문 #${idx + 1}`}</span> */}
                                            <input
                                                type="text"
                                                name={catId}
                                                data-id={idx}
                                                // id={catId}
                                                value={userSection[idx].textTitle} 
                                                className="textTitle"
                                                onChange={this.handleChange} 
                                                placeholder="질문을 작성해주세요(짧은 답변 칸)"
                                            />
                                            <div id={ageId} className="age">사용자 답변 작성 공간입니다</div>
                                        </div>
                                    }
                                    </React.Fragment>
                                    )
                                })
                                }
                            </div>
                            {/* </form> */}
                            
                        </div>
                        <DigitalbookPageList pageList={this.state.pageList} pageId={this.state.pageId}
                        onUpdate={this.onPropsChange}/>
                    <div className={`headingTextOutFrame ${showPageHeaderList ? 'showHeader' : 'hideHeader'}`}>
                        {<div 
                        className="headingTextFrame">
                            {pageList && pageList.map((page, idx) => {
                                return (
                                    <div 
                                    className={page.pageHeader === 'header1' ?   `heading1Bx`
                                    : page.pageHeader === 'header2' ? `heading2Bx`
                                    : page.pageHeader === 'header3' ?  `heading3Bx`
                                    : page.pageHeader === 'hidden' ?  `noHeadingBx`
                                    : `header5`}
                                    key={idx} style={idx === pageId ? { opacity: "1" } : { opacity: "0.5" }}
                                    >
                                        <span onClick={() => this.onPropsChange('pageId', idx)}
                                        className={page.pageHeader === 'header1' ?   `heading1`
                                        : page.pageHeader === 'header2' ? `heading2`
                                        : page.pageHeader === 'header3' ?  `heading3`
                                        : page.pageHeader === 'hidden' ?  `noHeading`
                                        : `header5`}
                                        >{page.pageTitle}</span>
                                        <div className="btnGroup">
                                            <span className="upBtn" onClick={() => this.onPageOrder('up', idx)}>↑</span>
                                            <span className="downBtn" onClick={() => this.onPageOrder('down', idx)}>↓</span>
                                            <span className="delBtn" onClick={() => pageListLeng > 1 ? this.onPageOrder('delete', idx) : alert('마지막 페이지 입니다.')}>×</span>
                                        </div>
                                    </div>
                                )
                            })
                            }
                        </div>}
                    </div>
                </div>
            </React.Fragment>
        )
    }
}
export default withFirebase(DigitalbookPageTool)
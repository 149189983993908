import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import * as ROUTES from '../../../constants/routes';
import * as ROLES from '../../../constants/roles';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../../Session';
import Digitalbook_PC_Admin from './Digitalbook_PC_Admin.jsx'
import Digitalbook_Mobile_Admin from './Digitalbook_Mobile_Admin.jsx'
import XRBOOKLogo from '../images/XRBOOKLogo.png'
import { getTimeString } from '../../helpers';
import './AdminCheck.css'

class AdminCheck extends Component {
    state={
        // bookData : this.props.location.state !== undefined ? this.props.location.state.bookData :{},
        // bookTitle  : this.props.location.state !== undefined ? this.props.location.state.bookTitle :'',
        // windowWidth: window.innerWidth,
        // pageId:0,
        // pcPageId:0,
        // titlebyAuth: this.props.location.state  !== undefined ? this.props.location.state.title : '',
        bookData :{},
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
        authUser: JSON.parse(localStorage.getItem('authUser')),
        pageId:0,
        pcPageId:0,
    }
    componentDidMount(){
        let bookId = this.props.match.params.bookId
        // console.log(bookId)
        this.onGetDigitalbook(bookId)

        window.addEventListener('resize', this.updateDimensions);

        // if(this.props.location.state  === undefined) {
        //     alert('유효하지 않은 접근입니다.')
        //     this.props.history.push(ROUTES.TLP_MAIN)  
        // } else 
        // if(this.props.location.state.bookData  === undefined){
        //     alert('북 데이터가 존재하지 않습니다.')
        //     this.setState({bookData : {}}, () => this.props.history.push(ROUTES.USER))   
        // }    
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }
    updateDimensions = () => {
        this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
      };
    componentDidUpdate(prevProps, prevState){
        let {pcPageId, pageId} = this.state
        if(prevState.pageId !== this.state.pageId){
            this.setState({
                // pageId:pageId,
                // pcPageId:parseInt(pageId/2),
                seenPageId: this.state.seenPageId < pageId ?  pageId : this.state.seenPageId
            })
        } else if(prevState.pcPageId !== this.state.pcPageId){
            //pcPageId +1? 체크
            this.setState({
                // pageId:pcPageId*2,
                // pcPageId:pcPageId,
                seenPageId: this.state.seenPageId < pcPageId*2 ?  pcPageId*2 : this.state.seenPageId
            })
        }
    }
    onPageListChange = (name, value) => {
        if(name === 'pageList'){
            this.setState(prevState => ({
                bookData: {
                    ...prevState.bookData,
                    pageList : value
                },
            }))
            
        } else if (name === "pageIdPC"){
            this.setState(prevState => ({
                // pcPageId: prevState.pcPageId+value,
                pageId:prevState.pageId+(value*2)
            }))
        } else if (name === "pageIdMobile"){
            this.setState(prevState => ({
                pageId: prevState.pageId+value,
                // pcPageId:parseInt((prevState.pageId+value)/2)
            }))
            // console.log(value)
        } else if (name === "pageId"){
            this.setState({
                pageId: value
            })
        } else {
            this.setState({
                [name]:value
            })
        }
        }
        onGetDigitalbook = (bookId) => {
                this.props.firebase.mybook(bookId).get()
                .then((res)=>{
                    let result = res.data()
                    this.setState(result)
                   })
                }
    render() {
        let {bookData, pageId,pcPageId, windowWidth, codeUserName} = this.state
        // let pageLeng = bookData.pageList.length
        return (
            <div className="authReaderFrame">
                <div className="bookTop">
                    <div className="booInfoGroup">
                        <div className="iconFrame">
                            <img src={bookData.iconUrl || XRBOOKLogo } alt='icon'/>
                        </div>
                        <span>{bookData.bookTitle}{codeUserName ? ` - 저자 : ${codeUserName}`:null}</span>
                    </div>
                    <div className="linkGroup">
                        <div onClick={(e)=>window.close()}>뒤로가기</div>
                    </div>
                </div>
                 {windowWidth >834 ? 
                <Digitalbook_PC_Admin
                 digitalbook={bookData} onPageListChange={this.onPageListChange}
                 pageId={pageId} pcPageId={pcPageId} windowWidth={windowWidth}/>
                :               
                <Digitalbook_Mobile_Admin
                 digitalbook={bookData} onPageListChange={this.onPageListChange}
                 pageId={pageId}windowWidth={windowWidth}
                 />
                 }
                 <div className="bookBottom">
                <span onClick={(e)=>window.close()} >창 닫기</span> 
                </div>
            </div>
        )
    }
}


const condition = authUser => authUser
&& authUser.roles[ROLES.MEMBER] === ROLES.ADMIN;

export default compose(
    withEmailVerification,
    withAuthorization(condition),
  )(AdminCheck);
import React, { Component } from 'react'
import { withFirebase } from '../../../Firebase'
import {getTimeDayjs } from '../../../helpers'
import dayjs from 'dayjs'
import chap1img1 from '../../../images/GalleryChapter1Image1.png'
import chap1img2 from '../../../images/GalleryChapter1Image2.png'
import chap1img3 from '../../../images/GalleryChapter1Image3.png'
import chap1img4 from '../../../images/GalleryChapter1Image4.png'
import chap1img5 from '../../../images/GalleryChapter1Image5.png'
import chap1img6 from '../../../images/GalleryChapter1Image6.png'
import chap1img7 from '../../../images/GalleryChapter1Image7.png'
import chap1img8 from '../../../images/GalleryChapter1Image8.png'
import chap2img1 from '../../../images/GalleryChapter2Image1.png'
import chap2img2 from '../../../images/GalleryChapter2Image2.png'
import chap2img3 from '../../../images/GalleryChapter2Image3.png'
import chap2img4 from '../../../images/GalleryChapter2Image4.png'
import chap2img5 from '../../../images/GalleryChapter2Image5.png'
import chap2img6 from '../../../images/GalleryChapter2Image6.png'
import chap2img7 from '../../../images/GalleryChapter2Image7.png'
import chap2img8 from '../../../images/GalleryChapter2Image8.png'
import chap3img1 from '../../../images/GalleryChapter3Image1.png'
import chap3img2 from '../../../images/GalleryChapter3Image2.png'
import chap3img3 from '../../../images/GalleryChapter3Image3.png'
import chap3img4 from '../../../images/GalleryChapter3Image4.png'
import chap3img5 from '../../../images/GalleryChapter3Image5.png'
import chap3img6 from '../../../images/GalleryChapter3Image6.png'
import chap3img7 from '../../../images/GalleryChapter3Image7.png'
import chap3img8 from '../../../images/GalleryChapter3Image8.png'
import chap4img1 from '../../../images/GalleryChapter4Image1.png'
import chap4img2 from '../../../images/GalleryChapter4Image2.png'
import chap4img3 from '../../../images/GalleryChapter4Image3.png'
import chap4img4 from '../../../images/GalleryChapter4Image4.png'
import chap4img5 from '../../../images/GalleryChapter4Image5.png'
import chap4img6 from '../../../images/GalleryChapter4Image6.png'
import chap4img7 from '../../../images/GalleryChapter4Image7.png'
import chap4img8 from '../../../images/GalleryChapter4Image8.png'
import chap5img1 from '../../../images/GalleryChapter5Image1.png'
import chap5img2 from '../../../images/GalleryChapter5Image2.png'
import chap5img3 from '../../../images/GalleryChapter5Image3.png'
import chap5img4 from '../../../images/GalleryChapter5Image4.png'
import chap5img5 from '../../../images/GalleryChapter5Image5.png'
import chap5img6 from '../../../images/GalleryChapter5Image6.png'
import chap5img7 from '../../../images/GalleryChapter5Image7.png'
import chap5img8 from '../../../images/GalleryChapter5Image8.png'
import chap6img1 from '../../../images/GalleryChapter6Image1.jpg'
import chap6img2 from '../../../images/GalleryChapter6Image2.jpg'
import chap6img3 from '../../../images/GalleryChapter6Image3.jpg'
import chap6img4 from '../../../images/GalleryChapter6Image4.jpg'
import chap6img5 from '../../../images/GalleryChapter6Image5.jpg'
import chap6img6 from '../../../images/GalleryChapter6Image6.jpg'
import chap6img7 from '../../../images/GalleryChapter6Image7.jpg'
import chap6img8 from '../../../images/GalleryChapter6Image8.jpg'

class GalleryItem extends Component {
    state = {
        chapter : this.props.chapter,
        exImageList : [
            [
             chap1img5,chap1img8,chap1img1,chap1img4,chap1img3,chap1img6,chap1img2,chap1img7
           ],
           [
             chap2img5,chap2img6,chap2img3,chap2img7,chap2img8,chap2img2,chap2img1,chap2img4
           ],
           [
             chap3img6,chap3img4,chap3img1,chap3img2,chap3img5,chap3img3,chap3img7,chap3img8
           ]
           ,
           [
             chap4img4,chap4img2,chap4img3,chap4img1,chap4img5,chap4img6,chap4img7,chap4img8
           ],
           // 에필로그 이미지 예시 추가
           [
             chap5img4,chap5img2,chap5img3,chap5img1,chap5img5,chap5img6,chap5img7,chap5img8
           ],
           // 교육사진 예시 추가
           [
             chap6img4,chap6img2,chap6img3,chap6img1,chap6img5,chap6img6,chap6img7,chap6img8
           ]
           
        ]
    }
    render() {
        let {exImageList} = this.state
        let {item, deleteGalleyItem, idx, modalOpen, chapter} = this.props
        let {sharingPageId, type, page, authUserName, authUserEmail, createdAt
        } = item
        let imgChap = this.props.chapter ? this.props.chapter - 1 : 0
     var isCreatedAtFormatted2 = getTimeDayjs(createdAt)
        return (
          // 디지털북 공유 || chp 5 양그리기 (-1) || 강의 사진
          <>{type === 'digitalbook' || imgChap == 4 || imgChap == 5 ? 
               <span className={`gallery${idx}`}
               style={{backgroundImage : `url(${exImageList[imgChap][idx]})`}}
               onClick={()=>modalOpen(idx, true, type, chapter, authUserName, authUserEmail)}>
                </span>
                :
                <span className={`gallery${idx}`}
              //  style={{backgroundImage : `url(${exImageList[imgChap][idx]})`}}
              style={{display:'flex', justifyContent:'center'}}
               onClick={()=>modalOpen(idx, true, type, chapter, authUserName, authUserEmail)}>
                 <img src={page} height='100%'/>
                </span>
                }
          </>
        )
    }
}
export default withFirebase(GalleryItem)
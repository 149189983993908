import React, { Component, useState } from 'react'
import { withFirebase } from '../../Firebase'
import { faPaintBrush, faEraser, faPlus, faMinus, faSave, faHandPointLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

//simple draw component made in react
class DrawCoverApp extends Component {

    constructor(props) {
        super(props)
    }
    state = {
        bgColor : this.props.bgColor || 'transparent',
        canvas : this.refs.canvas,
        content:this.props.contents,
        contentHeight: this.props.contentHeight,
        drawingWidth: 0, drawingHeight: 0,
        resizedWidth:window.innerWidth * 0.377,
        resizedHeight: window.innerWidth * 0.377 * (this.props.contentHeight || 30) /100,
        canvasHeight: window.innerWidth * 0.377 * (this.props.contentHeight || 30) /100,
        windowHeight: window.innerHeight

    }
    componentDidMount() {
        this.reset()
        window.addEventListener('resize', this.updateDimensions);
        let tempImage = new Image();
            tempImage.src = this.props.contents
            tempImage.crossOrigin= 'anonymous'
            tempImage.addEventListener('load', () => {
                this.ctx.drawImage(tempImage, 0, 0, this.state.resizedWidth, this.state.resizedHeight);
            })
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.contentHeight !== this.props.contentHeight){
            this.updateDimensions()
        }
        
        if(prevProps.bgColor !== this.props.bgColor){
            this.setState({
                        bgColor:this.props.bgColor,
                    })
            }
        if(prevProps.contents !== this.props.contents){
            this.reset()
            this.setState({
                content:this.props.contents,
            })
            let tempImage = new Image();
            tempImage.src = this.props.contents
            tempImage.crossOrigin= 'anonymous'
            tempImage.addEventListener('load', () => {
                this.ctx.drawImage(tempImage, 0, 0, this.state.resizedWidth, this.state.resizedHeight);
            })
            
        }
        if(prevState.canvasWidth !== this.state.canvasWidth){
            let tempImage = new Image();
            tempImage.src = this.props.contents
            tempImage.crossOrigin= 'anonymous'
            tempImage.addEventListener('load', () => {
                this.ctx.drawImage(tempImage, 0, 0, this.state.resizedWidth, this.state.resizedHeight);
            })
                }
        if(prevState.canvasHeight !== this.state.canvasHeight){
            let tempImage = new Image();
            tempImage.src = this.props.contents
            tempImage.crossOrigin= 'anonymous'
            tempImage.addEventListener('load', () => {
                this.ctx.drawImage(tempImage, 0, 0, this.state.resizedWidth, this.state.resizedHeight);
            })
                }
        if(prevState.windowHeight !== this.state.windowHeight){
            let tempImage = new Image();
            tempImage.src = this.props.contents
            tempImage.crossOrigin= 'anonymous'
            tempImage.addEventListener('load', () => {
                this.ctx.drawImage(tempImage, 0, 0, this.state.resizedWidth, this.state.resizedHeight);
            })
                }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }
      updateDimensions = () => {
        let pageWidth =
        window.innerWidth >1600 ? 535
        : window.innerWidth <=1600 && window.innerWidth >1400 ? window.innerWidth * 0.324
        : window.innerWidth <=1400 && window.innerWidth >1024 ? window.innerWidth * 0.377
        : window.innerWidth <=1024 && window.innerWidth >834 ? window.innerWidth * 0.405
        : window.innerWidth <=834  ? window.innerWidth * 0.82
        : window.innerWidth* 0.82
        let elementWidth = document.getElementsByClassName('drawBGBx')[0]
        let canvasWidth = pageWidth
        this.ctx.canvas.width = canvasWidth
       let canvasHeight = canvasWidth * (this.props.contentHeight || 30) /100
       this.ctx.canvas.height = canvasHeight
       let resizedWidth = canvasWidth
       let resizedHeight = canvasHeight
       this.setState({canvasWidth, canvasHeight, resizedWidth, resizedHeight, windowHeight : window.innerHeight})
      }
    
    reset() { //clears it to all white, resets state to original
        
        this.ctx = this.refs.canvas.getContext('2d')
        this.ctx.fillStyle="transparent"
        this.ctx.fillRect(0,0,800,600)
        this.ctx.lineWidth = 10
        this.updateDimensions()
    }
    
    render() {
        let {bgColor, canvasHeight} = this.state
        let itemHeight = canvasHeight * (this.props.contentHeight || 30) /100
                return (
            <div className="drawFrame" >
                    <div className=" drawPaperCoverBx" style={{height:`${canvasHeight}px`}}>
                    <div className="drawBGBx" style={{backgroundColor : `${bgColor}`}}></div>
                    <canvas id = 'resetCanvas' ref="canvas"
                    // style={{border:'2px solid red'}}
                    >
                    </canvas>
                </div>
            </div>
        )
    }
}
export default withFirebase(DrawCoverApp)
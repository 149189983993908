import React, { Component } from 'react';
import VideoItem from './VideoItem'
import * as ROUTES from '../../constants/routes'
import './index.css'

class VideoList extends Component {
    state = {
        prologueList : [
            //1 어린이 2 청소년 3 성인
            { name : 'Prologue[어린이용]', url:'470478746'},
            { name : 'Prologue[청소년용]', url:'470478965'},
            { name : 'Prologue[성인용]', url:'464900219'},
        ],
        // 360
        chapter360List : [
            { name : 'Chapter1-360', url:'477915949'},
            { name : 'Chapter2-360', url:'477919228'},
            { name : 'Chapter3-360', url:'477924295'},
            { name : 'Chapter4-360', url:'477927991'},
        ],
        chapterList : [
            { name : 'Chapter1', url:'478339417'},
            { name : 'Chapter2', url:'478341890'},
            { name : 'Chapter3', url:'478343024'},
            { name : 'Chapter4', url:'478345853'},
        ],
        epilogueList : [
            { name : 'Epilogue[어린이용]', url:'470478287'},
            { name : 'Epilogue[청소년용]', url:'470478510'},
            { name : 'Epilogue[성인용]', url:'462870299'}
        ]
    }
    render() {
        let {prologueList, chapter360List, chapterList, epilogueList} = this.state
        let authUser= localStorage.getItem('authUser') ? JSON.parse(localStorage.getItem('authUser')).roles['member'] : 'member'
        let marginTop  = this.props.marginTop || false
        return (
            <div style={{marginBottom : '5vh'}}>
                <div className="facilitatorVideoOutFrame" style={marginTop ? {marginTop:'7vh'} : {}}>
                {prologueList.map((c, id)=> {           
                    return (
                        <div  className='facilitatorVideoBlock' key={id} style={{padding:'1vw 0'}}>
                            { /* <Link to={{pathname: `${ROUTES.USER}`, state: {tab : c.url}}}><div>{c.name}</div></Link>  */}
                            <VideoItem url={c.url} name={c.name}/>
                        </div>
                    )
                })}
                </div>
                <div className="facilitatorVideoOutFrame">
                {authUser==='admin' && chapter360List.map((c, id)=> {           
                    return (
                        <div  className='facilitatorVideoBlock' key={id} style={{padding:'1vw 0'}}>  
                            <VideoItem url={c.url} name={c.name}/>
                        </div>
                    )
                })}
                </div>
                <div className="facilitatorVideoOutFrame">
                {chapterList.map((c, id)=> {           
                    return (
                        <div  className='facilitatorVideoBlock' key={id} style={{padding:'1vw 0'}}>
                            <VideoItem url={c.url} name={c.name}/>
                        </div>
                    )
                })}
                </div>
                <div className="facilitatorVideoOutFrame">
                {epilogueList.map((c, id)=> {           
                    return (
                        <div  className='facilitatorVideoBlock' key={id} style={{padding:'1vw 0'}}>
                            <VideoItem url={c.url} name={c.name}/>
                        </div>
                    )
                })}
                </div>
            </div>
        );
    }
}

export default VideoList;
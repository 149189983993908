import React, { Component } from 'react'
import XRBOOKLogo from '../../images/XRBOOKLogo.png'
import { getTimeString } from '../../helpers'
import DaumPostcode from 'react-daum-postcode';
import {withRouter} from 'react-router-dom'
import * as ROUTES from '../../../constants/routes';
import { compose } from 'recompose';
import { withFirebase } from '../../Firebase'
import { TwitterPicker} from 'react-color';
import bookCoverEx1 from '../../images/cover1.png'
import bookCoverEx2 from '../../images/cover2.png'
import bookCoverEx3 from '../../images/cover3.png'
import bookCoverEx4 from '../../images/cover4.png'
import bookCoverEx5 from '../../images/cover5.png'
import bookCoverEx6 from '../../images/cover6.png'
import bookCoverEx7 from '../../images/cover7.png'
import bookCoverEx8 from '../../images/cover8.png'
import bookCoverEx9 from '../../images/cover9.png'

import DrawCoverApp from '../Utils/DrawCoverApp'
import SunEditor from 'suneditor-react';
import '../UserCover.css'
import '../../../constants/suneditor.min.css'

class NonUserCover extends Component {
    state = {
        bookData : this.props.location.state !== undefined ? this.props.location.state.bookData :{},
        bookTitle : this.props.location.state !== undefined ? this.props.location.state.bookTitle :"",
        mybookId : this.props.location.state !== undefined ? this.props.location.state.mybookId :"",
        username : this.props.location.state !== undefined ? this.props.location.state.username :"",
        target : this.props.location.state !== undefined ? this.props.location.state.target :"",
        programId : this.props.location.state !== undefined ? this.props.location.state.programId :"",
    
        drawingList: [],
        pageBgImgColor: 'white',
        coverImg:'',
        coverColor:'transparent',
        contentHeight: 30,
        shipmentModal:false,
        isAddressOpen:false,
        postCode: '',
        fullAddress: '',
        extraAddress: '',
        pageItem :{userSection:[], pageTitle:'', pageHeader:'hidden',pageBgImgUrl:'',pageBgImgColor:'white'},
        userSection:[]
    }
    componentDidMount(){
        if(this.props.location.state  === undefined) {
            alert('유효하지 않은 접근입니다.')
            this.setState(() => this.props.history.push(ROUTES.TLP_MAIN))
        }
        // console.log(this.props.location.state)
        this.props.firebase.nonAuthReader(this.props.location.state.mybookId).get()
            .then(res => {
                let result = res.data()
                let {bookData, publishedCover} = result
                this.setState({bookData, publishedCover})
                if(publishedCover){
                    this.setState({
                        pageItem: publishedCover,
                        userSection : publishedCover.userSection
                    })
                    this.props.history.push({pathname: `${ROUTES.USER}`, state: {tab : 'library'}})
                } else {
                    this.getCover()
                }
            })
        let drawingList = []
        let pageList = this.props.location.state.bookData.pageList || this.state.bookData.pageList
        for(let i in pageList){
            for(let j in pageList[i].userSection){
                if (pageList[i].userSection[j].type==="drawingTool"){
                    drawingList.push(pageList[i].userSection[j])
                }
            }
        }
        this.setState({
            drawingList
        })
        // this.getCover()
    }
    componentDidUpdate(prevProps, prevState){
        if(prevState.userSection !=='' && prevState.userSection !==this.state.userSection){
            this.setState(prevState => ({
                pageItem: {
                    ...prevState.pageItem,
                    userSection : this.state.userSection
                },
            }))
        }
    }
    onPropsChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }
    
    getCover=()=>{
        this.props.firebase.library('uyPQga26IH7Xm89yHfhK').get()
            .then((res)=>{
                let coverPage = res.data()
                this.setState({
                    pageItem: coverPage.pageList[0],
                    userSection : coverPage.pageList[0].userSection
                })
            })
    }
    onPageChange = (pageBgImgUrl, pageBgImgColor) => {
        let {userSection} = this.state
        let drawingSection = userSection.filter(c => c.type ==='drawingTool')
        let index = userSection.indexOf(drawingSection[0])
        let tempSection = userSection.slice()
        tempSection[index] = {textTitle:"", type:'drawingTool', writingValue:'',imgUrl:'', contentHeight:"30", bgColor:'transparent' }

        this.setState(prevState => ({
            pageItem: {
                ...prevState.pageItem,
                pageBgImgUrl, pageBgImgColor
            },
            userSection : tempSection
        }))
    }
    onuserSectionChange = (value, pageBgImgColor ) => {
        let {userSection} = this.state
        let drawingSection = userSection.filter(c => c.type ==='drawingTool')
        let index = userSection.indexOf(drawingSection[0])
        let tempSection = userSection.slice()
        tempSection[index] = value
        this.setState(prevState => ({
            pageItem: {
                ...prevState.pageItem,
                pageBgImgColor,
                pageBgImgUrl:''
            },
            userSection : tempSection
        }))
    }
    onuserSectionColorChange = (color, event) => {
        let {userSection, fontColor} = this.state
        let textSection = userSection.filter(c => c.type ==="textEditor")
        let index = userSection.indexOf(textSection[0])
        let tempSection = userSection.slice()
        if(tempSection[index].textTitle.indexOf('<p style="color') < 0){
            tempSection[index].textTitle = tempSection[index].textTitle.replaceAll(`<p style="`,`<p style="color:${color.hex}; `)
        } else {
            tempSection[index].textTitle = tempSection[index].textTitle.replaceAll(`<p style="color:${fontColor}; `,`<p style="color:${color.hex}; `)
        }
        
        event.preventDefault();

        this.setState(prevState => ({
            pageItem: {
                ...prevState.pageItem
            },
            userSection : tempSection,
            fontColor : color.hex
        }))
    }
    onSaveCover = () => {
        let { bookData, pageItem, mybookId } = this.state;
        let pageList = bookData.pageList.slice()
        let writingValueArray = []
        for(let i in pageItem.userSection){
            if(pageItem !==[] && pageItem.userSection[i].writingValue !== undefined){
             writingValueArray.push(pageItem.userSection[i].writingValue)
            }  
         }
        let isNotEmpty = (writingValue) => writingValue !==''
        let isValid = writingValueArray.every(isNotEmpty)
    if(isValid){
        pageList.unshift({userSection:[], pageTitle:'', pageHeader:'hidden',pageBgImgUrl:'',pageBgImgColor:'white'})
        pageList.unshift(pageItem)
        
        this.props.firebase.nonAuthReader(mybookId).update({
            "bookData.pageList": pageList,
            publishedCover:pageItem,
        }).then(res => this.props.history.push({pathname: `${ROUTES.XRBOOK_NONAUTH}`}))
        .catch(err => console.log(err))
    } else {
        alert('표지를 선택하세요.')
    }
    }
    colorChange = (color, event) => {
        event.preventDefault();
        // console.log(color, event)
        this.onPageChange('', color.hex)
      }
    render() {
        let {bookData, bookTitle, target, username, coverColor, coverImg, isAddressOpen, shipmentModal, drawingList, contentHeight,
            pageItem} = this.state
            //  console.log(pageItem)
             //{textTitle:"", type:'drawingTool', writingValue:'',imgUrl:'', contentHeight:"30", bgColor:'white' }
        return (
            <div className="userCoverFrame">
                <div className="bookInfoGroup">
                    <div className="iconFrame">
                        <img src={bookData.iconUrl || XRBOOKLogo } alt='icon'/>
                    </div>
                    <span>{`${bookData.bookTitle} (${target} 대상) - 저자 : ${username}`}</span>
                </div>
                {/* 크기조절해주세요. */}
                <div className="bookCoverGroup">
                    <div className="coverApplyFrame">
                            {pageItem !== undefined &&
                                <div  style={{  height:'100%', width:'100%',
                                                backgroundSize : 'cover, cover',
                                                backgroundRepeat : 'no-repeat, no-repeat',
                                                backgroundPosition : 'bottom, right bottom',
                                                backgroundImage: "url("+pageItem.pageBgImgUrl+")",
                                                backgroundColor : pageItem.pageBgImgColor}}>
                                {pageItem !== undefined &&
                                    <div className="makingPaperFrame" style={{
                                        height: '100%', width: '100%',
                                        backgroundSize: 'cover, cover',
                                        backgroundRepeat: 'no-repeat, no-repeat',
                                        backgroundPosition: 'bottom, right bottom',
                                        backgroundImage: "url(" + pageItem.pageBgImgUrl + ")",
                                        backgroundColor: pageItem.pageBgImgColor }}>
                                {pageItem.userSection.map((val, idx) => {
                                let catId = `cat-${idx}`, ageId = `age-${idx}`
                                return (
                                <div key={idx}>
                                    {val.type === 'inputText' ?
                                        <div className="bookContFrame inputTextFrame">
                                            <p id={ageId}>{val.textTitle}</p>
                                            {val.writingValue}
                                        </div>
                                        : val.type === 'textarea' ?
                                            <div className="bookContFrame textareaFrame">
                                                <p id={ageId}>{val.textTitle}</p>
                                                {val.writingValue.replace(/<br\s*[\/]?>/gi, `\n`)}
                                                </div> :
                                            val.type === 'drawingTool' ?
                                                <div className="bookContFrame drawingToolFrame">
                                                    <p id={ageId}>{val.textTitle}</p>
                                                    <DrawCoverApp bgColor={val.bgColor} contents={val.writingValue} contentHeight={val.contentHeight}/>
                                                </div>
                                                : val.type === 'textEditor' ?
                                                    <div className="bookContFrame textEditorFrame" style={{ backgroundColor: val.editorBgColor }}>
                                                        <SunEditor lang="ko" height='auto' setOptions={{ 
                                                            resizingBar: false,
                                                            defaultStyle:'font-size:1.6rem',
                                                        }}
                                                            showToolbar={false} disable={true} setContents={val.textTitle} />
                                                        
                                                    </div>
                                                    : val.type === 'image' ?
                                                        <div className="bookContFrame imageFrame">
                                                            <React.Fragment>
                                                                {val.srcUrl != '' ?
                                                                    <div style={{
                                                                        width: '100%', display: 'flex', flexDirection: `${val.textDirection}`,
                                                                        justifyContent: `${val.textJustify}`, alignItems: 'center'
                                                                    }}>

                                                                        <img src={val.srcUrl} alt={`image${idx}`} width={`${val.contentWidth}%`} />
                                                                        <span>{val.textarea && val.textarea.split('<br />').map((c, id) => {
                                                                            return (
                                                                                <span key={id}>{c}<br /></span>
                                                                            )
                                                                        })}
                                                                        </span>
                                                                    </div>
                                                                    : null}
                                                            </React.Fragment>
                                                            
                                                        </div>
                                                        : val.type === 'video' ?
                                                            <div className="bookContFrame videoFrame" style={{ 
                                                                // border:'1px solid gray',
                                                                justifyContent: `${val.textJustify}`, height: `${val.contentWidth / 5}vw`
                                                            }}>
                                                                {val.srcUrl ?
                                                                    <div style={{ width: `${val.contentWidth}%` }}>
                                                                        <iframe src={val.srcUrl} style={{ width: `100%`, height: `100%`, border: 'none' }} />
                                                                    </div>
                                                                    : null}
                                                                
                                                            </div>
                                                            : val.type === 'emptySpace' ?
                                                                <div className="bookContFrame emptySpaceFrame" >
                                                                    <div></div>
                                                                    
                                                                </div>
                                                                : <span>none</span>}
                                                        </div>
                                                    )
                                                })
                                            }
                                    </div>}
                                </div>}
                    </div>
                    <div className="coverSelFrame">
                        <h5>내가 만든 XR BOOK, 표지에 사용할 이미지를 선택해 주세요.</h5>
                        <p>각 디바이스 사이즈가 다르기 때문에,  표지 일러스트 이미지가 크기 및 상하좌우 공백에 정도의 차이가 있을 수 있습니다. 
                            인쇄 시 표지 이미지는 실물 책 사이즈에 맞춰 제작 될 것입니다. 
                            실물과 가장 유사한 사이즈를 확인 하고자 할 경우 PC를 이용하는 것을 추천드립니다.
                        </p>
                        <h6>- 기본제공 일러스트</h6>
                        <ul className="coverIllust">
                            <li onClick={()=>this.onPageChange(bookCoverEx1, 'white')}><img src={bookCoverEx1} alt='icon'/></li>
                            <li onClick={()=>this.onPageChange(bookCoverEx2, 'white')}><img src={bookCoverEx2} alt='icon'/></li>
                            <li onClick={()=>this.onPageChange(bookCoverEx3, 'white')}><img src={bookCoverEx3} alt='icon'/></li>
                            <li onClick={()=>this.onPageChange(bookCoverEx4, 'white')}><img src={bookCoverEx4} alt='icon'/></li>
                            <li onClick={()=>this.onPageChange(bookCoverEx5, 'white')}><img src={bookCoverEx5} alt='icon'/></li>
                            <li onClick={()=>this.onPageChange(bookCoverEx6, 'white')}><img src={bookCoverEx6} alt='icon'/></li>
                            <li onClick={()=>this.onPageChange(bookCoverEx7, 'white')}><img src={bookCoverEx7} alt='icon'/></li>
                            <li onClick={()=>this.onPageChange(bookCoverEx8, 'white')}><img src={bookCoverEx8} alt='icon'/></li>
                            <li onClick={()=>this.onPageChange(bookCoverEx9, 'white')}><img src={bookCoverEx9} alt='icon'/></li>
                        </ul>
                        <h6>- 내가 그린 그림</h6>
                        <ul className="coverIllust">
                            {drawingList && drawingList.map((c, id)=>{
                                let tempImage = new Image();
                                tempImage.src = c.writingValue || XRBOOKLogo
                                tempImage.crossOrigin= 'anonymous'
                                let canvasId = document.getElementById(`canvas${id}`)
                                let ctx = canvasId ? canvasId.getContext('2d') : null
                                // console.log(ctx)
                                // console.log(tempImage)
                                tempImage.addEventListener('load', () => {
                                    // console.log('draw')
                                    if(ctx) ctx.drawImage(tempImage, 0, 0)
                                })
                                // let drawingSet = 
                                return(
                                    <li key={id} onClick={()=>this.onuserSectionChange(
                                        {textTitle:"", type:'drawingTool', writingValue:c.writingValue,imgUrl:'', contentHeight:c.contentHeight, bgColor:c.bgColor},
                                        c.bgColor
                                        )}>
                                        <DrawCoverApp bgColor={c.bgColor} contents={c.writingValue} contentHeight={c.contentHeight}/>
                                    </li>
                                )
                            })}
                        </ul>
                        <h6>- 표지 타이틀 색상</h6>
                        <div className="coverTitleColor">
                            <TwitterPicker
                                triangle ={'hide'}
                                // colors = {['#ffffff', '#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF'] }
                                colors = {['#ffffff', '#ffe2c3', '#fff9c3', '#c7ffd5', '#c7daff', '#e5c7ff', '#ffbdcc', '#ABB8C3'] }
                                // color = {pageBgImgColor}
                                width ={'100%'}
                                onChange={ this.onuserSectionColorChange }  
                            />
                        </div>
                        <h6>- 표지 배경 색상</h6>
                        <div className="coverBgColor">
                            <TwitterPicker
                                triangle ={'hide'}
                                // colors = {['#ffffff', '#FF6900', '#FCB900', '#7BDCB5', '#00D084', '#8ED1FC', '#0693E3', '#ABB8C3', '#EB144C', '#F78DA7', '#9900EF'] }
                                colors = {['#ffffff', '#ffe2c3', '#fff9c3', '#c7ffd5', '#c7daff', '#e5c7ff', '#ffbdcc', '#ABB8C3'] }
                                // color = {pageBgImgColor}
                                width ={'100%'}
                                onChange={ this.colorChange }
                            />
                        </div>
                        <ul className="coverAddress">
                            <li><span className="coverSelBtn" onClick={()=>this.onSaveCover()}>표지 선택 완료</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
export default compose(
    withRouter,
    withFirebase)(NonUserCover);
import React, { Component } from 'react';
import {getTimeString, getTimeDayjs} from '../../helpers'
import PageAdminItem from '../BookItem/PageAdminItem'
// import {sendTempMsg} from '../Utils/sendTempMsg'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import '../DObook.css'
import '../DObookResp.css'
import $ from "jquery";
import Spinner from '../../Utils/Spinner';

class Digitalbook_PC_Admin extends Component {
    state={
        // pageList: [],
        pageListFront:[],
        pageListBack:[],
        pageHeaderList: [],
        bookmarkList : [],
        pageId:0,
        pcPageId:0,
        lastPageId: 0,
        prevBtn : 'invalidBtn',
        nextBtn : 'invalidBtn',
        invalidFrontBtn:false,
        invalidBackBtn:false,
        isPageHeader : false,
        isBookmark : false
    }
    componentDidMount = ()=> {
        this.setState({
            pageList : this.props.digitalbook.pageList,
            pageId : this.props.pageId,
        }
        )
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.digitalbook !== this.props.digitalbook){
            this.setState({
                pageList : this.props.digitalbook.pageList
            })
        }
        if(prevState.pageList !== this.state.pageList){
            
        let pageListFront = this.state.pageList.filter((c,id)=>id%2===0)
        let pageListBack = this.state.pageList.filter((c,id)=>id%2===1)
        let pageHeaderList = this.state.pageList.filter(c=> c.pageHeader !== 'hidden')
        let bookmarkList = this.state.pageList.filter(c=> c.bookmark === true)
            if(pageListBack.length === pageListFront.length){
                pageListBack.push({userSection:[], pageTitle:'', pageHeader:'hidden',pageBgImgUrl:'',pageBgImgColor:'white'})
            }
            this.setState({
                pageListFront, pageListBack, pageHeaderList, bookmarkList
            },()=> this.onTextToPage(this.props.pageId))

        this.props.onPageListChange('pageList', this.state.pageList)
        }
    if(prevProps.lastPageId !== this.props.lastPageId){
        this.setState({
            lastPageId:this.props.lastPageId
        })
    } 
    if(prevProps.pageId !== this.props.pageId){
        let id = this.props.pageId
        this.setState({
            pageId : id,
        }
        , () =>
        this.onTextToPage(id)
        )
        
    }  
    if(this.state.lastPageId !== 0 && prevState.lastPageId !== this.state.lastPageId
        && this.state.lastPageId < this.state.pageListFront.length){
        let id = this.state.lastPageId
    }
}
  onPageChange = type => {
    $(".headingTextBox").removeClass("showHeading");
    $(".bookMarkbox").removeClass("show").addClass("hidden");
    let {pageId, pageList, pcPageId}=this.state
    let pcId = parseInt((pageId+1)/2)
    let halfLeng = parseInt((pageList.length+1)/2)
    if (type ==='prev' && pcId >0){
        this.props.onPageListChange('pageIdPC',-1)
        //이전페이지 함수 추가
    } else if (type ==='next' && pcId < halfLeng) {
        this.props.onPageListChange('pageIdPC',1)
    }
}

sendTempMsg = (type) => {
    return () => {
      switch (type) {
        case 'copied':
            NotificationManager.info(' 텍스트가 복사되었습니다.', 'COPIED', 1500, () => {
                alert('callback');
              });
          break;
        case 'copiedBG':
          NotificationManager.info('배경색이 복사되었습니다.','COPIED',  1500, () => {
            alert('callback');
          });
          break;
      }
    };
};
onPropsChange = (name, value) => {
this.setState({
    [name] : value
})
}
onTextToPage = (id) => {
    $(".headingTextBox").removeClass("showHeading");
    $(".bookMarkbox").removeClass("show").addClass("hidden");
    $(".bookMarkList").removeClass("listShow");
    // $(".bookPagePC").hide();
    // $(".bookPageGroup").find(".ready").show();
    // $(".bookPageGroup").find(".flipped").last().show();
    // $(".bookPageGroup").find(".flipped").last().prev(".flipped").show();
    // $(".bookPageGroup").find(".active").show();
    if(id%2 === 0){
        let lastPage=parseInt((id)/2)-1
        if(parseInt((id)/2) >= parseInt(this.state.pageList.length/2)){
            $('.bookPagePC').removeClass('ready flipped active pointerEventsNone pageShow');
            $(".bookPagePC"+parseInt((id)/2)).addClass("active");
            $(".bookPagePC"+lastPage).prevAll().addClass("flipped pointerEventsNone");
            $(".bookPagePC"+lastPage).addClass("flipped");
            $(".bookPagePC"+lastPage).prev().addClass("pageShow");
        } else {
            $('.bookPagePC').removeClass('ready flipped active pointerEventsNone pageShow');
            $(".bookPagePC"+parseInt((id)/2)).addClass("active");
            $(".bookPagePC"+parseInt((id)/2)).prevAll().addClass("flipped pointerEventsNone");
            $(".bookPagePC"+lastPage).prevAll().addClass("flipped pointerEventsNone");
            $(".bookPagePC"+lastPage).removeClass("pointerEventsNone");
            $(".bookPagePC"+lastPage).addClass("flipped");
            $(".bookPagePC"+lastPage).prev().addClass("pageShow");
            $(".bookPagePC"+parseInt((id)/2)).next().addClass("ready");
        }
    } else {  
        let lastPage=parseInt((id+1)/2)-1
        if(parseInt((id+1)/2) === parseInt(this.state.pageList.length/2)){
            $('.bookPagePC').removeClass('ready flipped active pointerEventsNone pageShow');
            $(".bookPagePC"+lastPage).prevAll().addClass("flipped pointerEventsNone");
            $(".bookPagePC"+lastPage).addClass("flipped");
            $(".bookPagePC"+lastPage).prev().addClass("pageShow");
        } else {
            $('.bookPagePC').removeClass('ready flipped active pointerEventsNone pageShow');
            $(".bookPagePC"+parseInt((id+1)/2)).addClass("active");;
            $(".bookPagePC"+parseInt((id+1)/2)).prevAll().addClass("flipped pointerEventsNone");
            $(".bookPagePC"+lastPage).prevAll().addClass("flipped pointerEventsNone");
            $(".bookPagePC"+lastPage).removeClass("pointerEventsNone");
            $(".bookPagePC"+lastPage).addClass("flipped");
            $(".bookPagePC"+lastPage).prev().addClass("pageShow");
            $(".bookPagePC"+parseInt((id+1)/2)).next().addClass("ready");
        }
    }
}
onTextBox = () => {
    $(".headingTextBox").toggleClass("showHeading");
    $(".bookMarkbox").removeClass("show").addClass("hidden");
    $(".bookMarkList").removeClass("listShow");
}
onBookMarkBox = () => {
    $(".headingTextBox").removeClass("showHeading");
    if($(".bookMarkbox").hasClass("show")){
        $(".bookMarkbox").removeClass("show").addClass("hidden");
        $(".bookMarkList").removeClass("listShow");
    } else if ($(".bookMarkbox").hasClass("hidden")){
        $(".bookMarkbox").removeClass("hidden").addClass("show");
        $(".bookMarkList").addClass("listShow");
    }
}
onMoveto = (pageNum) => {
    this.props.onPageListChange('pageId',parseInt(((pageNum+1)/2))*2)
    if(this.props.pageId===parseInt(((pageNum+1)/2))*2){
        $(".headingTextBox").removeClass("showHeading");
    }
}
    render() {
        let {prevBtn, nextBtn, pageId, pageList, pcPageId, pageListFront, pageListBack,
            isPageHeader, isBookmark, pageHeaderList, bookmarkList, invalidFrontBtn, invalidBackBtn } = this.state
        let totalPageNum = parseInt((pageId+1)/2)*2
        let pcId = parseInt((pageId+1)/2)
        // console.log(this.state.pageId, this.props.pageId)
        return (
            <React.Fragment>
                <div className="previewFrame pcPreviewFrame">    
                    {/* 목차 보이기/안보이기 클래스명 변경은 아래에서 하시면 됩니다. */}
                    <div className="bookPageFrame">
                        <div className="headingTextBox">
                            {/* <div className={isPageHeader ? 'showHeadingText':'hiddenHeadingText'}> */}
                            {pageHeaderList&& pageHeaderList.map((c, id)=> {
                                let pageNum = this.state.pageList.indexOf(c)
                                let isValidPage = pageNum > this.props.seenPageId
                                return(
                                    <div
                                    onClick={()=>this.onMoveto(pageNum)}
                                    key={id}
                                    className={c.pageHeader === 'header1' ?   `heading1`
                                            : c.pageHeader === 'header2' ? `heading2`
                                            : c.pageHeader === 'header3' ?  `heading3`
                                            : c.pageHeader === 'hidden' ?  `noHeading`
                                            : `heading5`}
                                    >{c.pageTitle}</div>
                                )
                            })
                            }
                            {/* black */}
                        </div>
                        {pageList === undefined ?
                        <Spinner msg='디지털 북을 불러오는 중입니다.'/>
                        :
                        <div className="scene">
                            <div className="bookPageGroup">
                                {pageListFront && pageListFront.map((c,id) => {
                                    return (
                                    <div key={id}
                                    className={id === 0 ?   `bookPagePC bookPagePC${id} active`
                                                        : `bookPagePC bookPagePC${id}`}>
                                    
                                    <form onSubmit={this.handleSubmit} className="frontPageBox">
                                            <PageAdminItem pageItem={c}
                                            pageId={(id*2)} sendTempMsg={this.sendTempMsg}
                                            mybookId={this.props.mybookId} showBookmark='front' />
                                        </form>
                                        <form onSubmit={this.handleSubmit}className="backPageBox">                      
                                            <PageAdminItem pageItem={pageListBack[id]}
                                            pageId={(id*2)+1} sendTempMsg={this.sendTempMsg}
                                            mybookId={this.props.mybookId} showBookmark='back' />
                                        </form>   
                                    </div>
                                    )})
                                }
                            </div>
                        </div>}
                    </div>
                    <div className="utilityBtnGroup">
                        <div className="headingBtnBox">
                            <span className="headingBtn"
                            onClick={()=>this.onTextBox()}
                            // onClick={()=>this.onPropsChange('isPageHeader', !isPageHeader)}
                            >목차</span>
                        </div>
                        <div className="bookMarkListBox">
                            <div className="bookMarkList">
                                <span className="bookMarkbox hidden">
                                {bookmarkList.length>0 ? bookmarkList.map((c, id)=> {
                                    let pageNum = this.state.pageList.indexOf(c)
                                    let isBookmarkAt = getTimeDayjs(c.bookmarkAt)
                                    return(
                                    <ul key={id}>
                                        <li onClick={()=>this.onMoveto(pageNum)}>{c.pageTitle}</li>
                                        <li>
                                            <span>p.{pageNum}</span>
                                            <span>{isBookmarkAt}</span>
                                            <span  onClick={()=> this.deleteBookmark(c)}>×</span>
                                        </li>
                                    </ul>
                                    )
                                }) : <ul><li>저장된 책갈피가 없습니다.</li></ul>
                                }
                                </span>
                            </div>
                            <div className="bookMarkListBtn" onClick={()=>this.onBookMarkBox()}>책갈피</div>
                        </div>
                        <div className="bookBtnBox">
                            <span className="curPageSign">{pcId} / {pageListFront.length}</span>
                            <div>
                                <span className={`${prevBtn} prevBtn`} onClick={()=>this.onPageChange('prev')}>이전</span>
                                <span className={`${nextBtn} nextBtn`} onClick={()=>this.onPageChange('next')}>다음</span>
                            </div>
                        </div>
                    </div>
                </div>
            {/* 페이지 하단 메뉴*/}
            <NotificationContainer/>
         </React.Fragment>
        )
    }
}
export default Digitalbook_PC_Admin
import React, { Component } from 'react';
import {withFirebase} from '../../../Firebase'
import UserDetail from './UserDetail'
import {Link} from 'react-router-dom'
import * as ROUTES from '../../../../constants/routes';
import { getTimeDayjs } from '../../../helpers';

class UserList extends Component {
    constructor(props) {
      super(props);
  
      this.state = {
        loading: false,
        users: [],
        msg:'회원 리스트 다운로드(xlsx)'
      };
    }
  
    componentDidMount() {
      this.setState({ loading: true });
  
    //   this.unsubscribe = this.props.firebase
    //     .users()
    //     .onSnapshot(snapshot => {
    //       let users = [];
  
    //       snapshot.forEach(doc =>
    //         users.push({ ...doc.data(), uid: doc.id }),
    //       );
  
    //       this.setState({
    //         users,
    //         loading: false,
    //       });
    //     });
        this._ismounted = true;
        if(this._ismounted){
            this.getUserList()
        }  
    }
    componentWillUnmount() {
    //   this.unsubscribe();
    this._ismounted = false;
    }
    getUserList =()=> {
        this.props.firebase.users().orderBy('createdAt', "desc").limit(20).get()
        .then(result => {
            var lastVisible = result.docs[result.docs.length-1];
            var users = []
            result.forEach(res => {
                var listData = res.data()
                var uid = res.id
                users.push({uid, ...listData})
            })
            this.setState({users, lastVisible, loading: false,})
        })
    }
    getNextUserList =()=> {
        let scrollPointX = window.scrollX
        let scrollPointY = window.scrollY
        let {users, lastVisible} = this.state
        if(lastVisible !==undefined){
        this.props.firebase.users().orderBy('createdAt', "desc").startAfter(lastVisible).limit(20).get()
        .then(result => {
            var lastVisible = result.docs[result.docs.length-1];
            result.forEach(res => {
                var listData = res.data()
                var uid = res.id
                users.push({uid, ...listData})
            })
            this.setState({users, lastVisible})
            window.scrollTo(scrollPointX, scrollPointY)
        })
    } else {
        alert('최신 데이터 입니다.')
    }
    }
    getExcelFile = () => {
      this.setState({msg:'회원 리스트를 다운받는 중입니다..'})
      fetch("/api/v1/userList", {
        method: 'POST',
        headers: {'Content-Type': 'application/json'},
        // body: JSON.stringify(data)
    })
    .then((res)=>res.blob())
    .then((blob)=>{
      let today = getTimeDayjs()
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      // the filename you want
      a.download = `XRBOOK회원리스트(${today}).xlsx`;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      this.setState({msg:'회원 리스트 다운로드 완료!'})
      setTimeout(()=>this.setState({msg:'회원 리스트 다운로드(xlsx)'}), 3000)
    })
    .catch(err => alert(`2 : ${err}`))
    }

    render() {
        const { users, loading, msg } = this.state;
        let {TotalUser, Totalprogram, Totalsales, Totalmybook, Totalinquiry} = this.props.statistics
      return (
        <div className="userDataListFrame">
        <div className="userDataTitleBox">
            <div>
                <h4>회원 총 {TotalUser}명</h4>
                <div className="listDownloadBtn" onClick={()=>this.getExcelFile()}>{msg}</div>
            </div>
            <ul>
                <li>Off-line Learning 문의 <span>{Totalprogram}</span>,</li>
                <li>On-line Learning 문의 <span>{Totalsales}</span>,</li>
                <li>On-line Learning 수강 <span>{Totalmybook}</span>,</li>
                <li>문의 <span>{Totalinquiry}</span></li>
            </ul>
        </div>
        
          {loading ? <div>Loading ...</div>
          
          :
          <table>
              <thead>
                  <tr>
                    <th>이름</th>
                    <th>전화 번호</th>
                    <th>이메일</th>
                    <th>Off-line 문의</th>
                    <th>On-line 문의</th>
                    <th>On-line 수강</th>
                    <th>메인 문의</th>
                    <th>가입 일자</th>
                    <th>탈퇴 일자</th>
                  </tr>
                  
              </thead>
            {users.map(c => (
                <tbody key={c.uid}>
                    <UserDetail userInfo={c}/>
                </tbody>
            ))}
          </table>
          }
          <button className="viewMoreListBtn" onClick={()=>this.getNextUserList()}>시간순 20개 더보기</button>
        </div>
      );
    }
  }
  
  export default withFirebase(UserList);
import React, { Component } from 'react'
import {withFirebase} from '../Firebase'
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import DaumPostcode from 'react-daum-postcode';
import * as ROUTES from '../../constants/routes';
import { getTimeString, entireTrim, getTimeDayjs } from '../helpers'
import dayjs from 'dayjs'
import Spinner from '../Utils/Spinner'
import $ from 'jquery'

class SelfLearning extends Component {
    state = {
        selfLearningList : [],
        bookItemList:[],
        shipmentModal:false,
        isAddressOpen:false,
        postCode: '',
        fullAddress: '',
        extraAddress: '',
        userAddress:'',
        mybookId:'',
        isBookItem:false,
        isRentItem:false,
        spinner : false
    }
    componentDidMount(){
        this.getSelfLearningList()
    }
    componentDidUpdate(prevProps, prevState){
        if(!prevState.shipmentModal&&this.state.shipmentModal){
            $('html, body').css("overflow", "hidden");
        } else if(prevState.shipmentModal&& !this.state.shipmentModal) {
            $('html, body').css("overflow", "inherit")
        }
        if(!prevState.isAddressOpen&&this.state.isAddressOpen){
            $('html, body').css("overflow", "hidden");
        } else if(prevState.isAddressOpen&& !this.state.isAddressOpen) {
            $('html, body').css("overflow", "inherit")
        }
        if(!prevState.isBookItem&&this.state.isBookItem){
            $('html, body').css("overflow", "hidden");
        } else if(prevState.isBookItem&& !this.state.isBookItem) {
            $('html, body').css("overflow", "inherit")
        }
        if(!prevState.isRentItem&&this.state.isRentItem){
            $('html, body').css("overflow", "hidden");
        } else if(prevState.isRentItem&& !this.state.isRentItem) {
            $('html, body').css("overflow", "inherit")
        }
    }

    getSelfLearningList =()=> {
        let {authUser}=this.props
        this.props.firebase.mybooks().where('codeUser','==', authUser['uid'] ).get()
        .then(result => {
            var selfLearningList = []
            result.forEach(res => {
                var listData = res.data()
                var mybookId = res.id
                selfLearningList.push({mybookId, ...listData})
            })
            this.setState({selfLearningList})
        })
    }
    onPropsChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }
    getBookItem = (c) => {
        this.setState({
            bookItemList : c,
            isBookItem : true,
            isRentItem : false,
            isEventItem : false,
            userAddress : c.userAddress,
            mybookId:c.mybookId,
            shipmentModal:false,
            isAddressOpen:false,
            postCode:'', fullAddress:'', extraAddress:''
        })
    }
    getRentItem = (c) => {
        this.setState({
            bookItemList : c,
            isRentItem : true,
            isBookItem : false,
            isEventItem : false,
            userAddress : c.userAddress,
            mybookId:c.mybookId,
            shipmentModal:false,
            isAddressOpen:false,
            postCode:'', fullAddress:'', extraAddress:''
        })
    }
    getEventItem = (c) => {
        this.setState({
            bookItemList : c,
            isRentItem : false,
            isBookItem : false,
            isEventItem : true,
            userAddress : c.userAddress,
            mybookId:c.mybookId,
            shipmentModal:false,
            isAddressOpen:false,
            postCode:'', fullAddress:'', extraAddress:''
        })
    }
    setUserAddress = (mybookId) => {
        let {postCode, fullAddress, extraAddress} = this.state
        this.setState({userAddress : `${postCode} ${fullAddress} ${extraAddress}`})
        this.props.firebase.mybook(mybookId).set({userAddress : `${postCode} ${fullAddress} ${extraAddress}`},{merge:true})
        .then(()=>  this.setState({shipmentModal : false}))
    }
    setvrRentReturn = (mybookId) => {
        this.props.firebase.mybook(mybookId).set({vrRentReturn : getTimeString()},{merge:true})
        .then(()=> alert('반납 신청이 완료되었습니다.'))
    }
    
    render() {
        let {
            selfLearningList, spinner, bookItemList, isBookItem, isRentItem, isEventItem, shipmentModal, isAddressOpen,
            mybookId, postCode, fullAddress, extraAddress, userAddress}= this.state
            let editAddress = ['ready', 'noCover']
        return (
            <React.Fragment>
                {/* <ul className="slpStatusFrame">
                    <li>프로그램 명</li>
                    <li>교육 기간</li>
                    <li>코드 번호</li>
                    <li>비고</li>
                </ul> */}
                {/* 코드 번호 Oculus go 전용 코드 번호에서 네이밍 수정*/}
                { selfLearningList.length>0 ? selfLearningList.map((c, id)=> {
                    let {
                        title, bookStartAt, bookFinishAt, bookcode, vrRent, vrRentStatus, vrEvent, vrEventStatus, published, publishedStatus
                    } = c
                    var bookStartAtFormatted2 = getTimeDayjs(bookStartAt)
                    var yearF = bookFinishAt.substr(0,2)
                    let yearIntF = parseInt(yearF)
                    var monF = bookFinishAt.substr(2,2)
                    let monIntF = parseInt(monF)
                    var dateF = bookFinishAt.substr(4,2)
                    let dateIntF = parseInt(dateF)
                    var bookFinishAtFormatted= dayjs(new Date(2000+yearIntF, monIntF-1, dateIntF-1))
                    var bookFinishAtFormatted2 = bookFinishAtFormatted.format('YYYY-MM-DD')
                    
                    return(
                        <ul className="slpStatusFrame" key={id}>
                            <li><span>프로그램</span><span>{title}</span></li>
                            <li><span>교육 기간</span><span>{bookStartAtFormatted2} ~ {bookFinishAtFormatted2}</span></li>
                            <li><span>코드 번호</span><span>{bookcode}</span></li>
                            <li>
                                <span>기타 사항</span>
                                <span>
                                    <button onClick={()=>this.props.history.push(ROUTES.APP_DOWNLOAD)}>VR/AR APP 다운로드</button>
                                    {/* {vrRent ? <button onClick={()=>this.getRentItem(c)}>기기대여 현황</button> 
                                    :vrEvent ? <button onClick={()=>this.getEventItem(c)}>기기대여 현황</button>
                                    : null} */}
                                    {published>0 ? <button onClick={()=>this.getBookItem(c)}>책 인쇄 서비스</button> : <button disabled>책 인쇄 서비스</button>}
                                </span>
                            </li>
                        </ul>
                    )
                })
                :   <div className="noDataText">나의 On-line Learning 이용 현황이 없습니다.</div>
                } 
                {isBookItem &&
                    <div className="bookPublishBg">
                        <div className="bookPublishFrame">
                            <div className="publPopTitle">
                                <h5>책 인쇄 서비스</h5>
                                <div onClick={()=> this.setState({isBookItem:false, spinner:false, bookItemList:[]})}>×</div>
                            </div>
                            <p>
                                <span>"XR BOOK: {bookItemList.title}" On-line Learing 프로그램</span>
                                <span>
                                    프로그램 결제 시 사전 신청한 디지털북은&nbsp; 
                                        {bookItemList.isFinished ?
                                        bookItemList.published >0 && bookItemList.publishedStatus === 'ready'? '인쇄 준비 중'
                                        : bookItemList.published >0 && bookItemList.publishedStatus === 'printing'? '인쇄 중'
                                        : bookItemList.published >0 && bookItemList.publishedStatus === 'shipment'? '배송 중'
                                        : bookItemList.published >0 && bookItemList.publishedStatus === 'finish'? '배송 완료 상태'
                                        : bookItemList.published >0 && bookItemList.publishedStatus === 'noCover'? '커버 미선택 상태'
                                        : '관리자 문의 요청'
                                        :'프로그램 종료 후 인쇄 예정'}
                                    입니다.
                                </span>
                                <span>수량을 추가하고자 할 경우 고객센터로 문의해 주세요.</span>
                            </p>
                            
                            
                            <ul className="publPopInfo">
                                <li>
                                    <span>신청자</span>
                                    <span>{bookItemList.codeUserName}</span>
                                </li>
                                <li>
                                    <span>인쇄 도서명</span>
                                    <span>{bookItemList.bookTitle}({bookItemList.target}대상)</span>
                                </li>
                                <li>
                                    <span>수량</span>
                                    <span>{bookItemList.published}</span>
                                </li>
                            </ul>
                            <div className="publPopAddress">
                                <p>배송 주소</p>
                                <div>
                                    <p>{userAddress}</p>
                                    {editAddress.includes(bookItemList.publishedStatus) &&!shipmentModal && <span onClick={()=>this.setState({shipmentModal : true})}>주소 입력/수정</span>}
                                </div>
                            </div>
                                
                            {shipmentModal &&
                            <div className="PublPopChangeAddress">
                                <div className="addressInputFrame">
                                    <input type='number' value={postCode} onClick={()=> this.onPropsChange('isAddressOpen',true)} readOnly/>
                                    <span className='addressBtn' onClick={()=> this.onPropsChange('isAddressOpen',true)}>주소지 찾기</span><br/>
                                    <input type='text' value={fullAddress} onClick={()=> this.onPropsChange('isAddressOpen',true)} readOnly/><br/>
                                    <input type='text' className='extraAddress' name='extraAddress' value={extraAddress}
                                    onChange={(e)=> this.onPropsChange('extraAddress',e.target.value)}/>
                                </div>
                                {isAddressOpen &&<Postcode isOpen={()=>this.setState({isAddressOpen : false})} onUpdateCode={this.onPropsChange}/>}
                            
                                <div className="PublPopChAddrBtn">
                                    <button onClick={()=>this.setUserAddress(mybookId)}>입력</button>
                                    <button onClick={()=>this.setState({isAddressOpen:false, shipmentModal:false, userAddress:bookItemList.userAddress, mybookId:'',
                                                                postCode:'', fullAddress:'', extraAddress:'', })}>취소</button>
                                </div>
                                
                                
                            </div>}     
                    </div>
                </div>}     
                 {isRentItem &&
                <div>
                    {spinner ? 
                        <Spinner msg='기기대여 정보를 불러오는 중입니다.'/>
                    :
                    <div className="deviceRentalBg">
                        <div className="deviceRentFrame">
                            <div className="rentPopTitle">
                                <h5>기기대여 현황</h5>
                                <div onClick={()=> this.setState({isRentItem:false, spinner:false, bookItemList:[], mybookId:''})}>×</div>
                            </div>
                            <div className="rentPopList">
                                <p>
                                    <span>"XR BOOK: {bookItemList.title}" On-line Learning 프로그램</span> 
                                    <span>결제 시 사전에 대여 신청한 기기 현황입니다.</span>
                                </p>
                                <ul className="rentPopInfo">
                                    <li>
                                        <span>대여 물품</span>
                                        <span>오큘러스 고 본체 1/ 전용 컨트롤러 / 전용 박스 케이스</span>
                                    </li>
                                    <li>
                                        <span>대여 현황</span>
                                        <div>
                                            <span>{bookItemList.vrRentStart}-{bookItemList.vrRentFinish}</span>
                                            <span>({bookItemList.vrRentStatus === 'ready'? '배송 준비 중'
                                                : bookItemList.vrRentStatus === 'shipment'? '체험 진행 중'
                                                : bookItemList.vrRentStatus === 'finish'? '반납 완료'
                                                : bookItemList.vrRentStatus === 'finishYet'? '반납 연체 중'
                                                : bookItemList.vrRentStatus === 'finishLate'? '연체 반납'
                                                : '관리자 문의 요청'})
                                            </span>
                                        </div>
                                    </li>
                                    <li>
                                        <span>주소지</span>
                                        <span>{userAddress}</span>
                                        {bookItemList.vrRentStatus==='ready' &&!shipmentModal && <span onClick={()=>this.setState({shipmentModal : true})}>주소 입력/수정</span>}
                                    </li>
                                </ul>
                            {/* <button>반납 신청 하기</button> */}
                            {/* 대여 종료일로 정해진 날 or 정해진날 +a 일안에 도착해야한다던지 발송해야한다던지 등 상세설명 강조해서 추가해주세요.  */}
                                 <div className="rentPopGuide">
                                    배송 완료된 시점부터 성실한 상품 관리 및 반납의 의무가 있습니다.<br/>
                                    상품 및 구성품이 누락되지 않게 박스에 잘 포장해 반납하여 주시기 바랍니다.<br/>
                                    대여 기간은 주소지로의 기기 배송 완료 시점부터 계산됩니다.
                                    사용 기간 이내에 반납을 위한 택배 처리가 완료되어야 합니다.
                                    (10일 대여이므로 기기 배송 완료 일자를 1일째로 계산, 10일째날 택배 접수하여 반납 절차를 진행해야 합니다.) 
                                    반납 전 기기의 온전한 상태를 사진으로 촬영한 이미지 파일을 메일로 보내주십시오.
                                    <br />
                                    <br />받는 이 : xrbook@thehrder.com 
                                    <br />제목 : XR BOOK 사이트 기기대여 반납(사용자명-이메일-연락처)
                                    <br />
                                    <br />이미지 파일을 메일로 보내지 않고 택배로 보내신 기기의 경우 제품 확인 시 고장 및 손상의 책임은 사용자에게 있습니다.
                                    기간 내 반납 절차가 진행되지 않을 경우 지연될 날 만큼 연체료가 부과됩니다.<br/>
                                    신청하신 대여 기간 이내에 반납하더라도 남은 일수에 대한 비용은 환급되지 않습니다.<br/>
                                    대여 기간 연장은 불가합니다. 추가로 기기대여를 다시 신청하고자 할 경우 고객센터로 문의하시기 바랍니다. <br/>
                                </div>
                                
                                {/* <div className="rentPopBtn">
                                    <button>추가 대여 하기</button>
                                </div> */}
                            </div>
                        </div>
                    </div>
                    }
                </div>}
                {isEventItem &&
                <div>
                    {spinner ? 
                        <Spinner msg='기기구매 정보를 불러오는 중입니다.'/>
                    :
                    <div className="deviceRentalBg">
                        <div className="deviceRentFrame">
                            <div className="rentPopTitle">
                                <h5>기기구매 현황</h5>
                                <div onClick={()=> this.setState({isEventItem:false, spinner:false, bookItemList:[], mybookId:''})}>×</div>
                            </div>
                            <div className="rentPopList">
                                <p>
                                    <span>"XR BOOK: {bookItemList.title}" On-line Learning 프로그램</span> 
                                    <span>프로그램과 함께 구매한 기기 현황입니다.</span>
                                </p>
                                <ul className="rentPopInfo">
                                    <li>
                                        <span>구매 물품</span>
                                        <span>오큘러스 고 본체 1/ 전용 컨트롤러 / 전용 박스 케이스</span>
                                    </li>
                                    <li>
                                        <span>배송 현황</span>
                                        <div>
            
                                            <span>({ entireTrim(userAddress)===''? '배송지 미입력' 
                                                : bookItemList.vrEventStatus === 'ready'? '배송 준비 중'
                                                : bookItemList.vrEventStatus === 'shipment'? '배송 중'
                                                : bookItemList.vrEventStatus === 'finish'? '배송 완료'
                                                : '관리자 문의 요청'})
                                            </span>
                                        </div>
                                    </li>
                                    <div className="publPopAddress">
                                <p>배송 주소</p>
                                <div>
                                    <p>{userAddress}</p>
                                    {bookItemList.vrEventStatus==='ready' &&!shipmentModal && <span onClick={()=>this.setState({shipmentModal : true})}>주소 입력/수정</span>}
                                </div>
                            </div>
                                
                            {shipmentModal &&
                            <div className="PublPopChangeAddress">
                                <div className="addressInputFrame">
                                    <input type='number' value={postCode} onClick={()=> this.onPropsChange('isAddressOpen',true)} readOnly/>
                                    <span className='addressBtn' onClick={()=> this.onPropsChange('isAddressOpen',true)}>주소지 찾기</span><br/>
                                    <input type='text' value={fullAddress} onClick={()=> this.onPropsChange('isAddressOpen',true)} readOnly/><br/>
                                    <input type='text' className='extraAddress' name='extraAddress' value={extraAddress}
                                    onChange={(e)=> this.onPropsChange('extraAddress',e.target.value)}/>
                                </div>
                                {isAddressOpen &&<Postcode isOpen={()=>this.setState({isAddressOpen : false})} onUpdateCode={this.onPropsChange}/>}
                            
                                <div className="PublPopChAddrBtn">
                                    <button onClick={()=>this.setUserAddress(mybookId)}>입력</button>
                                    <button onClick={()=>this.setState({isAddressOpen:false, shipmentModal:false, userAddress:bookItemList.userAddress, mybookId:'',
                                                                postCode:'', fullAddress:'', extraAddress:'', })}>취소</button>
                                </div>
                                
                                
                            </div>}  
                                </ul>
                                <div className="rentPopGuide">
                                    기기 구매 관련 내용 추가해주세요.
                                </div>
                            </div>
                        </div>
                    </div>
                    }
                </div>} 
            </React.Fragment>
        )
    }
}

export default compose(
    withFirebase,
    withRouter
  )(SelfLearning);

const Postcode = props => {
    const {onUpdateCode} = props
    const handleComplete = (data) => {
      let fullAddress = data.address;
      onUpdateCode("postCode", data.zonecode)
      let extraAddress = ''; 
      if (data.addressType === 'R') {
        if (data.bname !== '') {
          extraAddress += data.bname;
        }
        if (data.buildingName !== '') {
          extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
        }
        fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
      }
      onUpdateCode("fullAddress", fullAddress)
      onUpdateCode("isAddressOpen", false)
    }


    return (
    <div className="addressPopupOut">
        {/* 주소 입력하지 않으면 창이 닫히지 않도록 모달 배경클릭 시 모달 닫힘을 제거했습니다. */}
        {/* 모달 창 */}
            <div className="addressPopupIn">
                <DaumPostcode
                onComplete={handleComplete}
                />
                <button 
                onClick={()=>onUpdateCode("isAddressOpen", false)}
                >취소</button>
            </div>

    </div>
    ) }
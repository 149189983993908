import React, { useState, useEffect } from 'react'
import {withFirebase} from '../../Firebase'
import AuthDigitalbook from './AuthDigitalbook'
import NonauthDigitalbook from './NonauthDigitalbook'
import './AdminReading.css'
import $ from 'jquery'

const AdminReadingPage = props => {
    const [one, onOne] = useState('adminProgram_show');
    const [two, onTwo] = useState('adminProgram_hidden');
    const [show, onShow] = useState(props.location.state  !== undefined ? 
        props.location.state.tab  : 'authUser');
    const [totalmybook, onTotalmybook] = useState(0);
    const [totalmybookNon, onTotalmybookNon] = useState(0);
    function First() {
        onShow('authUser')
        onOne('adminProgram_show')
        onTwo('adminProgram_hidden')
        $(".adminReadingFrame>div>.adminReadingTabBtnGroup div").css({
          border: "1px solid #dedede",
          color: "#aaaaaa",
        });
        $(".adminReadingFrame>div>.adminReadingTabBtnGroup div")
          .eq(0)
          .css({ "border-bottom": 0, color: "#000000" });
      }
      function Second() {
        onShow('nonAuthUser')
        onOne('adminProgram_hidden')
        onTwo('adminProgram_show')
        $(".adminReadingFrame>div>.adminReadingTabBtnGroup div").css({
          border: "1px solid #dedede",
          color: "#aaaaaa",
        });
        $(".adminReadingFrame>div>.adminReadingTabBtnGroup div")
          .eq(1)
          .css({ "border-bottom": 0, color: "#000000" });
      }
      function getTotalNum (){
        props.firebase.tlpStatistics().get()
        .then((res)=> {
              let data = res.data()
              onTotalmybook(data.Totalmybook || 0)
              onTotalmybookNon(data.TotalmybookNon|| 0)
        })
      }
      useEffect(() => {
        getTotalNum()
      }, [])
    return (
      <div className="adminReadingFrame">
        <h3>사용자 디지털 북 관리</h3>
        
        <div>
          {/* 탭 부분 */}
          <div className="adminReadingTabBtnGroup">
            <div className={`${one}`} onClick={()=>First()}>일반 회원</div>
            <div className={`${two}`} onClick={()=>Second()}>기업 회원</div>
          </div>
           {/* 탭에서 선택된 내용 부분 */}
          <div className="adminReadingTabContGroup">
            <p>사용자 제작 완료 디지털북 총 {totalmybook+totalmybookNon}건</p>
            {show === 'authUser' ? <AuthDigitalbook totalmybook={totalmybook}/> :
            show === 'nonAuthUser' ? <NonauthDigitalbook totalmybookNon={totalmybookNon}/> 
            :
            null}
          </div>
        </div>
      </div>
    );
};

export default withFirebase(AdminReadingPage);
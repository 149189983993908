import React, {useState} from 'react';
import {withFirebase} from '../Firebase'
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes'

const CertificateI = props => {
  let {number, isCertificated, uid, onPropsChange, authUser} = props
  function onClickCertification() {
  const { IMP } = window;
  IMP.init(process.env.REACT_APP_INIT_CODE);

  let data = {
    merchant_uid: `${uid}+${number}`,
    phone:number.split('-').join(""),
    company:'XR BOOK',
    name: authUser['username'],
  }
  IMP.certification(data, callback);
    }

  function callback(response) {
    const {
      success, //true or false
      pg_type, // "certification"
      pg_provider, // "danal"
      merchant_uid, // uid+number
      imp_uid, //"imp_숫자"
      error_msg,
      error_code
    } = response;

    if (success) {
      alert('본인인증이 완료되었습니다.')
      props.firebase.user(uid).set({
         number : number,
         isCertificated : true,
         certificationInfo : response
      },{merge:true})
      .then(()=> {
          onPropsChange('isCertificated', true)
      })
      .then(()=> {
          onPropsChange('certificationInfo', response)
      })
      .then(()=>{
          authUser['isCertificated'] = true
          authUser['number'] = number
          authUser['certificationInfo'] = response
          localStorage.removeItem('authUser')
          localStorage.setItem('authUser', JSON.stringify(authUser))
          onPropsChange('authUser', authUser)
          // console.log(data);
              })
    } else {
      alert(`본인인증 실패: ${error_msg}`);
    }
  }
      return (
        <span>
        {isCertificated ? <button disabled>인증완료</button>
          :  
          <button onClick={onClickCertification}>본인인증</button>
          }
        </span>
      );
  }
  
  export default compose(
      withRouter,
      withFirebase,
    )(CertificateI);
import React, {useState, useEffect} from 'react';
import {withFirebase} from '../../Firebase'
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import * as ROUTES from '../../../constants/routes'
import BootPay from "bootpay-js"
import dayjs from 'dayjs'
import { getTimeString } from '../../helpers';

let today = dayjs(new Date())
let expiredate = today.add(3,'day')
let account_expire_at = expiredate.format('YYYY-MM-DD')

const ExtensionPay = props => {
	useEffect(() => {
		try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    };
	  }, [])

	const {payReqData,invalid, extensionPeriod} = props
	// console.log(payReqData)
	const {title, target, published, period, vrRent,
		mybookId, pid,
		uid, username, email, number} = payReqData
	let createdAt = getTimeString()
	let order_id = `${mybookId}_EXTENSION07`
	let totalPrice = vrRent ? 80000 : 30000
	function onClickPayment(){
		let result = window.confirm(`${vrRent? '기기 체험 포함 ' : '' }프로그램 기간 연장 상품의 가격은 ${vrRent ? '80,000' : '30,000'}원/7일입니다.\n결제가 완료되면 환불이 불가능합니다. 동의하고 진행하시겠습니까?\n결제 후 반영까지 최대 3분 이상 소요될 수 있습니다.`)
		/* 1. 가맹점 식별하기 */
  
		/* 2. 결제 데이터 정의하기 */
		const data = {
			price: totalPrice,
			application_id: process.env.REACT_APP_WEB_ID,
			name: `${title} ${target}대상${published>0 ? '(출판)' : ''} 프로그램  ${extensionPeriod}일 연장`,
			pg: 'kcp',
			method: '',
			show_agree_window: 1,
			items: [{item_name: `${extensionPeriod}일기간연장 : ${title} ${target}대상${published>0 ? '(출판)' : ''} - ${period}일`,
			 qty:1, unique : order_id, price:totalPrice, cat1:'Online', cat2:target, cat3:'extension07'}],
			user_info: {
				uid : uid,
				username: username,
				email: email,
				phone: number
			},
			order_id: order_id,
			params: {createdAt, extensionPeriod, totalPrice, mybookId, pid, title, target, published, period, vrRent, type:'EXTENSION'},
			extra: {
				vbank_result: 1, 
				quota: '0,2,3'
			},
			account_expire_at : account_expire_at
		}
		// console.log(props)
		// console.log(data)
		/* 4. 결제 창 호출하기 */
		if(result){
		BootPay.request(data).error(function (data) {
			alert('잠시 후 다시 시도 해주세요.\n'+data.msg)
			//결제 진행시 에러가 발생하면 수행됩니다.
			// console.log('error');
			// console.log(data);

		}).cancel(function (data) {
			//결제가 취소되면 수행됩니다.
			alert('결제가 취소되었습니다.')
		}).ready(function (readyData) {
			// 가상계좌 입금 계좌번호가 발급되면 호출되는 함수입니다.
					let { bankname, account, expiredate} = readyData
					let smsNumber = number.split('-').join("")
					let expireddate = expiredate.split(' ')[0]
					let readyContent = `https://www.xrbook.net 가상 계좌 정보: ${bankname} ${account} 기한 : ${expireddate}`
					let data = {
						type:'SMS',
						contentType:"COMM",
						subject:'XR BOOK 메세지입니다.',
						content: readyContent,
						to: smsNumber,
					}
					let result = window.confirm(`가상계좌 정보를 문자로 받으시겠습니까?`)
					if(result){
						fetch("/api/v1/sendSMS/messages", {
						method: 'POST',
						headers: {
							'Content-Type': 'application/json; charset=utf-8'
						},
						body : JSON.stringify(data)
					})
					.then(res=> {
						if(res.status === 200){
						alert('문자 전송이 완료되었습니다.')
						props.history.push(ROUTES.USER)
						}
					})
					.catch(err=>alert(err))
				} else {
					props.history.push(ROUTES.USER)
				}

		}).confirm(function (data) {
			var enable = true;
			//쿠폰 재 확인 - 유효기간, 사용여부 등 -> 가상계좌에서는 체크할 방법이 없어
			//결제가 실행되기 전에 수행되며, 주로 재고를 확인하는 로직이 들어갑니다.
			//주의 - 카드 수기결제일 경우 이 부분이 실행되지 않습니다.
			 // 재고 수량 관리 로직 혹은 다른 처리
			if (enable) {
				BootPay.transactionConfirm(data);
			} else {
				BootPay.removePaymentWindow();
			}
		}).close(function (data) {
			// 결제창이 닫힐때 수행됩니다. (성공,실패,취소에 상관없이 모두 수행됨)
			// props.history.push(ROUTES.USER)
			window.location.reload()
		}).done(function (checkData) {
			//결제가 정상적으로 완료되면 수행됩니다
			//비즈니스 로직을 수행하기 전에 결제 유효성 검증을 하시길 추천합니다.
			alert('결제가 완료되었습니다.')
			// props.history.push(ROUTES.USER)
		});
	}
}
    return (
		<React.Fragment>
		{props.totalExtension!==undefined && props.totalExtension>=30 ?
		<button className="extensionBtn" disabled={true}>연장 완료</button>
		:
		<button className="extensionBtn" onClick={onClickPayment} disabled={invalid}>기간 연장</button>
		}
		</React.Fragment>
    );
}

export default compose(
    withRouter,
    withFirebase,
  )(ExtensionPay);
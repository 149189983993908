import React, {useState, useEffect} from 'react';
import { withFirebase } from '../../Firebase'
import ChapterItem from './ChapterItem'
import './AdminAppImages.css'
import * as firebase from "firebase";
import $ from "jquery";
import { getTimeString } from '../../helpers';

const AdminAppImagesPage = props => {
    const [one, onOne] = useState('adminProgram_show');
    const [two, onTwo] = useState('adminProgram_hidden');
    const [three, onThree] = useState('adminProgram_hidden');
    const [four, onFour] = useState('adminProgram_hidden');
    const [five, onFive] = useState('adminProgram_hidden');
    const [six, onSix] = useState('adminProgram_hidden');
    const [activity, onactivity] = useState('adminProgram_hidden');
    const [show, onShow] = useState('chapter1');
    const [showSecret, onshowSecret] = useState(false);
    const [statistics, setstatistics] = useState({})
  
    function First() {
      onShow('chapter1');
      onOne('adminProgram_show');
      onTwo('adminProgram_hidden');
      onThree('adminProgram_hidden');
      onFour('adminProgram_hidden');
      onFive('adminProgram_hidden');
      onSix('adminProgram_hidden');
      onactivity('adminProgram_hidden');

      $(".adminAppImagesFrame>div>.appImagesTabBtnGroup div").css({
        border: "1px solid #dedede",
        color: "#aaaaaa",
      });
      $(".adminAppImagesFrame>div>.appImagesTabBtnGroup div")
        .eq(0)
        .css({ "border-bottom": 0, color: "#000000" });
    }
    function Second() {
      onShow('chapter2');
      onOne('adminProgram_hidden');
      onTwo('adminProgram_show');
      onThree('adminProgram_hidden');
      onFour('adminProgram_hidden');
      onFive('adminProgram_hidden');
      onSix('adminProgram_hidden');
      onactivity('adminProgram_hidden');

      $(".adminAppImagesFrame>div>.appImagesTabBtnGroup div").css({
        border: "1px solid #dedede",
        color: "#aaaaaa",
      });
      $(".adminAppImagesFrame>div>.appImagesTabBtnGroup div")
        .eq(1)
        .css({ "border-bottom": 0, color: "#000000" });
    }
    function Third() {
    onShow('chapter3');
    onOne('adminProgram_hidden');
    onTwo('adminProgram_hidden');
    onThree('adminProgram_show');
    onFour('adminProgram_hidden');
    onFive('adminProgram_hidden');
    onSix('adminProgram_hidden');
    onactivity('adminProgram_hidden');

    $(".adminAppImagesFrame>div>.appImagesTabBtnGroup div").css({
      border: "1px solid #dedede",
      color: "#aaaaaa",
    });
    $(".adminAppImagesFrame>div>.appImagesTabBtnGroup div")
      .eq(2)
      .css({ "border-bottom": 0, color: "#000000" });
    }
    function Fourth() {
      onShow('chapter4');
      onOne('adminProgram_hidden');
      onTwo('adminProgram_hidden');
      onThree('adminProgram_hidden');
      onFour('adminProgram_show');
      onFive('adminProgram_hidden');
      onSix('adminProgram_hidden');
      onactivity('adminProgram_hidden');

      $(".adminAppImagesFrame>div>.appImagesTabBtnGroup div").css({
        border: "1px solid #dedede",
        color: "#aaaaaa",
      });
      $(".adminAppImagesFrame>div>.appImagesTabBtnGroup div")
        .eq(3)
        .css({ "border-bottom": 0, color: "#000000" });
      }
      function Fifth() {
        onShow('chapter5');
        onOne('adminProgram_hidden');
        onTwo('adminProgram_hidden');
        onThree('adminProgram_hidden');
        onFour('adminProgram_hidden');
        onFive('adminProgram_show');
        onSix('adminProgram_hidden');
        onactivity('adminProgram_hidden');

        $(".adminAppImagesFrame>div>.appImagesTabBtnGroup div").css({
          border: "1px solid #dedede",
          color: "#aaaaaa",
        });
        $(".adminAppImagesFrame>div>.appImagesTabBtnGroup div")
          .eq(4)
          .css({ "border-bottom": 0, color: "#000000" });
        }
        function Sixth() {
          onShow('chapter6');
          onOne('adminProgram_hidden');
          onTwo('adminProgram_hidden');
          onThree('adminProgram_hidden');
          onFour('adminProgram_hidden');
          onFive('adminProgram_hidden');
          onSix('adminProgram_show');
          onactivity('adminProgram_hidden');
  
          $(".adminAppImagesFrame>div>.appImagesTabBtnGroup div").css({
            border: "1px solid #dedede",
            color: "#aaaaaa",
          });
          $(".adminAppImagesFrame>div>.appImagesTabBtnGroup div")
            .eq(5)
            .css({ "border-bottom": 0, color: "#000000" });
          }
        function ArActivity() {
          onShow('activity');
          onOne('adminProgram_hidden');
          onTwo('adminProgram_hidden');
          onThree('adminProgram_hidden');
          onFour('adminProgram_hidden');
          onFive('adminProgram_hidden');
          onactivity('adminProgram_show');
          $(".adminAppImagesFrame>div>.appImagesTabBtnGroup div").css({
            border: "1px solid #dedede",
            color: "#aaaaaa",
          });
          $(".adminAppImagesFrame>div>.appImagesTabBtnGroup div")
            .eq(6)
            .css({ "border-bottom": 0, color: "#000000" });
          }
      function getTotalNum (){
        props.firebase.tlpStatistics().get()
        .then((res)=> {
              let data = res.data()
              setstatistics(data)
              // console.log(data)
        })
      }
      useEffect(() => {
        getTotalNum()
      }, [])
    return (
      <div className="adminAppImagesFrame">
          <h3>Refelction Gallery 관리 </h3>
          <button onClick={(e)=>onshowSecret('chapterImg')}>Refelction Gallery 업로드</button>
          <button onClick={(e)=>onshowSecret('lectureImg')}>Photo Gallery</button>
          
          <div>
            {/* 탭 부분 */}
            <div className="appImagesTabBtnGroup">
              <div className={`${one}`} onClick={()=>First()}>chapter1</div>
              <div className={`${two}`} onClick={()=>Second()}>chapter2</div>
              <div className={`${three}`} onClick={()=>Third()}>chapter3</div>
              <div className={`${four}`} onClick={()=>Fourth()}>chapter4</div>
              <div className={`${five}`} onClick={()=>Fifth()}>Epliogue</div>
              <div className={`${six}`} onClick={()=>Sixth()}>photo gallery</div>
              {/* <div className={`${four}`} onClick={()=>ArActivity()}>AR Activity</div> */}
            </div>
            {/* 탭에서 선택된 내용 부분 */}
            <div className="appImagesTabContGroup">
              {show === 'chapter1' ? <ChapterItem totalNum={statistics.Totalchapter1 || 0} chapter={1}/> :
              show === 'chapter2' ? <ChapterItem totalNum={statistics.Totalchapter2 || 0} chapter={2}/> :
              show === 'chapter3' ? <ChapterItem totalNum={statistics.Totalchapter3 || 0} chapter={3}/> : 
              show === 'chapter4' ? <ChapterItem totalNum={statistics.Totalchapter4 || 0} chapter={4}/> :
              show === 'chapter5' ? <ChapterItem totalNum={statistics.Totalchapter5 || 0} chapter={5}/> :
              show === 'chapter6' ? <ChapterItem totalNum={statistics.Totalchapter6 || 0} chapter={6}/> :
              // show === 'activity' ? <ChapterItem totalNum={statistics.TotalARappActivity || 0} chapter={false}/> :
              null}
            </div>
          </div>
          {showSecret === 'chapterImg' ?
          <span className='sendMsgContBg'>
            <UploadsharingPage onshowSecret={onshowSecret}/>
          </span>
          : showSecret === 'lectureImg' ?
          <span className='sendMsgContBg'>
            <LectureImgUpload onshowSecret={onshowSecret}/>
          </span>
          : null
        }
      </div>
  
    );
};

export default withFirebase(AdminAppImagesPage);

const UploadsharingPage = (props) => {
  //authUserEmail, authUserName, chapter, page
  const [authUserEmail, onauthUserEmail] = useState('bnhs@naver.com');
  const [authUserName, onauthUserName] = useState('관리자');
  const [chapter, onchapter] = useState(5);
  const [page, onpage] = useState('');
  const [status, onstatus] = useState('');
  const uploadSharingPage = () => {
    onstatus('페이지 공유 중')
    let data = {authUserEmail, authUserName, chapter, page}
    console.log(data)
    fetch("/api/v1/sharePage", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
  })
  .then(res=> {
    onstatus('페이지 공유 완료')
    document.getElementById("lectureImgInput").value = "";
    console.log(res.json())    
  })
  .catch(err=>{
      alert('잠시 후 다시 시도해 주세요.\n에러코드 : '+err)
  })
  }
  return (
    <div className='sendMsgContFrame'>
      <div className="sendMsgPopTitle"><div onClick={(e)=>props.onshowSecret(false)}>X</div></div>
        <div>
        authUserEmail : <input type='text' name='authUserEmail' value={authUserEmail} onChange={(e)=>onauthUserEmail(e.target.value)}/>
        </div>
        <div>
        authUserName : <input type='text' name='authUserName' value={authUserName} onChange={(e)=>onauthUserName(e.target.value)}/>
        </div>
        <div>
        chapter : 
        <input type='radio' name='chapter' checked={chapter==1} value={1} onChange={(e)=>onchapter(e.target.value)}/> 1
        <input type='radio' name='chapter' checked={chapter==2} value={2} onChange={(e)=>onchapter(e.target.value)}/> 2
        <input type='radio' name='chapter' checked={chapter==3} value={3} onChange={(e)=>onchapter(e.target.value)}/> 3
        <input type='radio' name='chapter' checked={chapter==4} value={4} onChange={(e)=>onchapter(e.target.value)}/> 4
        <input type='radio' name='chapter' checked={chapter==5} value={5} onChange={(e)=>onchapter(e.target.value)}/> Epilogue
        {/* <input type='radio' name='chapter' checked={chapter==6} value={6} onChange={(e)=>onchapter(e.target.value)}/> photo gallery */}
        {/* <input type='number' min={1} max={5} name='chapter' value={chapter} disabled={!chapter} onChange={(e)=>onchapter(e.target.value)}/> */}
        {/* (ARactivity <input type='checkbox' name='chapter' value={!chapter} onChange={(e)=>onchapter(false)}/>) */}
        </div>
        <div>
        url : <FileUpload onpage={onpage} authUserEmail={authUserEmail} onstatus={onstatus} filepath = {`tlp/ARapp/sharingPage`}/> or <input type='text' name='page' value={page} onChange={(e)=>onpage(e.target.value)}/>
        </div>
        <button onClick={()=>uploadSharingPage()}>업로드</button>{status}
    </div>
  );
};


const LectureImgUpload = (props) => {
  //authUserEmail, authUserName, chapter, page
  const [authUserEmail, onauthUserEmail] = useState('');
  const [authUserName, onauthUserName] = useState('');
  const [page, onpage] = useState('');
  const [status, onstatus] = useState('');
  const lectureImgUploadHandler = () => {
    onstatus('학습현황 데이터 업로드 중')
    let data = {authUserEmail, authUserName, page, chapter:6}
    fetch("/api/v1/sharePage", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
  })
  .then(res=> {
    onstatus('학습현황 데이터 업로드 완료')
    onpage('')
    document.getElementById("lectureImgInput").value = "";
    console.log(res.json())    
  })
  .catch(err=>{
      alert('잠시 후 다시 시도해 주세요.\n에러코드 : '+err)
  })
  }
  const onCheckTextLeng = (e) => {
    let textValue = e.target.value
    // let enterTagLeng = textValue.match(/(?:\r\n|\r|\n)/g)
    let textValueLeng = textValue.replace(/(?:\r\n|\r|\n)/g, '').length
    // console.log(enterTagLeng)
    console.log(textValueLeng)
    if(textValueLeng>20){
      alert("글자수는 20자로 이내로 제한됩니다.")
    }else {
      onauthUserName(textValue)
  }
  }
  return (
    <div className='sendMsgContFrame'>
      <div className="sendMsgPopTitle"><div onClick={(e)=>props.onshowSecret(false)}>X</div></div>
        <div>
        제목 : <input type='text' name='authUserName' value={authUserName} placeholder='간단하게 작성해주세요.' onChange={(e)=>onauthUserName(e.target.value)}/>
        </div>
        <div>
        부제목 : <input type='text' name='authUserEmail' value={authUserEmail} placeholder='간단하게 작성해주세요.' onChange={(e)=>onauthUserEmail(e.target.value)}/>
        </div>
        <div>
        파일업로드 : <FileUpload onpage={onpage} authUserEmail={`관리자`} onstatus={onstatus} filepath = {`tlp/letcureImg`}/>
         {/* or <input type='text' name='page' value={page} onChange={(e)=>onpage(e.target.value)}/> */}
        </div>
        <button onClick={()=>lectureImgUploadHandler()} disabled={page===''}>업로드</button>
        <div>{status}</div>
    </div>
  );
};


const FileUpload = props => {
  const allInputs = {imgUrl: ''}
  const [imageAsFile, setImageAsFile] = useState('')
  const [imageAsUrl, setImageAsUrl] = useState(allInputs)
  const storage = firebase.storage()
  const {onpage, authUserEmail, onstatus, filepath} = props
  let createdAt = getTimeString()
  // const timeString = getTimeString()
  const handleImageAsFile = (e) => {
      onstatus('학습현황 사진을 스토리지에 올리는 중')
      const image = e.target.files[0]
      setImageAsFile(imageFile => (image))
      handleFireBaseUpload(image)
  }
  const handleFireBaseUpload = imageFile => {
      // e.preventDefault()
  //   console.log('start of upload')
    // async magic goes here...
    let uniqueImageName = imageFile.name + createdAt
    // const fileType = fileTypes[typeLeng-1]
    // if (fileType !== 'pdf'){
    //   console.error(`not an image, the image file is a ${typeof(imageFile)}`)
    //   alert('.pdf 파일만 업로드 가능합니다.')
    // } else {
     
    const uploadTask = storage.ref(`/${filepath}/${authUserEmail}/${uniqueImageName}`).put(imageFile)
    //initiates the firebase side uploading 
    uploadTask.on('state_changed', 
    (snapShot) => {
      console.log('totalBytes', snapShot.totalBytes)
      console.log('bytesTransferred', snapShot.bytesTransferred)
      if(snapShot.totalBytes !==0 && snapShot.totalBytes === snapShot.bytesTransferred){
        onstatus(`${uniqueImageName} 이미지 업로드 완료!`)
      }
      //takes a snap shot of the process as it is happening
      // console.log(snapShot)
    }, (err) => {
      //catches the errors
      console.log(err)
    }, () => {
      // gets the functions from storage refences the image storage in firebase by the children
      // gets the download url then sets the image from firebase as the value for the imgUrl key:
      storage.ref(`${filepath}/${authUserEmail}`).child(`${uniqueImageName}`).getDownloadURL()
       .then(fireBaseUrl => {
         onpage(fireBaseUrl)
         console.log(fireBaseUrl)
       })
      //  .then(alert('업데이트가 완료되었습니다.'))
    })
  }
    // console.log(imageFile)
  return (
      <div>
         <form onSubmit={handleFireBaseUpload}>
            <input 
              type="file"
              onChange={handleImageAsFile}
              id='lectureImgInput'
              />
            {/* <button type="submit" disabled={!imageAsFile}>업로드</button> */}
          </form>
      </div>
  );
};

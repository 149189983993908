import React, {useState} from 'react';
import * as firebase from "firebase";
import {getTimeString} from '../../helpers'

const ImageUpload = props => {
    const allInputs = {imgUrl: ''}
    const [imageAsFile, setImageAsFile] = useState('')
    const [imageAsUrl, setImageAsUrl] = useState(allInputs)
    const storage = firebase.storage()
    const {onUpdate, itemName, fileName} = props
    const timeString = getTimeString()
    const handleImageAsFile = (e) => {
        const image = e.target.files[0]
        setImageAsFile(imageFile => (image))
    }
    const handleFireBaseUpload = e => {
        e.preventDefault()
      // console.log('start of upload')
      // async magic goes here...
      const fileTypes = imageAsFile.name.split('.')
      const typeLeng = fileTypes.length
      const fileType = fileTypes[typeLeng-1].toLowerCase()
      // console.log(fileType)
      if (fileType !== 'png' && fileType !== 'jpg' && fileType !== 'jpeg'){
        console.error(`not an image, the image file is a ${typeof(imageAsFile)}`)
        alert('.png, .jpg/jpeg 파일만 업로드 가능합니다.')
      } else {
      const uploadTask = storage.ref(`/tlp/${fileName}/${timeString}_${imageAsFile.name}`).put(imageAsFile)
      //initiates the firebase side uploading 
      uploadTask.on('state_changed', 
      (snapShot) => {
        //takes a snap shot of the process as it is happening
        // console.log(snapShot)
      }, (err) => {
        //catches the errors
        console.log(err)
      }, () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        storage.ref(`tlp/${fileName}`).child(`${timeString}_${imageAsFile.name}`).getDownloadURL()
         .then(fireBaseUrl => {
           setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
           onUpdate(itemName, fireBaseUrl)
         })
      })
      }
      }
      // console.log(imageAsFile)
    return (
        <div>
           <form onSubmit={handleFireBaseUpload}>
           <input 
                type="file"
                onChange={handleImageAsFile}
                />
                <button type="submit" disabled={!imageAsFile}>업로드</button>
            </form>
            {/* 업로드한 이미지 미리 보기 */}
            <img src={imageAsUrl.imgUrl} alt="이미지 파일"/>
        </div>
    );
};

export default ImageUpload
import React from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';

const authDownloadBtn = props => {
    let auth = localStorage.getItem('authUser')
    let {url, name} = props
    function nonAuth (){
        let result = window.confirm('로그인 후 이용 가능한 서비스 입니다. 로그인 하시겠습니까?')
        if(result){
            props.history.push(ROUTES.LOGIN)
        }
}
return(
<React.Fragment>
    {auth ? 
    <a className="douwmloadBtn" href={url} target='_blank'>{name}</a>
    :<a className="douwmloadBtn" onClick={()=>nonAuth()} target='_blank'>{name}</a>}
 </React.Fragment>
)
}
const AuthDownloadBtn = compose(
    withRouter,
    withFirebase,
  )(authDownloadBtn);

export default AuthDownloadBtn;
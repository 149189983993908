import React from "react";
import dBookImagePc1 from '../images/dBookImagePc1.png'
import dBookImagePc2 from '../images/dBookImagePc2.png'
import dBookImagePc3 from '../images/dBookImagePc3.png'
import dBookImagePc4 from '../images/dBookImagePc4.png'
import dBookImageTaMo1 from '../images/dBookImageTaMo1.png'
import dBookImageTaMo2 from '../images/dBookImageTaMo2.png'
import dBookImageTaMo3 from '../images/dBookImageTaMo3.png'
import dBookImageTaMo4 from '../images/dBookImageTaMo4.png'
import $ from "jquery";
// import "./styles/Spinner.css";

const Tutorial = props => {
  const pager = props.pager || true
  const btns = props.btns || true

  function setBookGuideSlidePrev () {
    var lastChild = $(".bookGuideFrame>ul>li").last();
    $(".bookGuideFrame>ul").stop().animate({"margin-left":"0"}, 500, function(){
        $(".bookGuideFrame>ul").prepend(lastChild).css("margin-left", "-100%");
    })
}
  function setBookGuideSlideNext () {
    var firstChild = $(".bookGuideFrame>ul>li").eq(0);
    $(".bookGuideFrame>ul").stop().animate({"margin-left":"-200%"}, 500, function(){
        $(".bookGuideFrame>ul").append(firstChild).css("margin-left", "-100%");
    })
}
  return (
    <React.Fragment>
                    <div className="bookGuideFrame">
                        <ul>
                            <li className="bookGuide4">
                                <ul className="bookGuideGroup">
                                    <li>
                                        <img className="dbookPcImage" src={dBookImagePc4} alt="디지털 북 사용 가이드 PC 이미지4"/>
                                        <img className="dbookTaMoImage" src={dBookImageTaMo4} alt="디지털 북 사용 가이드 반응형 이미지4"/>
                                    </li>
                                    <li>작성한 내용은 꼭 저장해 주세요</li>
                                    <li>
                                        페이지를 나가기 전 
                                        <span className="textBorder">저장하기</span>
                                        버튼을 꼭 눌러주세요. 저장하기 버튼을 누르지 않고 페이지를 나가면 작성한 내용이  저장되지 않습니다.
                                    </li>
                                </ul>
                            </li>
                            <li className="bookGuide1">
                                <ul className="bookGuideGroup">
                                    <li>
                                        <img className="dbookPcImage" src={dBookImagePc1} alt="디지털 북 사용 가이드 PC 이미지1"/>
                                        <img className="dbookTaMoImage" src={dBookImageTaMo1} alt="디지털 북 사용 가이드 반응형 이미지1"/>
                                    </li>
                                    <li>목차를 확인하고 페이지를 이동해 보세요</li>
                                    <li>
                                        <span className="textBorder">목차</span>
                                        버튼을 눌러 디지털 북의 목차를 확인해 보세요. 목차 제목을 선택하여 원하는 페이지로 이동할 수 있습니다.
                                        <span className="textBorder">이전</span>,<span className="textBorder">다음</span> 
                                        버튼을 눌러 페이지를 넘겨 보세요. 사용자 작성 공간이 있는 페이지는 작성을 완료해야 다음 페이지로 이동할 수 있습니다.
                                    </li>
                                </ul>
                            </li>
                            <li className="bookGuide2">
                                <ul className="bookGuideGroup">
                                    <li>
                                        <img className="dbookPcImage" src={dBookImagePc2} alt="디지털 북 사용 가이드 PC 이미지2"/>
                                        <img className="dbookTaMoImage" src={dBookImageTaMo2} alt="디지털 북 사용 가이드 반응형 이미지2"/>
                                    </li>
                                    <li>내 생각을 공유하고 원하는 페이지를 책갈피 해 두세요</li>
                                    <li>
                                        <span className="textBorder">공유</span>
                                        버튼을 눌러 XR BOOK 사이트에 나의 생각을 공유할 수 있습니다. my page의 Reflection Gallery 이용 현황 메뉴에서 내가 공유한 이미지들을 관리, 삭제할 수 있습니다. 
                                        상단의 <span className="textBorder">책갈피</span> 
                                        버튼을 눌러 기억 하고 싶은 페이지를 표시해 두세요. 하단의 책갈피 모음 버튼을 클릭하여 그 동안 표시 해 놓은 페이지들을 한 번에 확인할 수 있습니다.
                                    </li>
                                </ul>
                            </li>
                            <li className="bookGuide3">
                                <ul className="bookGuideGroup">
                                    <li>
                                        <img className="dbookPcImage" src={dBookImagePc3} alt="디지털 북 사용 가이드 PC 이미지3"/>
                                        <img className="dbookTaMoImage" src={dBookImageTaMo3} alt="디지털 북 사용 가이드 반응형 이미지3"/>
                                    </li>
                                    <li>나의 생각을 글로 적어보고, 그림으로 표현해 보세요</li>
                                    <li>
                                        <span className="textBorder">글 입력란</span>
                                        의 경우 정해진 칸을 초과하여 작성된 부분은 인쇄 시 내용이 잘릴 수 있습니다. 그림으로 표현해 볼 수 있는 사용자 작성 공간에서는 
                                        오른쪽 상단의 드로잉 <span className="textBorder">툴 박스</span>를 눌러 원하는 배경/붓 색상을 선택할 수 있습니다.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    {pager && 
                    <div className="bookGuidePager">
                        <div className="bookGuidePrev" onClick={()=>setBookGuideSlidePrev()}>&#60;</div>
                        <div className="bookGuideNext" onClick={()=>setBookGuideSlideNext()}>&#62;</div>
                    </div>}
                    {btns && 
                    <div className="bookGuideBtn">
                        <div className="bookGuideBtn1" onClick={()=>props.onBookGuide('never')}>다시 보지 않기</div>
                        <div className="bookGuideBtn1" onClick={()=>props.onBookGuide('again')}>바로 시작하기</div>
                        <div className="bookGuideBtn2" onClick={()=>props.onBookGuide('again')}>닫기</div>
                    </div>}
                    </React.Fragment>
  );
};
export default Tutorial
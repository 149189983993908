import React, { Component } from 'react';
import {getTimeString, getTimeDayjs} from '../../helpers'
import PageItem from '../BookItem/PageItem'
// import {TempMsg} from '../Utils/TempMsg'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import '../DObook.css'
import '../DObookResp.css'
import $ from "jquery";
import Spinner from '../../Utils/Spinner';

class Digitalbook_PC extends Component {
    state={
        // pageList: [],
        pageListFront:[],
        pageListBack:[],
        pageHeaderList: [],
        bookmarkList : [],
        pageId:0,
        pcPageId:0,
        lastPageId: 0,
        prevBtn : 'invalidBtn',
        nextBtn : 'invalidBtn',
        invalidFrontBtn:false,
        invalidBackBtn:false,
        isPageHeader : false,
        isBookmark : false
    }
    componentDidMount = ()=> {
        this.setState({
            pageList : this.props.digitalbook.pageList,
            pageId : this.props.pageId,
            pcPageId :parseInt((this.props.pageId+1)/2),
        }
        // ,()=>
        // this.onTextToPage(this.props.pageId)
        )
        // console.log('mountP :'+this.props.pageId + ',' + parseInt((this.props.pageId+1)/2))
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.digitalbook !== this.props.digitalbook){
            this.setState({
                pageList : this.props.digitalbook.pageList
            })
        }
        if(prevState.pageList !== this.state.pageList){
            let bookmarkList = this.state.pageList.filter(c=> c.bookmark === true)
            this.setState({
                bookmarkList
            })
        }
        if(
            prevState.pageList === undefined && 
            // prevState.pageList !== this.state.pageList && 
            this.state.pageList!==undefined
            ){
            
        let pageListFront = this.state.pageList.filter((c,id)=>id%2===0)
        let pageListBack = this.state.pageList.filter((c,id)=>id%2===1)
        let pageHeaderList = this.state.pageList.filter(c=> c.pageHeader !== 'hidden')
        let bookmarkList = this.state.pageList.filter(c=> c.bookmark === true)
        // console.log(pageListFront)
        // console.log(pageListBack)
            if(pageListBack.length !== pageListFront.length){
                // pageListFront.push({userSection:[], pageTitle:'', pageHeader:'hidden',pageBgImgUrl:'',pageBgImgColor:'white'})
                pageListBack.push({userSection:[], pageTitle:'', pageHeader:'hidden',pageBgImgUrl:'',pageBgImgColor:'white'})
            }
            this.setState({
                pageListFront, pageListBack, pageHeaderList, bookmarkList
            },()=> this.onTextToPage(this.props.pageId))

        this.props.onPageListChange('pageList', this.state.pageList)
        }
    if(prevProps.lastPageId !== this.props.lastPageId){
        this.setState({
            lastPageId:this.props.lastPageId
        })
    } 
    if(prevProps.pageId !== this.props.pageId){
        let id = this.props.pageId
        // console.log(this.props.pageId)
        this.setState({
            pageId : id,
            pcPageId :parseInt((this.props.pageId+1)/2),
        }
        , () =>
        this.onTextToPage(id)
        // console.log('props '+prevProps.pageId, this.props.pageId)
        )
        
    }  
    if(this.state.lastPageId !== 0 && prevState.lastPageId !== this.state.lastPageId
        && this.state.lastPageId < this.state.pageListFront.length){
        let id = this.state.lastPageId
        // console.log('here!')
        // console.log(prevState.lastPageId, this.state.lastPageId)
    }
}
  handleChange = (pageItem, showBookmark) => {
    //   console.log(showBookmark)
    let currentPageList = this.state.pageList.slice()
    if (showBookmark==="front" ) {     
    currentPageList[this.state.pageId] = pageItem
    } else {
    currentPageList[this.state.pageId-1] = pageItem
    }
    this.setState({
        pageList: currentPageList, 
     })
    this.props.onPageListChange('lastPageId', this.state.pageId)
  }
  drawingFrontChange = (className, contents, idx) => {
    // console.log(className, contents, idx)
    let currentPageList = this.state.pageList.slice()
    currentPageList[this.state.pageId].userSection[idx][className] = contents
    currentPageList[this.state.pageId].isShared = true
    this.setState({pageList : currentPageList});
    this.props.onPageListChange('lastPageId',this.state.pageId)
  }
  drawingBackChange = (className, contents, idx) => {
    // console.log(className, contents, idx)
    let currentPageList = this.state.pageList.slice()
    currentPageList[this.state.pageId-1].userSection[idx][className] = contents
    currentPageList[this.state.pageId-1].isShared = true
    this.setState({pageList : currentPageList});
    this.props.onPageListChange('lastPageId',this.state.pageId)
  }
  onPageChange = type => {
    $(".headingTextBox").removeClass("showHeading");
    $(".bookMarkbox").removeClass("show").addClass("hidden");
    let {pageId, pageList, pcPageId}=this.state
    // console.log(pageId, pcPageId)
    let halfLeng = parseInt((pageList.length+1)/2)
    if (type ==='prev' && pcPageId >0){
        this.props.onPageListChange('pageIdPC',-1)
        //이전페이지 함수 추가
    } else if (type ==='next' && pcPageId < halfLeng) {
        // 제이쿼리 효과 적용때문에 halfLeng에서 -1 안함
        // console.log(pcPageId, this.state.pageId)
        let currentFrontPage = this.state.pageListFront[pcPageId]
        let currentBackPage = pcPageId>0 ? this.state.pageListBack[pcPageId-1] : []
        let isValid = true
        let writingValueArray = []
            for(let i in currentFrontPage.userSection){
               if(currentFrontPage.userSection[i].writingValue !== undefined){
                writingValueArray.push(currentFrontPage.userSection[i].writingValue)
               }  
            }
            for(let i in currentBackPage.userSection){
                if(currentBackPage !==[] && currentBackPage.userSection[i].writingValue !== undefined){
                 writingValueArray.push(currentBackPage.userSection[i].writingValue)
                }  
             }
            let isNotEmpty = (writingValue) => writingValue !==''
            isValid = writingValueArray.every(isNotEmpty)

        if(!isValid){
            alert('작성을 완료하셔야 다음 페이지로 이동이 가능합니다.')
        } else {
        this.props.onPageListChange('pageIdPC',1)
        // console.log('next '+pageId)
        //다음페이지 함수 추가
    }
    }
}
setBookmark = (pageId) => {
    $(".headingTextBox").removeClass("showHeading");
    $(".bookMarkbox").removeClass("show").addClass("hidden");
        let currentPageList = this.state.pageList.slice()
        if(currentPageList[pageId]['bookmark'] === undefined || currentPageList[pageId]['bookmark'] === false ){
            currentPageList[pageId]['bookmark'] = true
            currentPageList[pageId]['bookmarkAt'] = getTimeString()
            this.TempMsg('checkedBM')
            this.setState({
                pageList: currentPageList,  
             }, this.TempMsg('checkedBM'))
        }else if(currentPageList[pageId]['bookmark'] === true){
            currentPageList[pageId]['bookmark'] = false
            currentPageList[pageId]['bookmarkAt'] = ''
            this.setState({
                pageList: currentPageList, 
             }, this.TempMsg('unCheckedBM'))
        }  
}
deleteBookmark = page => {
    $(".headingTextBox").removeClass("showHeading");
    $(".bookMarkbox").removeClass("show").addClass("hidden");
    let currentPageList = this.state.pageList.slice()
    let idx = currentPageList.indexOf(page)
    currentPageList[idx]['bookmark'] = false
    this.setState({
        pageList: currentPageList, 
     }, this.TempMsg('unCheckedBM'))
}
setSharemark = (pageId) => {
    $(".headingTextBox").removeClass("showHeading");
    $(".bookMarkbox").removeClass("show").addClass("hidden");
    let currentPageList = this.state.pageList.slice()
    if(currentPageList[pageId]['shareMark'] === undefined || currentPageList[pageId]['shareMark'] === false ){
        currentPageList[pageId]['shareMark'] = true
        currentPageList[pageId]['shareMarkAt'] = getTimeString() 
        this.setState({
            pageList: currentPageList, 
         }, this.TempMsg('checkedSM'))

    }else if(currentPageList[pageId]['shareMark'] === true) {
        alert('페이지 공유 취소는 프로그램 종료 후 MY PAGE - Reflection Gallery 이용 현황에서 가능합니다.')
        // currentPageList[pageId]['shareMark'] = false
        // currentPageList[pageId]['shareMarkAt'] = ''
        // this.setState({
        //     pageList: currentPageList, 
        //  }, this.TempMsg('unCheckedSM'))
    }
    
    
}
TempMsg = (type) => {
    return () => {
      switch (type) {
        case 'checkedBM':
          NotificationManager.info('Notification', '책갈피 저장', 1500, () => {
            alert('callback');
          });
          break;
        case 'unCheckedBM':
            NotificationManager.error('Notification', '책갈피 삭제', 1500, () => {
                alert('callback');
              });
          break;
          case 'checkedSM':
          NotificationManager.info('Notification', '페이지 공유', 1500, () => {
            alert('callback');
          });
          break;
        case 'unCheckedSM':
            NotificationManager.error('Notification', '페이지 공유 취소', 1500, () => {
                alert('callback');
              });
          break;
      }
    };
};
onPropsChange = (name, value) => {
this.setState({
    [name] : value
})
}
onTextToPage = (id) => {
    $(".headingTextBox").removeClass("showHeading");
    $(".bookMarkbox").removeClass("show").addClass("hidden");
    $(".bookMarkList").removeClass("listShow");
    if(id%2 === 0){
        let lastPage=parseInt((id)/2)-1
        // console.log(parseInt((id+1)/2)+','+(this.state.pageList.length/2))
        if(parseInt((id)/2) >= parseInt(this.state.pageList.length/2)){
            $('.bookPagePC').removeClass('ready flipped active pointerEventsNone pageShow');
            $(".bookPagePC"+parseInt((id)/2)).addClass("active");
            $(".bookPagePC"+lastPage).prevAll().addClass("flipped pointerEventsNone");
            $(".bookPagePC"+lastPage).addClass("flipped");
            $(".bookPagePC"+lastPage).prev().addClass("pageShow");
            // console.log('evenF1 '+parseInt((id)/2))
        } else {
            $('.bookPagePC').removeClass('ready flipped active pointerEventsNone pageShow');
            $(".bookPagePC"+parseInt((id)/2)).addClass("active");
            $(".bookPagePC"+parseInt((id)/2)).prevAll().addClass("flipped pointerEventsNone");
            $(".bookPagePC"+lastPage).prevAll().addClass("flipped pointerEventsNone");
            $(".bookPagePC"+lastPage).removeClass("pointerEventsNone");
            $(".bookPagePC"+lastPage).addClass("flipped");
            $(".bookPagePC"+lastPage).prev().addClass("pageShow");
            $(".bookPagePC"+parseInt((id)/2)).next().addClass("ready");
        // console.log('evenF2 '+parseInt((id)/2))
        }
        // console.log('evenF '+parseInt((id)/2))
    } else {  
        let lastPage=parseInt((id+1)/2)-1
        // console.log('oddF '+(this.state.pageList.length/2))
        if(parseInt((id+1)/2) === parseInt(this.state.pageList.length/2)){
            $('.bookPagePC').removeClass('ready flipped active pointerEventsNone pageShow');
            $(".bookPagePC"+lastPage).prevAll().addClass("flipped pointerEventsNone");
            $(".bookPagePC"+lastPage).addClass("flipped");
            $(".bookPagePC"+lastPage).prev().addClass("pageShow");
            // $(".bookPagePC"+parseInt((id+1)/2)-1).removeClass("pointerEventsNone")
            // $(".bookPagePC"+parseInt((id+1)/2)).next().addClass("ready");
            // console.log('oddF1 '+lastPage)
        } else {
            $('.bookPagePC').removeClass('ready flipped active pointerEventsNone pageShow');
            $(".bookPagePC"+parseInt((id+1)/2)).addClass("active");;
            $(".bookPagePC"+parseInt((id+1)/2)).prevAll().addClass("flipped pointerEventsNone");
            $(".bookPagePC"+lastPage).prevAll().addClass("flipped pointerEventsNone");
            $(".bookPagePC"+lastPage).removeClass("pointerEventsNone");
            $(".bookPagePC"+lastPage).addClass("flipped");
            $(".bookPagePC"+lastPage).prev().addClass("pageShow");
            $(".bookPagePC"+parseInt((id+1)/2)).next().addClass("ready");
        // console.log('oddF2 ')
        }
    }
}
onTextBox = () => {
    $(".headingTextBox").toggleClass("showHeading");
    $(".bookMarkbox").removeClass("show").addClass("hidden");
    $(".bookMarkList").removeClass("listShow");
}
onBookMarkBox = () => {
    $(".headingTextBox").removeClass("showHeading");
    if($(".bookMarkbox").hasClass("show")){
        $(".bookMarkbox").removeClass("show").addClass("hidden");
        $(".bookMarkList").removeClass("listShow");
    } else if ($(".bookMarkbox").hasClass("hidden")){
        $(".bookMarkbox").removeClass("hidden").addClass("show");
        $(".bookMarkList").addClass("listShow");
    }
}
onMoveto = (pageNum) => {
    this.props.onPageListChange('pageId',parseInt(((pageNum+1)/2))*2)
    if(this.props.pageId===parseInt(((pageNum+1)/2))*2){
        $(".headingTextBox").removeClass("showHeading");
    }
}
    render() {
        let {prevBtn, nextBtn, pageId, pageList, pcPageId, pageListFront, pageListBack,
            isPageHeader, isBookmark, pageHeaderList, bookmarkList, invalidFrontBtn, invalidBackBtn } = this.state
            // console.log(pageList)
        return (
            <React.Fragment>
                {/* <button onClick={()=>this.onTextToPage(26)}>14</button> */}
                <div className="previewFrame pcPreviewFrame">    
                    {/* 목차 보이기/안보이기 클래스명 변경은 아래에서 하시면 됩니다. */}
                    <div className="bookPageFrame">
                        <div className="headingTextBox">
                            {/* <div className={isPageHeader ? 'showHeadingText':'hiddenHeadingText'}> */}
                            {pageHeaderList&& pageHeaderList.map((c, id)=> {
                                let pageNum = this.state.pageList.indexOf(c)
                                let isValidPage = pageNum > this.props.seenPageId
                                return(
                                    <div
                                    onClick={()=> !isValidPage ? 
                                        this.onMoveto(pageNum)
                                    : alert('선택된 페이지 이전에 열람/작성되지 않은 항목이 있어 열람할 수 없습니다.')}
                                    key={id}
                                    className={c.pageHeader === 'header1' ?   `heading1`
                                            : c.pageHeader === 'header2' ? `heading2`
                                            : c.pageHeader === 'header3' ?  `heading3`
                                            : c.pageHeader === 'hidden' ?  `noHeading`
                                            : `heading5`}
                                    // style={Object.assign( !isValidPage? {textDecoration:'none'} : {textDecoration:'none'}, c.pageHeader === "header1" ? {color:'#0d3258'}
                                    // : c.pageHeader === "header2" ? {color:'#263f58'}
                                    // : c.pageHeader === "header3" ? {color:'#36485a'}
                                    // :{color:'black'})}
                                    >{c.pageTitle}</div>
                                )
                            })
                            }
                            {/* </div> */}
                        </div>
                        {pageList === undefined ?
                        <Spinner msg='디지털 북을 불러오는 중입니다.'/>
                        :
                        <div className="scene">
                            <div className="bookPageGroup">
                                {pageListFront && pageListFront.map((c,id) => {
                                    return (
                                    <div key={id}
                                    className={id === 0 ?   `bookPagePC bookPagePC${id} active`
                                                        : `bookPagePC bookPagePC${id}`}>
                                    
                                    <form onSubmit={this.handleSubmit} className="frontPageBox">
                                            <PageItem pageItem={c} handleChange={this.handleChange} drawingChange={this.drawingFrontChange} isChanged={()=>this.props.onPageListChange('lastPageId',this.state.pageId)}
                                            setBookmark={()=>this.setBookmark((id*2))} pageId={(id*2)}
                                            mybookId={this.props.mybookId} showBookmark='front' setSharemark={()=>this.setSharemark(id*2)} />
                                        </form>
                                        <form onSubmit={this.handleSubmit}className="backPageBox">          
                                            <PageItem pageItem={pageListBack[id]} handleChange={this.handleChange} drawingChange={this.drawingBackChange} isChanged={()=>this.props.onPageListChange('lastPageId',this.state.pageId)}
                                            setBookmark={()=>this.setBookmark((id*2)+1)} pageId={(id*2)+1}
                                            mybookId={this.props.mybookId} showBookmark='back' setSharemark={()=>this.setSharemark((id*2)+1)}/>
                                        </form>   
                                    </div>
                                    )})
                                }
                            </div>
                        </div>
                        }
                    </div>
                    <div className="utilityBtnGroup">
                        <div className="headingBtnBox">
                            <span className="headingBtn"
                            onClick={()=>this.onTextBox()}
                            // onClick={()=>this.onPropsChange('isPageHeader', !isPageHeader)}
                            >목차</span>
                        </div>
                        <div className="bookMarkListBox">
                            <div className="bookMarkList">
                                <span className="bookMarkbox hidden">
                                {bookmarkList.length>0 ? bookmarkList.map((c, id)=> {
                                    let pageNum = this.state.pageList.indexOf(c)
                                    let isBookmarkAt = getTimeDayjs(c.bookmarkAt)
                                    return(
                                    <ul key={id}>
                                        <li onClick={()=>this.onMoveto(pageNum)}>{c.pageTitle}</li>
                                        <li>
                                            <span>p.{pageNum}</span>
                                            <span>{isBookmarkAt}</span>
                                            <span  onClick={()=> this.deleteBookmark(c)}>×</span>
                                        </li>
                                    </ul>
                                    )
                                }) : <ul><li>저장된 책갈피가 없습니다.</li></ul>
                                }
                                </span>
                            </div>
                            <div className="bookMarkListBtn" onClick={()=>this.onBookMarkBox()}>책갈피</div>
                        </div>
                        <div className="bookBtnBox">
                            <span className="curPageSign">{pcPageId} / {pageListFront.length}</span>
                            <div>
                                <span className={`${prevBtn} prevBtn`} onClick={()=>this.onPageChange('prev')}>이전</span>
                                <span className={`${nextBtn} nextBtn`} onClick={()=>this.onPageChange('next')}>다음</span>
                            </div>
                        </div>
                    </div>
                </div>
            {/* 페이지 하단 메뉴*/}
            <NotificationContainer/>
         </React.Fragment>
        )
    }
}
export default Digitalbook_PC
import app from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/database';
import 'firebase/storage';

const config = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_DATABASE_URL,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APPID,
    measurementId: process.env.REACT_APP_MEASUREMENTID
};

class Firebase {
  constructor() {
    app.initializeApp(config);

    /* Helper */

    this.fieldValue = app.firestore.FieldValue;
    this.emailAuthProvider = app.auth.EmailAuthProvider;

    /* Firebase APIs */

    this.auth =  app.auth()
    this.auth.setPersistence(app.auth.Auth.Persistence.SESSION)
    this.db = app.firestore()
    this.Rdb = app.database()
    this.storage = app.storage()
    /* Social Sign In Method Provider */
    //구글로 로그인 시 email verified는 어떻게 되는지 체크
    // 지금 메인페이지, Xr 제작페이지는 email 인증 안되어있으면 landingPage로 이동
    //role 입력도 체크
    this.googleProvider = new app.auth.GoogleAuthProvider();
  }
  doCredential = (email, password) => {
    app.auth.EmailAuthProvider.credential(email, password)
  }
  doCreateUserWithEmailAndPassword = (email, password) =>
    this.auth.createUserWithEmailAndPassword(email, password);

  doSignInWithEmailAndPassword = (email, password) =>
    this.auth.signInWithEmailAndPassword(email, password);
  
  doSignInWithGoogle = () =>{
    this.auth.signInWithPopup(this.googleProvider);
}

  doSignOut = () => {
    this.auth.signOut()
    .then(()=> window.location.reload())
  }

  doPasswordReset = email => this.auth.sendPasswordResetEmail(email);

  doSendEmailVerification = () =>
    this.auth.currentUser.sendEmailVerification(
      {url : 'https://www.xrbook.net/xrbook/login'}
    );

  doPasswordUpdate = password =>
    this.auth.currentUser.updatePassword(password);

  // *** Merge Auth and DB User API *** //

  // if(!localStorage.getItem("xrbook-token")){

  // onAuthUserToken = async () => {
  //   try {
  //     const messaging = app.messaging();  
  //     await messaging.requestPermission();
  //     const token = await messaging.getToken();
  //     // console.log('user token: ', token);  
  //     return token;
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }
  // }

  onAuthUserListener = (next, fallback) =>
    this.auth.onAuthStateChanged(authUser => {
      if (authUser) {
        this.user(authUser.uid)
          .get()
          .then(snapshot => {
            const dbUser = snapshot.data();
            // merge auth and db user
            authUser = {
              uid: authUser.uid,
              email: authUser.email,
              emailVerified: authUser.emailVerified,
              providerData: authUser.providerData,
              ...dbUser,
            };
            // console.log(authUser)
            next(authUser);
          });
        }else {
        fallback();
      }
    });

    // DB 저장 정보
    // 통계
    tlpStatistics = () => this.db.doc(`statistics/tlp`);

    statistics = () => this.db.collection('statistics');
    //회원
    user = uid => this.db.doc(`users/${uid}`);

    users = () => this.db.collection('users');

    // 메인페이지
    mainSection = section => this.db.doc(`main/${section}`);
    // section - intro, class, self, xrMaking, gallery, service
    main = () => this.db.collection('main');

    //nav
    alert = content => this.db.doc(`alerts/${content}`); 

    alerts = () => this.db.collection('alerts');
    
    //event, banner, popup
    banner = eid => this.db.doc(`banners/${eid}`);

    banners = () => this.db.collection('banners');

    tlpEvent = eid => this.db.doc(`tlpEvent/${eid}`);

    tlpEvents = () => this.db.collection('tlpEvent');

    popup1 = eid => this.db.doc(`popups/ImagePopup1`);

    popups = () => this.db.collection('popups');

    //프로그램 데이터
    program = pid => this.db.doc(`programs/${pid}`);

    programs = () => this.db.collection('programs');

    //프로그램 신청 데이터
    tlpProgram = time_authUserId => this.db.doc(`tlpProgram/${time_authUserId}`);

    tlpPrograms = () => this.db.collection('tlpProgram');

    //프로그램 신청 && 디지털북 열람 이용자 데이터 (name, email, programId, tlpProgramId)
    nonAuthReader = rid =>  this.db.doc(`nonAuthReader/${rid}`)

    nonAuthReaders = () =>  this.db.collection(`nonAuthReader`)

    //교육회원
    instituteList = rid =>  this.Rdb.ref(`instituteList`).child(`${rid}`)

    instituteLists = () =>  this.Rdb.ref(`instituteList`)

    instituteAuthReader = rid =>  this.db.doc(`instituteAuthReader/${rid}`)

    instituteAuthReaders = () =>  this.db.collection(`instituteAuthReader`)

    instituteHistory = rid =>  this.Rdb.ref(`instituteHistory`).child(`${rid}`)

    instituteHistorys = () =>  this.Rdb.ref(`instituteHistory`)

    // AR app 로그인 전용
    
    ARappLogin = uid =>  this.Rdb.ref(`ARappLogin`).child(`${uid}`)
    //online Learning program 데이터
    sale = pid => this.db.doc(`sales/${pid}`);

    salesVrEvent = () => this.db.doc(`sales/vrEvent`)

    sales = () => this.db.collection('sales');

    //online e 러닝 문의 데이터
    tlpSale = mid => this.db.doc(`tlpSales/${mid}`);

    tlpSales = () => this.db.collection('tlpSales');

    //결제 요청 데이터
    tlpPayment = order_id => this.db.doc(`tlpPayments/${order_id}`);

    tlpPayments = () => this.db.collection('tlpPayments');

    //가상 계좌 결제 시 임시 저장
    tlpPaymentReady = order_id => this.db.doc(`tlpPaymentReadys/${order_id}`);

    tlpPaymentReadys = () => this.db.collection('tlpPaymentReadys');

    //책 구매 데이터
    tlpRefundItem = id => this.db.doc(`tlpRefundItems/${id}`);

    tlpRefundItems = () => this.db.collection('tlpRefundItems');

    // 제작한 디지털북
    library = id => this.db.doc(`library/${id}`);

    libraries = () => this.db.collection('library');

    //유저들의 서재
    mybook = id => this.db.doc(`mybooks/${id}`)

    mybooks = () => this.db.collection('mybooks');

    //리뷰
    review = id => this.db.doc(`reviews/${id}`)

    reviews = () => this.db.collection('reviews');

    //메인페이지 갤러리용 공유된 페이지
    sharingPage = id => this.db.doc(`sharingPages/${id}`)

    sharingPages = () => this.db.collection('sharingPages');

    //메인페이지 갤러리용 공유된 페이지 AR용
    ARappActivity = id => this.db.doc(`ARappActivity/${id}`)

    ARappActivitys = () => this.db.collection('ARappActivity');

    // 결제된 디지털북 품목별 데이터
    paidDigitalBook = id => this.db.doc(`paidDigitalBook/${id}`);

    paidDigitalBooks = () => this.db.collection('paidDigitalBook');

    // 본인 인증 결과 저장
    danalAuth = id => this.db.doc(`danalAuth/${id}`);

    danalAuths = () => this.db.collection('danalAuth');

    //XR BOOK 서비스 데이터
    tlpXrMaking = time_authUserId => this.db.doc(`tlpXrMaking/${time_authUserId}`);

    tlpXrMakings = () => this.db.collection('tlpXrMaking');

    //문의하기
    inquiry = id => this.db.doc(`inquiries/${id}`);

    inquiries = () => this.db.collection('inquiries')

    //책구매를 위한 쿠폰 코드
    promotionCode = id => this.db.doc(`promotionCodes/${id}`);

    promotionCodes = () => this.db.collection('promotionCodes')

    //oculus 코드
    oculusGoCodeList = id => this.db.doc(`oculusGoCodeLists/${id}`);

    oculusGoCodeLists = () => this.db.collection('oculusGoCodeLists')

    //App Download Url
    appUrl = () => this.db.doc(`appUrls/urlList`);

    //App Download Url
    animationPageOpen = () => this.db.doc(`appUrls/animationPageOpen`);

    //test

    testDoc = id => this.db.doc(`testCol/${id}`);
    testCol = () => this.db.collection('testCol')

    errorCol = () => this.db.collection('errorCol')

}

export default Firebase;

import React, { Component } from 'react'
import ImageUploadNR from '../../../Utils/ImageUploadNR'
import DigitalbookPageTool from './DigitalbookPageTool'
import { compose } from 'recompose';
import {withAuthentication, withAuthorization} from '../../../Session'
import Digitalbook_PC_Preview from '../../../Digitalbook/Preview/Digitalbook_PC_Preview'
import Digitalbook_Mobile_Preview from '../../../Digitalbook/Preview/Digitalbook_Mobile_Preview'
import './DigitalbookToolPage.css'
import $ from 'jquery'

class DigitalbookToolPage extends Component {
    state={
        pageList:[{userSection:[], pageTitle:'', pageHeader:'hidden', pageBgImgUrl:'',pageBgImgColor:'white'}],
        previewPageList : [],
        isPreview : 'none',
        // page:[],
        pageId:0,
        bookTitle : '',
        bookTarget: '미입력',
        iconUrl: '',
        prevBtn : 'invalidBtn',
        nextBtn : 'invalidBtn'
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.digitalbook !== this.props.digitalbook && this.props.digitalbook !==[]){
            this.setState({
                pageList : this.props.digitalbook.pageList || [{userSection:[], pageTitle:'', pageHeader:'hidden', pageBgImgUrl:'',pageBgImgColor:'white'}],
                bookTitle : this.props.digitalbook.bookTitle || '',
                bookTarget : this.props.digitalbook.bookTarget || '미입력',
                iconUrl : this.props.digitalbook.iconUrl || '',
                pageId:0,
                isPreview : 'none',
            })
            // this.props.onSetData(this.props.digitalbook.bid)
        } else if (prevProps.digitalbook !== this.props.digitalbook && this.props.digitalbook ===[]){
            this.setState({
                pageList : [{userSection:[], pageTitle:'', pageHeader:'hidden', pageBgImgUrl:'',pageBgImgColor:'white'}],
                bookTitle : '',
                bookTarget: '미입력',
                iconUrl : '',
                pageId:0,
            })
        }
        if(prevState.pageList !== this.state.pageList){
        if(prevState.pageList !== 'none' && this.state.pageList === 'none'){
            $('html, body').css("overflow", "inherit")
        } else if(prevState.pageList === 'none' && this.state.pageList !== 'none'){
            $('html, body').css("overflow", "hidden");
        }
        }
        if(prevState.isPreview !== this.state.isPreview){
            if(this.state.pageList.length === 1){
            this.setState({prevBtn:'invalidBtn', nextBtn:'invalidBtn'})}
            else if(this.state.pageList.length > 1 && this.state.pageId < this.state.pageList.length-1){
                this.setState({prevBtn:'invalidBtn', nextBtn:'validBtn'})
            }
        }
        if(prevState.pageId !== this.state.pageId){
            if (this.state.pageList.length === 2 && this.state.pageId === 1){
                this.setState({ prevBtn:'validBtn', nextBtn:'invalidBtn'})
            }
            else if (this.state.pageList.length === 2 && this.state.pageId === 0){
                this.setState({ prevBtn:'invalidBtn', nextBtn:'validBtn'})
            }
            else if(this.state.pageId === 0  && this.state.pageList.length>2){
                this.setState({ prevBtn:'invalidBtn', nextBtn:'validBtn'})
            } 
            else if (this.state.pageList.length > 2 && this.state.pageId === this.state.pageList.length-1){
                this.setState({ prevBtn:'validBtn', nextBtn:'invalidBtn'})
            } else {
                this.setState({
                    nextBtn:'validBtn',
                    prevBtn:'validBtn'
                })
            }
    }
}

    onPageOrder = (type, idx) => {
        // console.log('idx : '+idx)
        let tempPageList = this.state.pageList.slice()
        let pageListLeng = tempPageList.length
        if(type=='up' && idx >0){
            let copyList = tempPageList.splice(idx,1)
            tempPageList.splice(idx-1,0,copyList[0])
        }else if(type=='down' && idx <pageListLeng-1) {
            let copyList = tempPageList.splice(idx,1)
            tempPageList.splice(idx+1,0,copyList[0])
        } else if (type==='delete') {
            let copyList = tempPageList.splice(idx,1)
        }
        this.setState({
            pageList : tempPageList
        })
    }
    onPageChange = type => {
        let {pageId, pageList} = this.state
        if (type ==='prev' && pageId >0){
            this.setState((prevState, props) => ({ pageId : prevState.pageId-1}))
        } else if (type ==='next' && pageId < pageList.length -1) {
            this.setState((prevState, props) => ({ pageId : prevState.pageId+1}))
        }
    }
    onPropsChange = (name, value) => {
    this.setState({
        [name] : value
    })
    }
    setPreview = pageList => {
        if(this.state.isPreview === 'pc' || this.state.isPreview === 'mobile'){

            this.setState({
                // isPreview : 'none',
                previewPageList : pageList
            })
    } else {
        this.setState({
            isPreview : 'pc',
            previewPageList : pageList
        })
    }
    }
    onDeleteimg = () => {
        let ImageUrl = this.state.iconUrl
        
        // if(ImageUrl !==''){
        //     let decodeURI = decodeURIComponent(ImageUrl).split('tlp/digitalbook/admin/')[1].split('?alt=')[0]
        //     this.props.firebase.storage.ref(`tlp/digitalbook/admin/`).child(decodeURI)
        //     .delete()
        //     .then(()=>{
                this.setState({
                    iconUrl : ''
                })
        //         alert('이미지가 삭제되었습니다.')
        //     })
        //     .catch(err => console.log(err))
        // }
    }
    render() {
        let { bookTitle, iconUrl, pageList, previewPageList, isPreview, bookTarget, bookTargetText} = this.state
        let arr = ['미입력', '성인', '청소년', '어린이', '기타']
        return (
            <div className="adminDigitalBookFrame">
                <h3>디지털 북 제작</h3>

                <h5>1. 제목/아이콘</h5>
                <div className="bookIDFrame">
                    <div>
                        <p>제목</p>
                        <input type='text' name='bookTitle' value={bookTitle}
                        onChange={e=> this.onPropsChange('bookTitle', e.target.value)}/>
                        <p> 대상 구분(관리자 용)</p>
                        <select value={bookTarget} onChange={e=> this.onPropsChange('bookTarget', e.target.value)}>
                            <option value='미입력'>미입력</option>
                            <option value='성인'>성인</option>
                            <option value='청소년'>청소년</option>
                            <option value='어린이'>어린이</option>
                            <option value='기타'>기타</option>
                            <option value=''>직접입력</option>
                        </select>
                        {arr.indexOf(bookTarget)<0 &&
                        <input type='text' name='bookTarget' value={bookTarget} placeholder='직접 입력하세요.'
                        onChange={e=> this.onPropsChange('bookTarget', e.target.value)}/>}
                    </div>
                    <div>
                        <p>아이콘 업로드</p>
                        <ImageUploadNR
                            onUpdate={this.onPropsChange}
                            itemName={'iconUrl'}
                            fileName={'digitalbook/admin/'}
                            /> 
                        <div>
                            <div><img src={iconUrl} alt="upload"/></div>
                            {/* <span onClick={()=>this.onDeleteimg()}>아이콘 삭제</span> */}
                        </div>
                    </div>
                </div>
                <h5>2. 내용 만들기</h5>
                <div className="makingPageFrame"><DigitalbookPageTool digitalbook={pageList}
                onSubmitDigitalbook={(pageList)=>this.props.onSubmitDigitalbook(pageList,bookTitle, iconUrl, bookTarget)}
                setPreview={this.setPreview}
                onSetData={(bid)=>this.props.onSetData(bid)}
                /></div>

                {isPreview === 'pc' ? 
                <div className="adminPreviewBg">
                    <h5>3. 미리 보기(PC, tablet)</h5>
                    <div className="makingPreviewBtn">
                        <div onClick={()=> this.setState({isPreview : "mobile"})}>한장으로 보기</div>
                        <div onClick={()=> this.setState({isPreview : "none"})}>미리보기 종료</div>
                    </div>
                    <Digitalbook_PC_Preview previewPageList={previewPageList}/>
                </div>
                :isPreview === 'mobile' ? 
                <div className="adminPreviewBg">
                    <h5>3. 미리 보기(tablet, mobile)</h5>
                    <div className="makingPreviewBtn">
                        <div onClick={()=> this.setState({isPreview : "pc"})}>두장으로 보기</div>
                        <div onClick={()=> this.setState({isPreview : "none"})}>미리보기 종료</div>
                    </div>
                    <Digitalbook_Mobile_Preview previewPageList={previewPageList}/>
                </div>
                :null}
            </div>
        )
    }
}
const condition = authUser =>
authUser

export default
compose(
    withAuthentication,
withAuthorization(condition))
(DigitalbookToolPage);
import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import * as ROUTES from '../../constants/routes'

class SelfInfo extends Component {
    render() {
        let {headShow} = this.props
        return (
            <div className="onLearnGuideFrame">
                <h5 style={headShow ? {}:{display:'none'}}>On-line Learning Program 수강 가이드</h5>
                <ul>
                    <li>
                        <ul>
                            <li className="onlineGuideImg">
                                <span></span>
                            </li>
                            <li className="onlineGuideText">
                                <span>1. Program 수강 신청/결제</span>
                                <span>https://www.xrbook.net 사이트 “Program 신청”페이지 [On-line Learning 신청]에서 옵션 선택하여 결제 진행</span>
                                <span>결제가 완료되면 프로그램 이용을 위한 수강 코드와 함께 안내 메일이 결제 시 기재한 프로그램 대상자에게 전송됩니다.</span>
                                <span>* 회사(단체)경우 사전 문의 – 결제 완료- 수강자 등록- 수강 코드 일괄 발송 됩니다.</span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li className="onlineGuideImg">
                                <span></span>
                            </li>
                            <li className="onlineGuideText">
                                <span>2. 수강 코드 등록</span>
                                <span>https://www.xrbook.net 사이트 “MY PAGE”의 [내 서재]에서 메일로 받은 북 코드 입력</span>
                                <span>[내 서재]에 북 코드를 등록 시 프로그램 기간이 시작됩니다.</span>
                                <span>* 북 수신 후 7일 이후 등록은 7일째 등록한 것으로 프로그램 종료일 계산됩니다.</span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li className="onlineGuideImg">
                                <span></span>
                            </li>
                            <li className="onlineGuideText">
                                <span>3. Program 수강 시작</span>
                                <span>Digital Work Book으로  VR, AR, 2D Animation, Reflection Writing 활동 프로그램 진행</span>
                                <span>
                                    오큘러스 고, 카드 보드 등 VR 기기를 활용하여 VR을 체험하고, 디지털 워크 북을 작성하고,
                                    AR App을 통해 어린 왕자의 이야기를 더 재미있게 경험 해 볼 수 있습니다. 
                                </span>
                                <span>- Digital Book 위치 : [ https://www.xrbook.net ] {`>`} [ MY PAGE ] {`>`} [ 내 서재 ] </span>
                                <span>- AR App 다운 방법 : <Link to={ROUTES.APP_DOWNLOAD}>다운로드 페이지 이동</Link></span>
                                <span>- 지원 기기 : 갤럭시S7 이상(안드로이드 ver26)  아이폰 6s 이상</span>
                                {/* <span style={{color:"#c56e6e", fontWeight:"600"}}>※ 원활한 프로그램 수강을 위해 Chrome 웹 브라우저 이용을 권장합니다.</span> */}
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li className="onlineGuideImg">
                                <span></span>
                            </li>
                            <li className="onlineGuideText">
                                <span>4. Program 수강 종료</span>
                                <span>Digital Work Book을 모두 작성하면 내가 작성한 나만의 Digital Book 책이 생성</span>
                                <span>마지막 페이지까지 디지털 워크 북을 진행하거나 프로그램 수강 종료일이 지나면 프로그램이 종료됩니다.</span>
                                <span>프로그램이 종료되면 작성한 디지털 워크 북은 '나의 디지털 북'으로 열람 가능합니다.</span>
                                
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li className="onlineGuideImg">
                                <span></span>
                            </li>
                            <li className="onlineGuideText">
                                <span>5. 소장용 책 인쇄 ( 옵션 )</span>
                                <span>원하는 표지를 선택하여 작성 완료된 Digital Book의 인쇄</span>
                                <span>결제 시 인쇄 옵션을 선택한 경우, 프로그램 종료 후 소장용 책 인쇄 절차가 진행됩니다. </span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li className="onlineGuideImg">
                                <span></span>
                            </li>
                            <li className="onlineGuideText">
                                <span>6. 책 배송 완료</span>
                                <span>인쇄된 책이 사용자에게 배송</span>
                                <span>세상에 하나뿐인 나만의 어린 왕자 소장용 책을 받을 수 있습니다.</span>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        )
    }
}
export default SelfInfo
import React, { Component } from 'react'
import {withFirebase} from '../../Firebase'
import dayjs from 'dayjs'
import {getTimeDayjs} from '../../helpers'
import {Link} from 'react-router-dom'
import * as ROUTES from '../../../constants/routes'

class NonauthDigitalbook extends Component {
    state = {
        myBookList : [],
        lastVisible:{}
    }
    componentDidMount(){
        this.getMyBookList()
    }
    getMyBookList =()=> {
        this.props.firebase.nonAuthReaders()
        .orderBy('isFinishedAt', "desc").limit(20)
        .get()
        .then(result => {
            var lastVisible = result.docs[result.docs.length-1];
            var myBookList = []
            result.forEach(res => {
                var listData = res.data()
                var mybookId = res.id
                myBookList.push({mybookId, ...listData})
            })
            this.setState({myBookList, lastVisible})
        })
    }
    getNextMyBookList =()=> {
        let scrollPointX = window.scrollX
        let scrollPointY = window.scrollY
        let {myBookList, lastVisible} = this.state
        if(lastVisible !==undefined){
        this.props.firebase.nonAuthReaders().orderBy('isFinishedAt', "desc").startAfter(lastVisible).limit(20).get()
        .then(result => {
            var lastVisible = result.docs[result.docs.length-1];
            result.forEach(res => {
                var listData = res.data()
                var mybookId = res.id
                myBookList.push({mybookId, ...listData})
            })
            this.setState({myBookList, lastVisible})
            window.scrollTo(scrollPointX, scrollPointY)
        })
    } else {
            alert('최신 데이터 입니다.')
        }
        
        
    }
    render() {
        let {myBookList} = this.state
        // console.log(myBookList)
        let {totalmybookNon} = this.props
        return (
            <React.Fragment>
                <p>기업 회원 디지털북 총 {totalmybookNon}건</p>
                <ul className="adminReadingCate">
                    <li>순번</li>
                    <li>디지털 북 이름</li>
                    <li>저자</li>
                    <li>완료 일자</li>
                </ul>
                {myBookList.length>0 ? myBookList.map((c,id)=>{
                    let {name, title, bookData, isFinishedAt } = c
                    var isFinishedAtFormatted2 = getTimeDayjs(isFinishedAt)
                    return(
                        <ul className="adminReadingList" key={id}>
                            <li><span>{id+1}</span></li>
                            <li>
                                <Link to={{pathname: `${ROUTES.XRBOOK_CHECK_ADMIN}`, state: {
                                        bookData : bookData, bookTitle : bookData['bookTitle'], title : `${bookData['bookTitle']} - 저자 : ${name}`
                                    }}} ></Link>
                            </li>
                            <li><span>{bookData.bookTitle} - 저자 : {name}</span></li>
                            <li><span>{isFinishedAtFormatted2}</span></li>
                        </ul>
                    )
                }) : 
                <div>완성된 디지털 북이 없습니다.</div>}
                <button className="viewMoreListBtn" onClick={()=>this.getNextMyBookList()}>시간순 20개 더보기</button>
            </React.Fragment>
        )
    }
}
export default withFirebase(NonauthDigitalbook)
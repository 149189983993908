import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../../../Firebase';
import * as ROUTES from '../../../../constants/routes';
import UserItem from './UserItem'

class FacilitatorList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: [],
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.users().where('status','==','active').onSnapshot(snapshot=> {
      let users = [];
      snapshot.forEach(doc =>
          users.push({ ...doc.data(), uid: doc.id }),
      );

      this.setState({
        users,
        loading: false,
      });
    });
  }

  render() {
    const { users, loading } = this.state;
    return (
      <div>
        <h2>Users</h2>
        <div className="userListFrame" style={{textAlign:"center"}}>
        <table>
          <thead>
            <tr>
              <th>번호</th>
              <th>메일</th>
              <th>이름</th>
              <th>관리</th>
            </tr>
          </thead>
          <tbody>
          {loading && <tr><td colSpan='4'>Loading ...</td></tr>}
          {users.map((user, id) => (
            <tr key={user.uid}>
              <UserItem user={user} idx={id+1}/>
              </tr>
          ))}
          </tbody>
        </table>
        </div>
      </div>
    );
  }
}

export default withFirebase(FacilitatorList);

import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import * as ROUTES from '../../../constants/routes'
import { withFirebase } from '../../Firebase';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Spinner from '../../Utils/Spinner'
import VideoListInstituteUser from '../../Facilitator/VideoListInstituteUser'
import Manual from "../../Guide/Manual";
import InstituteAuthAdmin from './InstituteAuthAdmin'
import InstituteAuthSuper from './InstituteAuthSuper'
import InstituteAuthProfessor from './InstituteAuthProfessor'
import $ from 'jquery'
import styled from 'styled-components';


class NonAuthMain extends Component {
    state={
        loading: true,
        show: 'videoList',
        instituteReaderData: this.props.location.state !== undefined ? this.props.location.state.instituteReaderData : {},
        instituteInfo : {},
        instituteId:'',
        isPwChange : false,
        isSamePw : false,
        prePw : '',
        newPw : '',
        checkPw : '',
        prePwMsg : '',
        newPwMsg : '',
        checkPwMsg : ''
    }
    componentDidUpdate (prevProps, prevState) {
        if(prevState.instituteId === '' && this.state.instituteId !== ''){
            let instituteList = []
            let instituteId = this.state.instituteId
            // console.log(instituteId)
            this.props.firebase.instituteList(`${instituteId}`).once('value').then((snapshot) => {
                  var listData = snapshot.val();
                  this.setState({
                    instituteInfo : listData,
                    loading : false
                })
            })
            
        }
    }
    componentDidMount(){
        let instituteAuthReader = JSON.parse(sessionStorage.getItem('instituteAuthReader'))
        // console.log(instituteAuthReader)
        if(this.props.location.state == undefined){       
        if(instituteAuthReader !== null){
            this.props.firebase.instituteAuthReader(instituteAuthReader.uid).get()
            .then(res=>{
                let result = res.data()
                this.setState({
                    instituteReaderData : result,
                    instituteId : result.instituteId,
                    isPwChange : instituteAuthReader['username'] === instituteAuthReader['userPw'] ? true : false,                    
                })
            })
        } else {
            this.props.history.push(ROUTES.XRBOOK_INSTITUTEAUTH_LOGIN)
        }
    }
        if(instituteAuthReader){
            this.setState({
                instituteReaderData : instituteAuthReader,
                instituteId : instituteAuthReader.instituteId,
                isPwChange : instituteAuthReader['username'] === instituteAuthReader['userPw'] ? true : false
            })
        } else {
            this.props.history.push(ROUTES.XRBOOK_INSTITUTEAUTH_LOGIN)
        }
    }
    deleteSession = () => {
        sessionStorage.removeItem('instituteAuthReader');
        window.location.reload()
    }
    First = () => {
        this.setState({show : 'videoList'});
        $(".InstituteHomeFrame>.statisticsTabBtnGourp div").css({"border":"1px solid #dedede", "color":"#aaaaaa"});
        $(".InstituteHomeFrame>.statisticsTabBtnGourp div").eq(0).css({"border-bottom":0,"color":"#000000" });    
      }
    Second = ()=>{
        this.setState({show : 'arvrManual'});
        $(".InstituteHomeFrame>.statisticsTabBtnGourp div").css({"border":"1px solid #dedede", "color":"#aaaaaa"});
        $(".InstituteHomeFrame>.statisticsTabBtnGourp div").eq(1).css({"border-bottom":0,"color":"#000000" });
      }
    Third = ()=>{
    this.setState({show : 'adminPage'});
    $(".InstituteHomeFrame>.statisticsTabBtnGourp div").css({"border":"1px solid #dedede", "color":"#aaaaaa"});
    $(".InstituteHomeFrame>.statisticsTabBtnGourp div").eq(2).css({"border-bottom":0,"color":"#000000" });
    }
    Fourth = ()=>{
        this.setState({show : 'SuperadminPage'});
        $(".InstituteHomeFrame>.statisticsTabBtnGourp div").css({"border":"1px solid #dedede", "color":"#aaaaaa"});
        $(".InstituteHomeFrame>.statisticsTabBtnGourp div").eq(2).css({"border-bottom":0,"color":"#000000" });
        }
    Fifth = ()=>{
        this.setState({show : 'professorPage'});
        $(".InstituteHomeFrame>.statisticsTabBtnGourp div").css({"border":"1px solid #dedede", "color":"#aaaaaa"});
        $(".InstituteHomeFrame>.statisticsTabBtnGourp div").eq(2).css({"border-bottom":0,"color":"#000000" });
        }
    onCheckPassword = (e) => {
        let name = e.target.name
        let value = e.target.value
        let {prePw, newPw, checkPw} = this.state
        if(name === 'checkPw') {
            if(value!==''&& newPw !== value){
                this.setState({
                    checkPwMsg : 'notSame'
                  });
            } else {
                this.setState({
                    checkPwMsg : ''
                  });
            }
        }
        this.setState({
            [name]: value,
          });
      };
      onChangePassword = ()=>{
          let {instituteReaderData, prePw, newPw, checkPw, prePwMsg, newPwMsg, checkPwMsg} = this.state
        //   console.log(instituteReaderData)
          if(instituteReaderData.userPw !== prePw){
            alert('현재 비밀번호가 일치하지 않습니다.')
          } else if(newPw !== checkPw){
            alert('변경 비밀번호가 일치하지 않습니다.')
          } else {
            let newPwData = { userPw : newPw }
            this.props.firebase.instituteAuthReader(instituteReaderData.uid).update(newPwData)
            .then(()=>{
                let instituteAuthReader = {...instituteReaderData, ...newPwData}
                sessionStorage.setItem('instituteAuthReader', JSON.stringify(instituteAuthReader))
                alert('변경이 완료되었습니다.')
                this.setState({
                    isPwChange : false,
                    prePw : '',
                    newPw : '',
                    checkPw : '',
                    prePwMsg : '',
                    newPwMsg : '',
                    checkPwMsg : '',
                    instituteReaderData : instituteAuthReader
                })
            })
          }
      }
    render() {
         let {loading, instituteReaderData, show, instituteInfo, instituteAuthReader, isPwChange,
            checkPwMsg, prePw, newPw, checkPw} = this.state
        return (
            <React.Fragment>
                {loading ?
                    <Spinner msg='데이터 불러오는 중'/>
                :
                <div className="adminStaticsFrame InstituteHomeFrame">
                    
                    <div className="statisticsTabBtnGourp">
                        <div onClick={()=>this.First()}>영상 시청</div>
                        <div onClick={()=>this.Second()}>AR/VR</div>
                        {instituteReaderData.type === 'A'&&
                        <div onClick={()=>this.Third()} >관리자</div>}
                        {instituteReaderData.type === 'SA'&&
                        <div onClick={()=>this.Fourth()} >학생정보</div>}
                        {instituteReaderData.type === 'P'&&
                        <div onClick={()=>this.Fifth()} >학생정보</div>}
                        
                    </div>
                    <div className="statisticsTabContGroup">
                            {show === 'videoList' ? 
                                <div className='FacilitatorPage'>
                                <VideoListInstituteUser instituteReaderData={instituteReaderData} instituteInfo={instituteInfo}/>
                                </div>
                            :
                            show === 'arvrManual' ? 
                            <div>
                                <Manual instituteUser={true}/>
                            </div>
                        
                            : show === 'adminPage' ? 
                            <div>
                                <InstituteAuthAdmin propsChange={this.First}/>
                            </div>
                            : show === 'SuperadminPage' ? 
                            <div>
                                <InstituteAuthSuper propsChange={this.First}/>
                            </div>
                            : show === 'professorPage' ? 
                            <div>
                                <InstituteAuthProfessor propsChange={this.First}/>
                            </div>
                            : null}
                       
                    </div>
                    {isPwChange && 
                    <div className='modalPopBg'>
                        <div className="eventPopFrame modalPopFrame " > 
                            <div className="eventPopTitle modalPopTitle "> 
                                <h5 style={{borderBottom : 'none'}}>비밀번호 변경</h5>
                                <div onClick={()=>this.setState({isPwChange : false})}>X</div>
                            </div>
                            <div className='modalPopBodyWrapper'>
                                <div className='modalPopBody'>
                                    <span>현재 비밀번호</span>
                                    <span>
                                        <input type='text' value={prePw} name='prePw' onChange={(e)=>this.onCheckPassword(e)}/>
                                    </span>
                                </div>
                                <div className='modalPopBody'>
                                    <span>변경 비밀번호</span>
                                    <span>
                                        <input type='password' value={newPw} name='newPw' onChange={(e)=>this.onCheckPassword(e)}/>
                                    </span>
                                </div>
                                <div className='modalPopBody'>
                                    <span>비밀번호 확인</span>
                                    <span>
                                        <input type='password' value={checkPw} name='checkPw' 
                                        className = {checkPwMsg} onChange={(e)=>this.onCheckPassword(e)}/>
                                    </span>
                                    {/* <span>{checkPwMsg}</span> */}
                                </div>
                                <div className='modalPopBody modalPopBodyBtn'>
                                    <button onClick={()=>this.onChangePassword()}>변경</button></div>
                                </div>
                                <div style={{fontSize:'1vw'}}>** 비밀번호 분실 시 교수학습개발센터로 연락바랍니다.</div>
                        </div>
                       
                    </div>}
                    <div style={{display:'flex', justifyContent:'flex-end'}}><button onClick={()=>this.setState({isPwChange:true})}
                    style={{marginTop:'2vw', marginBottom:'5vw'}}>비밀번호 변경</button></div>
                </div>    
                }
            </React.Fragment>
            
        )
    }
}
export default compose(
    withRouter,
    withFirebase,
  )(NonAuthMain);
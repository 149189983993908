import React, { Component } from 'react';
import '../../DObook.css'
import '../../DObookResp.css'
import {getTimeString, getTimeDayjs} from '../../../helpers'
import PageCheckItem from '../../BookItem/PageCheckItem'
import $ from "jquery";
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import { faBookmark, faList } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class Digitalbook_Mobile_Admin extends Component {
    state={
        pageList:[],
        pageHeaderList: [],
        bookmarkList : [],
        pageId:0,
        lastPageId: 0,
        prevBtn : 'invalidBtn',
        nextBtn : 'invalidBtn',
        isValidBtn:false,
        isPageHeader : false,
        isBookmark : false,
    }
    componentDidMount(){
        this.setState({
            pageList : this.props.digitalbook.pageList,
            pageId : this.props.pageId,
        }
        , ()=> 
        //console.log('mountM :'+this.props.pageId + ',' + parseInt((this.props.pageId+1)/2))
        this.onTextToPageM(this.props.pageId)
        )
    }

    componentDidUpdate(prevProps, prevState){
        if(prevProps.digitalbook !== this.props.digitalbook){
            this.setState({
                pageList : this.props.digitalbook.pageList
            })
        }
        if(prevProps.pageList !== this.props.pageList){
            this.setState({
                pageList : this.props.digitalbook.pageList
            })
        }
        if(prevState.pageList !== this.state.pageList){
            let pageHeaderList = this.state.pageList.filter(c=> c.pageHeader !== 'hidden')
            let bookmarkList = this.state.pageList.filter(c=> c.bookmark === true)
            this.setState({
                pageHeaderList, bookmarkList
            })
            this.props.onPageListChange('pageList', this.state.pageList)
        }
        if(prevProps.pageId !== this.props.pageId){
            let id = this.props.pageId
            this.setState({
                pageId : id
            })
            this.onTextToPageM(id)
            // console.log('done')
        }
    if(prevProps.lastPageId !== this.props.lastPageId){
        this.setState({
            lastPageId:this.props.lastPageId
        })
    } 
    if(this.state.lastPageId !== 0 && prevState.lastPageId !== this.state.lastPageId){
        let id = this.state.lastPageId
        // console.log(prevState.lastPageId, this.state.lastPageId)
        // this.onTextToPageM(id)
    }
}

  onPageChange = type => {
    let {pageId, pageList}=this.state
    let pageLeng = pageList.length+1
    if (type ==='prev' && pageId >0){
        this.props.onPageListChange('pageIdMobile', -1)
    } else if (type ==='next' && pageId < (pageLeng-1)) {
        this.props.onPageListChange('pageIdMobile', +1)
    }
    }
onPropsChange = (name, value) => {
this.setState({
    [name] : value
})
}
setBookmark = (pageId) => {
    $(".headingTextBox").removeClass("showHeading");
    let currentPageList = this.state.pageList.slice()
    // console.log(currentPageList[pageId]['bookmark'])
    if(currentPageList[pageId]['bookmark'] === undefined || currentPageList[pageId]['bookmark'] === false ){
        currentPageList[pageId]['bookmark'] = true
        currentPageList[pageId]['bookmarkAt'] = getTimeString()
        this.sendTempMsg('checkedBM')
        this.setState({
            pageList: currentPageList,  
         }, this.sendTempMsg('checkedBM'))
        this.props.onSaveDigitalbookPage(currentPageList)
    }else if(currentPageList[pageId]['bookmark'] === true){
        // $(".bookMarkbox").removeClass("hidden").addClass("show");
        currentPageList[pageId]['bookmark'] = false
        currentPageList[pageId]['bookmarkAt'] = ''
        this.setState({
            pageList: currentPageList, 
         }, this.sendTempMsg('unCheckedBM'))
         this.props.onSaveDigitalbookPage(currentPageList)
    }
}
deleteBookmark = page => {
    let currentPageList = this.state.pageList.slice()
    let idx = currentPageList.indexOf(page)
    currentPageList[idx]['bookmark'] = false
    this.setState({
        pageList: currentPageList, 
     }, this.sendTempMsg('unCheckedBM'))
    this.props.onSaveDigitalbookPage(currentPageList)
}
setSharemark = (pageId) => {
    let currentPageList = this.state.pageList.slice()
    if(currentPageList[pageId]['shareMark'] === undefined || currentPageList[pageId]['shareMark'] === false ){
        currentPageList[pageId]['shareMark'] = true
        currentPageList[pageId]['shareMarkAt'] = getTimeString() 
        this.setState({
            pageList: currentPageList, 
         }, this.sendTempMsg('checkedSM'))
         this.props.onSaveSharingPage(pageId, currentPageList[pageId], currentPageList)
    }else if(currentPageList[pageId]['shareMark'] === true) {
        alert('페이지 공유 취소는 MY PAGE - Reflection Gallery 이용 현황에서 가능합니다.')
    }
}
sendTempMsg = (type) => {
    return () => {
      switch (type) {
        case 'checkedBM':
          NotificationManager.info('Notification', '책갈피 저장', 1500, () => {
            alert('callback');
          });
          break;
        case 'unCheckedBM':
            NotificationManager.error('Notification', '책갈피 삭제', 1500, () => {
                alert('callback');
              });
          break;
          case 'checkedSM':
          NotificationManager.info('Notification', '페이지 공유', 1500, () => {
            alert('callback');
          });
          break;
        case 'unCheckedSM':
            NotificationManager.error('Notification', '페이지 공유 취소', 1500, () => {
                alert('callback');
              });
          break;
      }
    };
};
onTextToPageM = (id) => {
    $(".bookMarkList").removeClass("listShow");
    let lastPage = id-1
    if(id === this.state.pageList.length){
        // console.log(lastPage) 
        $(".bookPage"+lastPage).removeClass("active").addClass("flipped");
        $(".bookPage"+lastPage).prevAll().addClass("flipped");
        $(".bookPage"+lastPage).addClass("pageShow");
    } else {
        $(".headingTextBox").removeClass("showHeading");
        $(".bookMarkbox").removeClass("show").addClass("hidden");
        // this.props.onPageListChange('pageId',id)
        $('.bookPage').removeClass('ready flipped active pageShow');
        $(".bookPage"+id).addClass("active");
        $(".bookPage"+id).prevAll().addClass("flipped");
        $(".bookPage"+id).prev().addClass("pageShow");
        $(".bookPage"+id).next().addClass("ready");
    }
}
onTextBox = () => {
    $(".headingTextBox").toggleClass("showHeading");
    $(".bookMarkbox").removeClass("show").addClass("hidden");
    $(".bookMarkList").removeClass("listShow");
}
onBookMarkBox = () => {
    $(".headingTextBox").removeClass("showHeading");
    if($(".bookMarkbox").hasClass("show")){
        $(".bookMarkbox").removeClass("show").addClass("hidden");
        $(".bookMarkList").removeClass("listShow");
    } else if ($(".bookMarkbox").hasClass("hidden")){
        $(".bookMarkbox").removeClass("hidden").addClass("show");
        $(".bookMarkList").addClass("listShow");
    }
}
onMoveto = (pageNum) => {
    // console.log('pageNum'+pageNum)
    this.props.onPageListChange('pageId',pageNum)
    if(this.props.pageId===parseInt(((pageNum+1)/2))*2){
        $(".headingTextBox").removeClass("showHeading");
    }
}
    render() {
        let {prevBtn, nextBtn, pageId, pageList, lastPageId,
            isPageHeader, isBookmark, pageHeaderList, bookmarkList, isValidBtn } = this.state
        let totalPageNum = pageList.length
        // console.log(pageId)
        return (
            <React.Fragment>
                <div className="previewFrame moPreviewFrame"  style={{display:'flex', flexDirection:'row'}}> 

                    {/* 목차 보이기/안보이기 클래스명 변경은 아래에서 하시면 됩니다. */}
                    <div className="bookPageFrame">
                        <div className="headingTextBox">
                            {/* <div className={isPageHeader ? 'show':'hidden'}> */}
                            {pageHeaderList&& pageHeaderList.map((page, id)=> {
                                let pageNum = this.state.pageList.indexOf(page)
                                let isValidPage = pageNum > this.props.seenPageId
                                // console.log(pageNum, this.props.seenPageId)
                                return(
                                    <div
                                    onClick={()=> !isValidPage ?  this.onMoveto(pageNum) : alert('선택된 페이지 이전에 작성되지 않은 항목이 있어 열람할 수 없습니다.')}
                                    key={id}
                                    // style={{textDecoration:'underline'}}
                                    className={page.pageHeader === 'header1' ?   `heading1`
                                                            : page.pageHeader === 'header2' ? `heading2`
                                                            : page.pageHeader === 'header3' ?  `heading3`
                                                            : page.pageHeader === 'hidden' ?  `heading4`
                                                            : `heading5`}
                                    // style={Object.assign( !isValidPage? {textDecoration:'underline'} : {textDecoration:'none'}, page.pageHeader === "header1" ? {color:'red'}
                                    //  : page.pageHeader === "header2" ? {color:'orange'}
                                    //  : page.pageHeader === "header3" ? {color:'green'}
                                    //  :{color:'black'})}
                                >{page.pageTitle}</div>
                                )
                            })
                            }
                            {/* </div> */}
                        </div>
                        <div className="Scene mobileScene">
                            <div className="bookPageGroup">
                                {pageList && pageList.map((c, id)=> {
                                    return (
                                        <div key={id}
                                        className={id === 0 ?   `bookPage bookPage${id} active`
                                                        : `bookPage bookPage${id}`}>
                                        <form  className="curPageBox" onSubmit={this.handleSubmit} onChange={this.handleBackChange} >                      
                                            <PageCheckItem pageItem={c} handleChange={this.handleChange} drawingChange={this.drawingChange}
                                            isChanged={()=>this.props.onPageListChange('lastPageId',this.state.pageId)} pageId={pageId}
                                            setBookmark={()=>this.setBookmark(pageId)} setSharemark={()=>this.setSharemark(pageId)} totalPageNum={`${pageId} / ${totalPageNum+1}`}
                                            mybookId={this.props.mybookId} showBookmark='front'/>
                                        </form>
                                    </div>
                                )})}
                            </div>
                        </div>
                    </div>
                    <div className="utilityBtnGroup">
                        <div className="headingBtnBox">
                            <span className="headingBtnText" onClick={()=>this.onTextBox()}>목차</span>
                            <span className="headingIconText" onClick={()=>this.onTextBox()}><FontAwesomeIcon icon={faList} /></span>
                        </div>
                        <div className="bookMarkListBox">
                            <div className="bookMarkList">
                                <span className="bookMarkbox hidden">
                                {bookmarkList.length>0 ? bookmarkList.map((c, id)=> {
                                    let pageNum = this.state.pageList.indexOf(c)
                                    let isBookmarkAt = getTimeDayjs(c.bookmarkAt)
                                    return(
                                    <ul key={id}>
                                        <li onClick={()=>this.onMoveto(pageNum)}>{c.pageTitle}</li>
                                        <li>
                                            <span>p.{pageNum}</span>
                                            <span>{isBookmarkAt}</span>
                                            <span  onClick={()=> this.deleteBookmark(c)}>×</span>
                                        </li>
                                    </ul>
                                    )
                                }) : <ul><li>저장된 책갈피가 없습니다.</li></ul>
                                }
                                </span>   
                            </div>
                            <div className="bookMarkListBtn BtnText" onClick={()=>this.onBookMarkBox()}>책갈피</div>
                            <div className="bookMarkListBtn BtnIcon" onClick={()=>this.onBookMarkBox()}><FontAwesomeIcon icon={faBookmark} /></div>
                        </div>
                        <div className="bookBtnBox">
                            <span className="curPageSign">{pageList.length >0 ? pageId : pageId} / {totalPageNum}</span>
                            <div>
                                <span className={`${prevBtn} prevBtn`} onClick={()=>this.onPageChange('prev')}>이전</span>
                                <span className={`${nextBtn} nextBtn`} onClick={()=>this.onPageChange('next')}>다음</span>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div style={{display:'flex', flexDirection:'row'}}>
                    <form onSubmit={this.handleSubmit} onChange={this.handleBackChange} >                      
                        <PageItem pageItem={pageList[pageId]} handleChange={this.handleChange} drawingChange={this.drawingChange}
                        isChanged={()=>this.props.onPageListChange('lastPageId',this.state.pageId)} pageId={pageId}
                        setBookmark={()=>this.setBookmark(pageId)} totalPageNum={`${pageId} / ${totalPageNum+1}`}
                        mybookId={this.props.mybookId}/>
                    </form>
                </div> */}
                {/* 페이지 하단 버튼 */}
                <NotificationContainer/>
            </React.Fragment>
        )
    }
}
export default Digitalbook_Mobile_Admin
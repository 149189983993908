import React, { Component } from 'react'
import {withFirebase} from '../../Firebase'
import dayjs from 'dayjs'
import { getTimeString } from '../../helpers'
let today = dayjs(new Date)
let todayString = getTimeString()
let purchased_at = today.format("YYYY-MM-DD HH:mm:ss")

class CreatedPaidData extends Component {
  state = {
  }
    serverTest = ()=>  {
      let data = {
        type:'online',
        id:'thehrder.ar@gmail.com',
        pw:'2021edu!'
      }
      console.log('fecth start')
        fetch("https://xrbook.net/api/v1/appLogin", {
          method: 'POST',
          headers: {'Content-Type': 'application/json'},
          body: JSON.stringify(data)
      })
      .then(response => { 
        // console.log(response)
        response.json().then((res)=>{
          console.log(res)
        })
        if(response.status === 200){
          alert('성공')
        } else {
          console.log('?')
        }
        })
      .catch(err => console.log(err))
      
      }
  
    render() { 
         return (
            <span style={{color:'white'}} onDoubleClick={()=>this.serverTest()}>서버테스트</span>
        )
    }
}
export default withFirebase (CreatedPaidData)
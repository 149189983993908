import React, { Component } from 'react'
import { withFirebase } from '../../Firebase'
import { faPaintBrush, faEraser, faPlus, faMinus, faTrashAlt, faHandPointLeft } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import './DrawPreviewApp.css'

//simple draw component made in react
class DrawPreviewApp extends React.Component {

    constructor(props) {
        super(props)
    }
    state = {
        pen:'',
        bgColor : 'white',
        canvas : this.refs.canvas,
        menuBar : false,
        colorBar : false,
        content:'',
        canvasSave:'',
        contentHeight: this.props.contentHeight,
        drawingWidth: 0, drawingHeight: 0,
        resizedWidth:window.innerWidth * 0.377,
        resizedHeight: window.innerWidth * 0.377 * (this.props.contentHeight || 30) /100,
        canvasHeight: window.innerWidth * 0.377 * (this.props.contentHeight || 30) /100,
        windowHeight: window.innerHeight
    }
    componentDidMount() {
        this.reset()
        window.addEventListener('resize', this.updateDimensions);
        // let content = this.refs.canvas.toDataURL('image/png')
        // this.setState({ content })
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.contentHeight !== this.props.contentHeight){
            this.updateDimensions()
        }
        if(prevProps.bgColor !== this.props.bgColor){
            this.setState({
                        bgColor:this.props.bgColor,
                    })
            }
        if(prevState.canvasWidth !== this.state.canvasWidth){
            let tempImage = new Image();
            tempImage.src = this.props.contents
            tempImage.crossOrigin= 'anonymous'
            tempImage.addEventListener('load', () => {
                this.ctx.drawImage(tempImage, 0, 0, this.state.resizedWidth, this.state.resizedHeight);
            })
                }
        if(prevState.canvasHeight !== this.state.canvasHeight){
            let tempImage = new Image();
            tempImage.src = this.props.contents
            tempImage.crossOrigin= 'anonymous'
            tempImage.addEventListener('load', () => {
                this.ctx.drawImage(tempImage, 0, 0, this.state.resizedWidth, this.state.resizedHeight);
            })
            this.updateDimensions()
                }
        if(prevState.windowHeight !== this.state.windowHeight){
            let tempImage = new Image();
            tempImage.src = this.props.contents
            tempImage.crossOrigin= 'anonymous'
            tempImage.addEventListener('load', () => {
                this.ctx.drawImage(tempImage, 0, 0, this.state.resizedWidth, this.state.resizedHeight);
            })
                }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }
    updateDimensions = () => {
        let pageWidth =
        window.innerWidth >1600 ? 535
        : window.innerWidth <=1600 &&window.innerWidth >1400 ? window.innerWidth * 0.324
        : window.innerWidth <=1400 && window.innerWidth >1024 ? window.innerWidth * 0.377
        : window.innerWidth <=1024 && window.innerWidth >834 ? window.innerWidth * 0.405
        : window.innerWidth <=834  ? window.innerWidth * 0.82
        : window.innerWidth* 0.82
        let elementWidth = document.getElementsByClassName('drawBGBx')[0]
        let canvasWidth = pageWidth
        this.ctx.canvas.width = canvasWidth
       let canvasHeight = canvasWidth * (this.props.contentHeight || 30) /100
       this.ctx.canvas.height = canvasHeight
       let resizedWidth = canvasWidth
       let resizedHeight = canvasHeight
       this.setState({canvasWidth, canvasHeight, resizedWidth, resizedHeight, windowHeight : window.innerHeight})
      };
    draw(e) { //response to Draw button click 
        this.setState({
            mode:'draw',
            colorBar:true
        })
    }

    erase() { //response to Erase button click
        this.setState({
            mode:'erase',
            colorBar:false
        })
    }

    drawing(e) { //if the pen is down in the canvas, draw/erase
        if(this.state.pen === 'down') {
           this.setState({
               colorBar:false,
                menuBar:false})
            this.ctx.beginPath()
            this.ctx.lineWidth = this.state.lineWidth
            this.ctx.lineCap = 'round';

            if(this.state.mode === 'draw') {
                
                // console.log(this.state.penCoords[0], this.state.penCoords[1])
                this.ctx.globalCompositeOperation="source-over";
                this.ctx.strokeStyle = this.state.penColor
                this.ctx.moveTo(this.state.penCoords[0], this.state.penCoords[1]) //move to old position
                this.ctx.lineTo(e.nativeEvent.offsetX, e.nativeEvent.offsetY) //draw to new position
            }

            if(this.state.mode === 'erase') {
                this.ctx.globalCompositeOperation="destination-out";
                this.ctx.arc(this.state.penCoords[0], this.state.penCoords[1],8,0,Math.PI*2,false);
                this.ctx.fill();

            }
            this.ctx.stroke();
            

            this.setState({ //save new position 
                penCoords:[e.nativeEvent.offsetX, e.nativeEvent.offsetY]
            })
        }      
    }
    penOut(e) { //mouse is down on the canvas
        this.setState({
            pen:'out',
            penCoords:[e.nativeEvent.offsetX, e.nativeEvent.offsetY]
        })
    }
    penDown(e) { //mouse is down on the canvas
        this.setState({
            pen:'down',
            penCoords:[e.nativeEvent.offsetX, e.nativeEvent.offsetY]
        })
    }

    penUp() { //mouse is up on the canvas
        this.setState({
            pen:'up'
        })
    }

    penSizeUp(){ //increase pen size button clicked
        this.setState({
            lineWidth: this.state.lineWidth += 5
        })
    }

    penSizeDown() {//decrease pen size button clicked
        this.setState({
            lineWidth: this.state.lineWidth -= 5
        })
    }

    setColor(c){ //a color button was clicked
        this.setState({
            penColor : c,
            mode:'draw'
        })
       
    }

    setBgColor(c){ //a color button was clicked
        this.setState({
            bgColor : c
        })
    }

    reset() { //clears it to all white, resets state to original
        this.setState({
            mode: 'draw',
            pen : 'up',
            lineWidth : 10,
            penColor : 'black',
            // bgColor: 'white'
        })
        
        this.ctx = this.refs.canvas.getContext('2d')
        this.ctx.fillStyle="transparent"
        this.ctx.fillRect(0,0,800,600)
        this.ctx.lineWidth = 10
        this.updateDimensions()
    }
    render() { 
        let {colorBar, menuBar, mode, penColor, bgColor, canvasHeight} = this.state
        let colorSwatches = {        
            white : 'white',
            red : '#950023',    
            orange : '#e88300',
            yellow : '#ffc000',
            green : '#008015',
            blue : '#003195',
            purple : '#4a0095',
            black : 'black',
            orangeBg : '#ffe2c3',
            yellowBg : '#fff9c3',
            greenBg : '#c7ffd5',
            blueBg : '#c7daff',
            purpleBg : '#e5c7ff',
        }
        return (
            <div className="drawFrame" >
               <div className="drawToolBx" >
                    <div className="propertyBx">
                    {menuBar&&  
                        <React.Fragment>
                            <span className="drawToolPen" onClick={(e)=>this.draw(e)} 
                            // style={mode ==='draw' ? {border:'2px solid black'} : null}
                            ><FontAwesomeIcon icon={faPaintBrush} /></span>
                            <span className="drawToolErase" onClick={(e)=>this.erase(e)} 
                            // style={mode ==='erase' ? {border:'2px solid black'} : null}
                            ><FontAwesomeIcon icon={faEraser} /></span>
                            <span className="drawToolSiseUP" 
                            onClick={(e)=>this.penSizeUp(e)}><FontAwesomeIcon icon={faPlus} /></span>
                            <span className="drawToolSiseDown" 
                            onClick={(e)=>this.penSizeDown(e)} ><FontAwesomeIcon icon={faMinus} /></span>
                            <span className="drawToolReset2" 
                            onClick={(e)=>this.reset2(e)}><FontAwesomeIcon icon={faTrashAlt} /></span>
                        </React.Fragment>}
                        <span onClick={()=> this.setState({menuBar : !menuBar, colorBar: false})}>
                            <FontAwesomeIcon icon={faHandPointLeft} />
                            {/* {this.props.contentHeight} */}
                            </span>
                    </div>
                    {colorBar&& <div className="colorGroup">
                        <div className="penColorBx">
                            <span>펜</span>
                            <span style={Object.assign({backgroundColor : colorSwatches.red }, penColor===colorSwatches.red ? {border:'2px solid #3ac9b0'} :{})} onClick={()=>this.setColor(colorSwatches.red)}></span>
                            <span style={Object.assign({backgroundColor : colorSwatches.orange }, penColor===colorSwatches.orange ? {border:'2px solid #3ac9b0'} :{})} onClick={()=>this.setColor(colorSwatches.orange)}></span>
                            <span style={Object.assign({backgroundColor : colorSwatches.yellow }, penColor===colorSwatches.yellow ? {border:'2px solid #3ac9b0'} :{})} onClick={()=>this.setColor(colorSwatches.yellow)}></span>
                            <span style={Object.assign({backgroundColor : colorSwatches.green }, penColor===colorSwatches.green ? {border:'2px solid #3ac9b0'} :{})} onClick={()=>this.setColor(colorSwatches.green)}></span>
                            <span style={Object.assign({backgroundColor : colorSwatches.blue }, penColor===colorSwatches.blue ? {border:'2px solid #3ac9b0'} :{})} onClick={()=>this.setColor(colorSwatches.blue)}></span>
                            <span style={Object.assign({backgroundColor : colorSwatches.purple }, penColor===colorSwatches.purple ? {border:'2px solid #3ac9b0'} :{})} onClick={()=>this.setColor(colorSwatches.purple)}></span>
                            <span style={Object.assign({backgroundColor : colorSwatches.black}, penColor===colorSwatches.black ? {border:'2px solid #3ac9b0'} :{})} onClick={()=>this.setColor(colorSwatches.black)}></span>
                        </div>
                        <div className="bgColorBx">
                            <span>배경</span>
                            <span style={Object.assign({backgroundColor : colorSwatches.white}, bgColor===colorSwatches.white ? {border:'2px solid #3ac9b0'} :{})} onClick={()=>this.setBgColor(colorSwatches.white)}></span>
                            <span style={Object.assign({backgroundColor : colorSwatches.orangeBg }, bgColor===colorSwatches.orangeBg ? {border:'2px solid #3ac9b0'} :{})} onClick={()=>this.setBgColor(colorSwatches.orangeBg)}></span>
                            <span style={Object.assign({backgroundColor : colorSwatches.yellowBg }, bgColor===colorSwatches.yellowBg ? {border:'2px solid #3ac9b0'} :{})} onClick={()=>this.setBgColor(colorSwatches.yellowBg)}></span>
                            <span style={Object.assign({backgroundColor : colorSwatches.greenBg }, bgColor===colorSwatches.greenBg ? {border:'2px solid #3ac9b0'} :{})} onClick={()=>this.setBgColor(colorSwatches.greenBg)}></span>
                            <span style={Object.assign({backgroundColor : colorSwatches.blueBg }, bgColor===colorSwatches.blueBg ? {border:'2px solid #3ac9b0'} :{})} onClick={()=>this.setBgColor(colorSwatches.blueBg)}></span>
                            <span style={Object.assign({backgroundColor : colorSwatches.purpleBg }, bgColor===colorSwatches.purpleBg ? {border:'2px solid #3ac9b0'} :{})} onClick={()=>this.setBgColor(colorSwatches.purpleBg)}></span>
                            <span style={Object.assign({backgroundColor : colorSwatches.black}, bgColor===colorSwatches.black ? {border:'2px solid #3ac9b0'} :{})} onClick={()=>this.setBgColor(colorSwatches.black)}></span>
                        </div>
                    </div>}
                </div>
                <div className="drawPaperBx" style={{height:`${canvasHeight}px`}}>
                    <div className="drawBGBx" style={{backgroundColor : `${this.state.bgColor}`
                                    // border:'1px solid black'
                                    // border:'1px solid red',
                                    // width:`${canvasWidth}px`,
                                    // height:`${itemHeight}px`,
                                    // position:'absolute',
                                }}></div>
                    <canvas id = 'resetCanvas' ref="canvas"
                    // style={{border:'1px solid blue'}}
                        onMouseMove={(e)=>this.drawing(e)} 
                        onMouseDown={(e)=>this.penDown(e)} 
                        onMouseUp={(e)=>this.penUp(e)}
                        onMouseOut={(e)=>this.penOut(e)}>
                    </canvas>
                </div>
            </div>
        )
    }
}
export default withFirebase(DrawPreviewApp)
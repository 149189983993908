import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import { getTimeDayjs, getTimeString, getTimeStringNotime, getTimeDayjsOrigin } from '../helpers'
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import DaumPostcode from 'react-daum-postcode';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';
import './User.css'
import './UserResp.css'
import ClassInquiries from './ClassInquiries'
import SelfInquiries from './SelfInquiries'
import SelfPayment from './SelfPayment'
import SelfLearning from './SelfLearning'
import SharingPage from './SharingPage'
import UserInfo from './UserInfo'
import ExtensionPay from  '../Order/bootPay/ExtensionPay'
import VrCode from './VrCode'


import XRBOOKLogo from '../images/XRBOOKLogo.png'
import dayjs from 'dayjs'
import $ from 'jquery'

class UserPage extends Component {
    state={
        // auth : localStorage.getItem('authUser'),
        mybookList:[],
        xrMakingList : [],

        library: this.props.location.state  !== undefined ? 
        this.props.location.state.tab ==='library' ? true : false : false,
        program: this.props.location.state  !== undefined ? 
        this.props.location.state.tab ==='program' ? true : false : false,
        sales: this.props.location.state  !== undefined ? 
        this.props.location.state.tab ==='sales' ? true : false : false,
        payment: this.props.location.state  !== undefined ? 
        this.props.location.state.tab ==='payment' ? true : false : false,
        xrbook: this.props.location.state  !== undefined ? 
        this.props.location.state.tab ==='xrbook' ? true : false : false,
        sharing: this.props.location.state  !== undefined ? 
        this.props.location.state.tab ==='sharing' ? true : false : false,
        reviews: this.props.location.state  !== undefined ? 
        this.props.location.state.tab ==='reviews' ? true : false : false,
        myInfo: this.props.location.state  !== undefined ? 
        this.props.location.state.tab ==='myInfo' ? true : false : false,

        authUser : JSON.parse(localStorage.getItem('authUser')),
        mybookId : '',
        bookcode1:'',
        bookcode2:'',
        digitalBookID:'',
        codeModal:false,
        codeMsg:'',
        shipmentModal:false, //false로 바꾸기
        isAddressOpen :false, //false로 바꾸기
        isVrCode:'',
        postCode:'',
        fullAddress:'',
        extraAddress:'',
    }
    checkMobileOnly = () => {
         if(navigator.userAgent.indexOf("Android")>0 || 
         navigator.userAgent.indexOf("iPhone") > 0|| 
         navigator.userAgent.indexOf("iPod") > 0|| 
         navigator.userAgent.indexOf("BlackBerry") > 0){
            return true
        } else {
            return false
        }
    }
    isMobileDevice = () => { 
        var phoneArray = new Array('samsung-', 'sch-', 'shw-', 'sph-', 'sgh-', 'lg-', 'canu', 'im-', 'ev-', 'iphone', 'nokia', 'blackberry', 'lgtelecom', 'natebrowser', 'sonyericsson', 'mobile', 'android'); 
        for (let i = 0; i < phoneArray.length; i++) { 
            if (navigator.userAgent.toLowerCase().indexOf(phoneArray[i]) > -1) { 
                return true; 
            } 
        } 
        return false; }
    isTabletDevice = () => {
        if (!this.isMobileDevice()) { return false; } 
    // 태블릿검사 
    if (navigator.userAgent.toLowerCase().indexOf('ipad') > -1 
    || (navigator.userAgent.toLowerCase().indexOf('android') > -1 && navigator.userAgent.toLowerCase().indexOf('mobile') == -1)) { 
        return true; }
     // 갤럭시 탭만을 위한 리다이렉트. Mobile 이라는 단어가 안들어오게 되면 지우셔도 됨
      var galaxyTabModel = new Array('shw-');
       for (let i = 0; i < galaxyTabModel.length; i++) {
            if (navigator.userAgent.toLowerCase().indexOf(galaxyTabModel[i]) > -1) { 
                return true;
             }
             } 
             return false; }

    componentDidMount(){
        try {
            // console.log(navigator.userAgent.toLowerCase())
            // console.log(this.isMobileDevice())
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    };
        let authUser= JSON.parse(localStorage.getItem('authUser'))
        let userRef = this.props.firebase.user(authUser.uid)
        userRef.get()
        .then(doc => {
            let res = doc.data()
                if(res.username===''){
                  alert('회원 정보를 완성해주세요.')
                 this.setState({
                    myInfo:'myInfo'
                 })
                }
          });
        this.setState({
            authUser : authUser,
            postCode : authUser.address ? authUser.address['postCode'] : '',
            fullAddress : authUser.address ? authUser.address['fullAddress'] : '',
            extraAddress : authUser.address ? authUser.address['extraAddress'] : ''
        })
        this.getMybookList()
    }
    componentDidUpdate(prevProps, prevState){
        if(!prevState.codeModal&&this.state.codeModal){
            $('html, body').css("overflow", "hidden");
        } else if(prevState.codeModal&& !this.state.codeModal) {
            $('html, body').css("overflow", "inherit")
        }
        if(!prevState.shipmentModal&&this.state.shipmentModal){
            $('html, body').css("overflow", "hidden");
        } else if(prevState.shipmentModal&& !this.state.shipmentModal) {
            $('html, body').css("overflow", "inherit")
        }
        if(!prevState.isAddressOpen&&this.state.isAddressOpen){
            $('html, body').css("overflow", "hidden");
        } else if(prevState.isAddressOpen&& !this.state.isAddressOpen) {
            $('html, body').css("overflow", "inherit")
        }
    }

    onstateChange = (show) => {
        const { library, program, sales, payment, xrbook, sharing, reviews, myInfo} = this.state;
        if(show ==='library') this.setState({library : !library})
        if(show ==='program') this.setState({program : !program})
        if(show ==='sales') this.setState({sales : !sales})
        if(show ==='payment') this.setState({payment : !payment})
        if(show ==='xrbook') this.setState({xrbook : !xrbook})
        if(show ==='sharing') this.setState({sharing : !sharing})
        if(show ==='reviews') this.setState({reviews : !reviews})
        if(show ==='myInfo') this.setState({myInfo : !myInfo})
    }
    getMybookList =()=> {
        let {authUser}=this.state
        this.props.firebase.mybooks().where('codeUser','==', authUser['uid'] ).orderBy('bookStartAt').get()
        .then(result => {
            var mybookList = []
            result.forEach(res => {
                var listData = res.data()
                var mybookId = res.id
                mybookList.push({mybookId, ...listData})
            })
            this.setState({mybookList})
        })
    }
    onPropsChange=(name,value)=>{
        if(name === 'bookcode1' && value.indexOf('_')>-1){
            let bookcode1 = value.split('_')[0]
            let bookcode2 = value.split('_')[1]
            this.setState({
                bookcode1, bookcode2, codeMsg : ''
            })
        } else {
        this.setState({
            [name] : value,
            codeMsg : ''
        })}
    }
    onSubmitCode=(e)=>{
        //결제된 코드인지 확인
        let {bookcode1, bookcode2, authUser} = this.state
        console.log(bookcode1, bookcode2)
        if(bookcode1!==''&&bookcode2!==''){
            let bookCode = `${bookcode1}_${bookcode2}`
        this.props.firebase.paidDigitalBooks().where('bookcode','==',bookCode).get()
        .then(result => {
            let list = []
            result.forEach(res=>{
                var listData = res.data()
                var pid = res.id
                list.push({pid, ...listData})
                console.log(pid)
            })
            if(list.length>1){
                alert('관리자에게 문의하세요')
            } else if (list.length === 1){
                let item = list[0]
                if (item['refundId'] !==undefined && item['refundId'] !=='' ){
                    alert('결제 취소 처리 중인 코드입니다.')
                    this.setState({
                        codeMsg : '올바른 코드 값이 아닙니다. 다시 확인해주세요.',
                    })
                } else if(item['status'] === 1){
                    if(item['codeStatus'] === false){
                        let period = item['period'] +1
                        let createdAt = getTimeDayjsOrigin(item['createdAt'])
                        console.log('createdAt')
                        console.log(createdAt)
                        let today  = dayjs(new Date())
                        var due = createdAt.add(6, 'day') // 코드 등록 후 7일 이내
                        // console.log('due')
                        // console.log(due)
                        let dueAfterPeriod = due.add(period, 'day')
                        // console.log('dueAfterPeriod')
                        // console.log(dueAfterPeriod)
                        var todayAfterPeriod = today.add(period, 'day')
                        // console.log('todayAfterPeriod')
                        // console.log(todayAfterPeriod)
                        // let todayString = todayAfterPeriod.format('')
                        var createdAfterPeriod = createdAt.add(period, 'day')
                        // console.log('createdAfterPeriod')
                        // console.log(createdAfterPeriod)
                        let bookFinishAt = 
                        today.isBefore(due) ? getTimeStringNotime(todayAfterPeriod)
                        : getTimeStringNotime(dueAfterPeriod)
                        
                        // console.log('bookFinishAt : '+bookFinishAt)
                        // console.log(today.isBefore(createdAfterPeriod))
                        // console.log(item)
                        if(today.isBefore(createdAfterPeriod)){
                            // console.log(item['digitalBookID'])
                            let digitalBookID = item['digitalBookID']
                            this.props.firebase.library(digitalBookID).get()
                            .then(res => {
                                let bookData = res.data()
                               if(bookData===undefined){
                                   alert('디지털북이 수정되었습니다. 관리자 문의 바랍니다.')
                                   //디지털북 ID가 수정되었는지 확인
                               }
                                this.props.firebase.mybooks().add({
                                    // item안에 title이름으로 programTitle들어가있음
                                    ...item,
                                    codeUser:authUser['uid'],
                                    codeUserName:authUser.username,
                                    number : authUser['number'],
                                    bookData,
                                    seenPageId:0,
                                    lastPageId:0,
                                    bookStartAt : getTimeString(new Date()),
                                    bookFinishAt : bookFinishAt,
                                    bookStatus: 'applied',
                                    vrRentStatus : item['vrRent'] ? 'ready': false,
                                    vrEventStatus : item['vrEvent'] ? 'ready': false,
                                    publishedStatus : item['published']>0 ? 'ready' : false,
                                    publishedCover : false,
                                    code : bookCode,
                                    codeStatus: true,
                                    isFinished : false,
                                    review : false
                                })
                                .then(res=>{
                                    this.props.firebase.tlpStatistics().update({Totalmybook : this.props.firebase.fieldValue.increment(1)})
                                    let pid = item['pid']
                                    this.props.firebase.paidDigitalBook(pid).set({
                                        codeStatus:true,
                                        codeUserName:authUser.username,
                                        codeUserUid:authUser.uid,
                                        userBookId : res.id,
                                        bookStartAt : getTimeString(today),
                                        bookFinishAt : getTimeString(createdAfterPeriod),
                                    },{merge:true})
                                    this.setState({
                                        mybookId : res.id,
                                        bookcode1 : '',
                                        bookcode2 : ''
                                    })
                                })
                            }).then(()=>{
                                if(item['published']>0 || item['vrRent'] || item['vrEvent']){
                                    if(item['published']>0){
                                        this.props.firebase.tlpStatistics().update({Totalpublished : this.props.firebase.fieldValue.increment(1)})
                                    }
                                    if(item['vrRent']){
                                        this.props.firebase.tlpStatistics().update({TotalvrRent : this.props.firebase.fieldValue.increment(1)})
                                    }
                                    if(item['vrEvent']){
                                        this.props.firebase.tlpStatistics().update({TotalvrEvent : this.props.firebase.fieldValue.increment(1)})
                                    }
                                    this.setState({
                                        shipmentModal : true,
                                        codeModal: false
                                    })
                                } else {
                                    this.setState({
                                        codeModal: false
                                    })
                                }

                            })
                            .then(()=> this.getMybookList())
                            .catch(err=>console.log(err))
                            
                        } else {
                            alert('프로그램 종료일이 지난 코드입니다.')
                        }
                    } else {
                        alert('이미 사용한 코드입니다.')
                        this.setState({
                            codeMsg : '올바른 코드 값이 아닙니다. 다시 확인해주세요.',
                        })
                    }
                } else if (item['status']===20){
                    alert('결제가 취소된 코드입니다.')
                    this.setState({
                        codeMsg : '올바른 코드 값이 아닙니다. 다시 확인해주세요.',
                    })
                } else if (item['status']===-30){
                    alert('결제 취소 처리 중인 코드입니다.')
                    this.setState({
                        codeMsg : '올바른 코드 값이 아닙니다. 다시 확인해주세요.',
                    })
                }else {
                    alert('미결제 코드거나 결제 대기중인 코드입니다.\n결제가 완료된 후에도 결제 승인까지 최대 하루가 걸릴 수 있습니다.')
                    this.setState({
                        codeMsg : '올바른 코드 값이 아닙니다. 다시 확인해주세요.',
                    })
                }
            } else {
                alert('유효한 코드가 아닙니다.')
                this.setState({
                    codeMsg : '올바른 코드 값이 아닙니다. 다시 확인해주세요.',
                })
            }
        })
        .catch(err=>{
            alert('관리자에게 문의하세요.\n에러 : '+err)
        this.setState({
                    codeMsg : '올바른 코드 값이 아닙니다. 다시 확인해주세요.',
                })
        })
        } else {
            alert('북 코드를 모두 입력하세요.')
        }
    }
    onSubmitShipment=()=>{
        //주소 저장
        let { postCode, fullAddress, extraAddress, mybookId} = this.state
        this.props.firebase.mybook(mybookId).set({
            userAddress : `${postCode} ${fullAddress} ${extraAddress}` },{merge:true})
            .then(()=>{
                this.setState({
                    shipmentModal : false
                })
            })
            .then(this.getMybookList)
    }
    onSetShipment = (mybookId) => {
        this.setState({
            mybookId,
            shipmentModal:true})
    }
      render() {
        const { library, program, sales, payment, xrbook, sharing, reviews, myInfo, authUser, mybookId, isVrCode,
             mybookList, programList, onlineList, xrMakingList, bookcode1,bookcode2, postCode, fullAddress, extraAddress, isAddressOpen, codeModal, codeMsg, shipmentModal} = this.state;
        const authName = authUser['username']
        let isCertificated = authUser['isCertificated']
        let uid = authUser['uid']
        let number = authUser['number']
        const showLibrary =  library ? 'show' : 'hidden'
        const showProgram =  program ? 'show' : 'hidden'
        const showSales = sales ? 'show' : 'hidden'
        const showPayment =
        // 'show'
        payment ? 'show' : 'hidden'
        const showXrbook = xrbook ? 'show' : 'hidden'
        const showSharing = sharing ? 'show' : 'hidden'
        const showReviews =reviews ? 'show' : 'hidden'
        const showMyInfo = myInfo ? 'show' : 'hidden'
          return (
            <div className="userMypageBg">
                <div className="userMypageFrame">
                    <h3>{authName||'회원'}님</h3>
                    <p>즐거운 하루 되세요 !</p>

                    <div className='tab' onClick={()=> this.onstateChange('library')}>내 서재</div>
                        <div className={`contents ${showLibrary}`}>
                           
                            <div className={`libraryBg`}>
                            <div className="libraryFrame">
                            {/* <div className="myLibraryFrame"> */}
                                {/* 회사명, 참여인원, 프로그램명, 결제가격, 코드, 진행상황, 일자, 비고 */}
                                {mybookList.length>0 ? mybookList.map(c=> {
                                    let { published, userAddress, bookData, target, mybookId, digitalBookID, site, unique, seenPageId, lastPageId,
                                        bookFinishAt, review, vrRent, isFinished, pid, publishedCover, period,
                                        isExtended, extensionPeriod, extendPayData, totalExtension  }= c
                                        let bookTitle= bookData.bookTitle
                                    let title = `${bookTitle} (${target} 대상) - 저자 : ${authName}`
                                    
                                    // console.log(c)
                                    var year = bookFinishAt.substr(0,2)
                                        year = parseInt(year)
                                        var mon = bookFinishAt.substr(2,2)
                                        mon = parseInt(mon)
                                        var date = bookFinishAt.substr(4,2)
                                        date = parseInt(date)
                                        var isFinishedAtFormatted= dayjs(new Date(2000+year, mon-1, date-1))
                                        var isFinishedAtFormatted2 = isFinishedAtFormatted.format('YYYY-MM-DD')
                                return (
                                    <div key={pid}>
                                        {/* 완성했을때 */}
                                        {isFinished || bookFinishAt<getTimeString() ? 
                                                // 리뷰없을때
                                                !review ? <span><Link to={{pathname: `${ROUTES.XRBOOK_REVIEW}`, state: { published : published, userAddress:userAddress,
                                                                bookTitle:bookTitle, mybookId: mybookId, digitalBookID: digitalBookID, username : authName,
                                                                target : target, site: site, programId : unique,  bookData: bookData, programTitle:c.title }}} >
                                                                <span><img src={bookData.iconUrl || XRBOOKLogo } alt='icon'/></span>
                                                                <p>{title} - [종료/리뷰작성]</p>
                                                            </Link></span>
                                                // 리뷰썼고 커버 선택 안했을때
                                                : !publishedCover ? <span><Link to={{pathname: `${ROUTES.XRBOOK_COVER}`, state: { published : published, userAddress:userAddress,
                                                    bookTitle:bookTitle, mybookId: mybookId, digitalBookID: digitalBookID, username : authName, bookData: bookData,
                                                    target : target, site: site, programId : unique , programTitle:c.title }}} >
                                                                <span><img src={bookData.iconUrl || XRBOOKLogo } alt='icon'/></span>
                                                                <p>{title} - [종료/커버선택]</p>
                                                            </Link></span>
                                                //리뷰썼고 커버선택 필요 없거나 커버 선택 완료했을때
                                                :   <span><Link to={{pathname: `${ROUTES.XRBOOK_CHECK}`, state: {
                                                    bookData : bookData,
                                                    authUser:authUser,
                                                    title:title,
                                                    bookTitle:bookTitle,
                                                    mybookId:mybookId,
                                                    seenPageId:seenPageId,
                                                    lastPageId:lastPageId,
                                                    published : published,
                                                    bookFinishAt  : bookFinishAt,
                                                    review : review, programTitle:c.title
                                                    }}} >
                                                        <span><img src={bookData.iconUrl || XRBOOKLogo } alt='icon'/></span>
                                                        <p>{title} - [종료]</p>
                                                    </Link></span>
                                        // 완성안했을때
                                        // 주소 입력 했을 때
                                        :userAddress ? 
                                                    // write 가능
                                                    <span className="columnMoblie" style={{display:'flex', justifyContent:'space-between'}}>
                                                    <Link to={{pathname: `${ROUTES.XRBOOK_AUTH}`, state: {
                                                        // bookData : bookData,
                                                        authUser:authUser,
                                                        title:title,
                                                        bookTitle:bookTitle,
                                                        mybookId:mybookId,
                                                        seenPageId:seenPageId,
                                                        lastPageId:lastPageId,
                                                        published : published,
                                                        bookFinishAt  : bookFinishAt,
                                                        review : review
                                                        }}} >
                                                    <span><img src={bookData.iconUrl || XRBOOKLogo } alt='icon'/></span>
                                                    <p>{title} {isFinishedAtFormatted2}까지</p>
                                                    </Link>
                                                    <span className="btnArea"  style={{display:'flex'}}>
                                                            {/* 버튼 */}
                                                        {!vrRent && <button  className="oculusVrAppBtn" onClick={()=>this.setState({isVrCode:mybookId})}>오큘러스 고 APP 코드</button>}
                                                        <ExtensionPay extensionPeriod={7} totalExtension={totalExtension} isExtended={isExtended} payReqData={c} />
                                                        </span>
                                                    </span>
                                        : // 주소입력 안했을때 write 불가능 - 주소입력필요
                                        // vrRent || published >0 ? 
                                        
                                        <div onClick={()=>this.onSetShipment(mybookId)}>
                                        <Link to='#'>
                                        <span><img src={bookData.iconUrl || XRBOOKLogo } alt='icon'/></span>
                                        <p>{title} {isFinishedAtFormatted2}까지 - [주소입력] </p>
                                        </Link>
                                        </div>
                                        // : // write 가능 주소입력 불필요
                                        // <span className="columnMoblie" style={{display:'flex', justifyContent:'space-between'}}>
                                        // <Link to={{pathname: `${ROUTES.XRBOOK_AUTH}`, state: {
                                        //     bookData : bookData,
                                        //     authUser:authUser,
                                        //     title:title,
                                        //     bookTitle:bookTitle,
                                        //     mybookId:mybookId,
                                        //     seenPageId:seenPageId,
                                        //     lastPageId:lastPageId,
                                        //     published : published,
                                        //     bookFinishAt  : bookFinishAt,
                                        //     review : review
                                        //     }}} >
                                        // <span><img src={bookData.iconUrl || XRBOOKLogo } alt='icon'/></span>
                                        // <p>{title} {isFinishedAtFormatted2}까지</p>
                                        // </Link>
                                        //     <span className="btnArea"  style={{display:'flex'}}>
                                        //         {/* 버튼 */}
                                        //     {!vrRent && <button  className="oculusVrAppBtn" onClick={()=>this.setState({isVrCode:mybookId})}>오큘러스 고 APP 코드</button>}
                                        //     <ExtensionPay extensionPeriod={7} totalExtension={totalExtension} isExtended={isExtended} payReqData={c} />
                                        //     </span>
                                        // </span>
                                        }
                                    </div>
                                    )
                                })
                                : 
                                <div className="noDataText">추가된 디지털 북이 없습니다. 메일로 전송된 수강 코드 번호를 입력하세요.</div>
                                }
                                </div>
                                <p>
                                    <span>* 기간 연장은 프로그램 종료 전, &nbsp;</span>
                                    <span>7일씩 가능합니다.</span>
                                </p>
                                <div className="bookRegistBtn" onClick={()=>
                                    isCertificated ? 
                                    this.setState({codeModal : !this.state.codeModal})
                                : alert('하단의 정보수정/탈퇴 탭에서 본인 인증을 해주세요.\nXR BOOK에 관한 안내 SMS를 발송합니다.')
                                }>수강 코드 등록</div>
                                </div>
                                {/* 모달창 */}
                                {isVrCode !=='' &&
                                <div className="codeRegiBg">
                                        <VrCode uid={authUser['uid']} isVrCode={isVrCode} onPropsChange={this.onPropsChange}/>
                                </div>
                                }
                                    {codeModal&&
                                    <div className="codeRegiBg">
                                        <div className="codeRegiBx">
                                            <div className="titleBox">
                                                <h5>수강 코드 등록</h5>
                                                <div onClick={()=>this.setState({codeModal : false})}>×</div>
                                            </div>
                                            <form>
                                                <div className="codeInputFrame">
                                                    <p>수강 코드 번호</p>
                                                    <span>
                                                        <input type='text' name='bookcode1' value={bookcode1} onChange={(e)=> this.onPropsChange('bookcode1',e.target.value)}/><span>_</span>
                                                        <input type='text' name='bookcode2' value={bookcode2} onChange={(e)=> this.onPropsChange('bookcode2',e.target.value.replace(/[^0-9]/g,""))}/>
                                                    </span>
                                                </div>
                                                {codeMsg && <p className="errMsg">{codeMsg}</p>}
                                                <p>이미 코드를 등록하고 디지털 북을 오픈 한 경우 디지털 콘텐츠 특성 상 전액 환불이 불가합니다.</p>
                                                <div className="codeRegiBtn" onClick={(e)=>this.onSubmitCode(e)}>등록하기</div>
                                            </form>
                                        </div>
                                        
                                    </div>}
                                    {shipmentModal&&
                                    <div className="codeRegiBg">
                                        <div className="addressRegiBx">
                                            <div className="titleBox">
                                                <h5>배송지 정보 입력</h5>
                                                {/* <div onClick={()=> this.setState({shipmentModal : false})}>X</div> */}
                                            </div>
                                            <div className="addressRegiCont">
                                                <p>프로그램 종료 후 인쇄된 책 배송을 위한 주소를 입력해 주세요</p>
                                                <div className="addressInputFrame">
                                                    <input type='number' value={postCode} onClick={()=> this.onPropsChange('isAddressOpen',true)} readOnly/>
                                                    <span className='addressBtn' onClick={()=> this.onPropsChange('isAddressOpen',true)}>주소지 찾기</span><br/>
                                                    <input type='text' value={fullAddress} onClick={()=> this.onPropsChange('isAddressOpen',true)} readOnly/><br/>
                                                    <input type='text' className='extraAddress' name='extraAddress' value={extraAddress}
                                                    onChange={(e)=> this.onPropsChange('extraAddress',e.target.value)}/>
                                                </div>
                                                <button className="addressRegiBtn" disabled={fullAddress===''||extraAddress===''} onClick={()=>this.onSubmitShipment()}>제출하기</button>
                                            </div>
                                            {isAddressOpen &&<Postcode isOpen={()=>this.setState({isAddressOpen : false})} onUpdateCode={this.onPropsChange}/>}
                                        </div>
                                        
                                    </div>}
                    </div>

                    <div className='tab' onClick={()=> this.onstateChange('program')}>Off-line Learning Program 문의 조회</div>
                        <div className={`contents ${showProgram}`}>
                            <ClassInquiries authUser={authUser}/>
                    </div>

                    <div className='tab' onClick={()=> this.onstateChange('sales')}>On-line Learning Program 문의 조회</div>
                        <div className={`contents ${showSales}`}>
                        <SelfInquiries authUser={authUser}/>
                        </div>
                    <div className='tab' onClick={()=> this.onstateChange('payment')}>결제 내역 및 이용 현황</div>
                        <div className={`contents ${showPayment}`}>
                            <h6>1. 결제 내역</h6>
                            <div>
                                <SelfPayment authUser={authUser}/>
                            </div>
                            <h6>2. 나의 On-line Learning 이용 현황</h6>
                            <div>
                                <SelfLearning authUser={authUser}/>
                            </div>
                        </div>
                    <div className='tab' onClick={()=> this.onstateChange('sharing')}>Reflection Gallery 이용 현황</div>
                        <div className={`contents ${showSharing}`}>
                            <SharingPage authUser={authUser}/>
                        </div>
{/* 
                    <div className='tab' onClick={()=> this.onstateChange('reviews')}>리뷰작성</div>
                        <div className={`contents ${showReviews}`}>
                            <p className="myReviewTxt">교육 프로그램 / 어린 왕자 책 리뷰</p>
                            관리자가 선택박스에서 해당 프로그램/책/제작 서비스 완료를 선택했을 때 추가 되어야 할 부분 (myReviewFrame)↓
                            <ul className="myReviewFrame">
                                <li>"XR Reflection Writing Book" 후기를 작성해 주세요</li>
                                <li>2020-07-04</li>
                                <li><button>글쓰기</button></li>
                            </ul>
                        </div> */}

                    <div className='tab' onClick={()=> this.onstateChange('myInfo')}>정보 수정/탈퇴</div>
                        <div className={`contents ${showMyInfo}`}>
                        <UserInfo firebase={this.props.firebase} authUser={authUser}/>
                        </div>
                </div>
            </div>
          )
      }
  }

  const condition = authUser =>authUser
//    && authUser['roles'][ROLES.MEMBER] === ROLES.MEMBER;

  export default compose(
    withEmailVerification,
    withAuthorization(condition),
  )(UserPage);

  const Postcode = props => {
    const {onUpdateCode} = props
    const handleComplete = (data) => {
      let fullAddress = data.address;
      onUpdateCode("postCode", data.zonecode)
      let extraAddress = ''; 
      if (data.addressType === 'R') {
        if (data.bname !== '') {
          extraAddress += data.bname;
        }
        if (data.buildingName !== '') {
          extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
        }
        fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
      }
      onUpdateCode("fullAddress", fullAddress)
      onUpdateCode("isAddressOpen", false)
    }


    return (
    <div className="addressPopupOut">
        {/* 주소 입력하지 않으면 창이 닫히지 않도록 모달 배경클릭 시 모달 닫힘을 제거했습니다. */}
        {/* 모달 창 */}
            <div className="addressPopupIn">
            <DaumPostcode
                onComplete={handleComplete}
            />
            </div>

    </div>
    ); 
  } 
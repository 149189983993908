import React, { Component } from 'react'
import { isEmail, phoneFormatter, sideTrim, getTimeString } from '../../../helpers'
import {Link} from 'react-router-dom'
import { withFirebase } from '../../../Firebase'
import * as ROUTES from '../../../../constants/routes'
import Spinner from '../../../Utils/Spinner'
import './Contact.css'
import './ContactRes.css'
class Contact extends Component {
    state = {
        showCategory : this.props.showCategory || '',
        isEmailValid : false,
        emailError : false,
        name : '',
        number : '',
        email : '',
        time:'',
        agree: false,
        category : 'others',
        inquiries : '',
        isValid : false,
        loading : false
    }
    componentDidUpdate(prevProps, prevState){
      let {name, number, email, agree, time, inquiries, isEmailValid} = this.state;
      if(prevState.name !== name
        ||prevState.number !== number
        ||prevState.email !== email
        ||prevState.agree !== agree
        ||prevState.time !== time
        ||prevState.inquiries !== inquiries
        ){
         
          if(name === '') {
            this.setState({isValid:false})
          } else if (number === ''|| number.length<9){
            this.setState({isValid:false})
          } else if (email === '' || isEmailValid===false){
            this.setState({isValid:false})
          } else if (agree === false) {
            this.setState({isValid:false})
          } else if( time === ''){
            this.setState({isValid:false})
          } else if ( inquiries===''){
            this.setState({isValid:false})
          } else {
            this.setState({isValid : true})
          }
      }

  }
    stateChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }
    onPropsChange = (name, value) => {
      this.setState({
        [name] : value
    })
    }
    validateEmail = e => {
        const emailEntered = e.target.value
        if (isEmail(this.state.email)) {
          this.setState({
            isEmailValid: true,
            email : emailEntered
          });
        } else {
          this.setState({
            isEmailValid: false,
            email : emailEntered
          });
        }
      };
      onCheckTextAreaLeng = (e) => {
        let textValue = e.target.value
        // let enterTagLeng = textValue.match(/(?:\r\n|\r|\n)/g)
        let textValueLeng = textValue.replace(/(?:\r\n|\r|\n)/g, '').length
        // console.log(enterTagLeng)
        // console.log(textValueLeng)
        if(textValueLeng>3000){
          alert("글자수는 3000자로 이내로 제한됩니다.")
        }else {
        this.setState({
          inquiries :textValue.replace(/(?:\r\n|\r|\n)/g, '<br />')
        })
      }
      }
      getCategoryName = (category) => {
        const categoryMap = {
            'introduction': '도입문의',
            'preview': '시연문의',
            'partnership': '사업제휴',
            'others': '기타문의'
        };
        return categoryMap[category] || '기타문의';
    }

    getAuthUserId = () => {
        const { authUser } = this.props.firebase || {};
        return authUser ? authUser.uid : 'nonAuth';
    }

    sendMail = async (e) => {
        e.preventDefault();
        
        const {name, number, email, agree, inquiries, isEmailValid, time} = this.state;
        
        // 유효성 검사
        if(!name || !number || number.length < 9 || !email || !isEmailValid || !agree || !time || !inquiries) {
            !name && alert('회사/개인 이름을 작성해 주세요.');
            (!number || number.length < 9) && alert('올바른 연락처를 작성해 주세요.');
            (!email || !isEmailValid) && alert('올바른 이메일을 입력해 주세요.');
            !agree && alert('개인 정보 수집 및 활용에 동의해 주세요.');
            !time && alert('연락 가능한 시간을 입력해주세요.');
            !inquiries && alert('문의 내용을 입력해 주세요.');
            return;
        }

        this.setState({loading: true});

        try {
            // Firestore에 직접 저장
            const db = this.props.firebase.db;
            await db.collection('inquiries').add({
                name,
                number,
                email,
                agree,
                time,
                category: this.getCategoryName(this.state.category),
                inquiries,
                sendAt: getTimeString(),
                authUserId: this.getAuthUserId(),
                site: 'tlp',
                reply: '',
                replyAt: '',
                tempReply: '',
                tempReplyAt: ''
            });

            // 성공 메시지 표시
            alert('문의사항이 등록되었습니다.\n영업일 기준 2-3일 이내 회신드리겠습니다.');
            
            // 폼 초기화
            this.setState({
                name: '',
                number: '',
                email: '',
                agree: false,
                time: '',
                category: 'others',
                inquiries: '',
                loading: false
            });
        } catch (error) {
            console.error('Error:', error);
            alert('문의사항이 등록되었습니다.\n영업일 기준 2-3일 이내 회신드리겠습니다.');
            this.setState({ loading: false });
        }
    };

    render() {
        const {isEmailValid, emailError, name, number, email, agree, category, time, inquiries, loading, isValid} = this.state
        const numLeng = number.length
        // console.log(number)
        //버튼 비활성화시 className=gray, 활성화시 'dark' -> 수정가능 .replace(/<br ?\/?>/g, "\n") // .replace(/<br\s*[\/]?>/gi,`\n`)
        const formattedInquiries = inquiries.replace(/(<br>|<br\/>|<br \/>)/g, '\n')
        return ( 
            <div className="contactBg">
              {!loading ? 
              <div className="contactFrame">
                <h3>문의 하기</h3>
                <form className="inputGroup" onSubmit={this.sendMail}>
                  <div className="contGroup">
                    <div className="radioGroup">
                        <div className="radioTitle">문의 유형</div>
                        <div className="radioBtn">
                          <div><input type="radio" id="introduction" name="category" value="introduction" checked={category === "introduction"} onChange={this.stateChange}/><span onClick={()=>this.onPropsChange('category', "introduction")}>도입문의</span></div>
                          <div><input type="radio" id="preview" name="category" value="preview" checked={category === "preview" } onChange={this.stateChange}/><span onClick={()=>this.onPropsChange('category', "preview")}>시연문의</span></div>
                          <div><input type="radio" id="partnership" name="category" value="partnership" checked={category ==="partnership" } onChange={this.stateChange}/><span onClick={()=>this.onPropsChange('category', "partnership")}>사업제휴</span></div>
                          <div><input type="radio" id="others" name="category" value="others" checked={category ==="others"} onChange={this.stateChange}/><span onClick={()=>this.onPropsChange('category', "others")}>기타문의</span></div>
                        </div>
                    </div>
                    <div className="textareaBx"><textarea type='text' name='inquiries' value={formattedInquiries}
                     placeholder="프로그램 및 서비스 활용 목적, 시기, 대상자 등 간략히 문의 사항을 작성해주시면 연락처로 회신 드리겠습니다." 
                     onChange={this.onCheckTextAreaLeng}/></div>
                  </div>
                  <span className="centerLine"></span>
                  <div className="infoGroup">
                    
                    <input type='text' name='name' value={name} placeholder="* 회사/개인 이름" onChange={event => this.setState({name: sideTrim(event.target.value)})}/>
                    <input type='text' name='number' value={number} placeholder="* 전화번호" onChange={event => this.setState({number: phoneFormatter(event.target.value.replace(/[^0-9]/g,""))})}/>
                    <input type='text' name='email' value={email} placeholder="* 메일주소" onChange={this.validateEmail}/>
                    <input type='text' name='time' value={time} placeholder="* 연락 가능 시간" onChange={event => this.setState({time: event.target.value})}/>
                    <p className="emphasizeText">*필수 입력사항입니다.</p>
                    <div className="textareaBx"><textarea type='text' name='inquiries' value={formattedInquiries}
                     placeholder="프로그램 및 서비스 활용 목적, 시기, 대상자 등 간략히 문의 사항을 작성해주시면 연락처로 회신 드리겠습니다." 
                     onChange={this.onCheckTextAreaLeng}/></div>

                      {email !=='' && isEmailValid ? null : email !=='' && !isEmailValid ? <div className="errorMsg">유효한 메일이 아닙니다.</div> : <div className="errorMsg"> </div>}
                      {emailError ? <div className="errorMsg">중복된 메일 주소 입니다.</div> : null }
                    <Link to={ROUTES.POLICIES} className="termsLink">개인정보 활용동의 약관보기</Link>
                    <div className="checkBx"><input type='checkbox' name='agree' checked={agree} onChange={()=>this.onPropsChange('agree', !agree)}/>
                    <span onClick={()=>this.onPropsChange('agree', !agree)}>문의 사항 안내를 위한 개인 정보 수집 및 활용에 동의합니다.</span></div>
                    {/* <button style={isValid ? {backgroundColor : '#000f1e', color:'#eeeeee'}:{backgroundColor:'#eeeeee'}} type="submit">문의하기</button> */}
                    {isValid ? <button type="submit">문의하기</button> : <button disabled>문의하기</button> }
                    {/* <button style={isValid ? {backgroundColor : '#000f1e', color:'#eeeeee'}:{backgroundColor:'#eeeeee'}} type="submit">문의하기</button> */}
                    {/* disabled={isInvalid} */}
                  </div>
                </form>
              </div>
              : <Spinner msg={`문의사항을 저장 중입니다`}/>}
            </div>
        )
    }
}
export default withFirebase(Contact)
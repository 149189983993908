import React, { Component } from 'react'
import {phoneFormatter} from '../helpers'
import PasswordChangeForm from '../PasswordChange'
import {withFirebase} from '../Firebase'
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes'
import Certificate from '../Order/bootPay/Certificate'
import '../User/User.css'
import BootPay from "bootpay-js"

class UserInfo extends Component {
    state={
        authUser : this.props.authUser,
        uid : this.props.authUser['uid'],
        username : this.props.authUser['username'],
        email : this.props.authUser['email'],
        roles : this.props.authUser['roles'],
        isCertificated:this.props.authUser['isCertificated'] || false,
        certificationInfo:this.props.authUser['certificationInfo'] || {},
        password:'',
        passwordConfirm:'',
        number: this.props.authUser['number'] || '',
        isSecession : false,
        secessionModal:false,
        modalData:{}
    }
    componentDidUpdate(prevProps, prevState){
        if(this.props.authUser['number'] !== this.state.number && this.state.isCertificated !==false){
            this.setState({isCertificated:false, certificationInfo:{}})
        }
        if(this.props.authUser['number'] === this.state.number && this.state.isCertificated ===false
        && prevState.number !== this.state.number){
            this.setState({isCertificated:true, certificationInfo:this.props.certificationInfo})
        }
        
    }
    onstateChange = (e) => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }
    onstateToggle = (toggle, data) => {
        const modalData = !this.state.secessionModal? data : {}
        // console.log(this.state.secessionModal, data)
        if(toggle === 'isSecession'){
        this.setState({
            isSecession : !this.state.isSecession
        })} else if(toggle === 'secessionModal'){
            this.setState({
                secessionModal : !this.state.secessionModal,
                modalData: modalData
            })
        }
    }
    onPropsChange=(name,value)=>{
        this.setState({
            [name] : value,
        })
    }
    onInfoUpdate = () => {
        const {uid, username, number, isCertificated, certificationInfo, email, roles} = this.state
        this.props.firebase.user(uid).set({username : username},{merge:true})
        .then(()=>{
            let authUserStorage = JSON.parse(localStorage.getItem('authUser'))
            authUserStorage['username']=username
            localStorage.removeItem('authUser')
            localStorage.setItem('authUser', JSON.stringify(authUserStorage))
            alert('계정 이름이 변경되었습니다.')
        })
        .then(()=>this.props.history.goBack(2))
        .catch(err => console.log(err))
    }
	getCertification =()=>{
        let {username, number, isCertificated, uid, authUser} = this.state
        let {firebase} = this.props
        if(!isCertificated) {
                const { IMP } = window;
                IMP.init(process.env.REACT_APP_INIT_CODE);
              
                let data = {
                  merchant_uid: `${uid}+${number}`,
                  phone:number.split('-').join(""),
                  company:'XR BOOK',
                  name: username,
                }
                IMP.certification(data, callback);              
                function callback(response) {
                  const {
                    success, //true or false
                    pg_type, // "certification"
                    pg_provider, // "danal"
                    merchant_uid, // uid+number
                    imp_uid, //"imp_숫자"
                    error_msg,
                    error_code
                  } = response;
              
                  if (success) {
                    firebase.user(uid).set({
                        username:username,
                       number : number,
                       isCertificated : true,
                       certificationInfo : response
                    },{merge:true})
                    .then(()=>{
                        authUser['isCertificated'] = true
                        authUser['username'] = username
                        authUser['number'] = number
                        authUser['certificationInfo'] = response
                        localStorage.removeItem('authUser')
                        localStorage.setItem('authUser', JSON.stringify(authUser))
                            })
                    .then(()=>{
                        let result = window.confirm('본인 인증이 완료되어 연락처를 저장합니다.')
                        if(result){
                            window.location.reload()
                        } else {
                            window.location.reload()
                        }
                    })
                  } else {
                    alert(`본인인증 실패: ${error_msg}`);
                  }
                }
    } else {
        window.location.reload()
    }
    }
    goMain = () => {
        this.props.firebase.auth.signOut().then(()=>
            this.props.history.push(ROUTES.TLP_MAIN))
    }
    render() {
        const {authUser} = this.props
        const { username, email, password, passwordConfirm, number, isSecession, secessionModal, isCertificated, uid } = this.state
        const confirm = password !== '' && passwordConfirm !== '' && password !== passwordConfirm ? true : false
        return (
            <div className="myInfoFrame">
               {!isSecession ?
               <React.Fragment>
                   <ul>
                        <li>
                            <span className="infoCate">이름</span>
                            <span>
                            <input type='text' name='username' value={username} onChange={this.onstateChange}/>
                            <button disabled={username === ''} onClick={()=> this.onInfoUpdate()}>이름 변경</button>
                            </span>
                        </li>
                        <li>
                            <span className="infoCate">이메일 (ID)</span> 
                            <span>{email}</span>
                        </li>
                        
                        <li>
                            <span className="infoCate">연락처</span>
                            <span>
                                <input type='text' name='number' value={number} 
                                onChange={event => this.setState({number: phoneFormatter(event.target.value.replace(/[^0-9]/g,""))})}/>
                                {isCertificated ? <button disabled>인증완료</button>
                                    :  
                                    <button onClick={this.getCertification}>본인인증 후 저장</button>
                                    }
                            </span>
                        </li>
                        <li>
                            <span className="infoCate">비밀번호</span>
                            <PasswordChangeForm/>
                        </li>
                        {confirm && <div>비밀번호가 일치하지 않습니다.</div>}
                        <li><span className="infoDelLink" onClick={()=>this.onstateToggle('isSecession')}>계정 삭제하기</span></li>
                    </ul>
                </React.Fragment>
                :
                <React.Fragment>
                <div className="infoDelBtnGroup">
                    <button onClick={()=>this.onstateToggle('secessionModal', authUser)}>탈퇴 하기</button>
                    <button onClick={()=>this.onstateToggle('isSecession')}>취소</button>
                </div>
                {secessionModal &&
                    <Modal data={authUser} showModal={()=>this.onstateToggle('secessionModal')} goMain={this.goMain} />
                }
                </React.Fragment>
                }
            </div>
        )
    }
}
export default compose(
    withRouter,
    withFirebase,
  )(UserInfo);

const Modal = props => {
    const {data, showModal, goMain} = props
    const uidData = {uid : data.uid}
    function secessionUser (){
        fetch("/api/v1/withdraw", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(uidData)
        })
        .then(response => { response.json()
            .then((res) => {
               
              if(res.msg === 'success') {
            alert('회원 탈퇴가 완료되었습니다.\n이용해 주셔서 고맙습니다.')
            goMain()
            } else {
              alert('다시 시도해주세요. \n오류가 계속되면 관리자에게 문의해주세요. \nxrbook@thehrder.com')
            }
          })
          .catch(err =>  alert(`다시 시도해주세요. \n오류가 계속되면 관리자에게 문의해주세요. \nxrbook@thehrder.com \n에러 : ${err}`))
          })
    }
        return(
            <div className="secessionAlertBg" key={data.id}>
                <div className="secessionAlertBx">
                    <div className="secessionAlertTitle">
                        <h5><span>어린 왕자, 나를 만나다 </span><span>사이트 회원 탈퇴</span></h5>
                        <span onClick={showModal}>×</span>
                    </div>
                    <div className="secessionAlertCont">
                        <h6>회원 탈퇴에 관하여 아래의 사항을 꼭 확인하시기 바랍니다.</h6>
                        <ul>
                            <li>회원 탈퇴 후, 해당 서비스를 더 이상 이용할 수 없으며, 모든 사적인 정보는 삭제됩니다.</li>
                            <li>단, 공유 플랫폼에 전송된 이미지는 개인정보 수집 약관 동의에 의해 보존됩니다.</li>
                            <li>회원 탈퇴를 신청하시면, 해당 아이디는 영구적으로 정지되며 재가입이 되지 않습니다.</li>
                            <li>기타 해당 웹사이트의 정책, 약관 등에서 정하고 있는 정보가 삭제됩니다.</li>
                        </ul>
                    </div>
                    <div className="infoDelBtnGroup">
                        <button onClick={secessionUser}>탈퇴하기</button>
                        <button onClick={showModal}>취소</button>
                    </div>
                </div>
            </div>
    )
    }

import React, { Component } from 'react'
import {withFirebase} from '../../../Firebase'
import PaymentList from './PaymentList'
import dayjs from 'dayjs'

class TlpPayments extends Component {
    state = {
        selfPaymentList : [],
        paidDigitalbook:[],
        paidOpen : false
    }
    componentDidMount(){
        this.getSelfPaymentList()
    }
    getSelfPaymentList =()=> {
        this.props.firebase.tlpPayments().orderBy('params.createdAt', "desc").limit(20).get()
        .then(result => {
            var lastVisible = result.docs[result.docs.length-1];
            var selfPaymentList = []
            result.forEach(res => {
                var listData = res.data()
                var pid = res.id
                selfPaymentList.push({pid, ...listData})
            })
            this.setState({selfPaymentList, lastVisible})
        })
    }
    getNextSelfPaymentList =()=> {
        let scrollPointX = window.scrollX
        let scrollPointY = window.scrollY
        let {selfPaymentList, lastVisible} = this.state
        if(lastVisible !==undefined){
        this.props.firebase.tlpPayments().orderBy('params.createdAt', "desc").startAfter(lastVisible).limit(20).get()
        .then(result => {
            var lastVisible = result.docs[result.docs.length-1];
            result.forEach(res => {
                var listData = res.data()
                var pid = res.id
                selfPaymentList.push({pid, ...listData})
            })
            this.setState({selfPaymentList, lastVisible})
            window.scrollTo(scrollPointX, scrollPointY)
        })
    } else {
        alert('최신 데이터 입니다.')
    }
    }
    render() {
        let {selfPaymentList} = this.state
        let {totalNum} = this.props
        return (
            <React.Fragment>
                <p>프로그램 결제 {totalNum}건</p>
                <p>결제 내역 및 프로그램 대상 입력 정보</p>
                <ul className="salesListCate salesList2Cate">
                    <li>결제자</li>
                    <li>연락처</li>
                    <li>이메일</li>
                    <li>신청 상품</li>
                    <li>결제 가격</li>
                    <li>결제 일자</li>
                    {/* <li>프로그램</li> */}
                    <li>할인 쿠폰</li>
                    <li>환불/취소</li>
                </ul>
                {selfPaymentList.length>0 ? selfPaymentList.map((c, id)=> {
                    return(
                    <div className="salesListFrame" key={id}>
                        <PaymentList selfPaymentList={c} params = {c.params}/>
                    </div>
                    )
                    })
                    : <div>On-line Learning 프로그램 결제 현황이 없습니다.</div>}
                <button className="viewMoreListBtn" onClick={()=>this.getNextSelfPaymentList()}>시간순 20개 더보기</button>
            </React.Fragment>
        )
    }
}
export default withFirebase(TlpPayments)
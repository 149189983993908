import React from 'react';
import AuthUserContext from './context';
import { withFirebase } from '../Firebase';
import * as ROUTES from '../../constants/routes';
import '../Landing/Landing.css'
import '../Landing/LandingResp.css'

const needsEmailVerification = authUser =>
  authUser &&
  authUser.emailVerified === false &&
  authUser.providerData
    .map(provider => provider.providerId)
    .includes('password');

const withEmailVerification = Component => {
  class WithEmailVerification extends React.Component {
    constructor(props) {
      super(props);

      this.state = { isSent: false };
    }

    onSendEmailVerification = () => {
      this.props.firebase
        .doSendEmailVerification()
        .then(() => {
          this.setState({ isSent: true })
          alert('인증 메일이 재전송 되었습니다.')
          });
    };
    onLoginPage = () => {
      this.props.firebase.auth.signOut().then(()=>
      this.props.history.push(ROUTES.LOGIN)
      )
      
    };

    render() {
      return (
        <AuthUserContext.Consumer>
          {authUser =>
            // needsEmailVerification(authUser) ? (
            //   <div className="landingBg">{/* className='wrapper' */}
            //     <div className="landingFrame">
            //       {!this.state.isSent ? (
            //         <React.Fragment>
            //         <ul className="emailVeriBoxPC">{/* className='container landingPageText' */}
            //           <li className="emailVeriTitle">인증을 위한 이메일이 전송되었습니다.</li>
            //           <li className="emailVeriTxt">회원 가입 시 입력한 메일로 인증 메일이 전송되었습니다.</li>
            //           <li className="emailVeriTxt">인터넷 연결 상태 등으로 인해 최대 3~5분이 소요될 수 있습니다.</li>
            //           <li className="emailVeriTxt">이 후에도 인증 메일이 도착하지 않으면 스팸메일함을 확인하시거나</li>
            //           <li className="emailVeriTxt">인증메일 재전송 버튼을 눌러 인증 메일을 재전송하세요.</li>
            //         </ul>
            //         <ul className="emailVeriBoxMo">
            //           <li className="emailVeriTitle">인증을 위한 이메일이 전송되었습니다.</li>
            //           <li className="emailVeriTxt">회원 가입 시 입력한 메일로 인증 메일이 전송되었습니다.
            //           인터넷 연결 상태 등으로 인해 최대 3~5분이 소요될 수 있습니다.</li>
            //           <li className="emailVeriTxt">이 후에도 인증 메일이 도착하지 않으면 스팸메일함을 확인하시거나 
            //           인증메일 재전송 버튼을 눌러 인증 메일을 재전송하세요.</li>
            //         </ul>
            //         </React.Fragment>
            //       ) : (
            //         <React.Fragment>
            //         <ul className="emailVeriBoxPC">
            //           <li className="emailVeriTitle">인증을 위한 이메일이 재전송되었습니다.</li>
            //           <li className="emailVeriTxt">회원 가입 시 입력한 메일로 인증 메일이 재전송되었습니다.</li>
            //           <li className="emailVeriTxt">인터넷 연결 상태 등으로 인해 최대 3~5분이 소요될 수 있습니다.</li>
            //           <li className="emailVeriTxt">이 후에도 인증 메일이 도착하지 않으면 스팸메일함을 확인하시거나</li>
            //           <li className="emailVeriTxt">고객센터 (1599-1432)로 문의바랍니다.</li>
            //         </ul>
            //         <ul className="emailVeriBoxMo">
            //           <li className="emailVeriTitle">인증을 위한 이메일이 재전송되었습니다.</li>
            //           <li className="emailVeriTxt">회원 가입 시 입력한 메일로 인증 메일이 재전송되었습니다.
            //           인터넷 연결 상태 등으로 인해 최대 3~5분이 소요될 수 있습니다.</li>
            //           <li className="emailVeriTxt">이 후에도 인증 메일이 도착하지 않으면 스팸메일함을 확인하시거나 
            //           고객센터 (1599-1432)로 문의바랍니다.</li>
            //         </ul>
            //         </React.Fragment>
            //       )}
            //       <div className="emailVerBtnGroup">{/* className='container landingPageBtnWrapper Trans wrapperRow' */}
            //         <button
            //           type="button"
            //           className=' button landingPageBtn dark whiteText textM bodyWrapper Trans'
            //           onClick={this.onSendEmailVerification}
            //           disabled={this.state.isSent}
            //         >
            //           인증메일 재전송 ↑
            //         </button>
            //         <button
            //           type="button"
            //           className=' button landingPageBtn dark whiteText textM bodyWrapper Trans'
            //           onClick={this.onLoginPage}
            //         >
            //           로그인 화면으로 →
            //         </button>
            //       </div>
            //     </div>
            //   </div>
            // ) : (
              <Component {...this.props} />
          //   )
          }
        </AuthUserContext.Consumer>
      );
    }
  }

  return withFirebase(WithEmailVerification);
};

export default withEmailVerification;
import React, { Component } from 'react'
import {withFirebase} from '../../../Firebase'
import {Link} from 'react-router-dom'
import dayjs from 'dayjs'
import VrRentPayment from './VrRentPayment'

class vrRental extends Component {
    state = {
        vrRentpaymentList : [],
    }
    componentDidMount(){
        this.getvrRentpaymentList()
    }
    getvrRentpaymentList =()=> {
            this.props.firebase.tlpPayments().where('anyVrRent','==', true).orderBy('params.createdAt', "desc").limit(20).get()
            .then(result => {
                var lastVisibleVr = result.docs[result.docs.length-1];
                var vrRentpaymentList = []
                result.forEach(res => {
                    var listData = res.data()
                    var vrRentpaymentId = res.id
                    vrRentpaymentList.push({vrRentpaymentId, ...listData})
                })
                this.setState({vrRentpaymentList, lastVisibleVr})
            })
        }
        getNextvrRentpaymentList =()=> {
            let scrollPointX = window.scrollX
            let scrollPointY = window.scrollY
            let {vrRentpaymentList, lastVisibleVr} = this.state
            if(lastVisibleVr !==undefined){
            this.props.firebase.tlpPayments().where('anyVrRent','==', true).orderBy('params.createdAt', "desc").startAfter(lastVisibleVr).limit(20).get()
            .then(result => {
                var lastVisibleVr = result.docs[result.docs.length-1];
                result.forEach(res => {
                    var listData = res.data()
                    var vrRentpaymentId = res.id
                    vrRentpaymentList.push({vrRentpaymentId, ...listData})
                })
                this.setState({vrRentpaymentList, lastVisibleVr})
                window.scrollTo(scrollPointX, scrollPointY)
            })
        } else {
                alert('최신 데이터 입니다.')
            }
        
        
    }
    render() {
        let {myBookList, vrRentpaymentList} = this.state
        let {totalNum} = this.props
        // console.log(this.state.lastVisible)
        return (
            <React.Fragment>
                <p>기기 체험 내역 총 {totalNum}건</p>
                <ul className="salesListCate salesList5Cate">
                    <li>결제자</li>
                    <li>연락처</li>
                    <li>이메일</li>
                    <li>수량</li>
                    <li>결제일자</li>
                    <li>첨부파일</li>
                    <li>상태</li>
                    <li>기기 체험</li>
                    <li>비고</li>
                </ul>
                {vrRentpaymentList.length>0 ? vrRentpaymentList.map(c=> {
                    const {vrRentStatus, params, vrRentpaymentId} = c
                    let itemList = params.itemList
                    let vrRentList = itemList.filter(a=> a.vrRent)
                    let vrRentListLeng = vrRentList.length
                return (
                    <React.Fragment key={vrRentpaymentId}>
                        <VrRentPayment vrRentpayment={c} vrRentStatus={vrRentStatus} vrRentList={vrRentList}
                        vrRentListLeng={vrRentListLeng} params={c.params}/>
                    </React.Fragment>
                    )
                })
                : <div>기기대여 현황이 없습니다.</div>}
                <button className="viewMoreListBtn" onClick={()=>this.getNextvrRentpaymentList()}>시간순 20개 더보기</button>
            </React.Fragment>
        )
    }
}
export default withFirebase(vrRental)
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../../../Firebase';
import * as ROUTES from '../../../../constants/routes';
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import { getTimeString } from '../../../helpers';

class MarketingItem extends Component {
    state = {
      user: this.props.user,
      marketingCode: this.props.user.marketingCode !== undefined ? this.props.user.marketingCode : '',
      chapter: this.props.user.chapter !== undefined ? this.props.user.chapter : [false, false, false, false],
      show:false
    };
  onPropsChange = (name, value) => {
    this.setState({
      [name]:value
    })
  }
  makeid = (length) => {
    var result           = '';
    var characters       = 'abcdefghijklmnopqrstuvwxyz';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }
  onSubmit = () => {
    let {user, marketingCode, chapter} = this.state
    let code = marketingCode
    if(marketingCode === ''){
    let randomString = 'mk'+ this.makeid(4)
    // Math.random().toString(36).substr(2,5);
    let date = getTimeString()
    let dateString = date.substr(2,4);
    code = randomString+'_'+dateString
  }
    this.props.firebase.user(user.uid).update({
      marketingCode : code,
      chapter
    })
    .then(()=>{
      this.setState({marketingCode : code, chapter})
      alert('변경사항이 저장되었습니다.')
    })
    .catch((err)=>{
      alert(err)
    })
  }
  arrayChange = (idx) => {
    this.setState(state=>{
      let arr = state.chapter
      arr[idx] = !arr[idx]
      return {
        chapter : arr
      }
    })
  }
  render() {
    const { user, marketingCode, chapter } = this.state;
    let idx = this.props.idx
    // console.log(this.state.chapter)
    return (
      <React.Fragment>
        <td>
          {idx}
        </td>
              <td>
                {user.email}
              </td>
              <td>
                {user.username}
              </td>
              <td>
                {marketingCode !== '' ? 
                <div style={{display:'flex'}}>
                  <span style={{display:'flex'}}>
                    <input type='checkbox' checked={chapter[0]} onChange={()=>this.arrayChange(0)}/>
                    <span onClick={()=>this.arrayChange(0)}>ch.1</span>
                  </span>
                  <span style={{display:'flex'}}>
                    <input type='checkbox' checked={chapter[1]} onChange={()=>this.arrayChange(1)}/>
                    <span onClick={()=>this.arrayChange(1)}>ch.2</span>
                  </span>
                  <span style={{display:'flex'}}>
                    <input type='checkbox' checked={chapter[2]} onChange={()=>this.arrayChange(2)}/>
                    <span onClick={()=>this.arrayChange(2)}>ch.3</span>
                  </span>
                  <span style={{display:'flex'}}>
                    <input type='checkbox' checked={chapter[3]} onChange={()=>this.arrayChange(3)}/>
                    <span onClick={()=>this.arrayChange(3)}>ch.4</span>
                  </span>
                  <button onClick={()=>this.onSubmit()}>저장</button>
                </div>
                : <button onClick={()=>this.onSubmit()}>코드 생성</button>
                }
              </td>
      </React.Fragment>
    );
  }
}

export default withFirebase(MarketingItem);

import React, { Component } from 'react'
import {withEmailVerification} from '../../Session'
import Intro from './Intro'
import './Main.css'
import './MainResp.css'
// import MainLoading from './MainLoading'
import ClassroomLearning from './ClassroomLearning'
import SelfLearning from './SelfLearning'
import Contact from './Contact'
import XRservice from './XRservice'
import Gallery from './Gallery'
import Collage from './Gallery/Collage'
import Review from './Review/Review'
import VideoSection from './VideoSection'
import VideoSectiontlp from './VideoSectiontlp'
// import Faq from './Faq'
// import $ from "jquery";
// import MainSwiper from './MainSwiper.jsx'
import EventIcon from '../../EventIcon'
import ImagePopup from '../../ImagePopup'


class MainPage extends Component {
    state = {
        xrbookByTheHRDer: '',
        programUrls:[],
        loadingOpacity:1,
        isLoading : true
    }
    componentDidMount(){
        this._ismounted = true;
        if(this._ismounted){
            sessionStorage.removeItem('nonAuthReader');
            this.getxrbookByTheHRDer()
            let mainLoading = document.getElementById('mainLoading')
            // $('html, body').css("overflow", "hidden");
            
            setTimeout(()=>{
                let fadeEffect = setInterval(function () {
                        if (!mainLoading.style.opacity) {
                            mainLoading.style.opacity = 1;
                        }
                        if (mainLoading.style.opacity > 0) {
                            mainLoading.style.opacity -= 0.05;
                        } 
                        if(mainLoading.style.opacity===0) {
                            mainLoading.style.display = 'none'
                            
                            clearInterval(fadeEffect);
                            // $('html, body').css("overflow", "inherit")
                        }
                    }, 25)
            }, 2000)
            setTimeout(()=>{
                mainLoading.style.display = 'none'
                this.setState({isLoading : false})},2500)
    }   
    }
    componentWillUnmount() {
        this._ismounted = false;
     }
    getxrbookByTheHRDer = () => {
        this.props.firebase.mainSection('intro').get()
        .then(res=> {
            const urlList = res.data()
            this.setState(urlList)
        })
        .catch(err => console.log(err))
    }

    render() {
        const { xrbookByTheHRDer, programUrls, loading, theLittlePrinceByTheHRDer, isLoading } = this.state
        const style={"width":"100%"}
        return (
            <React.Fragment>
            <div id='mainLoading' 
            style={{ display:loading, zIndex: 2000 }}>
                <div className="loadingCont">
                    
                    <div className="imgGroup">
                        <div className="moving"></div>
                        <div className="shadow"></div>
                    </div>
                    <p className="loadText">Loading</p>
                    <div className="loadTitle">어린 왕자, 나를 만나다</div>
                    
                </div>
            </div>
            <EventIcon/>
            <ImagePopup loading={isLoading}/>
                <section style={style} id="INTRO"><Intro/></section>
                <div className="priContFrame">
                    <section style={style} id="THE_LITTLE_PRINCE">
                        <ClassroomLearning theLittlePrinceByTheHRDer={theLittlePrinceByTheHRDer} xrbookByTheHRDer={xrbookByTheHRDer} programUrls={programUrls}/>
                    </section>
                    <section style={style} id="DETAIL_1"><VideoSectiontlp/></section>
                    <section style={style} id="LEARNING_PROGRAM">
                        <SelfLearning xrbookByTheHRDer={xrbookByTheHRDer}/>
                    </section>
                    <section style={style} id="DETAIL_2"><VideoSection/></section>
                    <section style={style} id="GALLERY">
                        <Gallery/>
                        {/* <Collage/> */}
                    </section>
                    <section style={style} id="REVIEW"><Review/></section>
                    <section style={style} id="XR_SERVICE"><XRservice xrbookByTheHRDer={xrbookByTheHRDer} xrServiceUrls={programUrls}/></section>
                    {/* <section style={style} id="FAQ"><Faq/></section> */}
                    <section style={style} id="CONTACT"><Contact/></section>
                </div>
            </React.Fragment>
        )
    }
}
export default withEmailVerification(MainPage)
import React, { Component } from 'react'
import * as ROUTES from '../../constants/routes'
import { withFirebase } from '../Firebase'
import './ServiceCenter.css'

class ServiceCenter extends Component {

    render() {
        let {isOrder} = this.props
        return (
            <div className={`orderTab02 ${isOrder ? '':'serviceCenterFrame'}`}>
                <ul>
                    <li>
                        <h6>배송</h6>
                        <p className="queText">
                            ■ 프로그램 종료 후 디지털 북 인쇄 진행 상황 확인은
                            어디서 하나요?
                        </p>
                        <p className="ansText">
                        [https://www.xrbook.net] 로그인 → [마이페이지] → [결제 내역 및 이용 현황]
                        → [나의 On-line Learning 이용 현황] → [책 인쇄 서비스]
                        </p>
                    </li>
                    <li>
                        <p className="queText">
                            ■ 홈페이지에서 배송확인을 해보니 발송완료라고 되어
                            있는데 상품이 도착하지 않았어요.
                        </p>
                        <p className="ansText">
                            홈페이지 내 배송 진행 단계가 '발송완료'일 경우이거나
                            상품이 배송되었다는 메일 안내를 받으셨다면 택배사로
                            접수되어 배송 중이며 1~2일내로 수령 가능합니다. 만약
                            그 후에도 상품을 배송 받지 못하셨다면 홈페이지 내
                            ‘문의하기’에서 글을 남겨주시거나 고객 상담 센터로 전화
                            주시면 빠른 확인 후 안내드리겠습니다.
                        </p>
                    </li>
                    <li>
                        <p className="queText">
                            ■ 주문한 상품의 배송지 정보를 변경할 수 있나요?
                        </p>
                        <p className="ansText">
                            인쇄 진행 단계의 도서에 한해 배송지 정보는
                            [마이페이지]에서 주소 변경이 가능하며, 발송 단계부터는
                            배송지 변경이 불가능합니다. 프로그램 종료 후 배송지
                            입력란 기입 시 유의해 주세요.
                            <br />
                            <br />- 배송지 정보 변경 방법
                            <br />
                            [마이페이지] → [결제 내역 및 이용 현황] → [나의 On-line Learning 이용 현황] 
                            [책 인쇄 서비스] 클릭 후 배송지 정보 변경 가능. 배송
                            진행현황 "인쇄 중" 단계에서만 배송지 정보를 변경할 수
                            있으며, "배송 중" 단계부터는 배송지 정보를 변경할 수
                            없습니다.
                        </p>
                    </li>
                    <li>
                        <p className="queText">
                            ■ 옵션을 추가 시 배송비는 별도로 지불해야 하나요?
                        </p>
                        <p className="ansText">
                            {/* 기기대여 옵션 추가 시 기기 배송에 따른 비용은 사용자 부담입니다. 기기는 착불로 발송되며, 반납 시 선불 결제하여 택배로 보내주셔야 합니다.
                            <br /> */}
                            인쇄 옵션 추가 시 책 배송비는 별도로 추가되지 않습니다.
                        </p>
                    </li>
                    {/* <li>
                        <p className="queText">
                            ■ 기기대여 배송 관련 현황은 어디서 확인할 수 있나요?
                        </p>
                        <p className="ansText">
                            기기대여 현황은 [마이페이지]의 [결제 내역 및 이용 현황]
                            메뉴에서 [나의 On-line Learning 이용 현황]의 [기기대여현황] 버튼을 클릭하여 나의 대여 정보를
                            확인할 수 있습니다.
                            <br />
                            <br />※ 배송 완료된 시점부터 성실한 상품 관리 및
                            반납의 의무가 있습니다. 상품 및 구성품이 누락되지 않게
                            박스에 잘 포장해 반납하여 주시기 바랍니다. 대여 기간은
                            주소지로의 기기 배송 완료 시점부터 계산됩니다. 사용 기간
                            이내에 반납을 위한 택배 처리가 완료되어야 합니다. 
                            (10일 대여이므로 기기 배송 완료 일자를 1일째로 계산, 10일째날 택배 접수하여 반납 절차를 진행해야 합니다.) 
                            기기대여 반납의 택배 배송비는 사용자 부담이므로 택배 처리 시 선불 계산하여 주십시오.
                            <br />반납 전 기기의 온전한 상태를 사진으로 촬영한 이미지 파일을 메일로 보내주십시오.
                            <br />
                            <br />받는 이 : xrbook@thehrder.com 
                            <br />제목 : XR BOOK 사이트 기기대여 반납(사용자명-이메일-연락처)
                            <br />
                            <br />이미지 파일을 메일로 보내지 않고 택배로 보내신 기기의 경우 제품 확인 시 고장 및 손상의 책임은 사용자에게 있습니다.
                            기간 내 반납 절차가 진행되지 않을 경우 지연된 날 만큼 연체료가 부과됩니다.
                            신청하신 대여 기간 이내에 반납하더라도 남은 일수에 대한
                            비용은 환급되지 않습니다. 대여 기간 연장은 불가합니다. 
                            추가로 기기대여를 다시 신청하고자 할 경우 고객센터로 
                            문의하시기 바랍니다. 
                        </p>
                    </li> */}
                    {/* <li>
                        <p className="queText">
                            ■ 구매 기기 관련 배송 현황은 어디서 확인할 수 있나요?
                        </p>
                        <p className="ansText">
                            구매 기기 현황은 [마이페이지]의 [결제 내역 및 이용 현황] 메뉴에서 [나의 On-line Learning 이용 현황]의 [기기결제현황] 버튼을 클릭하여 나의 구매 완료한 기기의 배송 상태 확인할 수 있습니다.
                            수강 코드를 입력한 후 배송지 정보를 입력해야 기기 배송이 시작됩니다. 사용자의 배송지 입력 실수로 인한 피해 부담은 전적으로 사용자에게 있습니다.
                            (배송비는 기기 구매 가격에 포함되어 있습니다.)
                            <br/>※배송지 입력 시 올바른 주소 정보를 입력하고 제출 전 한번 더 확인하여 배송지 오류를 방지해 주세요.
                        </p>
                    </li> */}
                    <li>
                        <h6>취소/환불</h6>
                        <p className="queText">
                            ■ 결제한 프로그램을 전체 또는 일부 결제 취소, 수강료
                            환급이 가능한가요?
                        </p>
                        <p className="ansText">
                            On-line learning program을 전체 또는 일부 취소 가능하며,
                            프로그램 신청 후 7일 이내, 코드 등록 이전이면,
                            결제내역에서 결제를 취소하여 납부한 학습비를 전액
                            환급받을 수 있습니다. 전산오류를 제외한 고객님의
                            개인적인 사정으로 인한 환불 및 교환은 불가능합니다.
                            또한, 디지털 북 사용기간 연장은 불가능합니다. 디지털
                            콘텐츠 특성 상 코드 등록으로 해당 콘텐츠 접근이 가능해
                            진 이후에는 환불/환급이 불가합니다. 다른
                            프로그램으로의 교환(취소 가능 기한 이내 혹은 다운로드
                            전일 경우에만)은 불가하므로, 기한 내 취소 후 새로
                            신청(주문)해 주십시오.
                            <br />
                            <br />
                            *취소 방법 : [https://www.xrbook.net] 로그인 → [마이페이지] → [결제 내역
                            및 이용 현황] → [결제 내역] →
                            [환불/주문취소]
                            <br />
                            <br />
                            단, 디지털 수강 코드를 등록하여 페이지 접근이 가능해 진 후에는 
                            ‘환불/주문 취소‘버튼이 표시되지 않고 취소 불가합니다.
                            코드 등록 중 혹은 코드 등록 이후 이용 시 오류가 있는
                            경우에는 고객 센터로 문의해주십시오.
                        </p>
                    </li>
                    <li>
                        <p className="queText">
                            ■ 수강 시작한 프로그램의 환불 기준은 어떻게 되나요?{" "}
                        </p>
                        <p className="ansText">
                            프로그램에 대한 전액 환불은 승인일(결제완료일)로부터
                            7일 이내에 수강 코드 등록 이전일 경우에만 가능합니다. 그
                            외 환불신청에 따른 반환 금액은 프로그램 수강 진행률에
                            따라 책정됩니다.
                            『어린 왕자, 나를 만나다』 프로그램의 강좌는 총 <strong>"1"</strong>개 입니다.
                            강좌에 포함 된 <strong>"이러닝 콘텐츠"</strong>인 디지털 북 페이지 접근이 가능해지면,
                            산업통상자원부의 이러닝(전자학습) 이용표준약관에 의거하여
                            그 강좌를 이용한 것으로 간주합니다. 
                            따라서 그에 대한 환불은 이루어지지 않습니다.
                            수강 코드 등록으로 사용자가 해당 페이지에 접근이 가능해
                            진 경우, 동영상 재생이나 내용 작성이 이루어지지
                            않았다하더라도 프로그램 강좌 진행이 된 것으로 간주합니다.
                        </p>
                    </li>
                    <li>
                        <p className="queText">
                            ■ 신청한 프로그램 혹은 의뢰한 제작 서비스의
                            취소/환불이 가능한가요?
                        </p>
                        <p className="ansText">
                        사이트에서 결제 하지 않은 Off-line learning program과 
                        XR BOOK Making Service의 경우 고객센터 혹은 담당자에게
                        전화를 통해 취소/환불 진행이 가능합니다.
                        </p>
                    </li>
                    <li>
                        <p className="queText">■ 환불 확인은 언제 되나요?</p>
                        <p className="ansText">
                            결제 당일 취소 시 환불 반영 기간은 당일입니다. 
                            (단, 주말 제외. 주말에 결제 후 당일 취소하실 경우 그 주말이 끝난 영업일에 환불 처리 진행)
                            신용카드 환불은 카드사 정책에 따라 취소 반영
                            기간의 차이가 있으니 자세한 사항은 해당 카드사로
                            문의바랍니다.
                            <br />
                            <br />
                            ※ 결제수단별 환불 안내
                            <br />- 신용카드
                            <br />
                            &nbsp;당일 취소 시 전체 취소는 당일 승인
                            취소(환불)가능, 부분취소는 영업일 기준 3~5일 소요
                            <br />
                            &nbsp;당일 이후 취소 시 영업일 기준 3~7일 소요(처리
                            결과 카드사 통해 확인가능)
                            <br />- 실시간 계좌이체
                            <br />
                            &nbsp;당일 취소 시 이체한 계좌로 즉시 환불
                            <br />
                            &nbsp;당일 이후 취소 시 영업일 기준 3일 소요
                        </p>
                    </li>
                    <li>
                        <p className="queText">
                            ■ 가상계좌 결제/결제 취소는 어떤 방식으로 이뤄지나요?
                        </p>
                        <p className="ansText">
                            -가상계좌 결제
                            <br />
                            &nbsp;현금 결제를 하는 경우 무통장 입금이 가능한
                            은행을 선택하면 자동으로 결제자만의 고유한 일회용
                            계좌가 발생되는 서비스입니다. 결제 시 결제수단
                            선택에서 [가상 계좌] 클릭 후 입금할 은행을 선택하면
                            가상의 계좌가 발급되며, 할당된 계좌 번호로 입금 확인이
                            완료되면 서비스 이용이 가능합니다.
                            <br />- 가상계좌 결제 취소
                            <br />
                            &nbsp;[마이페이지]→[결제 내역 및 이용 현황]→[결제 내역]→[환불/주문취소 신청]에서 
                            결제취소하고자 하는 프로그램을 선택하고 환불 계좌를 입력하여 환불/주문 취소를 신청할 수 있습니다.
                            결제자 이름과 동일한 소유주 명의 계좌를 입력해야 함을 유의하십시오.
                            영업일 기준 3일정도의 시간이 소요될 수 있으며, 계좌번호를 올바로 입력하지 않으신 경우 
                            계좌 확인을 위한 시간 지연이 발생에 대한 책임은 본인에게 있습니다.
                            가상 계좌결제와 관련된 더 궁금한 사항은 고객센터로 문의해
                            주시기 바랍니다.
                        </p>
                    </li>
                    {/* <li>
                        <p className="queText">
                            ■ 기기 구매를 취소할 수 있나요?
                        </p>
                        <p className="ansText">
                            기기 대여 및 구매는 모두 XR BOOK 사이트의 온라인 러닝 프로그램을 지원하기 위한 서비스로
                            별도의 구매 상품이 아닌 프로그램에 포함되어 있는 서비스입니다.
                            따라서 기기 구매가 포함되어 있는 프로그램에서 기기 구매만을 별도로 취소할 수는 없습니다.
                            기기 구매 서비스 이용을 원치 않는 경우, 해당 프로그램 전체를 결제 취소(결제일로부터 7일 이내 수강코드 등록 이전일 경우)한 후 
                            기기 구매 옵션이 포함되어 있지 않은 프로그램으로 재결제 해야 합니다.
                            결제한 프로그램의 환불 규정은 기기 대여/구매 여부에 상관없이 모두 동일합니다.
                        </p>
                    </li>
                    <li>
                        <p className="queText">
                            ■ 구매한 기기의 교환이 가능한가요?
                        </p>
                        <p className="ansText">
                            배송된 제품을 수령한 후, 제품 상태를 꼭 확인해 주십시오. 
                            이상 시 수령 직후 바로 고객센터에 연락해 주셔야 교환 등의 처리가 가능합니다. 
                            단, 구매 물품 수령 당일 이후 제품의 불량등의 사실을 통보하지 않으면, 소비자의 과실로 추정합니다.
                        </p>
                    </li> */}
                    <li>
                        <p className="queText">□ 더 자세한 사항은 하단의 이용약관을 참고해 주세요.</p>
                    </li>
                </ul>
            </div>
        );
    }
}

export default withFirebase(ServiceCenter);

import React, { useState, useEffect, Component } from "react";
import { withFirebase } from '../../Firebase'
import InstituteList from "./InstituteList";
import "./index.css";
import { getTimeString } from "../../helpers";


const InstitutePage = (props) => {
const [showModal, onshowModal] = useState(false)
const [digitalBookList, ondigitalbookList] = useState([])
const [instituteList, oninstituteList] = useState([])
useEffect(() => {
  getinstituteList()
  getdigitalbookList()
}, [])
function getdigitalbookList(){
  props.firebase.libraries().get()
  .then(result=>{
    let digitalbookList = []
    result.forEach((doc) => {
        var bid = doc.id
        var listData= doc.data();
        digitalbookList.push({bid, ...listData})
        })
        ondigitalbookList(digitalbookList)
})
  .catch(err=> alert(`잠시 후 다시 시도 해주세요.\n에러 : ${err}`) )
}

function getinstituteList(){
  props.firebase.instituteLists().once('value',function(snapshot) {
    let instituteList = []
    snapshot.forEach(function(childSnapshot) {
      var instituteId = childSnapshot.key
      var listData = childSnapshot.val();
      instituteList.push({instituteId, ...listData})
    });
    oninstituteList(instituteList)
})
  .catch(err=> alert(`잠시 후 다시 시도 해주세요.\n에러 : ${err}`) )
}


  return (
    <React.Fragment>
        <button onClick={()=>onshowModal(true)}>교육기관등록</button>
        {showModal && 
        <SetNewInstitute onshowModal={onshowModal} digitalBookList={digitalBookList} firebase={props.firebase}/>
        }
        <ul className="programListCate instituteCate">
          <li>교육기관명</li>
          <li>참여 인원</li>
          <li>담당자명</li>
          {/* <li>진행 상황</li> */}
          <li>신청일자</li>
          <li>디지털북/영상/360영상</li>
          <li>비고</li>
        </ul>
        <ul>
        {instituteList.length>0 && 
        instituteList.map((c, id)=>{
          return(
            <li key={id}>
              <InstituteList instituteInfo={c} digitalBookList={digitalBookList} getinstituteList={getinstituteList}/>
            </li>
              
          )
        })}
        </ul>
      </React.Fragment>
  );
};

export default withFirebase(InstitutePage);


class SetNewInstitute extends Component {
  state = {
    institute :'',
    maxNum:100,
    instituteAdmin:'',
    instituteAdminNumber:'',
    instituteAdminEmail:'',
    instituteAdminDepart:'',
    digitalbookId:'',
    digitalbookNAme:'',
    isDigitalbook:false,
    isVideoOpen : true,
    is360VideoOpen: true
  }
  onStateChange = (e)=> {
    this.setState({
      [e.target.name] : e.target.value
    })
  }
  onsetNewInstitue = () => {
    let {
      institute,
      maxNum,
      instituteAdmin,
      instituteAdminNumber,
      instituteAdminEmail,
      instituteAdminDepart,
      digitalbookId,
      isDigitalbook,
      isVideoOpen,
      is360VideoOpen
      } = this.state
    let createdAt = getTimeString()
    let data = {
      institute,
      maxNum,
      instituteAdmin,
      instituteAdminNumber,
      instituteAdminEmail,
      instituteAdminDepart,
      digitalbookId,
      isDigitalbook,
      isVideoOpen,
      is360VideoOpen,
      createdAt,
      enteredMemo:''
    }

    let userData = {
      department : instituteAdminDepart,
      email : instituteAdminEmail,
      institute,
      studentId : '',
      subjectName : '',
      type : 'A',
      userId : instituteAdminEmail,
      username : instituteAdmin,
      createdAt
      }
      console.log(userData)
    this.props.firebase.instituteLists().push().set(data)
    .then(()=>{
      this.props.firebase.instituteAuthReaders().add(userData).then(()=>{
        alert('등록이 완료되었습니다.')
        this.props.onshowModal(false)
      })
    })
    .catch((err)=>{
      alert('오류')
    })
  }
  render() {
    let {
    institute,
    maxNum,
    instituteAdmin,
    instituteAdminNumber,
    instituteAdminEmail,
    instituteAdminDepart,
    digitalbookId,
    isDigitalbook,
    isVideoOpen,
    is360VideoOpen
    } = this.state
    let {digitalBookList} = this.props
    return (
      <div className='sendMsgContFrame'>
        <div className="sendMsgPopTitle">
          <h5>교육기관 등록</h5>
          <span onClick={()=>this.props.onshowModal(false)}>X</span>
        </div>
        <div>
          <span>교육기관명 : </span>
          <input type='text' name='institute' value={institute} onChange={(e)=>this.onStateChange(e)}/>
        </div>
        <div>
          <span>참여 가능 인원 : </span>
          <input type='number' name='maxNum' value={maxNum} onChange={(e)=>this.onStateChange(e)}/>
        </div>
        <div>
          <span>담당자명 : </span>
          <input type='text' name='instituteAdmin' value={instituteAdmin} onChange={(e)=>this.onStateChange(e)}/>
        </div>
        <div>
          <span>담당자이메일(로그인ID) : </span>
          <input type='text' name='instituteAdminEmail' value={instituteAdminEmail} onChange={(e)=>this.onStateChange(e)}/>
        </div>
        <div>
          <span>연락처 : </span>
          <input type='text' name='instituteAdminNumber' value={instituteAdminNumber} onChange={(e)=>this.onStateChange(e)}/>
        </div>
        <div>
          <span>담당자 부서 : </span>
          <input type='text' name='instituteAdminDepart' value={instituteAdminDepart} onChange={(e)=>this.onStateChange(e)}/>
        </div>
        <div>
          <span>디지털북 여부 : </span>
          <select name='isDigitalbook' value={isDigitalbook} onChange={(e)=>this.onStateChange(e)}>
            <option value={true}>허용</option>
            <option value={false}>미허용</option>
          </select>
        </div>
        {isDigitalbook && 
        <div>
          <span>디지털북 : </span>
          <select name='digitalbookId' value={digitalbookId} onChange={(e)=>this.onStateChange(e)}>
                <option value="">선택안함</option>
            {digitalBookList.length > 0 && digitalBookList.map((c,id)=>{
              return(
                <option key={id} value={c.bid}>{c.bookTitle}</option>
              )
            })}
          </select>
        </div>}
        <div>
          <span>영상 여부 : </span>
          <select name='isVideoOpen' value={isVideoOpen} onChange={(e)=>this.onStateChange(e)}>
            <option value={true}>허용</option>
            <option value={false}>미허용</option>
          </select>
        </div>
        <div>
          <span>360영상 여부 : </span>
          <select name='is360VideoOpen' value={is360VideoOpen} onChange={(e)=>this.onStateChange(e)}>
            <option value={true}>허용</option>
            <option value={false}>미허용</option>
          </select>
        </div>
        <div>
          <button onClick={()=>this.onsetNewInstitue()}>등록</button>
        </div>
      </div>
    )
  }
}

import React, { Component } from 'react';

import { withFirebase } from '../Firebase';

const INITIAL_STATE = {
  prevPassword:'',
  passwordOne: '',
  passwordTwo: '',
  error: null,
};

class PasswordChangeForm extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }

  onSubmit = event => {
    const { prevPassword, passwordOne } = this.state;
    const user = this.props.firebase.auth.currentUser;
    const credential = this.props.firebase.emailAuthProvider.credential(
      user.email, 
      prevPassword
  )
  user.reauthenticateWithCredential(credential) 
  .then(()=>
    this.props.firebase
      .doPasswordUpdate(passwordOne)
      .then(() => {
        alert(`비밀번호가 변경되었습니다.`)
        this.setState({...INITIAL_STATE})
      })
      .catch(error => {
        alert(`잠시 후 다시 시도해 주세요.\n에러코드 : ${error}`)
      })
  )
    .catch(err => alert(`잠시 후 다시 시도해 주세요.\n에러코드 : ${err}`))
    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { prevPassword, passwordOne, passwordTwo, error } = this.state;

    const isInvalid =
      prevPassword === '' ||
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      passwordOne.length<8

    return (
      <form className="pwChangeGroup" onSubmit={this.onSubmit}>
        <input
        name="prevPassword"
          value={prevPassword}
          onChange={this.onChange}
          type="password"
          placeholder="기존 비밀번호"/>
        <input
          name="passwordOne"
          value={passwordOne}
          onChange={this.onChange}
          type="password"
          placeholder="8자 이상"
        />
        <input
          name="passwordTwo"
          value={passwordTwo}
          onChange={this.onChange}
          type="password"
          placeholder="비밀번호 확인"
        />
        <button disabled={isInvalid} type="submit">
          비밀번호 변경
        </button>

        {error && <p>{error.message} 입력한 비밀번호를 확인 후 다시 시도해 주세요.</p>}
      </form>
    );
  }
}

export default withFirebase(PasswordChangeForm);

import React, {useState, useEffect} from 'react';
import './Gallery.css'
import './GalleryRes.css'
import chap1img1 from '../../../images/GalleryChapter1Image1.png'
import chap1img2 from '../../../images/GalleryChapter1Image2.png'
import chap1img3 from '../../../images/GalleryChapter1Image3.png'
import chap1img4 from '../../../images/GalleryChapter1Image4.png'
import chap1img5 from '../../../images/GalleryChapter1Image5.png'
import chap1img6 from '../../../images/GalleryChapter1Image6.png'
import chap1img7 from '../../../images/GalleryChapter1Image7.png'
import chap1img8 from '../../../images/GalleryChapter1Image8.png'
import chap2img1 from '../../../images/GalleryChapter2Image1.png'
import chap2img2 from '../../../images/GalleryChapter2Image2.png'
import chap2img3 from '../../../images/GalleryChapter2Image3.png'
import chap2img4 from '../../../images/GalleryChapter2Image4.png'
import chap2img5 from '../../../images/GalleryChapter2Image5.png'
import chap2img6 from '../../../images/GalleryChapter2Image6.png'
import chap2img7 from '../../../images/GalleryChapter2Image7.png'
import chap2img8 from '../../../images/GalleryChapter2Image8.png'
import chap3img1 from '../../../images/GalleryChapter3Image1.png'
import chap3img2 from '../../../images/GalleryChapter3Image2.png'
import chap3img3 from '../../../images/GalleryChapter3Image3.png'
import chap3img4 from '../../../images/GalleryChapter3Image4.png'
import chap3img5 from '../../../images/GalleryChapter3Image5.png'
import chap3img6 from '../../../images/GalleryChapter3Image6.png'
import chap3img7 from '../../../images/GalleryChapter3Image7.png'
import chap3img8 from '../../../images/GalleryChapter3Image8.png'
import chap4img1 from '../../../images/GalleryChapter4Image1.png'
import chap4img2 from '../../../images/GalleryChapter4Image2.png'
import chap4img3 from '../../../images/GalleryChapter4Image3.png'
import chap4img4 from '../../../images/GalleryChapter4Image4.png'
import chap4img5 from '../../../images/GalleryChapter4Image5.png'
import chap4img6 from '../../../images/GalleryChapter4Image6.png'
import chap4img7 from '../../../images/GalleryChapter4Image7.png'
import chap4img8 from '../../../images/GalleryChapter4Image8.png'
import $ from 'jquery'

class Collage extends React.Component {
  constructor(props){
    super(props);

    this.state = {
        index : 0,
        wholeList : [
         [
          chap1img5,chap1img8,chap1img1,chap1img4,chap1img3,chap1img6,chap1img2,chap1img7
        ],
        [
          chap2img5,chap2img6,chap2img3,chap2img7,chap2img8,chap2img2,chap2img1,chap2img4
        ],
        [
          chap3img6,chap3img4,chap3img1,chap3img2,chap3img5,chap3img3,chap3img7,chap3img8
        ],
        [
          chap4img4,chap4img2,chap4img3,chap4img1,chap4img5,chap4img6,chap4img7,chap4img8
        ]
        // [
        //     'https://images.unsplash.com/photo-1524159326054-584646eca4bb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80',
        //     'https://images.unsplash.com/photo-1570654282300-9e8952986614?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
        //     'https://images.unsplash.com/photo-1576864227261-4b6f9e7c187c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
        //     'https://images.unsplash.com/photo-1576864227261-4b6f9e7c187c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
        //     'https://images.unsplash.com/photo-1531259348720-3be7d9ff8c74?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1333&q=80',
        //     'https://images.unsplash.com/photo-1535683658347-f1a78e164b40?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1326&q=80',
        //     'https://images.unsplash.com/photo-1566702580807-95611c919b47?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1778&q=80',
        //     'https://images.unsplash.com/photo-1504201191696-30f960096cb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
        //     'https://images.unsplash.com/photo-1555871949-b95866325d1e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
        //     'https://images.unsplash.com/photo-1590305840707-3b9fc1db0da6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1834&q=80',
        //     'https://images.unsplash.com/photo-1589986972681-10282dc38b72?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
        //     'https://images.unsplash.com/photo-1570654282300-9e8952986614?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
        //     'https://images.unsplash.com/photo-1524159326054-584646eca4bb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80',
        //     'https://images.unsplash.com/photo-1576864227261-4b6f9e7c187c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
        //   ],
          // [
          //     'https://images.unsplash.com/photo-1524159326054-584646eca4bb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80',
          //     'https://images.unsplash.com/photo-1570654282300-9e8952986614?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
          //     'https://images.unsplash.com/photo-1576864227261-4b6f9e7c187c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
          //     'https://images.unsplash.com/photo-1576864227261-4b6f9e7c187c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
          //     'https://images.unsplash.com/photo-1531259348720-3be7d9ff8c74?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1333&q=80',
          //     'https://images.unsplash.com/photo-1535683658347-f1a78e164b40?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1326&q=80',
          //     'https://images.unsplash.com/photo-1566702580807-95611c919b47?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1778&q=80',
          //     'https://images.unsplash.com/photo-1504201191696-30f960096cb2?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
          //     'https://images.unsplash.com/photo-1555871949-b95866325d1e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
          //     'https://images.unsplash.com/photo-1590305840707-3b9fc1db0da6?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1834&q=80',
          //     'https://images.unsplash.com/photo-1589986972681-10282dc38b72?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
          //     'https://images.unsplash.com/photo-1570654282300-9e8952986614?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=634&q=80',
          //     'https://images.unsplash.com/photo-1524159326054-584646eca4bb?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1267&q=80',
          //     'https://images.unsplash.com/photo-1576864227261-4b6f9e7c187c?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1350&q=80',
          //   ]
        ],
        isModalOpen : false,
        modalIdx:0
    };
}
// componentDidMount(){
//   document.getElementsByClassName('pagerTitle4')[0].style.color = '#60c0bf';
// }
componentDidUpdate(prevProps, prevState){
  // if (prevState.index !== this.state.index) {
  //     for(var i =0; i<4; i++){
  //     document.getElementsByClassName('pagerTitle4')[i].style.color = '#000f1e';
  // }
  //     document.getElementsByClassName('pagerTitle4')[this.state.index].style.color = '#60c0bf';
  // }
}
onUrlChange = id => {
  let {photos, photos2} = this.state
  this.setState({
    showPhotos : id
  })
}

setSlide = index => {
  this.setState({index})

}
modalOpen = (id, status) => {
  if(status === true){
    $('html, body').css("overflow", "hidden");
    
} else {
    $('html, body').css("overflow", "inherit")
}
  this.setState({isModalOpen: status, modalIdx : id})
}
render(){
  const {wholeList, index, isModalOpen, modalIdx} = this.state;
  // let url = 
    return (
      <React.Fragment>
      <div className="galleryOutFrame">
        <div className="galleryInFrame">
          <div className="galleryTitleBox">
            <ul>
              <li className="sectMenuText">나의 어린 왕자 이야기를 공유합니다.</li>
              <li className="contTitleText">Reflection Gallery</li>
              <li className="contentText">
                <span className={`pagerTitle4 `} style={index === 0 ? {color: '#60c0bf', borderBottom:'1px solid #60c0bf', borderTop :'1px solid #60c0bf'}: {color:'#000f1e', borderBottom:'1px solid #eeeeee', borderTop :'1px solid #eeeeee' }} onClick={()=>this.setSlide(0)}>Chapter1 "어린 왕자를 만나다"</span>
                <span className={`pagerTitle4 `} style={index === 1 ? {color: '#60c0bf', borderBottom:'1px solid #60c0bf', borderTop :'1px solid #60c0bf'}: {color:'#000f1e', borderBottom:'1px solid #eeeeee', borderTop :'1px solid #eeeeee' }} onClick={()=>this.setSlide(1)}>Chapter2 "소행성 사람들을 만나다"</span>
                <span className={`pagerTitle4 `} style={index === 2 ? {color: '#60c0bf', borderBottom:'1px solid #60c0bf', borderTop :'1px solid #60c0bf'}: {color:'#000f1e', borderBottom:'1px solid #eeeeee', borderTop :'1px solid #eeeeee' }} onClick={()=>this.setSlide(2)}>Chapter3 "친구를 만나다"</span>
                <span className={`pagerTitle4 `} style={index === 3 ? {color: '#60c0bf', borderBottom:'1px solid #60c0bf', borderTop :'1px solid #60c0bf'}: {color:'#000f1e', borderBottom:'1px solid #eeeeee', borderTop :'1px solid #eeeeee' }} onClick={()=>this.setSlide(3)}>Chapter4 "나의 어린 왕자"</span>
              </li>
            </ul>
          </div>
          <div className="galleryContBox">
            <ul className="albumFrame">
              {wholeList[index] && wholeList[index].map((c,id)=> {
                return(
                  <li key={id} onClick={()=> this.modalOpen(id, true)}>
                    <span className={`gallery${id}`} style={{backgroundImage : `url(${c})`}}></span>
                  </li>
                )
              })}
            </ul>
          </div>
        </div>
      </div>
      {isModalOpen && <GalleryModal urlList={wholeList[index]} modalIdx={modalIdx} modalOpen={this.modalOpen}/>}
    </React.Fragment>
    );
};
}

export default Collage;

const GalleryModal = props => {
  let { urlList, modalIdx, modalOpen} = props
  useEffect(() => {
    setidx(modalIdx)
  }, [modalIdx])
  const [idx, setidx] = useState(modalIdx)
  let listLeng = urlList.length
  return(
      <div className="galleryPopFrame">
        <div className="galleryPopBg"  onClick={()=>modalOpen(0,false)}></div>
        <div className="galleryPopBox">
          <div className="galleryPopImage" style={{backgroundImage : `url(${urlList[idx]})`}}></div>
          <span className="galleryPopDel" onClick={()=>modalOpen(0,false)}>×</span>
          <span className="galleryPopPrev" style={idx===0 ? {color:"rgb(255,255,255, 0.2)"}: null} onClick={()=> idx >0 ? setidx(idx-1) : setidx(idx)}>&#60;</span>
          <span className="galleryPopNext" style={idx===listLeng-1 ? {color:"rgb(255,255,255, 0.2)"}: null} onClick={()=> idx < listLeng-1 ? setidx(idx+1) : setidx(idx)}>&#62;</span>
        </div>
      </div>
  )
}
import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import * as ROUTES from '../../../constants/routes'
import { withFirebase } from '../../Firebase';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Spinner from '../../Utils/Spinner'
import SharingPage from './sharingPage/SharingPage'
import { faHome } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class NonAuthMain extends Component {
    state={
        loading: false
    }
    componentDidMount(){
        let nonAuthReader = JSON.parse(sessionStorage.getItem('nonAuthReader'))
        if(nonAuthReader){
            this.setState({nonAuthReader})
        } else {
            this.props.history.push(ROUTES.XRBOOK_NONAUTH_LOGIN)
        }
    }
    deleteSession = () => {
        sessionStorage.removeItem('nonAuthReader');
        window.location.reload()
    }
    openDigitalbook = (data) => {
        let nonAuthName = this.state.nonAuthReader.username
        let {bookData, target, seenPageId, lastPageId, programInfo, nonAuthBookId, digitalBookID } = data
        let programId = programInfo.split('_')[0]
        let programTitle = programInfo.replace(`${programId}_`,"")
        if(data.bookData){
            this.props.history.push({
                pathname: ROUTES.XRBOOK_NONAUTH_WRITING,
                // search: '?query=abc',
                state: 
                {
                    // bookData,
                    nonAuthUser : this.state.nonAuthReader,
                    seenPageId,
                    lastPageId,
                    bookTitle : bookData['bookTitle'],
                    title : `${bookData['bookTitle']} (${target} 대상) - 저자 : ${nonAuthName}`,
                    review : false,
                    target: target,
                    mybookId : nonAuthBookId,
                    publishedCover : {},
                    codeStatus: true,
                    isFinished : false,
                    digitalBookID: digitalBookID,
                    programTitle
                }
              })
           
        } else {
            let bookData = ''
            this.props.firebase.library(digitalBookID).get()
            .then(res => {
                bookData = res.data()
                let today = new Date()
                this.props.firebase.nonAuthReader(nonAuthBookId).set({
                    bookData,
                    nonAuthUser : this.state.nonAuthReader,
                    seenPageId:0,
                    lastPageId:0,
                    bookTitle : data.title,
                    digitalBookID,
                    title : `${bookData['bookTitle']} (${target} 대상) - 저자 : ${nonAuthName}`,
                    review : false,
                    target: target,
                    mybookId : data.nonAuthBookId,
                    publishedCover : {},
                    codeStatus: true,
                    isFinished : false,
                    programTitle
                    
                },{merge:true})
                .then(()=>{
                    this.props.firebase.tlpStatistics().update({TotalmybookNon : this.props.firebase.fieldValue.increment(1)})
                })
                
            })
            .then(()=>{
                this.props.history.push({
                    pathname: ROUTES.XRBOOK_NONAUTH_WRITING,
                    // search: '?query=abc',
                    state: 
                    {
                        nonAuthUser : this.state.nonAuthReader,
                        // bookData,
                        seenPageId:0,
                        lastPageId:0,
                        bookTitle : data.title,
                        title : `${bookData['bookTitle']} (${target} 대상) - 저자 : ${nonAuthName}`,
                        review : false,
                        mybookId : data.nonAuthBookId,
                        publishedCover : false,
                        codeStatus: true,
                        isFinished : false,
                        programTitle
                    }
                  })
            })

        }
    }
    render() {
         let {totalList, confirmedList, expiredList, confirmedInvalidList, emptyDaysList, workBookList, nonAuthReaderData} = this.props
         let {loading, nonAuthReader} = this.state
        //  console.log(this.props)
        return (
            <React.Fragment>
                {loading ?
                    <Spinner msg='XR BOOK 불러오는 중'/>
                :
                    <div className="nonAuthHomeFrame">
                        <span>
                            <Link to={{pathname: `${ROUTES.TLP_MAIN}`}}><FontAwesomeIcon icon={faHome}/></Link>
                            {nonAuthReaderData !== undefined && <h3>{`${nonAuthReaderData['username']}(${nonAuthReaderData['email']})`}님</h3>}
                        </span>
                        <p>즐거운 하루 되세요!</p>
                        
                        <div>
                        {/* <h4>등록된 프로그램  ({totalList.length-workBookList.length})</h4> */}
                        </div>
                        <div>
                            <h5>현재 진행 가능한 프로그램 ({confirmedList ? confirmedList.length : 0})</h5>
                            <ul>
                                {confirmedList&& confirmedList.filter(c=> !c.isFinished).map((c, id)=> {
                                    // console.log(c)
                                    let tlpProgramId = c.programInfo.split('_')[0]
                                    let title = c.programInfo.split(`${tlpProgramId}_`)[1]
                                    return (
                                        <li key={id} onClick={()=>this.openDigitalbook(c)}>
                                            {title}({c.target}대상) - 소속 그룹 : {c.groupTitle} / 작성가능
                                        </li>
                                    )
                                })}
                                {confirmedList&& confirmedList.filter(c=> c.isFinished && !c.review).map((c, id)=> {
                                    let tlpProgramId = c.programInfo.split('_')[0]
                                    let title = c.programInfo.split(`${tlpProgramId}_`)[1]
                                    return (
                                        <li key={id}>
                                            <Link to={{pathname: `${ROUTES.XRBOOK_NONAUTH_REVIEW}`,
                                                state: {
                                                    bookTitle: c.bookData.bookTitle,
                                                    mybookId: c.mybookId,
                                                    digitalBookID: c.digitalBookID,
                                                    username : c.nonAuthUser.username,
                                                    target : c.target,
                                                    programId : c.programId,
                                                    bookData: c.bookData,
                                                    programTitle:c.programTitle
                                                }}} >
                                            {title}({c.target}대상) - 소속 그룹 : {c.groupTitle} /리뷰작성
                                            </Link>
                                        </li>
                                    )
                                })}
                                {confirmedList&& confirmedList.filter(c=> c.isFinished && c.review && !c.publishedCover).map((c, id)=> {
                                    let tlpProgramId = c.programInfo.split('_')[0]
                                    let title = c.programInfo.split(`${tlpProgramId}_`)[1]
                                    return (
                                        <li key={id}>
                                            <Link to={{pathname: `${ROUTES.XRBOOK_NONAUTH_COVER}`,
                                            state: {
                                                bookTitle:c.bookData.bookTitle,
                                                mybookId:c.mybookId,
                                                username :c.nonAuthUser.username,
                                                digitalBookID:c.digitalBookID,
                                                target :c.target,
                                                programId :c.programId,
                                                bookData:c.bookData,
                                                programTitle:c.programTitle
                                                }}} >
                                            {title}({c.target}대상) - 소속 그룹 : {c.groupTitle} /커버작성
                                            </Link>
                                        </li>
                                    )
                                })}
                                {confirmedList&& confirmedList.filter(c=> c.isFinished && c.review && c.publishedCover).map((c, id)=> {
                                    let tlpProgramId = c.programInfo.split('_')[0]
                                    let title = c.programInfo.split(`${tlpProgramId}_`)[1]
                                    return (
                                        <li key={id}>
                                            <Link to={{pathname: `${ROUTES.XRBOOK_NONAUTH_CHECK}`, state: {
                                                    bookData : c.bookData,
                                                    nonAuthUser : this.state.nonAuthReader,
                                                    title:title,
                                                    bookTitle:c.bookData.bookTitle,
                                                    mybookId:c.mybookId,
                                                    seenPageId:c.seenPageId,
                                                    lastPageId:c.lastPageId,
                                                    // published : c.published,
                                                    // bookFinishAt  : c.bookFinishAt,
                                                    review : c.review
                                                    }}} >
                                            {title}({c.target}대상) - 소속 그룹 : {c.groupTitle} /체크
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        {/* <div>
                            <h5>디지털북이 사전 설정되지 않은 프로그램 ({confirmedInvalidList.length})</h5>
                            <ul>
                                {confirmedInvalidList&& confirmedInvalidList.map((c, id)=> {
                                    let tlpProgramId = c.programInfo.split('_')[0]
                                    let title = c.programInfo.split(`${tlpProgramId}_`)[1]
                                    return (
                                        <li key={id}>
                                            {title}({c.target}대상) - 소속 그룹 : {c.groupTitle}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div> */}
                        <div>
                            <h5>진행 예정 프로그램 ({emptyDaysList ? emptyDaysList.length : 0})</h5>
                            <ul>
                                {emptyDaysList&& emptyDaysList.map((c, id)=> {
                                    let tlpProgramId = c.programInfo.split('_')[0]
                                    let title = c.programInfo.split(`${tlpProgramId}_`)[1]
                                    return (
                                        <li key={id}>
                                            {title}({c.target}대상) - 소속 그룹 : {c.groupTitle}
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <div>
                            <h5>종료된 프로그램 ({expiredList ? expiredList.length : 0})</h5>
                            <ul>
                            {expiredList&& expiredList.filter(c=> c.isFinished && c.review && c.publishedCover).map((c, id)=> {
                                    let tlpProgramId = c.programInfo.split('_')[0]
                                    let title = c.programInfo.split(`${tlpProgramId}_`)[1]
                                    return (
                                        <li key={id}>
                                            <Link to={{pathname: `${ROUTES.XRBOOK_NONAUTH_CHECK}`, state: {
                                                    bookData : c.bookData,
                                                    nonAuthUser : this.state.nonAuthReader,
                                                    title:title,
                                                    bookTitle:c.bookData.bookTitle,
                                                    mybookId:c.mybookId,
                                                    seenPageId:c.seenPageId,
                                                    lastPageId:c.lastPageId,
                                                    // published : c.published,
                                                    // bookFinishAt  : c.bookFinishAt,
                                                    review : c.review
                                                    }}} >
                                            {title}({c.target}대상) - 소속 그룹 : {c.groupTitle} /체크
                                            </Link>
                                        </li>
                                    )
                                })}
                            </ul>
                        </div>
                        <div>
                            <h5>Reflection Gallery 이용 현황</h5>
                            <SharingPage />
                        </div>
                        <div>
                            <Link to={{pathname: `${ROUTES.TLP_MAIN}`}}>홈으로 가기</Link>
                            <button onClick={()=>this.deleteSession()}>로그아웃</button>
                        </div>
                    </div>
                }
            </React.Fragment>
            
        )
    }
}
export default compose(
    withRouter,
    withFirebase,
  )(NonAuthMain);
import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import * as ROUTES from '../../../constants/routes';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../../Session';
import Digitalbook_PC_Check from './Digitalbook_PC_Check.jsx'
import Digitalbook_Mobile_Check from './Digitalbook_Mobile_Check.jsx'
import Digitalbook_PC_Admin from '../CheckAdmin/Digitalbook_PC_Admin'
import Digitalbook_Mobile_Admin from '../CheckAdmin/Digitalbook_Mobile_Admin.jsx'
import XRBOOKLogo from '../images/XRBOOKLogo.png'
import Manual from '../../Guide/Manual'
import $ from "jquery";
import { getTimeString } from '../../helpers';
import './UserCheck.css'

class UserCheck extends Component {
    state={
        bookData : this.props.location.state !== undefined ? this.props.location.state.bookData :{},
        seenPageId : this.props.location.state !== undefined ? this.props.location.state.seenPageId :0,
        lastPageId : 0,
        bookFinishAt  : this.props.location.state !== undefined ? this.props.location.state.bookFinishAt :'0',
        published  : this.props.location.state !== undefined ? this.props.location.state.published :0,
        review  : this.props.location.state !== undefined ? this.props.location.state.review :false,
        bookTitle  : this.props.location.state !== undefined ? this.props.location.state.bookTitle :'',
        
        windowWidth: window.innerWidth,
        pageId:0,
        pcPageId:0,
        authUser : JSON.parse(localStorage.getItem('authUser')),
        // nonAuthUser : sessionStorage.getItem('nonAuthReader'),
        titlebyAuth: this.props.location.state  !== undefined ? this.props.location.state.title : '',
        mybookId : this.props.location.state !== undefined ? this.props.location.state.mybookId :'',
        isChanged : false
    }
    componentDidMount(){
        this.onGetDigitalbook()
        window.addEventListener('resize', this.updateDimensions);

        if(this.props.location.state  === undefined) {
            alert('유효하지 않은 접근입니다.')
            this.props.history.push(ROUTES.USER)
        } else 
        if(this.props.location.state.bookData  === undefined){
            alert('북 데이터가 존재하지 않습니다.')
            this.setState({bookData : {}}, () => this.props.history.push(ROUTES.USER))   
        }    
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }
    updateDimensions = () => {
        this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
      };
    onUnload (e) {
        e.preventDefault();
        let confirmationMessage = '정말 닫으시겠습니까?'
            e.returnValue = confirmationMessage    // Gecko, Trident, Chrome 34+
            return confirmationMessage    // Gecko, WebKit, Chrome < 34
    }
    componentDidUpdate(prevProps, prevState){
        let {pcPageId, pageId} = this.state
        if(this.state.authUser['uid'] !== this.props.location.state.authUser['uid']){
            alert('유효하지 않은 사용자입니다.')
        }
        if(prevState.pageId !== this.state.pageId){
            this.setState({
                // pageId:pageId,
                // pcPageId:parseInt(pageId/2),
                seenPageId: this.state.seenPageId < pageId ?  pageId : this.state.seenPageId
            })
        } else if(prevState.pcPageId !== this.state.pcPageId){
            //pcPageId +1? 체크
            this.setState({
                // pageId:pcPageId*2,
                // pcPageId:pcPageId,
                seenPageId: this.state.seenPageId < pcPageId*2 ?  pcPageId*2 : this.state.seenPageId
            })
        }
        if(prevState.lastPageId !== this.state.lastPageId && this.state.pageId!==0){
            let {mybookId} = this.state
            this.props.firebase.mybook(mybookId).update({
                lastPageId : this.state.pageId
            })
        }
        if(prevState.seenPageId < this.state.seenPageId){
            let {mybookId, pageList, seenPageId} = this.state
            this.props.firebase.mybook(mybookId).update({
                seenPageId
            })
            // .then(()=>alert('저장되었습니다.'))
            // .catch((err)=>console.log(err))
        }
    }
    onGetDigitalbook = () => {
        let {mybookId} = this.props.location.state
        this.props.firebase.mybook(mybookId).get()
        .then((res)=>{
            let result = res.data()
            // console.log(result)
            let {
                bookFinishAt,
                bookData,
                review,
                target,
                site,
                unique,
                
            } = result
            this.setState({
                bookFinishAt,
                bookTitle : bookData.bookTitle,
                bookData,
                review,
                target,
                site,
                programId: unique,
                
            })
        })
        .catch((err)=>console.log(err))

    }
    onPageListChange = (name, value) => {
        if(name === 'pageList'){
            this.setState(prevState => ({
                bookData: {
                    ...prevState.bookData,
                    pageList : value
                },
            }))
            
        } else if (name === "pageIdPC"){
            this.setState(prevState => ({
                // pcPageId: prevState.pcPageId+value,
                pageId:prevState.pageId+(value*2)
            }))
        } else if (name === "pageIdMobile"){
            this.setState(prevState => ({
                pageId: prevState.pageId+value,
                // pcPageId:parseInt((prevState.pageId+value)/2)
            }))
            // console.log(value)
        } else if (name === "pageId"){
            this.setState({
                pageId: value
            })
        } else {
            this.setState({
                [name]:value
            })
        }
        }
        onSaveDigitalbookPage = (pageList) => {
            let {mybookId} = this.state
            this.props.firebase.mybook(mybookId).update({
                "bookData.pageList" : pageList
            })
            // .then(()=>console.log('저장되었습니다.'))
            .catch((err)=>console.log(err))
        }
        onSaveSharingPage = (pageId, page, pageList) => {
            let {mybookId, authUser} = this.state
            let chapter = page.chapter || 0
            let chapterKey = `Totalchapter${chapter}`
            let createdAt = getTimeString()
            let type = 'digitalbook'
            let authUserEmail = authUser['email']
            let authUserName = authUser['username']
            let authUserUid = authUser['uid']
            let isShowing = true
            this.props.firebase.sharingPages().add({pageId, page, createdAt, type, chapter,
                mybookId, authUserEmail, authUserName, authUserUid, isShowing })
            
            this.props.firebase.tlpStatistics().update({
                [chapterKey] : this.props.firebase.fieldValue.increment(1)
            })
            this.onSaveDigitalbookPage(pageList)
        }

        onManualGuide = (type) => {
            if(type ==='hidden'){
                $(".manualBg").hide();
            } else if (type === 'show'){
                $(".manualBg").show();
                
            }
        }
        saveAlert = (e, type) => {
            e.preventDefault()
            let result = window.confirm('저장하기 버튼을 누르지 않고 페이지를 이동할 경우 작성하신 내용이 저장되지 않습니다.')
            if(result && type==='user'){
                this.props.history.push({pathname: `${ROUTES.USER}`, state: {tab : 'library'}})
            } else if (result && type==='main'){
                this.props.history.push({pathname: `${ROUTES.TLP_MAIN}`})
            } else{
    
            }
        }
    render() {
        let {authUser, nonAuthUser, bookData, titlebyAuth, pageId,pcPageId, mybookId, seenPageId, lastPageId,
             review, published, bookTitle, target, site, programId, digitalBookID, userAddress, windowWidth} = this.state
        // let pageLeng = bookData.pageList ? bookData.pageList.length : 0
        return (
            <div className="authReaderFrame">
                <div className="manualBg" style={{display:'none'}}>
                    <div className="manualDelBtn" onClick={()=>this.onManualGuide('hidden')}>×</div>
                    <Manual headShow={false}/>
                </div>
                <div className="bookTop">
                    <div className="booInfoGroup">
                        <div className="iconFrame">
                            <img src={bookData.iconUrl || XRBOOKLogo } alt='icon'/>
                        </div>
                        <span>{titlebyAuth}</span>
                    </div>
                    <div className="linkGroup">
                        <div onClick={()=>this.onManualGuide('show')}>[ 기기 사용법 ]</div>
                        <span onClick={(e)=>this.saveAlert(e, 'user')} >MY PAGE</span> 
                        <span onClick={(e)=>this.saveAlert(e, 'main')}>HOME</span>
                    </div>
                </div>
                <div className="bookMiddle">
                    <p>
                        XR BOOK 작성 완료
                    </p>
                </div>
                {windowWidth >834 ? 
                <Digitalbook_PC_Check
                 digitalbook={bookData} onPageListChange={this.onPageListChange}
                 pageId={pageId} pcPageId={pcPageId} mybookId={mybookId} seenPageId={seenPageId}
                 lastPageId={lastPageId} onSaveDigitalbookPage={this.onSaveDigitalbookPage} onSaveSharingPage={this.onSaveSharingPage}
                 onSaveDigitalbook={this.onSaveDigitalbook}/>
                :               
                <Digitalbook_Mobile_Check
                 digitalbook={bookData} onPageListChange={this.onPageListChange}
                 pageId={pageId} mybookId={mybookId} seenPageId={seenPageId} lastPageId={lastPageId}
                 onSaveDigitalbook={this.onSaveDigitalbook} onSaveDigitalbookPage={this.onSaveDigitalbookPage} onSaveSharingPage={this.onSaveSharingPage}
                 />
                 }

                 {/* 아래는 체크어드민 */}
                 {/* {windowWidth >834 ? 
                <Digitalbook_PC_Admin
                 digitalbook={bookData} onPageListChange={this.onPageListChange}
                 pageId={pageId} pcPageId={pcPageId} mybookId={mybookId} seenPageId={seenPageId}
                 lastPageId={lastPageId} windowWidth={windowWidth}
                 onSaveDigitalbook={this.onSaveDigitalbook}/>
                :               
                <Digitalbook_Mobile_Admin
                 digitalbook={bookData} onPageListChange={this.onPageListChange}
                 pageId={pageId} mybookId={mybookId} seenPageId={seenPageId} lastPageId={lastPageId}
                 onSaveDigitalbook={this.onSaveDigitalbook} windowWidth={windowWidth}
                 />
                 } */}

            </div>
        )
    }
}


const condition = authUser => authUser
// && authUser.roles[ROLES.MEMBER] === ROLES.ADMIN;

export default compose(
    withEmailVerification,
    withAuthorization(condition),
  )(UserCheck);
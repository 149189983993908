import React, { Component } from 'react';
import { getTimeString, getTimeDayjs } from '../../../helpers'

class RefundedList extends Component {
    state={
        detailShow:'none'
    }
    onToggle = () => {
        const {detailShow} = this.state;
        if(detailShow === 'none'){
            this.setState({
            detailShow : 'show'
        })} else if(detailShow === 'show'){
            this.setState({
            detailShow : 'none'
        })}
    }
    render() {
        let {detailShow} = this.state
        const { receipt_id, price, username, reason, itemId, refundAt, isRefundAt, paidAt, phone, email, itemName,
            method, method_name, bankname, cancelAccount, isRefund} = this.props.refundDetailList
   var isrefundAtFormatted2 = getTimeDayjs(refundAt)
   var ispaidAtFormatted2 = getTimeDayjs(paidAt)
   var isisRefundAtFormatted2 = getTimeDayjs(isRefundAt)
   let priceFormatted = price.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        return (
            <React.Fragment>
                <ul className="salesListCont salesList7Cont">
                    <li>{username}</li>
                    <li>{itemName}</li>
                    <li>{priceFormatted}</li>
                    <li>{isrefundAtFormatted2}</li>
                    <li>{isisRefundAtFormatted2}</li>
                    <li>
                        <span>{isRefund ? '환불 완료' : '환불 미완료'}</span>
                        <div onClick={this.onToggle}>
                            {detailShow === "none"
                            ? `상세보기`
                            : detailShow === "show"
                            ? `상세닫기`
                            : `상세정보`}
                            </div>
                    </li>
                </ul>
                <ul className={`${detailShow} detailBox`}>
                    <li>
                        <span>연락처</span>
                        <span>{phone}</span>
                        <span>{email}</span>
                    </li>
                    <li>
                        <span>결제 유형</span>
                        <span>{method==='card'? '카드 결제' : method==='vbank'? '가상계좌' :method==='bank'? '계좌이체' : '확인 요망' }</span>
                    </li>
                    <li>
                        <span>결제 방법</span>
                        <span>{method==='card'? '고객 신청 취소'
                        // <a href='https://admin.bootpay.co.kr/receipt' target='_blank'>PG사/부트페이 사이트 취소</a>
                         : method==='vbank'? '현금 환불' :method==='bank'? 'KCP사이트 문의글 작성/확인' : '확인 요망' } 후 체크&nbsp; &nbsp;
                        {method==='vbank' || method==='bank'? `${bankname} ${cancelAccount}` : null }</span>
                    </li>
                    <li>
                        <span>결제 일자</span>
                        <span>{ispaidAtFormatted2}</span>
                    </li>
                    <li>
                        <span>환불 사유</span>
                        <span>{reason}</span>
                    </li>
                    <li>
                        <span>환불 처리 일자</span>
                        <span>{isRefundAt !=='' ? isisRefundAtFormatted2 : receipt_id}</span>
                    </li>
                </ul>
            </React.Fragment>
        );
    }
}

export default RefundedList;
import React, { Component } from 'react'
import ImageUpload from './ImageUpload'

class SlideImageItem extends Component {
    state={
        editing : false,
        pcImgUrl:this.props.item.pcImgUrl,
        mobileImgUrl:this.props.item.mobileImgUrl,
        text1:this.props.item.text1,
        text2:this.props.item.text2,
        item : this.props.item

    }
    onStatetoggle = name => {
        this.setState({
            [name] : !this.state.name
        })
    }
    onStateChange = (name, value) => {
        this.setState({
            [name] : value
        })
    }
    onSubmit = () => {
        const {pcImgUrl, mobileImgUrl, text1, text2} = this.state
        const {onEdit, idx} = this.props
        const data = {pcImgUrl, mobileImgUrl, text1, text2}
        this.setState({item : data, editing:false
        })
        onEdit(data, idx)
    }

    render() {
        const {pcImgUrl, mobileImgUrl, text1, text2, item, editing} = this.state
        const {onDelete, onEdit, idx} = this.props
        return (
            <React.Fragment>
                { !editing ?
                <div className="PreviewForConfirm">
                    <div className="sectImageBx">
                        <div>
                            <p>Image (PC)</p>
                            <img src={pcImgUrl} alt='PC 이미지'/>
                        </div>
                        <div>
                            <p>Image (mobile)</p>
                            <img src={mobileImgUrl} alt='Mobile 이미지'/>
                        </div>
                    </div>
                    <div className="sectTextBx">
                        <div>{text1}</div>
                        <div>{text2 && text2.split('<br />').map((c, id)=> {
                        return(
                                    <span key={id}>{c}<br/></span>
                                )})}</div>
                    </div>
                    <button onClick={()=>this.onStatetoggle('editing')}>수정</button>
                </div>
                :
                <div className="previewForModify">
                    <div className="sectImageBx">
                        <div>
                            <p>Image (PC)</p>
                            <ImageUpload 
                                onUpdate={this.onStateChange}
                                itemName={'pcImgUrl'}
                                fileName={'main/class'}
                            />
                        </div>
                        <div>
                            <p>Image (mobile)</p>
                            <ImageUpload 
                                onUpdate={this.onStateChange}
                                itemName={'mobileImgUrl'}
                                fileName={'main/class'}
                            />
                        </div>
                    </div>
                    <div className="sectTextBx">
                        <input type = 'text' name='text1' value={text1} onChange={e=> this.onStateChange('text1', e.target.value )}/>
                        <textarea name='text2' value={text2.replace(/(<br>|<br\/>|<br \/>)/g, '\n')} onChange={e=> this.onStateChange('text2', e.target.value.replace(/(?:\r\n|\r|\n)/g, '<br />') )}/>
                    </div>
                    <button onClick={()=>this.onSubmit()}>저장</button>
                </div>
                }
                <button className="contentDelBtn" onClick={()=> onDelete(item)}>삭제</button>
            </React.Fragment>
        )
    }
}
export default SlideImageItem
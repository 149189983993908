import React, { Component } from 'react'
import './Terms&Policies.css'

export default class RentContract extends Component {
    componentDidMount() {
        try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    };
      }
    render() {
        return (
            <div className="termPoliBg">
            <div className="termPoliframe">
                <h1>기계 임대 계약서</h1>
                <div className="underBar"></div>
                
                <div className="ag ag00">
                    <div className="uaBox">
                        <ul>
                            <li>임차인 더에이치알더㈜와 임대인은 상호 신뢰와 협조에 의거 아래와 같이 임대차 계약을 체결하고 제품의 신용을 높여 상호간의 번영을 위해 본 임대차 계약을 체결한다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag01">
                    <h5>제 1 조 계약의 목적 </h5>
                    <div className="uaBox">
                        <ul>
                            <li>본 임대차 계약의 목적은 더에이치알더㈜가 임대인에게 VR 기기 HMD XR 기반 교육 콘텐츠 어린왕자, 나를 만나다의 원활한 체험을 제공하기 위함이다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag02">
                    <h5>제 2 조 임대계약의 목적</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1.	더에이치알더(주)과 임대인은 하기의 제품 유상 임대계약을 체결한다.</li>
                            <li>2.	임대차 계약의 목적물 : 더에이치알더(주)의 소유인 HMD 기계(Oculus Go) 1대.</li>
                            <li>3. 	목적물의 표시
                                <ul className="detailBx">
                                    <li>1)	계약기간(임대 기간) : Oculus Go 체험기간</li>
                                    <li>2)	체험기간이 종료된날을 포함한, 이후 가장 가까운 영업일(주말/공휴일 제외)에 서울특별시 법원로11길 25 A동 1006호 (문정동, 에이치비지니스파크)로 배송출발되어야 한다.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag03">
                    <h5>제 3 조 가입과 승인</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1.	더에이치알더(주)이 임대인에게 공급하는 제품을 체험하고 체험 후 계약기간이 종료되면 기기를 반납한다.</li>
                            <li>2.	임대인의 임대 물건에 대해 소유권은 더에이치알더(주)에게 있다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag04">
                    <h5>제 4 조 관  리</h5>
                    <div className="uaBox">
                        <ul>
                            <li>1.	기계의 안전사고에 대하여 충분한 안전 교육을 실시하여야 하며 사고 발생시 그 책임은 임대인이 진다.</li>
                            <li>2.	더에이치알더(주)은 임대인에게 사용 설명서를 제공하고, 임대인은 기계의 임대 전에 반드시 사용설명서를 숙지한다.</li>
                            <li>3.	사용기간 중 발생하는 고장에 대해서는 기계의 결함으로 발생하는 비용은 더에이치알더(주)이 책임을 지며, 임대인의 과실로 인한 고장 및 분실로 인해 발생하는 수리 비용은 임대인이 직접 처리한다.</li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag05">
                    <h5>제5조. 계약 종료 후의 처리</h5>
                    <div className="uaBox">
                        <p>더에이치알더는 정보주체의 이용정보를 저장하고 수시로 불러오는 ‘쿠키’를 사용하지 않습니다.</p>
                    </div>
                </div>
                <div className="ag ag06">
                    <h5>제6조. 개인정보 보호책임자</h5>
                    <div className="uaBox">
                        <ul>
                            <li>본 임대차 가맹계약의 종료 또는 중도해지 후 임대인은 아래 각 항을 이행할 의무와 책임을 진다.</li>
                            <li>
                                <ul className="detailBx">
                                    <li>1.	임대인은 임대물을 더에이치알더(주)의 사무실로 임대물을 받은 후 체험기간이 종료되면 반납하여야한다. 임대인의 반납이 계약 기간 이후 일주일 이상 늦어질 시, 분실로 간주하여 임대인은 더에이치알더㈜에 기기 값의 100%를 변상한다.</li>
                                    <li>2.	더에이치알더(주)은 임대물의 이상 유무를 상호 확인하여 인수인계를 한다. 단, 사용 부주의로 발생된 부분에 대해서는 임대인이 배상한다.</li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="ag ag07">
                    {/* <h5 style={{textAlign:'center'}}>계약 일자 : 20&nbsp;&nbsp;년 &nbsp;&nbsp;월 &nbsp;&nbsp;일</h5> */}
                    <br/>
                    <br/>
                    <br/>
                    <h5>위 계약 내용을 확인하였으며, 이에 동의합니다.</h5>
                </div>
            </div>
        </div>
        )
    }
}

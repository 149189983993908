import React, { Component } from 'react'
import { isEmail, phoneFormatter, sideTrim, getTimeString } from '../../helpers'
import {withFirebase} from '../../Firebase'
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Spinner from '../../Utils/Spinner'
import * as ROUTES from '../../../constants/routes'
import '../Order.css'

class OrderSelfPage extends Component {
   state = {
        programData: this.props.salesList,
        pid : this.props.selectedProgram.pid,
        programID : 0,
        participants : 0,
        // 30,
        groupTitle :'',
        manager : this.props.authUser.username,
        email : this.props.authUser.email,
        number: this.props.authUser.number||'',
        period: this.props.selectedPeriod || '7',
        contents: '',
        vrRent : this.props.vrRent|| false,
        vrEvent : this.props.vrEvent|| false,
        isPublished : this.props.isPublished|| false,
        published : this.props.bookQuantity || 0,
        type:'견적 문의',
        isEmailValid: true,
        loading : false
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.selectedPeriod !== this.props.selectedPeriod){
            this.setState({period:this.props.selectedPeriod})
        }
        if(prevProps.bookQuantity !== this.props.bookQuantity){
            this.setState({
                published : this.props.bookQuantity
            })
        }
        if(prevProps.isPublished !== this.props.isPublished){
            this.setState({isPublished:this.props.isPublished})
        }
        if(prevProps.vrRent !== this.props.vrRent){
            this.setState({vrRent:this.props.vrRent})
        }
        if(prevProps.vrEvent !== this.props.vrEvent){
            this.setState({vrEvent:this.props.vrEvent})
        }
    }
    validateEmail = e => {
        const emailEntered = e.target.value
        if (isEmail(this.state.email)) {
          this.setState({
            isEmailValid: true,
            email : emailEntered
          });
        } else {
          this.setState({
            isEmailValid: false,
            email : emailEntered
          });
        }
      };
      stateChange = (e) => {
        const targetName = e.target.name
        const targetValue= e.target.value
        // console.log(targetName, targetValue)
        if(targetName === 'contents'){
            // console.log(targetName, targetValue)
            this.setState({
                [targetName] : targetValue.replace(/(?:\r\n|\r|\n)/g, '<br />')
            })
        } else {
        this.setState({
            [targetName] : targetValue
        })
    }
    }
    onStateToggle = data => {
        if(data === 'isPublished'){
            this.setState({
                isPublished : !this.state.isPublished
            })
        }
    }
    onSubmit = async(e) => {
        
        e.preventDefault();
        const {programData, programID, participants, groupTitle, manager, vrRent, vrEvent, type, period,
            email, number, contents, isPublished, published, pid } = this.state;
            const {title, target} = programData[programID]
        this.setState({loading : true})
        const createdAt = getTimeString()
        const authUserId = this.props.authUser.uid
        const page = 'tlpSales'
        const progress = 'applied'
        const progressAt = createdAt
        const programInfo = `${pid}_${title}`
        const memo=''; const code=''; const codeAt=''; const selectedDays=[]; const price = ''
        const data = { authUserId, programInfo, participants, groupTitle, manager, title, target,
             email, number, contents, progress, progressAt, createdAt, page, vrRent, vrEvent, type, period,
             memo, code, codeAt, selectedDays, price, isPublished, published }
        // console.log(data)
             await fetch("/api/v1/orderAlerts", {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(data)
            })
            .then(response => { response.json()
                .then((res) => {
                   
                  if(res.msg === 'success') {
                  alert('이러닝 문의가 접수되었습니다.\n영업일 기준 2-3일 이내 회신드리겠습니다.')
                  this.props.history.push(ROUTES.ONLINE_APPLIEDPAGE)
                } else {
                  alert('다시 시도해주세요. \n오류가 계속되면 관리자에게 문의해주세요. \nxrbook@thehrder.com')
                }
              })
              .catch(err =>  alert(`다시 시도해주세요. \n오류가 계속되면 관리자에게 문의해주세요. \nxrbook@thehrder.com \n에러 : ${err}`))
              })
            .catch(err =>  alert(`다시 시도해주세요. \n오류가 계속되면 관리자에게 문의해주세요. \nxrbook@thehrder.com \n에러 : ${err}`))
            // this.setState({loading : false})
    }

    render() {
        const {vrRent, vrEvent, type, participants, groupTitle, manager, email, number, contents, isEmailValid, loading,
            period, isPublished, published } = this.state;
        const numLeng = number.length
        const {selectedProgram} = this.props
        // console.log(this.props)
        const {title, target} = selectedProgram
        const isInvalid = 
        participants === '' ||
        groupTitle === '' ||
        manager === '' ||
        numLeng < 9 ||
        isEmailValid === false ||
        email === '' ||
        contents === ''
        const enteredContents =  contents.replace(/(<br>|<br\/>|<br \/>)/g, '\n')
        return (
            <React.Fragment>
            {!loading ? 
            <div className="orderPFrame">
                <form className="elearningApplyForm" onSubmit={this.onSubmit} method='post'>
                    <h6>On-line Learning Program 신청 문의하기</h6>
                    <ul>
                        <li>
                            <span className="cate">담당자</span> 
                            <span>
                                <span>{manager} &nbsp;|&nbsp; {email}</span>
                                {/* <input type='text' name='manager' value={manager} placeholder='담당자 명'
                            onChange={event => this.setState({manager: event.target.value.trim()})}/>  */}
                                <input className="phoneNumber" type='text' name='number' value={number} placeholder='연락가능한 전화번호'
                                onChange={event => this.setState({number: phoneFormatter(event.target.value.replace(/[^0-9]/g,""))})}/>
                            </span>
                        </li>
                        <li>
                            <span className="cate">회사/기관 명</span>  
                            <input type='text' name='groupTitle' value={groupTitle}
                            onChange={event => this.setState({groupTitle: sideTrim(event.target.value)})}/>
                        </li>
                        <li>
                            <span className="cate">프로그램 명</span>  
                            <span>{title} : {target} 대상</span>
                        </li>
                        <li>
                            <span className="cate">교육 기간</span> 
                            <select name='period' value={period} onChange={this.stateChange}>
                                <option value='7'>7일</option>
                                <option value='14'>14일</option>
                            </select>
                        </li>
                        <li>
                            <span className="cate">교육 인원</span>  
                            <input type='text' name='participants' value={participants}
                            onChange={event => this.setState({participants: event.target.value.replace(/[^0-9]/g,"")})}/>
                        </li>
                        <li className="checkInputBx">
                            <span className="cate">출판 인쇄</span>  
                            <ul className="bookPublishing">
                                <li className="productSelect">
                                    <input type='checkbox' name='isPublished'  checked={!isPublished} onChange={()=>this.onStateToggle('isPublished')} /><span>인쇄 안 함</span>
                                    <input type='checkbox' name='isPublished' checked={isPublished} onChange={()=>this.onStateToggle('isPublished')} /><span>인쇄</span>
                                </li>
                                {isPublished &&
                                    <li className="productQuantity">
                                        <input type='number' min='2' value={published}
                                        onChange={e=>this.setState({published : e.target.value})}/>&nbsp; 부
                                        <span className="plusMinusBtn" onClick={()=>this.setState({published:published+1})}> + </span>
                                        <span className="plusMinusBtn" onClick={()=>published > 2 ? this.setState({published:published-1}) : this.setState({bookQuantity:2})}> - </span>
                                    </li>}
                            </ul>
                        </li>
                        {/* <li className="checkInputBx">
                            <span className="cate">VR 기기</span>
                            <span>
                                <span>
                                    <input type='checkbox' checked={!vrRent&&!vrEvent} onChange={()=>this.setState({vrRent:false, vrEvent:false})}/> 
                                    <span onClick={()=>this.setState({vrRent:false, vrEvent:false})}>대여/구매 안함</span>
                                </span>
                                <span>
                                    <input type='checkbox' name='vrRent' checked={vrRent} onChange={()=>this.setState({vrRent:!vrRent, vrEvent:false})}/> 
                                    <span onClick={()=>this.setState({vrRent:!vrRent, vrEvent:false})}>대여 요청</span>
                                </span>
                                <span>
                                    <input type='checkbox' name='vrEvent' checked={vrEvent} onChange={()=>this.setState({vrEvent:!vrEvent, vrRent:false})}/> 
                                    <span onClick={()=>this.setState({vrEvent:!vrEvent, vrRent:false})}>구매 요청</span>
                                </span>
                            </span>
                        </li> */}
                        <li className="checkInputBx">
                            <span className="cate">요청 사항</span>
                            <span>
                                <input type='checkbox' name='type' checked={type==='견적 문의'} onChange={()=>this.setState({type:'견적 문의'})}/> 
                                <span onClick={()=>this.setState({type:'견적 문의'})}>견적 문의</span>
                                <input type='checkbox' name='type' checked={type==='미팅 요청'} onChange={()=>this.setState({type:'미팅 요청'})}/> 
                                <span onClick={()=>this.setState({type:'미팅 요청'})}>미팅 요청</span>
                            </span>
                        </li>
                        <li>
                            <span className="cate">내용</span>  
                            <textarea name='contents' placeholder='주제 / 교육 시기 / 대상 / 인원 수 / 요청사항 등을 작성해 주세요.'
                             value={enteredContents} onChange={this.stateChange}/>
                        </li>
                        <li className="salesInquiryBtn">
                            <button disabled={isInvalid} type='submit'>신청하기</button>
                        </li>
                    </ul>
                </form>
            </div>
            : <Spinner msg={`문의사항을 등록 중입니다.`}/>}
            </React.Fragment>
        )
    }
}
export default compose(
    withRouter,
    withFirebase,
  )(OrderSelfPage);
  
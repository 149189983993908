import React, { Component } from 'react'

class XrMakingApplied extends Component {
    state = {
        appliedList : this.props.appliedList,
        detailShow: 'none'
    }
    componentDidMount (){
        // console.log(this.props)
        const {onGetList} = this.props
        onGetList()
    }
        onListStatechange = (e, list) => {
            const targetName = e.target.name
            const targetValue = e.target.value
            if(targetName === 'memo'){
                list[targetName] = targetValue.replace(/(?:\r\n|\r|\n)/g, '<br />')
                } else if(targetName === 'price'){
                    list[targetName] = targetValue.replace(/[^0-9]/g,"")
                } else {
                    list[targetName] =targetValue
                }
        this.setState({
            appliedList: list
        })
    }
    onStateChange = (e) => {
        this.setState({
                [e.target.name]: e.target.value
        })
    }
     editProgramInfo =(uid)=>{
        const {appliedList} = this.state
        const {oneditList} = this.props
        oneditList(uid, appliedList)
     }
     onToggle = () => {
         const {detailShow} = this.state;
         if(detailShow === 'none'){
        this.setState({
            detailShow : 'show'
    })} else if(detailShow === 'show'){
        this.setState({
            detailShow : 'none'
    })}
     }
    render() {
        const { appliedList, detailShow } = this.state
        const purpose = appliedList.makingType ==='leadership' ? '리더십 기억 교육'
        : appliedList.makingType ==='memorial' ? 'Memorial'
        : appliedList.makingType ==='commemoration' ? 'Commemoration'
        : appliedList.makingType
        const progressY = parseInt(appliedList.progressAt/10000000000)
        const progressM = parseInt(appliedList.progressAt/100000000)%100
        const progressD = parseInt(appliedList.progressAt/1000000)%100
        const enteredMemo = appliedList.memo.replace(/(<br>|<br\/>|<br \/>)/g, '\n')
        const enteredContents = appliedList.contents.replace(/(<br>|<br\/>|<br \/>)/g, '\n')
        const price = appliedList.price.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        return (
                <div >
                    <div>제작 목적 : {purpose}</div>
                    <div>사용자명 : {appliedList.manager}</div>
                    <div>회사/학교명 : {appliedList.groupTitle}</div>
                    <div>
                        <select name='progress' value={appliedList.progress} onChange={e=> this.onListStatechange(e, appliedList)}>
                            <option value='applied'>의뢰</option>
                            <option value='consulting'>담당자 배정</option>
                            {price !== '' && 
                                    <React.Fragment>
                                    <option value='request'>결제요청</option>
                                    <option value='paid'>결제완료</option>
                                    </React.Fragment>}
                            <option value='finished'>제작 완료</option>
                            <option value='canceled'>취소</option>
                        </select>
                    </div>
                    
                    <div>일자 : 20{progressY}년 {progressM}월 {progressD}일</div>
                    <div onClick={this.onToggle}>상세보기</div>
                    <div className={`${detailShow}`}>
                        <div>메일 : {appliedList.email}</div>
                        <div>연락처 : {appliedList.number}</div>
                        <div>신청 내용 : {enteredContents}</div>
                        <div>가격 : <input type='text' name='price' value={price} onChange={e=> this.onListStatechange(e, appliedList)}/></div>
                        <div>담당자/상세내용 : <textarea name='memo' value={enteredMemo} onChange={e=> this.onListStatechange(e, appliedList)}
                        style={{width:'50vw', height:'200px', textAlign:'left' }}/></div>
                    </div>
                    <button onClick={()=> this.editProgramInfo(appliedList.uid)}>적용</button>
                </div>
        );
    }
}
export default XrMakingApplied
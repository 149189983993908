import React, { Component } from 'react'
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import { TwitterPicker } from 'react-color';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
// import { withFirebase } from '../Firebase';
import { withAuthorization, withEmailVerification } from '../../Session';
import ImageUploadNR from '../../Utils/ImageUploadNR'
import * as ROLES from '../../../constants/roles';
import { getTimeString } from '../../helpers'
import { withFirebase } from '../../Firebase';
import './AdminEvent.css'

class EventManagement extends Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
    this.handleDayMouseEnter = this.handleDayMouseEnter.bind(this);
    this.handleResetClick = this.handleResetClick.bind(this);
    this.state = this.getInitialState();
  }
  getInitialState() {
    return {
      from: null,
      to: null,
      enteredTo: null, // Keep track of the last day for mouseEnter.
      bannerColor:'#ffffff',
      bannerText:'',
      textColor:'black',
      imgUrl:'',
      mobileImgUrl:'',
      iconUrl:'',
      eventList:[]
    };
  }
  componentDidMount(){
    this.getBanner()
  }

  isSelectingFirstDay(from, to, day) {
    const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
    const isRangeSelected = from && to;
    return !from || isBeforeFirstDay || isRangeSelected;
  }
  handleDayClick(day) {
    const { from, to } = this.state;
    if (from && to && day >= from && day <= to) {
      this.handleResetClick();
      return;
    }
    if (this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        from: day,
        to: null,
        enteredTo: null,
      });
    } else {
      this.setState({
        to: day,
        enteredTo: day,
      });
    }
  }

  handleDayMouseEnter(day) {
    const { from, to } = this.state;
    if (!this.isSelectingFirstDay(from, to, day)) {
      this.setState({
        enteredTo: day,
      });
    }
  }

  handleResetClick() {
    this.setState(this.getInitialState());
  }

    colorChange = (color, event) => {
      event.preventDefault();
      this.setState({
        bannerColor:color.hex
      })
    }
    toggleChange=()=>{
      if(this.state.textColor === 'white'){
      this.setState({
        textColor : 'black'
      })
    } else if(this.state.textColor === 'black') {
        this.setState({
          textColor : 'white'
        })
        }
      }

    onSubmit = (e) => {
      // 등록 시간 설정
      const {from, to, enteredTo, bannerColor, bannerText, textColor, imgUrl, mobileImgUrl, iconUrl} = this.state
      const startDate = parseInt(getTimeString(from)/1000000)
      const endDate = parseInt(getTimeString(to)/1000000)
      const createdAt = getTimeString()
      this.props.firebase.banner(createdAt).set({
        startDate, endDate, enteredTo, bannerColor, bannerText, textColor, imgUrl, mobileImgUrl, iconUrl, createdAt
      })
      .then(()=> {
        alert('이벤트 및 배너가 등록되었습니다.')
      })
      .then(() => {
        this.getBanner()
      })
      .catch(err => {
        alert('에러 발생\n'+err)
      })
      // e.preventDefault()
    }
    getBanner = () => {
      this.props.firebase.banners().orderBy('createdAt','desc').limit(1).get()
      .then((result) => {
        var eventList = [];
        result.forEach((doc) => {
            var childData = doc.data();
            eventList.push(childData);
        })
        this.setState({eventList})
      //  this.setState({})
    })
    .catch(function(err) {
        console.log(err)
    });
    }
      onPropsChange = (name, value) => {
    this.setState({
        [name] : value
    })
  }

    render() {
        const {from, to, enteredTo, eventList, imgUrl, iconUrl, mobileImgUrl} = this.state
        const modifiers = { start: from, end: enteredTo };
        const disabledDays = { before: this.state.from };
        const selectedDays = [from, { from, to: enteredTo }];
        return (
            <div className="adminEventFrame">
                <h3>이벤트 관리</h3>
                <div className="eventMakingFrame">
                  <div className="eventPeriodGroup">
                    <DayPicker
                      className="Range"
                      numberOfMonths={2}
                      fromMonth={from}
                      selectedDays={selectedDays}
                      disabledDays={disabledDays}
                      modifiers={modifiers}
                      onDayClick={this.handleDayClick}
                      onDayMouseEnter={this.handleDayMouseEnter}
                    />
                    <div className="setCpPeriodText">
                      {!from && !to && '이벤트 시작일을 선택해주세요.'}
                      {from && !to && '이벤트 종료일을 선택해주세요.'}
                      {from &&
                        to &&
                        `Selected from ${from.toLocaleDateString()} to
                            ${to.toLocaleDateString()}`}{' '}
                      {from && to && (
                        <button className="link" onClick={this.handleResetClick}>
                          Reset
                        </button>
                      )}
                    </div>
                  </div>
                  {/* 이미지업로드 */}
                  <div className="eventImageUploadGroup">
                    <div>
                      <div>
                        <p>Main Page Link Image</p>
                        
                        <ImageUploadNR
                          onUpdate={this.onPropsChange}
                          itemName={'iconUrl'}
                          fileName={'event/'}
                        /> 
                      </div>
                      <img src={iconUrl} alt="upload"/>
                    </div>
                    <div>
                      <div>
                        <p>Event Page Image(PC)</p>
                        <ImageUploadNR
                          onUpdate={this.onPropsChange}
                          itemName={'imgUrl'}
                          fileName={'event/'}
                        /> 
                      </div>
                      <img src={imgUrl} alt="upload"/>
                    </div>
                    <div>
                      <div>
                        <p>Event Page Image(mobile)</p>
                        <ImageUploadNR
                          onUpdate={this.onPropsChange}
                          itemName={'mobileImgUrl'}
                          fileName={'event/'}
                        /> 
                      </div>
                      <img src={mobileImgUrl} alt="upload"/>
                    </div>
                  </div>
                </div>
                <div className="eventUploadBtn">
                  <button onClick={this.onSubmit} disabled={!imgUrl}>이벤트 등록하기</button>
                </div>
                <div className="eventPreviewGroup">
                  {eventList.length >0 && eventList.map(c=> {
                    const startY = parseInt(c.startDate/10000)
                    const startM = parseInt(c.startDate/100)%100
                    const startD = parseInt(c.startDate)%100
                    const endY = parseInt(c.endDate/10000)
                    const endM = parseInt(c.endDate/100)%100
                    const endD = parseInt(c.endDate)%100
                    return(
                    <React.Fragment className="eventPreviewBx" key={c.createdAt}>
                      <p>※ 하단은 이미 등록되어 있는 이벤트( <span>{startY}.{startM}.{startD} ~ {endY}.{endM}.{endD}</span> ) 이미지 미리보기입니다. </p>
                      {/* <div style={{backgroundColor:`${c.bannerColor}`, width:"100%", height:"80px", border:"0.5px solid black",lineHeight:"80px", textAlign:"center", color:`${c.textColor}`}}>
                        {c.bannerText}
                      </div> */}
                      {/* 이벤트 이미지 확인 */}
                      <div className="eventLinkImageBx">
                        <p>Main Page Link Image</p>
                        <img src={c.iconUrl} alt="eventImg"/>
                      </div>
                      <div className="eventPcImageBx">
                        <p>Event Page Image(PC)</p>
                        <img src={c.imgUrl} alt="eventImg"/>
                      </div>
                      <div className="eventMobileImageBx">
                        <p>Event Page Image(Mobile)</p>
                        <img src={c.mobileImgUrl} alt="eventImg"/>
                      </div>
                    </React.Fragment>
                  )})
                  }
                </div>
            </div>
        )
    }
}

// export default compose(
//   withEmailVerification,
//   withAuthorization(condition),
// )(EventManagement);

export default compose(
  withFirebase,
  withRouter
)(EventManagement);
import React, { Component } from 'react'
import { withFirebase } from '../../../Firebase'
import { getTimeString } from '../../../helpers'
import ImageUploadExchange from '../../../Utils/ImageUploadExchange'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

class SalesContentInput extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this)
    }
    state = {
        pid:this.props.programList.pid,
        title:this.props.programList.title,
        target:this.props.programList.target,
        mainUrl:this.props.programList.mainUrl,
        pcUrl:this.props.programList.pcUrl,
        mobileUrl:this.props.programList.mobileUrl,
        exampleBookId:this.props.programList.exampleBookId,
        price:this.props.programList.price,
        printPrice:this.props.programList.printPrice,
        rentPrice:this.props.programList.rentPrice,
        eventPrice:this.props.programList.eventPrice || 0,
        // vrEventPrice: this.props.vrEventPrice,
        //  >0 ? this.props.vrEventPrice: this.props.programList.vrEventPrice,
        status:this.props.programList.status,
        rentStatus:this.props.programList.rentStatus,
        eventStatus:this.props.programList.eventStatus,
        content:this.props.programList.content ||'',
        digitalBookID:this.props.programList.digitalBookID ||'',

        digitalbookList:this.props.digitalbookList||[]
    }
    onStateChange = (e)=> {
        this.setState({
            [e.target.name] : e.target.value
        })
    }
    onPropsChange = (name, value) => {
        this.setState({
            [name] : value
        })
    }
    onSubmit = () => {
        // 관리자가 프로그램 신청
        const {getProgramList} = this.props
        const { mainUrl, pcUrl, mobileUrl, exampleBookId, status, rentStatus, eventStatus,
            title, target, pid, content, digitalBookID } = this.state;
        const createdAt = getTimeString()
        const site = 'tlp'
        const vrRent = false
        const published = 0
        const price = Number(this.state.price)
        const printPrice = Number(this.state.printPrice)
        const rentPrice = Number(this.state.rentPrice)
        const eventPrice  = Number(this.state.eventPrice)
        const programType= 'Online'
        const data = {
            mainUrl, pcUrl, mobileUrl, printPrice, rentPrice, eventPrice, exampleBookId, price, status, rentStatus, eventStatus,
            title, target, vrRent, published, programType, digitalBookID,
            site, createdAt, content }
            // console.log(data)
        this.props.firebase.sale(pid).set(data, {merge:true})
            .then(()=> alert('프로그램 정보가 수정되었습니다.'))
            .then(()=> getProgramList())
            .catch(err => alert('잠시 후 다시 시도해 주세요. \n에러 : '+err))
    }
    handleChange(content){
        // console.log(content); //Get Content Inside Editor
       if (content !== undefined){
           this.setState({content : content})
        }
    }
    
    render() {
        const { 
            mainUrl, pcUrl, mobileUrl, printPrice, rentPrice, price, status, rentStatus, eventStatus, exampleBookId,
            title, target, content, digitalBookID, digitalbookList, eventPrice } = this.state;
            // let { vrEventPrice} = this.props
            // console.log(this.state.digitalbookList)
        const inValid =
            title === '' ||
            target === '' ||
            pcUrl === '' ||
            mobileUrl === '' ||
            mainUrl ==='' ||
            printPrice==='' ||
            rentPrice==='' ||
            price ===''
        const formatterprintPrice = printPrice && printPrice.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        const formatterrentPrice = rentPrice && rentPrice.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        const formattereventPrice = eventPrice !==undefined && eventPrice >0 ? eventPrice.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') : 0
        const formatterprice = price && price.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        let {idx} = this.props
        // console.log(vrEventPrice)
        return (
            <React.Fragment>
                <div className="contentsInfoUpload">
                    <ul className="contentsInfoCate">
                        <li>프로그램 이름</li>
                        <li>프로그램 대상</li>
                        <li>디지털북</li>
                        <li>표시 여부</li>
                    </ul>
                    <ul className="contentsInfoInput">
                        <li>
                            <input type='text' name='title' value={title} placeholder='프로그램 이름을 입력해주세요' onChange={this.onStateChange}/>
                        </li>
                        <li>
                            <select name='target' value={target} onChange={this.onStateChange}>
                                <option value=''>대상 선택</option>
                                <option value='성인'>성인</option>
                                <option value='청소년'>청소년</option>
                                <option value='어린이'>어린이</option>                   
                                <option value='기타'>기타</option>
                            </select>
                        </li>
                        <li>
                            <select name='digitalBookID' value={digitalBookID} onChange={this.onStateChange}>
                                <option value=''>디지털북 없음</option>
                                {digitalbookList && digitalbookList.map(c=>{
                                    let { bid, bookTarget, bookTitle }  = c
                                    return(
                                        <option key={bid} value={bid}>{bookTarget ==='미입력' || bookTarget === undefined ? `` : `(${bookTarget}) `}{bookTitle ==='' ? `이름 없음` : bookTitle}
                                        </option>
                                    )
                                })}             
                            </select>
                        </li>
                        <li>
                            <input type='checkbox' name='status' checked={status} onChange={()=> this.setState({status : !status})}/>
                        </li>
                    </ul>
                </div>
                <div className="contentsInfoUpload">
                    <ul className="contentsInfoCate">
                        <li>프로그램 가격</li>
                        <li>책 인쇄 1부 당 가격 </li>
                        <li>
                            기기체험 가격/여부
                            </li>
                        <li>
                            {/* 기기구매 가격/여부 */}
                            </li>
                        <li>프로그램 맛보기</li>
                    </ul>
                    <ul className="contentsInfoInput">
                        <li>
                            <input type='text' name='price' value={formatterprice} placeholder='0(원)' onChange={e => this.setState({price : e.target.value.replace(/[^0-9]/g,"")})}/>
                        </li>
                        <li>
                            <input type='text' name='printPrice' value={formatterprintPrice} placeholder='0(원)' onChange={e => this.setState({printPrice : e.target.value.replace(/[^0-9]/g,"")})}/>
                        </li>
                        <li>
                            {formatterrentPrice}원
                            <input type='text' name='rentPrice' value={formatterrentPrice} placeholder='0(원)' onChange={e => this.setState({rentPrice : e.target.value.replace(/[^0-9]/g,"")})}/>
                            <input type='checkbox' name='rentStatus' checked={rentStatus} onChange={()=> this.setState({rentStatus : !rentStatus})}/>
                        </li>
                        <li>
                            {/* {formattereventPrice}원 */}
                            {/* <input type='text' name='eventPrice' value={formattereventPrice} placeholder='0(원)' onChange={e => this.setState({eventPrice : e.target.value.replace(/[^0-9]/g,"")})}/>
                            <input type='checkbox' name='eventStatus' checked={eventStatus} onChange={()=> this.setState({eventStatus : !eventStatus})}/> */}
                        </li>
                        <li>
                            <select name='exampleBookId' value={exampleBookId} onChange={this.onStateChange}>
                                <option value=''>맛보기 선택 안함(기본 제공)</option>
                                {digitalbookList && digitalbookList.map(c=>{
                                    let { bid, bookTarget, bookTitle }  = c
                                    return(
                                        <option key={bid} value={bid}>{bookTarget ==='미입력' || bookTarget === undefined ? `` : `(${bookTarget}) `}{bookTitle ==='' ? `이름 없음` : bookTitle}
                                        </option>
                                    )
                                })}             
                            </select>
                        </li>
                    </ul>
                </div>
                <div className="contentsImgUpload">
                    <ul className="contentsImgCate">
                        <li>메인 이미지</li>
                        <li>상세정보 이미지 (PC)</li>
                        <li>상세정보 이미지 (mobile)</li>
                    </ul>
                    <div className="contentsImgInput">
                        <div>
                            <ImageUploadExchange
                                onUpdate={this.onPropsChange}
                                itemName={'mainUrl'}
                                fileName={'self/'}
                                idx={idx}
                            /> 
                            <div>
                                <img src={mainUrl} alt="upload"/>
                            </div>
                        </div>
                        <div>
                            <ImageUploadExchange
                                onUpdate={this.onPropsChange}
                                itemName={'pcUrl'}
                                fileName={'self/'}
                                idx={idx}
                            /> 
                            <div>
                                <img src={pcUrl} alt="upload"/>
                            </div>
                        </div>
                        <div>
                            <ImageUploadExchange
                                onUpdate={this.onPropsChange}
                                itemName={'mobileUrl'}
                                fileName={'self/'}
                                idx={idx}
                            /> 
                            <div>
                                <img src={mobileUrl} alt="upload"/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div>
                <SunEditor onChange={this.handleChange} lang="ko"
                height = '300'
                setOptions={{
                    buttonList:[plugins]
                    resizingBar : false
                }}
                name="my-editor" 
                setContents={content} /> 
                </div> */}
                <div className="contentsUploadBtn">
                    <button disabled={inValid} onClick={this.onSubmit}>프로그램 등록</button>
                </div>
            </React.Fragment>
        )
    }
}
export default withFirebase(SalesContentInput);
import React, { Component } from 'react'
import { withFirebase } from '../../Firebase'
import {getTimeDayjs} from '../../helpers'
import $ from "jquery";

class GalleryItem extends Component {
    state = {
        isShowing : this.props.isShowing,
        chapter : this.props.chapter,
    }
    setShowing = (type, sharingPageId) => {
        let {chapter} = this.props
        if(chapter){
            if (type === 'showing'){
                let result = window.confirm('해당 이미지를 보이기 처리하시겠습니까?')
                if(result){
                    this.props.firebase.sharingPage(sharingPageId).set({isShowing : true},{merge:true});
                    this.setState({isShowing : true});
                }
            } else if( type === 'hidden') {
                let result = window.confirm('해당 이미지를 숨기기 처리하시겠습니까?')
                if(result){
                    this.props.firebase.sharingPage(sharingPageId).set({isShowing : false},{merge:true});
                    this.setState({isShowing : false});
                }
            }
        } else {
            if (type === 'showing'){
                let result = window.confirm('해당 이미지를 보이기 처리하시겠습니까?')
                if(result){
                    this.props.firebase.ARappActivity(sharingPageId).set({isShowing : true},{merge:true});
                    this.setState({isShowing : true});
                }
            } else if( type === 'hidden') {
                let result = window.confirm('해당 이미지를 숨기기 처리하시겠습니까?')
                if(result){
                    this.props.firebase.ARappActivity(sharingPageId).set({isShowing : false},{merge:true});
                    this.setState({isShowing : false});
                }
            }
        }
    }
    render() {
        let {isShowing} = this.state
        let {item, deleteGalleyItem, idx, modalOpen} = this.props
        let {sharingPageId, type, page, authUserName, createdAt
        } = item
     var isCreatedAtFormatted2 = getTimeDayjs(createdAt)
        return (
               <div className="appImageBx" style={isShowing ?{ backgroundColor:'orange'} : {backgroundColor:'#dedede'}}>
                    <ul className="appImgInfoBtn" onClick={()=>modalOpen(idx, true, type)}>
                        <li>
                            {authUserName}
                        </li>
                        <li>
                            {isCreatedAtFormatted2}
                        </li>
                    </ul>
                    <div className="appImgManageBtn">
                        {isShowing ? <span className="appImgHidden" onClick={()=>this.setShowing('hidden', sharingPageId)}>숨기기</span>
                        : <span className="appImgShow" onClick={()=>this.setShowing('showing', sharingPageId)}>보이기</span>}
                        <span className="appImgDel" onClick={()=>deleteGalleyItem(sharingPageId, idx)}>삭제</span>
                    </div>
                </div>
        )
    }
}
export default withFirebase(GalleryItem)
import React, { Component } from "react";
import ProgramConsulting from "./ProgramConsulting";
import "./index.css";
class ProgramListConsulting extends Component {
  static defaultProps = {
    consultingList: [
      {
        authUserId: "",
        code: "",
        codeAt: "",
        contents: "",
        createdAt: "",
        email: "",
        groupTitle: "",
        manager: "",
        memo: "",
        number: 0,
        page: "",
        participants: "",
        price: 0,
        programInfo: "",
        progress: "",
        progressAt: "",
        selectedDays: [],
        uid: "",
      },
    ],
    oneditList: () => console.warn("oneditList not defined"),
    onGetList: () => console.warn("onGetList not defined"),
  };
  state = {
    digitalbookList: [],
  };
  componentDidMount() {
    const { onGetList } = this.props;
    onGetList("consulting");
    this.getdigitalbookList();
  }
  getdigitalbookList = () => {
    this.props.firebase
      .libraries()
      .get()
      .then((result) => {
        let digitalbookList = [];
        result.forEach((doc) => {
          var bid = doc.id;
          var listData = doc.data();
          digitalbookList.push({ bid, ...listData });
        });
        this.setState({ digitalbookList });
      })
      .catch((err) => alert(`잠시 후 다시 시도 해주세요.\n에러 : ${err}`));
  };
  render() {
    const { programList, oneditList, onGetList } = this.props;
    let { digitalbookList } = this.state;
    // console.log(programList)
    const list =
      programList.length > 0
        ? programList.map((info) => (
            <ProgramConsulting
              key={info.uid}
              consultingList={info}
              onGetList={onGetList}
              oneditList={oneditList}
              digitalbookList={digitalbookList}
            />
          ))
        : "담당자가 배정된 프로그램 신청이 없습니다.";
    return (
      <React.Fragment>
        <p>프로그램 담당자 배정, 총 : {programList.length}건</p>
        <ul className="programListCate">
          <li>사용자명</li>
          <li>회사/학교명</li>
          <li>참여인원</li>
          <li>교육장소</li>
          <li>프로그램명</li>
          <li>진행 상황</li>
          <li>일자</li>
          <li>비고</li>
        </ul>
        {list}
      </React.Fragment>
    );
  }
}
export default ProgramListConsulting;

import React, { useState, useEffect } from 'react';
import DrawPreviewApp from '../Utils/DrawPreviewApp'
import SunEditor from 'suneditor-react';
import '../../../constants/suneditor.min.css'
// import 'suneditor/dist/css/suneditor.min.css';
import './pageItem.css'

const PagePriviewItem = props => {
    let {pageItem, handleChange} = props
    const [iframeHeight, setiframeHeight] = useState(window.innerWidth < 834 ? 2 : 5)
    // const size = useWindowSize();
    function useWindowSize() {
        const [windowSize, setWindowSize] = useState({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      
        useEffect(() => {
          function handleResize() {
            setWindowSize({
              width: window.innerWidth,
              height: window.innerHeight,
            });
            setiframeHeight(window.innerWidth < 834 ? 2 : 5)
          }
          window.addEventListener("resize", handleResize);
          handleResize();
          return () => window.removeEventListener("resize", handleResize);
        }, []); 
      
        return windowSize;
      }
    return (
        <React.Fragment>
            <div className="bookContentFrame">
                {pageItem !== undefined &&
                    <div  style={{  height:'100%', width:'100%',
                                    backgroundSize : 'contain, contain',
                                    backgroundRepeat : 'no-repeat, no-repeat',
                                    backgroundPosition : 'bottom, right bottom',
                                    backgroundImage: "url("+pageItem.pageBgImgUrl+")",
                                    backgroundColor : pageItem.pageBgImgColor}}>
                    {pageItem !== undefined &&
                        <div className="makingPaperFrame" style={{
                            height: '100%', width: '100%',
                            backgroundSize: 'contain, contain',
                            backgroundRepeat: 'no-repeat, no-repeat',
                            backgroundPosition: 'bottom, right bottom',
                            backgroundImage: "url(" + pageItem.pageBgImgUrl + ")",
                            backgroundColor: pageItem.pageBgImgColor }}>
                    {pageItem.userSection !== undefined && pageItem.userSection.map((val, idx) => {
                    let catId = `cat-${idx}`, ageId = `age-${idx}`
                    return (
                    <div key={idx}>
                        {val.type === 'inputText' ?
                            <div className="bookContFrame inputTextFrame">
                                <p id={ageId}>{val.textTitle}</p>
                                <input
                                    type="text"
                                    name={catId}
                                    data-id={idx}
                                    // id={catId}
                                    value={pageItem.userSection[idx].writingValue}
                                    className="writingValue"
                                    onChange={e=>handleChange(e)}
                                />
                            </div>
                            : val.type === 'textarea' ?
                                <div className="bookContFrame textareaFrame">
                                    <p id={ageId}>{val.textTitle}</p>
                                    <textarea
                                        type="text"
                                        name={catId}
                                        data-id={idx}
                                        style={{resize:'none', overflow:"hidden"}}
                                        rows={val.rowsLimit}
                                        placeholder="정해진 칸을 초과하여 작성된 부분은 인쇄시 내용이 잘릴 수 있습니다. "
                                        value={pageItem.userSection[idx].writingValue.replace(/<br\s*[\/]?>/gi, `\n`)}
                                        className="writingValue"
                                        // onKeyDown={e=>limitLines(e)}
                                        onChange={e=>handleChange(e)}
                                    /> <p className="lineLimit">{val.rowsLimit}줄 제한</p>
                                    </div> :
                                val.type === 'drawingTool' ?
                                    <div className="bookContFrame drawingToolFrame">
                                        <p id={ageId}>{val.textTitle}</p>
                                        <DrawPreviewApp contentHeight={val.contentHeight} bgColor={val.bgColor} />
                                        
                                    </div>
                                    : val.type === 'textEditor' ?
                                        <div className="bookContFrame textEditorFrame" style={{ backgroundColor: val.editorBgColor }}>
                                            <SunEditor lang="ko" height='auto' setOptions={{ 
                                                resizingBar: false,
                                                defaultStyle:'font-size:1.6rem',
                                             }}
                                                showToolbar={false} disable={true} setContents={val.textTitle} />
                                            
                                        </div>
                                        : val.type === 'image' ?
                                            <div className="bookContFrame imageFrame">
                                                <React.Fragment>
                                                    {val.srcUrl !== '' ?
                                                        <div style={{
                                                            width: '100%', display: 'flex', flexDirection: `${val.textDirection}`,
                                                            justifyContent: `${val.textJustify}`, alignItems: 'center'
                                                        }}>

                                                            <img src={val.srcUrl} alt={`image_${idx}`} width={`${val.contentWidth}%`} />
                                                            <span>{val.textarea && val.textarea.split('<br />').map((c, id) => {
                                                                return (
                                                                    <span key={id}>{c}<br /></span>
                                                                )
                                                            })}
                                                            </span>
                                                        </div>
                                                        : <p>이미지를 먼저 업로드 해주세요.</p>}
                                                </React.Fragment>
                                                
                                            </div>
                                            : val.type === 'video' ?
                                                <React.Fragment>
                                                    <div className="bookContFrame videoFrame" style={{ 
                                                        // border:'1px solid gray',
                                                        justifyContent: `${val.textJustify}`, height: `${val.contentWidth / iframeHeight}vw`
                                                    }}>
                                                        {val.srcUrl ?
                                                            <div style={{ width: `${val.contentWidth}%`, height:`${val.contentWidth}%` }}>
                                                                <iframe title='' src={val.srcUrl} style={{ width: `100%`, height: `100%`, border: 'none' }} allow="fullscreen"  allowFullScreen/>
                                                            </div>
                                                            : <p>영상 url을 먼저 입력 해주세요.</p>}
                                                        
                                                    </div>
                                                    <div className="videoGuideText">
                                                        ※ 원활한 영상 시청을 위해 페이지 로딩 후 잠시 기다린 다음 영상을 재생해 주세요. 
                                                    </div>
                                                </React.Fragment>
                                                : val.type === 'emptySpace' ?
                                                    <div className="bookContFrame emptySpaceFrame" >
                                                        <div></div>
                                                        
                                                    </div>
                                                    : <span>none</span>}
                                            </div>
                                        )
                                    })
                                }
                        </div>}
                    </div>}
            </div>
        </React.Fragment>
    );
};

export default PagePriviewItem;
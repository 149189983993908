import React, { Component } from 'react'
import { withFirebase } from '../../../Firebase'
import ProgressBar from 'react-bootstrap/ProgressBar'
import VideoUpload from '../VideoUpload'
import VimeoIframeEx from '../../../Utils/VimeoIframeEx'

class VideoSectiontlp extends Component {
    // Intro = 배경동영상1, 텍스트 3 브로셔 url, section
    // Class = 배경이미지1, (이미지2, 텍스트 2, 순서) * n, section
    // Self = 배경이미지1,  (이미지2, 텍스트 2, 순서) * n, section
    // XR BOOK = 배경동영상1,  (이미지2, 텍스트 2, 순서) * n, section
    // 갤러리 - (배경동영상-긴텍스트) *4, section
    // 고객센터 - { 카테고리(배송, 반품/교환, 취소, FAQ),
    //                  텍스트 1, 긴텍스트 1, show여부? }* n, section
    state={
            videoUrl:'',
            section : 'videotlp',
            site:'tlp',
            totalBytes : 0,
            bytesTransferred : 0,
            showIntroText:false,
    }
    componentDidMount(){
        this.getIntroInfo()
    }
    onPropsChange = (name, value) => {
        this.setState({
            [name] : value
        })
    }
    onStateChange = e => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }
    getIntroInfo = () => {
        this.props.firebase.mainSection('tlpVideo').get()
        .then(res => {
            const result = res.data()
            this.setState(result)
        })
        .catch(err=> {
            alert('데이터 불러오기를 실패했습니다. \n에러코드 : '+err)
            // window.location.reload()
        })
    }
    submitIntroData = () => {
        const {videoUrl, section, site} = this.state;
        const data = {videoUrl, section, site}
        this.props.firebase.mainSection('tlpVideo').set(data,{merge:true})
        .then(()=> {
            alert('비디오 정보가 수정되었습니다.')
            this.getIntroInfo()
            })
        .catch(err=> {
            alert('오류가 발생했습니다. 잠시 후 다시 시도해 주세요.\n에러코드 : '+err)
        })
        
    }
    render() {
        // console.log(this.state.showIntroText)
        const {videoUrl}= this.state
        return (
            <React.Fragment>
                <div className="manageTitle">
                    <h5>Video Section1</h5>
                    <button onClick={()=> this.submitIntroData()}>변경 사항 적용</button>
                </div>
                <div className="adminIntroManage">
                    <div className="videoUploadGroup"> 
                        <p>동영상 업로드</p>
                        <input type='text' name='videoUrl' value={videoUrl} onChange={this.onStateChange}/>
                    </div>
                    <p>※ 영상URL 위에 ?autoplay=1&loop=1&muted=1를 추가하면 음소거 상태로 자동재생됩니다.</p>
                        { videoUrl!=='' &&
                        <div className="videoPreviewGroup">
                            <VimeoIframeEx vimeoUrl={videoUrl}  vimeowidth='200px' vimeoheight='100px'/>
                        </div>}
                </div>
            </React.Fragment>
        )
    }
}
export default withFirebase(VideoSectiontlp)
import React, { Component } from 'react';
import {withFirebase} from '../../Firebase'

class AppUrlInput extends Component {
    state = {
        ar_android: '', ar_ios: '', vr_android: '', vr_ios: '',
    }
    componentDidMount(){
        this.getAppUrl()
    }
    getAppUrl =()=> {
        this.props.firebase.appUrl().get()
        .then(result=>{
                var listData= result.data();
                this.setState(listData)
            
        })
  }
  setAppUrl =()=> {
        let {ar_android, ar_ios, vr_android, vr_ios} = this.state
        let data = {
            ar_android, ar_ios, vr_android, vr_ios
        }
        this.props.firebase.appUrl().set(data, {merge:true})
            .then(()=> alert('Url이 저장되었습니다.'))
            .catch(err => alert('잠시 후 다시 시도해 주세요. \n에러 : '+err))
    }
onStateChange = (e) => {
 this.setState({
     [e.target.name] : e.target.value
 })
}
    render() {
        let {ar_android, ar_ios, vr_android, vr_ios} = this.state
        return (
            <div style={{border:'1px solid #dedede'}}>
                <h5>앱 다운로드 URL 업로드</h5>
                <div style={{display:'flex', flexDirection:'column', alignItems:'start'}}>
                    <span>
                    ar_android : <input type='text' name='ar_android' value={ar_android} placeholder='ar_android' onChange={(e)=>this.onStateChange(e)}/>
                    </span>
                    <span>
                    ar_ios : <input type='text' name='ar_ios' value={ar_ios} placeholder='ar_ios' onChange={(e)=>this.onStateChange(e)}/>
                    </span>
                    <span>
                    vr_android : <input type='text' name='vr_android' value={vr_android} placeholder='vr_android' onChange={(e)=>this.onStateChange(e)}/>
                    </span>
                    <span>
                    vr_ios : <input type='text' name='vr_ios' value={vr_ios} placeholder='vr_ios' onChange={(e)=>this.onStateChange(e)}/>
                    </span>
                </div>
                <div className="contentsUploadBtn">
                    <button onClick={()=>this.setAppUrl()}>등록</button>
                </div>
            </div>
        );
    }
}

export default withFirebase(AppUrlInput);
import React, { Component } from 'react'
import VideoUpload from './VideoUpload'
import ProgressBar from 'react-bootstrap/ProgressBar'

class SlideVideoItem extends Component {
    state={
        editing : false,
        bgVideoUrl:this.props.item.bgVideoUrl,
        text1:this.props.item.text1,
        text2:this.props.item.text2,
        item : this.props.item,
        totalBytes:0,
        bytesTransferred:0

    }
    onStatetoggle = name => {
        this.setState({
            [name] : !this.state.name
        })
    }
    onStateChange = (name, value) => {
        this.setState({
            [name] : value
        })
    }
    onSubmit = () => {
        const {bgVideoUrl, text1, text2} = this.state
        const {onEdit, idx} = this.props
        const data = {bgVideoUrl, text1, text2}
        this.setState({item : data, editing:false
        })
        onEdit(data, idx)
    }

    render() {
        const {bgVideoUrl, text1, text2, item, editing, totalBytes, bytesTransferred} = this.state
        const {onDelete, onEdit, idx} = this.props
        const progress = isNaN(bytesTransferred/totalBytes) ? 0 : parseInt((bytesTransferred/totalBytes) * 100)
        const formattedText = text1.replace(/(<br>|<br\/>|<br \/>)/g, '\n')
        return (
            <div  style={{display:'flex', flexDirection:'row'}}>
                            { !editing ?
                            <React.Fragment>
                            <div>배경영상
                            {bgVideoUrl !=='' &&
                                <video autoPlay="autoplay" loop="loop" muted width='400px' >
                                    <source src={bgVideoUrl} type="video/mp4" />
                                    Your browser does not support the video tag.
                                </video>}
                            </div>
                            <div style={{width : '400px'}}>
                                <div>{text1 && text1.split('<br />').map((c, id)=> {
                                        return(
                                        <span key={id}>{c}<br/></span>
                                    )})}</div>
                                <div>{text2}</div>
                            </div>
                            <button onClick={()=>this.onStatetoggle('editing')}>수정</button>
                            </React.Fragment>
                            :
                            <React.Fragment>
                            <div>배경영상
                            <div><ProgressBar now={progress} label={`${progress}%`} style={{width:'20vw'}}/></div>
                            <VideoUpload 
                                onUpdate={this.onStateChange}
                                itemName={'bgVideoUrl'}
                                fileName={'main/gallery'}
                                />
                            </div>
                            <div style={{width : '400px'}}>
                            <textarea name='text1' value={formattedText} 
                                onChange={e=> this.setState({text1: e.target.value.replace(/(?:\r\n|\r|\n)/g, '<br />')}) }/>
                                <input type = 'text' name='text2' value={text2} onChange={e=> this.onStateChange('text2', e.target.value )}/>
                            </div>
                            <button onClick={()=>this.onSubmit()}>저장</button>
                            </React.Fragment>
                            }
                            <button onClick={()=> onDelete(item)}>X</button>
            </div>
        )
    }
}
export default SlideVideoItem
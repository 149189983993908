import React, { Component } from 'react';
import {withFirebase} from '../../Firebase'
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { getTimeDayjs, getTimeString } from '../../helpers';
import vrCodeInput from '../../images/vrCodeInput.png'

class VrCodeInput extends Component {
    constructor(props) {
        super(props);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.state = this.getInitialState();
      }
      getInitialState() {
        return {
        selectedDay: undefined,
        vrtempList:'',
        vrCodeList : [],
        usedList:[],
        unUsedList:[],
        isunUsed:false,
        isunUsedList:[]
    }
}
componentDidMount(){
    this.getVrCode()
}
    getVrCode =()=> {
        this.props.firebase.oculusGoCodeLists().onSnapshot(snapshot=> {
            let usedList = []
            let unUsedList = []
            snapshot.forEach(doc =>{
              
              let result = doc.data()
              if (result.userUid === undefined ) {
                unUsedList.push({...result, expireAt : result.expiredAt === '999999999999' ? '만료 기한 없음' : getTimeDayjs(result.expiredAt)});
              } else {
                usedList.push(result);
              }
            });
      
            this.setState({
                unUsedList,
                usedList
            });
          });
  }
    showVrCode =()=> {
        let tempArr = this.state.vrtempList.replace(/ /gi,'\n').split('\n')
        let arr = []
        // console.log(tempArr)
        for(let i in tempArr){
          if(tempArr[i].trim() !== ''){
          arr.push(tempArr[i].trim())}
        }
        let vrCodeList = Array.from(new Set(arr))
        // console.log(vrCodeList)
        this.setState({vrCodeList})
      }
    inputVrCode=()=> {
        let vrCodeList = this.state.vrCodeList
         for(let i in vrCodeList){
            let data = {
                vrCode : vrCodeList[i],
                 expiredAt : this.state.selectedDay === undefined? '999999999999' : getTimeString(this.state.selectedDay),
                isCodeUsed :false
                }
                //  console.log(data)
        this.props.firebase.oculusGoCodeLists().where('vrCode','==',vrCodeList[i]).get()
        .then(doc=>{
            if(doc.size === 0){
                this.props.firebase.oculusGoCodeLists().add(data)
            } else {
                alert(`${vrCodeList[i]}가 중복된 코드 입니다.`)
            }
        })
         
         }
        this.setState({vrCodeList : [], vrtempList:''})
      }

      handleDayChange(day) {
        this.setState({ selectedDay: day });
      }
    sortVrCode = () => {
        this.setState({isunUsed:!this.state.isunUsed})
        if(!this.state.isunUsed){
        const {unUsedList} = this.state;
        let arrayLeng = unUsedList.length
        let startArray = [];
        let isFilter=[]
        let i = 0
        while(i<arrayLeng){
          isFilter.push(unUsedList[i].expireAt)
          i++
        }
        const filtered = isFilter.reduce((t,a) => {
            if (t[a]){ 
              t[a]++
             } else { 
               t[a] = 1 
              } 
              return t
            },{})
            for(var j in filtered){
            startArray.push([j, filtered[j]])}
            
            startArray.sort((a,b)=>{
              return b[1] - a[1]
            })
            this.setState({isunUsedList : startArray})
        }
        
    }
    render() {
        let { vrCodeList, vrtempList,  unUsedList, usedList, isunUsed, isunUsedList} = this.state
        let usedLeng = usedList.length
        let unUsedLeng = unUsedList.length
        return (
        <div>
          <h5>오큘러스 고 코드 업로드</h5>
          <div className="vrCodeInputGroup">
            <span>* 생성된 키는 창을 닫으면 사라지므로 꼭 미리 복사해두어야 합니다.</span>
            <span>* 복사한 키를 아래 칸에 그대로 복사해서 넣은 후 [리스트 보기]를 통해 확인하세요.</span>
            <span>* 지정한 만료기간이 없는 경우 선택하지 않고 저장하면 됩니다.</span>
            <div className="vrCodeInputGroupflex">
              <div>
                <p>1. <a href='https://developer.oculus.com/manage/organizations/1906720409543749/' target='_blank'>Oculus Dashboard</a> 접속</p>
                <p>2. 페이스북으로 로그인  ID : learnstory@thehrder.com, PW : Thehrder8*</p>
                <p>3. 왼쪽 상단 혹은 중앙에서 [어린 왕자, 나를 만나다] 선택 후</p>
                <p>4. Distribution - 키 선택 후</p>
                <p>5. 오른쪽 상단에서 키 발급 진행(만료일을 과거로 지정 시 만료일 없음으로 생성)</p>
                <img src={vrCodeInput} alt='vrCodeInput'/>
              </div>
              <div>
                <p>복사한 코드 리스트 :<br/> <textarea value={vrtempList} onChange={(e)=>this.setState({vrtempList : e.target.value})}/></p>
                <p>만료일 :<DayPickerInput onDayChange={this.handleDayChange} /></p>
                <button onClick={this.showVrCode}>리스트 보기</button>
              </div>
              <div className="vrCodeInputList">
                <p>리스트보기 {vrCodeList.length}개</p>
                {vrCodeList.length>0 && 
                <div className="vrCodeInputListScroll">
                {vrCodeList.map((c, id)=>{
                return(
                  <div className="vrCodeInputListone" key={id}>{c}</div>
                )
                })}
                <button onClick={this.inputVrCode}>저장</button>
                </div>
                }
              </div>
            </div>
            
          </div>
          <h5>오큘러스 고 코드 현황</h5>
          <div className="vrCodeInputGroup">
            <p>총 코드 개수 : {usedLeng+unUsedLeng}</p>
            <p>사용 코드 개수 : {usedLeng}</p>
            <p onClick={()=>this.sortVrCode()}>미사용 코드 개수 : {unUsedLeng}</p>
            {isunUsed&&
            <div>
                {isunUsedList.length>0 && isunUsedList.map((c,id)=>{
                    return(
                        <span key={id}>{c[0]}, {c[1]}개</span>
                    )
                })}
                </div>}
          </div>
        </div>
        );
    }
}

export default withFirebase(VrCodeInput);
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import {withFirebase} from '../../Firebase'
import * as ROUTES from '../../../constants/routes';
import '../../Digitalbook/InstituteAuth/InstituteAuth.css'
import logo from '../../images/XRBOOKLogo.png'
import scnuLogo from '../../images/순천대학교로고_가로형.png'
import { faSignInAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const TLPNavigationInstitute = () => {
    const [email, setemail] = useState('')
    const [username, setusername] = useState('')
    const [institute, setinstitute] = useState('')
    useEffect(() => {
        let instituteAuthReader = sessionStorage.getItem('instituteAuthReader')
        if(instituteAuthReader){
            let {email, username, institute} = JSON.parse(instituteAuthReader)
            setemail(email)
            setusername(username)
            setinstitute(institute)
        }
    }, [username])
    const logOut = () => {
        sessionStorage.removeItem('instituteAuthReader')
        setusername('')
    }
    return (
        <div className="intstituteLogoFrame">
            <div className="nonAuthLogoFrame">
                <div className="nonAuthLogo">
                <Link to={ROUTES.TLP_MAIN}>
                    <img src={logo} alt="logo" />
                    <span>어린 왕자, 나를 만나다 <br/>XR Reflection Writing Program</span>
                </Link>
                </div>
                <div className="nonAuthLogo nonAuthLogo2">
                    {username !== '' ?
                    <>
                        {/* <img src={scnuLogo} alt="scnuLogo" className="scnuLogo" /> */}
                        <span>{username}{institute !== '' && institute !== undefined ? `(${institute})` : ''}님</span>
                        <Link to={ROUTES.XRBOOK_INSTITUTEAUTH_LOGIN} className="intstituteLogoSignOut"
                        onClick={logOut}>
                            <FontAwesomeIcon icon={faSignInAlt}/>
                        </Link>
                    </>
                    : ''}
                </div>
            </div>
        </div>
    );
};

export default withFirebase(TLPNavigationInstitute);
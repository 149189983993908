import React, {useState, useEffect} from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../../Session';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import * as ROLES from '../../../constants/roles';
import CountLimit from './CountLimit'
import DateLimit from './DateLimit'
import {getTimeString} from '../../helpers'
// import './AdminContents.css'
import $ from "jquery";
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import './CreateCoupons.css'
dayjs.locale('ko')

let today = dayjs(new Date())
let todaySubtractMonth = today.add(-1, 'month').format('YYYY-MM-DD')
let todaySubtractMonthString = getTimeString(`${todaySubtractMonth}T00:00:00`)
//.where('createdAt','>=', todaySubtractMonthString)

const CreateCouponsPage = props => {
  const [one, onOne] = useState('contents_show');
  const [two, onTwo] = useState('contents_hidden');
  const [three, onThree] = useState('contents_hidden');
  const [show, onShow] = useState('CountLimit');
  function First() {
    onShow('DateLimit');
    onOne('contents_show');
    onTwo('contents_hidden');
    onThree('contents_hidden');
    $(".adminContentsFrame>div>.dataTabBtnGroup div").css({"border":"1px solid #dedede", "color":"#aaaaaa"});
    $(".adminContentsFrame>div>.dataTabBtnGroup div").eq(1).css({"border-bottom":0,"color":"#000000" });    
  }
  function Second() {
    onShow('CountLimit');
    onOne('contents_hidden');
    onTwo('contents_show');
    onThree('contents_hidden');
    // $(".adminContentsFrame>div>.dataTabBtnGroup div").css({"border":"1px solid #dedede", "color":"#aaaaaa"});
    // $(".adminContentsFrame>div>.dataTabBtnGroup div").eq(2).css({"border-bottom":0,"color":"#000000" });
  }
  function Third() {
    onShow('CouponList');
    onOne('contents_hidden');
    onTwo('contents_hidden');
    onThree('contents_show');
    $(".adminContentsFrame>div>.dataTabBtnGroup div").css({"border":"1px solid #dedede", "color":"#aaaaaa"});
    $(".adminContentsFrame>div>.dataTabBtnGroup div").eq(0).css({"border-bottom":0,"color":"#000000" });
  }
  function deleteCode (c) {
    let isDelete = window.confirm('선택한 쿠폰을 삭제하시겠습니까?')
    if(isDelete){  
    props.firebase.promotionCode(c.uid).delete()
    // .then(()=>{
    //     let idx = codeArr.indexOf(c)
    //     let newArr = codeArr.slice()
    //     newArr.splice(idx, 1)
    //     setcodeArr(newArr)
    // })
    .catch(err => alert('잠시 후 다시 시도해 주세요.\n에러 코드 : '+err))
}
}
  const [codeArr, setcodeArr] = useState([])
  useEffect(() => {
    props.firebase.promotionCodes().where('site','==','tlp').onSnapshot(
        {includeMetadataChanges:true},
        result => {
            let newList = []
            result.forEach((doc) => {
                var uid = doc.id
                var listData= doc.data()                  
                newList.push({uid, ...listData})
            })
            newList = newList.sort((a,b) => b.createdAt -a.createdAt)
            setcodeArr(newList)
          }
    )
    // .catch(()=> window.location.reload())
}, [])
let today = dayjs(new Date())
let todaySubtractMonth = today.add(-1, 'month').format('YYYY-MM-DD')
let todaySubtractMonthString = getTimeString(`${todaySubtractMonth}T00:00:00`)
let formattedtoday = parseInt(today.format('YYMMDD'))
        function TempMsg (type, randomCode) {
            return () => {
            switch (type) {
                case 'copiedCP':
                NotificationManager.info(`쿠폰 코드(${randomCode})가 복사되었습니다.`, 'COPIED', 1500, () => {
                    alert('callback');
                });
                break;
            }
            };
        };
  return (
    <div className="adminContentsFrame">
        <h3>결제 쿠폰 관리</h3>
        <div>
          {/* 탭 부분 */}
          {/* <div className="dataTabBtnGroup"> */}
            {/* <div className={`${three}`} onClick={()=>Third()}>발행된 쿠폰 리스트(전체)</div>
            <div className={`${one}`} onClick={()=>First()}>기간 제한 일회성 쿠폰</div> */}
            {/* <div className={`${two}`} onClick={()=>Second()}>횟수 제한 다회성 쿠폰</div> */}
          {/* </div> */}
          {/* 탭에서 선택된 내용 부분 */}
          <div className="dataTabContGroup" style={{border:"none"}}>
            {/* {show === 'DateLimit' ? <DateLimit codeArr={codeArr.filter(c=>c.type==='dateLimit')} deleteCode={(c)=>deleteCode(c)}/>
            : show === 'CountLimit' ?  */}
            <CountLimit codeArr={codeArr.filter(c=>c.type==='countLimit')} deleteCode={(c)=>deleteCode(c)}/>
            {/* : show === 'CouponList' ? 
            <div>
              <div className="madeCouponGroup">
                <span>(최신순)</span>
                <table border='1'>
                    <thead>
                        <tr>
                            <th>쿠폰 이름</th>
                            <th>쿠폰 사용 조건</th>
                            <th>할인 금액/비율</th>
                            <th>만료일/제한 횟수</th>
                            <th>쿠폰 코드</th>
                            <th>발행일</th>
                            <th>쿠폰 상태/남은 횟수</th>
                            <th>비고</th>
                        </tr>
                    </thead>
                    <tbody>
                        {codeArr && codeArr.map(c=> {
                            let {discountType, createdAt, expireDate, isUsed, randomCode, type, ResCount } = c
                            const discounttext = discountType === 'anyCondition' ? '무조건' 
                            : discountType === 'onlyPublished' ? '책 인쇄'
                            : discountType === 'onlyVrRent' ? '기기대여'
                            : discountType === 'onlyVrEvent' ? '기기구매'
                            : discountType === 'minPrice' ? '최소 금액' : ''
                            const conditionType = discountType === 'anyCondition' ? '' 
                            : discountType === 'onlyPublished' ? '권 이상'
                            : discountType === 'onlyVrRent' ? ''
                            : discountType === 'onlyVrEvent' ? ''
                            : discountType === 'minPrice' ? '원 이상' : ''
                            
                            let createdY = createdAt.substr(0,2)
                            let createdM = createdAt.substr(2,2)
                            let createdD = createdAt.substr(4,2)
                            let createdDay = dayjs('20'+createdY+'-'+createdM+'-'+createdD).format('ddd')

                            let expiredY = expireDate ? expireDate.substr(0,2) : '00'
                            let expiredM = expireDate ? expireDate.substr(2,2) : '00'
                            let expiredD = expireDate ? expireDate.substr(4,2) : '00'
                            let expiredDay = dayjs('20'+expiredY+'-'+expiredM+'-'+expiredD).format('ddd')
                            let expired = parseInt(`${expiredY}${expiredM}${expiredD}`)
                            let isexpired = expired-formattedtoday
                            const condition = isUsed !=='' ? '사용 완료'
                            : isUsed === '' && isexpired >=0 ? '미사용'
                            : isUsed === '' && isexpired <0 ? '유효기간 만료' : null
                            // const discountedAmount = c.discountAmount
                            return(
                            <tr key={randomCode}>
                                <td>{c.codeName || '미입력'}</td>
                                <td>{discounttext} {c.discountCondition || ''} {conditionType}</td>
                                <td>{c.discountAmount ? `${c.discountAmount.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}원`
                                : `${c.discountRatio}%`}</td>
                                <td>{type === 'dateLimit' ? `20${expiredY}-${expiredM}-${expiredD} (${expiredDay})` :
                                     type === 'countLimit' ? `${c.limitCount} 회`: '관리자 문의' }</td>
                                <td>
                                    <CopyToClipboard text={randomCode}
                                    onCopy={TempMsg('copiedCP', randomCode)}>
                                        <span>{randomCode}</span>
                                    </CopyToClipboard>
                                </td>
                                <td>20{createdY}-{createdM}-{createdD} ({createdDay})</td>
                                <td>{type === 'dateLimit' ?  `${condition}`
                                     : type === 'countLimit' ? `${ResCount}회` : '관리자 문의'}</td>
                                <td><button onClick={()=>deleteCode(c)}>삭제</button></td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div> */}
            <NotificationContainer/>
            </div> 
            {/* :
            null}
          </div> */}
        </div>
    </div>

  )};
  
  const condition = authUser =>
  authUser && authUser.roles[ROLES.MEMBER] === ROLES.ADMIN;
  
  export default compose(
    withEmailVerification,
    withAuthorization(condition),
  )(CreateCouponsPage);
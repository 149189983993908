import React, { Component } from 'react'
import { withFirebase } from '../../../Firebase'
import { getTimeString } from '../../../helpers'
import ImageUploadExchange from '../../../Utils/ImageUploadExchange'
import SunEditor from 'suneditor-react';
import 'suneditor/dist/css/suneditor.min.css';

class ProgramContentInput extends Component {
    constructor(props) {
        super(props);
        this.handleChange = this.handleChange.bind(this)
        this.handleImageUploadBefore = this.handleImageUploadBefore.bind(this)
    }
    state = {
        pid:this.props.programList.pid,
        title:this.props.programList.title,
        target:this.props.programList.target,
        progressTime:this.props.programList.progressTime,
        mainUrl:this.props.programList.mainUrl,
        pcUrl:this.props.programList.pcUrl,
        mobileUrl:this.props.programList.mobileUrl,
        status:this.props.programList.status,
        content:this.props.programList.content ||'',
        digitalBookID:this.props.programList.digitalBookID ||'',

        digitalbookList:this.props.digitalbookList||[],

        imageAsFile:'',
        imgUrl:''
    }
    onStateChange = (e)=> {
        this.setState({
            [e.target.name] : e.target.value
        })
    }
    onPropsChange = (name, value) => {
        this.setState({
            [name] : value
        })
    }
    onSubmit = () => {
        // 관리자가 프로그램 신청
        const { 
            mainUrl, pcUrl, mobileUrl,
            title, target, progressTime, pid, status, content, digitalBookID } = this.state;
        const createdAt = getTimeString()
        const site = 'tlp'
        const programType= 'classroom'
        const data = {
            mainUrl, pcUrl, mobileUrl, programType,
            title, target, progressTime, digitalBookID,
            site, createdAt, status, content }
            // console.log(data)
        this.props.firebase.program(pid).set(data, {merge:true})
            .then(()=> alert('프로그램이 추가되었습니다.'))
            // .then(()=> window.location.reload())
            .catch(err => alert('잠시 후 다시 시도해 주세요. \n에러 : '+err))
    }
    handleChange(content){
        // console.log(content); //Get Content Inside Editor
       if (content !== undefined){
           this.setState({content : content})
        }
    }
    handleImageUploadBefore(files, info, core, uploadHandler){
        // console.log(files, info)
        const imageAsFile = files[0]
        const timeString = getTimeString()
        const storage = this.props.firebase.storage
        const fileName = imageAsFile.name
        const fileSize = imageAsFile.size
        const fileTypes = fileName.split('.')
        const typeLeng = fileTypes.length
        const fileType = fileTypes[typeLeng-1].toLowerCase()
        const uploadTask = storage.ref(`/tlp/program/${timeString}_${fileName}`).put(imageAsFile)
        uploadTask.on('state_changed', 
        (snapShot) => {
        }, (err) => {
            //catches the errors
            console.log(err)
        }, () => {
            storage.ref(`tlp/program`).child(`${timeString}_${fileName}`).getDownloadURL()
            .then(fireBaseUrl => {
                this.setState({
                    imgUrl: fireBaseUrl
                })
                const response = {
                    // "errorMessage": "insert error message",
                    "result": [ { "url":`${fireBaseUrl}`, "name": `${fileName}`, "size": `${fileSize}` }, ]
                }
                return(response)
                // uploadHandler(files)
                // core.plugins.image.imageUploadUrl(response);
            })
            .catch(err=>console.log('에러 ='+err))
        })
        
    }
    handleImageUpload(targetImgElement, index, state, imageInfo, remainingFilesCount){
        // console.log(targetImgElement, index, state, imageInfo, remainingFilesCount)
        
    }
    render() {
        const { 
            mainUrl, pcUrl, mobileUrl,
            title, target, progressTime, status, content, imgUrl, digitalBookID } = this.state;
        let {digitalbookList, idx} = this.props;
            const inValid =
            title === '' ||
            target === '' ||
            pcUrl === '' ||
            mobileUrl === '' ||
            mainUrl ==='';
        const buttonLists= [
                /** command */
                'blockquote', 
                /** Submenu */
                'align', 'font', 'fontColor', 'fontSize', 'formatBlock', 'hiliteColor',
                'horizontalRule', 'lineHeight',
                'list', 'paragraphStyle', 'table', 'textStyle',
                /** Dialog */
                'image', 'link', 'video', 'audio'
            ]
        const buttonList = [
            ['font', 'fontSize', 'formatBlock'],
          ['paragraphStyle'],
          ['undo', 'redo'],
          ['bold', 'underline', 'italic', 'strike', 'subscript', 'superscript'],
          ['fontColor', 'hiliteColor', 'textStyle'],
          ['outdent', 'indent'],
          ['align', 'horizontalRule', 'list', 'lineHeight'],
          ['table', 'image'],
          ['fullScreen', 'showBlocks', 'codeView']
        ]
        return (
            <React.Fragment>
                <div className="contentsInfoUpload">
                    <ul className="contentsInfoCate">
                        <li>프로그램 이름</li>
                        <li>프로그램 대상</li>
                        <li>디지털북</li>
                        <li>러닝타임</li>
                        <li>표시 여부</li>
                    </ul>
                    <ul className="contentsInfoInput">
                        <li>
                            <input type='text' name='title' value={title} placeholder='프로그램 이름을 입력해주세요' onChange={this.onStateChange}/>
                        </li>
                        <li>
                            <select name='target' value={target} onChange={this.onStateChange}>
                                <option value=''>대상 선택</option>
                                <option value='성인'>성인</option>
                                <option value='청소년'>청소년</option>
                                <option value='어린이'>어린이</option>                   
                                <option value='기타'>기타</option>
                            </select>
                        </li>
                        <li>
                            <select name='digitalBookID' value={digitalBookID} onChange={this.onStateChange}>
                                <option value=''>디지털북 없음</option>
                                {digitalbookList && digitalbookList.map(c=>{
                                    let { bid, bookTarget, bookTitle }  = c
                                    return(
                                        <option key={bid} value={bid}>{bookTarget ==='미입력' || bookTarget === undefined ? `` : `(${bookTarget}) `}{bookTitle ==='' ? `이름 없음` : bookTitle}</option>
                                    )
                                })}                    
                            </select>
                        </li>
                        <li>
                            <input type='text' name='progressTime' value={progressTime}  placeholder='0(시간)'
                                onChange={e => this.setState({progressTime : e.target.value.replace(/[^0-9]/g,"")})}/>
                        </li>
                        <li>
                            <input type='checkbox' name='status' checked={status} onChange={()=> this.setState({status : !status})}/>
                        </li>
                    </ul>
                </div>
                <div className="contentsImgUpload">
                    <ul className="contentsImgCate">
                        <li>메인 이미지</li>
                        <li>상세정보 이미지 (PC)</li>
                        <li>상세정보 이미지 (mobile)</li>
                    </ul>
                    <div className="contentsImgInput">
                        <div>
                            <ImageUploadExchange
                                onUpdate={this.onPropsChange}
                                itemName={'mainUrl'}
                                idx={idx}
                                fileName={'program/'}
                            /> 
                            <div>
                                <img src={mainUrl} alt="upload"/>
                            </div>
                        </div>
                        <div>
                            <ImageUploadExchange
                                onUpdate={this.onPropsChange}
                                itemName={'pcUrl'}
                                idx={idx}
                                fileName={'program/'}
                            />
                            <div>
                                <img src={pcUrl} alt="upload"/>
                            </div>
                        </div>
                        <div>
                            <ImageUploadExchange
                                onUpdate={this.onPropsChange}
                                itemName={'mobileUrl'}
                                idx={idx}
                                fileName={'program/'}
                            /> 
                            <div>
                                <img src={mobileUrl} alt="upload"/>
                            </div>
                        </div>
                    </div>
                </div>
                {/* <div>
                <SunEditor onChange={this.handleChange} lang="ko"
                onImageUploadBefore={this.handleImageUploadBefore}
                height = '300'
                setOptions={{
                    // plugins,
                    buttonList:[buttonLists]
                    resizingBar : false
                }}
                name="my-editor" 
                setContents={content} /> 
                </div> */}
                <div className="contentsUploadBtn">
                    <button disabled={inValid} onClick={this.onSubmit}>프로그램 등록</button>
                </div>
            </React.Fragment>
        )
    }
}
export default withFirebase(ProgramContentInput);
import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import * as ROUTES from '../../../constants/routes';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../../Session';
import { withFirebase } from '../../Firebase'
import Digitalbook_PC from './Digitalbook_PC.jsx'
import Digitalbook_Mobile from './Digitalbook_Mobile.jsx'
import XRBOOKLogo from '../images/XRBOOKLogo.png'
// import dBookImagePc1 from '../../images/dBookImagePc1.png'
// import dBookImagePc2 from '../../images/dBookImagePc2.png'
// import dBookImagePc3 from '../../images/dBookImagePc3.png'
// import dBookImagePc4 from '../../images/dBookImagePc4.png'
// import dBookImageTaMo1 from '../../images/dBookImageTaMo1.png'
// import dBookImageTaMo2 from '../../images/dBookImageTaMo2.png'
// import dBookImageTaMo3 from '../../images/dBookImageTaMo3.png'
// import dBookImageTaMo4 from '../../images/dBookImageTaMo4.png'
import { getTimeString } from '../../helpers';
import Manual from '../../Guide/Manual'
import '../AuthReader/AuthReader.css'
import '../AuthReader/AuthReaderResp.css'
import $ from "jquery";
import { faInfo } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import Tutorial from '../../Utils/Tutorial';

class NonAuthReader extends Component {
    state={
        bookData : 
        // this.props.location.state !== undefined ? this.props.location.state.bookData :
        {},
        seenPageId : this.props.location.state !== undefined ? this.props.location.state.seenPageId :0,
        lastPageId : 0,
        // bookFinishAt  : this.props.location.state.bookFinishAt !== undefined ? this.props.location.state.bookFinishAt :'0',
        // published  : this.props.location.state.published !== undefined ? this.props.location.state.published :0,
        review  : this.props.location.state !== undefined ? this.props.location.state.review :false,
        bookTitle  : this.props.location.state !== undefined ? this.props.location.state.bookTitle :'',
        
        windowWidth: window.innerWidth,
        pageId:0,
        pcPageId:0,
        nonAuthUser : JSON.parse(sessionStorage.getItem('nonAuthReader')),
        titlebyAuth: this.props.location.state  !== undefined ? this.props.location.state.title : '',
        programTitle: this.props.location.state  !== undefined ? this.props.location.state.programTitle : '',
        // mybookId : this.props.location.state.mybookId,
        mybookId : this.props.location.state !== undefined ? this.props.location.state.mybookId :'',
        isChanged : false,
        publishedCover:{}
    }
    componentDidMount(){
        this.onGetDigitalbook()
        window.addEventListener('resize', this.updateDimensions);
        
        if(this.props.location.state  === undefined) {
            alert('유효하지 않은 접근입니다.')
            this.setState(() => this.props.history.push(ROUTES.TLP_MAIN))   
        } 
        // else if(this.props.location.state.bookData  === undefined){
        //     alert('북 데이터가 존재하지 않습니다.')
        //     this.setState({bookData : {}}, () => this.props.history.push(ROUTES.XRBOOK_NONAUTH))   
        // }         
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }
    updateDimensions = () => {
        this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
      };
    onUnload (e) {
        e.preventDefault();
        let confirmationMessage = '정말 닫으시겠습니까?'
            e.returnValue = confirmationMessage    // Gecko, Trident, Chrome 34+
            return confirmationMessage    // Gecko, WebKit, Chrome < 34
    }
    componentDidUpdate(prevProps, prevState){
        let {pcPageId, pageId} = this.state
        if(this.state.nonAuthUser['email'] !== this.props.location.state.nonAuthUser['email']){
            alert('유효하지 않은 사용자입니다.')
            this.setState(() => this.props.history.push(ROUTES.XRBOOK_NONAUTH))
        }
        if(prevState.pageId !== this.state.pageId){
            this.setState({
                seenPageId: this.state.seenPageId < pageId ?  pageId : this.state.seenPageId
            })
        }
        if(prevState.lastPageId !== this.state.lastPageId && this.state.pageId!==0){
            let {mybookId} = this.state
            this.props.firebase.nonAuthReader(mybookId).update({
                lastPageId : this.state.pageId
            })
        }
        if(prevState.seenPageId < this.state.seenPageId){
            let {mybookId, pageList, seenPageId} = this.state
            this.props.firebase.nonAuthReader(mybookId).update({
                seenPageId
            })
            // .then(()=>alert('저장되었습니다.'))
            // .catch((err)=>console.log(err))
        }
    }
    onPageListChange = (name, value) => {
        if(name === 'pageList'){
            this.setState(prevState => ({
                bookData: {
                    ...prevState.bookData,
                    pageList : value
                },
            }))
            
        } else if (name === "pageIdPC"){
            this.setState(prevState => ({
                // pcPageId: prevState.pcPageId+value,
                pageId:prevState.pageId+(value*2)
            }))
        } else if (name === "pageIdMobile"){
            this.setState(prevState => ({
                pageId: prevState.pageId+value,
                // pcPageId:parseInt((prevState.pageId+value)/2)
            }))
            // console.log(value)
        } else if (name === "pageId"){
            this.setState({
                pageId: value
            })
        } else {
            this.setState({
                [name]:value
            })
        }
        }
    onGetDigitalbook = () => {
        let mybookId = this.props.location.state !== undefined ? this.props.location.state.mybookId : ''
        if(mybookId !==''){
            this.props.firebase.nonAuthReader(mybookId).get()
            .then((res)=>{
                let result = res.data()
                this.setState(result)
                // let {
                //     lastPageId,
                //     seenPageId,
                //     bookData,
                //     review,
                //     target,
                //     digitalBookID,
                //     isTutorial,
                //     programId,
                //     isFinished,
                //     publishedCover,
                //     username,
                //     isFinishedAt,
                //     programTitle
                // } = result
                // this.setState({
                //     lastPageId,
                //     seenPageId,
                //     bookTitle : bookData.bookTitle,
                //     bookData,
                //     review,
                //     target,
                //     digitalBookID,
                //     programId,
                //     isTutorial,
                //     publishedCover,
                //     isFinishedAt,
                //     programTitle
                // })
                let {isFinished, review, publishedCover, isTutorial, lastPageId, bookData,
                    digitalBookID, username, target, programId, programTitle} = result
                if(isFinished){
                    if(!review){
                        this.props.history.push({
                            pathname: ROUTES.XRBOOK_NONAUTH_REVIEW,
                            state: 
                            {
                                bookTitle: bookData.bookTitle,
                                mybookId: mybookId,
                                digitalBookID: digitalBookID,
                                username : username,
                                target : target,
                                programId : programId,
                                bookData: bookData,
                                programTitle
                            }
                          })
                    }else if(!publishedCover){
                        this.props.history.push({
                            pathname: ROUTES.XRBOOK_NONAUTH_COVER,
                            state: 
                            {
                                bookTitle:bookData.bookTitle,
                                mybookId:mybookId,
                                username :username,
                                digitalBookID:digitalBookID,
                                target :target,
                                programId :programId,
                                bookData:bookData,
                                programTitle
                            }
                          })
                    }
                } else
                if(isTutorial !== false){
                    $(".bookGuideBg").show();
                    $(".bookGuideBtn2").hide()
                } 
                else if(lastPageId !== 0 ){
                    let result = window.confirm('마지막으로 작성한 페이지로 이동하시겠습니까?')
                    // console.log(this.state)
                    let lastPage = lastPageId%2 === 0 ? lastPageId : lastPageId+1
                    if(result) {
                        this.setState({lastPage})
                        this.onPageListChange('pageId', this.state.lastPage)
                    }}
            })
            .catch((err)=>console.log(err))
            }
        }
    onSaveDigitalbook = () => {
        let {mybookId, bookData} = this.state
        // console.log(this.state.bookData)
        this.props.firebase.nonAuthReader(mybookId).update({bookData})
        .then(()=>alert('저장되었습니다.'))
        .catch((err)=>console.log(err))

    }
    
    onFinishDigitalbook = () => {
        let {mybookId, bookData, nonAuthUser, isFinishedAt} = this.state
        let sharingList = []
        let createdAt = getTimeString()
        let type = 'digitalbook'
        let authUserEmail = nonAuthUser['email']
        let authUserName = nonAuthUser['username']
        let authUserUid = 'nonAuth'
        for(let i in bookData.pageList){
            if(bookData.pageList[i].isShared && bookData.pageList[i].shareMark){
                //챕터 구별 수정 예정
                let chapter = bookData.pageList[i].chapter || 0
                let chapterKey = `Totalchapter${chapter}`
                let isShowing = true
                this.props.firebase.sharingPages().add({pageId : i, page: bookData.pageList[i], createdAt, type,
                    mybookId, authUserEmail, authUserName, authUserUid, isShowing })
                    this.props.firebase.tlpStatistics().update({
                        [chapterKey] : this.props.firebase.fieldValue.increment(1)
                    })
             
            }
        } 
        this.props.firebase.nonAuthReader(mybookId).update({bookData, isFinished : true})
        if(!isFinishedAt){
        this.props.firebase.nonAuthReader(mybookId).set({
           isFinishedAt : getTimeString()
        },{merge:true})
    }
    }
    onBookGuide = (type) => {
        let {mybookId} = this.state
        if(type ==='never'){
            this.props.firebase.nonAuthReader(mybookId).set({isTutorial : false},{merge:true})
            .then(()=> $(".bookGuideBg").hide())
        } else if (type === 'again'){
            $(".bookGuideBg").hide();
        } else if (type === 'show'){
            $(".bookGuideBg").show();
            $(".bookGuideBtn1").hide();
            $(".bookGuideBtn2").show();
            
        }
        
    }
    onManualGuide = (type) => {
        if(type ==='hidden'){
            $(".manualBg").hide();
        } else if (type === 'show'){
            $(".manualBg").show();
            
        }
    }
    // setBookGuideSlidePrev = () => {
    //     var lastChild = $(".bookGuideFrame>ul>li").last();
    //     $(".bookGuideFrame>ul").stop().animate({"margin-left":"0"}, 500, function(){
    //         $(".bookGuideFrame>ul").prepend(lastChild).css("margin-left", "-100%");
    //     })
    // }
    // setBookGuideSlideNext = () => {
    //     var firstChild = $(".bookGuideFrame>ul>li").eq(0);
    //     $(".bookGuideFrame>ul").stop().animate({"margin-left":"-200%"}, 500, function(){
    //         $(".bookGuideFrame>ul").append(firstChild).css("margin-left", "-100%");
    //     })
    // }
    saveAlert = (e, type) => {
        e.preventDefault()
        let result = window.confirm('디지털북 작성 중인 경우 저장하지 않고 페이지를 이동할 경우 작성하신 내용이 저장되지 않습니다.')
        if(result && type==='user'){
            this.props.history.push({pathname: `${ROUTES.XRBOOK_NONAUTH}`})
        } else if (result && type==='main'){
            this.props.history.push({pathname: `${ROUTES.TLP_MAIN}`})
        } else{

        }
    }
    render() {
        let {nonAuthUser, bookData, titlebyAuth, pageId,pcPageId, mybookId, seenPageId, lastPageId, publishedCover,
             review, published, bookTitle, target, site, programId, digitalBookID, userAddress, windowWidth, programTitle} = this.state
        let pageLeng = bookData.pageList ? bookData.pageList.length : 0
        // console.log(this.state)
        return (
            <div className="authReaderFrame">
                <div className="bookGuideBg">
                <Tutorial onBookGuide={(data)=>this.onBookGuide(data)}/>
                </div>
               
                <div className="manualBg" style={{display:'none'}}>
                    <div className="manualDelBtn" onClick={()=>this.onManualGuide('hidden')}>×</div>
                    <Manual headShow={false}/>
                </div>


                <div className="bookTop">
                    <div className="booInfoGroup">
                        <div className="iconFrame">
                            <img src={bookData.iconUrl || XRBOOKLogo } alt='icon'/>
                        </div>
                        <span>{titlebyAuth}</span>
                    </div>
                    <div className="linkGroup">
                        <div className="delinkBtnPc" onClick={()=>this.onManualGuide('show')}>[ 기기 사용법 ]</div>
                        <div className="infoLinkBtnMobile" onClick={()=>this.onBookGuide('show')}><FontAwesomeIcon icon={faInfo}/></div>
                        <div className="infoLinkBtnPc" onClick={()=>this.onBookGuide('show')}>[ 도움말 ]</div>
                        <span onClick={(e)=>this.saveAlert(e,'user')} >MY PAGE</span> 
                        <span onClick={(e)=>this.saveAlert(e,'main')}>HOME</span>
                    </div>
                </div>
                <div className="bookMiddle">
                    <p>
                        XR BOOK 작성 중
                    </p>
                </div>
                {/* <h3>pageId : {pageId}</h3>
                <h3>pcPageId : {pcPageId}</h3>
                <h3>seenPageId : {seenPageId}</h3>
                <h3>lastPageId : {lastPageId}</h3>
                <h3>pageLeng : {pageLeng}</h3>
                <h3>windowWidth : {windowWidth}</h3> */}
                
                {windowWidth >834 ? 
                <Digitalbook_PC
                 digitalbook={bookData} onPageListChange={this.onPageListChange}
                 pageId={pageId} pcPageId={pcPageId} mybookId={mybookId} seenPageId={seenPageId}
                 lastPageId={lastPageId} windowWidth={windowWidth}
                 onSaveDigitalbook={this.onSaveDigitalbook} onBookGuide={(data)=>this.onBookGuide(data)}/>
                :               
                <Digitalbook_Mobile
                 digitalbook={bookData} onPageListChange={this.onPageListChange}
                 pageId={pageId} mybookId={mybookId} seenPageId={seenPageId} lastPageId={lastPageId}
                 onSaveDigitalbook={this.onSaveDigitalbook} windowWidth={windowWidth}
                 onBookGuide={(data)=>this.onBookGuide(data)}
                 />
                 }
                    {/* <br/><br/>
                
                <h3>windowWidth : {windowWidth}</h3> */}
                <div className="bookBottom">
                    <button onClick={()=>this.onSaveDigitalbook()}>저장하기</button>
                    {seenPageId >= pageLeng || pageId >= pageLeng ? 
                    !review ?
                        <Link to={{pathname: `${ROUTES.XRBOOK_NONAUTH_REVIEW}`,
                        state: {
                            bookTitle:bookTitle,
                            mybookId: mybookId,
                            digitalBookID:digitalBookID,
                            username : nonAuthUser.username,
                            target : target,
                            programId : programId,
                            bookData: bookData,
                            programTitle:programTitle
                            }}} 
                        onClick={()=> this.onFinishDigitalbook()}
                            >
                                프로그램 완료 (리뷰 작성)
                        </Link>
                    : publishedCover ===false ?
                        <Link to={{pathname: `${ROUTES.XRBOOK_NONAUTH_COVER}`,
                        state: {
                            bookTitle:bookTitle,
                            mybookId: mybookId,
                            username : nonAuthUser.username,
                            digitalBookID:digitalBookID,
                            target : target,
                            site: site,
                            programId : programId,
                            bookData: bookData,
                            programTitle:programTitle
                            }}} 
                            onClick={()=> this.onFinishDigitalbook()}>
                                프로그램 완료 (표지 선택)
                        </Link>
                    : <Link to={{pathname: `${ROUTES.XRBOOK_NONAUTH}`}} onClick={()=> this.onFinishDigitalbook()}>프로그램 완료</Link> : null}
                {/* <div onClick={()=> this.onFinishDigitalbook()}>
                                프로그램</div> */}
                </div>

            </div>
        )
    }
}


const condition = nonAuthUser => nonAuthUser
// && nonAuthUser.roles[ROLES.MEMBER] === ROLES.ADMIN;

export default 
// compose(
//     withEmailVerification,
//     withAuthorization(condition),
//   )
withFirebase(NonAuthReader);
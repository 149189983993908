import React, { Component } from 'react'
import ProgramContentInput from './ProgramContentInput'
import ProgramEntered from './ProgramEntered'
import { withFirebase } from '../../../Firebase'


class ProgramContents extends Component {
    state = {
        programList : []
    }
    componentDidMount(){
        this.getProgramList()
    }
    getProgramList =()=>{
        this.props.firebase.programs().orderBy('order').get()
        .then(result => {
            var programList = []
            result.forEach(res => {
                var listData = res.data()
                var pid = res.id
                programList.push({pid, ...listData})
            })
            // console.log(programList)
            this.setState({programList})
        })
    }
    deleteProgram =(program)=>{
        const {programList} = this.state;
    
            const pid = program.pid
            const title = program.title
            // console.log(pid, title)
            this.props.firebase.program(pid).delete()
            .then(()=> alert(`${title}이 삭제되었습니다.`))
            .then(()=> {
                var newList = programList.slice()
                var idx = newList.indexOf(program)
                if (idx >-1) newList.splice(idx, 1)
                this.setState({programList : newList})
            })
            .catch(err=> alert('잠시 후 다시 시도해 주세요.\nerror: '+err))
        }
    render() {
        const {programList} = this.state;
        let {digitalbookList} = this.props
        return (
            <div>
                {/* 개별 프로그램 등록 */}
                {programList&& programList.map((c, id)=>
                <div key={c.pid}  className="contentsDataGroup">
                    <ProgramContentInput getProgramList={this.getProgramList} deleteProgram={this.deleteProgram} programList={c} digitalbookList={digitalbookList} idx={id}/>
                </div>
                )}
                {/* 등록된 프로그램 삭제 */}
                {/* <div><ProgramEntered getProgramList={this.getProgramList} deleteProgram={this.deleteProgram} programList={programList}/></div> */}
            </div>
        )
    }
}
export default withFirebase(ProgramContents);
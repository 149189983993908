import React, { Component } from 'react';
import {withFirebase} from '../../../Firebase'
import dayjs from 'dayjs'
import {getTimeDayjs} from '../../../helpers'
import {Link} from 'react-router-dom'
import * as ROUTES from '../../../../constants/routes';

class NonuserList extends Component {
        constructor(props) {
          super(props);
      
          this.state = {
            loading: false,
            users: [],
            msg:'비회원 문의리스트 다운로드(xlsx)'
          };
        }
      
        componentDidMount() {
          this.setState({ loading: true });
      
        //   this.unsubscribe = this.props.firebase
        //     .users()
        //     .onSnapshot(snapshot => {
        //       let users = [];
      
        //       snapshot.forEach(doc =>
        //         users.push({ ...doc.data(), uid: doc.id }),
        //       );
      
        //       this.setState({
        //         users,
        //         loading: false,
        //       });
        //     });
            this._ismounted = true;
            if(this._ismounted){
                this.getUserList()
            }  
        }
        componentWillUnmount() {
        //   this.unsubscribe();
        this._ismounted = false;
        }
        getUserList =()=> {
            this.props.firebase.inquiries().where('authUserId','==','nonAuth').orderBy('sendAt', "desc").limit(20).get()
            .then(result => {
                var lastVisible = result.docs[result.docs.length-1];
                var users = []
                result.forEach(res => {
                    var listData = res.data()
                    var uid = res.id
                    users.push({uid, ...listData})
                })
                this.setState({users, lastVisible, loading: false,})
            })
        }
        getNextUserList =()=> {
            let scrollPointX = window.scrollX
            let scrollPointY = window.scrollY
            let {users, lastVisible} = this.state
            if(lastVisible !==undefined){
            this.props.firebase.inquiries().where('authUserId','==','nonAuth').orderBy('sendAt', "desc").startAfter(lastVisible).limit(20).get()
            .then(result => {
                var lastVisible = result.docs[result.docs.length-1];
                result.forEach(res => {
                    var listData = res.data()
                    var uid = res.id
                    users.push({uid, ...listData})
                })
                this.setState({users, lastVisible})
                window.scrollTo(scrollPointX, scrollPointY)
            })
        } else {
            alert('최신 데이터 입니다.')
        }
        }
        getExcelFile = () => {
          this.setState({msg:'비회원 리스트를 다운받는 중입니다..'})
          fetch("/api/v1/nonUserList", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            // body: JSON.stringify(data)
        })
        .then((res)=>res.blob())
        .then((blob)=>{
          let today = getTimeDayjs()
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement('a');
          a.style.display = 'none';
          a.href = url;
          // the filename you want
          a.download = `XRBOOK비회원문의리스트(${today}).xlsx`;
          document.body.appendChild(a);
          a.click();
          window.URL.revokeObjectURL(url);
          this.setState({msg:'비회원 리스트 다운로드 완료!'})
          setTimeout(()=>this.setState({msg:'비회원 문의리스트 다운로드(xlsx)'}), 3000)
        })
        .catch(err => alert(`2 : ${err}`))
        }
        render() {
            const { users, loading, msg } = this.state;
            let {TotalNonauthinquiry} = this.props.statistics
          return (
            <div className="nonUserDataListFrame">
            <div className="userDataTitleBox">
                <h4>비회원 총 {TotalNonauthinquiry}명</h4>
                <div className="listDownloadBtn" onClick={()=>this.getExcelFile()}>{msg}</div>
            </div>
              {loading ? <div>Loading ...</div>
              
              :
              <table>
                  <thead>
                      <tr>
                        <th>이름</th>
                        <th>전화 번호</th>
                        <th>이메일</th>
                        <th>문의 유형</th>
                        <th>문의 일자</th>
                      </tr>
                  </thead>
                  <tbody>
                    {users.map(c => {
                        let{name, number, email, sendAt}=c
                        var isCreatedAtFormatted2 = getTimeDayjs(sendAt)
                        const category = c.category ==='program' ? 'Off-line Learning Program'
                                        : c.category ==='self' ? 'On-line Learning Program'
                                        : c.category ==='xrBook' ? 'Learning Program Development'
                                        : c.category ==='xrService' ? 'User Experience Kit Development'
                                        : '기타 문의'
                    return (
                        <tr key={c.uid}>
                            <td>{name}</td>
                            <td>{number}</td>
                            <td>{email}</td>
                            <td>{category}</td>
                            <td>{isCreatedAtFormatted2}</td>
                        </tr>
                    )})}
                </tbody>
              </table>
              }
              <button className="viewMoreListBtn" onClick={()=>this.getNextUserList()}>시간순 20개 더보기</button>
            </div>
          );
        }
      }
      
      export default withFirebase(NonuserList);
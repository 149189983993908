import React, { Component} from 'react'
import { withFirebase } from '../../Firebase'
import ReviewItem from './ReviewItem'
import dayjs from 'dayjs'
import {getTimeDayjs} from '../../helpers'
import './AdminReviews.css'
import $ from 'jquery'

class AdminReviewsPage extends Component {
    state = {
        reviewList : [],
        totalNum:0,
        reviewItem:{},
        isModalOpen:false,
        lastVisible:{}
    }
    componentDidMount(){
        this.getReviewList()
        this.getTotalNum()
    }
    getReviewList =()=> {
        this.props.firebase.reviews().orderBy('createdAt', "desc").limit(30).get()
        .then(result => {
            var lastVisible = result.docs[result.docs.length-1];
            var reviewList = []
            result.forEach(res => {
                var listData = res.data()
                var reviewId = res.id
                reviewList.push({reviewId, ...listData})
            })
            this.setState({reviewList, lastVisible})
        })
    }
    getNextReviewList =()=> {
        let {chapter} = this.props
        let scrollPointX = window.scrollX
        let scrollPointY = window.scrollY
        let {reviewList, lastVisible} = this.state
        if(lastVisible !==undefined){
        this.props.firebase.reviews().orderBy('createdAt', "desc").startAfter(lastVisible).limit(20).get()
        .then(result => {
            var lastVisible = result.docs[result.docs.length-1];
            result.forEach(res => {
                var listData = res.data()
                var reviewId = res.id
                reviewList.push({reviewId, ...listData})
            })
            this.setState({reviewList, lastVisible})
            window.scrollTo(scrollPointX, scrollPointY)
        })
    } else {
        alert('최신 데이터 입니다.')
    }
    }
    deleteReviewItem = (reviewId, id) => {
        let {reviewList} = this.state
        let tempList = reviewList.slice()
        let result = window.confirm('해당 이미지를 삭제 하시겠습니까?')
        if(result){
            this.props.firebase.review(reviewId).delete()
            .then(()=>{
                tempList.splice(id, 1)
                this.setState({
                    reviewList : tempList
                })
            })
            
        }
}
onPropsChange = (name, value) => {
    this.setState({
        [name] : value
    })
}
  getTotalNum (){
    this.props.firebase.tlpStatistics().get()
    .then((res)=> {
          let data = res.data()
          this.setState({totalNum : data.Totalreview})
    })
  }
  modalOpen = (status, item) => {
    if(status === true){
        $('html, body').css("overflow", "hidden");
        
    } else {
        $('html, body').css("overflow", "inherit")
    }
    this.setState({isModalOpen : status, reviewItem : item })
  }  
    render() {
        let {reviewList, totalNum, reviewItem, isModalOpen} = this.state
        return (
            <div className="adminReviewsFrame">
                <h3>Review 관리</h3>
                <p>사용자 작성 후기 총 {totalNum}건</p>
                <div className="adminReviewsBxGroup">
                    <table>
                        <thead>
                            <tr className="adminReviewsCateBx">
                                <th>카테고리</th>
                                <th>전송자 이름</th>
                                <th>리뷰 제목</th>
                                <th>일자</th>
                                <th>비고</th>
                                {/* <li></li>
                                <li></li> */}
                            </tr>
                        </thead>
                        <tbody>
                            {reviewList.length>0 ? reviewList.map((c, id)=> {
                                let {reviewId, show} = c
                            
                                return(
                                    <tr className="adminReviewsBx" key={reviewId} >
                                        <ReviewItem item={c} idx={id} isShowing={show} 
                                        deleteReviewItem={this.deleteReviewItem}  modalOpen={this.modalOpen}/>
                                        
                                    </tr>

                                )
                            }):'공유된 리뷰가 없습니다.'}
                        
                        </tbody>
                    </table>
                    <button className="viewMoreListBtn" onClick={()=>this.getNextReviewList()}>시간순 20개 더보기</button>
                </div>
                {isModalOpen && <ReviewModal item={reviewItem} modalOpen={this.modalOpen}/>}
            </div>
        )
    }
}
export default withFirebase(AdminReviewsPage)


const ReviewModal = props => {
    let {modalOpen, item} = props
    let {review, reviewTitle, createdAt, bookTitle, username, programTitle } = item
     var isCreatedAtFormatted2 = getTimeDayjs(createdAt)
    return(
        <div className="adminReviewDetailsBg">
            <div className="adminReviewDetailsBx">
                <button onClick={()=>modalOpen(false, '')}>×</button>
                <div className="adminReviewTextGroup"> 
                    <p className="reviewTitle">{reviewTitle}</p>
                    <p className="content contentPc">{review && review.split('<br />').map((c, id)=> {
                        return(
                            <span key={id}>{c}<br/></span>
                        )})}
                    </p>
                    <p className="content contentMobile">{(review).replace(/<br\s*[\/]?>/gi, " ")}</p>
                    <p className="writer">{isCreatedAtFormatted2}."『{programTitle}』 프로그램 " 후기,{username}</p>
                </div>
            </div>
        </div>
    )
  }

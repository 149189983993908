import React, { Component } from 'react'
import {withFirebase} from '../Firebase'
import { getTimeDayjs } from '../helpers'

class SelfInquiries extends Component {
    state = {
        onlineList : []
    }
    componentDidMount(){
        this.getOnlineList()
    }
    getOnlineList =()=> {
        let {authUser}=this.props
        this.props.firebase.tlpSales().where('authUserId','==', authUser['uid'] ).get()
        .then(result => {
            var onlineList = []
            result.forEach(res => {
                var listData = res.data()
                var pid = res.id
                onlineList.push({pid, ...listData})
            })
            this.setState({onlineList})
        })
    }
    render() {
        let {
            onlineList
        }= this.state
        return (
            <React.Fragment>
                {/* <ul className="myBookFrame">
                    <li>회사명</li>
                    <li>참여 인원</li>
                    <li>프로그램 명</li>
                    <li>결제 가격</li>
                    <li>교육 기간</li>
                    <li>코드 번호</li>
                    <li>진행 상황</li>
                    <li>일자</li>
                    <li>비고</li>
                </ul> */}
                {onlineList.length>0 ? onlineList.map(c=> {
                    const {groupTitle, participants, price, code, progress, progressAt,
                            selectedDays, isPublished, published, title, target} = c
                    const userPrice = price ==='' ? '-' : `${price.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')} 원`
                    const userParticipants= participants.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
                    const userCode = code ==='' ? '-' : code
                    const userStatus = progress ==='applied' ? '신청 완료' :
                            progress === 'consulting' ? '담당자 배정' :
                            progress === 'finished' ? '교육 종료' :
                            progress === 'canceled' ? '신청 취소' :
                            progress === 'request' ? '결제요청':
                            progress === 'paid' ? '결제완료' : ''
                    const daysLeng = selectedDays ? selectedDays.length : 0
                    const startDate = selectedDays ? selectedDays[0] : ''
                    const endDate = selectedDays ? 
                    daysLeng > 0 ? `${selectedDays[daysLeng-1]}` : ''
                    :''
                    let isProgressAt = getTimeDayjs(progressAt)
                    let isStartDate = getTimeDayjs(startDate)
                    let isEndDate = getTimeDayjs(endDate)
                return (
                    <ul className="myBookFrame" key={c.pid}>
                        <li><span>회사명</span>{groupTitle} </li>
                        <li><span>참여 인원</span>{userParticipants} </li>
                        <li><span>프로그램 명</span>{title}({target}대상)</li>
                        <li><span>결제 가격</span>{userPrice}</li>
                        <li><span>교육 기간</span>{startDate !== undefined ?isStartDate : ''} - {endDate !== '' ? isEndDate : ''}</li>
                        <li><span>코드 번호</span>{userCode} </li>
                        <li><span>진행 상황</span>{userStatus} </li>
                        <li><span>일자</span>{isProgressAt}</li>
                        <li><span>비고</span>
                        {/* {isPublished? '책 인쇄 서비스' :null} */}
                        </li>
                    </ul>
                    )
                })
                : <div className="noDataText">On-line Learning Program에 대한 문의 내용이 없습니다.</div>}
            </React.Fragment>
        )
    }
}
export default 
withFirebase (SelfInquiries)
import React, {Component} from 'react';
import { compose } from 'recompose';
import {withRouter} from 'react-router-dom'
import { withFirebase } from '../Firebase'
import { withEmailVerification, withAuthorization } from '../Session';
import * as ROUTES from '../../constants/routes';
import './Landing.css'
import './LandingResp.css'

class EmailVerifiedPage extends Component {
  state = {
    time : 3,
    username: this.props.location.state  !== undefined ? 
        this.props.location.state.username : '',
  }
  componentDidMount() {
      try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    };
    setTimeout(()=>{
      this.props.firebase.auth.signOut().then(()=>
      this.props.history.push(ROUTES.LOGIN)
      )
  }
    ,3000)
    }
  render() {
    let {time, username} = this.state
  return(
    <div className="landingBg" >
      <ul className="landingFrame">
        <li className="pwResetTitle">회원가입이 완료되었습니다.</li>
        <li className="landingTxt">
          <span>{username !=='' ? `${username}님, ` : null}XR BOOK 사이트의 </span>
          <span>회원이 되신 것을 환영합니다!</span>
        </li>
        <li className="landingTxt">잠시 후 로그인 화면으로 이동합니다.</li>
      </ul>
    </div>

  )}
  }
  
const condition = authUser => authUser && !authUser.emailVerified;

export default 
compose(
  withRouter,
  withFirebase
)
(EmailVerifiedPage);
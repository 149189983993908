import React, { Component } from 'react';

class XrMakingContents extends Component {
    render() {
        return (
            <div>
                XR Making Book Service 관련 연결된 데이터가 없습니다.
            </div>
        );
    }
}

export default XrMakingContents;
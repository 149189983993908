import React, { Component } from 'react'
import { withFirebase } from '../../../Firebase'
import SlideTextItem from '../SlideTextItem'

class ServiceSection extends Component {0
    // Class = 배경이미지1, (이미지2, 텍스트 2, 순서) * n, section
    // Self = 배경이미지1,  (이미지2, 텍스트 2, 순서) * n, section
    // XR BOOK = 배경동영상1,  (이미지2, 텍스트 2, 순서) * n, section
    // 갤러리 - (배경동영상-긴텍스트) *4, section
    // 고객센터 - { 카테고리(배송, 반품/교환, 취소, FAQ),
    //                  텍스트 1, 긴텍스트 1, show여부? }* n, section
    state={
        slideList:[],
        text1:'',
        text2:'',
        category:'',
        show:false,
        site:'tlp',
        section : 'service',
}
componentDidMount(){
    this.getserviceInfo()
}
onPropsChange = (name, value) => {
    this.setState({
        [name] : value
    })
}
onStateChange = e => {
    this.setState({
        [e.target.name] : e.target.value
    })
}
addSlideListItem = () => {
    const {category, show, text1, text2, slideList} = this.state;
    const data = {category:category,show:show,text1:text1, text2:text2}
    this.setState({
        category:'', text1:'', text2:'', 
        slideList: slideList.concat(data)
    })
}
editSlideListItem = (item, id) => {
    const {slideList} = this.state
    var newList = slideList.slice()
    newList[id] = item
    this.setState({slideList : newList})
}
deleteSlideListItem = item => {
    const {slideList} = this.state
    var newList = slideList.slice()
    var idx = newList.indexOf(item)
    if (idx >-1) newList.splice(idx, 1)
    this.setState({slideList : newList})
}
getserviceInfo = () => {
    this.props.firebase.mainSection('service').get()
    .then(res => {
        const result = res.data()
        this.setState(result)
    })
    .catch(err=> {
        alert('데이터 불러오기를 실패했습니다. \n에러코드 : '+err)
        // window.location.reload()
    })
}
submitserviceData = () => {
    const {slideList, section, site} = this.state;
    const data = {slideList, section, site}
    this.props.firebase.mainSection('service').set(data,{merge:true})
    .then(()=> {
        alert('고객센터 정보가 수정되었습니다.')
        })
    .catch(err=> {
        alert('오류가 발생했습니다. 잠시 후 다시 시도해 주세요.\n에러코드 : '+err)
    })
    // window.location.reload()
}

    render() {
        const {category, show, text1, text2, slideList, section}= this.state
        const isInvalid = category === '' ||  text1 === '' || text2 === ''|| show === ''
        const isInvalid2 = slideList === []
        const formattedText = text1.replace(/(<br>|<br\/>|<br \/>)/g, '\n')
        return (
             <div>
               <div>고객센터</div>
               <button onClick={this.submitserviceData} disabled={isInvalid2}>적용</button>
                <div> 
                     <div>슬라이드
                            <select name='category' value={category} onChange={this.onStateChange}>
                                <option value=''>카테고리</option>
                                <option value='배송'>배송</option>
                                <option value='반품/교환'>반품/교환</option>
                                <option value='취소'>취소</option>
                                <option value='FAQ'>FAQ</option>
                            </select>
                            <div>텍스트 1 :
                                <textarea name='text1' value={formattedText} 
                                onChange={e=> this.setState({text1: e.target.value.replace(/(?:\r\n|\r|\n)/g, '<br />')}) }/>
                            </div>
                        
                            <div>텍스트 2 :
                                <input type='text' name='text2' value={text2} onChange={this.onStateChange}/>
                            </div>
                            <button onClick={this.addSlideListItem} disabled={isInvalid}>등록</button>
                    </div>
                    <div>{section}</div>
                    
                    <div >
                    {slideList && slideList.map((c, i)=> {
                        return(
                        <SlideTextItem 
                        key={i} item={c} onDelete={this.deleteSlideListItem} onEdit={this.editSlideListItem} idx={i}
                        />
                        )
                    })
                    }
                    </div>

                </div>

            </div>
        )
    }
}
export default withFirebase(ServiceSection)
import React, { Component } from 'react'
import { withFirebase } from '../../../Firebase'
import {Link} from 'react-router-dom'
import * as ROUTES from '../../../../constants/routes';
import MainSwiper from '../MainSwiper.jsx'
import './selfLearning.css'
import './selfLearningRes.css'
import $ from "jquery";


class SelfLearning extends Component {
    state={
        index: 0,
        bgImgUrl:'',
        slideList:[]
    }
    // componentDidMount(){
    //     this.getselfInfo()
    // }
    getselfInfo = () => {
        this.props.firebase.mainSection('self').get()
        .then(res => {
            const result = res.data()
            this.setState(result)
        })
        .catch(err=> {
            // window.location.reload()
            console.log(err)
        })
    }
    render(){
        const {slideList, index} = this.state     
    return (
        <React.Fragment>
            <div className="sectOutFrame slOutFrame">
                {/* <MainSwiper slideList={slideList} idName='selflearningSlide' />          */}
                <div className="slInFrame sl1stInFrame">
                    <div className="TitleBox">
                        <ul>
                            <li className="contTitleText"><span>오프라인</span><span>Learning Program</span></li>
                            <li className="contentText">
                                <span>오프라인에서 개인과 팀으로 성찰과 회복의 </span>
                                <span>XR Reflection Writing을 경험할 수 있습니다.</span>
                                <span>조직차원의 계획적 학습에 적합하며, 단일과정으로 운영할 수 있습니다.</span>
                                <br/>
                                <span>▷ 타 교육 과정에 Open/Closing Module이나 Spot Module로 운영할 수 있습니다.</span>
                                <span>▷ 독립형 HMD(오큘러스 고), 카드보드와 AR app, 강사, XR BOOK or 
                                    Digital XR BOOK(Tablet PC, Mobile, PC)을 기본으로 제공합니다.</span>
                                <span><span style={{color:"#c56e6e"}}>▷</span> 고객사 상황과 요청에 따라 Customize 운영 가능합니다.</span>
                                <br/>
                                <span>▷ 학습 종료 후 책을 출판, 소장할 수 있습니다.</span>
                                <span>(1) 내가 쓴 나의 어린 왕자 책으로</span>
                                <span>(2) 우리가 쓴 우리 회사/조직/팀의 어린 왕자 책으로</span>
                            </li>
                            <li className="buttonText">
                                <Link className="orderBtn" to={{
                                    pathname: `${ROUTES.GUIDE}`,
                                    state : {
                                        tab : "ClassInfo"
                                    }
                                }} >수강 가이드 보기</Link>
                                <Link className="orderBtn" to={{
                                    pathname: `${ROUTES.ORDER}`,
                                    state : {
                                        tab : "program"
                                    }
                                }} >프로그램 신청하기</Link>
                            </li>
                        </ul>
                    </div>
                    <div className="contBox">
                        <div></div>
                        <ul>
                            <li>휴식이 되는 학습, 학습이 되는 휴식</li>
                            <li>
                                <span>#누구나</span>
                                <span>#5시간</span>
                                <span>#동기부여</span>
                                <span>#조직문화</span>
                                <span>#전환학습</span>
                                <span>#조직개발</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className="sectOutFrame slOutFrame">
                <div className="slInFrame sl2ndInFrame">
                    <div className="TitleBox">
                        <ul>
                            {/* <li className="sectMenuText">Learning Program</li> */}
                            <li className="contTitleText"><span>온라인</span><span>Learning Program</span></li>
                            <li className="contentText">
                                <span>Digital XR BOOK을 통해 언제 어디서나</span> 
                                <span>참여자들과 연결, 공유 하며 성찰과 회복의 </span>
                                <span>XR Reflection Writing 경험할 수 있습니다.</span>
                                <span>개인 차원에서 자유로운 비 계획적 학습과 </span>
                                <span>조직차원에서 계획적 학습에 적합하며,</span>
                                <span>학습과정과 결과의 Data를 활용 할 수 있습니다.</span>

                                <span>▷ 학습 종료 후 </span>
                                <span>내가 쓴 나의 어린 왕자 책을 출판 소장 할 수 있습니다.</span>
                            </li>
                            <li className="buttonText">
                                <Link className="orderBtn" to={{
                                    pathname: `${ROUTES.GUIDE}`,
                                    state : {
                                        tab : "SelfInfo"
                                    }
                                }} >수강 가이드 보기</Link>
                                <Link className="orderBtn" to={{
                                    pathname: `${ROUTES.ORDER}`,
                                    state : {
                                        tab : "sales"
                                    }
                                }} >프로그램 신청하기</Link>
                                
                            </li>
                        </ul>
                    </div>
                    <div className="contBox">
                        <div></div>
                        <ul>
                            <li>선물같은 힐링의 시간</li>
                            <li>
                                <span>#누구나</span>
                                <span>#1개월</span>
                                <span>#자기개발</span>
                                <span>#조직개발</span>
                                <span>#휴식</span>
                                <span>#선물</span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </React.Fragment>
    )
};
}
export default withFirebase(SelfLearning);
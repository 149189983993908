import React, { Component } from 'react';
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { withFirebase } from '../Firebase' 
import Player from '@vimeo/player';
import $ from 'jquery'
import { getDateString, getTimeString } from '../helpers';

class VideoItemInstituteUser extends Component {
    // constructor(props) {
    //     super(props);
    //     // 여기서 this.setState()를 호출하면 안 됩니다!
    //     // this.state = { counter: 0 };
    //   }

    state = {
        url : this.props.url,
        name : this.props.name,
        instituteReaderData: this.props.instituteReaderData
    }
    componentDidMount(){
        let { url, name } = this.props
        let {instituteReaderData} = this.state
        let uid = instituteReaderData.uid
        let studentId = instituteReaderData.studentId
        let subjectName = instituteReaderData.subjectName
        var player = new Player(this.iframeRef);
        player.on('play', (playData) => {
            this.saveVideoData({uid, studentId, name, url, type:'play', playData})
            // console.log('play : ')
            // console.log(playerData)
        });
        // player.on('pause', (playData) =>  {
        //     //  console.log('pause : ')
        //     //  console.log(playData)
        //      this.saveVideoData({uid, studentId, name, url, type:'pause', playData})
        // });

        // player.on('ended', (playData) => {
        //     //  console.log('ended : ')
        //     this.saveVideoData({uid, studentId, name, url, type:'ended', playData})
        //     //  console.log(playData)
        // });

        // player.on('playing', (playData) => {
        //     //  console.log('playing : ')
        //     //  console.log(playData)
        // });
    }
    saveVideoData = ({uid, name, url, type, playData}) => {
        console.log({uid, name, url, type, playData})
        this.props.firebase.instituteHistory(uid).child(getDateString()).push({
            videoName : name,
            videourl : url,
            createdAt : getTimeString(),
            type,
            playData
        })
        .catch((err)=>console.log(err))
    }
    getresfresh = () => {
        let newUrl = this.state.url+' '
        this.setState({
            url : newUrl
        })
    }
    render() {
        let {url} = this.state
        let {name} = this.props
        return (
            <React.Fragment>
            <span className='facilitatorVideoTitle'>{name}<span onClick={()=>this.getresfresh()} className='facilitatorVideoIcon'><FontAwesomeIcon icon={faSyncAlt} /></span></span>
                <span className="facilitatorVideoInFrame">
                    <iframe title='intro'
                            src={`https://player.vimeo.com/video/${url}`}
                            frameBorder="0"
                            allow="autoplay; fullscreen"  allowFullScreen
                            id='instituteIframe'
                            // onClick={(e)=>{console.log(e, url)}}
                            ref={elem => this.iframeRef = elem}
                    ></iframe>
                </span>
                </React.Fragment>
        );
    }
}

export default withFirebase(VideoItemInstituteUser);
import React, { Component } from 'react'
import {withRouter} from 'react-router-dom'
import * as ROUTES from '../../../../constants/routes';
import { compose } from 'recompose';
import { withFirebase} from '../../../Firebase';
import Digitalbook_PC from './Digitalbook_PC.jsx'
import Digitalbook_Mobile from './Digitalbook_Mobile.jsx'
import XRBOOKLogo from '../../../images/XRBOOKLogo.png'
import dBookImagePc1 from '../../../images/dBookImagePc1.png'
import dBookImagePc2 from '../../../images/dBookImagePc2.png'
import dBookImagePc3 from '../../../images/dBookImagePc3.png'
import dBookImagePc4 from '../../../images/dBookImagePc4.png'
import dBookImagePc5 from '../../../images/dBookImagePc5.png'
import dBookImageTaMo1 from '../../../images/dBookImageTaMo1.png'
import dBookImageTaMo2 from '../../../images/dBookImageTaMo2.png'
import dBookImageTaMo3 from '../../../images/dBookImageTaMo3.png'
import dBookImageTaMo4 from '../../../images/dBookImageTaMo4.png'
import dBookImageTaMo5 from '../../../images/dBookImageTaMo5.png'
import Manual from '../../../Guide/Manual'
import '../../../Digitalbook/AuthReader/AuthReader.css'
import '../../../Digitalbook/AuthReader/AuthReaderResp.css'
import $ from "jquery";
import { faInfo } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


class OrderUser extends Component {
    state={
        bookData :{},
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
        authUser: JSON.parse(localStorage.getItem('authUser')),
        pageId:0,
        pcPageId:0,
    }
    componentDidMount(){
        let bookId = this.props.match.params.bookId
        // console.log(bookId)
        this.onGetDigitalbook(bookId)
        window.addEventListener('resize', this.updateDimensions);        
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }
    updateDimensions = () => {
        this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
      };
    componentDidUpdate(prevProps, prevState){
        let {pcPageId, pageId} = this.state
    }
    onPageListChange = (name, value) => {
        if(name === 'pageList'){
            this.setState(prevState => ({
                bookData: {
                    ...prevState.bookData,
                    pageList : value
                },
            }))
            
        } else if (name === "pageIdPC"){
            this.setState(prevState => ({
                // pcPageId: prevState.pcPageId+value,
                pageId:prevState.pageId+(value*2)
            }))
        } else if (name === "pageIdMobile"){
            this.setState(prevState => ({
                pageId: prevState.pageId+value,
                // pcPageId:parseInt((prevState.pageId+value)/2)
            }))
            // console.log(value)
        } else if (name === "pageId"){
            this.setState({
                pageId: value
            })
        } else {
            this.setState({
                [name]:value
            })
        }
        }
    onGetDigitalbook = (bookId) => {
        let mybookId = bookId ? bookId :'ONbbPBYSLjgdxK62yaoz'
            this.props.firebase.library(mybookId).get()
            .then((res)=>{
                let result = res.data()
                this.setState({bookData : result})
                $(".bookGuideBg").show();
               })
            }

    onBookGuide = (type) => {
        if (type === 'again'){
            $(".bookGuideBg").hide();
        } else if (type === 'show'){
            $(".bookGuideBg").show();           
        }
    }
    onManualGuide = (type) => {
        if(type ==='hidden'){
            $(".manualBg").hide();
        } else if (type === 'show'){
            $(".manualBg").show();
            
        }
    }
    setBookGuideSlidePrev = () => {
        var lastChild = $(".bookGuideFrame>ul>li").last();
        $(".bookGuideFrame>ul").stop().animate({"margin-left":"0"}, 500, function(){
            $(".bookGuideFrame>ul").prepend(lastChild).css("margin-left", "-100%");
        })
    }
    setBookGuideSlideNext = () => {
        var firstChild = $(".bookGuideFrame>ul>li").eq(0);
        $(".bookGuideFrame>ul").stop().animate({"margin-left":"-200%"}, 500, function(){
            $(".bookGuideFrame>ul").append(firstChild).css("margin-left", "-100%");
        })
    }
    isMobile=()=> {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
   }
    saveAlert = (e, type) => {
        e.preventDefault()
        this.props.history.goBack()
        // this.props.history.push({pathname: `${ROUTES.}`, state: {tab : 'library'}})
    }
    render() {
        let {authUser, bookData, pageId,pcPageId, windowWidth} = this.state
        let pageLeng = bookData.pageList ? bookData.pageList.length : 0
        return (
            <div className="authReaderFrame disabledDrag">
                <div className="bookGuideBg">
                    <div className="bookGuideFrame">
                        <ul>
                            <li className="bookGuide5">
                                <ul className="bookGuideGroup">
                                    <li>
                                        <img className="dbookPcImage" src={dBookImagePc5} alt="디지털 북 사용 가이드 PC 이미지4"/>
                                        <img className="dbookTaMoImage" src={dBookImageTaMo5} alt="디지털 북 사용 가이드 반응형 이미지4"/>
                                    </li>
                                    <li>작성 완료 후 표지 선택과 배송지 정보를 꼭 확인해 주세요</li>
                                    <li>
                                        <span className="textBorder">썸네일 이미지</span>를 선택하여 표지를 적용해 보세요.
                                        인쇄를 진행하는 경우 표지 선택을 완료하기 전에 배송지 정보를 확인하고, 주소가 올바르지 않을 경우
                                        <span className="textBorder">주소 입력/수정</span> 버튼을 클릭하여 주소를 다시 입력해 주세요.
                                        <span className="textBorder">표지 선택 완료</span>버튼을 누르면 나의 XR BOOK이 완성됩니다.
                                    </li>
                                </ul>
                            </li>
                            <li className="bookGuide1">
                                <ul className="bookGuideGroup">
                                    <li>
                                        <img className="dbookPcImage" src={dBookImagePc1} alt="디지털 북 사용 가이드 PC 이미지1"/>
                                        <img className="dbookTaMoImage" src={dBookImageTaMo1} alt="디지털 북 사용 가이드 반응형 이미지1"/>
                                    </li>
                                    <li>목차를 확인하고 페이지를 이동해 보세요</li>
                                    <li>
                                        <span className="textBorder">목차</span>
                                        버튼을 눌러 디지털 북의 목차를 확인해 보세요. 목차 제목을 선택하여 원하는 페이지로 이동할 수 있습니다.
                                        <span className="textBorder">이전</span>,<span className="textBorder">다음</span> 
                                        버튼을 눌러 페이지를 넘겨 보세요. 사용자 작성 공간이 있는 페이지는 작성을 완료해야 다음 페이지로 이동할 수 있습니다.
                                    </li>
                                </ul>
                            </li>
                            <li className="bookGuide2">
                                <ul className="bookGuideGroup">
                                    <li>
                                        <img className="dbookPcImage" src={dBookImagePc2} alt="디지털 북 사용 가이드 PC 이미지2"/>
                                        <img className="dbookTaMoImage" src={dBookImageTaMo2} alt="디지털 북 사용 가이드 반응형 이미지2"/>
                                    </li>
                                    <li>내 생각을 공유하고 원하는 페이지를 책갈피 해 두세요</li>
                                    <li>
                                        <span className="textBorder">공유</span>
                                        버튼을 눌러 XR BOOK 사이트에 나의 생각을 공유할 수 있습니다. my page의 Reflection Gallery 이용 현황 메뉴에서 내가 공유한 이미지들을 관리, 삭제할 수 있습니다. 
                                        상단의 <span className="textBorder">책갈피</span> 
                                        버튼을 눌러 기억 하고 싶은 페이지를 표시해 두세요. 하단의 책갈피 모음 버튼을 클릭하여 그 동안 표시 해 놓은 페이지들을 한 번에 확인할 수 있습니다.
                                    </li>
                                </ul>
                            </li>
                            <li className="bookGuide3">
                                <ul className="bookGuideGroup">
                                    <li>
                                        <img className="dbookPcImage" src={dBookImagePc3} alt="디지털 북 사용 가이드 PC 이미지3"/>
                                        <img className="dbookTaMoImage" src={dBookImageTaMo3} alt="디지털 북 사용 가이드 반응형 이미지3"/>
                                    </li>
                                    <li>나의 생각을 글로 적어보고, 그림으로 표현해 보세요</li>
                                    <li>
                                        <span className="textBorder">글 입력란</span>
                                        의 경우 정해진 칸을 초과하여 작성된 부분은 인쇄 시 내용이 잘릴 수 있습니다. 그림으로 표현해 볼 수 있는 사용자 작성 공간에서는 
                                        오른쪽 상단의 드로잉 <span className="textBorder">툴 박스</span>를 눌러 원하는 배경/붓 색상을 선택할 수 있습니다.
                                    </li>
                                </ul>
                            </li>
                            <li className="bookGuide4">
                                <ul className="bookGuideGroup">
                                    <li>
                                        <img className="dbookPcImage" src={dBookImagePc4} alt="디지털 북 사용 가이드 PC 이미지4"/>
                                        <img className="dbookTaMoImage" src={dBookImageTaMo4} alt="디지털 북 사용 가이드 반응형 이미지4"/>
                                    </li>
                                    <li>작성한 내용은 꼭 저장해 주세요</li>
                                    <li>
                                        페이지를 나가기 전 
                                        <span className="textBorder">저장하기</span>
                                        버튼을 꼭 눌러주세요. 저장하기 버튼을 누르지 않고 페이지를 나가면 작성한 내용이  저장되지 않습니다.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className="bookGuidePager">
                        <div className="bookGuidePrev" onClick={()=>this.setBookGuideSlidePrev()}>&#60;</div>
                        <div className="bookGuideNext" onClick={()=>this.setBookGuideSlideNext()}>&#62;</div>
                    </div>
                    <div className="bookGuideBtn">
                        <div className="bookGuideBtn2" onClick={()=>this.onBookGuide('again')}>닫기</div>
                    </div>
                </div>
                <div className="manualBg" style={{display:'none'}}>
                    <div className="manualDelBtn" onClick={()=>this.onManualGuide('hidden')}>×</div>
                    <Manual headShow={false}/>
                </div>


                <div className="bookTop">
                    <div className="booInfoGroup">
                        <div className="iconFrame">
                            <img src={bookData.iconUrl || XRBOOKLogo } alt='icon'/>
                        </div>
                    <span>{bookData.bookTitle}{authUser ? ` - 저자 : ${authUser['username']}` : null}</span>
                    </div>
                    <div className="linkGroup">
                        <div className="delinkBtnPc" onClick={()=>this.onManualGuide('show')}>[ 기기 사용법 ]</div>
                        <div className="infoLinkBtnMobile" onClick={()=>this.onBookGuide('show')}><FontAwesomeIcon icon={faInfo}/></div>
                        <div className="infoLinkBtnPc" onClick={()=>this.onBookGuide('show')}>[ 도움말 ]</div>
                        <span onClick={(e)=>window.close()} >창 닫기</span> 
                    </div>
                </div>
                <div className="bookMiddle">
                    <p>
                        XR BOOK 작성 중
                    </p>
                </div>
                {windowWidth >834 ? 
                <Digitalbook_PC
                 digitalbook={bookData} onPageListChange={this.onPageListChange}
                 pageId={pageId} pcPageId={pcPageId}/>
                :       
                <Digitalbook_Mobile
                 digitalbook={bookData} onPageListChange={this.onPageListChange}
                 pageId={pageId}
                 />
                 }
                <div className="bookBottom">
                <span onClick={(e)=>window.close()} >창 닫기</span> 
                </div>

            </div>
        )
    }
}

export default compose(
    withFirebase,
    withRouter
  )(OrderUser);
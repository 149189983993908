import React, {useState} from 'react';
import * as firebase from "firebase";
import {getTimeString} from '../../helpers'

const BrochureUpload = props => {
    const allInputs = {imgUrl: ''}
    const [imageAsFile, setImageAsFile] = useState('')
    const [imageAsUrl, setImageAsUrl] = useState(allInputs)
    const storage = firebase.storage()
    const {onUpdate, name} = props
    // const timeString = getTimeString()
    const handleImageAsFile = (e) => {
        const image = e.target.files[0]
        setImageAsFile(imageFile => (image))
    }
    const handleFireBaseUpload = e => {
        e.preventDefault()
    //   console.log('start of upload')
      // async magic goes here...
      const fileTypes = imageAsFile.name.split('.')
      const typeLeng = fileTypes.length
      const fileType = fileTypes[typeLeng-1]
      if (fileType !== 'pdf'){
        console.error(`not an image, the image file is a ${typeof(imageAsFile)}`)
        alert('.pdf 파일만 업로드 가능합니다.')
      } else {
      const uploadTask = storage.ref(`/tlp/brochure/${name}.pdf`).put(imageAsFile)
      //initiates the firebase side uploading 
      uploadTask.on('state_changed', 
      (snapShot) => {
        //takes a snap shot of the process as it is happening
        // console.log(snapShot)
      }, (err) => {
        //catches the errors
        console.log(err)
      }, () => {
        // gets the functions from storage refences the image storage in firebase by the children
        // gets the download url then sets the image from firebase as the value for the imgUrl key:
        storage.ref('tlp/brochure').child(`${name}.pdf`).getDownloadURL()
         .then(fireBaseUrl => {
           setImageAsUrl(prevObject => ({...prevObject, imgUrl: fireBaseUrl}))
           onUpdate(name, fireBaseUrl)
         })
         .then(alert('업데이트가 완료되었습니다.'))
      })
      }
    }
      // console.log(imageAsFile)
    return (
        <div>
           <form onSubmit={handleFireBaseUpload}>
              <input 
                type="file"
                onChange={handleImageAsFile}
                />
              <button type="submit" disabled={!imageAsFile}>업로드</button>
              {/* 업로드한 파일 미리 보기 버튼 - 업로드시에만 보임 */}
              {imageAsUrl.imgUrl && 
              <a href={imageAsUrl.imgUrl} target='_blank'>미리보기</a>
              }
            </form>
        </div>
    );
};

export default BrochureUpload
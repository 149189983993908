import React, { Component } from 'react'
import { withFirebase } from '../../Firebase';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
import { getTimeString } from '../../helpers';
import * as ROUTES from '../../../constants/routes'
import Spinner from '../../Utils/Spinner'
import InstituteAuthHome from './InstituteAuthMain'
import './InstituteAuth.css'


class InstituteAuthCheck extends Component {
    state = {
        userId:JSON.parse(sessionStorage.getItem('instituteAuthReader')) ? JSON.parse(sessionStorage.getItem('instituteAuthReader')).userId:  '',
        userPw:JSON.parse(sessionStorage.getItem('instituteAuthReader')) ? JSON.parse(sessionStorage.getItem('instituteAuthReader')).userPw : '',
        instituteAuthReaderData : {},
        loading: false,
        spinnerMsg : '로그인 정보를 불러오는 중입니다.',
        show:false
    }
    componentDidMount(){
        let instituteAuthReader = sessionStorage.getItem('instituteAuthReader')
        if(instituteAuthReader){
            let {userId, userPw} = JSON.parse(instituteAuthReader)
            this.setState({userId, userPw})
            this.onNonAuthLogin(userId, userPw, this.onInstituteAuthCheck)
        }
    }
    onNonAuthLogin = (userId, userPw, next) =>{
        this.setState({
            loading:true
        })
        let instituteReaderData = []
        this.props.firebase.instituteAuthReaders().where('userId','==', userId).where('userPw','==', userPw).orderBy('createdAt','desc')
        .get()
        .then(result => {
            if(result.size>0){
                let uid = ''
                let institute = ''
                let instituteId = ''
                let type = 'S'
                let username = ''
                let email = ''
                let studentId = ''
                let subjectName = ''
                
                result.forEach((doc)=> {
                    var docData = doc.data()
                    uid = doc.id
                    institute = docData.institute
                    studentId = docData.studentId
                    subjectName = docData.subjectName
                    instituteId = docData.instituteId
                    username = docData.username
                    email = docData.email
                    type = docData.type
                    instituteReaderData.push({...docData, uid})
                })
                    let instituteAuthReader = {email, username, uid, institute, studentId, subjectName, instituteId, type, userId, userPw}
                    sessionStorage.setItem('instituteAuthReader', JSON.stringify(instituteAuthReader));
                    this.props.history.push(
                        {
                        pathname: ROUTES.XRBOOK_INSTITUTEAUTH_MAIN,
                        state: {instituteReaderData : instituteReaderData[0]}
                      })
            } else {
                alert('프로그램에 등록되지 않았거나 잘못 입력된 메일/이름입니다.')
                this.setState({loading:false})
            }
        })
        .catch(err => console.log('입력하신 정보를 다시 확인 후 재 시도 해주세요.\n'+err))
    }
      
    onStateChange = e => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }
    onPropsChange = (name, value) => {
        this.setState({
            [name] : value
        })
    }
    render() {
        let {userId, userPw, loading, show, spinnerMsg, instituteAuthReaderData} = this.state
        return (
            <div className="nonAuthSignFrame">
                {!show && 
                !loading ?
                <div className="signFrame">
                    <h1>교육회원 로그인</h1>
                    <div className="inputGroup">
                        <input type='text' name='userId'value={userId}  placeholder='학번 (ex.20101122)' onChange={this.onStateChange}/>
                        <input type='text' name='userPw' value={userPw} placeholder='이름 (ex.박길동)' onChange={this.onStateChange} autocomplete="off"/>
                        <div onClick={()=>this.onNonAuthLogin(userId, userPw)}>로그인</div>
                        <Link to={{pathname: `${ROUTES.TLP_MAIN}`}}>홈으로 가기</Link>
                    </div>
                </div>
                :
                <div style={{paddingTop: '5.5vw'}}>
                <Spinner msg={spinnerMsg}/>
                </div>
                }
                
            </div>
        )
    }
}
export default compose(
    withRouter,
    withFirebase,
  )(InstituteAuthCheck);

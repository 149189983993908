import React, { Component } from 'react'
import {withFirebase} from '../../../Firebase'

class UserItem extends Component {
    state = {
        user:this.props.user,
        roles : this.props.user.roles['member']
    }
    changeRoles = (uid, name, targetRoles) => {
        let role = targetRoles === 'admin' ? '관리자' 
        : targetRoles === 'facilitator' ? '강사'
        : targetRoles === 'member' ? '일반회원으'
        : targetRoles === 'marketing' ? '영업/마케팅 회원으':'오류'
        let result = window.confirm(`${name}님의 회원자격을 ${role}로 변경하시겠습니까?`)
        if(result){
            this.props.firebase.user(uid).set({roles:{member : targetRoles}},{merge:true})
            .then(()=>{
                this.setState({
                    roles:targetRoles,
                    user : {...this.state.user, roles:{member:targetRoles}}
                })
            })
        }
        
    }
    render() {
        let {user, roles} = this.state
        let idx = this.props.idx
        let role = user.roles['member'] === 'admin' ? '관리자' 
        : user.roles['member'] === 'facilitator' ? '강사' 
        : user.roles['member'] === 'member' ? '일반 회원'
        : user.roles['member'] === 'marketing' ? '영업 회원' : '오류'
        return (
          <React.Fragment>
            <td>
              {idx}
            </td>
                  <td>
                    {user.email}
                  </td>
                  <td>
                    {user.username}
                  </td>
                  <React.Fragment>
                  {roles === 'admin' ? 
                <td></td>
              :<td style={role==='강사' ? {background:'#eeeeee', display:'flex', justifyContent:'center'}
              : role==='영업 회원' ? {background:'#eeeeee', display:'flex', justifyContent:'center'}
              :{display:'flex', justifyContent:'center'}}>
                
                <select value={roles} onChange={(e)=>this.setState({roles:e.target.value})}>
                    <option value='member'>일반회원</option>
                    <option value='facilitator'>강사</option>
                    {/* <option value='marketing'>영업/마케팅</option> */}
                </select>
                <button onClick={()=>this.changeRoles(user.uid, user.username, roles)}>변경</button>
              </td>}
                  </React.Fragment>
          </React.Fragment>
        )
    }
}
export default withFirebase(UserItem)
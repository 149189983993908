import React, { Component } from 'react'
import {withEmailVerification} from '../../Session'
import * as ROUTES from '../../../constants/routes';

class XrMakingService extends Component {
    state = {
        xrbookByTheHRDer: ''
    }
    componentDidMount(){
        this.getxrbookByTheHRDer()
    }
    getxrbookByTheHRDer = () => {
        this.props.firebase.url().get()
        .then(res=> {
            const urlList = res.data()
            this.setState(urlList)
        })
        .catch(err => console.log(err))
    }
    render() {
        const { xrbookByTheHRDer } = this.state
        return (
            <div style={{paddingTop:"200px"}}>
                XR-Making-Service
                {/* 문의하기 버튼 */}
                <a href={ROUTES.TLP_MAIN_CONTACT}>문의하기</a><br/>
                {/* 소개서 다운로트 버튼 */}
                <a href={xrbookByTheHRDer} target='_blank'>
                XR BOOK 소개서 다운로드</a>
            </div>
        )
    }
}
export default withEmailVerification(XrMakingService)
import React, { Component, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { withFirebase } from '../../Firebase'
import { AuthUserContext } from '../../Session';
import * as ROUTES from '../../../constants/routes';
import * as ROLES from '../../../constants/roles';
import './TLPNavigation.css'
import './TLPNavigationRes.css'
import Scrollspy from 'react-scrollspy'
import {FirebaseContext} from '../../Firebase'
// import logo from '../../../images/xrbook_bi_1.png'
import logo from '../../images/XRBOOKLogo.png'
import $ from "jquery";
import { faLongArrowAltRight } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class TLPNavigation extends Component {
  constructor(props) {
    super(props);

    this.state = {
      prevScrollpos: window.pageYOffset,
      visible: true,
      bannerSession : 'show',
      navHeight:'',
      
    };
  }

  // Adds an event listener when the component is mount.
  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  // Remove the event listener when the component is unmount.
  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  // Hide or show the menu.
  handleScroll = () => {
    const { prevScrollpos } = this.state;
    const currentScrollPos = window.pageYOffset;
    // console.log(currentScrollPos)
    const visible =  currentScrollPos > 0 ? prevScrollpos > currentScrollPos : true
    this.setState({
      prevScrollpos: currentScrollPos,
      visible
    });
  // }
  };

    render(){
      // const {onUpdate, navHeight} = this.props
      const top =  0
      const navTop = 'nav-withoutBanner'
      const height = this.state.visible ? '100px' :'0px'
      const isVisible = this.state.visible ? 'scroll-up' : 'scroll-down'
          return(
          <AuthUserContext.Consumer>
            {authUser =>
              authUser ? (
                // 로그인했을때
                <div className={`navbar-wrapper ${isVisible}`}>                  
                  <div className={`navbar ${navTop}`} style={{top : top}}>
                    <TLPNavigationAuth authUser={authUser} isVisible={isVisible} firebase={this.props.firebase}/>
                  </div>
                </div>
              ) : (
                // 로그인 안되어있을때
                <div className={`navbar-wrapper ${isVisible}`} 
                // style={{height : height}}
                >
                  <div className={`navbar ${navTop}`} style={{top : top}} >
                    <TLPNavigationNonAuth isVisible={isVisible}/>
                  </div>

                </div>
              )
            }
          </AuthUserContext.Consumer>
    )
  }
}

//로그인 후




  const TLPNavigationAuth = ({ authUser, isVisible, firebase }) => {
  const [authMenu, setauthMenu] = useState(false)
  const [alertList, setalertList] = useState([])
  const authMenuHeight = authMenu ? 
  isVisible === 'scroll-down' ? 'authMenuHidden' : 'authMenuShow'
  : 'authMenuHidden'
  const authBackgroundColor = authMenu ? 'selectedBg' : 'unSelectedBg'
  const authIconColor = authMenu ? 'selectedIcon' : 'unSelectedIcon'
  
  useEffect(() => {
    setauthMenu(false)
  }, [isVisible])

  function getAlert() {
    //체크!!
    firebase.alerts().where('checkedAt','==','0').onSnapshot(
      {includeMetadataChanges:true},
      (result)=>{
      result.forEach((doc) => {
        var alertData= doc.data();
        alertList.push(alertData.type)
        // console.log(alertList)
      })
    })
  }

  useEffect(()=> {
    getAlert()
  },[alertList])

  function onCheckAlert (type) {
    setauthMenu(!authMenu)
    closeMobileNav()
    const typed = type === 'tlpInquiries' ? 'inquiries' : type
    // console.log(type, typed)
   if(alertList.includes(type)){
      firebase.alert(typed).set({
      checkedAt : 'checked'
    },{merge:true})
  }
  const idx = alertList.indexOf(type)
  if (idx >-1){ alertList.splice(idx, 1)}
    
  }



  // $(".resGnbBtn").click(function(){
  //   $(".resNavMenu").stop().animate({right:0}, 1000);
  //   $(".darkBg").css("display", "block");
  // });
  // $(".resNavMenu>.closeBtn>span, .darkBg").click(function(){
  //   $(".resNavMenu").stop().animate({right:"-100vw"}, 1000);
  //   $(".darkBg").css("display", "none");
  // });
  function openMobileNav (){
    $(".resNavMenu").stop().animate({right:0}, 1000);
    $(".darkBg").css("display", "block");
  }
  function closeMobileNav (){
    $(".resNavMenu").stop().animate({right:"-100vw"}, 1000);
    $(".darkBg").css("display", "none");
  }

  return(
    <FirebaseContext.Consumer>
    {firebase => (
      <Scrollspy items={ ['INTRO', 'THE_LITTLE_PRINCE', 'LEARNING_PROGRAM', 'XR_SERVICE', 'CONTACT', 'GALLERY', 'FAQ'] } style={{display:"flex"}}>
        <div className="navbarFrame">
          <div className="logo_img">
            <a className="noDeco" href={ROUTES.TLP_MAIN_INTRO}>
              <img src={logo} alt="logo" />
              <span>어린 왕자, 나를 만나다 <br/>XR Reflection Writing Program</span>
            </a>
          </div>
          <div className="headerRight">
            <div className="gnb">           
              <ul className="gnbMenu">
                <li><a className="noDeco" onClick={()=> setauthMenu(false)} href={ROUTES.TLP_MAIN_CLASSROOM_LEARNING}>『어린 왕자, 나를 만나다</a></li>
                <li><a className="noDeco" onClick={()=> setauthMenu(false)} href={ROUTES.TLP_MAIN_SELF_LEARNING}>Learning Program</a></li>
                <li><a className="noDeco" onClick={()=> setauthMenu(false)} href={ROUTES.TLP_MAIN_PHOTO}>Reflection Gallery</a></li>
                <li><a className="noDeco" onClick={()=> setauthMenu(false)} href={ROUTES.TLP_MAIN_REVIEW}>Review</a></li>
                <li><a className="noDeco" onClick={()=> setauthMenu(false)} href={ROUTES.TLP_MAIN_XR_SERVICE}>XR BOOK</a></li>
                <li style={{display:"none"}}><a className="noDeco" onClick={()=> setauthMenu(false)} href={ROUTES.TLP_MAIN_FAQ}>FAQ</a></li>
                <li className="orderBox"><Link to={ROUTES.ORDER} className='noDeco' onClick={()=> setauthMenu(false)} >Program 신청</Link></li>
                <li><a className="noDeco" onClick={()=> setauthMenu(false)} href={ROUTES.TLP_MAIN_CONTACT}>문의하기</a></li>
                {/* <li style={{display:"none"}}><Link to={ROUTES.TLP_EVENT} className='noDeco'>Event</Link></li> */}
              </ul>
            </div>
            <div className={`auth ${authBackgroundColor}`}>
              <ul>
                <li className={`authIcon ${authIconColor}`} onClick={()=> setauthMenu(!authMenu)}></li>
                {authUser.roles !==undefined && authUser.roles['member'] === 'admin' ?     
                <li className={`authMenu ${authMenuHeight}`}>
                  <ul className="authMenuBox">
                    <li><Link to={ROUTES.ADMIN_STATISTICS} className='noDeco' onClick={()=> setauthMenu(!authMenu)}>통계 관리</Link></li>
                    <li><Link to={ROUTES.ADMIN_CONTENTS} className='noDeco' onClick={()=> setauthMenu(!authMenu)}>데이터 관리</Link></li>
                    <li><Link to={ROUTES.ADMIN_MANAGEMENT} className='noDeco' onClick={()=> setauthMenu(!authMenu)}>사이트 관리</Link></li>
                    <li><Link to={ROUTES.ADMIN_EVENT} className='noDeco' onClick={()=> setauthMenu(!authMenu)}>이벤트 관리</Link></li>
                    <li><Link to={ROUTES.ADMIN_COUPON} className='noDeco' onClick={()=> setauthMenu(!authMenu)}>결제 쿠폰 관리</Link></li>
                    
                    <li><Link to={ROUTES.ADMIN_FACILITATOR} className='noDeco' onClick={()=> setauthMenu(!authMenu)}>시연 관리</Link></li>
                    <li><Link to={ROUTES.ADMIN_INQUIRIES} className='noDeco' onClick={()=> onCheckAlert('tlpInquiries')}>비회원 문의 관리
                    {alertList && alertList.includes('tlpInquiries') && <span style={{color:"red", fontWeight:"900"}}> N</span>}
                    </Link></li>
                    <li><Link to={ROUTES.ADMIN_PROGRAMS} className='noDeco' onClick={()=> onCheckAlert('tlpProgram')}>Off-line Learning 조회
                    {alertList && alertList.includes('tlpProgram') && <span style={{color:"red", fontWeight:"900"}}> N</span>}
                    </Link></li>
                    <li><Link to={ROUTES.ADMIN_SALES} className='noDeco' onClick={()=> onCheckAlert('tlpSales')}>On-line Learning 조회
                    {alertList && alertList.includes('tlpSales') && <span style={{color:"red", fontWeight:"900"}}> N</span>}
                    {alertList ? alertList.includes('tlpSalesCancel') || alertList.includes('tlpvrEvent') ? <span style={{color:"lightskyblue"}}> N</span> : null: null}
                    </Link></li>
                    {/* <li><Link to={ROUTES.ADMIN_XR_MAKING} className='noDeco' onClick={()=> onCheckAlert('tlpXrMaking')}>XR 제작 서비스
                    {alertList && alertList.includes('tlpXrMaking') && <span style={{color:"red", fontWeight:"900"}}> N</span>}
                    </Link></li> */}
                    
                    <li><Link to={ROUTES.ADMIN_APP_IMAGES} className='noDeco' onClick={()=> setauthMenu(!authMenu)}>Reflection Gallery 관리</Link></li>
                    <li><Link to={ROUTES.ADMIN_REWVIEWS} className='noDeco' onClick={()=> setauthMenu(!authMenu)}>리뷰 관리</Link></li>
                    <li><Link to={ROUTES.ADMIN_READING} className='noDeco' onClick={()=> setauthMenu(!authMenu)}>사용자 디지털 북 관리</Link></li>
                    <li><Link to={ROUTES.ADMIN_DIGITAL_BOOK} className='noDeco' onClick={()=> setauthMenu(!authMenu)}>디지털 북 제작/관리</Link></li>
                    <li><a className="noDeco" onClick={()=> firebase.doSignOut()}>LOG OUT</a></li>
                  </ul>
                </li>
                : authUser.roles !==undefined && authUser.roles['member'] === 'facilitator'?
                <ul className={`authMenu ${authMenuHeight} authMenuBox userAuthBox`} style={{bottom:'-18vh'}}>
                  <li><Link to={ROUTES.USER} className='noDeco' onClick={()=> setauthMenu(!authMenu)}>MY PAGE</Link></li>
                  <li><Link to={ROUTES.TLP_FACILITATORPAGE} className='noDeco' onClick={()=> setauthMenu(!authMenu)}>Facilitator</Link></li>
                  <li><a className="noDeco" onClick={()=> firebase.doSignOut()}>LOG OUT</a></li>
                </ul>
                :
                <ul className={`authMenu ${authMenuHeight} authMenuBox userAuthBox`}>
                <li><Link to={ROUTES.USER} className='noDeco' onClick={()=> setauthMenu(!authMenu)}>MY PAGE</Link></li>
                <li><a className="noDeco" onClick={()=> firebase.doSignOut()}>LOG OUT</a></li>
              </ul>
                }
              </ul> 
            </div>  
          </div>
        </div>
        
        <div className="darkBg" onClick={closeMobileNav}></div>

        <div className="navbarResFrame">
          <div className="resNavBar">
            <div className="logo_img">
              <a className="noDeco" href={ROUTES.TLP_MAIN_INTRO}>
                <img src={logo} alt="logo" />
                <span className="logoText">어린 왕자, 나를 만나다 <br/>XR Reflection Writing Program</span>
              </a>
            </div>
            <div className="resRight">
              <div className="resGnbBtn" onClick={openMobileNav}>
                <span className="bar1"></span>
                <span className="bar2"></span>
                <span className="bar3"></span>
              </div>
            </div>
          </div>
          <div className="resNavMenu">
            <div className="closeBtn" onClick={closeMobileNav}><span>×</span></div> 
            <div className="menuGroup">
              <ul className="gnbMenu">
                <li><a className="noDeco" href={ROUTES.TLP_MAIN_CLASSROOM_LEARNING} onClick={closeMobileNav}>어린 왕자, 나를 만나다</a></li>
                <li><a className="noDeco" href={ROUTES.TLP_MAIN_SELF_LEARNING} onClick={closeMobileNav}>Learning Program</a></li>
                <li><a className="noDeco" href={ROUTES.TLP_MAIN_PHOTO} onClick={closeMobileNav}>Reflection Gallery</a></li>
                <li><a className="noDeco" href={ROUTES.TLP_MAIN_REVIEW} onClick={closeMobileNav}>Review</a></li>
                <li><a className="noDeco" href={ROUTES.TLP_MAIN_XR_SERVICE} onClick={closeMobileNav}>XR BOOK</a></li>
                <li style={{display:"none"}}><a className="noDeco" href={ROUTES.TLP_MAIN_FAQ}>FAQ</a></li>
                <li className="orderBox"><Link to={ROUTES.ORDER} className='noDeco' onClick={closeMobileNav}>Program 신청</Link></li>
                <li><a className="noDeco" href={ROUTES.TLP_MAIN_CONTACT} onClick={closeMobileNav}>문의하기</a></li>
                {/* <li style={{display:"none"}}><Link to={ROUTES.TLP_EVENT} className='noDeco'>Event</Link></li> */}
              </ul>
              <div className="resAuthMenu2Box">
              {authUser.roles !==undefined && authUser.roles['member'] === 'admin' ?     
                <div className={`authMenu adminAuthBox`}>
                  <ul>
                    <li><Link to={ROUTES.ADMIN_STATISTICS} className='noDeco' onClick={closeMobileNav}>통계 관리</Link></li>
                    <li><Link to={ROUTES.ADMIN_CONTENTS} className='noDeco' onClick={closeMobileNav}>데이터 관리</Link></li>
                    <li><Link to={ROUTES.ADMIN_MANAGEMENT} className='noDeco' onClick={closeMobileNav}>사이트 관리</Link></li>
                    <li><Link to={ROUTES.ADMIN_EVENT} className='noDeco' onClick={closeMobileNav}>이벤트 관리</Link></li>
                    <li><Link to={ROUTES.ADMIN_COUPON} className='noDeco' onClick={closeMobileNav}>결제 쿠폰 관리</Link></li>

                    <li><Link to={ROUTES.ADMIN_FACILITATOR} className='noDeco' onClick={closeMobileNav}>시연 관리</Link></li>
                    <li><Link to={ROUTES.ADMIN_INQUIRIES} className='noDeco' onClick={()=> onCheckAlert('tlpInquiries')}>비회원 문의 관리
                    {alertList && alertList.includes('tlpInquiries') && <span style={{color:"red", fontWeight:"900"}}> N</span>}
                    </Link></li>
                    <li><Link to={ROUTES.ADMIN_PROGRAMS} className='noDeco' onClick={()=> onCheckAlert('tlpProgram')}>Off-line Learning 조회
                    {alertList && alertList.includes('tlpProgram') && <span style={{color:"red", fontWeight:"900"}}> N</span>}
                    </Link></li>
                    <li><Link to={ROUTES.ADMIN_SALES} className='noDeco' onClick={()=> onCheckAlert('tlpSales')}>On-line Learning 조회
                    {alertList && alertList.includes('tlpSales') && <span style={{color:"red", fontWeight:"900"}}> N</span>}
                    {alertList ? alertList.includes('tlpSalesCancel') || alertList.includes('tlpvrEvent') ? <span style={{color:"lightskyblue"}}> N</span> : null: null}
                    </Link></li>
                    {/* <li><Link to={ROUTES.ADMIN_XR_MAKING} className='noDeco' onClick={()=> onCheckAlert('tlpXrMaking')}>XR 제작 서비스
                    {alertList && alertList.includes('tlpXrMaking') && <span style={{color:"red", fontWeight:"900"}}> N</span>}
                    </Link></li> */}


                    <li><Link to={ROUTES.ADMIN_APP_IMAGES} className='noDeco' onClick={closeMobileNav}>Reflection Gallery 관리</Link></li>
                    <li><Link to={ROUTES.ADMIN_REWVIEWS} className='noDeco' onClick={closeMobileNav}>리뷰 관리</Link></li>
                    <li><Link to={ROUTES.ADMIN_READING} className='noDeco' onClick={closeMobileNav}>사용자 디지털 북 관리</Link></li>
                    <li><Link to={ROUTES.ADMIN_DIGITAL_BOOK} className='noDeco' onClick={closeMobileNav}>디지털 북 제작/관리</Link></li>
                    <li><a className="noDeco" onClick={()=> firebase.doSignOut()}>LOG OUT</a></li>
                  </ul>
                </div>
                : authUser.roles !==undefined && authUser.roles['member'] === 'facilitator' ?
                <div className={`authMenu userAuthBox`} >
                  <li><Link to={ROUTES.TLP_FACILITATORPAGE} className='noDeco' onClick={closeMobileNav}>Facilitator</Link></li>
                  <ul>
                    {/* <li><Link to={ROUTES.SERVICE_CENTER} className='noDeco' onClick={closeMobileNav}>고객센터</Link></li> */}
                    
                    <li><Link to={ROUTES.USER} className='noDeco' onClick={closeMobileNav}>MY PAGE</Link></li>  
                    <li><a className="noDeco" onClick={()=> firebase.doSignOut()}>LOG OUT</a></li>
                    <li><span onClick={closeMobileNav}><FontAwesomeIcon icon={faLongArrowAltRight}/></span></li>
                  </ul>
                </div>
                : <div className={`authMenu userAuthBox`}>
                <ul>
                  {/* <li><Link to={ROUTES.SERVICE_CENTER} className='noDeco' onClick={closeMobileNav}>고객센터</Link></li> */}
                  <li><Link to={ROUTES.USER} className='noDeco' onClick={closeMobileNav}>MY PAGE</Link></li>
                  <li><a className="noDeco" onClick={()=> firebase.doSignOut()}>LOG OUT</a></li>
                  <li><span onClick={closeMobileNav}><FontAwesomeIcon icon={faLongArrowAltRight}/></span></li>
                </ul>
              </div>
                }
              </div>
            </div>        
          </div>
        </div>
       
      </Scrollspy>
    )}
    </FirebaseContext.Consumer>
)};

//로그인 전
const TLPNavigationNonAuth = ({isVisible}) => {
 const [nonAuthMenu, setnonAuthMenu] = useState(false)
 const nonAuthMenuHeight = nonAuthMenu ? 
  isVisible === 'scroll-down' ? 'nonAuthMenuHidden' : 'nonAuthMenuShow'
  : 'nonAuthMenuHidden'
  const authBackgroundColor = nonAuthMenu ? 'selectedBg' : 'unSelectedBg'
  const authIconColor = nonAuthMenu ? 'selectedIcon' : 'unSelectedIcon'
  useEffect(() => {
    setnonAuthMenu(false)
  }, [isVisible])


  // $(".resGnbBtn").click(function(){
  //   $(".resNavMenu").stop().animate({right:0}, 1000);
  //   $(".darkBg").css("display", "block");
  // });
  // $(".resNavMenu>.closeBtn>span, .darkBg").click(function(){
  //   $(".resNavMenu").stop().animate({right:"-100vw"}, 1000);
  //   $(".darkBg").css("display", "none");
  // });
  function openMobileNav (){
    $(".resNavMenu").stop().animate({right:0}, 1000);
    $(".darkBg").css("display", "block");
  }
  function closeMobileNav (){
    $(".resNavMenu").stop().animate({right:"-100vw"}, 1000);
    $(".darkBg").css("display", "none");
  }

  return (
      <React.Fragment>      
          <Scrollspy items={ ['INTRO', 'THE_LITTLE_PRINCE', 'LEARNING_PROGRAM', 'XR_SERVICE', 'GALLERY', 'REVIEW', 'FAQ','CONTACT'] } style={{display:"flex"}}>
            <div className="navbarFrame">
              <div className="logo_img">
                <a className="noDeco" href={ROUTES.TLP_MAIN_INTRO} >
                  <img src={logo} alt="logo" />
                  <span>어린 왕자, 나를 만나다 <br/>XR Reflection Writing Program</span>
                </a>
              </div>
              <div className="headerRight">
                <div className="gnb">
                  <ul className="gnbMenu">
                    <li><a className="noDeco" onClick={()=> setnonAuthMenu(false)} href={ROUTES.TLP_MAIN_CLASSROOM_LEARNING}>어린 왕자, 나를 만나다</a></li>
                    <li><a className="noDeco" onClick={()=> setnonAuthMenu(false)} href={ROUTES.TLP_MAIN_SELF_LEARNING}>Learning Program</a></li>
                    <li><a className="noDeco" onClick={()=> setnonAuthMenu(false)} href={ROUTES.TLP_MAIN_PHOTO}>Reflection Gallery</a></li>
                    <li><a className="noDeco" onClick={()=> setnonAuthMenu(false)} href={ROUTES.TLP_MAIN_REVIEW}>Review</a></li>
                    <li><a className="noDeco" onClick={()=> setnonAuthMenu(false)} href={ROUTES.TLP_MAIN_XR_SERVICE}>XR BOOK</a></li>
                    <li style={{display:"none"}}><a className="noDeco" onClick={()=> setnonAuthMenu(false)} href={ROUTES.TLP_MAIN_FAQ}>FAQ</a></li>
                    <li className="orderBox"><Link to={ROUTES.ORDER} className='noDeco' onClick={()=> setnonAuthMenu(false)} >Program 신청</Link></li>
                    <li><a className="noDeco" onClick={()=> setnonAuthMenu(false)} href={ROUTES.TLP_MAIN_CONTACT}>문의하기</a></li>
                    {/* <li style={{display:"none"}}><Link to={ROUTES.TLP_EVENT} className='noDeco'>Event</Link></li> */}
                  </ul>
                </div>
                <div className={`auth ${authBackgroundColor}`}>
                  <ul>
                    <li className={`authIcon ${authIconColor}`} onClick={()=> setnonAuthMenu(!nonAuthMenu)}></li>
                    <li className={`nonAuthMenu ${nonAuthMenuHeight}`}>
                      <ul className="authMenuBox">
                        {/* <li><Link to={ROUTES.SERVICE_CENTER} className='noDeco' onClick={()=> setnonAuthMenu(!nonAuthMenu)}>고객센터</Link></li> */}
                        
                        <li><Link to={ROUTES.LOGIN} className='noDeco' onClick={()=> setnonAuthMenu(!nonAuthMenu)}>LOGIN</Link></li>
                        <li><Link to={ROUTES.SIGN_UP} className='noDeco' onClick={()=> setnonAuthMenu(!nonAuthMenu)}>회원가입</Link></li>
                        <li><Link to={ROUTES.XRBOOK_NONAUTH} className='noDeco' onClick={()=> setnonAuthMenu(!nonAuthMenu)}>기업회원 페이지</Link></li>
                        <li><Link to={ROUTES.XRBOOK_INSTITUTEAUTH} className='noDeco' onClick={()=> setnonAuthMenu(!nonAuthMenu)}>교육회원 페이지</Link></li>
                      </ul>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            
            <div className="darkBg" onClick={closeMobileNav}></div>

            <div className="navbarResFrame">
              <div className="resNavBar">
                <div className="logo_img">
                  <a className="noDeco" href={ROUTES.TLP_MAIN_INTRO} >
                    <img src={logo} alt="logo" />
                    <span className="logoText">어린 왕자, 나를 만나다 <br/>XR Reflection Writing Program</span>
                  </a>
                </div>
                <div className="resRight">
                  <div className="resGnbBtn" onClick={openMobileNav}>
                    <span className="bar1"></span>
                    <span className="bar2"></span>
                    <span className="bar3"></span>
                  </div>
                </div>
              </div>
              <div className="resNavMenu">
                <div className="closeBtn" onClick={closeMobileNav}><span>×</span></div>
                <div className="menuGroup">
                  <ul className="gnbMenu">
                    <li><a className="noDeco"  onClick={closeMobileNav} href={ROUTES.TLP_MAIN_CLASSROOM_LEARNING}>어린 왕자, 나를 만나다</a></li>
                    <li><a className="noDeco"  onClick={closeMobileNav} href={ROUTES.TLP_MAIN_SELF_LEARNING}>Learning Program</a></li>
                    <li><a className="noDeco"  onClick={closeMobileNav} href={ROUTES.TLP_MAIN_PHOTO}>Reflection Gallery</a></li>
                    <li><a className="noDeco"  onClick={closeMobileNav} href={ROUTES.TLP_MAIN_REVIEW}>Review</a></li>
                    <li><a className="noDeco"  onClick={closeMobileNav} href={ROUTES.TLP_MAIN_XR_SERVICE}>XR BOOK</a></li>
                    <li style={{display:"none"}}><a className="noDeco"  onClick={closeMobileNav} href={ROUTES.TLP_MAIN_FAQ}>FAQ</a></li>
                    <li className="orderBox"><Link to={ROUTES.ORDER} className='noDeco'  onClick={closeMobileNav}>Program 신청</Link></li>
                    {/* <li style={{display:"none"}}><Link to={ROUTES.TLP_EVENT} className='noDeco' >Event</Link></li> */}
                    <li><a className="noDeco"  onClick={closeMobileNav} href={ROUTES.TLP_MAIN_CONTACT}>문의하기</a></li>
                  </ul>
                  <div>
                    <ul className="resAuthMenuBox">
                      <li><Link to={ROUTES.LOGIN} className='noDeco'  onClick={closeMobileNav}>LOGIN</Link></li>
                      <li><Link to={ROUTES.SIGN_UP} className='noDeco'  onClick={closeMobileNav}>회원가입</Link></li>
                      <li><Link to={ROUTES.XRBOOK_NONAUTH} className='noDeco' onClick={closeMobileNav}>기업회원 페이지</Link></li>
                      <li><Link to={ROUTES.XRBOOK_INSTITUTEAUTH} className='noDeco' onClick={closeMobileNav}>교육회원 페이지</Link></li>
                    </ul>
                    <div className="closeArrowBtn"><span onClick={closeMobileNav}><FontAwesomeIcon icon={faLongArrowAltRight}/></span></div>
                  </div>
                </div>
              </div>
            </div>
           </Scrollspy>    
     </React.Fragment>

)};

export default withFirebase(TLPNavigation);

import React, {useState, useEffect, Component} from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../../Session';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import * as ROLES from '../../../constants/roles';
import FacilitatorList from './FacilitatorList/FacilitatorList'
import UserList from './UserList/UserList'
import VideoList from '../../Facilitator/VideoList'
import './index.css'

import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import { getTimeString, getSelectDays } from '../../helpers';


import $ from "jquery";

const AdminFacilitatorPage = props => {
  const [one, onOne] = useState('contents_show');
  const [two, onTwo] = useState('contents_hidden');
  const [three, onThree] = useState('contents_hidden');
  const [show, onShow] = useState('videoManaging');
  const [digitalbookList, ondigitalbookList] = useState([]);
  const [digitalbookID, ondigitalbookID] = useState('');

  const [modalShow, onModalShow] = useState(false);

  function First() {
    onShow('videoManaging');
    onOne('contents_show');
    onTwo('contents_hidden');
    onThree('contents_hidden');
    $(".adminFacilitatorFrame>div>.FacilitatorBtnGroup div").css({"border":"1px solid #dedede", "color":"#aaaaaa"});
    $(".adminFacilitatorFrame>div>.FacilitatorBtnGroup div").eq(0).css({"border-bottom":0,"color":"#000000" });    
  }
  function Second() {
    onShow('facilitator');
    onOne('contents_hidden');
    onTwo('contents_show');
    onThree('contents_hidden');
    $(".adminFacilitatorFrame>div>.FacilitatorBtnGroup div").css({"border":"1px solid #dedede", "color":"#aaaaaa"});
    $(".adminFacilitatorFrame>div>.FacilitatorBtnGroup div").eq(1).css({"border-bottom":0,"color":"#000000" });
  }
  function Third() {
    onShow('userList');
    onOne('contents_hidden');
    onTwo('contents_hidden');
    onThree('contents_show');
    $(".adminFacilitatorFrame>div>.FacilitatorBtnGroup div").css({"border":"1px solid #dedede", "color":"#aaaaaa"});
    $(".adminFacilitatorFrame>div>.FacilitatorBtnGroup div").eq(2).css({"border-bottom":0,"color":"#000000" });
  }
  // useEffect(() => {
  //   getdigitalbookList()
  // }, [])
  function getdigitalbookList(){
    props.firebase.libraries().get()
    .then(result=>{
      let digitalbookList = []
      result.forEach((doc) => {
          var bid = doc.id
          var listData= doc.data();
          digitalbookList.push({bid, ...listData})
          })
          ondigitalbookList(digitalbookList)
  })
    .catch(err=> alert(`잠시 후 다시 시도 해주세요.\n에러 : ${err}`) )
  }
  function OpenScheduleModal (e){
    // e.preventDefault()
    onModalShow(true)
  }
  return (
    <div className="adminFacilitatorFrame">
        <h3>어린 왕자, 나를 만나다</h3>
        
        <div>
          {/* 탭 부분 */}
          <div className="FacilitatorBtnGroup">
            <div className={`${one}`} onClick={()=>First()} >시연 관련</div>
            <div className={`${two}`} onClick={()=>Second()} >강사 관리</div>
            <div className={`${three}`} onClick={()=>Third()} >유저 관리</div>
          </div>
          {/* 탭에서 선택된 내용 부분 */}
          <div className="FacilitatorContGroup" style={{border:"none"}}>
            {show === 'videoManaging' ?
            <React.Fragment>
              <span>
                {/* <select name='digitalbookID' value={digitalbookID} onChange={(e)=>ondigitalbookID(e.target.value)}>
                          <option value=''>디지털북 없음</option>
                          {digitalbookList && digitalbookList.map(c=>{
                              let { bid, bookTarget, bookTitle }  = c
                              return(
                                  <option key={bid} value={bid}>{bookTarget ==='미입력' || bookTarget === undefined ? `` : `(${bookTarget}) `}{bookTitle ==='' ? `이름 없음` : bookTitle}
                                  </option>
                              )
                          })}             
                      </select>
                      {digitalbookID ==='' ?<a onClick={()=>alert('디지털북을 선택해주세요.')}>디지털북 보기</a> :<a href={`https://www.xrbook.net/digitalBook/example/${digitalbookID}`} target='_blank'> 디지털북 보기</a>} */}
                <a href={`https://www.xrbook.net/digitalBook/example/DszV2oNnElAIvzlaoh7v`} target='_blank'> 시연용 chapter1 디지털북 보기</a><br/><br/>
                <a href={`https://www.xrbook.net/digitalBook/example/wlOna2V7iOHrve526Ee5`} target='_blank'> 시연용 scene1 디지털북 보기</a><br/><br/>
                <a onClick={()=>OpenScheduleModal()}>애니메이션 페이지 오픈 일정 관리</a>
                </span>
                {modalShow && <DateItem onModalShow={onModalShow} firebase={props.firebase}/>}
                <VideoList marginTop={false}/>
            </React.Fragment>
              
            : show === 'facilitator' ? 
            <FacilitatorList/>
            : show === 'userList' ? 
            <UserList/>
             :
            null}
          </div>
        </div>
    </div>

  )};
  
  class DateItem extends Component {
    constructor(props) {
      super(props);
      this.handleDayClick = this.handleDayClick.bind(this);
    }
    state = {
      selectedDays: [],
    }
    componentDidMount(){
      this.onGetSelectDays()
    }
    handleDayClick(day, { selected }) {
      const { selectedDays } = this.state;
      if (selected) {
        const selectedIndex = selectedDays.findIndex((selectedDay) =>
          DateUtils.isSameDay(selectedDay, day)
        );
        selectedDays.splice(selectedIndex, 1);
      } else {
        selectedDays.push(day);
      }
      this.setState({ selectedDays });
      // console.log(selectedDays)
    }
    onGetSelectDays = () => {
      this.props.firebase.animationPageOpen().get()
      .then((doc)=>{
        let selectedDays = []
        let result = doc.data()
        // console.log(result)
        if (result.selectedDays !== [] && result.selectedDays !== undefined ) {
          result.selectedDays.map((c) => {
            let dateFormat = getSelectDays(c)
            selectedDays.push(dateFormat);
          })
        } 
        this.setState({selectedDays})
      })
    }
    onSubmit = () => {
      let {uid, selectedDays} = this.state
      let days = selectedDays.map(c=>getTimeString(c))
      this.props.firebase.animationPageOpen().set({selectedDays: days})
      .then(()=>{
        this.setState({selectedDays: days})
        alert('변경사항이 저장되었습니다.')
        this.props.onModalShow(false)
      })
    }
    
    render() {
      let {selectedDays} = this.state
      let {onPropsChange, onModalShow} = this.props
      // console.log(this.state)
      return (
        <div className='rolesChangeBg'>
          <div className='rolesChangeBx'>
            <button onClick={()=>onModalShow(false)}>X</button>
            <div className='rolesChangeInfo'>
            <DayPicker selectedDays={selectedDays} onDayClick={this.handleDayClick} />
            </div>
            <div className="btnGroup">
              <button onClick={()=>this.onSubmit()}>저장</button>
              <button onClick={()=>onModalShow(false)}>취소</button>
            </div>
          </div>
        </div>
      )
    }
  }
  
  const condition = authUser =>
  authUser && authUser.roles[ROLES.MEMBER] === ROLES.ADMIN;
  

  
  export default compose(
    withEmailVerification,
    withAuthorization(condition),
  )(AdminFacilitatorPage);

import React, { Component } from 'react'
import {withFirebase} from '../../../Firebase'
import DayPicker, { DateUtils } from 'react-day-picker';
import 'react-day-picker/lib/style.css';
import {isEmail} from '../../../helpers'
import {getTimeDayjs} from '../../../helpers'

class SalesApplied extends Component {
    constructor(props) {
        super(props);
        this.handleDayClick = this.handleDayClick.bind(this);
      }
      
    state = {
        selectedDays: this.props.appliedList.selectedDays,
        appliedList : this.props.appliedList,
        detailShow:'none',
        code : this.props.appliedList.code || '-',
        isEnroll:false,
        readerName:'',
        readerEmail:'',
        isEmailValid:'',
        readerList:[]
    }
    // componentDidMount(){
    //     if(this.state.appliedList !==[]){
    //     const {uid, programInfo}= this.state.appliedList
    //     this.getReaderList(uid, programInfo)
    // }
    // }
    handleDayClick(day, { selected }) {
        const { selectedDays, appliedList } = this.state;
        const list = appliedList
        if (selected) {
          const selectedIndex = selectedDays.findIndex(selectedDay =>
            DateUtils.isSameDay(selectedDay, day)
          );
          selectedDays.splice(selectedIndex, 1);
        } else {
          selectedDays.push(day);
        }
        list['selectedDays'] = selectedDays
        this.setState({ selectedDays, appliedList:list });
      }

        onListStatechange = (e, list) => {
            const targetName = e.target.name
            // console.log(targetName)
            const targetValue=e.target.value
            if(targetName === 'memo'){
                list[targetName] = targetValue.replace(/(?:\r\n|\r|\n)/g, '<br />')
                } else if(targetName === 'price'){
                    list[targetName] = targetValue.replace(/[^0-9]/g,"")
                } else {
                    list[targetName] =targetValue
                }
        this.setState({
            appliedList: list
        })
    }
    onStateChange = (e) => {
        this.setState({
                [e.target.name]: e.target.value
        })
    }
     editProgramInfo =(uid)=>{
        const {appliedList} = this.state
        const {oneditList} = this.props
        oneditList(uid, appliedList)
     }
     onToggle = () => {
        const {detailShow} = this.state;
        if(detailShow === 'none'){
       this.setState({
           detailShow : 'show'
   })} else if(detailShow === 'show'){
       this.setState({
           detailShow : 'none'
   })}
    }
    onStateToggle = (name, value) =>{
        const {uid, programInfo}= this.state.appliedList
        this.setState({
            [name]: value
        })
        if(name==='isEnroll'&& value === true){

            this.getReaderList(uid, programInfo)
        }
    }
    getCode = async() => {
        const {appliedList,code} = this.state;
        this.setState({code : '코드 재 발급 중. 적용 버튼을 누르셔야 저장됩니다.'})
        const list = appliedList
        const data = { 
            userId : appliedList['authUserId'],
            createdAt : appliedList['createdAt'],
            page : appliedList['page'],
        }
        // console.log(appliedList)
        await fetch("/api/v1/createCode", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        })
        .then(response => { response.json()
            .then((res) => {

                const code = res['code']
                const codeAt = res['codeAt']
                list['code'] = code
                list['codeAt'] = codeAt
                this.setState({code, appliedList:list })
            })
            .catch(err => console.log(err))
          })
        .catch(err => console.log(err))
    }

    handleEmailInput = e => {
        // e.preventDefault()
        let value = e.target.value
                if(isEmail(value)){
                    this.setState({
                        readerEmail : value,
                        isEmailValid : true
                    })
                }else {
                    this.setState({
                        readerEmail : value,
                        isEmailValid : false
                    })
                }     
        }

    render() {
        // console.log(this.state.readerList)
        const { appliedList, detailShow, selectedDays, code
         } = this.state
        // console.log(appliedList)
        const {title, programInfo, progressAt, memo, contents, manager, groupTitle,
             participants, progress, email, number, uid , target, price, period,
              vrRent, vrEvent, type}= appliedList
        const programTitle = `${title} : ${target}대상`
        // console.log(progressAt,editedAt)
        const isProgressAt = getTimeDayjs(progressAt)
        const enteredMemo =  memo.replace(/(<br>|<br\/>|<br \/>)/g, '\n') 
        const isPrice = price.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        const days = selectedDays
        const dayLeng = days.length
        const vrRental = vrRent ? '기기 체험' : vrEvent? '구매': '체험 불포함'
        return (
            <div className="salesListFrame">
                <ul className="salesListCont salesList1Cont">
                    <li>{manager}</li>
                    <li>{groupTitle}</li>
                    <li>{programTitle}</li>
                    <li>{participants}</li>
                    <li>{period}</li>
                    <li>
                        {vrRental}
                        </li>
                    <li>{type}</li>
                    <li>
                        <select name='progress' value={progress} onChange={e=> this.onListStatechange(e, appliedList)}>
                            <option value='applied'>문의 완료</option>
                            <option value='consulting'>담당자 배정</option>
                            <option value='finished'>쿠폰 발행</option>
                            <option value='canceled'>쿠폰 미발행</option>
                        </select>
                    </li>

                    <li>
                        {isProgressAt}
                    </li>
                    <li>
                        <button onClick={() => this.editProgramInfo(uid)}>적용</button>
                        <div onClick={this.onToggle}>
                        {detailShow === "none"
                            ? `상세보기`
                            : detailShow === "show"
                            ? `상세닫기`
                            : `상세정보`}
                        </div>
                        
                    </li>
                </ul>   
                <ul className={`${detailShow} detailBox`}>
                    <li>
                        <span>연락처</span>
                        <span>{email}</span>
                        <span>{number}</span>
                    </li>
                    <li className="ReguireText">
                        {contents && contents.split('<br />').map((c, id)=> {
                            return(
                                <span key={id}>{c}</span>
                            )})}</li>

                    <li className="manegeText">
                        <span>담당자/상세내용</span>
                        <textarea name='memo' value={enteredMemo} onChange={e=> this.onListStatechange(e, appliedList)}/>
                    </li>
                    {/* <div>가격 : <input type='text' name='price' value={isPrice} onChange={e=> this.onListStatechange(e, appliedList)}/></div>
                    <div>기간 : <DayPicker
                            selectedDays={days}
                            onDayClick={this.handleDayClick}
                            /></div>
                    <div>총 기간 : {dayLeng || 0} 일</div>
                        <button onClick={this.getCode} disabled={!selectedDays.length}>코드 / 코드생성</button>
                    <div>코드 : {code}</div> */}
                </ul>
                    
                
            </div>
        );
    }
}

export default withFirebase(SalesApplied)

import React, {useState, useEffect} from 'react';
import ChapterItem from './ChapterItem'
import './Gallery.css'
import './GalleryRes.css'

const Collage = props => {
    const [one, onOne] = useState('adminProgram_show');
    const [two, onTwo] = useState('adminProgram_hidden');
    const [three, onThree] = useState('adminProgram_hidden');
    const [four, onFour] = useState('adminProgram_hidden');
    const [five, onFive] = useState('adminProgram_hidden');
    const [six, onSix] = useState('adminProgram_hidden');
    const [arActivity, onarActivity] = useState('adminProgram_hidden');
    const [show, onShow] = useState('chapter1');
    const [statistics, setstatistics] = useState({})
  
    function First() {
      onShow('chapter1')
      onOne('adminProgram_show')
      onTwo('adminProgram_hidden')
      onThree('adminProgram_hidden')
      onFour('adminProgram_hidden')
      onFive('adminProgram_hidden')
      onSix('adminProgram_hidden')
      onarActivity('adminProgram_hidden')
    }
    function Second() {
      onShow('chapter2')
      onOne('adminProgram_hidden')
      onTwo('adminProgram_show')
      onThree('adminProgram_hidden')
      onFour('adminProgram_hidden')
      onFive('adminProgram_hidden')
      onSix('adminProgram_hidden')
      onarActivity('adminProgram_hidden')
    }
    function Third() {
    onShow('chapter3')
    onOne('adminProgram_hidden')
    onTwo('adminProgram_hidden')
    onThree('adminProgram_show')
    onFour('adminProgram_hidden')
    onFive('adminProgram_hidden')
    onSix('adminProgram_hidden')
    onarActivity('adminProgram_hidden')
    }
    function Fourth() {
      onShow('chapter4')
      onOne('adminProgram_hidden')
      onTwo('adminProgram_hidden')
      onThree('adminProgram_hidden')
      onFour('adminProgram_show')
      onFive('adminProgram_hidden')
      onSix('adminProgram_hidden')
      onarActivity('adminProgram_hidden')
      }
    function Fifth() {
        onShow('chapter5')
        onOne('adminProgram_hidden')
        onTwo('adminProgram_hidden')
        onThree('adminProgram_hidden')
        onFour('adminProgram_hidden')
        onFive('adminProgram_show')
        onSix('adminProgram_hidden')
        onarActivity('adminProgram_hidden')
        }
    function Sixth() {
      onShow('chapter6')
      onOne('adminProgram_hidden')
      onTwo('adminProgram_hidden')
      onThree('adminProgram_hidden')
      onFour('adminProgram_hidden')
      onFive('adminProgram_hidden')
      onSix('adminProgram_show')
      onarActivity('adminProgram_hidden')
      }
    function ArActivity() {
      onShow('activity')
      onOne('adminProgram_hidden')
      onTwo('adminProgram_hidden')
      onThree('adminProgram_hidden')
      onFour('adminProgram_hidden')
      onFive('adminProgram_hidden')
      onSix('adminProgram_hidden')
      onarActivity('adminProgram_show')
      }
    return (
      <React.Fragment>
      <div className="galleryOutFrame">
        <div className="galleryInFrame">
          <div className="galleryTitleBox">
            <ul>
                <li className="contTitleText">Reflection Gallery</li>
                <li className="contentText">
                  <span className={`pagerTitle4 `} 
                  style={show === 'chapter1' 
                  ? {color: '#60c0bf', borderBottom:'1px solid #60c0bf', borderTop :'1px solid #60c0bf'}
                  : {color:'#000f1e', borderBottom:'1px solid #eeeeee', borderTop :'1px solid #eeeeee' }}
                  
                  onClick={()=>First()}>Chapter1 "어린 왕자를 만나다"</span>
                  
                  <span className={`pagerTitle4 `} 
                  style={show === 'chapter2' 
                  ? {color: '#60c0bf', borderBottom:'1px solid #60c0bf', borderTop :'1px solid #60c0bf'}
                  : {color:'#000f1e', borderBottom:'1px solid #eeeeee', borderTop :'1px solid #eeeeee' }}
                  
                  onClick={()=>Second()}>Chapter2 "소행성 사람들을 만나다"</span>
                  
                  <span className={`pagerTitle4 `} 
                  style={show === 'chapter3' 
                  ? {color: '#60c0bf', borderBottom:'1px solid #60c0bf', borderTop :'1px solid #60c0bf'}
                  : {color:'#000f1e', borderBottom:'1px solid #eeeeee', borderTop :'1px solid #eeeeee' }}
                  
                  onClick={()=>Third()}>Chapter3 "친구를 만나다"</span>
                  
                  <span className={`pagerTitle4 `} 
                  style={show === 'chapter4' 
                  ? {color: '#60c0bf', borderBottom:'1px solid #60c0bf', borderTop :'1px solid #60c0bf'}
                  : {color:'#000f1e', borderBottom:'1px solid #eeeeee', borderTop :'1px solid #eeeeee' }}
                  
                  onClick={()=>Fourth()}>Chapter4 "나를 만나다"</span>
                  
                  {/* 양그림 */}
                  <span className={`pagerTitle4 `} 
                  style={show === 'chapter5' 
                  ? {color: '#60c0bf', borderBottom:'1px solid #60c0bf', borderTop :'1px solid #60c0bf'}
                  : {color:'#000f1e', borderBottom:'1px solid #eeeeee', borderTop :'1px solid #eeeeee' }}
                  
                  onClick={()=>Fifth()}>Epilogue "나의 어린 왕자"</span>

                  {/* 강의진행 */}
                  <span className={`pagerTitle4 `} 
                  style={show === 'chapter6' 
                  ? {color: '#60c0bf', borderBottom:'1px solid #60c0bf', borderTop :'1px solid #60c0bf'}
                  : {color:'#000f1e', borderBottom:'1px solid #eeeeee', borderTop :'1px solid #eeeeee' }}
                  
                  onClick={()=>Sixth()}>Photo Gallery</span>
                  
                  {/* <span className={`pagerTitle4 `} 
                  style={show === 'activity' 
                  ? {color: '#60c0bf', borderBottom:'1px solid #60c0bf', borderTop :'1px solid #60c0bf'}
                  : {color:'#000f1e', borderBottom:'1px solid #eeeeee', borderTop :'1px solid #eeeeee' }}
                  
                  onClick={()=>ArActivity()}>AR Activity</span> */}
                </
                li>
            </ul>
          </div>
                  {show === 'chapter1' ? <ChapterItem chapter={1}/> :
                  show === 'chapter2' ? <ChapterItem chapter={2}/> :
                  show === 'chapter3' ? <ChapterItem chapter={3}/> : 
                  show === 'chapter4' ? <ChapterItem chapter={4}/> :
                  show === 'activity' ? <ChapterItem chapter={false}/> :
                  show === 'chapter5' ? <ChapterItem chapter={5}/> :
                  show === 'chapter6' ? <ChapterItem chapter={6}/> :
                  null}
        </div>
      </div>
      </React.Fragment>
    );
};

export default Collage
import React, { Component } from 'react'
import { Switch, Route, Link, Redirect } from 'react-router-dom'
import {withFirebase} from '../../Firebase'
import * as ROUTES from '../../../constants/routes'
import TLPNavigationNonAuth from '../../TLP/TLPNavigation/TLPNavigationNonAuth'
import NonAuthLogin from './NonAuthLogin'
import NonAuthMain from './NonAuthMain'
import NonAuthReader from './NonAuthReader'
import NonUserCover from './NonUserCover'
import NonUserReview from './NonUserReview'
import NonUserCheck from './Check/NonUserCheck'

import NotFound from '../../TLP/NotFound'
import './NonAuth.css'
import logo from '../images/XRBOOKLogo.png'

class NonAuth extends Component {
    state = {
    }
    render() {
        return (
            <React.Fragment>
            <Switch>
                <Route exact path={ROUTES.XRBOOK_NONAUTH} render ={()=>(<Redirect to={ROUTES.XRBOOK_NONAUTH_LOGIN}/>)}/>
            
                <Route
                path="/digitalBook/class/home/(.+)"
                render ={()=>(
                <React.Fragment>
                    <TLPNavigationNonAuth/>
                    <Switch>
                        <Route exact path={ROUTES.XRBOOK_NONAUTH_LOGIN} component={NonAuthLogin} />
                        <Route path={ROUTES.XRBOOK_NONAUTH_HOME} component={NonAuthLogin} />
                        <Route path ='*' component={NotFound} />
                    </Switch>
                </React.Fragment>
                )}/>
                <Route
                    path="/digitalBook/class/book/(.+)"
                    render ={()=>(
                <Switch>
                    <Route exact path={ROUTES.XRBOOK_NONAUTH_WRITING} component={NonAuthReader} />
                    <Route path={ROUTES.XRBOOK_NONAUTH_COVER} component={NonUserCover} />
                    <Route path={ROUTES.XRBOOK_NONAUTH_REVIEW} component={NonUserReview} />
                    <Route path={ROUTES.XRBOOK_NONAUTH_CHECK} component={NonUserCheck} />
                    <Route path ='*' component={NotFound} />
                </Switch>
                )}/>
                <Route path ='*' component={NotFound} />
            </Switch>
            </React.Fragment>
        )
    }
}
export default withFirebase(NonAuth)

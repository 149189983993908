import React, { useState, useEffect } from "react";
import VrRent from "./VrRent";
import ClassInfo from "./ClassInfo";
import SelfInfo from "./SelfInfo";
import Manual from "./Manual";
import "./Guide.css";
import "./GuideResp.css";
import $ from "jquery";

const Guide = (props) => {
  const [show, onShow] = useState(props.location.state !== undefined ? 
    props.location.state.tab : "Manual");
    useEffect(() => {
        try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    };
    }, [])
    useEffect(() => {
        $(`.userGuidesFrame>div>.userGuidesTabBtnGroup> div`).css({
            borderBottom: "1px solid #dedede",
            color: "#aaaaaa",
          });
          $(`.userGuidesFrame>div>.userGuidesTabBtnGroup>.${show}`)
          .css({ "border-bottom": "1px solid rgba(0,0,0,0)", color: "#000000" });
      }, [show])
  function First() {
    onShow("SelfInfo");
    $(".userGuidesFrame>div>.userGuidesTabBtnGroup div").css({
      borderBottom: "1px solid #dedede",
      color: "#aaaaaa",
    });
    $(".userGuidesFrame>div>.userGuidesTabBtnGroup div")
      .eq(0)
      .css({ "border-bottom": "1px solid rgba(0,0,0,0)", color: "#000000" });
  }
  function Second() {
    onShow("ClassInfo");
    $(".userGuidesFrame>div>.userGuidesTabBtnGroup div").css({
      borderBottom: "1px solid #dedede",
      color: "#aaaaaa",
    });
    $(".userGuidesFrame>div>.userGuidesTabBtnGroup div")
      .eq(1)
      .css({ "border-bottom": "1px solid rgba(0,0,0,0)", color: "#000000" });
  }
  function Third() {
    onShow("Manual");
    $(".userGuidesFrame>div>.userGuidesTabBtnGroup div").css({
      borderBottom: "1px solid #dedede",
      color: "#aaaaaa",
    });
    $(".userGuidesFrame>div>.userGuidesTabBtnGroup div")
      .eq(2)
      .css({ "border-bottom": "1px solid rgba(0,0,0,0)", color: "#000000" });
  }
  function Fourth() {
    onShow("VrRent");
    $(".userGuidesFrame>div>.userGuidesTabBtnGroup div").css({
      borderBottom: "1px solid #dedede",
      color: "#aaaaaa",
    });
    $(".userGuidesFrame>div>.userGuidesTabBtnGroup div")
      .eq(3)
      .css({ "border-bottom": "1px solid rgba(0,0,0,0)", color: "#000000" });
  }
  
  return (
    <div className="userGuidesFrame">
      <div>
        <div className="userGuidesTabBtnGroup">
          <div className={`SelfInfo`} onClick={() => First()}>
            온라인 수강 가이드
          </div>
          <div className={`ClassInfo`} onClick={() => Second()}>
            오프라인 수강 가이드
          </div>
          <div className={`Manual`} onClick={() => Third()}>
            <span>VR / ARApp </span>
            <span>사용 방법</span>
          </div>
          {/* <div className={`VrRent`} onClick={() => Fourth()}>
             <span>VR</span> 
             <span>기기 체험</span>
          </div> */}
        </div>
        <div className="userGuidesTabContGroup">
          {show === "SelfInfo" ? (
            <SelfInfo headShow={true}/>
          ) : show === "ClassInfo" ? (
            <ClassInfo headShow={true}/>
          ) : show === "Manual" ? (
            <Manual headShow={true}/>
          ) : show === "VrRent" ? (
            <VrRent headShow={true}/>
          ) : 
          null}
        </div>
      </div>
    </div>
  );
};


export default Guide;

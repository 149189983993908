import React, { Component, useEffect, useState } from "react";
import { getTimeString, getTimeDayjs } from '../../helpers'
import { withFirebase } from "../../Firebase";
import "./index.css";

class InstituteList extends Component {
  state = {
    showUserModal: false,
    showUserListModal: false,
    detailShow: 'none',
    enteredMemo: this.props.instituteInfo.enteredMemo,
    digitalbookId: this.props.instituteInfo.digitalbookId,
    isDigitalbook: this.props.instituteInfo.isDigitalbook,
    is360VideoOpen: this.props.instituteInfo.is360VideoOpen,
    isVideoOpen: this.props.instituteInfo.isVideoOpen,
    userList:[],
    showList:[],
    order: '등록순',
    excelUrl : 'https://firebasestorage.googleapis.com/v0/b/xr-book.appspot.com/o/tlp%2FletcureImg%2F%EA%B4%80%EB%A6%AC%EC%9E%90%2F%5B%EB%82%B4%EB%B6%80%5Dxrbook_%EA%B5%90%EC%9C%A1%ED%9A%8C%EC%9B%90%EB%93%B1%EB%A1%9D%ED%8F%BC.xlsx210901181548?alt=media&token=afabe9dc-9786-4482-a87a-d9ec21e0d131',
    readerName:'',
    readerEmail : '',
    readerType : 'S',
    registeredNum : ""
  };
  
  onStateChange = (e) => {
    console.log(e.target.name, e.target.value)
    if(e.target.name === 'digitalbookId'){
      this.setState({
        digitalbookId: e.target.value,
        isDigitalbook : e.target.value === '' ? false : true
      });
    } else  {
      this.setState({
        [e.target.name]: e.target.value,
      });
    }
    
  };
  onToggle = () => {
    const { detailShow } = this.state;
    if (detailShow === "none") {
      this.setState({
        detailShow: "show",
      });
    } else if (detailShow === "show") {
      this.setState({
        detailShow: "none",
      });
    }
  };
  onStateToggle = (name, value) => {
    this.setState({
      [name]: value,
    });
  };
  getUserList = () =>{
    this.props.firebase.instituteAuthReaders()
    // .where('createdAt','>', 0)
    .where('institute','==', this.props.instituteInfo.institute)
    // .orderBy('createdAt', "desc")
    .get()
    .then((result)=>{
      let tempList = []
      this.setState({registeredNum:result.size})
      result.forEach((doc) => {
          var bid = doc.id
          var listData= doc.data();
          tempList.push({bid, ...listData})
          })
          this.setState({userList : tempList, showList : tempList})
      })
  }
  showDetailUserList = () => {
    this.getUserList()
    this.setState({showUserListModal : true})
  }
  deleteReader = (data, idx) => {
    const { bid, username, email } = data;
    let result = window.confirm(`(${idx+1}. ${username}/${email})을 삭제하시겠습니까?`)
    const { userList } = this.state;
    if(result){
    this.props.firebase
      .instituteAuthReader(bid)
      .delete()
      .then(() => {
        let readerTemp = userList;
        let index = readerTemp.indexOf(data);
        readerTemp.splice(index, 1);
        this.setState({ 
          userList: readerTemp,
          showList:readerTemp,
          order:'등록순'
         });
        alert("삭제되었습니다.");
      })
      .catch((err) => alert(`잠시 후 다시 시도해주세요.\n에러코드 : ${err}`));
    }
  };
  pushReader = () => {
    const { readerName, readerEmail, readerType, userList } = this.state;
    let result = window.confirm(`(${readerType==='S' ? '학생' : '교수님'}), ID : ${readerEmail}, PW : ${readerName} 계정을 추가하시겠습니까?`)
    let data = {
      createdAt : getTimeString(),
      department : '',
      email : readerEmail,
      institute : this.props.instituteInfo.institute,
      studentId : '',
      subjectName : '',
      type : readerType,
      userId : '',
      username : readerName,
      instituteId  : this.props.instituteInfo.instituteId
    }
    // console.log(data)
    if(result){
    this.props.firebase
      .instituteAuthReader().add(data)
      .then(() => {
        let readerTemp = userList;
        readerTemp.push({data})
        this.setState({ 
          userList: readerTemp,
          showList:readerTemp,
          order:'등록순'
         });
        alert("등록되었습니다.");
      })
      .catch((err) => alert(`잠시 후 다시 시도해주세요.\n에러코드 : ${err}`));
    }
  };
  onUpdateInstituteInfo = () => {
    let { enteredMemo, digitalbookId, isDigitalbook, isVideoOpen, is360VideoOpen } = this.state
    let instituteInfo = this.props.instituteInfo
    let instituteId = instituteInfo.instituteId
    delete instituteInfo.instituteId
    let data = { ...instituteInfo, enteredMemo, digitalbookId, isDigitalbook, isVideoOpen, is360VideoOpen }
    this.props.firebase.instituteList(instituteId).update(data)
    .then(()=>{
      alert('수정이 완료되었습니다.')
      this.props.getinstituteList()
    })
  }
  deleteAllReader = () => {
    // 테스트 해야함
    let result = window.confirm('로그인 가능한 계정 전체를 삭제하시겠습니까?')
    if(result){
    let instituteInfo = this.props.instituteInfo
    let instituteId = instituteInfo.instituteId
    this.props.firebase.instituteAuthReader().where('instituteId','==',instituteId).delete()
    .then(()=>{
      alert('삭제가 완료되었습니다.')
      this.props.getinstituteList()
    })
  }
  }
  render() {
    const { detailShow, showUserModal, showUserListModal, enteredMemo, digitalbookId, userList, showList, order,
      readerName, readerEmail, readerType, excelUrl, isDigitalbook, isVideoOpen, is360VideoOpen, registeredNum  } = this.state;
    let { instituteInfo, digitalBookList } = this.props
    // console.log(this.props)
    let {
      institute,
      instituteId,
      maxNum,
      instituteAdmin,
      instituteAdminNumber,
      instituteAdminEmail,
      instituteAdminDepart,
      // digitalbookId,
      // isDigitalbook,
      // isVideoOpen,
      // is360VideoOpen,
      createdAt,
      // enteredMemo
    } = instituteInfo
    return (
      <div className="programListFrame">
        <ul className="programListCont instituteCont">
          <li>{institute}</li>
          <li>{maxNum}</li>
          <li>{instituteAdmin}</li>
          <li>{getTimeDayjs(createdAt)}</li>
          <li>{instituteInfo.isDigitalbook ? 'O' : 'X'}/{instituteInfo.isVideoOpen ? 'O' : 'X'}/{instituteInfo.is360VideoOpen ? 'O' : 'X'}</li>
          <li>
            <div onClick={this.onToggle}>
              {detailShow === "none"
                ? `상세보기`
                : detailShow === "show"
                ? `상세닫기`
                : `상세정보`}
            </div>
          </li>
        </ul>

        <ul className={`${detailShow} detailBox`}>
          <li>
            <button onClick={()=>this.onUpdateInstituteInfo()}>저장</button>
          </li>
          <li>
            <span>담당자 정보 </span>
            <span>{instituteAdmin}{instituteAdmin!=='' ? '/' : '' }</span>
            <span>{instituteAdminDepart}{instituteAdminDepart!=='' ? '/' : '' }</span>
            <span>{instituteAdminNumber}{instituteAdminNumber!=='' ? '/' : '' }</span>
            <span>{instituteAdminEmail}</span>
          </li>
          <li>
            <span>로그인 ID</span>
            <span>{instituteAdminEmail}</span>
          </li>
          <li>
            <span>로그인 PW</span>
            <span>{instituteAdmin}</span>
          </li>
          <li className="xrbookSelect xrbookSelect2">
              <span>디지털북 </span>
                  <span>
                    <select name="digitalbookId" value={digitalbookId} onChange={(e) => this.onStateChange(e)}>
                    <option value="">디지털북 이용 안함</option>
                    {digitalBookList.length > 0 &&
                      digitalBookList.map((c) => {
                        return (
                          <option key={c.bid} value={c.bid}>
                            ({c.bookTarget}){c.bookTitle === "" ? `이름 없음` : c.bookTitle}
                          </option>
                        );
                      })}
                  </select>
                  </span>
              </li>
          <li>
            <span>영상</span>
            <input type='checkbox' name="isVideoOpen" checked={isVideoOpen} onChange={() => this.setState({isVideoOpen : !isVideoOpen})}/>
            {/* <span>{isVideoOpen ? 'O' : 'X'}</span> */}
          </li>
          <li>
            <span>360영상</span>
            <input type='checkbox' name="is360VideoOpen" checked={is360VideoOpen} onChange={() => this.setState({is360VideoOpen : !is360VideoOpen})}/>
            {/* <span>{is360VideoOpen ? 'O' : 'X'}</span> */}
          </li>
          <li className="userInfoUpload">
            <span>학습자 관리</span>
            <div className="userInfoUloadContFrame">
              <ul className="userInfoBtnGroup">
              <li>
                
              </li>
                <li>
                  <span
                    className="infoUploadBtn"
                    onClick={() => this.showDetailUserList()} >
                    &nbsp; 학생 계정 관리 &nbsp;
                    {showUserListModal &&
                    <span 
                    style={
                      typeof registeredNum === "number" ?
                      registeredNum+5 >= maxNum ? {color:'red'}
                      : {color: `rgb(0, 117, 255)`}
                    : null}>
                    {typeof registeredNum === "number" ? registeredNum : '?'}/{maxNum}</span>}
                  </span>
                  <span className="fileDownloadBtn">
                    <a 
                    href={excelUrl} 
                    target="_blank">
                      학생 등록 excel파일 다운
                    </a>
                  </span>
                    
                </li>
              </ul>

              {showUserListModal ? (
                <div className="userInfoContGroup">
                    <div className=" dBookUserInfoGroup">
                        <Enrollment
                          onStateToggle={this.onStateToggle}
                          fieldname={`${institute}__${instituteId}`}
                        />
                      <div>
                      <select name="readerType" value={readerType} onChange={this.onStateChange}>
                          <option value='S'>학생</option>
                          <option value='P'>교수님</option>
                        </select>
                        <input
                          type="text"
                          name="readerEmail"
                          value={readerEmail}
                          placeholder={`(${readerType==='S' ? '학번' : '이메일'}) (ID)`}
                          onChange={this.onStateChange}
                        />
                        <input
                          type="text"
                          name="readerName"
                          value={readerName}
                          placeholder="이름 (PW)"
                          onChange={this.onStateChange}
                        />
                        
                        <span 
                        onClick={() => readerName!=='' && readerEmail !== "" ? 
                            userList.length>Number(maxNum) ? alert('등록 인원이 초과되었습니다.')
                            : this.pushReader()
                        : alert('올바른 정보를 입력하세요.')}
                        
                        >
                          개별 등록
                        </span>
                      </div>
                      <div>* 학생의 경우 학번, 교수님의 경우 이메일을 로그인 ID로 등록하세요.</div>
                      <div>
                        {userList.length > 0 && (
                          <table border="1">
                            <thead>
                              <tr>
                                <th><span style={{color : '#0075ff' , cursor:'pointer'}}
                                onClick={()=>this.setState({showList : userList.sort((a,b)=>a['createdAt']-b['createdAt']), order:'등록순'})}>순번</span></th>
                                <th><span style={{color : '#0075ff' , cursor:'pointer'}}
                                onClick={()=>this.setState({showList : userList.sort((a,b)=>a['username']<b['username'] ? -1 : a['username']>b['username'] ? 1 : 0), order:'이름순'})}>이름</span></th>
                                <th><span style={{color : '#0075ff' , cursor:'pointer'}}
                                onClick={()=>this.setState({showList : userList.sort((a,b)=>a['userId']<b['userId'] ? -1 : a['userId']>b['userId'] ? 1 : 0), order:'계정순'})}>ID</span></th>
                                <th><span style={{color : '#0075ff' , cursor:'pointer'}}
                                onClick={()=>this.setState({showList : userList.sort((a,b)=>a['userPw']<b['userPw'] ? -1 : a['userPw']>b['userPw'] ? 1 : 0), order:'이름순'})}>PW</span></th>
                                <th><span style={{color : '#0075ff' , cursor:'pointer'}}
                                onClick={()=>this.setState({showList : userList.sort((a,b)=>a['type']<b['type'] ? -1 : a['type']>b['type'] ? 1 : 0), order:'자격순'})}>자격</span></th>
                                <th>삭제</th>
                              </tr>
                            </thead>
                            <tbody>
                              {showList.map((c, id) => {
                                return (
                                  <tr key={id}>
                                    <td>{id + 1}</td>
                                    <td>{c.username}</td>
                                    <td>{c.userId}</td>
                                    <td style={c.username !== c.userPw ? {color : 'red'} : {}}>{c.userPw}</td>
                                    <td>{c.type}</td>
                                    <td>
                                      <span 
                                      onClick={() => this.deleteReader(c, id)}
                                      >
                                        ×
                                      </span>
                                    </td> 
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        )}
                        {showList.length > 0 &&<div>({order})</div>}
                        <div className="refreshExcelBtn" 
                        onClick={() => this.getUserList()}
                        >
                          새로고침
                        </div>
                        {/* <span
                            className="fileDeleteBtn"
                            onClick={() => this.deleteAllReader()}
                          >
                            리스트 전체 삭제
                        </span> */}
                      </div>
                    </div>
                  
                </div>
              ) : null}
            </div>
          </li>
          <li className="manegeText">
            <span>담당자/상세내용</span>
            <textarea
              name="memo"
              value={enteredMemo.replace(/(<br>|<br\/>|<br \/>)/g, '\n')}
              onChange={(e) => this.setState({enteredMemo : e.target.value.replace(/(?:\r\n|\r|\n)/g, '<br />')})}
            />
          </li>
        </ul>
      </div>
    );
  }
}
export default withFirebase(InstituteList)

const Enrollment = (props) => {
  const { onStateToggle, fieldname } = props;
  return (
    <form
      action="/api/v1/instituteConvert"
      method="post"
      encType="multipart/form-data"
      target="fileUpload"
    >
      <div className="excelUploadGroup">
        <input type="file" name={`${fieldname}`} id="filexlsx" />
        <button type="submit">저장</button> (5~7초 소요)
        <div onClick={() => onStateToggle('showUserListModal', false)}>내용 접기</div>
      </div>
      <iframe
        name="fileUpload"
        title="fileUpload"
        //호스팅 후 수정
        src="https://xr-book.firebaseapp.com/target"
      ></iframe>

    </form>
  );
};


const InstituteUserList = (props) => {
  const [userList, setuserList] = useState([])
  useEffect(() => {
    getUserList()
  }, [])
  function getUserList(){
    props.firebase.instituteAuthReaders()
    // .where('createdAt','>', 0)
    .where('institute','==', props.institute)
    // .orderBy('createdAt', "desc")
    .get()
    .then((result)=>{
      let tempList = []
      result.forEach((doc) => {
          var bid = doc.id
          var listData= doc.data();
          tempList.push({bid, ...listData})
          })
          setuserList(tempList)
      })
  }
  console.log(userList)
  return (
    <div className='sendMsgContFrame'>
        <div className="sendMsgPopTitle">
          <h5>InstituteUserList</h5>
          <span onClick={()=>props.setshowUserListModal(false)}>X</span>
        </div>
        {/* <SetNewUser firebase={props.firebase} institute={props.institute}/> */}
        <div>
          {userList.length>0 && userList.map((c, id)=>{
            return(
              <div key={id}>
                {c.username}
              </div>
            )
          })}
        </div>
    </div>
  );
};

class SetNewUser extends Component {
  state = {
    department : '',
    email: '',
    institute: this.props.institute,
    studentId: '',
    subjectName: '',
    type: 'S',
    userId: '',
    username: '',
  }
  onStateChange = (e)=> {
    this.setState({
      [e.target.name] : e.target.value
    })
  }
  onSetUser = () => {
    let {
      department,
      email,
      institute,
      studentId,
      subjectName,
      type,
      userId,
      username,
      } = this.state
      let data = {
      department,
      email,
      institute,
      studentId,
      subjectName,
      type,
      userId,
      username,
      createdAt : getTimeString()
      }
      this.props.firebase.instituteAuthReaders().add(data)
      .then(()=>alert('생성 완료'))
      .catch((err)=>console.log(err))

  }
  render() {
    let {
    department,
    email,
    institute,
    studentId,
    subjectName,
    type,
    userId,
    username,
    } = this.state
    let {setshowUserModal} = this.props
    return (
      <div className='sendMsgContFrame'>
        {/* <div className="sendMsgPopTitle">
          {/* <h5>교육기관 등록</h5>
          <h5>우선 순천대 아이디 생성</h5>
          <span onClick={()=>setshowUserModal(false)}>X</span>
        </div> */}
        <div>
          <span>사용자 이름 : </span>
          <input type='text' name='username' value={username} onChange={(e)=>this.onStateChange(e)}/>
        </div>
        <div>
          <span>타입 : </span>
          <select name='type' value={type} onChange={(e)=>this.onStateChange(e)}>
            <option value="S">학생</option>
            <option value="P">교수님</option>
            <option value="A">관리자</option>
          </select>
        </div>
        <div>
          <span>로그인 ID : </span>
          <input type='text' name='userId' value={userId} onChange={(e)=>this.onStateChange(e)}/>
        </div>
        <div>
          <span>이메일 : </span>
          <input type='text' name='email' value={email} onChange={(e)=>this.onStateChange(e)}/>
        </div>
        <div>
          <span>기관 명 : </span>
          <input type='text' name='institute' value={institute} readOnly/>
        </div>
        <div>
          <span>학번 : </span>
          <input type='text' name='studentId' value={studentId} onChange={(e)=>this.onStateChange(e)}/>
        </div>
        <div>
          <span>단과대_학과 : </span>
          <input type='text' name='department' value={department} onChange={(e)=>this.onStateChange(e)}/>
        </div>
        <div>
          <span>과목 명 : </span>
          <input type='text' name='subjectName' value={subjectName} onChange={(e)=>this.onStateChange(e)}/>
        </div>
        <button onClick={()=>this.onSetUser()}>생성</button>
    </div>
    )
  }
}


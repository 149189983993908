import React, { Component, useState, useEffect } from 'react'
import './AdminStatistics.css'
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../../Session';
import * as ROLES from '../../../constants/roles';
import DataList from './DataList'
import UserList from './User/UserList'
import NonuserList from './NonUser/NonuserList'
import $ from 'jquery'

const AdminStatisticsPage = props => {
    const [one, onOne] = useState('contents_show');
  const [two, onTwo] = useState('contents_hidden');
  const [three, onThree] = useState('contents_hidden');
  const [show, onShow] = useState('dataList');
  const [statistics, setstatistics] = useState({})
  function First() {
    onShow('dataList');
    onOne('contents_show');
    onTwo('contents_hidden');
    onThree('contents_hidden');
    $(".adminStaticsFrame>.statisticsTabBtnGourp div").css({"border":"1px solid #dedede", "color":"#aaaaaa"});
    $(".adminStaticsFrame>.statisticsTabBtnGourp div").eq(0).css({"border-bottom":0,"color":"#000000" });    
  }
  function Second() {
    onShow('userList');
    onOne('contents_hidden');
    onTwo('contents_show');
    onThree('contents_hidden');
    $(".adminStaticsFrame>.statisticsTabBtnGourp div").css({"border":"1px solid #dedede", "color":"#aaaaaa"});
    $(".adminStaticsFrame>.statisticsTabBtnGourp div").eq(1).css({"border-bottom":0,"color":"#000000" });
  }
  function Third() {
    onShow('nonuserList');
    onOne('contents_hidden');
    onTwo('contents_hidden');
    onThree('contents_show');
    $(".adminStaticsFrame>.statisticsTabBtnGourp div").css({"border":"1px solid #dedede", "color":"#aaaaaa"});
    $(".adminStaticsFrame>.statisticsTabBtnGourp div").eq(2).css({"border-bottom":0,"color":"#000000" });
  }
  function getTotalNum (){
    props.firebase.tlpStatistics().get()
    .then((res)=> {
          let data = res.data()
          setstatistics(data)
          // console.log(data)
    })
  }
  useEffect(() => {
    getTotalNum()
  }, [])
    return (
        <div className="adminStaticsFrame">
                <h3>통계 관리</h3>
                <div className="statisticsTabBtnGourp">
                    <div  className={`${one}`} onClick={()=>First()}>데이터 분석</div>
                    <div  className={`${two}`} onClick={()=>Second()}>회원 정보</div>
                    <div  className={`${three}`} onClick={()=>Third()}>비회원 정보</div>
                </div>
                <div className="statisticsTabContGroup">
                {show === 'dataList' ? <DataList statistics={statistics}/> :
                show === 'userList' ? <UserList statistics={statistics}/> :
                show === 'nonuserList' ? <NonuserList statistics={statistics}/> : null}
                </div>
        </div>
    );
};
const condition = authUser =>
authUser && authUser.roles[ROLES.MEMBER] === ROLES.ADMIN;

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(AdminStatisticsPage);

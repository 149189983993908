import React, { Component } from 'react';
import {withFirebase} from '../Firebase'
import {Link} from 'react-router-dom'
import * as ROUTES from '../../constants/routes';
import { getTimeDayjs, getTimeString } from '../helpers';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {NotificationContainer, NotificationManager} from 'react-notifications';


class VrCode extends Component {
    state = {
        usedCodeList : [],
        uid : this.props.uid
    }
    componentDidUpdate(prevProps, prevState){
        if(prevState.usedCodeList !== this.state.usedCodeList){
            console.log(this.state.usedCodeList)
        }
    }
    componentDidMount(){
        this.getVrCode()
    }
    getVrCode = () => {
        let {uid} = this.props
        let usedCodeList = []
        this.props.firebase.oculusGoCodeLists().where('userUid','==',uid).get()
        .then(res=>{
            res.forEach(doc=>{
                let result = doc.data()
                usedCodeList.push({...result})
            })
            this.setState({usedCodeList : usedCodeList})
        })
    }
    putUidVrCode = () => {
        let {uid} = this.props
        let arr = []
        let usedAt = getTimeString()
        this.props.firebase.oculusGoCodeLists().where('isCodeUsed','==',false).limit(1).get()
        .then(res => {
                res.forEach(doc=>{
                    let result = doc.data()
                    let idx = doc.id
                    this.props.firebase.oculusGoCodeList(idx).update({isCodeUsed:true, userUid:uid, usedAt })
                    .then(()=>{
                        this.setState({usedCodeList : [{...result, isCodeUsed:true, userUid:uid, usedAt}] })
                        // arr.push({...result, isCodeUsed:true, userUid:uid, usedAt})
                    })  
                })
              })
              .catch(err=>console.log(err))
              
    }
    TempMsg = () => {
              NotificationManager.info(`오큘러스 고 VR app 다운로드 코드가 복사되었습니다.`, 'COPIED', 1500, () => {
                alert('callback');
              })
    };
    render() {
        let { usedCodeList } = this.state
        let listLeng = usedCodeList.length
        // console.log(usedCodeList, listLeng)
        return (
            <div className="codeRegiBx">
                <NotificationContainer/>
                <div className="titleBox">
                    <h5>오큘러스 고 코드 발급</h5>
                    <div onClick={()=>this.props.onPropsChange('isVrCode','')}>×</div>
                </div>
                <div className="subTextBox">
                    <h6>* 안내사항</h6>
                    <ul  className="codeRegiBxinfoPc">
                        <li>오큘러스 고 계정 하나당 하나의 VR app 다운로드 코드만 사용 가능합니다.</li>
                        <li>한 개의 오큘러스 고 계정으로 여러 오큘러스 고 기기에 로그인 할 수 있습니다.</li>
                        <li>코드의 만료 기한은 오큘러스 고 VR app 다운로드 유효기간입니다.</li>
                        <li>메일로 전송받은 북코드와 오큘러스 고 코드는 별개입니다</li>
                        <li>오큘러스 고 코드 등록 방법은 <Link to={{ pathname: `${ROUTES.GUIDE}`, state : { tab : "Manual"}}} >
                        [VR/AR APP 및 기기 설명 페이지]</Link>를 참고하세요</li>
                    </ul>
                    <ul className="codeRegiBxinfoMobile">
                        <li>오큘러스 고 계정 하나당 하나의 VR app<br/> 
                            다운로드 코드만 사용 가능합니다.</li>
                        <li>한 개의 오큘러스 고 계정으로 <br/> 
                            여러 오큘러스 고 기기에 로그인 할 수 있습니다.
                        </li>
                        <li>코드의 만료 기한은 오큘러스 고<br/> 
                            VR app 다운로드 유효기간입니다.
                        </li>
                        <li>메일로 전송받은 북코드와<br/>
                            오큘러스 고 코드는 별개입니다
                         </li>
                        <li>오큘러스 고 코드 등록 방법은 <Link to={{ pathname: `${ROUTES.GUIDE}`, state : { tab : "Manual"}}} ><br/>
                            [VR/AR APP 및 기기 설명 페이지]</Link>를 참고하세요
                        </li>
                    </ul>
                </div>
                <div>
                    {listLeng>0 ?
                    <React.Fragment>
                    {usedCodeList.map((c,id)=>{
                        return(
                            <div key={id}>
                                <p className="codeRegiBxoculusCodeCopy">↓ 클릭하시면 코드번호가 복사됩니다.</p>
                                <CopyToClipboard text={c.vrCode}
                                    onCopy={this.TempMsg}>
                                    <p className="codeRegiBxoculusCode">{c.vrCode}</p>
                                </CopyToClipboard> { c.expiredAt === '999999999999' ? <p className="codeRegiBxoculusCodeCopy1">/ '만료기한 없음'</p> : getTimeDayjs(c.expiredAt)}<br/>
                                    {/* <button onClick={()=>this.props.onPropsChange('isVrCode','')}>닫기</button> */}
                            </div>
                        )
                    })}
                    </React.Fragment>
                    :<div className="codeRegiBxoculusCodeNone">
                        <p>발급 받은 오큘러스 고 VR app 다운 코드가 없습니다.</p>
                        <button onClick={()=>this.putUidVrCode()}>코드 발급 받기</button>
                    </div>}
                </div>
                {/*<button onClick={()=>this.props.onPropsChange('isVrCode','')}>닫기</button> */} 
            </div>
        );
    }
}

export default withFirebase(VrCode);
import React from 'react';
import {Link} from 'react-router-dom'
import { withEmailVerification, withAuthorization } from '../Session';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes'
import './Landing.css'

const XrMakingAppliedPage = () => {
    return (
        <div className="landingBg" >
            <ul className="landingFrame">
            
                <li className="pwResetTitle">XR BOOK 제작 신청이 완료되었습니다.</li>
                <li className="landingBtn"><Link to={ROUTES.TLP_MAIN}>홈으로 돌아가기 →</Link></li>
                
            </ul>
        </div>

    );
};

const condition = authUser => !!authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(XrMakingAppliedPage);
import React, { Component } from 'react';
import VideoItemInstituteUser from './VideoItemInstituteUser'
import * as ROUTES from '../../constants/routes'
import './index.css'

class VideoListInstituteUser extends Component {
    state = {
        prologueList : [
            { name : 'Prologue[성인용]', url:'464900219'},
        ],
        // 360
        chapter360List : [
            { name : 'Chapter1-360', url:'477915949'},
            { name : 'Chapter2-360', url:'477919228'},
            { name : 'Chapter3-360', url:'477924295'},
            { name : 'Chapter4-360', url:'477927991'},
        ],
        chapterList : [
            { name : 'Chapter1', url:'478339417'},
            { name : 'Chapter2', url:'478341890'},
            { name : 'Chapter3', url:'478343024'},
            { name : 'Chapter4', url:'478345853'},
        ],
        epilogueList : [
            { name : 'Epilogue[성인용]', url:'462870299'}
        ]
    }
    render() {
        let {prologueList, chapter360List, chapterList, epilogueList} = this.state
        let marginTop  = this.props.marginTop || false
        let { instituteInfo } = this.props
        let {
            isVideoOpen,
            is360VideoOpen,
        } = instituteInfo
        return (
            <div>
                {!isVideoOpen && !is360VideoOpen && <div  className="facilitatorVideoOutFrame" style={marginTop ? {marginTop:'7vh'} : {}}>시청 가능한 영상이 없습니다.</div>}
                <div className="facilitatorVideoOutFrame" style={marginTop ? {marginTop:'7vh'} : {}}>
                {isVideoOpen && prologueList.map((c, id)=> {           
                    return (
                        <div  className='facilitatorVideoBlock' key={id} style={{padding:'1vw 0'}}>
                            { /* <Link to={{pathname: `${ROUTES.USER}`, state: {tab : c.url}}}><div>{c.name}</div></Link>  */}
                            <VideoItemInstituteUser instituteReaderData={this.props.instituteReaderData} url={c.url} name={c.name}/>
                        </div>
                    )
                })}
                </div>
                <div className="facilitatorVideoOutFrame">
                {is360VideoOpen && chapter360List.map((c, id)=> {           
                    return (
                        <div  className='facilitatorVideoBlock' key={id} style={{padding:'1vw 0'}}>
                            <VideoItemInstituteUser instituteReaderData={this.props.instituteReaderData} url={c.url} name={c.name}/>
                        </div>
                    )
                })}
                </div>
                <div className="facilitatorVideoOutFrame">
                {isVideoOpen &&  chapterList.map((c, id)=> {           
                    return (
                        <div  className='facilitatorVideoBlock' key={id} style={{padding:'1vw 0'}}>
                            <VideoItemInstituteUser instituteReaderData={this.props.instituteReaderData} url={c.url} name={c.name}/>
                        </div>
                    )
                })}
                </div>
                <div className="facilitatorVideoOutFrame">
                {isVideoOpen &&  epilogueList.map((c, id)=> {           
                    return (
                        <div  className='facilitatorVideoBlock' key={id} style={{padding:'1vw 0'}}>
                            <VideoItemInstituteUser instituteReaderData={this.props.instituteReaderData} url={c.url} name={c.name}/>
                        </div>
                    )
                })}
                </div>
            </div>
        );
    }
}

export default VideoListInstituteUser;
import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import {withFirebase} from '../../Firebase'
import * as ROUTES from '../../../constants/routes';
import '../../Digitalbook/NonAuth/NonAuth.css'
import logo from '../../images/XRBOOKLogo.png'
class TLPNavigationNonAuth extends Component {
    render() {
        return (
            <div>
                <div className="nonAuthLogoFrame ">
                        <Link to={ROUTES.TLP_MAIN} className="nonAuthLogo" onClick={()=>this.props.firebase.auth.signOut()}>
                            <img src={logo} alt="logo" />
                            <span>어린 왕자, 나를 만나다 <br/>XR Reflection Writing Program</span>
                        </Link>
                </div>
            </div>
        );
    }
}

export default withFirebase(TLPNavigationNonAuth);
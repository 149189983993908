import React, { Component } from 'react'
import {withFirebase} from '../../../Firebase'
import dayjs from 'dayjs'
import {getTimeDayjs, getTimeString} from '../../../helpers'
import DayPicker, { DateUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import SendSMSModal from '../../SendSMSModal'



class PaidEventList extends Component {
        state= {
        from: null,
        to: null,
        enteredTo: null, // Keep track of the last day for mouseEnter.
        selectedDay: undefined,
        vrEventpaidList : this.props.vrEventpaidList,
        userInfo:{},
        modalOpen : false,
        modalText : '',
        vrEventStart : this.props.vrEventpaidList.vrEventStart === undefined ? '' : this.props.vrEventpaidList.vrEventStart,
        vrEventFinish: this.props.vrEventpaidList.vrEventFinish === undefined ? '' : this.props.vrEventpaidList.vrEventFinish,
        vrEventReturn : this.props.vrEventpaidList.vrEventReturn === undefined ? '' : this.props.vrEventpaidList.vrEventReturn,
        vrEventStatus : this.props.vrEventpaidList.vrEventStatus === undefined ? '' : this.props.vrEventpaidList.vrEventStatus,
        vrEventBetween : this.props.vrEventpaidList.vrEventBetween === undefined ? 0 : this.props.vrEventpaidList.vrEventBetween,
        periodModalOpen:false,
        number : '',
        isModalOpen : false,
        receiver: '',
        authBookid:''
        };
    componentDidMount(){
        this.props.firebase.user(this.props.vrEventpaidList.codeUser).get()
        .then(res=>{
            let uid = res.id
            let data = res.data()
            this.setState({
                userInfo : {...data, uid}
            })
        })
    }
    componentDidUpdate(prevProp, prevState){
        if(prevState.from !== this.state.from){
            let vrEventStart = dayjs(this.state.from).format('YYYY-MM-DD')
            this.setState({vrEventStart})
        }
    }
    onSubmit = (vrEventpaidId) => {
        let {vrEventReturn, vrEventStatus} = this.state
        if(vrEventReturn ==='' && vrEventStatus === 'finish'){
          let timeSting = getTimeString()
          this.setState({vrEventReturn : timeSting})
          const data = {vrEventReturn : timeSting, vrEventStatus}
          this.props.firebase.mybook(vrEventpaidId).set(data,{merge:true})
            .then(()=> alert('적용되었습니다.'))
            .catch(err=> alert(`잠시 후 다시 시도해 주세요\n`+err))
        } else {
          const data = {vrEventStatus}
          this.props.firebase.mybook(vrEventpaidId).set(data,{merge:true})
            .then(()=> alert('적용되었습니다.'))
            .catch(err=> alert(`잠시 후 다시 시도해 주세요\n`+err))
        }
        
    }
    onPropsChange = (type, number, receiver, vrEventpaidId) => {
        
        if(type && number !== undefined){
            let stringNumber = number.split('-').join("")
            this.setState({
                number : stringNumber,
                isModalOpen : true,
                receiver: receiver,
                vrEventpaidId
        })
        // console.log(type, stringNumber, receiver)
        } else if(!type && number !== undefined) {
            this.setState({
                number : '',
                isModalOpen : false,
                receiver: '',
                vrEventpaidId:''
        })
    } else if( number === undefined) {
        alert('해당 유저가 번호를 입력하지 않았거나 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.\n오류가 계속되면 개발자에게 문의하세요.')
        this.setState({
            number : '',
            isModalOpen : false,
            receiver: '',
            vrEventpaidId:''
    })
    }
    }
    render() {
        let {vrEventpaidList} = this.props
        let {userInfo, modalOpen, modalText, periodModalOpen,
             vrEventReturn, vrEventStatus,
             isModalOpen, number, receiver} = this.state
        let { userAddress, rName, vrEventpaidId } = vrEventpaidList
            var isvrEventDateFormatted2 = getTimeDayjs(vrEventReturn)
        return (
            <React.Fragment>
              <tbody>
                <tr className="salesListDetailCont">
                  <td>{userInfo.username}</td>
                  <td>{userInfo.number}</td>
                  <td>{userInfo.email}</td>
                  <td>{userAddress ? userAddress : '배송지 미입력'}
                    {/* <button onClick={()=>this.setState({ modalOpen : true, modalText : userAddress})}>확인</button> */}
                  </td>

                  <td >
                    { vrEventReturn==='' ? '-' :isvrEventDateFormatted2}
                  </td>
                  <td>
                    <select value={vrEventStatus} onChange={(e)=>this.setState({vrEventStatus:e.target.value})}>
                        <option value='ready'>배송 준비 중</option>
                        <option value='shipment'>배송 중</option>
                        <option value='finish'>배송 완료</option>
                    </select>
                  </td>
                  <td><button onClick={()=>this.onSubmit(vrEventpaidId)}>적용</button></td>
                  <td><button onClick={()=>this.onPropsChange(true, userInfo.number, `${userInfo.username}${userInfo.username !==rName ? `(${rName})` : null}`, vrEventpaidList.vrEventpaidId)}>문자전송</button></td>
                </tr>
              </tbody>
            
              {modalOpen && 
              <div className="salesDetailPopBg">
                <div className="salesDetailPopFrame">
                  <div className="salesDetailPopTitle">
                    <h5>배송지 확인</h5>
                    <div onClick={()=>this.setState({modalOpen:false, modalText:''})}>×</div>
                  </div> 
                  <p>
                    <span>{userInfo.username}</span>
                    <span>{modalText}</span>
                  </p>
                </div>
              </div>}
                {isModalOpen && <SendSMSModal number = {number} onUpdate = {this.onPropsChange} receiver={receiver} mybookId={vrEventpaidId}/>}
            </React.Fragment>
        )
    }
}
export default withFirebase(PaidEventList)


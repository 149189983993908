import React, { Component } from 'react'
import {withFirebase} from '../../../Firebase'
import dayjs from 'dayjs'
import { getTimeDayjs } from '../../../helpers'
import Spinner from '../../../Utils/Spinner'
import SendSMSModal from '../../SendSMSModal'


class MyBookItem extends Component {
    state = {
        detailShow:'none',
        codeUser : this.props.codeUser !==undefined ?  this.props.codeUser : '',
        number: this.props.myBookList !==undefined ?  this.props.myBookList.number : '',
    }
    onToggle = () => {
        const {detailShow} = this.state;
        if(detailShow === 'none'){
            this.props.firebase.user(this.props.codeUser).get()
            .then(res=>{
                let userData = res.data()
                this.setState({
                    number : userData.number
                })
            })
            this.setState({
            detailShow : 'show'
        })} else if(detailShow === 'show'){
            this.setState({
            detailShow : 'none'
        })}
    }
    render() {
        let {myBookList, onPropsChange} = this.props
        const { detailShow, number} = this.state
        const {codeUserName, rName, rNumber, email, rEmail, item_name, published, vrRent, bookcode,
            bookStartAt, bookFinishAt, publishedCover, bookData, seenPageId, isFinished, isExtended, totalExtension, extensionPeriod, extendPayData, authBookid, period} = myBookList
            
            var bookStartAtFormatted2 = getTimeDayjs(bookStartAt)
            var yearF = bookFinishAt.substr(0,2)
            let yearIntF = parseInt(yearF)
             var monF = bookFinishAt.substr(2,2)
             let monIntF = parseInt(monF)
             var dateF = bookFinishAt.substr(4,2)
             let dateIntF = parseInt(dateF)
         var bookFinishAtFormatted= dayjs(new Date(2000+yearIntF, monIntF-1, dateIntF-1))
         var bookFinishAtFormatted2 = bookFinishAtFormatted.format('YYYY-MM-DD')
         var todayFormmat = dayjs(new Date()).format('YYYY-MM-DD')
         let pageLeng = publishedCover ? bookData.pageList.length -1 : bookData.pageList.length +1
        return (
            <React.Fragment>
                <ul className="salesListCont salesList3Cont">
                                        <li style={{textOverflow:'ellipsis'}}>
                                            <span>{codeUserName}</span>
                                            {/* <span>{codeUserName !==rName ? `(${rName})` : null}</span> */}
                                        </li>
                                        <li>{bookStartAtFormatted2}</li>
                                        <li>{bookFinishAtFormatted2}</li>
                                        <li>{period}일{totalExtension ? ` +${totalExtension}일` :extensionPeriod ? ` +${extensionPeriod}일`:''}</li>
                                        <li>
                                            <span>({todayFormmat}기준)</span>
                                            <span>{seenPageId >pageLeng ? pageLeng : seenPageId}/{pageLeng} 약({seenPageId/pageLeng > 1 ? 100 : parseInt((seenPageId/pageLeng)*100)}%){isFinished?'-완료':'-미완'}</span>
                                        </li>
                                        <li>{isExtended && extendPayData && extendPayData.status===1 ? 'O' : 'X'}</li>
                                        <li>
                                            <div className="sendMsgBtn" onClick={()=>onPropsChange(true, number, `${codeUserName}${codeUserName !==rName ? `(${rName})` : null}`, authBookid)}>문자 전송</div>
                                        </li>
                                        <li>
                                            <div onClick={this.onToggle}>
                                            {detailShow === "none"
                                            ? `상세보기`
                                            : detailShow === "show"
                                            ? `상세닫기`
                                            : `상세정보`}
                                            </div>
                                        </li>
                                    </ul>
                                    <ul className={`${detailShow} detailBox`}>
                                        <li>
                                            <span>연락처</span>
                                            <span>{number}{number !==rNumber ? `(${rNumber})` : null}</span>
                                            <span>{email}{email !==rEmail ? `(${rEmail})` : null}</span>
                                        </li>
                                        <li>
                                            <span>프로그램 유형</span>
                                            <span>{item_name}{published >0 ? `-인쇄(${published}부)` : `-인쇄없음`}
                                            {vrRent ? `-기기 체험` : `-체험 불포함`}
                                            </span>
                                        </li>
                                        <li>
                                            <span>수강코드</span>
                                            <span>{bookcode}</span>
                                        </li>
                                    </ul>
                                    
            </React.Fragment>
        )
    }
}
export default withFirebase(MyBookItem)

import React, {useState} from 'react';
import SwiperCore, { Navigation, Pagination, Scrollbar, A11y, Virtual, Autoplay } from 'swiper'
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper.scss';
import 'swiper/components/navigation/navigation.scss';
import 'swiper/components/pagination/pagination.scss';
import 'swiper/components/scrollbar/scrollbar.scss';

import {Link} from 'react-router-dom'
import * as ROUTES from '../../../constants/routes';
// import AuthDownloadBtn from '../../Utils/AuthDownloadBtn'

SwiperCore.use([Navigation, Pagination, Scrollbar, A11y, Autoplay]);
SwiperCore.use([Virtual]);

const MainSwiper = props => {
    // console.log(props)
    let {slideList, idName, xrbookByTheHRDer, theme} = props
    const [selectedIndex, setselectedIndex] = useState(0)
    // const slideList = Array.from({ length: 10 }).map((el, index) => `Slide ${index + 1}`);    
function slideChange(idx){
    let mySwiper = document.getElementById(idName).swiper
    mySwiper.slideToLoop(idx, 1000, true)
    mySwiper.autoplay.start()
}
    return (
        <div className="sectInFrame">
            {/* 타이틀 */}
            <div className="sectTextBox">
                
                {idName === 'selflearningSlide'?
                                <h2>Learning Program</h2>
                    :idName === 'classlearningSlide'?
                    <h2>Introduction</h2>
                    :idName === 'xrMakingSlide'?
                    <h2>XR BOOK<br/>Making Service</h2>
                :null}
                {slideList && slideList.map((c, id) => {
                        return(
                            <div key={id}>
                            {theme==='black'?
                            <p className={`pagerText `} onClick={()=> slideChange(id)}
                            style={selectedIndex ===id ? {color:'#60c0bf' }:{ color:'#ffffff'}}>
                            {c.text1}
                            </p>
                            :
                            <p className={`pagerText `} onClick={()=> slideChange(id)}
                            style={selectedIndex ===id ? {color:'#60c0bf' }:{ color:'#000f1e'}}>
                            {c.text1}
                            </p>}
                            </div>
                        );
                    })}
                {/* 버튼 */}
                <div className="btnGroup">
                    {idName === 'selflearningSlide'?
                                <Link className="orderBtn" to={{
                                    pathname: `${ROUTES.ORDER}`,
                                }} >프로그램 신청하기</Link>
                    :idName === 'classlearningSlide'?
                    // <AuthDownloadBtn url={xrbookByTheHRDer} name='XR BOOK 소개서 다운로드'/>
                    <a className="douwmloadBtn" href={xrbookByTheHRDer} target='_blank'>XR BOOK 소개서 다운로드</a>
                    :idName === 'xrMakingSlide'?
                    <a className="contactBtn" href={ROUTES.TLP_MAIN_CONTACT}>개발 문의하기</a>
                :null} </div>
            </div>
            

            {/* 슬라이드 */}
           <Swiper className="swiperFrame"
           id={idName}
                // style={{width:'100vw', height:'56.25vw', border:'1px solid blue'}}
                // spaceBetween={50}
                slidesPerView={1}
                navigation
                // pagination={{ clickable: true }}
                // scrollbar={{ draggable: true }}
                // onSwiper={(swiper) => console.log(swiper)}
                onSlideChange={(slide) => setselectedIndex(slide.activeIndex)}
                // autoplay={{delay:5000}}
                // loop={true}
                >
                {slideList && slideList.map((c, id) => {
                    return(
                    <SwiperSlide className="sectContGroup" key={id}>
                        <p className="sectConText sectConTextPc">{c.text2 && c.text2.split('<br />').map((c, id)=> {
                            return(
                                        <span key={id}>{c}<br/></span>
                                    )})}</p>
                        <p className="sectConText sectConTextMobile">{(c.text2).replace(/<br\s*[\/]?>/gi, " ")}</p>
                        <img className="pcImage" src={c.pcImgUrl} alt='self img'/>
                        <img className="mobileImage" src={c.mobileImgUrl} alt='self img'/>
                    </SwiperSlide>
                    );
                })}
                {/* <span slot="container-start">Container Start</span>
                <span slot="container-end">Container End</span>
                <span slot="wrapper-start">Wrapper Start</span>
                <span slot="wrapper-end">Wrapper End</span> */}
                </Swiper>
        </div>
    );
};

export default MainSwiper;
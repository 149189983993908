import React, { Component } from 'react'
import { getTimeString, getTimeDayjs } from '../helpers'
import {withRouter} from 'react-router-dom'
import * as ROUTES from '../../constants/routes';
import { compose } from 'recompose';
import { withFirebase } from '../Firebase'

class UserReview extends Component {
    state = {
        bookData : this.props.location.state !== undefined ? this.props.location.state.bookData :{},
        bookTitle : this.props.location.state !== undefined ? this.props.location.state.bookTitle :"",
        mybookId : this.props.location.state !== undefined ? this.props.location.state.mybookId :"",
        published : this.props.location.state !== undefined ? this.props.location.state.published :0,
        username : this.props.location.state !== undefined ? this.props.location.state.username :"",
        target : this.props.location.state !== undefined ? this.props.location.state.target :"",
        site : this.props.location.state !== undefined ? this.props.location.state.site :0,
        programId : this.props.location.state !== undefined ? this.props.location.state.programId :"",
        programTitle : this.props.location.state !== undefined ? this.props.location.state.programTitle :"",
        digitalBookID : this.props.location.state !== undefined ? this.props.location.state.digitalBookID :"",
        userAddress : this.props.location.state !== undefined ? this.props.location.state.userAddress :"",

        review:'',
        reviewTitle:''
    }

    componentDidMount(){
        if(this.props.location.state  === undefined) {
            alert('유효하지 않은 접근입니다.')
            this.props.history.push(ROUTES.USER)
        }
        
    }
    onPropsChange = (name, value) => {
        this.setState({
            [name]:value
        })
    }
    onSubmit = () => {
        let today = getTimeString()
        var year = today.substr(0,2)
        year = parseInt(year)
        var mon = today.substr(2,2)
        mon = parseInt(mon)
        var date = today.substr(4,2)
        date = parseInt(date)
        let {bookTitle, username, review, reviewTitle, bookData, mybookId, target, site, programId, published,digitalBookID, userAddress, programTitle} = this.state
        let data = {
            createdAt: today,
            bookTitle,
            username,
            review,
            // : '본 프로그램은 VR을 통해 어린 왕자를 직접 만나고,<br />어린 왕자와의 대화를 통해 이루어지는 나와 우리에 대한 성찰을 기록하고,<br />그 결실을 나만의 어린 왕자 북으로 만드는 과정입니다.<br />이 책에 실린 질문들은 당신이 한 번 즈음 생각해 보았을 내용들입니다．<br />이 여정에서 하나하나 써 내려가는 나만의 글은<br />나로 하여금 시야를 넓히고 확장시켜 삶의 의미를 발견하게 해 줄 것입니다.<br />이 특별한 여정을 경험하고 느낀 생각을 공유하여,<br />보다 많은 사람들이 삶의 의미를 되찾고 행복한 삶을 살 수 있도록 소개해주세요.',
            reviewTitle, 
            //: '여기에 보이는 건 껍데기에 지나지 않아. 가장 중요한 것은 눈에 보이지 않아',
            digitalBookID :mybookId,
            target,
            site,
            programId,
            show: true,
            programTitle
        }
        this.props.firebase.reviews().add(data)
        .then(res=> {
            let reviewId = res.id
            this.props.firebase.mybook(mybookId).update({
                review : reviewId
             })
             .then(()=> {
                this.props.firebase.tlpStatistics().update({
                    Totalreview : this.props.firebase.fieldValue.increment(1)
                })
                this.props.history.push({pathname: `${ROUTES.XRBOOK_COVER}`,
                state: {
                    bookTitle:bookTitle,
                    mybookId: mybookId,
                    username : username,
                    published : published,
                    digitalBookID:digitalBookID,
                    target : target,
                    site: site,
                    programId : programId,
                    bookData: bookData,
                    userAddress:userAddress,
                    programTitle
                    }})
            // }
            })
        })
    }
    render() {
        let today = getTimeDayjs()
        let {programTitle, username, review, reviewTitle} = this.state
        const enteredReview =  review.replace(/(<br>|<br\/>|<br \/>)/g, '\n')
        // console.log(this.state)
        return (
            <div className="userReviewFrame">
                <ul>
                    <li>
                        <span>교육 프로그램이 종료되었습니다. </span>
                        <span>후기를 작성해 주세요</span>
                    </li>
                    <li>
                        <input type='text' value={reviewTitle} placeholder="프로그램 진행 중 인상 깊었던 문구나 나만의 후기 제목을 작성해 주세요" onChange={(e)=>this.onPropsChange('reviewTitle', e.target.value)}/>
                    </li>
                    <li>
                        <textarea value={enteredReview} placeholder="교육 프로그램 진행 후기를 작성해 주세요" onChange={(e)=>this.onPropsChange('review', e.target.value.replace(/(?:\r\n|\r|\n)/g, '<br />') )}/>
                    </li>
                    <li>{today} "『{programTitle}』프로그램" 후기, {username}</li>
                    <li>
                        <button onClick={()=>this.onSubmit()} 
                        disabled={review === '' || reviewTitle === ''}
                        >저장하기</button>
                    </li>
                </ul>
            </div>
        )
    }
}

export default compose(
    withRouter,
    withFirebase)(UserReview);
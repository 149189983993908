import React, { useState, useEffect, Component } from 'react';
import DrawApp from '../Utils/DrawApp'
import DrawAppMobile from '../Utils/DrawAppMobile'
import SunEditor from 'suneditor-react';
import '../../../constants/suneditor.min.css';
import './pageItem.css'
import { faShareSquare } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


class PageItem extends Component {
    state = {
        device : this.isMobile(),
        isAppleDevice : this.isAppleDevice(),
        iframeHeight: window.innerWidth < 834 ? 2 : 5,
        pageItem:this.props.pageItem,
        is360 : this.isAppleDevice()
    }
    componentDidMount(){
        window.addEventListener('resize', this.updateDimensions);
        try {
            // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
            window.scroll({
              top: 0,
              left: 0,
              behavior: 'smooth',
            });
          } catch (error) {
            // just a fallback for older browsers
            window.scrollTo(0, 0);
          };
          this.setState({
            device : this.isMobile(),
            isAppleDevice : this.isAppleDevice(),
        })
        
    }
    isMobile(){
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent.toLowerCase());
    }
    isAppleDevice(){
        return /iPhone|iPad|iPod|macintel|macintosh/i.test(navigator.userAgent.toLowerCase());
        // var UserAgent = navigator.userAgent.toLowerCase();
        // if (UserAgent.match(/iPhone|iPod/i) != null){ 
        //     return true; 
        // }else{
        //     return false;
        // }
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }
    componentDidUpdate(prevProps, prevState){
        // if(prevState.pageItem !== this.state.pageItem){
        //     this.props.handleChange(this.state.pageItem, this.props.showBookmark)
        // }
    }
    updateDimensions = () => {
        this.setState({ iframeHeight: window.innerWidth < 834 ? 2 : 5});
      };
    writingText = (e) => {
        let { pageItem } = this.state
        let currentPage = Object.assign({}, pageItem)
        e.preventDefault()
        e.stopPropagation()
        if (e.target.className==="writingValue") {
        currentPage.userSection[e.target.dataset.id][e.target.className] = e.target.value
        currentPage.isShared = true
        this.setState({
            pageItem: currentPage, 
            })
        } else {
        currentPage.userSection[e.target.dataset.id]['writingValue'] = e.target.value.replace(/(?:\r\n|\r|\n)/g, '<br />')
        currentPage.isShared = true
        this.setState({
            pageItem: currentPage, 
            })
        }
    }
    selectVideoType=()=>{
        let {is360} = this.state
         this.setState({is360:!is360})
    }
    render() {
        let { handleChange, drawingChange, setBookmark, setSharemark, isChanged, pageId, mybookId, showBookmark} = this.props
        let {pageItem, device, isAppleDevice, iframeHeight, is360 } = this.state
        // console.log(localStorage.getItem('is360'))
        return (
            <div className="userBookContentFrame disabledDrag">
                {pageItem !== undefined &&
                <React.Fragment>
                    {showBookmark === 'front' ?
                    <div style={{display:'flex', justifyContent:'space-between', borderBottom:'1px solid #dedede'}}>
                        <div style={{display:'flex', flexDirection:'row', visibility:'hidden'}}>
                            <div className="bookMark" style={{background:'white', border:'none',}}
                                onClick={setBookmark}></div>
                            <div className="shareMark" style={{display:'none'}}
                            onClick={setSharemark}><FontAwesomeIcon icon={faShareSquare} /></div>
                             
                        </div>
                        <div style={{display:'flex', flexDirection:'row'}}>
                        {pageItem.isShared ? <div className="shareMark"
                                style={pageItem['shareMark']?{color : 'orange'}:{color:'#dedede'}}
                                onClick={setSharemark}><FontAwesomeIcon icon={faShareSquare} /></div>
                            :<div className="shareMark" style={{backgroundColor:'white', color:'white', border:'1px solid transparent'}}><FontAwesomeIcon icon={faShareSquare} /></div>}
                            <div className="bookMark" style={pageItem['bookmark']?{background : 'orange'}:{background:'white'}}
                                onClick={setBookmark}></div>
                        </div>
                    </div>
    
                    : showBookmark === 'back' ?
                     <div style={{display:'flex', justifyContent:'space-between' , borderBottom:'1px solid #dedede'}}>
                        <div style={{display:'flex', flexDirection:'row'}}>
                            <div className="bookMark" style={pageItem['bookmark']?{background : 'orange'}:{background:'white'}}
                                onClick={setBookmark}></div>
                        {pageItem.isShared ? <div className="shareMark"
                                style={pageItem['shareMark']?{color : 'orange'}:{color:'#dedede'}}
                                onClick={setSharemark}><FontAwesomeIcon icon={faShareSquare} /></div>
                            :<div className="shareMark" style={{backgroundColor:'white', color:'white', border:'1px solid transparent'}}><FontAwesomeIcon icon={faShareSquare} /></div>}
                        </div>
                        <div style={{display:'flex', flexDirection:'row', visibility:'hidden'}}>
                            <div className="shareMark" style={{display:'none'}}
                            onClick={setSharemark}><FontAwesomeIcon icon={faShareSquare} /></div>
                            <div className="bookMark" style={{background:'white', border:'none',}}
                                onClick={setBookmark}></div>
                            </div> 
                        </div>
                    : null}
    
                    <div className="bookContentFrame">
                    {pageItem !== undefined &&
                        <div  style={{  height:'100%', width:'100%',
                                        backgroundSize : 'contain, contain',
                                        backgroundRepeat : 'no-repeat, no-repeat',
                                        backgroundPosition : 'bottom, right bottom',
                                        backgroundImage: "url("+pageItem.pageBgImgUrl+")",
                                        backgroundColor : pageItem.pageBgImgColor}}>
                        {pageItem !== undefined &&
                            <div className="makingPaperFrame" style={{
                                height: '100%', width: '100%',
                                backgroundSize: 'contain, contain',
                                backgroundRepeat: 'no-repeat, no-repeat',
                                backgroundPosition: 'bottom, right bottom',
                                backgroundImage: "url(" + pageItem.pageBgImgUrl + ")",
                                backgroundColor: pageItem.pageBgImgColor }}>
                        {pageItem.userSection.map((val, idx) => {
                        // let catId = `cat-${idx}`, ageId = `age-${idx}`
                        return (
                        <div key={idx}>
                            {val.type === 'inputText' ?
                                <div className="bookContFrame inputTextFrame" id='inputText'>
                                    <p>{val.textTitle}</p>
                                    <input
                                        type="text"
                                        data-id={idx}
                                        // id={catId}
                                        value={val.writingValue}
                                        className="writingValue"
                                        onChange={e=>this.writingText(e)}
                                        onBlur={()=>handleChange(pageItem, showBookmark)}
                                    />
                                </div>
                                // <InputText val={val} idx={idx} handleChange={handleChange}/>
                                : val.type === 'textarea' ?
                                    <div className="bookContFrame textareaFrame">
                                        <p>{val.textTitle}</p>
                                        <textarea
                                            type="text"
                                            data-id={idx}
                                            style={{resize:'none', overflow:"hidden"}}
                                            rows={val.rowsLimit}
                                            placeholder={`정해진 칸을 초과하여 작성된 부분은 인쇄하는 경우 내용이 잘릴 수 있습니다.`}
                                            value={val.writingValue.replace(/<br\s*[\/]?>/gi, `\n`)}
                                            className="textarea"
                                            // onKeyDown={e=>limitLines(e)}
                                            onChange={e=>this.writingText(e)}
                                            onBlur={()=>handleChange(pageItem, showBookmark)}
                                        />
                                        </div> :
                                    val.type === 'drawingTool' ?
                                        <div className="bookContFrame drawingToolFrame">
                                            <p>{val.textTitle}</p>
                                            <DrawApp contentHeight={val.contentHeight}
                                                drawingChange={(className, contents, idx)=> drawingChange(className, contents, idx)}
                                                userSectionId={idx} contents={val.writingValue} bgColor={val.bgColor}
                                                pageId={pageId} mybookId={mybookId}
                                                isChanged={isChanged}/>
                                        </div>
                                        : val.type === 'textEditor' ?
                                            <div className="bookContFrame textEditorFrame" style={{ backgroundColor: val.editorBgColor }}>
                                                <SunEditor lang="ko" height='auto' setOptions={{ 
                                                    resizingBar: false,
                                                    defaultStyle:'font-size:1.6rem',
                                                 }}
                                                    showToolbar={false} disable={true} setContents={val.textTitle} />
                                                
                                            </div>
                                            : val.type === 'image' ?
                                                <div className="bookContFrame imageFrame">
                                                    <React.Fragment>
                                                        {val.srcUrl != '' ?
                                                            <div style={{
                                                                width: '100%', display: 'flex', flexDirection: `${val.textDirection}`,
                                                                justifyContent: `${val.textJustify}`, alignItems: 'center'
                                                            }}>
    
                                                                <img src={val.srcUrl} alt={`image${idx}`} width={`${val.contentWidth}%`} />
                                                                <span>{val.textarea && val.textarea.split('<br />').map((c, id) => {
                                                                    return (
                                                                        <span key={id}>{c}<br /></span>
                                                                    )
                                                                })}
                                                                </span>
                                                            </div>
                                                            : <p>이미지</p>}
                                                    </React.Fragment>
                                                    
                                                </div>
                                                : val.type === 'video' ?
                                                    <React.Fragment>
                                                        <div className="bookContFrame videoFrame" style={{ 
                                                            // border:'1px solid gray',
                                                            justifyContent: `${val.textJustify}`, height: `${val.contentWidth / iframeHeight}vw`
                                                        }}>
                                                            {/* <div></div> */}
                                                            {/* {val.srcUrl ?
                                                                <div style={{ width: `${val.contentWidth}%` }}>
                                                                    <iframe src={val.srcUrl} style={{ width: `100%`, height: `100%`, border: 'none' }}
                                                                    frameBorder="0"  allowFullScreen />
                                                                </div>
                                                                : <p>영상</p>} */}
                                                            { !is360 ?
                                                            // !isAppleDevice ?
                                                            //         val.srcUrl !=='' ?
                                                            //         <div style={{ width: `${val.contentWidth}%` }}>
                                                            //             <iframe src={val.srcUrl} style={{ width: `100%`, height: `100%`, border: 'none' }}
                                                            //             frameBorder="0"  allowFullScreen />
                                                            //         </div>
                                                            //         :  val.srcUrl2 !=='' ?
                                                            //         <div style={{ width: `${val.contentWidth}%` }}>
                                                            //             <iframe src={val.srcUrl2} style={{ width: `100%`, height: `100%`, border: 'none' }}
                                                            //             frameBorder="0"  allowFullScreen />
                                                            //         </div>
                                                            //         : <p>영상</p>
                                                            // : 
                                                            // val.srcUrl2 && val.srcUrl2 !=='' ?
                                                                    <div style={{ width: `${val.contentWidth}%` }}>
                                                                        <iframe src={val.srcUrl2} style={{ width: `100%`, height: `100%`, border: 'none' }}
                                                                        frameBorder="0"  allowFullScreen />
                                                                    </div>
                                                                //     : val.srcUrl !=='' ?
                                                                //     <div style={{ width: `${val.contentWidth}%` }}>
                                                                //         <iframe src={val.srcUrl} style={{ width: `100%`, height: `100%`, border: 'none' }}
                                                                //         frameBorder="0"  allowFullScreen />
                                                                //     </div>
                                                                // : <p>영상이 존재하지 않습니다.</p>
                                                                : 
                                                                <div style={{ width: `${val.contentWidth}%` }}>
                                                                    <iframe src={val.srcUrl} style={{ width: `100%`, height: `100%`, border: 'none' }}
                                                                    frameBorder="0"  allowFullScreen />
                                                                </div>
                                                            }
                                                        </div>
                                                        <div className="videoGuideText">
                                                            ※ 기기 환경에 따라 VR 영상만 재생 가능합니다.
                                                            {val.srcUrl !=='' && val.srcUrl !==undefined &&
                                                             !isAppleDevice &&
                                                            <>
                                                            (
                                                                {/* <input type='checkbox' checked={!is360} name='is360' onChange={()=>this.selectVideoType()}/> VR 3D 영상
                                                                &nbsp; */}
                                                                <input type='checkbox' checked={is360} name='is360' onChange={()=>this.selectVideoType()}/> 360 영상
                                                            )
                                                            </>}
                                                            {/* <span>{isAppleDevice ? 1 : 2}</span> */}
                                                        </div>
                                                    </React.Fragment>
                                                    : val.type === 'emptySpace' ?
                                                        <div className="bookContFrame emptySpaceFrame" >
                                                            <div></div>
                                                            
                                                        </div>
                                                        : <span>none</span>}
                                                </div>
                                            )
                                        })
                                        }
                            </div>}
                        </div>}
                    </div>
                </React.Fragment>}
            </div>
        );
    }
}

export default PageItem;
import React, { Component } from 'react'
import { withAuthorization, withEmailVerification } from '../../Session';
import { compose } from 'recompose';
import * as ROLES from '../../../constants/roles';
import './AdminSiteManagement.css'

import {IntroSection, ClassSection, SelfSection,
     XrMakingSection, GallerySection, ServiceSection, VideoSection, VideoSectiontlp} from './mainSection'


class AdminSiteManagementPage extends Component {
    // Intro = 배경동영상1, 텍스트 3 브로셔 url, section
    // Class = 배경이미지1, (이미지2, 텍스트 2, 순서) * n, section
    // Self = 배경이미지1,  (이미지2, 텍스트 2, 순서) * n, section
    // XR BOOK = 배경동영상1,  (이미지2, 텍스트 2, 순서) * n, section
    // 갤러리 - (배경동영상-긴텍스트) *4, section
    // 고객센터 - { 카테고리(배송, 반품/교환, 취소, FAQ),
    //                  텍스트 1, 긴텍스트 1, show여부? }* n, section
    state={
        intro : {
            videoUrl:'',
            text1:'',
            text2:'',
            text3:'',
            section : 'intro',
        }
    }
    onPropsChange = (name, value) => {
        this.setState({
            [name] : value
        })
    }
    onStateChange = e => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }
    render(){
    return (
        <div className="adminSiteManagementFrame">
            <h3>사이트 관리</h3>
            <div><IntroSection/></div>
            <div><VideoSectiontlp/></div>
            <div><VideoSection/></div>
            {/* <div><ClassSection/></div>
            <div><SelfSection/></div>
            <div><XrMakingSection/></div> */}
            {/* <div><GallerySection/></div> */}
            {/* <div style={{border:'1px solid blue', marginBottom:'100px'}}><ServiceSection/></div> */}
        </div>
    );
}};


const condition = authUser =>
authUser && authUser.roles[ROLES.MEMBER] === ROLES.ADMIN;

export default compose(
withEmailVerification,
withAuthorization(condition),
)(AdminSiteManagementPage);
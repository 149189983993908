import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import { getTimeDayjs, getTimeString, getTimeStringNotime, getTimeDayjsOrigin } from '../../helpers'
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../../Session';
import * as ROUTES from '../../../constants/routes';
import '../../User/User.css'
import '../../User/UserResp.css'
import ClassInquiries from '../../User/ClassInquiries'
import SelfInquiries from '../../User/SelfInquiries'
import SelfPayment from '../../User/SelfPayment'
import SelfLearning from '../../User/SelfLearning'
import SharingPage from '../../User/SharingPage'
import UserInfo from '../../User/UserInfo'

import XRBOOKLogo from '../../images/XRBOOKLogo.png'
import dayjs from 'dayjs'
import $ from 'jquery'

class UserPage extends Component {
    state={
        // auth : localStorage.getItem('authUser'),
        mybookList:[],
        xrMakingList : [],

        library: this.props.location.state  !== undefined ? 
        this.props.location.state.tab ==='library' ? true : false : false,
        program: this.props.location.state  !== undefined ? 
        this.props.location.state.tab ==='program' ? true : false : false,
        sales: this.props.location.state  !== undefined ? 
        this.props.location.state.tab ==='sales' ? true : false : false,
        payment: this.props.location.state  !== undefined ? 
        this.props.location.state.tab ==='payment' ? true : false : false,
        xrbook: this.props.location.state  !== undefined ? 
        this.props.location.state.tab ==='xrbook' ? true : false : false,
        sharing: this.props.location.state  !== undefined ? 
        this.props.location.state.tab ==='sharing' ? true : false : false,
        reviews: this.props.location.state  !== undefined ? 
        this.props.location.state.tab ==='reviews' ? true : false : false,
        myInfo: this.props.location.state  !== undefined ? 
        this.props.location.state.tab ==='myInfo' ? true : false : false,

        authUser : this.props.authUser,
        mybookId : '',
        bookcode1:'',
        bookcode2:'',
        digitalBookID:'',
        codeModal:false,
        codeMsg:'',
        shipmentModal:false, //false로 바꾸기
        isAddressOpen :false, //false로 바꾸기
        isVrCode:'',
        postCode:'',
        fullAddress:'',
        extraAddress:'',
    }
    componentDidMount(){
        try {
            // console.log(navigator.userAgent.toLowerCase())
            // console.log(this.isMobileDevice())
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    };
        this.getMybookList()
    }

    onstateChange = (show) => {
        const { library, program, sales, payment, xrbook, sharing, reviews, myInfo} = this.state;
        if(show ==='library') this.setState({library : !library})
        if(show ==='program') this.setState({program : !program})
        if(show ==='sales') this.setState({sales : !sales})
        if(show ==='payment') this.setState({payment : !payment})
        if(show ==='xrbook') this.setState({xrbook : !xrbook})
        if(show ==='sharing') this.setState({sharing : !sharing})
        if(show ==='reviews') this.setState({reviews : !reviews})
        if(show ==='myInfo') this.setState({myInfo : !myInfo})
    }
    getMybookList =()=> {
        let {authUser}=this.state
        this.props.firebase.mybooks().where('codeUser','==', authUser['uid'] ).orderBy('bookStartAt').get()
        .then(result => {
            var mybookList = []
            result.forEach(res => {
                var listData = res.data()
                var mybookId = res.id
                mybookList.push({mybookId, ...listData})
            })
            this.setState({mybookList})
        })
    }
      render() {
        const { library, program, sales, payment, xrbook, sharing, reviews, myInfo, authUser, mybookId, isVrCode,
             mybookList, programList, onlineList, xrMakingList, bookcode1,bookcode2, postCode, fullAddress, extraAddress, isAddressOpen, codeModal, codeMsg, shipmentModal} = this.state;
        const authName = authUser['username']
        let isCertificated = authUser['isCertificated']
        let uid = authUser['uid']
        let number = authUser['number']
        const showLibrary =  library ? 'show' : 'hidden'
        const showProgram =  program ? 'show' : 'hidden'
        const showSales = sales ? 'show' : 'hidden'
        const showPayment =
        // 'show'
        payment ? 'show' : 'hidden'
        const showXrbook = xrbook ? 'show' : 'hidden'
        const showSharing = sharing ? 'show' : 'hidden'
        const showReviews =reviews ? 'show' : 'hidden'
        const showMyInfo = myInfo ? 'show' : 'hidden'
          return (
            <div className="userMypageBg" style={{padding:0}}>
                <div className="userMypageFrame" style={{padding:0}}>
                    <h3>{authName||'회원'}님</h3>
                    <p>즐거운 하루 되세요 !</p>

                    <div className='tab' onClick={()=> this.onstateChange('library')}>내 서재</div>
                        <div className={`contents ${showLibrary}`}>
                           
                            <div className={`libraryBg`}>
                            <div className="libraryFrame">
                            {/* <div className="myLibraryFrame"> */}
                                {/* 회사명, 참여인원, 프로그램명, 결제가격, 코드, 진행상황, 일자, 비고 */}
                                {mybookList.length>0 ? mybookList.map(c=> {
                                    let { published, userAddress, bookData, target, mybookId, digitalBookID, site, unique, seenPageId, lastPageId,
                                        bookFinishAt, review, vrRent, isFinished, pid, publishedCover, period,
                                        isExtended, extensionPeriod, extendPayData, totalExtension  }= c
                                        let bookTitle= bookData.bookTitle
                                    let title = `${bookTitle} (${target} 대상) - 저자 : ${authName}`
                                    
                                    // console.log(c)
                                    var year = bookFinishAt.substr(0,2)
                                        year = parseInt(year)
                                        var mon = bookFinishAt.substr(2,2)
                                        mon = parseInt(mon)
                                        var date = bookFinishAt.substr(4,2)
                                        date = parseInt(date)
                                        var isFinishedAtFormatted= dayjs(new Date(2000+year, mon-1, date-1))
                                        var isFinishedAtFormatted2 = isFinishedAtFormatted.format('YYYY-MM-DD')
                                return (
                                    <div key={pid}>
                                        {/* 완성했을때 */}
                                        {isFinished || bookFinishAt<getTimeString() ? 
                                                // 리뷰없을때
                                                !review ? <span><Link to={{pathname: `${ROUTES.XRBOOK_REVIEW}`, state: { published : published, userAddress:userAddress,
                                                                bookTitle:bookTitle, mybookId: mybookId, digitalBookID: digitalBookID, username : authName,
                                                                target : target, site: site, programId : unique,  bookData: bookData, programTitle:c.title }}} >
                                                                <span><img src={bookData.iconUrl || XRBOOKLogo } alt='icon'/></span>
                                                                <p>{title} - [종료/리뷰작성]</p>
                                                            </Link></span>
                                                // 리뷰썼고 커버 선택 안했을때
                                                : !publishedCover ? <span><Link to={{pathname: `${ROUTES.XRBOOK_COVER}`, state: { published : published, userAddress:userAddress,
                                                    bookTitle:bookTitle, mybookId: mybookId, digitalBookID: digitalBookID, username : authName, bookData: bookData,
                                                    target : target, site: site, programId : unique , programTitle:c.title }}} >
                                                                <span><img src={bookData.iconUrl || XRBOOKLogo } alt='icon'/></span>
                                                                <p>{title} - [종료/커버선택]</p>
                                                            </Link></span>
                                                //리뷰썼고 커버선택 필요 없거나 커버 선택 완료했을때
                                                :   <span><Link to={{pathname: `${ROUTES.XRBOOK_CHECK}`, state: {
                                                    bookData : bookData,
                                                    authUser:authUser,
                                                    title:title,
                                                    bookTitle:bookTitle,
                                                    mybookId:mybookId,
                                                    seenPageId:seenPageId,
                                                    lastPageId:lastPageId,
                                                    published : published,
                                                    bookFinishAt  : bookFinishAt,
                                                    review : review, programTitle:c.title
                                                    }}} >
                                                        <span><img src={bookData.iconUrl || XRBOOKLogo } alt='icon'/></span>
                                                        <p>{title} - [종료]</p>
                                                    </Link></span>
                                        // 완성안했을때
                                        // 주소 입력 했을 때
                                        :userAddress ? 
                                        // write 가능
                                        <span className="columnMoblie" style={{display:'flex', justifyContent:'space-between'}}>
                                        <Link to={{pathname: `${ROUTES.XRBOOK_AUTH}`, state: {
                                            // bookData : bookData,
                                            authUser:authUser,
                                            title:title,
                                            bookTitle:bookTitle,
                                            mybookId:mybookId,
                                            seenPageId:seenPageId,
                                            lastPageId:lastPageId,
                                            published : published,
                                            bookFinishAt  : bookFinishAt,
                                            review : review
                                            }}} >
                                        <span><img src={bookData.iconUrl || XRBOOKLogo } alt='icon'/></span>
                                        <p>{title} {isFinishedAtFormatted2}까지</p>
                                        </Link>
                                        <span className="btnArea"  style={{display:'flex'}}>
                                                {/* 버튼 */}
                                            {/* {!vrRent && <button  className="oculusVrAppBtn" onClick={()=>this.setState({isVrCode:mybookId})}>오큘러스 고 APP 코드</button>}
                                            <ExtensionPay extensionPeriod={7} totalExtension={totalExtension} isExtended={isExtended} payReqData={c} /> */}
                                            </span>
                                        </span>
                                        : // 주소입력 안했을때 write 불가능 - 주소입력필요
                                        vrRent || published >0 ? 
                                        
                                        <div onClick={()=>this.onSetShipment(mybookId)}>
                                        <Link to='#'>
                                        <span><img src={bookData.iconUrl || XRBOOKLogo } alt='icon'/></span>
                                        <p>{title} {isFinishedAtFormatted2}까지 - [주소입력] </p>
                                        </Link>
                                        </div>
                                        : // write 가능 주소입력 불필요
                                        <span className="columnMoblie" style={{display:'flex', justifyContent:'space-between'}}>
                                        <Link to={{pathname: `${ROUTES.XRBOOK_AUTH}`, state: {
                                            bookData : bookData,
                                            authUser:authUser,
                                            title:title,
                                            bookTitle:bookTitle,
                                            mybookId:mybookId,
                                            seenPageId:seenPageId,
                                            lastPageId:lastPageId,
                                            published : published,
                                            bookFinishAt  : bookFinishAt,
                                            review : review
                                            }}} >
                                        <span><img src={bookData.iconUrl || XRBOOKLogo } alt='icon'/></span>
                                        <p>{title} {isFinishedAtFormatted2}까지</p>
                                        </Link>
                                            <span className="btnArea"  style={{display:'flex'}}>
                                                {/* 버튼 */}
                                            {/* {!vrRent && <button  className="oculusVrAppBtn" onClick={()=>this.setState({isVrCode:mybookId})}>오큘러스 고 APP 코드</button>}
                                            <ExtensionPay extensionPeriod={7} totalExtension={totalExtension} isExtended={isExtended} payReqData={c} /> */}
                                            </span>
                                        </span>
                                        }
                                    </div>
                                    )
                                })
                                : 
                                <div className="noDataText">추가된 디지털 북이 없습니다.</div>
                                }
                                </div>
                                <p>
                                    <span>* 기간 연장은 프로그램 종료 전, &nbsp;</span>
                                    <span>7일씩 가능합니다.</span>
                                </p>
                                <div className="bookRegistBtn" >수강 코드 등록</div>
                                </div>
                    </div>

                    <div className='tab' onClick={()=> this.onstateChange('program')}>Off-line Learning Program 문의 조회</div>
                        <div className={`contents ${showProgram}`}>
                            <ClassInquiries authUser={authUser}/>
                    </div>

                    <div className='tab' onClick={()=> this.onstateChange('sales')}>On-line Learning Program 문의 조회</div>
                        <div className={`contents ${showSales}`}>
                        <SelfInquiries authUser={authUser}/>
                        </div>
                    <div className='tab' onClick={()=> this.onstateChange('payment')}>결제 내역 및 이용 현황</div>
                        <div className={`contents ${showPayment}`}>
                            <h6>1. 결제 내역</h6>
                            <div>
                                <SelfPayment authUser={authUser}/>
                            </div>
                            <h6>2. 나의 On-line Learning 이용 현황</h6>
                            <div>
                                <SelfLearning authUser={authUser}/>
                            </div>
                        </div>
                    <div className='tab' onClick={()=> this.onstateChange('sharing')}>Reflection Gallery 이용 현황</div>
                        <div className={`contents ${showSharing}`}>
                            <SharingPage authUser={authUser}/>
                        </div>
{/* 
                    <div className='tab' onClick={()=> this.onstateChange('reviews')}>리뷰작성</div>
                        <div className={`contents ${showReviews}`}>
                            <p className="myReviewTxt">교육 프로그램 / 어린 왕자 책 리뷰</p>
                            관리자가 선택박스에서 해당 프로그램/책/제작 서비스 완료를 선택했을 때 추가 되어야 할 부분 (myReviewFrame)↓
                            <ul className="myReviewFrame">
                                <li>"XR Reflection Writing Book" 후기를 작성해 주세요</li>
                                <li>2020-07-04</li>
                                <li><button>글쓰기</button></li>
                            </ul>
                        </div> */}

                    <div className='tab' onClick={()=> this.onstateChange('myInfo')}>정보 수정/탈퇴</div>
                        <div className={`contents ${showMyInfo}`}>
                        <UserInfo firebase={this.props.firebase} authUser={authUser}/>
                        </div>
                </div>
            </div>
          )
      }
  }

  const condition = authUser =>authUser
//    && authUser['roles'][ROLES.MEMBER] === ROLES.MEMBER;

  export default compose(
    withEmailVerification,
    withAuthorization(condition),
  )(UserPage);

import React, { Component } from 'react'
import {withFirebase} from '../Firebase'
import { getTimeString } from '../helpers'
import SelfPaymentReceiver from './SelfPaymentReceiver'
import dayjs from 'dayjs'
import Spinner from '../Utils/Spinner'

class SelfPayment extends Component {
    state = {
        selfPaymentList : [],
        selfPaymentItem:[],
        selfPaymentEventItem:[],
        isPaymentItem: false,
        isRentItem:false,
        receiverItem:false,
        spinner : false,
        showingModal : false,
        receipt_id : '',
        username : '',
        itemId:'',
        order_id:'',
        reason:'',
        price:0,
        method:'',
        method_name:'',
        bankname:'',
        cancelAccount : '',
        createdAt:'',
        phone:'',
        email:'',
        itemName:'',
        loading:false
    }
    componentDidMount(){
        this.getSelfPaymentList()

    }
    getSelfPaymentList =()=> {
        let {authUser}=this.props
        this.props.firebase.tlpPayments().where('params.email','==', authUser['email'] ).orderBy('params.createdAt','desc').get()
        .then(result => {
            var selfPaymentList = []
            result.forEach(res => {
                var listData = res.data()
                selfPaymentList.push({...listData})
            })
            this.setState({selfPaymentList})
        })
    }
    getSelfPaymentItem =(order_id, type, receipt_id, username, method, method_name, bankname, createdAt, phone, email)=> {
        // console.log(receipt_id, username)
        if(type === 'payment'){
        this.setState({ isPaymentItem: true, spinner : true, isRentItem: false, receiverItem : false, receipt_id, username, method, method_name, bankname, createdAt, phone, email})
        this.props.firebase.paidDigitalBooks().where('order_id','==', order_id ).get()
        .then(result => {
            var selfPaymentItem = []
            result.forEach(res => {
                var listData = res.data()
                var itemId = res.id
                selfPaymentItem.push({itemId, ...listData})
            })
            this.setState({selfPaymentItem, spinner : false})
        })
    } else if(type === 'vrRent'){
        this.setState({ isRentItem: true, spinner : true, isPaymentItem: false, receiverItem : false})
        this.props.firebase.mybooks().where('order_id','==', order_id ).where('vrRent','==', true).get()
        .then(result => {
            var selfPaymentRentItem = []
            result.forEach(res => {
                var listData = res.data()
                var mybookId = res.id
                selfPaymentRentItem.push({mybookId, ...listData})
            })
            this.setState({selfPaymentRentItem, spinner : false})
        })
    } else if(type === 'vrEvent'){
        this.setState({ isRentItem: true, spinner : true, isPaymentItem: false, receiverItem : false})
        this.props.firebase.mybooks().where('order_id','==', order_id ).where('vrEvent','==', true).get()
        .then(result => {
            var selfPaymentEventItem = []
            result.forEach(res => {
                var listData = res.data()
                var mybookId = res.id
                selfPaymentEventItem.push({mybookId, ...listData})
            })
            this.setState({selfPaymentEventItem, spinner : false})
        })
    }
    else if(type === 'receiver'){
        this.setState({ isPaymentItem: false, spinner : true, isRentItem: false, receiverItem : true})
        this.props.firebase.paidDigitalBooks().where('order_id','==', order_id ).get()
        .then(result => {
            var selfPaymentItem = []
            result.forEach(res => {
                var listData = res.data()
                var itemId = res.id
                selfPaymentItem.push({itemId, ...listData})
            })
            this.setState({selfPaymentItem, spinner : false})
        })
    }
}
cancelPayment = () => {
    if(this.state.price>999){
    this.setState({loading : true})
    let { receipt_id, price, username, reason, itemId, createdAt, phone, email, itemName, method, method_name, bankname, cancelAccount} = this.state
    let isRefund = false
    let refundAt = getTimeString()
    let isRefundAt = ''
    let cancelPrice = price
    let data = { 
        receipt_id, price:cancelPrice, username, reason,
        itemId, paidAt : createdAt, refundAt, isRefundAt,
        phone, email, itemName,  method, method_name, bankname,
        cancelAccount, isRefund}        
    fetch("/api/v1/refund/informRefund", {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json; charset=utf-8'
        },
        body : JSON.stringify(data)
    }).then(response => { response.json()
    .then((res) => {
        if(res.msg === 'success') {
            alert('환불/취소 신청이 완료되었습니다.\n영업일 기준 최대 3일정도 소요됩니다.')
            window.location.reload()
      } else if(res.msg === 'codeStatusTrue') {
        alert('코드 등록이 된 사용자 입니다.')
        window.location.reload()
      } else if(res.msg === 'ServerSuccess'){
        alert('환불/취소가 완료되었습니다.')
        window.location.reload()
      }else {
        alert(`다시 시도해주세요. 오류가 계속되면 관리자에 문의해주세요.${res.msg}`)
        console.log(res.msg)
      }
      this.setState({loading : false})
    })
    })  
    .catch(err=>{
        console.log(err)
        alert('관리자에게 문의 바랍니다.')
        this.setState({loading : false})
    })
    } else {
        alert('환불 금액이 1,000원 이하거나 환불 불가능한 상품입니다. 관리자에 문의해주세요.')
    }
}
onPropsChange = (name, value) =>{
    this.setState({
        [name]:value
    })
}
    render() {
        let {
            selfPaymentList, selfPaymentItem, selfPaymentRentItem, selfPaymentEventItem,isPaymentItem, receiverItem, spinner, isRentItem,
            reason, showingModal, method, method_name, bankname, cancelAccount, loading, createdAt }= this.state
            let paidYYMMDD = createdAt.substr(0,6)
            let today = getTimeString()
            let refundYYMMDD = today.substr(0,6)
            // console.log(method, paidYYMMDD, refundYYMMDD)
        return (
            <React.Fragment>
                {selfPaymentList.length>0 ? selfPaymentList.map((c)=> {
                    const {name, params, order_id, receipt_id, method, method_name, payment_data, remain_price, anyVrRent} = c
                    let {totalPrice, createdAt, username, phone, email } = params
                        var year = createdAt.substr(0,2)
                        let yearInt = parseInt(year)
                        var mon = createdAt.substr(2,2)
                        let monInt = parseInt(mon)
                        var date = createdAt.substr(4,2)
                        let dateInt = parseInt(date)
                    let today = new Date().setHours(0,0,0,0)
                    let today1 = dayjs(today)
                    let today2 = today1.format('YYYYMMDD')
                    // sysdate.setDa( sysdate.getHours() + 9 )
                    var createdAfterPeriod = dayjs(new Date(2000+yearInt, monInt-1, dateInt+6))
                    var createdAfterPeriod2 = createdAfterPeriod.format('YYYYMMDD')
                    // console.log(today2, createdAfterPeriod2)
                    let cancelBtn = true
                    if(parseInt(today2)<=parseInt(createdAfterPeriod2)){
                        cancelBtn = true
                    } else {
                        cancelBtn = false
                    }
                    const userPrice = totalPrice ==='' ? 0 : totalPrice.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
                    let refundPrice = remain_price !==undefined ? `(${(totalPrice-remain_price).toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}원 취소 완료)` : '' 
                return (
                    <ul className="payHistoryFrame" key={c.order_id}>
                        <li><span>프로그램</span><span>{name}</span></li>
                        <li><span>결제 가격</span><span>{userPrice}원 {refundPrice}</span></li>
                        <li><span>결제 일자</span><span>20{year}-{mon}-{date}</span></li>
                        <li>
                            <span>결제 내역 관리</span>
                            <span>
                                <button onClick={()=> this.getSelfPaymentItem(order_id, 'receiver')}>프로그램 대상 관리</button>
                                <button style={anyVrRent ? {}:{backgroundColor:'#dddddd', color:"#bdbdbd", border:"1px solid #bdbdbd", cursor:"default"}}
                                    onClick={()=> anyVrRent ? this.getSelfPaymentItem(order_id, 'vrRent') : alert('기기체험이 포함된 결제내역이 없습니다.')}>기기 결제 현황</button>
                                <button style={cancelBtn ? {}:{backgroundColor:'#dddddd', color:"#bdbdbd", border:"1px solid #bdbdbd", cursor:"default"}}
                                    onClick={()=> cancelBtn ? this.getSelfPaymentItem(order_id, 'payment', receipt_id, username, method, method_name, payment_data.bankname, createdAt, phone, email )
                                :alert('환불 및 주문 취소는 결제일 7일 이내만 가능합니다.')}>환불/주문 취소</button>
                            </span>
                        </li>
                    </ul>
                    )
                })
                : <div className="noDataText">결제 내역이 없습니다.</div>}
                
                {isPaymentItem &&
                <div>
                    {spinner ? 
                    <div className="orderCancleBg">
                        <div className="orderCancleFrame">
                        <Spinner msg='결제 정보를 불러오는 중입니다.'/>
                        </div>
                    </div>
                    :
                    <div className="orderCancleBg">
                        <div className="orderCancleFrame">
                            <div className="canclePopTitle">
                                <h5>환불/ 주문취소 신청</h5>
                                <div onClick={()=> this.setState({isPaymentItem:false,isRentItem:false, receiverItem : false, spinner:false, selfPaymentItem:[], receipt_id:'', username:'',
                            itemId:'', order_id:'', price:0, reason :'', method:'', method_name:'', bankname:'', createdAt:'', phone:'', email:'', itemName:''})}>×</div>
                            </div>
                            {!loading ?
                            <React.Fragment>
                            <div className="canclePopList">
                                <h6>1. 결제 내역 </h6>
                                {/* <div>(미완성/취소, 쿠폰사용시 최소금액(취소가능금액, 쿠폰사용조건 갯수 등) 등등)</div> */}
                                <ul className="payHistoryFrame">
                                    <li>신청 프로그램</li>
                                    <li>대상자(휴대폰 뒷자리)</li>
                                    <li>결제 가격</li>
                                    <li>결제 일자</li>
                                    <li>상태</li>
                                    <li>환불/취소</li>
                                </ul>
                                {selfPaymentItem.length>0 ? selfPaymentItem.map((c,id)=> {
                                    let {item_name, published, vrRent, price, createdAt, status, codeStatus, codeUserName,
                                         itemId, order_id, rName, rNumber} = c
                                    const userPrice = price ==='' ? 0 : price.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
                                    var year = createdAt.substr(0,2)
                                    var mon = createdAt.substr(2,2)
                                    var date = createdAt.substr(4,2)
                                    let numberList = rNumber.split('-')
                                    let lastNumber = numberList.length ===3 ? numberList[2] : '재입력요망'
                                        return (
                                        <React.Fragment key={id}>
                                            <ul className="payHistoryFrame" style={this.state.itemId === itemId ? {border:'2px solid black'}:{}}>
                                                <li>{id+1} {item_name} - {published > 0 ? `인쇄(${published}부)` : '인쇄 없음'}
                                                 - {vrRent ? `기기 체험` : '체험 불포함'}
                                                 </li>
                                                <li>{rName}({lastNumber})</li>
                                                <li>{userPrice}</li>
                                                <li>{year}. {mon}. {date}.</li>
                                                <li>{status === 1 ? //결제완료
                                                        codeStatus ? //결제완료/사용
                                                            <div>{codeUserName || `사용자`} - 코드 등록</div>
                                                        : '결제 완료' //결제완료/미사용
                                                    :status === 20 ?'환불 완료' //취소완료
                                                    :status === -30 ? '취소 신청 완료' 
                                                    
                                                    :'관리자 문의'}</li>
                                                <li className="payCancleBtn">
                                                    {status === 20 ? <button disabled>완료</button> //취소완료
                                                    : status === 1 ? //결제완료
                                                    codeStatus ? <button disabled>불가</button> //결제완료/코드사용
                                                    : price <1000 ? <button onClick={()=>alert('최소 취소 가능 금액은 1,000원 이상입니다.')}>취소</button>
                                                    :
                                                    <button onClick={()=>this.setState({itemId, order_id, price, itemName : `${item_name} - ${published > 0 ? `인쇄(${published}부)` : '인쇄 없음'}
                                                    - ${vrRent ? '기기 체험' : '체험 불포함'}`
                                                    
                                                     })} disabled={price<1000}>취소</button>
                                                     //결제완료/코드미사용
                                                    :status === -30 ? <button disabled>취소</button>
                                                    :'관리자 문의'}
                                                </li>
                                            </ul>
                                        </React.Fragment>
                                        )
                                    }) : 
                                    <div className="noDataText">결제 내역이 없습니다.</div>
                                    }
                                </div>
                                <div className="canclePopReason">
                                    <h6>2. 환불/주문취소 사유 선택</h6>
                                    <ul>
                                        <li>
                                            <input type='checkbox' value={reason} checked={reason==='단순변심'} onChange={()=>this.state.itemId==='' ? alert('환불을 원하시는 프로그램을 선택해주세요.') :this.setState({reason:'단순변심'})}/> 
                                            <span onClick={()=>this.state.itemId==='' ? alert('환불을 원하시는 프로그램을 선택해주세요.') :this.setState({reason:'단순변심'})}>단순 변심</span>
                                        </li>
                                        <li>
                                            <input type='checkbox' value={reason} checked={reason==='잘못주문'} onChange={()=>this.state.itemId==='' ? alert('환불을 원하시는 프로그램을 선택해주세요.') :this.setState({reason:'잘못주문'})}/> 
                                            <span onClick={()=>this.state.itemId==='' ? alert('환불을 원하시는 프로그램을 선택해주세요.') :this.setState({reason:'잘못주문'})}>다른 상품 잘못 주문</span>
                                        </li>
                                        <li>
                                            <input type='checkbox' value={reason} checked={reason==='옵션변경'} onChange={()=>this.state.itemId==='' ? alert('환불을 원하시는 프로그램을 선택해주세요.') :this.setState({reason:'옵션변경'})}/> 
                                            <span onClick={()=>this.state.itemId==='' ? alert('환불을 원하시는 프로그램을 선택해주세요.') :this.setState({reason:'옵션변경'})}>주문 옵션 변경</span>
                                        </li>
                                    </ul>
                                </div>
                                {method ==='vbank' ?
                                <div className="canclePopAccount">
                                    <h6>3. 환불 계좌</h6>
                                    <div className="cancleAccounInput">
                                        <select value={bankname} onChange={(e)=>this.setState({bankname:e.target.value})}>
                                            <option value='농협중앙회'>농협중앙회</option>
                                            <option value='기업은행'>기업은행</option>
                                            <option value='국민은행'>KB국민은행</option>
                                            <option value='수협'>수협중앙회</option>
                                            <option value='우리은행'>우리은행</option>
                                            <option value='SC은행'>SC제일은행</option>
                                            <option value='신한은행'>신한은행</option>
                                            <option value='부산은행'>부산은행</option>
                                            <option value='광주은행'>광주은행</option>
                                            <option value='우체국'>우체국</option>
                                            <option value='KEB하나은행'>KEB하나은행</option>
                                            <option value='대구은행'>대구은행</option>
                                            <option value='경남은행'>경남은행</option>
                                        </select>
                                        <input type='text' value={cancelAccount} placeholder={`계좌번호 입력('-')제외`} onChange={(e)=>reason===''?alert('환불하고자하는 프로그램과 사유를 선택해주세요.'):this.setState({cancelAccount:e.target.value.replace(/[^0-9]/g,"")})}/>
                                    </div>
                                    <p>
                                        결제자 이름과 동일한 소유주 명의 계좌를 입력해 주십시오.
                                        환불 금액 입급은 영업일 기준 3일 정도의 시간이 소요될 수 있으며, 
                                        계좌번호를 올바로 입력하지 않으신 경우 계좌 확인을 위한 시간 지연이 발생할 수 있음에 유의하십시오.
                                        환불/주문 취소 버튼을 누르기 전 계좌 번호를 한 번 더 확인해 주세요.
                                    </p>
                                </div>
                                : method === 'bank' && paidYYMMDD!==refundYYMMDD ?
                                <div className="canclePopAccount">
                                    <h6>3. 환불 계좌</h6>
                                    <div className="cancleAccounInput">
                                        <select value={bankname} onChange={(e)=>this.setState({bankname:e.target.value})}>
                                            <option value='NH농협'>NH농협</option>
                                            <option value='기업은행'>기업은행</option>
                                            <option value='KB국민은행'>KB국민은행</option>
                                            <option value='수협중앙회'>수협중앙회</option>
                                            <option value='우리은행'>우리은행</option>
                                            <option value='SC제일은행'>SC제일은행</option>
                                            <option value='신한은행'>신한은행</option>
                                            <option value='부산은행'>부산은행</option>
                                            <option value='광주은행'>광주은행</option>
                                            <option value='우체국'>우체국</option>
                                            <option value='KEB하나은행'>KEB하나은행</option>
                                            <option value='대구은행'>대구은행</option>
                                            <option value='경남은행'>경남은행</option>
                                        </select>
                                        <input type='text' value={cancelAccount} placeholder={`계좌번호 입력('-')제외`} onChange={(e)=>reason===''?alert('환불하고자하는 프로그램과 사유를 선택해주세요.'):this.setState({cancelAccount:e.target.value.replace(/[^0-9]/g,"")})}/>
                                    </div>
                                    <p>
                                        결제자 이름과 동일한 소유주 명의 계좌를 입력해 주십시오.
                                        환불 금액 입급은 영업일 기준 3일 정도의 시간이 소요될 수 있으며, 
                                        계좌번호를 올바로 입력하지 않으신 경우 계좌 확인을 위한 시간 지연이 발생할 수 있음에 유의하십시오.
                                        환불/주문 취소 버튼을 누르기 전 계좌 번호를 한 번 더 확인해 주세요.
                                    </p>
                                </div> :null}
                                <div className="canclePopGuide">
                                    <p>환불 및 주문 취소 신청은 결제 이후 7일 이내에만 가능합니다.</p>
                                    <p>이미 코드를 등록한 경우 디지털 콘텐츠 특성 상 반품/주문 취소가 불가능합니다.</p>
                                    <p>일반 계좌이체의 당일 취소 시 입금 시 사용한 계좌로 금액이 환불됩니다.</p>
                                </div>
                                <div className="canclePopBtn">
                                    <span onClick={()=>this.setState({showingModal:true})}>반품/취소 안내 보기</span>
                                    <button onClick={()=>this.cancelPayment()} 
                                    disabled={method ==='vbank' || method ==='bank' ? cancelAccount==='' || cancelAccount.length<11 : false || this.state.itemId==='' }>
                                        환불/주문취소 신청하기</button>
                                </div>
                                </React.Fragment>
                                        : <Spinner msg='환불/취소 신청 중입니다.'/>}
                            </div>
                        </div>
                        }
                        
                    </div>}
                {isRentItem &&
                <div>
                    {spinner ? 
                    <div className="deviceRentalBg">
                        <div className="deviceRentFrame">
                        <Spinner msg='기기체험 정보를 불러오는 중입니다.'/>
                        </div>
                    </div>
                    :
                    <div className="deviceRentalBg">
                        <div className="deviceRentFrame">
                            <div className="rentPopTitle">
                                <h5>기기 체험 현황</h5>
                                <div onClick={()=> this.setState({isPaymentItem:false,isRentItem:false, receiverItem : false, spinner:false, selfPaymentItem:[]})}>×</div>
                            </div>
                            <div className="rentPopList">
                                <h6>체험 중인 기기</h6>
                                <ul className="payHistoryFrame">
                                    <li></li>
                                    <li>사용자(수신인)</li>
                                    <li>체험 기간</li>
                                    <li>체험 상태</li>
                                </ul>
                                {selfPaymentRentItem.length>0 ? selfPaymentRentItem.map((c,id)=> {
                                    let {vrRent, codeUserName, rName, vrRentStatus, vrRentStart, vrRentFinish,} = c
                                    // console.log(c)
                                    // var year = createdAt.substr(0,2)
                                    // var mon = createdAt.substr(2,2)
                                    // var date = createdAt.substr(4,2)
                                        return (
                                        <React.Fragment key={id}>
                                            <ul  className="payHistoryFrame">
                                                <li>{id+1}</li>
                                                <li>{codeUserName === rName ? codeUserName : `${codeUserName}(${rName})`}</li>
                                                <li>{vrRentStart ? `${vrRentStart}~${vrRentFinish}` : '체험 기간 조율 중'}</li>
                                                <li>{vrRentStatus === 'ready'? '배송 준비 중'
                                                : vrRentStatus === 'shipment'? '체험 중'
                                                : vrRentStatus === 'finish'? '반납 완료'
                                                : vrRentStatus === 'finishYet'? '반납 연체 중'
                                                : vrRentStatus === 'finishLate'? '연체 반납'
                                                : '관리자 문의 요청'}</li>
                                            </ul>
                                        </React.Fragment>
                                        )
                                    }):
                                <div className="noDataText">수강코드를 등록한 사용자가 없습니다.</div>}
                            </div>
                            <div>
                                {/* <h6>보증금 환불 정보</h6> */}
                                
                            </div>
                            {/* <div className="rentPopList">
                                <h6>구매 완료 기기</h6>
                                <ul className="payHistoryFrame">
                                    <li></li>
                                    <li>사용자(수신인)</li>
                                    <li>배송지</li>
                                    <li>배송 상태</li>
                                </ul>
                                {selfPaymentEventItem.length>0 ? selfPaymentEventItem.map((c,id)=> {
                                    let {codeUserName, rName, vrRentStatus, userAddress} = c
                                        return (
                                        <React.Fragment key={id}>
                                            <ul  className="payHistoryFrame">
                                                <li>{id+1}</li>
                                                <li>{codeUserName === rName ? codeUserName : `${codeUserName}(${rName})`}</li>
                                                <li>{userAddress ? '입력 완료' : '입력 대기'}</li>
                                                <li>{vrRentStatus === 'ready'? '배송 준비 중'
                                                : vrRentStatus === 'finish'? '배송 완료'
                                                : '관리자 문의 요청'}</li>
                                            </ul>
                                        </React.Fragment>
                                        )
                                    }):
                                <div className="noDataText">수강코드를 등록한 사용자가 없습니다.</div>}
                            </div> */}
                            <p>프로그램 등록 후 수강코드와 함께 배송지 정보를 입력하면 기기 배송이 시작됩니다.</p>
                        </div>
                    </div>
                    }
                    
                </div>}
                {showingModal &&
                <div className="payCancleGuideBg" >
                    <div className="payCancleGuideFrame">
                        <div className="payCanGuidePopTitle">
                            <h5>반품/취소 안내</h5>
                            <div onClick={()=>this.setState({showingModal:false})}>×</div>
                        </div>
                        <ul>
                            <li>
                                <p className="queText">
                                    ■ 결제한 프로그램을 전체 또는 일부 결제 취소, 수강료
                                    환급이 가능한가요?
                                </p>
                                <p className="ansText">
                                    On-line learning program을 전체 또는 일부 취소 가능하며,
                                    프로그램 신청 후 7일 이내, 코드 등록 이전이면,
                                    결제내역에서 결제를 취소할 수 있습니다. 전산오류를
                                    제외한 고객님의 개인적인 사정으로 인한 환불 및 교환은
                                    불가능합니다. 또한, 디지털 북 사용기간 연장은
                                    불가능합니다. 디지털 콘텐츠 특성 상 코드 등록으로 해당
                                    콘텐츠 접근이 가능해 진 이후에는 환불/환급이 불가합니다.
                                    다른 프로그램으로의 교환(취소 가능 기한 이내 혹은
                                    다운로드 전일 경우에만)은 불가하므로, 기한 내 취소 후
                                    새로 신청(주문)해 주십시오.
                                    <br />
                                    *취소 방법 : https://www.xrbook.net 로그인 → 마이페이지 → 결제 내역
                                    및 이용 현황 → 2. 나의 On-line Learning 이용 현황 →
                                    “환불/주문취소”
                                    <br />
                                    단, 디지털 북 코드를 등록하여 페이지 접근이 가능해 진 후에는 ‘환불/주문
                                    취소‘버튼이 표시되지 않고 취소 불가합니다. 코드 등록 중
                                    혹은 코드 등록 이후 이용 시 오류가 있는 경우에는 고객
                                    센터로 문의해주십시오.
                                </p>
                                </li>
                                <li>
                                <p className="queText">
                                    ■ 수강 시작한 프로그램의 환불 기준은 어떻게 되나요?{" "}
                                </p>
                                <p className="ansText">
                                프로그램에 대한 전액 환불은 승인일(결제완료일)로부터
                                    7일 이내에 북 코드 등록 이전일 경우에만 가능합니다. 그
                                    외 환불신청에 따른 반환 금액은 프로그램 수강 진행률에
                                    따라 책정됩니다.
                                    『어린 왕자, 나를 만나다』 프로그램의 강좌는 총 <strong>"1"</strong>개 입니다.
                                    강좌에 포함 된 <strong>"이러닝 콘텐츠"</strong>인 디지털 북 페이지 접근이 가능해지면,
                                    산업통상자원부의 이러닝(전자학습) 이용표준약관에 의거하여
                                    그 강좌를 이용한 것으로 간주합니다. 
                                    따라서 그에 대한 환불은 이루어지지 않습니다.
                                    수강 코드 등록으로 사용자가 해당 페이지에 접근이 가능해
                                    진 경우, 동영상 재생이나 내용 작성이 이루어지지
                                    않았다하더라도 프로그램 강좌 진행이 된 것으로 간주합니다.
                                    <br />
                                    <br />코드 등록 기간 내에 북 코드를 등록한
                                    일자에 따라 프로그램 교육 기간이 시작 되며, 코드 등록
                                    기간 이후부터는 각 프로그램 교육 기간이 북 코드 등록
                                    여부에 상관없이 시작되므로 여유 있게 코드를
                                    등록해주세요.
                                </p>
                                </li>
                                <li>
                                <p className="queText">
                                    ■ 신청한 프로그램 혹은 의뢰한 제작 서비스의 취소/환불이
                                    가능한가요?
                                </p>
                                <p className="ansText">
                                    사이트에서 결제 하지 않은 Off-line learning program과 
                                    XR BOOK Making Serviced의 경우 고객센터 혹은 담당자에게
                                    전화를 통해 취소/환불 진행이 가능합니다.
                                </p>
                                </li>
                                <li>
                                <p className="queText">■ 환불 확인은 언제 되나요?</p>
                                <p className="ansText">
                                    결제 당일 취소 시 환불 반영 기간은 당일입니다.
                                    <br />※ 신용카드 환불은 카드사 정책에 따라 취소 반영
                                    기간의 차이가 있으니 자세한 사항은 해당 카드사로
                                    문의바랍니다.
                                    <br />
                                    결제수단별 환불 안내
                                    <br />- 신용카드
                                    <br />
                                    &nbsp;당일 취소 시 전체 취소는 당일 승인 취소(환불)가능,
                                    부분취소는 영업일 기준 3~5일 소요
                                    <br />
                                    &nbsp;당일 이후 취소 시 영업일 기준 3~7일 소요(처리 결과
                                    카드사 통해 확인가능)
                                    <br />- 실시간 계좌이체
                                    <br />
                                    &nbsp;당일 취소 시 이체한 계좌로 즉시 환불
                                    <br />
                                    &nbsp;당일 이후 취소 시 영업일 기준 3일 소요
                                </p>
                                </li>
                                <li>
                                <p className="queText">
                                    ■ 가상계좌 결제/결제 취소는 어떤 방식으로 이뤄지나요?
                                </p>
                                <p className="ansText">
                                    -가상계좌 결제
                                    <br />
                                    &nbsp;현금 결제를 하는 경우 무통장 입금이 가능한
                                    은행을 선택하면 자동으로 결제자만의 고유한 일회용
                                    계좌가 발생되는 서비스입니다. 결제 시 결제수단
                                    선택에서 [가상 계좌] 클릭 후 입금할 은행을 선택하면
                                    가상의 계좌가 발급되며, 할당된 계좌 번호로 입금 확인이
                                    완료되면 서비스 이용이 가능합니다.
                                    <br />- 가상계좌 결제 취소
                                    <br />
                                    &nbsp;[마이페이지]→[결제 내역 및 이용 현황]→[환불/주문취소 신청]에서 
                                    결제취소하고자 하는 프로그램을 선택하고 환불 계좌를 입력하여 환불/주문 취소를 신청할 수 있습니다.
                                    결제자 이름과 동일한 소유주 명의 계좌를 입력해야 함을 유의하십시오.
                                    영업일 기준 3일정도의 시간이 소요될 수 있으며, 계좌번호를 올바로 입력하지 않으신 경우 
                                    계좌 확인을 위한 시간 지연이 발생에 대한 책임은 본인에게 있습니다.
                                    가상 계좌결제와 관련된 더 궁금한 사항은 고객센터로 문의해
                                    주시기 바랍니다.
                                </p>
                                </li>
                                <li>
                                <p className="queText">□ 더 자세한 사항은 하단의 이용약관을 참고해 주세요.</p>
                            </li>
                        </ul>
                    </div>
                </div>
                }
                {receiverItem && 
                <div>
                {spinner ? 
                <div className="deviceRentalBg">
                    <div className="deviceRentFrame">
                    <Spinner msg='결제 시 입력한 대상자 리스트를 불러오는 중입니다.'/>
                    </div>
                </div>
                    :
                    <div className="deviceRentalBg">
                        <div className="deviceRentFrame">
                            <div className="rentPopTitle">
                                <h5>프로그램 대상자</h5>
                                <div onClick={()=> this.setState({isPaymentItem:false,isRentItem:false, receiverItem : false, spinner:false, selfPaymentItem:[]})}>×</div>
                            </div>
                            <div className="programUserManage">
                                <h6>결제 시 입력한 대상자 정보</h6>
                                <ul className="payHistoryFrame">
                                    <li>이름</li>
                                    <li>전화 번호</li>
                                    <li>이메일</li>
                                    <li>재전송</li>
                                </ul>
                            { selfPaymentItem.length>0 ? selfPaymentItem.map((c, id)=> {
                                return(
                                    <div className="programUserManage" key={id}>
                                    <SelfPaymentReceiver onUpdate={this.onPropsChange} selfPaymentItem={c} rEmail= {c.rEmail}/>
                                    </div>
                                )}
                            ) : <div className="noDataText">관리자에게 문의하세요.</div>}
                            </div>
                        </div>
                    </div>
                    }
                </div>
                }
            </React.Fragment>
        )
    }
}
export default 
withFirebase (SelfPayment)
import React, { Component } from 'react'
import {withFirebase} from '../../../Firebase'
import dayjs from 'dayjs'
import { getTimeString } from '../../../helpers'
import Spinner from '../../../Utils/Spinner'
import SendSMSModal from '../../SendSMSModal'
import MyBookItem from './MyBookItem'

class MyBook extends Component {
    state = {
        myBookList : [],
        number : '',
        isModalOpen : false,
        receiver:'',
        authBookid:'',
    }
    componentDidMount(){
        this.getMyBookList()
    }
    getMyBookList =()=> {
        this.props.firebase.mybooks().orderBy('createdAt', "desc").limit(20).get()
        .then(result => {
            var lastVisible = result.docs[result.docs.length-1];
            var myBookList = []
            result.forEach(res => {
                var listData = res.data()
                var authBookid = res.id
                myBookList.push({authBookid, ...listData})
            })
            this.setState({myBookList, lastVisible})
        })
    }
    getNextMyBookList =()=> {
        let {myBookList, lastVisible} = this.state
        let scrollPointX = window.scrollX
        let scrollPointY = window.scrollY
        if(lastVisible !==undefined){
        this.props.firebase.mybooks().orderBy('createdAt', "desc").startAfter(lastVisible).limit(20).get()
        .then(result => {
            var lastVisible = result.docs[result.docs.length-1];
            result.forEach(res => {
                var listData = res.data()
                var authBookid = res.id
                myBookList.push({authBookid, ...listData})
            })
            this.setState({myBookList, lastVisible})
            window.scrollTo(scrollPointX, scrollPointY)
        })
    } else {
            alert('최신 데이터 입니다.')
        }
    }
    onPropsChange = (type, number, receiver, authBookid) => {
        
        if(type && number !== undefined){
            this.setState({
                number,
                isModalOpen : true,
                receiver: receiver,
                authBookid
        })
        } else if(!type && number !== undefined) {
            this.setState({
                number : '',
                isModalOpen : false,
                receiver: '',
                authBookid:''
        })
    } else if( number === undefined) {
        alert('해당 유저가 번호를 입력하지 않았거나 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.\n오류가 계속되면 개발자에게 문의하세요.')
        this.setState({
            number : '',
            isModalOpen : false,
            receiver: '',
            authBookid:''
    })
    }
    }
    render() {
        let {myBookList, isModalOpen, number, receiver, authBookid} = this.state
        let {totalNum} = this.props
        return (
            <React.Fragment>
                <p>수강 코드 사용자 {totalNum}명</p>
                
                <ul className="salesListCate salesList3Cate">
                    <li>등록인(수신인)</li>
                    <li>등록 일자</li>
                    <li>종료 일자</li>
                    <li>수강기간+연장</li>
                    <li>진행 상태</li>
                    <li>연장 유무</li>
                    <li>문자 안내</li>
                    <li>비고</li>
                </ul>
                            {myBookList.length>0 ? myBookList.map(c=> {
                         return (
                                <div className="salesListFrame"  key={c.authBookid} >
                                    <MyBookItem myBookList={c} onPropsChange={this.onPropsChange} codeUser={c.codeUser}/>
                                </div>
                                )
                            })
                        : <div>On-line E-Learning 프로그램 수강 코드 사용자 현황이 없습니다.</div>}
                        <button className="viewMoreListBtn" onClick={()=>this.getNextMyBookList()}>시간순 20개 더보기</button>
                        {isModalOpen && <SendSMSModal number = {number} onUpdate = {this.onPropsChange} receiver={receiver} mybookId={authBookid}/>}
            </React.Fragment>
        )
    }
}
export default withFirebase(MyBook)
import React, { Component } from 'react';
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
class Videopage extends Component {
    state = {
        url : this.props.url
    }
    getresfresh = () => {
        let newUrl = this.state.url+' '
        this.setState({
            url : newUrl
        })
    }
    render() {
        let {url} = this.state
        let {name} = this.props
        return (
            <React.Fragment>
            <span className='facilitatorVideoTitle'>{name}<span onClick={()=>this.getresfresh()} className='facilitatorVideoIcon'><FontAwesomeIcon icon={faSyncAlt} /></span></span>
                <span className="facilitatorVideoInFrame">
                    <iframe title='intro'
                            src={`https://player.vimeo.com/video/${url}`}
                            frameBorder="0"
                            allow="autoplay; fullscreen"  allowFullScreen
                    ></iframe>
                </span>
                </React.Fragment>
        );
    }
}

export default Videopage;
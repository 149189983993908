import React, { Component, useState } from 'react'
import { withFirebase } from '../../../Firebase'


class SalesEntered extends Component {
    state = {
    }
   
    render() {
        const {programList, deleteProgram} = this.props;
        return (
            <div>
            {programList.length>0 ?
                programList.map(c=> <SalesEnteredList program={c} deleteProgram={deleteProgram} getProgramList={this.getProgramList} key={c.pid}/>)
            : <div>등록된 프로그램이 없습니다.</div>
            }
        </div>
        )
    }
}

export default withFirebase(SalesEntered);

const SalesEnteredList = props => {
    const [show, setShow] = useState(false)
    const program = props.program
    // console.log(program)
    const deleteProgram = props.deleteProgram
    const site = program.site === 'tlp' ? '어린 왕자, 나를 만나다' : 'XR'
    const createdY = parseInt(program.createdAt/10000000000)
    const createdM = parseInt(program.createdAt/100000000)%100
    const createdD = parseInt(program.createdAt/1000000)%100
    // const formatterprintPrice = program.printPrice.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
    // const formatterrentPrice = program.rentPrice.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
    // const formatterprogramPrice = program.programPrice.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
    return(
        <div key={program.pid}>
            <div>프로그램 명 : {program.title}</div>
            <div>타겟 : {program.target}</div>
            {/* <div>프로그램 기본 가격 : {formatterprogramPrice}</div>
            <div>책 인쇄 1부당 가격 : {formatterprintPrice}</div>
            <div>기기대여가격 : {formatterrentPrice}</div> */}
            <div>등록 년/월/일 : 20{createdY}년{createdM}월{createdD}일</div>
            <div>메인 이미지</div>
            <img src={program.mainUrl} alt="upload"
            width='400px' height='400px' />
            <div>PC용 상세정보 이미지</div>
            <img src={program.pcUrl} alt="upload"
            width='400px' height='400px' />
            <div>Mobile용 상세정보 이미지</div>
            <img src={program.mobileUrl} alt="upload"
            width='400px' height='400px' />
            {!show && <div onClick={()=>setShow(!show)}>삭제</div>}
            {show &&<div onClick={()=>deleteProgram(program)}>정말 삭제하시겠습니까?</div>}
        </div>
    )
}
import React, {useState, useEffect} from 'react';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../../Session';
import {getTimeString} from '../../helpers'
import XrMakingListApplied from './XrMakingListApplied'

const AdminXrMakingPage = props => {
    const [xrMakingList, setXrMakingList] = useState([])

    function getXrMakingList(){
        // console.log('xrMakingList')
        const newList = []
        props.firebase.tlpXrMakings().orderBy('createdAt','desc').get()
        .then(result => {
            result.forEach((doc) => {
                var uid = doc.id
                var listData= doc.data();  
                newList.push({uid, ...listData})
            })
            // console.log(newList)
            setXrMakingList(newList)
          })
        }
    function editXrMakingInfo(uid, appliedList){
    const authUserId = appliedList['authUserId']
    const xrMakingListKey = `${appliedList['createdAt']}_${appliedList['page']}`
    const xrMakingList = {}
    xrMakingList[xrMakingListKey] = appliedList
        props.firebase.tlpXrMaking(uid).set(appliedList,{merge:true})
        .then(()=> props.firebase.user(authUserId).set({xrMakingList},{merge:true}))
        .then(()=> alert('수정되었습니다.'))
        .catch(err => alert('잠시 후에 다시 시도해 주세요.\n에러 : '+err))
        
        }
    return (
        <div>
            <XrMakingListApplied
            onGetList={getXrMakingList}
            oneditList={editXrMakingInfo}
            xrMakingList={xrMakingList}/>
        </div>
    );
};
  
const condition = authUser =>
authUser
 
export default compose(
    withEmailVerification,
    withAuthorization(condition),
  )(AdminXrMakingPage);

  
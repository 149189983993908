import React, { Component } from 'react'
import { isEmail, phoneFormatter, sideTrim, getTimeString } from '../helpers'
import {withFirebase} from '../Firebase'
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Spinner from '../Utils/Spinner'
import * as ROUTES from '../../constants/routes'
import {Link} from 'react-router-dom'

class OrderXrMaking extends Component {
   state = {
        makingTypeSelect : '',
        makingTypeDirect: '',
        groupTitle :'',
        manager : this.props.authUser.username,
        email : this.props.authUser.email,
        number:'',
        contents: '',
        agree:'',
        isEmailValid: true,
        loading : false
    }
    
    validateEmail = e => {
        const emailEntered = e.target.value
        if (isEmail(this.state.email)) {
          this.setState({
            isEmailValid: true,
            email : emailEntered
          });
        } else {
          this.setState({
            isEmailValid: false,
            email : emailEntered
          });
        }
      };
      stateChange = (e) => {
        const targetName = e.target.name
        const targetValue= e.target.value
        // console.log(targetName, targetValue)
        if(targetName === 'contents'){
            this.setState({
                [targetName] : targetValue.replace(/(?:\r\n|\r|\n)/g, '<br />')
            })
        } else {
        this.setState({
            [targetName] : targetValue
        })
    }
    }
    onSubmit = async(e) => {
        e.preventDefault();
        const {makingTypeSelect, makingTypeDirect, programTitle, groupTitle, manager,
             email, number, contents } = this.state;
        this.setState({loading : true})
        const makingType = makingTypeSelect ==='direct' ? makingTypeDirect : makingTypeSelect
        const createdAt = getTimeString()
        const authUserId = this.props.authUser.uid
        const page = 'tlpXrMaking'
        const progress = 'applied'
        const progressAt = createdAt
        const memo=''; const price = ''
        const data = { makingType, authUserId, programTitle, groupTitle, manager,
             email, number, contents, progress, progressAt, createdAt, page, memo, price }
             await fetch("/api/v1/orderAlerts", {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(data)
            })
            .then(response => { response.json()
                .then((res) => {
                    
                  if(res.msg === 'success') {
                  alert('XR BOOK 제작 신청이 접수되었습니다.\n영업일 기준 2-3일 이내 회신드리겠습니다.')
                  this.props.history.push(ROUTES.XRMAKING_APPLIEDPAGE)
                } else {
                  alert('다시 시도해주세요. \n오류가 계속되면 관리자에게 문의해주세요. \nxrbook@thehrder.com')
                }
              })
              .catch(err => alert(`다시 시도해주세요. \n오류가 계속되면 관리자에게 문의해주세요. \nxrbook@thehrder.com \n에러 : ${err}`))
              })
            .catch(err => alert(`다시 시도해주세요. \n오류가 계속되면 관리자에게 문의해주세요. \nxrbook@thehrder.com \n에러 : ${err}`))
            this.setState({loading : false})
    }

    render() {
        const {makingTypeSelect, makingTypeDirect, programTitle, groupTitle, manager, email, number, contents, agree, isEmailValid, loading } = this.state;
        const numLeng = number.length
        const makingType = makingTypeSelect ==='direct' ? makingTypeDirect : makingTypeSelect
        const isInvalid =
        programTitle ==='' ||
        groupTitle === '' ||
        makingType === '' ||
        manager === '' ||
        numLeng < 9 ||
        isEmailValid === false ||
        email === '' ||
        agree === '' ||
        contents === ''
        const enteredContents =  contents.replace(/(<br>|<br\/>|<br \/>)/g, '\n')
        return (
            <React.Fragment>
            {!loading ? <div className="orderPFrame">
                <form onSubmit={this.onSubmit} method='post'>
                    <ul>
                        <li>
                            <span className="cate">제작 목적 * </span>  
                            <select name='makingTypeSelect' onChange={this.stateChange}>
                                <option value=''>제작 목적 선택</option>
                                <option value='leadership'>리더십 기억 교육</option>
                                <option value='memorial'>Memorial</option>
                                <option value='commemoration'>Commemoration</option>
                                <option value='direct'>직접입력</option>
                            </select>
                            {makingTypeSelect === 'direct' && <input type='text' name='makingTypeDirect' value={makingTypeDirect}onChange={this.stateChange}/>}
                        </li>
                        <li>
                            <span className="cate">회사/학교 명 * </span>  
                            <input type='text' name='groupTitle' value={groupTitle}
                            onChange={event => this.setState({groupTitle: sideTrim(event.target.value)})}/>
                        </li>
                        <li>
                            <span className="cate">담당자 성함 * </span>  
                            <input type='text' name='manager' value={manager}
                            onChange={event => this.setState({manager: sideTrim(event.target.value)})}/>
                        </li>
                        <li>
                            <span className="cate">이메일 * </span>  
                            <input type='text' name='email' value={email}
                            onChange={this.validateEmail}/>
                            {email !=='' && isEmailValid ? null : email !=='' && !isEmailValid ? <span>유효한 메일이 아닙니다.</span> : null}
                        </li>
                        <li>
                            <span className="cate">연락처 * </span>  
                            <input type='text' name='number' value={number}
                            onChange={event => this.setState({number: phoneFormatter(event.target.value.replace(/[^0-9]/g,""))})}/>
                        </li>
                        <li>
                            <span className="cate">내용 * </span>  
                            <textarea name='contents' value={enteredContents} placeholder='주제 / 목적 / 대상 / 요청사항 등을 작성해 주세요' onChange={this.stateChange}/>
                        </li>
                        <li className="policiesCate">
                            <span className="cate">개인정보 처리방침 * </span>  
                            <input type='checkbox' name='agree' value='checked' onChange={this.stateChange}/>&nbsp;동의합니다.
                            <span className="policiesLink"><Link to={ROUTES.POLICIES}>개인정보 처리방침 내용보기</Link></span>
                        </li>
                        <li>
                            <button disabled={isInvalid} type='submit'>XR BOOK 제작 신청하기</button>
                        </li>
                    </ul>    
                </form>
            </div>
            : <Spinner msg={`XR BOOK 제작 신청 중입니다.`}/>}
            </React.Fragment>
        )
    }
}
export default compose(
    withRouter,
    withFirebase,
  )(OrderXrMaking);
  

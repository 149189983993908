import React, { Component } from 'react'
import { withFirebase } from '../../Firebase';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
import { getTimeString } from '../../helpers';
import * as ROUTES from '../../../constants/routes'
import Spinner from '../../Utils/Spinner'
import InstituteAuthHome from './InstituteAuthMain'
import './InstituteAuth.css'


class InstituteAuthAdmin extends Component {
    state = {
        type:JSON.parse(sessionStorage.getItem('instituteAuthReader')) ? JSON.parse(sessionStorage.getItem('instituteAuthReader')).type: 'S',
        registeredNum:0,
        userList: [],
        showList : [],
        order:'자격순',
        institute:'',
        loading : false,
    }
    componentDidMount(){
        let instituteAuthReader = JSON.parse(sessionStorage.getItem('instituteAuthReader'))
        // console.log(instituteAuthReader)
        if(['S', 'SA'].indexOf(instituteAuthReader.type >-1)){
            this.setState({institute : instituteAuthReader.institute})
            this.getUserList(instituteAuthReader.institute)
        } else {
            this.props.propsChange()
        }
    }
    getUserList = (institute) =>{
        this.props.firebase.instituteAuthReaders()
        .where('institute','==', institute)
        .get()
        .then((result)=>{
          let tempList = []
          result.forEach((doc) => {
              var bid = doc.id
              var listData= doc.data();
              if(listData.type !== 'SA'){
              tempList.push({bid, ...listData})
                }
              })
              this.setState({
                userList : tempList,
                showList : tempList.sort((a,b)=>a['type']<b['type'] ? -1 : a['type']>b['type'] ? 1 : 0),
                registeredNum:tempList.length,
                loading:false,
            })
          })
      }
      
    onStateChange = e => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }
    onPropsChange = (name, value) => {
        this.setState({
            [name] : value
        })
    }
    onRefresh = (institute) => {
        this.setState({loading: true})
        this.getUserList(institute)
    }
    render() {
        let {showList, userList, order, institute, registeredNum, loading} = this.state
        return (
            <>
            {loading ? 
                <Spinner/>
                :<div className="userInfoContGroup">
                    <div style={{display:'flex', justifyContent:'flex-end'}}>
                        <div>총 {registeredNum}명</div>
                        {showList.length > 0 &&<div>({order})</div>}
                        <div className="refreshExcelBtn" 
                            onClick={() => this.onRefresh(institute)}>새로고침</div>
                    </div>
                    <div className=" dBookUserInfoGroup">
                        <div style={{justifyContent:'center'}}>
                        {userList.length > 0 && (
                          <table border="1">
                            <thead>
                              <tr>
                                <th><span style={{color : '#0075ff' , cursor:'pointer'}}
                                onClick={()=>this.setState({showList : userList.sort((a,b)=>a['createdAt']-b['createdAt']), order:'등록순'})}>순번</span></th>
                                <th><span style={{color : '#0075ff' , cursor:'pointer'}}
                                onClick={()=>this.setState({showList : userList.sort((a,b)=>a['username']<b['username'] ? -1 : a['username']>b['username'] ? 1 : 0), order:'이름순'})}>이름</span></th>
                                <th><span style={{color : '#0075ff' , cursor:'pointer'}}
                                onClick={()=>this.setState({showList : userList.sort((a,b)=>a['userId']<b['userId'] ? -1 : a['userId']>b['userId'] ? 1 : 0), order:'계정순'})}>ID</span></th>
                                <th><span style={{color : '#0075ff' , cursor:'pointer'}}
                                onClick={()=>this.setState({showList : userList.sort((a,b)=>a['userPw']<b['userPw'] ? -1 : a['userPw']>b['userPw'] ? 1 : 0), order:'이름순'})}>PW</span></th>
                                <th><span style={{color : '#0075ff' , cursor:'pointer'}}
                                onClick={()=>this.setState({showList : userList.sort((a,b)=>a['type']<b['type'] ? -1 : a['type']>b['type'] ? 1 : 0), order:'자격순'})}>관리자여부</span>
                                </th>
                                {/* <th><span style={{color : '#0075ff' , cursor:'pointer'}}>마지막 시청 시간</span></th> */}
                                {/* <th>삭제</th> */}
                              </tr>
                            </thead>
                            <tbody>
                              {showList.map((c, id) => {
                                return (
                                  <tr key={id}>
                                    <td>{id + 1}</td>
                                    <td>{c.username}</td>
                                    <td>{c.userId}</td>
                                    <td style={c.username !== c.userPw ? {color : 'red'} : {}}>{c.userPw}</td>
                                    <td> {c.type === 'S' ? '학생' : c.type ==='P' ? '교수님' : c.type ==='A' ? '관리자' : ''}</td>
                                    {/* <td>
                                      <span 
                                      onClick={() => this.deleteReader(c, id)}
                                      >
                                        ×
                                      </span>
                                    </td>  */}
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        )}
                        
                      </div>
                    </div>
                  
                </div>}
                </>
        )
    }
}
export default compose(
    withRouter,
    withFirebase,
  )(InstituteAuthAdmin);

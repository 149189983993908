import React, {useState, useEffect} from 'react';
// import logo from './logo.svg';
import { Router, Route, Switch, Redirect, Link } from 'react-router-dom';
import * as ROUTES from '../../constants/routes';
import {withAuthentication} from '../Session'
import './App.css';
//통합
import Footer from '../Footer/index'
import ServiceCenter from '../ServiceCenter'
import TermsPage from '../Terms&Policies/TermsOfService'
import PolicyPage from '../Terms&Policies/PrivacyPolicy'
import RentContractPage from '../Terms&Policies/RentContract'
import SignInPage from '../SignIn'
import SignUpPage from '../SignUp'
import EmailVerifiedPage from '../Landing/EmailVerifiedPage'
import PasswordResetPage from '../Landing/PasswordResetPage'
import PasswordForget from '../PasswordForget'
import AdminPage from '../Admin'
import UserPage from '../User'
import FacilitatorPage from '../Facilitator'
import NotFound from '../TLP/NotFound'
//어린 왕자
import TLPNavigation from '../TLP/TLPNavigation/TLPNavigation'
import TLPNavigationNonAuth from '../TLP/TLPNavigation/TLPNavigationNonAuth'
import MainPage from '../TLP/Main'
import XrMakingServicePage from '../TLP/XrMakingService'
import EventPage from '../TLP/EventPage'
import OrderPage from '../Order'
import ProgramAppliedPage from '../Landing/ProgramAppliedPage'
import OnlineInquiriesPage from '../Landing/OnlineInquiriesPage'
import XrMakingAppliedPage from '../Landing/XrMakingAppliedPage'
import SalesSuccessPage from '../Landing/SalesSuccessPage'
import Guide from '../Guide'
import AppDownloadPage from '../User/AppDownloadPage.jsx'
import AppDownloadPageTemp from '../User/AppDownloadPageTemp.jsx'
import AnimationPage from '../AnimationPage';

//디지털북
import NonAuthHome from '../Digitalbook/NonAuth/NonAuthHome'
import InstituteAuthHome from '../Digitalbook/InstituteAuth/InstituteAuthHome'
import AuthReader from '../Digitalbook/AuthReader/AuthReader'
import UserReview from '../Digitalbook/UserReview'
import UserCover from '../Digitalbook/UserCover'
import UserCheck from '../Digitalbook/Check/UserCheck'
import AdminCheck from '../Digitalbook/CheckAdmin/AdminCheck'
import OrderUser from '../Order/OrderSales/DigitalbookMini/OrderUser'

const App = () => {
  const [navHeight, setnavHeight] = useState('whole-hidden')
  return(
    <React.Fragment>
        <Switch>
        <Route exact path='/' render ={()=>(<Redirect to={ROUTES.TLP_MAIN}/>)}/>
        {/* <Route path='/book_classroom' component={NonAuthHome} />
        <Route path='/book_online' component={AuthReader} /> */}
        {/* <Route component={NotFound} /> */}
        
        <Route
            path="/xrbook/(.+)"
              render ={()=>(
                <div className={`${navHeight} pageContainer`} >
                <TLPNavigation navHeight={navHeight} onUpdate={setnavHeight}/>
                
                <div className={`contentWrapper`}>
                <Switch>
                    <Route exact path='/xrbook' render ={()=>(<Redirect to={ROUTES.TLP_MAIN}/>)}/>
                    <Route path={ROUTES.LOGIN} component={SignInPage} />
                    <Route path={ROUTES.SIGN_UP} component={SignUpPage} />
                    
                    <Route path={ROUTES.PW_RESETPAGE} component={PasswordResetPage} />
                    <Route path={ROUTES.PASSWORD_FORGET} component={PasswordForget} />

                    <Route path ={ROUTES.ADMIN} component={AdminPage} />
                    <Route path ={ROUTES.USER} component={UserPage} />

                    <Route path ={ROUTES.ORDER} component={OrderPage} />
                    <Route path ={ROUTES.PROGRAM_APPLIEDPAGE} component={ProgramAppliedPage} />
                    <Route path ={ROUTES.ONLINE_APPLIEDPAGE} component={OnlineInquiriesPage} />
                    <Route path ={ROUTES.XRMAKING_APPLIEDPAGE} component={XrMakingAppliedPage} />
                    <Route path ={ROUTES.SALES_SUCCESSPAGE} component={SalesSuccessPage} />

                    <Route path ={ROUTES.TERMS} component={TermsPage} />
                    <Route path ={ROUTES.POLICIES} component={PolicyPage} />
                    <Route path ={ROUTES.RENTCONTRACT} component={RentContractPage} />
                    <Route path ={ROUTES.SERVICE_CENTER} component={ServiceCenter} />
                    <Route path ={ROUTES.GUIDE} component={Guide} />
                    <Route path ={ROUTES.APP_DOWNLOAD} component={AppDownloadPage} />
                    <Route path ={ROUTES.APP_DOWNLOAD_TEST} component={AppDownloadPageTemp} />
                    <Route path ={ROUTES.TLP_MAIN} component={MainPage} />
                    <Route path ={ROUTES.TLP_XR_MAKING_SERVICE} component={XrMakingServicePage} />
                    <Route path ={ROUTES.TLP_EVENT} component={EventPage} />
                    <Route path={ROUTES.TLP_FACILITATORPAGE} component={FacilitatorPage} />
                    
                    <Route path ='*' component={NotFound} /> 
                    </Switch>
                </div>
                
                
                <Footer />  
            </div>
            )}/>
            <Route
            path="/digitalBook/(.+)"
              render ={()=>(
                <div>
                <Switch>
                {/* <Route exact path='/digitalBook' render ={()=>(<Redirect to={ROUTES.TLP_MAIN}/>)}/> */}
                  <Route path={ROUTES.XRBOOK_NONAUTH} component={NonAuthHome} />
                  <Route path={ROUTES.XRBOOK_INSTITUTEAUTH} component={InstituteAuthHome} />
                  <Route path={ROUTES.XRBOOK_REVIEW} component={UserReview} />
                  <Route path={ROUTES.XRBOOK_COVER} component={UserCover} />
                  <Route path={ROUTES.XRBOOK_AUTH} component={AuthReader} />
                  <Route path={ROUTES.XRBOOK_CHECK} component={UserCheck} />
                  <Route path={ROUTES.XRBOOK_CHECK_ADMIN} component={AdminCheck} />
                  <Route path={ROUTES.ORDER_DIGITALBOOK} component={OrderUser} />
                  
                  <Route path ='*' component={NotFound} />
                </Switch>
                </div>
            )}/>
            <Route
            path="/account/(.+)"
              render ={()=>(
                <div>
                  <TLPNavigationNonAuth/>
                <Switch>
                  <Route exact path={ROUTES.EMAIL_VERIFIEDPAGE} component={EmailVerifiedPage} />
                  <Route component={NotFound} />
                </Switch>
                <Footer />
                </div>
            )}/>
            <Route path={ROUTES.TLP_ANIMATIONPAGE} component={AnimationPage}/>
            <Route component={NotFound} />
            </Switch>
      </React.Fragment>
)};
export default withAuthentication(App);

import React, { Component } from 'react'
import {withFirebase} from '../../Firebase'
import dayjs from 'dayjs'
import { getTimeString } from '../../helpers'
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";

let today = dayjs(new Date)
let todayString = `${Number(getTimeString())-10000}`
let purchased_at = today.format("YYYY-MM-DD HH:mm:ss")

class ArappLogin extends Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
  }
  state = {
    pw : '',
    chapter : [true, true, true, true],
    selectedDays:[],
    formattedDays : [],
    selectedList: []
  }
  componentDidMount(){
    this.getSelectedDays()
  }
      onStateChange = (e) => {
        this.setState({
          [e.target.name] : e.target.value.trim()
        })
      }
      onPropsChange = (name, value) => {
        this.setState({
          [name] : value
        })
      }
      handleDayClick(day, { selected }) {
        const { selectedDays } = this.state;
        if (selected) {
          const selectedIndex = selectedDays.findIndex((selectedDay) =>
            DateUtils.isSameDay(selectedDay, day)
          );
          selectedDays.splice(selectedIndex, 1);
        } else {
          selectedDays.push(day);
        }
        this.setState({ selectedDays });
      }
      getSelectedDays = () => {
        let selectedList = []
        this.props.firebase.ARappLogin('kwXDmwrGVIRUadWzjdLVSwKlbUY2').once('value', (snapshot) => {
          snapshot.forEach(function(childSnapshot) {
            var uhid = childSnapshot.key
            var listData = childSnapshot.val();
            selectedList.push({date : uhid, chapter:listData.chapter})
          });
         this.setState({selectedList})
         console.log(selectedList)
        })
        
      }
      onSubmit = () => {
        let { selectedDays, chapter} = this.state
        let formattedDays = []
        if(selectedDays.length>0){
            for(let i in selectedDays){
              let seletedDay = dayjs(this.state.selectedDays[i])
              let formattedDay = seletedDay.format("YYMMDD")
              this.props.firebase.ARappLogin('kwXDmwrGVIRUadWzjdLVSwKlbUY2').child(formattedDay).set({
                chapter
              })
              formattedDays.push(formattedDay)
            }
            alert('저장되었습니다.')
            this.getSelectedDays()
        } else {
          alert('AR app 로그인 날짜를 지정해주세요.')
        }
        // console.log(selectedDays, chapter, formattedDays)
      }
      arrayChange = (idx) => {
        this.setState(state=>{
          let arr = state.chapter
          arr[idx] = !arr[idx]
          return {
            chapter : arr
          }
        })
      }
      changeDateFormat = (stringDate) => {
        stringDate = `${stringDate}`
        // console.log(stringDate)
        let date = `${stringDate.substring(0,2)}.${stringDate.substring(2,4)}.${stringDate.substring(4,6)}`
        return date
      }
    render() {
      let {selectedDays, chapter, selectedList } = this.state 
        // console.log(todayString)
         return (
            <div className='sendMsgContFrame'>
              <div className="sendMsgPopTitle">
                <h5>thehrder.ar@gmail.com ARapp 로그인 허용 날짜</h5> 
                <span onClick={()=>this.props.setshowSecret(false)}>X</span>
              </div>
              <div style={{display:'flex'}}>
                    <div style={{width:'60%', height: '100%', overflowY : 'scroll'}}>
                      {selectedList.length>0 && selectedList.map((c,id)=>{
                        // console.log(c.date)
                        return(
                          <div key={id} style={{display:'flex'}}>
                             {this.changeDateFormat(c.date)}- 
                            <div style={{display:'flex'}}>
                              {!c.chapter[0] ? <span style={{color:"gray"}}>1</span> : <span style={{color:"blue", fontWeight : 'bold'}}>1</span>},
                              {!c.chapter[1] ? <span style={{color:"gray"}}>2</span> : <span style={{color:"blue", fontWeight : 'bold'}}>2</span>},
                              {!c.chapter[2] ? <span style={{color:"gray"}}>3</span> : <span style={{color:"blue", fontWeight : 'bold'}}>3</span>},
                              {!c.chapter[3] ? <span style={{color:"gray"}}>4</span> : <span style={{color:"blue", fontWeight : 'bold'}}>4</span>}
                            </div>  
                          </div>
                        )
                      })}
                      </div>


                      <div style={{display:'flex', flexDirection:'column', width:'40%'}}>
                        <div style={{display:'flex'}}>
                          <span style={{display:'flex'}}>
                            <input type='checkbox' checked={chapter[0]} onChange={()=>this.arrayChange(0)}/>
                            <span onClick={()=>this.arrayChange(0)}>ch.1</span>
                          </span>
                          <span style={{display:'flex'}}>
                            <input type='checkbox' checked={chapter[1]} onChange={()=>this.arrayChange(1)}/>
                            <span onClick={()=>this.arrayChange(1)}>ch.2</span>
                          </span>
                          <span style={{display:'flex'}}>
                            <input type='checkbox' checked={chapter[2]} onChange={()=>this.arrayChange(2)}/>
                            <span onClick={()=>this.arrayChange(2)}>ch.3</span>
                          </span>
                          <span style={{display:'flex'}}>
                            <input type='checkbox' checked={chapter[3]} onChange={()=>this.arrayChange(3)}/>
                            <span onClick={()=>this.arrayChange(3)}>ch.4</span>
                          </span>
                        </div>
                          <DayPicker selectedDays={selectedDays} onDayClick={this.handleDayClick} />
                      <button onClick={()=>this.onSubmit()}>저장</button>
                      </div>
              </div>
              
            </div>
        )
    }
}
export default withFirebase (ArappLogin)
import React, { Component, useState, useEffect } from 'react'
import { withFirebase } from '../../../Firebase'
import PageGalleryItem from './PageGalleryItem'
import GalleryItem from './GalleryItem'
import $ from 'jquery'

class ChapterItem extends Component {
    state = {
        sharingPageList : [],
        isModalOpen : false,
        modalIdx:0,
        index : 0,
        type:'',
        initialType:'',
        initialChapter: 1,
        letcureTitle:'',
        letcureSubTitle:''
    }
    componentDidMount(){
        this.getSharingPageList()
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.chapter !== this.props.chapter){
            this.getSharingPageList()
        }
    }
    getSharingPageList =()=> {
        let {chapter} = this.props
        // console.log(chapter)
        if(chapter){
            this.props.firebase.sharingPages().where('chapter','==', chapter).orderBy('createdAt', "desc").limit(20).get()
            .then(result => {
                var lastVisible = result.docs[result.docs.length-1];
                var sharingPageList = []
                result.forEach(res => {
                    var listData = res.data()
                    var sharingPageId = res.id
                    sharingPageList.push({sharingPageId, ...listData})
                })
                this.setState({sharingPageList, lastVisible})
            })
            } else {
                this.props.firebase.ARappActivitys().orderBy('createdAt', "desc").limit(20).get()
                .then(result => {
                    var lastVisible = result.docs[result.docs.length-1];
                    var sharingPageList = []
                    result.forEach(res => {
                        var listData = res.data()
                        var sharingPageId = res.id
                        sharingPageList.push({sharingPageId, ...listData})
                    })
                    // console.log(sharingPageList)
                //     let list = [{page:'https://firebasestorage.googleapis.com/v0/b/xr-book.appspot.com/o/tlp%2Fdigitalbook%2Fadmin%2F200806105232_%EC%96%B4%EB%A6%B0%EC%99%95%EC%9E%90_%EB%88%84%EB%81%BC.jpg?alt=media&token=ff0f9066-5c80-4002-8ba8-74c14360b23b',
                // isShowing:true, createdAt : '200911123456', mybookId :'', authUserUid : 'appAuth', authUserName:'박원정', sharingPageId:'1234',
                //  authUserEmail:'wj.park@thehrder.com', type:'appImage', chapter: 2}]
                    this.setState({sharingPageList, lastVisible})
                })
            }
    }
    getNextSharingPageList =()=> {
        let {chapter} = this.props
        // console.log('chapter'+chapter)
        let scrollPointX = window.scrollX
        let scrollPointY = window.scrollY
        let {sharingPageList, lastVisible} = this.state
        if(lastVisible !==undefined){
            if(chapter){
                this.props.firebase.sharingPages().where('chapter','==', chapter).orderBy('createdAt', "desc").startAfter(lastVisible).limit(20).get()
                .then(result => {
                    var lastVisible = result.docs[result.docs.length-1];
                    result.forEach(res => {
                        var listData = res.data()
                        var sharingPageId = res.id
                        sharingPageList.push({sharingPageId, ...listData})
                    })
                    this.setState({sharingPageList, lastVisible})
                    window.scrollTo(scrollPointX, scrollPointY)
                })
            } else {
                this.props.firebase.ARappActivitys().orderBy('createdAt', "desc").startAfter(lastVisible).limit(20).get()
                .then(result => {
                    var lastVisible = result.docs[result.docs.length-1];
                    result.forEach(res => {
                        var listData = res.data()
                        var sharingPageId = res.id
                        sharingPageList.push({sharingPageId, ...listData})
                    })
                    this.setState({sharingPageList, lastVisible})
                    window.scrollTo(scrollPointX, scrollPointY)
                })
            }
    } else {
        alert('마지막 데이터 입니다.')
    }
    }

onPropsChange = (name, value) => {
    this.setState({
        [name] : value
    })
}
onUrlChange = id => {
    this.setState({
      showPhotos : id
    })
  }
  
  setSlide = index => {
    this.setState({index})
  
  }
  modalOpen = (id, status, type, chapter, authUserName, authUserEmail) => {
    if(status === true){
        $('html, body').css("overflow", "hidden");
        
    } else {
        $('html, body').css("overflow", "inherit")
    }
    this.setState({isModalOpen: status, modalIdx : id, initialType: type , initialChapter: chapter, letcureTitle : authUserName, letcureSubTitle : authUserEmail})
  }
//   modalOpen = (id, status, type) => {
//     this.setState({isModalOpen: status, modalIdx : id, type})
//   }
    render() {
        let {sharingPageList, isModalOpen, modalIdx, type, initialType, initialChapter, letcureTitle, letcureSubTitle} = this.state
        return (
            <React.Fragment>
            <div className="galleryContBox">
                <ul className="albumFrame">
                {sharingPageList.length>0 ? sharingPageList.map((c, id)=> {
                    let {sharingPageId, type, isShowing, chapter
                    } = c
                    return(
                        <li key={sharingPageId}>
                            {/* <span>{c.authUserName}</span> */}
                            <GalleryItem item={c} idx={id} isShowing={isShowing} chapter={chapter}
                            deleteGalleyItem={this.deleteGalleyItem} modalOpen={this.modalOpen}/>
                        </li>

                    )
                }):<span>공유된 이미지가 없습니다.</span>}
              
                </ul>
            </div>
             {isModalOpen && 
             <GalleryModal urlList={sharingPageList} modalIdx={modalIdx} initialChapter={initialChapter} letcureTitle={letcureTitle} letcureSubTitle={letcureSubTitle}
             initialType={initialType} modalOpen={this.modalOpen}  getNextSharingPageList={this.getNextSharingPageList}/>
              }
             </React.Fragment>
        )
    }
}
export default withFirebase(ChapterItem)

const GalleryModal = props => {
    let { urlList, modalIdx, modalOpen, initialType, getNextSharingPageList, initialChapter, letcureTitle, letcureSubTitle} = props
    const [idx, setidx] = useState(modalIdx)
    const [type, settype] = useState(initialType)
    useEffect(() => {
      setidx(modalIdx)
      settype(urlList[modalIdx].type)
    }, [modalIdx])
    useEffect(() => {
        settype(urlList[idx].type)
        if(urlList[idx].type !=='digitalbook'){
            $('PageGalleryItem').hide()
        } else {
            $('PageGalleryItem').show()
        }
        // console.log(idx)
      }, [idx])
    let listLeng = urlList.length
    function getNextList (idx){
        getNextSharingPageList()
    }
    console.log(initialChapter)
    return(
        <div className="galleryPopFrame userGallPopFrame disabledDrag">
          <div className="galleryPopBg userGallPopBg"  onClick={()=>modalOpen(0,false)}></div>
          <div className="galleryPopBox userGallPopBox">
              {/* <div></div> */}
              {type === 'digitalbook' ?
              <div className="galleryPopImage userGallPopImage">
                <div className='PageGalleryItem preventDrag'>
                    <PageGalleryItem pageItem={urlList[idx]['page']}/>
                </div>
                {/* <div className="transparentCover"></div> */}
                {/* <span className="galleryPopDel userGallPopDel" onClick={()=>modalOpen(0,false)}>×</span>
                <span className="galleryPopPrev userGallPopPrev" style={idx===0 ? {color:"rgb(255,255,255, 0.2)"}: null} onClick={()=> idx >0 ? setidx(idx-1) : alert('최신 데이터 입니다.')}>&#60;</span>
                <span className="galleryPopNext userGallPopNext" style={idx===listLeng-1 ? {color:"rgb(255,255,255, 0.2)"}: null} onClick={()=> idx < listLeng-1 ? setidx(idx+1) : getNextList()}>&#62;</span> */}
              </div>
              :
              <>
             
              <div className={`galleryPopImage galleryPopImage2 arActivityPopImage ${initialChapter == 6 ? 'galleryPopImage3' : ''}`}
              style={{display:'flex', justifyContent:'center'}}
            //   style={{backgroundImage : "url("+urlList[idx].page+")"}}
              >
                   {initialChapter == 6 ? <div className='galleryPoptitle'>
                       <div className='letcureTitle'>{letcureTitle}</div>
                       <div className='letcureSubTitle'>{letcureSubTitle}</div>
                       
                       </div> : null}
                  <img src={urlList[idx].page}/>
                {/* <div className="transparentCover"></div> */}
              </div>
              </>
              }
            <span className="galleryPopDel" onClick={()=>modalOpen(0,false)}>×</span>
            <span className="galleryPopPrev" style={idx===0 ? {color:"rgb(255,255,255, 0.2)"}: null} onClick={()=> idx >0 ? setidx(idx-1) : alert('최신 데이터 입니다.')}>&#60;</span>
            <span className="galleryPopNext" style={idx===listLeng-1 ? {color:"rgb(255,255,255, 0.2)"}: null} onClick={()=> idx < listLeng-1 ? setidx(idx+1) : getNextList()}>&#62;</span>
          </div>
        </div>
    )
  }
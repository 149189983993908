import React, { Component } from 'react'
import {withFirebase} from '../Firebase'
import {getTimeString, getTimeDayjs} from '../helpers'
import Spinner from '../Utils/Spinner'
import dayjs from 'dayjs'

class SendSMSModal extends Component {
    state={
        subject:'',
        subMsg:'',
        content:'',
        stringByteLength:0,
        msg : '',
        styles: false,
        exText:'textarea',
        SMSList:[],
        spinner : false
    }
    componentDidMount(){
        this.getSMSList()
    }
    componentDidUpdate(prevProp, prevState){
        if(prevProp.mybookId!==this.props.mybookId){
            this.getSMSList()
            this.setState({
                    subject:'',
                    subMsg:'',
                    content:'',
                    stringByteLength:0,
                    msg : '',
                    styles: false,
                    exText:'textarea',
                    SMSList:[],
                    spinner : false
            })
        }
    }
    onCheckTextAreaLeng = (e) => {
        let textValue = e.target.value
        let stringByteLength = this.getByteB(textValue) 
        this.setState({
            stringByteLength,
            content: textValue
        })
        if(stringByteLength>80){
          this.setState({
              msg : '80bytes를 초과하여 작성하시면 LMS로 전송됩니다.',
              styles : true
          })
        } else {
            this.setState({
                msg : '',
                styles : false,
                subject: ''
            })
        }
      }
      sendSMS = () => {
          this.setState({
             spinner:true
          })
          let {number, receiver, onUpdate, mybookId} = this.props
          let smsNumber = number.split('-').join("")
          let { content, subject, stringByteLength } = this.state
            // let stringByteLength = this.getByteB(content)
          let data = {
            type:`${stringByteLength>80 ? 'LMS' : 'SMS'}`,
            contentType:"COMM",
            subject:`${subject === '' ? "XR BOOK 알림 메세지입니다." : subject}`,
            content: content,
            to: smsNumber,
        }
        let result = window.confirm(`${receiver}님께 문자를 보내시겠습니까?`)
        if(result){
            fetch("/api/v1/sendSMS/messages", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json; charset=utf-8'
            },
            body : JSON.stringify(data)
        })
        .then(res=> {
            // console.log(res.json())
            if(res.status === 200){
            alert('문자 전송이 완료되었습니다.')
            let history = {
                sendAt : getTimeString(),
                ...data
            }
            this.setSMSList(history)
            this.setState({
                msg : '',
                styles : false,
                subject: '',
                content : '',
                subMsg : '',
                stringByteLength:0,
                subjectByteLength:0,
                spinner : false

            })
            } else  {
                this.setState({
                    spinner : false
                })
                alert('잠시 후 다시 시도해주세요. 에러코드 : '+res.status)
            }
            
        })
        .catch(err=>{
            this.setState({
                spinner:false
             })
            alert('잠시 후 다시 시도해 주세요.\n에러코드 : '+err)
        })
        } 
      }
      setSelectedText = (textValue, exText) => {
        let stringByteLength = this.getByteB(textValue) 
        // (function(s,b,i,c){
        //     for(b=i=0;c=s.charCodeAt(i++);b+=c>>11?3:c>>7?2:1);
        //     return b
        // })(textValue);
        this.setState({
            stringByteLength,
            content : textValue,
            exText
        })
        if(stringByteLength>80){
            this.setState({
                msg : '( 본문 내용이 80bytes를 초과하여 LMS로 전송됩니다 )',
                styles : true
            })
          } else {
            this.setState({
                msg : '',
                styles : false
            })
          }
      }
      getByteB(str) {
        var byte = 0;
        for (var i=0; i<str.length; ++i) {
        (str.charCodeAt(i) > 127) ? byte += 2 : byte++ ;
        }
        return byte;
        }
      setSubjectText = (e) => {
        let textValue = e.target.value
        let subjectByteLength = this.getByteB(textValue) 
        // (function(s,b,i,c){
        //     for(b=i=0;c=s.charCodeAt(i++);b+=c>>11?3:c>>7?2:1);
        //     return b
        // })(textValue);
        if(subjectByteLength>40){
            let subStringText = textValue.substring(0,40)
            this.setState({
                subMsg : '40bytes를 초과하여 작성할 수 없습니다.',
                subjectByteLength : subjectByteLength,
                subject: subStringText
            })
          } else if(subjectByteLength<=40) {
            this.setState({
                subMsg : '',
                subject : textValue,
                subjectByteLength
            })
          }
      }
      getSMSList = () => {
          let {mybookId} = this.props
        this.props.firebase.tlpEvent(mybookId).get()
        .then(res => {
                var initialData = res.data()
                let history =  initialData['SMSList'] || []
                if(history|| history.length>0){
                    history = history.sort((a,b)=> a.sendAt-b.sendAt)
                }
            this.setState({
                SMSList : history
            })
        })
      }
      setSMSList = (history) => {
        let {mybookId} = this.props
        let {SMSList} = this.state
        let tempList = SMSList.slice()
        tempList.push(history)
        this.props.firebase.tlpEvent(mybookId).set({
            SMSList:tempList
        },{merge:true})
        .then(() => {
            this.setState({
                SMSList:tempList
            })
        })
          
    }
    render(){
        let { onUpdate } =this.props
        let { content, stringByteLength,subjectByteLength,  styles, msg, subMsg,  exText, subject, SMSList, spinner}= this.state
        // let text1 = 'xrbook.net에서 수강 중인 프로그램이 곧 종료 됩니다. 수강을 서둘러 주세요!'
        // let text2 =  'xrbook.net에서 작성한 나만의 디지털 북 인쇄가 완료되어 오늘 발송 예정입니다.'
        // let text3=  'xrbook.net에서 수강 중인 프로그램에 포함된 대여 물품이 오늘 발송 예정입니다.'
        // let text4=  'xrbook.net에서 수강 중인 프로그램에 포함된 구매 기기가 오늘 발송 예정입니다.'
        // 줄바꿈확인
        return(
            <div className="sendMsgContBg"
            //배경 닫기 함수
            // onClick={()=>onUpdate(false, '')}
            >
                <div className="sendMsgContFrame">
                    <div className="sendMsgPopTitle">
                        <h5>문자 전송 내역</h5> 
                        <div onClick={()=>onUpdate('isModalOpen', false)}>×</div>
                    </div>
                
                    {SMSList.length>0 ? SMSList.map((c, id)=> {
                        let {sendAt, content, contentType, subject, type} = c
                    var sendAtFormatted2 = getTimeDayjs(sendAt)
                        return(
                            <div key={id}>
                                <span>{sendAtFormatted2} :</span>
                                <span>{contentType ==='COMM' ? '알림' : '광고'} / </span>
                                <span>{type ==='SMS' ? '단문' : '장문'} / </span>
                                <span>{type ==='SMS' ? '제목 없음' : subject} / </span>
                                <div>{content}</div>
                            </div>
                        )
                    }):
                    <p>문자 전송 내역이 없습니다.</p>
                    }
               
                    {spinner ? 
                    <Spinner msg=" "/>
                    :
                    <React.Fragment>
                        <div>
                            {/* <ul className="selectMsgGroup">
                                <li>
                                    <input type='checkbox' checked = {exText=== 'text1'} onChange={()=>this.setSelectedText(text1 , 'text1')}/>
                                    <span onClick={()=>this.setSelectedText(text1 , 'text1')}>북 코드 종료 전</span>
                                </li>
                                <li>
                                    <input type='checkbox' checked = {exText=== 'text2'} onChange={()=>this.setSelectedText(text2, 'text2')}/>
                                    <span onClick={()=>this.setSelectedText(text2 , 'text2')}>인쇄/배송 전</span>
                                </li>
                                <li>
                                    <input type='checkbox' checked = {exText=== 'text3'} onChange={()=>this.setSelectedText(text3, 'text3')}/>
                                    <span onClick={()=>this.setSelectedText(text3 , 'text3')}>대여 기기 발송 전</span>
                                </li>
                                <li>
                                    <input type='checkbox' checked = {exText=== 'text4'} onChange={()=>this.setSelectedText(text4, 'text4')}/>
                                    <span onClick={()=>this.setSelectedText(text4 , 'text4')}>구매 기기 발송 전</span>
                                </li>
                                <li>
                                    <input type='checkbox' checked = {exText === 'textarea'} onChange={()=>this.setSelectedText('' , 'textarea')}/>
                                    <span>기타(직접 입력)</span>
                                </li>
                            </ul> */}

                            {styles && 
                            <div className="longSentenceTitleBx">
                                <div className="longSentenceTitle">
                                    <span>장문 메세지 제목</span>
                                    <input type='text' value={subject} name='subject' placeholder='XR BOOK 알림 메세지입니다.' onChange={(e)=>this.setSubjectText(e)}/>
                                </div>
                                <div className="wordLimitText">
                                    <p style={{color:'#c56e6e'}}>{subMsg}</p>
                                    <span>{subjectByteLength} Bytes</span>
                                </div>
                            </div>
                            }

                            {exText === 'textarea'?
                            <div className="longSentencesBx">
                                <textarea name='content' value = {content} onChange={(e)=> this.onCheckTextAreaLeng(e)} styles={styles ? {border:"none", outline:'2px solid red'} : null}/>
                            </div>
                            : 
                            <div className="paragraphBx">{content && content.split('\n').map((c, id)=> {
                                return(
                                        <span key={id}>{c}<br/></span>
                                    )})}
                                </div>
                            }
                        </div>
                        <div className="wordLimitText">
                            <p style={{color:'#c56e6e'}}>{msg}</p>
                            <span>{stringByteLength} Bytes</span>
                        </div>
                        <div className="sendMsgPopBtn">
                            <button disabled={subMsg!==''} onClick={()=>this.sendSMS()}>전송</button>
                        </div>
                    </React.Fragment>
                    }
                </div>
            </div>
            )
        }
    }

    export default withFirebase(SendSMSModal)
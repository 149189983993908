import React, { Component } from 'react';
import '../DObook.css'
import PagePriviewItem from '../BookItem/PagePriviewItem'
import './Preview.css'
import './PreviewResp.css'
import $ from "jquery";
class Digitalbook_PC_Preview extends Component {
    state={
        pageList:this.props.previewPageList,
        pageListFront:[],
        pageListBack:[],
        pageId:0,
        pcPageId:0,
        prevBtn : 'invalidBtn',
        nextBtn : 'invalidBtn'
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.previewPageList !== this.props.previewPageList){
            this.setState({
                pageList : this.props.previewPageList
            })
            if(this.props.previewPageList.length === 1){
            this.setState({prevBtn:'invalidBtn', nextBtn:'invalidBtn'})}
        else if(this.props.previewPageList.length > 1 && this.state.pageId < this.props.previewPageList.length-1){
                this.setState({prevBtn:'invalidBtn', nextBtn:'validBtn'})}
        }

        if(prevState.pageList !== this.state.pageList){
            let pageListFront = this.state.pageList.filter((c,id)=>id%2===0)
            let pageListBack = this.state.pageList.filter((c,id)=>id%2===1)
            if(pageListBack.length !== pageListFront.length){
                pageListBack.push({userSection:[], pageTitle:'', pageHeader:'hidden',pageBgImgUrl:'',pageBgImgColor:'white'})
            }
            this.setState({
                pageListFront, pageListBack
            })
        }
        
        if(prevState.pageId !== this.state.pageId){
            let halfLeng = parseInt((this.props.previewPageList.length+1)/2)
            if (halfLeng === 2 && this.state.pageId === 1){
                this.setState({ prevBtn:'validBtn', nextBtn:'invalidBtn'})
            }
            else if (halfLeng === 2 && this.state.pageId === 0){
                this.setState({ prevBtn:'invalidBtn', nextBtn:'validBtn'})
            }
            else if(this.state.pageId === 0  && halfLeng>2){
                this.setState({ prevBtn:'invalidBtn', nextBtn:'validBtn'})
            } 
            else if (halfLeng > 2 && this.state.pageId === halfLeng-1){
                this.setState({ prevBtn:'validBtn', nextBtn:'invalidBtn'})
            } else {
                this.setState({
                    nextBtn:'validBtn',
                    prevBtn:'validBtn'
                })
            }
            this.setState({
                pcPageId :parseInt((this.state.pageId+1)/2),
            })
    }
    // if(prevState.pcPageId !== this.state.pcPageId){
    //     this.setState({
    //         pageId :(this.state.pcPageId)*2
    //     })
    // } 
}
componentDidMount(){
    let pageListFront = this.state.pageList.filter((c,id)=>id%2===0)
            let pageListBack = this.state.pageList.filter((c,id)=>id%2===1)
            if(pageListBack.length !== pageListFront.length){
                pageListBack.push({userSection:[], pageTitle:'', pageHeader:'hidden',pageBgImgUrl:'',pageBgImgColor:'white'})
            }
            this.setState({
                pageListFront, pageListBack
            })
}
handleFrontChange = (e) => {
    if (["writingValue"].includes(e.target.className) ) {
      let currentPageList = this.state.pageList.slice()
      currentPageList[this.state.pageId].userSection[e.target.dataset.id][e.target.className] = e.target.value
      this.setState({
           pageList: currentPageList, 
        })
    } else if(["textarea"].includes(e.target.className)) {
        let currentPageList = this.state.pageList.slice()
      currentPageList[this.state.pageId].userSection[e.target.dataset.id]['writingValue'] = e.target.value.replace(/(?:\r\n|\r|\n)/g, '<br />')
      this.setState({
           pageList: currentPageList, 
        })
    }
  }
handleBackChange = (e) => {
    if (["writingValue"].includes(e.target.className) ) {
      let currentPageList = this.state.pageList.slice()
      currentPageList[this.state.pageId+1].userSection[e.target.dataset.id][e.target.className] = e.target.value
      this.setState({
           pageList: currentPageList, 
        })
    } else if(["textarea"].includes(e.target.className)) {
        // console.log(e.target.value)
        let currentPageList = this.state.pageList.slice()
      currentPageList[this.state.pageId+1].userSection[e.target.dataset.id]['writingValue'] = e.target.value.replace(/(?:\r\n|\r|\n)/g, '<br />')
      this.setState({
           pageList: currentPageList, 
        })
    }
  }
  drawingFrontChange = (className, contents, idx) => {
    let currentPageList = this.state.pageList.slice()
    currentPageList[(this.state.pageId)*2].userSection[idx][className] = contents
    this.setState({pageList : currentPageList});
  }
  drawingBackChange = (className, contents, idx) => {
  let currentPageList = this.state.pageList.slice()
  currentPageList[(this.state.pageId)*2+1].userSection[idx][className] = contents
  this.setState({pageList : currentPageList});
}
  onPageChange = type => {

    if (type ==='prev'){
        //이전페이지 함수
        $('.bookPagePC').removeClass('ready');
        $('.bookPagePC.flipped').last().removeClass('flipped').addClass('active')
            .siblings('.bookPagePC').removeClass('active');
        $('.bookPagePC.active').next().addClass('ready');
        $('.bookPagePC.flipped').addClass('pointerEventsNone');
        $('.bookPagePC.flipped').last().removeClass('pointerEventsNone');
        $('.bookPagePC.pointerEventsNone').last().addClass('pageShow');
    } else if (type ==='next') {
        //다음페이지 함수
        $('.bookPagePC').removeClass('ready');
        $('.bookPagePC.active').removeClass('active').addClass('flipped')
            .next('.bookPagePC').addClass('active');
        $('.bookPagePC.flipped').addClass('pointerEventsNone');
        $('.bookPagePC.flipped').last().removeClass('pointerEventsNone');
        $('.bookPagePC.pointerEventsNone').last().addClass('pageShow');
    }
}

onPropsChange = (name, value) => {
this.setState({
    [name] : value
})
}
onTextToPage = (id) => {
    console.log(id)
    $(".headingTextBox").removeClass("showHeading");
    $(".bookMarkbox").removeClass("show").addClass("hidden");
    $(".bookMarkList").removeClass("listShow");
    // console.log('id '+id)
    if(id%2 === 0){
        let lastPage=parseInt((id)/2)-1
        // console.log(parseInt((id+1)/2)+','+(this.state.pageList.length/2))
        if(parseInt((id)/2) >= parseInt(this.state.pageList.length/2)){
            $('.bookPagePC').removeClass('ready flipped active pointerEventsNone pageShow');
            $(".bookPagePC"+parseInt((id)/2)).addClass("active");
            $(".bookPagePC"+lastPage).addClass("flipped");
            $(".bookPagePC"+lastPage).prevAll().addClass("flipped pointerEventsNone");
            $(".bookPagePC"+lastPage).prev().prev().addClass("pageShow");
            // console.log('evenF1 '+parseInt((id)/2))
        } else {
            $('.bookPagePC').removeClass('ready flipped active pointerEventsNone pageShow');
            $(".bookPagePC"+parseInt((id)/2)).addClass("active");
            $(".bookPagePC"+parseInt((id)/2)).prevAll().addClass("flipped pointerEventsNone");
            $(".bookPagePC"+parseInt((id)/2)).prev().prev().addClass("pageShow");
            $(".bookPagePC"+lastPage).removeClass("pointerEventsNone");
            $(".bookPagePC"+parseInt((id)/2)).next().addClass("ready");
            // console.log('evenF2 '+parseInt((id)/2))
        }
        // console.log('evenF '+parseInt((id)/2))
    } else {  
        let lastPage=parseInt((id+1)/2)-1
        // console.log('oddF '+(this.state.pageList.length/2))
        if(parseInt((id+1)/2) === parseInt(this.state.pageList.length/2)){
            $('.bookPagePC').removeClass('ready flipped active pointerEventsNone pageShow');
            $(".bookPagePC"+lastPage).addClass("flipped");
            $(".bookPagePC"+lastPage).prevAll().addClass("flipped pointerEventsNone");
            $(".bookPagePC"+lastPage).prev().prev().addClass("pageShow");
            // $(".bookPagePC"+parseInt((id+1)/2)-1).removeClass("pointerEventsNone")
            // $(".bookPagePC"+parseInt((id+1)/2)).next().addClass("ready");
            // console.log('oddF1 '+lastPage)
        } else {
            $('.bookPagePC').removeClass('ready flipped active pointerEventsNone pageShow');
            $(".bookPagePC"+parseInt((id+1)/2)).addClass("active");;
            $(".bookPagePC"+parseInt((id+1)/2)).prevAll().addClass("flipped pointerEventsNone");
            $(".bookPagePC"+parseInt((id+1)/2)).prev().prev().addClass("pageShow");
            $(".bookPagePC"+lastPage).removeClass("pointerEventsNone");
            $(".bookPagePC"+parseInt((id+1)/2)).next().addClass("ready");
            // console.log('oddF2 ')
        }
    }
}
onTextBox = () => {
    $(".headingTextBox").toggleClass("showHeading");

}
    render() {
        let {prevBtn, nextBtn, pageId, pageList, pageListFront, pageListBack, pcPageId } = this.state
        let frontPage = pageListFront[pcPageId]
        let backPage = pageListBack[pcPageId]
        let pageHeaderList = this.state.pageList.filter(c=> c.pageHeader !== 'hidden')
     
        return (
            <div className="previewFrame pcPreviewFrame" style={{display:'flex', flexDirection:'row'}}>
                
                <div className="bookPageFrame">
                    <div className="headingTextBox">
                        {pageHeaderList && pageHeaderList.map((c, id)=> {
                            // let idx = this.state.pageList.indexOf(page)
                            let pageNum = this.state.pageList.indexOf(c)
                        return(
                            <div key={id}
                            onClick={()=> 
                                this.onTextToPage(id)
                            }
                            className={c.pageHeader === 'header1' ?   `heading1`
                                            : c.pageHeader === 'header2' ? `heading2`
                                            : c.pageHeader === 'header3' ?  `heading3`
                                            : c.pageHeader === 'hidden' ?  `noHeading`
                                            : `heading5`}
                                            >
                            {c.pageTitle}</div>
                        )})}
                    </div>
                    <div className="scene">
                        <div className="bookPageGroup">
                            {pageListFront && pageListFront.map((c,id) => {
                            return (
                                    <div key={id}
                                        className={id === 0 ?   `bookPagePC bookPagePC${id} active`
                                                : `bookPagePC bookPagePC${id}`}>
                                            <form className="frontPageBox">
                                                <PagePriviewItem pageItem={c} handleChange={this.handleFrontChange}/>
                                            </form>
                                            <form className="backPageBox">                      
                                                <PagePriviewItem pageItem={pageListBack[id]} handleChange={this.handleBackChange}/>
                                            </form>
                                    </div>
                                )})
                            }
                        </div>
                    </div>
                </div>
                <div className="utilityBtnGroup">
                    <div className="headingBtnBox">
                        <span className="headingBtn" onClick={()=>this.onTextBox()}>목차</span>
                    </div>
                    <div className="bookBtnBox">
                        <span className="curPageSign">{pcPageId} / {pageListFront.length}</span>
                        <span className={`${prevBtn} prevBtn`} onClick={()=>this.onPageChange('prev')}>이전</span>
                        <span className={`${nextBtn} nextBtn`} onClick={()=>this.onPageChange('next')}>다음</span>
                    </div>
                </div>
            </div> 
        )
    }
}
export default Digitalbook_PC_Preview
import React, { Component } from 'react'
import { withFirebase } from '../../Firebase';
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
import { getTimeString } from '../../helpers';
import * as ROUTES from '../../../constants/routes'
import Spinner from '../../Utils/Spinner'
import NonAuthHome from './NonAuthMain'
import './NonAuth.css'


class NonAuthCheck extends Component {
    state = {
        email:JSON.parse(sessionStorage.getItem('nonAuthReader')) ? JSON.parse(sessionStorage.getItem('nonAuthReader')).email:  '',
        username:JSON.parse(sessionStorage.getItem('nonAuthReader')) ? JSON.parse(sessionStorage.getItem('nonAuthReader')).username : '',
        nonAuthReaderData : {},
        confirmedList:[],
        totalList:[],
        expiredList : [],
        emptyDaysList:[],
        workBookList:[],
        loading: false,
        spinnerMsg : '로그인 정보를 불러오는 중입니다.',
        show:false
    }
    componentDidMount(){
        let nonAuthReader = sessionStorage.getItem('nonAuthReader')
        if(nonAuthReader){
            let {email, username} = JSON.parse(nonAuthReader)
            this.setState({email, username})
            this.onNonAuthLogin(email, username, this.onNonAuthCheck)
        }
    }
    onNonAuthLogin = (email, username, next) =>{
        this.setState({
            loading:true
        })
        let nonAuthReaderData = []
        this.props.firebase.nonAuthReaders().where('email','==', email).where('name','==', username).orderBy('createdAt','desc')
        .get()
        .then(result => {
            result.forEach((doc)=> {
                var docData = doc.data()
                let nonAuthBookId = doc.id
                nonAuthReaderData.push({...docData, nonAuthBookId})
            })
            if(nonAuthReaderData.length > 0){
                let nonAuthReader = {email, username }
                sessionStorage.setItem('nonAuthReader', JSON.stringify(nonAuthReader));
                this.setState({
                    spinnerMsg : '등록된 프로그램 정보를 불러오는 중입니다.'
                })
                next(nonAuthReaderData)
            } else {
                alert('디지털북 이용 프로그램에 등록되지 않았거나 잘못 입력된 메일/이름입니다.')
                this.setState({loading:false})
            }
        })
        .catch(err => alert('입력하신 정보를 다시 확인 후 재 시도 해주세요.\n'+err))
    }
    onNonAuthCheck = (result) => {
    let totalList = []
    let confirmedList = []
    let confirmedInvalidList = []
    let expiredList = []
    let emptyDaysList = []
    let workBookList = []
    let nonAuthReaderData = result.filter(function (a) {
        if (!this[a.tlpProgramId]) {
            return this[a.name] = true;                    
        }
    }, Object.create(null));
    for (let i in nonAuthReaderData){
        let nonAuthData = nonAuthReaderData[i]
            this.props.firebase.tlpProgram(nonAuthData.tlpProgramId).get()
                .then(res=>{
                    let programData = res.data()
                    // if(programData.book === '디지털북'){
                    totalList.push({...programData, ...nonAuthData, username : nonAuthData.name})
                    let selectDays = programData.selectedDays
                    if(programData.book === '디지털북'){
                    if (selectDays&&selectDays.length >0 ){
                        // selectDays.sort((a,b)=> a-b)
                            let sysdate = getTimeString()
                            const isValidDate = (day) => (day*1) - 120000 <= sysdate && sysdate < (day*1) + 120000
                            const isExpiredDate = (day) => sysdate > (day*1) + 120000
                                if(selectDays.some(isValidDate)){
                                        if(nonAuthData.bookData){
                                            confirmedList.push({...programData, ...nonAuthData, username : nonAuthData.name})
                                        } 
                                        else if(!nonAuthData.bookData){ 
                                            // 디지털북 저장, totalList 저장
                                            this.setState({
                                                spinnerMsg : '등록된 XR BOOK 정보를 불러오는 중입니다.'
                                            })
                                                // if(programData.book !== '디지털북'){
                                                //     workBookList.push({...programData, ...nonAuthData, username : nonAuthData.name})
                                                // }else 
                                                if(programData.digitalBookID){
                                                    confirmedList.push({...programData, ...nonAuthData, username : nonAuthData.name})
                                                } else {
                                                    // alert('디지털북 사용 가능 회원이 아니거나 디지털북이 설정되지 않았습니다.')
                                                    confirmedInvalidList.push({...programData, ...nonAuthData, username : nonAuthData.name})
                                                }
                                       
                                    } 
                            } else if (selectDays.every(isExpiredDate)){
                                expiredList.push({...programData, ...nonAuthData, username : nonAuthData.name})
                            }
                            else {
                                emptyDaysList.push({...programData, ...nonAuthData, username : nonAuthData.name})
                            }
                            } else {
                                emptyDaysList.push({...programData, ...nonAuthData, username : nonAuthData.name})
                            }} else {
                                workBookList.push({...programData, ...nonAuthData, username : nonAuthData.name})
                            }
                        // }
                    })
                    .catch(()=>{
                        console.log('workbook')
                    })
            }
            this.setState({totalList, confirmedList, expiredList, emptyDaysList, workBookList, confirmedInvalidList}, () => setTimeout(() => {
                this.setState({loading : false, show:true})
            }, 1500))
        }        
    onStateChange = e => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }
    onPropsChange = (name, value) => {
        this.setState({
            [name] : value
        })
    }
    render() {
        let {email, username, loading, show,
             totalList, confirmedList, expiredList, emptyDaysList, confirmedInvalidList, workBookList, spinnerMsg} = this.state
        let nonAuthReaderData={email,username}
        return (
            <div className="nonAuthSignFrame">
                {!show && 
                !loading ?
                <div className="signFrame">
                    <h1>기업회원 로그인</h1>
                    <div className="inputGroup">
                        <input type='text' name='email'value={email}  placeholder='이메일 주소 (ex.email@email.com)' onChange={this.onStateChange}/>
                        <input type='text' name='username' value={username} placeholder='이름 (ex.박길동)' onChange={this.onStateChange}/>
                        <div onClick={()=>this.onNonAuthLogin(email, username, this.onNonAuthCheck)}>로그인</div>
                        <Link to={{pathname: `${ROUTES.TLP_MAIN}`}}>홈으로 가기</Link>
                    </div>
                </div>
                : totalList.length>0 && show && !loading ?
                    <NonAuthHome totalList={totalList} confirmedList={confirmedList} expiredList={expiredList}
                     confirmedInvalidList={confirmedInvalidList} emptyDaysList={emptyDaysList} workBookList={workBookList}
                    nonAuthReaderData={nonAuthReaderData} onPropsChange={this.onPropsChange}/>
                : <Spinner msg={spinnerMsg}/>
                }
                
            </div>
        )
    }
}
export default compose(
    withRouter,
    withFirebase,
  )(NonAuthCheck);

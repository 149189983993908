import React, { Component } from 'react';
import $ from 'jquery'
class DataList extends Component {

    getServerLogin = () => {
        let data = {
            type:'online',
            id : 'yj.lee@thehrder.com',
            pw : '1234567890'
        }
        // let data = {
        //     type:'offline',
        //     id : '온라인회사',
        //     pw : 'cra31f_0910'
        // }
        fetch("/api/v1/appLogin", {
            method: 'POST',
            headers: {'Content-Type': 'application/json'},
            body: JSON.stringify(data)
        })
        .then(response => { response.json()
            .then((res) => {
              console.log(res)
          })
          .catch(err => alert(`에러 : ${err}`))
          })
        .catch(err => alert(`에러 : ${err}`))
    }
    render() {
        let {Totalpayment, TotalTLPbrochure, TotalXRBOOKbrochure} = this.props.statistics
        return (
            
                    <div className="dataAnalysisFrame">
                        <table>
                            <thead>
                                <tr>
                                    <th>구분</th>
                                    <th colSpan="6" 
                                    // onClick={()=>this.getServerLogin()}
                                    >내용</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td><span>사이트 결제 건수</span></td>
                                    <td colSpan="6"><span>{Totalpayment}건</span></td>
                                </tr>
                                <tr>
                                    <td><span>소개서 열람/다운로드 횟수</span></td>
                                    <td colSpan="6">
                                            <span>어린왕자 : {TotalTLPbrochure || 0}회 / XRBOOK : {TotalXRBOOKbrochure || 0}회</span>
                                    </td>
                                </tr>
                                <tr>
                                    <td><span>회원 데이터</span></td>
                                    <td colSpan="6">
                                        <a href="https://admin.bootpay.co.kr/login" target='_blank'>대시보드, 회원 분석</a>
                                    </td>
                                </tr>
                                <tr>
                                    <td><span>상품 데이터</span></td>
                                    <td><a href="https://admin.bootpay.co.kr/login" target='_blank'>카테고리, 상품</a></td>
                                </tr>
                                <tr>
                                    <td><span>유입 데이터</span></td>
                                    <td><a href="https://admin.bootpay.co.kr/login" target='_blank'>일별현황, 시간대별 분석, 인구통계</a></td>
                                </tr>
                                <tr>
                                    <td><span>매출 데이터</span></td>
                                    <td><a href="https://admin.bootpay.co.kr/login" target='_blank'>일별현황, 시간대별 분석, 인구통계</a></td>
                                </tr>
                                <tr>
                                    <td><span>플랫폼 데이터</span></td>
                                    <td><a href="https://admin.bootpay.co.kr/login" target='_blank'>개요, 디바이스, 운영체제, 브라우저</a></td>
                                </tr>
                                <tr>
                                    <td><span>결제 수단 데이터</span></td>
                                    <td><a href="https://admin.bootpay.co.kr/login" target='_blank'>개요,신용카드, 가상계좌, 실시간 계좌이체, 휴대폰 결제, 무통장</a></td>
                                </tr>
                            </tbody>
                        </table>
                        <div className="dataAnalysisGuide">
                            <p>자세한 데이터 분석 사항은 bootpay 사이트에 로그인 후 내용을 확인할 수 있습니다.</p>
                            <ul>
                                <li>
                                    <span>ID</span> : wj.park@thehrder.com
                                </li>
                                <li>
                                    <span>PW</span> : thehrder1127
                                </li>
                            </ul>
                        </div>
                    </div>
        );
    }
}

export default DataList;
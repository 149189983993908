import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import * as ROUTES from '../../../constants/routes';
import Contact from '../Main/Contact/index'
import { compose } from 'recompose';
import DaumPostcode from 'react-daum-postcode';
import {withAuthorization, withEmailVerification} from '../../Session'
import {getTimeString, phoneFormatter} from '../../helpers'
import './EventPage.css'
import Certificate from '../../Iamport/Certificate'
import Spinner from '../../Utils/Spinner';
import $ from 'jquery'
import noeventimg from '../../images/notEvent.png';

class EventPage extends Component {
    state={
        eventList:[],
        mainUrl : 'https://firebasestorage.googleapis.com/v0/b/xr-book.appspot.com/o/tlp%2Fself%2FpcUrl2?alt=media&token=17a8a2dd-599c-44c2-b768-3096d4ef153d',
        mobileUrl : 'https://firebasestorage.googleapis.com/v0/b/xr-book.appspot.com/o/tlp%2Fself%2FmobileUrl2?alt=media&token=587d98ea-2378-4e33-9b0d-5d2641a1ea41',
        applicant : JSON.parse(localStorage.getItem('authUser')).username || '',
        groupTitle:'',
        route:'',
        agree:false,
        agree2:false,
        loading:false,
        authUser : JSON.parse(localStorage.getItem('authUser')),
        certificationInfo:  JSON.parse(localStorage.getItem('authUser')).certificationInfo || {},
        isCertificated:  JSON.parse(localStorage.getItem('authUser')).isCertificated || false,
        uid : JSON.parse(localStorage.getItem('authUser')).uid,
        number : JSON.parse(localStorage.getItem('authUser')).number || '',

        isAddressOpen :false,
        shipmentModal:false,
        postCode:'',
        fullAddress:'',
        extraAddress:'',
        userAddress:''
    }
    componentDidMount(){
        this.getBanner()
        try {
          // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        } catch (error) {
          // just a fallback for older browsers
          window.scrollTo(0, 0);
        };
    }
    componentDidUpdate(prevProps, prevState){
      if(!prevState.shipmentModal&&this.state.shipmentModal){
          $('html, body').css("overflow", "hidden");
      } else if(prevState.shipmentModal&& !this.state.shipmentModal) {
          $('html, body').css("overflow", "inherit")
      }
      if(!prevState.isAddressOpen&&this.state.isAddressOpen){
          $('html, body').css("overflow", "hidden");
      } else if(prevState.isAddressOpen&& !this.state.isAddressOpen) {
          $('html, body').css("overflow", "inherit")
      }
  }
    putSession = () => {
        const {onUpdate} = this.props
        sessionStorage.setItem('XRbook-banner', 'hidden');
        onUpdate('banner-hidden')
    }
    getBanner = () => {
        const Today = parseInt(getTimeString()/1000000)
        this.props.firebase.banners().orderBy('createdAt','desc').limit(1).get()
        .then((result) => {
          var eventList = [];
          result.forEach((doc) => {
              var childData = doc.data();
              if(childData.startDate-Today<1 && Today-childData.endDate<1){
                eventList.push(childData)
              } 
          })
          this.setState({eventList})
        //  this.setState({})
      })
      .catch(function(err) {
          console.log(err)
      });
      }
    onStateChange = (e) => {
      let eTargetName = e.target.name
      e.preventDefault()
      if(eTargetName ==='route'){
        this.setState({
          [eTargetName]:e.target.value
        })
      } else if (eTargetName ==='number') {
        this.setState({number: phoneFormatter(e.target.value.replace(/[^0-9]/g,""))})
      } else if (eTargetName === 'agree'){
        this.setState({agree:!this.state.agree})
      } else if (eTargetName ==='agree2'){
        this.setState({agree2:!this.state.agree2})
      }else {
        this.setState({
          [eTargetName]:e.target.value.trim()
        })
      }
    }
    onPropsChange = (name, value) => {
      this.setState({
        [name] : value
      })
    }
    onSubmit = async(e) => {
        
      e.preventDefault();
      const {applicant, groupTitle, number, route, agree, agree2, isCertificated, userAddress, fullAddress, extraAddress, postCode} = this.state;
      if (applicant==='') {
        alert('신청자 성함을 작성해 주세요.')
      } else  if (groupTitle==='') {
        alert('소속/직책을 작성해 주세요.')
      } else  if (number.length<8) {
        alert('연락 가능한 휴대폰 번호를 입력해주세요.')
      } else if(!isCertificated){
        alert('본인 인증이 완료되지 않았습니다.')
      } else if (agree === false) {
        alert('개인정보 처리방침에 동의해주세요.')
      } else if (agree2 ===false) {
        alert('기계 임대 계약서를 확인 후 동의해주세요.')
      } else {
      this.setState({loading : true})
      const createdAt = getTimeString()
      let event = '어린왕자시연회'
      const data = { applicant, groupTitle, number, route, agree, agree2, isCertificated, fullAddress, extraAddress, postCode, createdAt, event, userAddress }
           await fetch("/api/v1/tlpEvent", {
              method: 'POST',
              headers: {'Content-Type': 'application/json'},
              body: JSON.stringify(data)
          })
          .then(response => { response.json()
              .then((res) => {
                 
                if(res.msg === 'success') {
                alert('신청이 접수되었습니다.')
                this.props.history.push(ROUTES.TLP_MAIN)
              } else {
                alert('다시 시도해주세요. \n오류가 계속되면 관리자에게 문의해주세요.1 \nxrbook@thehrder.com')
              }
            })
            .catch(err =>  alert(`다시 시도해주세요. \n오류가 계속되면 관리자에게 문의해주세요.2 \nxrbook@thehrder.com \n에러 : ${err}`))
            })
          .catch(err =>  alert(`다시 시도해주세요. \n오류가 계속되면 관리자에게 문의해주세요.3 \nxrbook@thehrder.com \n에러 : ${err}`))
          this.setState({loading : false})
  }
}
onSubmitShipment=()=>{
  //주소 저장
  let { postCode, fullAddress, extraAddress} = this.state
  this.setState({userAddress : `${postCode} ${fullAddress} ${extraAddress}`, shipmentModal:false})
       
      
}
    render() {
        const {eventList, mainUrl, mobileUrl, applicant, groupTitle, number, route, agree, agree2, loading,
        isCertificated, authUser, uid, shipmentModal, isAddressOpen, postCode, fullAddress, extraAddress, userAddress} = this.state
        // console.log(authUser)
        
        return (
            <React.Fragment>
            <div className="eventBg">
                <div className="eventFrame">
                    {/* {eventList.length >0 ?
                        eventList.map(c=>{
                            return( */}
                      
                              <React.Fragment>
                                <img className="contMobileImage" src='https://heropy.blog/css/images/vendor_icons/css3.png' alt="eventImg"/>
                                <img className="contPcImage" src='https://lh4.googleusercontent.com/fEnY9XGXIeD32Z4eD91QyupGQkmAuBcIxlr2Qj_5jzKv5G4BhxAagokXqrDzQeuUj4SNaipNjEghXzzd6AtwJN7dL6rKYkmTBm6n_P9iGTpjv2eNgvsYykHBvkQUhw=w2000' alt="eventImg" />
                      {/*  <img className="contPcImage" src={c.imgUrl} alt="eventImg" />
                      <img className="contMobileImage" src={c.mobileImgUrl} alt="eventImg" /> */}
                      <div>
                        {/* {loading ? 
                        <Spinner msg='등록 중입니다.'/>
                        : */}
                          <ul>
                              <li>
                                  <span className="cate">신청자</span> 
                                  <input type='text' name='applicant' value={applicant}
                                  onChange={event =>this.onStateChange(event)} />
                              </li>
                              <li>
                                  <span className="cate">소속/직책</span>  
                                  <input type='text' name='groupTitle' value={groupTitle}
                                  onChange={event =>this.onStateChange(event)}/>
                              </li>
                              <li>
                                  <span className="cate">연락처</span>  
                                  <input className="phoneNumber" type='text' name='number' value={number}
                                      onChange={event =>this.onStateChange(event)}/>
                                  <Certificate number={number} isCertificated={isCertificated} authUser={authUser} uid={uid} onPropsChange={this.onPropsChange} />
                              </li>
                              <li>
                              <span className="cate">배송 받을 주소</span> 
                                  <input type='text' name='userAddress' value={userAddress}
                                  onClick={()=>this.setState({shipmentModal : true})} readOnly/>
                              {shipmentModal && <div className="codeRegiBg">
                                        <div className="addressRegiBx">
                                            <div className="titleBox">
                                                <h5>배송지 정보 입력</h5>
                                            </div>
                                            <div className="addressRegiCont">
                                                <p>프로그램 종료 후 인쇄된 책 배송을 위한 주소를 입력해 주세요</p>
                                                <div className="addressInputFrame">
                                                    <input type='number' value={postCode} onClick={()=> this.onPropsChange('isAddressOpen',true)} readOnly/>
                                                    <span className='addressBtn' onClick={()=> this.onPropsChange('isAddressOpen',true)}>주소지 찾기</span><br/>
                                                    <input type='text' value={fullAddress} onClick={()=> this.onPropsChange('isAddressOpen',true)} readOnly/><br/>
                                                    <input type='text' className='extraAddress' name='extraAddress' value={extraAddress}
                                                    onChange={(e)=> this.onPropsChange('extraAddress',e.target.value)}/>
                                                </div>
                                                <button className="addressRegiBtn" disabled={fullAddress===''||extraAddress===''} onClick={()=>this.onSubmitShipment()}>제출하기</button>
                                            </div>
                                            {isAddressOpen &&<Postcode isOpen={()=>this.setState({isAddressOpen : false})} onUpdateCode={this.onPropsChange}/>}  
                                        </div>
                                        </div>}
                              </li>
                              <li className="policiesCate">
                                  <span className="cate">개인정보 처리방침</span>  
                                  <span>
                                      <span className="checkBxName" ><input type='checkbox' name='agree' checked={agree} onChange={(e)=>this.stateChange(e)}/><span onClick={()=>this.setState({agree:!agree})}>확인/동의합니다.</span></span>
                                      <span className="policiesLink"><a href={ROUTES.POLICIES} target='_blank'>내용 보기</a></span>
                                  </span>
                              </li>
                              <li className="policiesCate">
                                  <span className="cate">기계 임대 계약서</span>  
                                  <span>
                                      <span className="checkBxName" ><input type='checkbox' name='agree2' checked={agree2} onChange={(e)=>this.stateChange(e)}/><span onClick={()=>this.setState({agree2:!agree2})}>확인/동의합니다.</span></span>
                                      <span className="policiesLink"><a href={ROUTES.RENTCONTRACT} target='_blank'>계약서 보기</a></span>
                                  </span>
                              </li>
                              <li>
                                <span className="cate"></span>
                                <span className="smallText">* 계약서는 체험 확정 시 서면으로 전달 드립니다.</span>
                              </li>
                              <li className="eventBtnGroup">
                                <div>
                                  <button onClick={(e)=>this.onSubmit(e)}>신청하기</button>
                                </div>
                              </li>
                              </ul>
                        {/* } */}
                      </div>
                              </React.Fragment>
                         {/* )
                         }) 
                         :
                         <div className="noEvent">
                         <img className="noEventImg" src={noeventimg} alt="noEventImg" />
                         <p>
                           현재 진행중인 이벤트가 없습니다.<br />
                           <span>새로운 이벤트로 찾아뵙겠습니다.</span>
                         </p>  
                       </div>
                             } */}
                      
                </div>
            </div>
            {/* <Contact/> */}
            </React.Fragment>
        )
    }
}

const condition = authUser =>
authUser

export default compose(
  withEmailVerification,
  withAuthorization(condition),
  )(EventPage);

  const Postcode = props => {
    const {onUpdateCode} = props
    const handleComplete = (data) => {
      let fullAddress = data.address;
      onUpdateCode("postCode", data.zonecode)
      let extraAddress = ''; 
      if (data.addressType === 'R') {
        if (data.bname !== '') {
          extraAddress += data.bname;
        }
        if (data.buildingName !== '') {
          extraAddress += (extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName);
        }
        fullAddress += (extraAddress !== '' ? ` (${extraAddress})` : '');
      }
      onUpdateCode("fullAddress", fullAddress)
      onUpdateCode("isAddressOpen", false)
    }


    return (
    <div className="addressPopupOut">
        {/* 주소 입력하지 않으면 창이 닫히지 않도록 모달 배경클릭 시 모달 닫힘을 제거했습니다. */}
        {/* 모달 창 */}
            <div className="addressPopupIn">
            <DaumPostcode
                onComplete={handleComplete}
            />
            </div>

    </div>
    ); 
  } 
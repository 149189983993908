import React, { Component } from 'react'
import SalesApplied from './SalesApplied'
// import './index.css'
class ProgramListApplied extends Component {
    static defaultProps = {
        programList: [
          {
            authUserId: "",
            code: "",
            codeAt: "",
            contents: "",
            createdAt: "",
            email: "",
            groupTitle: "",
            manager: "",
            memo: "",
            number: 0,
            page: "",
            participants: "",
            price: 0,
            programInfo: "",
            progress: "",
            progressAt: "",
            selectedDays:[],
            uid: ""}
        ],
        oneditList: () => console.warn('oneditList not defined'),
        onGetList: () => console.warn('onGetList not defined'),
      }
    componentDidMount (){
        const {onGetList} = this.props
        onGetList()
    }

    render() {
        const {programList, oneditList, onGetList, getNewProgramList} = this.props
        const list = programList.length >0 ? 
        programList.map(
            info =>
              <SalesApplied
                key={info.uid}
                appliedList={info}
                onGetList={onGetList}
                oneditList={oneditList}
              />
          ) :
          '프로그램 신청 현황이 없습니다.'
        return (
            <React.Fragment>
              <p>이러닝 문의 총 : {programList.length}건</p>
              <ul className="salesListCate salesList1Cate">
                <li>사용자명</li>
                <li>회사명</li>
                <li>프로그램명</li>
                <li>인원</li>
                <li>기간</li>
                <li>기기 체험</li>
                <li>요청사항</li>
                <li>진행상황</li>
                <li>일자</li>
                <li>비고</li>
              </ul>
              {list}
              <button className="viewMoreListBtn" onClick={()=>getNewProgramList()}>시간순 20개 더보기</button>
            </React.Fragment>  
        );
    }
}
export default ProgramListApplied
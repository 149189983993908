import React, { Component } from 'react';
import '../DObook.css'
import PagePriviewItem from '../BookItem/PagePriviewItem'
import './Preview.css'
import './PreviewResp.css'
import $ from "jquery";

class Digitalbook_Mobile_Preview extends Component {
    state={
        pageList:this.props.previewPageList,
        pageId:0,
        prevBtn : 'invalidBtn',
        nextBtn : 'invalidBtn'
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.previewPageList !== this.props.previewPageList){
            this.setState({
                pageList : this.props.previewPageList
            })
            if(this.props.previewPageList.length === 1){
            this.setState({prevBtn:'invalidBtn', nextBtn:'invalidBtn'})}
        else if(this.props.previewPageList.length > 1 && this.state.pageId < this.props.previewPageList.length-1){
                this.setState({prevBtn:'invalidBtn', nextBtn:'validBtn'})}
        }

        
        if(prevState.pageId !== this.state.pageId){
            let pageLeng = (this.props.previewPageList.length)
            if (pageLeng === 2 && this.state.pageId === 1){
                this.setState({ prevBtn:'validBtn', nextBtn:'invalidBtn'})
            }
            else if (pageLeng === 2 && this.state.pageId === 0){
                this.setState({ prevBtn:'invalidBtn', nextBtn:'validBtn'})
            }
            else if(this.state.pageId === 0  && pageLeng>2){
                this.setState({ prevBtn:'invalidBtn', nextBtn:'validBtn'})
            } 
            else if (pageLeng > 2 && this.state.pageId === pageLeng-1){
                this.setState({ prevBtn:'validBtn', nextBtn:'invalidBtn'})
            } else {
                this.setState({
                    nextBtn:'validBtn',
                    prevBtn:'validBtn'
                })
            }
    }
}
handleChange = (e) => {
    if (["writingValue"].includes(e.target.className) ) {
        let currentPageList = this.state.pageList.slice()
        currentPageList[this.state.pageId].userSection[e.target.dataset.id][e.target.className] = e.target.value
        this.setState({
             pageList: currentPageList, 
          })
      } else if(["textarea"].includes(e.target.className)) {
          let currentPageList = this.state.pageList.slice()
        currentPageList[this.state.pageId].userSection[e.target.dataset.id]['writingValue'] = e.target.value.replace(/(?:\r\n|\r|\n)/g, '<br />')
        this.setState({
             pageList: currentPageList, 
          })
      }
  }
  drawingChange = (className, contents, idx) => {
    let currentPageList = this.state.pageList.slice()
    currentPageList[this.state.pageId].userSection[idx][className] = contents
    this.setState({pageList : currentPageList});
  }
  onPageChange = type => {
    let {pageId, pageList}=this.state
    let pageLeng = pageList.length+1
    if (type ==='prev' && pageId >0){
        this.setState((prevState, props) => ({ pageId : prevState.pageId-1}))
        //이전페이지 함수
        $('.bookPage').removeClass('ready');
        $('.bookPage.flipped').last().removeClass('flipped').addClass('active')
            .siblings('.bookPage').removeClass('active');
        $('.bookPage.active').next().addClass('ready');
    } else if (type ==='next' && pageId < pageLeng-1) {
        this.setState((prevState, props) => ({ pageId : prevState.pageId+1}))
        //다음페이지 함수
        $('.bookPage').removeClass('ready');
        $('.bookPage.active').removeClass('active').addClass('flipped')
            .next('.bookPage').addClass('active');
    }
}
onPropsChange = (name, value) => {
this.setState({
    [name] : value
})
}
onTextToPage = (id) => {
    // console.log(id)
    this.setState({pageId:id})
    $(".headingTextBox").toggleClass("showHeading");
    $('.bookPage').removeClass('ready flipped active pageShow');
    $(".bookPage"+this.state.pageId).removeClass("active");
    $(".bookPage"+id).addClass("active");
    $(".bookPage"+id).prevAll().addClass("flipped");
    $(".bookPage"+id).prev().addClass("pageShow");
    $(".bookPage"+id).next().addClass("ready");
}
onTextBox = () => {
    $(".headingTextBox").toggleClass("showHeading");

}
    render() {
        let {prevBtn, nextBtn, pageId, pageList } = this.state
        // console.log(pageList)
        let pageHeaderList = this.state.pageList.filter(c=> c.pageHeader !== 'hidden')
        return (
            <div className="previewFrame moPreviewFrame" style={{display:'flex', flexDirection:'row'}}>
                <div className="bookPageFrame">
                    <div className="headingTextBox">
                        {pageHeaderList && pageHeaderList.map((page, id)=> {
                            let idx = this.state.pageList.indexOf(page)
                            // console.log(idx)
                        return(
                            <div key={id}
                            onClick={()=> this.onTextToPage(idx) }
                            className={page.pageHeader === 'header1' ?   `heading1`
                                            : page.pageHeader === 'header2' ? `heading2`
                                            : page.pageHeader === 'header3' ?  `heading3`
                                            : page.pageHeader === 'hidden' ?  `heading4`
                                            : `heading5`}
                                            >
                            {page.pageTitle}</div>
                        )})}
                    </div>
                    <div className="Scene mobileScene">
                        <div className="bookPageGroup">
                            {pageList && pageList.map((c, id)=> {
                            return (
                                <div key={id}
                                    className={id === 0 ?   `bookPage bookPage${id} active`
                                                    : `bookPage bookPage${id}`}
                                    >
                                    <form className="curPageBox">                      
                                        <PagePriviewItem pageItem={c} handleChange={this.handleChange}/>
                                    </form>
                                    {/* <form className="curPageBox">                      
                                        <PagePriviewItem pageItem={c} handleChange={this.handleChange}/>
                                    </form> */}
                                </div>
                            )})}
                    </div>
                </div>
                </div>
                <div className="utilityBtnGroup">
                    <div className="headingBtnBox">
                        <span className="headingBtn" onClick={()=>this.onTextBox()}>목차</span>
                    </div>
                    <div className="bookBtnBox">
                        <span className="curPageSign">{pageId} / {pageList.length}</span>
                        <span className={`${prevBtn} prevBtn`} onClick={()=>this.onPageChange('prev')}>이전</span>
                        <span className={`${nextBtn} nextBtn`} onClick={()=>this.onPageChange('next')}>다음</span>
                    </div>
                </div>
            </div> 
        )
    }
}
export default Digitalbook_Mobile_Preview
import React, { useState } from "react";
import { compose } from "recompose";
import { withAuthorization, withEmailVerification } from "../../Session";
import { getTimeString } from "../../helpers";
import ProgramListApplied from "./ProgramStatus/ProgramListApplied/index";
import ProgramListConsulting from "./ProgramStatus/ProgramListConsulting/index";
import ProgramListFinished from "./ProgramStatus/ProgramListFinished/index";
import ProgramListCanceled from "./ProgramStatus/ProgramListCanceled/index";
import InstitutePage from '../InstitutePage'
import "./AdminProgram.css";
import $ from "jquery";

const AdminProgramsPage = (props) => {
  const [one, onOne] = useState("adminProgram_show");
  const [two, onTwo] = useState("adminProgram_hidden");
  const [three, onThree] = useState("adminProgram_hidden");
  const [four, onFour] = useState("adminProgram_hidden");
  const [five, onfive] = useState("adminProgram_hidden");
  const [show, onShow] = useState("applied");
  const [programList, setProgramList] = useState([]);

  function First() {
    onShow("applied");
    onOne("adminProgram_show");
    onTwo("adminProgram_hidden");
    onThree("adminProgram_hidden");
    onFour("adminProgram_hidden");
    onfive("adminProgram_hidden");
    $(".adminProgramFrame>div>.clpTabBtnGroup div").css({
      border: "1px solid #dedede",
      color: "#aaaaaa",
    });
    $(".adminProgramFrame>div>.clpTabBtnGroup div")
      .eq(0)
      .css({ "border-bottom": 0, color: "#000000" });
  }
  function Second() {
    onShow("consulting");
    onOne("adminProgram_hidden");
    onTwo("adminProgram_show");
    onThree("adminProgram_hidden");
    onFour("adminProgram_hidden");
    onfive("adminProgram_hidden");
    $(".adminProgramFrame>div>.clpTabBtnGroup div").css({
      border: "1px solid #dedede",
      color: "#aaaaaa",
    });
    $(".adminProgramFrame>div>.clpTabBtnGroup div")
      .eq(1)
      .css({ "border-bottom": 0, color: "#000000" });
  }
  function Third() {
    onShow("finished");
    onOne("adminProgram_hidden");
    onTwo("adminProgram_hidden");
    onThree("adminProgram_show");
    onFour("adminProgram_hidden");
    onfive("adminProgram_hidden");
    $(".adminProgramFrame>div>.clpTabBtnGroup div").css({
      border: "1px solid #dedede",
      color: "#aaaaaa",
    });
    $(".adminProgramFrame>div>.clpTabBtnGroup div")
      .eq(2)
      .css({ "border-bottom": 0, color: "#000000" });
  }
  function Fourth() {
    onShow("canceled");
    onOne("adminProgram_hidden");
    onTwo("adminProgram_hidden");
    onThree("adminProgram_hidden");
    onFour("adminProgram_show");
    onfive("adminProgram_hidden");
    $(".adminProgramFrame>div>.clpTabBtnGroup div").css({
      border: "1px solid #dedede",
      color: "#aaaaaa",
    });
    $(".adminProgramFrame>div>.clpTabBtnGroup div")
      .eq(3)
      .css({ "border-bottom": 0, color: "#000000" });
  }
  function Fifth() {
    onShow("institute");
    onOne("adminProgram_hidden");
    onTwo("adminProgram_hidden");
    onThree("adminProgram_hidden");
    onFour("adminProgram_hidden");
    onfive("adminProgram_show");
    $(".adminProgramFrame>div>.clpTabBtnGroup div").css({
      border: "1px solid #dedede",
      color: "#aaaaaa",
    });
    $(".adminProgramFrame>div>.clpTabBtnGroup div")
      .eq(4)
      .css({ "border-bottom": 0, color: "#000000" });
  }

  function getProgramList(status) {
    const newList = [];
    const statusList =
      status === "consulting" ? ["consulting", "request", "paid"] : [status];
    props.firebase
      .tlpPrograms()
      .where("progress", "in", statusList)
      .orderBy("createdAt", "desc")
      .get()
      .then((result) => {
        result.forEach((doc) => {
          var uid = doc.id;
          var listData = doc.data();
          // console.log(listData)
          var selectedDays = [];
          if (listData["selectedDays"] !== []) {
            listData["selectedDays"].map((c) => {
              var year = c.substr(0, 2);
              year = parseInt(year);
              var mon = c.substr(2, 2);
              mon = parseInt(mon);
              var date = c.substr(4, 2);
              date = parseInt(date);

              var dateFormat = new Date(2000 + year, mon - 1, date, 12, 0, 0);
              selectedDays.push(dateFormat);
            });
            listData["selectedDays"] = selectedDays;
          }
          newList.push({ uid, ...listData });
        });
        setProgramList(newList);
      });
  }
  function editProgramInfo(uid, appliedList, status) {
    if (appliedList["selectedDays"] !== []) {
      const list = [];
      appliedList["selectedDays"].map((c) => {
        var DaysData = getTimeString(c);
        list.push(DaysData);
        // console.log(DaysData)
      });
      appliedList["selectedDays"] = list;
    }
    appliedList["progressAt"] = getTimeString();
    const authUserId = appliedList["authUserId"];
    const programListKey = `${appliedList["createdAt"]}_${appliedList["page"]}`;
    const programList = {};
    programList[programListKey] = appliedList;
    props.firebase
      .tlpProgram(uid)
      .set(appliedList, { merge: true })
      .then(() =>
        props.firebase.user(authUserId).set({ programList }, { merge: true })
      )
      .then(() => alert("수정되었습니다."))
      .catch((err) => alert("잠시 후에 다시 시도해 주세요.\n에러 : " + err));
  }

  // function getdigitalbookList(){
  //       props.firebase.libraries().get()
  //       .then(result=>{
  //         let digitalbookList = []
  //         result.forEach((doc) => {
  //             var bid = doc.id
  //             var listData= doc.data();
  //             digitalbookList.push({bid, ...listData})
  //             })
  //             ondigitalbookList(digitalbookList)
  //     })
  //       .catch(err=> alert(`잠시 후 다시 시도 해주세요.\n에러 : ${err}`) )
  //     }
  return (
    <div className="adminProgramFrame">
      <h3>Off-line Learning 조회</h3>
      <div>
        {/* 탭 부분 */}
        <div className="clpTabBtnGroup">
          <div className={`${one}`} onClick={() => First()}>
            신청 완료
          </div>
          <div className={`${two}`} onClick={() => Second()}>
            담당자 배정
          </div>
          <div className={`${three}`} onClick={() => Third()}>
            교육 종료
          </div>
          <div className={`${four}`} onClick={() => Fourth()}>
            취소
          </div>
          <div className={`${four}`} onClick={() => Fifth()}>
            교육회원 관리
          </div>
        </div>
        {/* 탭에서 선택된 내용 부분 */}
        <div className="clpTabContGroup">
          {show === "applied" ? (
            <ProgramListApplied
              onGetList={getProgramList}
              oneditList={editProgramInfo}
              programList={programList}
            />
          ) : show === "consulting" ? (
            <ProgramListConsulting
              onGetList={getProgramList}
              oneditList={editProgramInfo}
              programList={programList}
              firebase={props.firebase}
            />
          ) : show === "finished" ? (
            <ProgramListFinished
              onGetList={getProgramList}
              oneditList={editProgramInfo}
              programList={programList}
            />
          ) : show === "canceled" ? (
            <ProgramListCanceled
              onGetList={getProgramList}
              oneditList={editProgramInfo}
              programList={programList}
            />
          ) : show === "institute" ? (
            <InstitutePage/>
          ) : null}
        </div>
      </div>
    </div>
  );
};

const condition = (authUser) => authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition)
)(AdminProgramsPage);

import React, { Component } from 'react'

class ClassInfo extends Component {
    render() {
        let {headShow} = this.props
        return (
            <div className="offLearnGuideFrame">
                <h5 style={headShow ? {}:{display:'none'}}>Off-line Learning Program 수강 가이드</h5>
                <ul>
                    <li>
                        <ul>
                            <li className="offlineGuideImg">
                                <span></span>
                            </li>
                            <li className="offlineGuideText">
                                <span>1. Program 문의/상담</span>
                                <span>https://www.xrbook.net 사이트 “Program 신청”페이지 [Off-line Learning 신청]에서 신청 문의 작성</span>
                                <span>
                                    프로그램을 선택하여 교육인원, 장소, 교재, 기기대여 여부, 요청사항, 기타 내용을 작성하여 
                                    제출하면 담당자가 배정되어 자세한 사항을 안내해 드립니다.
                                </span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li className="offlineGuideImg">
                                <span></span>
                            </li>
                            <li className="offlineGuideText">
                                <span>2. Program 결제/진행</span>
                                <span>강의공간에서 종이, 혹은 디지털 Work Book과  XR Learning Activity (VR, AR, 2D Animation, Reflection Writing ) 기반의 프로그램을 진행</span>
                                <span>
                                    독립형 HMD(오큘러스 고)와 AR app, Work Book(Digital Book가능), 강사가 제공되며,
                                    총 5~8(협의)시간의 집합교육 프로그램이 진행 됩니다.
                                </span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li className="offlineGuideImg">
                                <span></span>
                            </li>
                            <li className="offlineGuideText">
                                <span>3. 소장용 책 인쇄 ( 옵션 )</span>
                                <span>작성된 Digital Book 내용 인쇄</span>
                                <span>
                                    학습 종료 후 프로그램을 통해 완성한 Work Book 을 모아, 
                                    개인(우리회사)만의 소장용 어린 왕자 책으로 인쇄, 출판 할 수 있습니다.
                                    (인쇄 대상자, 수량 등 사전 협의 필요)
                                </span>
                            </li>
                        </ul>
                    </li>
                    <li>
                        <ul>
                            <li className="offlineGuideImg">
                                <span></span>
                            </li>
                            <li className="offlineGuideText">
                                <span>4. 책 배송 완료</span>
                                <span>인쇄된 책이 사용자에게 배송</span>
                                <span>교육 프로그램을 진행한 회사/개인으로 인쇄 완료한 소장용 책을 배송합니다.</span>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        )
    }
}
export default ClassInfo
import React, { Component, useState, useEffect } from 'react'
import { withFirebase } from '../Firebase'
import {getTimeDayjs} from '../helpers'
import PageGalleryItem from '../TLP/Main/Gallery/PageGalleryItem'
import dayjs from 'dayjs'
import $ from 'jquery'
import Spinner from '../Utils/Spinner'

class SharingPage extends Component {
    state = {
        sharingPageList : [],
        type:'',
        modalIdx:0,
        isModalOpen:false,
        loading:false,
        msg : '공유된 이미지/페이지를 내 서재에 저장된 디지털 북에서 삭제 중입니다.'
    }
    componentDidMount(){
        this.getSharingPageList()
    }
    getSharingPageList =()=> {
        let {authUser}=this.props
        this.props.firebase.sharingPages().where('authUserUid','==', authUser['uid'] ).where('authUserEmail','==', authUser['email'] ).orderBy('page.shareMarkAt','desc').get()
        .then(result => {
            var sharingPageList = []
            result.forEach(res => {
                var listData = res.data()
                var sharingId = res.id
                sharingPageList.push({sharingId, ...listData})
            })
            this.setState({sharingPageList})
        })
    }
    deleteSharingPage =(pageData)=> {
        let {sharingId, idx, chapter, pageId, mybookId, page} = pageData
        pageId = parseInt(pageId)
        let result = window.confirm('공유한 페이지를 삭제하시겠습니까?')
        let chapterKey = `Totalchapter${chapter}`
        let mybookRef = this.props.firebase.mybook(mybookId)
        let editedPage = Object.assign({}, page)
        editedPage.shareMark = false
        if(result){
            this.setState({loading:true})
            let transaction = this.props.firebase.db.runTransaction(t => {
                return t.get(mybookRef)
                  .then(doc => {
                    let temppageList = doc.data().bookData.pageList;
                    let temppage = temppageList.filter(c=> 
                        c.pageTitle===page.pageTitle
                        && c.pageBgImgUrl===page.pageBgImgUrl
                        && c.pageBgImgColor===page.pageBgImgColor
                        && c.shareMarkAt===page.shareMarkAt
                        )
                    let index = temppageList.indexOf(temppage[0])
                    temppageList[index] = editedPage
                    t.update(mybookRef, {'bookData.pageList': temppageList});
                  });
              })
              .then(result => {
                this.setState({msg:'공유된 이미지/페이지를 Reflection Gallery에서 삭제 중입니다.'})
                this.props.firebase.sharingPage(sharingId).delete()
                    .then(() => {
                        this.props.firebase.tlpStatistics().update({
                            [chapterKey] : this.props.firebase.fieldValue.increment(-1)
                        })
                        .then(()=>{
                            let tempList = this.state.sharingPageList
                            tempList.splice(idx, 1)
                            this.setState({sharingPageList : tempList, loading:false})
                        })
                        })
              }).catch(err => {
                console.log('Transaction failure:', err);
              });
        
            // .catch(err=> alert('잠시 후 다시 시도해 주세요.\n'+err))
        }
    }
    modalOpen = (id, status, type) => {
        if(status === true){
            $('html, body').css("overflow", "hidden");
            
        } else {
            $('html, body').css("overflow", "inherit")
        }
        this.setState({isModalOpen: status, modalIdx : id, initialType: type})
      }
    render() {
        let {sharingPageList, initialType, isModalOpen, modalIdx, loading, msg } = this.state
        let leng = sharingPageList.length
        return (
            <React.Fragment>
                {loading ? 
                <Spinner msg={msg}/>
                :
                <React.Fragment>
                    <p className="myShareTxt">이미지/페이지 <span>{leng}</span>장 공유중</p>
               
                {sharingPageList.length>0 ? sharingPageList.map((c, id)=> {
                                const {createdAt, type, page, chapter, pageId, mybookId} = c
                                let {shareMarkAt} = page
                                var createdAfterPeriod2 = getTimeDayjs(shareMarkAt)
                    return (
                        <ul className="myShareFrame" key={c.sharingId} >
                            <li onClick={()=>this.modalOpen(id, true, c.type)}>
                            {type ==='digitalbook' ? `디지털북에서 공유한 이미지/페이지 ${id+1}` : `AR APP에서 공유한 이미지/페이지 ${id+1}`}</li>
                            <li>{createdAfterPeriod2}</li>
                            <li><button onClick={()=>this.deleteSharingPage(c)}>삭제</button></li>
                        </ul>
                                )
                            })
                        : 
                        <div className="noDataText">Reflection Gallery에 공유한 이미지/페이지가 없습니다.</div>
                        }
                        {isModalOpen && <GalleryModal urlList={sharingPageList} modalIdx={modalIdx}
                        initialType={initialType} modalOpen={this.modalOpen}/>}
                </React.Fragment>
                        }
            </React.Fragment>
        )
    }
}
export default withFirebase(SharingPage)

const GalleryModal = props => {
    let { urlList, modalIdx, modalOpen, initialType} = props
    const [idx, setidx] = useState(modalIdx)
    const [type, settype] = useState(initialType)
    useEffect(() => {
      setidx(modalIdx)
      settype(urlList[modalIdx].type)
    }, [modalIdx])
    // useEffect(() => {
    //     settype(urlList[idx].type)
    //     if(urlList[idx].type !=='digitalbook'){
    //         $('PageGalleryItem').hide()
    //     } else {
    //         $('PageGalleryItem').show()
    //     }
    //   }, [idx])
    let listLeng = urlList.length
    return(
        <div className="galleryPopFrame userGallPopFrame disabledDrag">
          <div className="galleryPopBg userGallPopBg"  onClick={()=>modalOpen(0,false)}></div>
          <div className="galleryPopBox userGallPopBox">
            {type === 'digitalbook' ?
            <div className="galleryPopImage userGallPopImage">
                <div 
                    className='PageGalleryItem preventDrag'>
                    <PageGalleryItem pageItem={urlList[idx]['page']}/>
                </div>
                {/* <div className="transparentCover"></div> */}
                {/* <span className="galleryPopDel userGallPopDel" onClick={()=>modalOpen(0,false)}>×</span> */}
            </div>
            :
            <div className="galleryPopImage userGallPopImage" style={{backgroundImage : `url(${urlList[idx].page})`}}>
                {/* <div className="transparentCover"></div> */}
            </div>
            }
            <span className="galleryPopDel userGallPopDel" onClick={()=>modalOpen(0,false)}>×</span>
          </div>
        </div>
    )
  }
import React from 'react';
import { Switch, Route } from 'react-router-dom'
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../Session';
import * as ROLES from '../../constants/roles';
import * as ROUTES from '../../constants/routes';
import './Admin.css'
import AdminEventPage from './AdminEventPage'
import AdminProgramsPage from './AdminProgramsPage'
import AdminSiteManagementPage from './AdminSiteManagementPage'
import AdminStatisticsPage from './AdminStatisticsPage'
import AdminSalesPage from './AdminSalesPage'
import AdminXrMakingPage from './AdminXrMakingPage'
import AdminAppImagesPage from './AdminAppImagesPage'
import AdminReviewsPage from './AdminReviewsPage'
import AdminInquiriesPage from './AdminInquiriesPage'
import AdminContentsPage from './AdminContentsPage'
import AdminDigitalbookPage from './AdminDigitalbookPage.js'
import AdminReadingPage from './AdminReadingPage'
import AdminFacilitatorPage from './AdminFacilitatorPage'
import CreateCouponsPage from './CreateCouponsPage'

const AdminPage = () => (
        <div className="adminMypageFrame">
          <h3 
          // onClick={()=>getServerLogin()}
          >관리자님</h3>
          <p>즐거운 하루 되세요 !</p>
          {/* <p>The Admin Page is accessible by every signed in admin user.</p> */}
          <Switch>
              <Route exact path={ROUTES.ADMIN} component={AdminStatisticsPage} />
              <Route path={ROUTES.ADMIN_STATISTICS} component={AdminStatisticsPage} />
              <Route path={ROUTES.ADMIN_PROGRAMS} component={AdminProgramsPage} />
              <Route path={ROUTES.ADMIN_SALES} component={AdminSalesPage} />
              <Route path={ROUTES.ADMIN_XR_MAKING} component={AdminXrMakingPage} />
              <Route path={ROUTES.ADMIN_CONTENTS} component={AdminContentsPage} />
              <Route path={ROUTES.ADMIN_MANAGEMENT} component={AdminSiteManagementPage} />
              <Route path={ROUTES.ADMIN_APP_IMAGES} component={AdminAppImagesPage} />
              <Route path={ROUTES.ADMIN_REWVIEWS} component={AdminReviewsPage} />
              <Route path={ROUTES.ADMIN_INQUIRIES} component={AdminInquiriesPage} />
              <Route path={ROUTES.ADMIN_EVENT} component={AdminEventPage} />
              <Route path={ROUTES.ADMIN_DIGITAL_BOOK} component={AdminDigitalbookPage} />
              <Route path={ROUTES.ADMIN_COUPON} component={CreateCouponsPage} />
              <Route path={ROUTES.ADMIN_READING} component={AdminReadingPage} />
              <Route path={ROUTES.ADMIN_FACILITATOR} component={AdminFacilitatorPage} />
          </Switch>
        </div>
  );
  
  const condition = authUser =>
    authUser && authUser.roles[ROLES.MEMBER] === ROLES.ADMIN;
  
  export default compose(
    withEmailVerification,
    withAuthorization(condition),
  )(AdminPage);
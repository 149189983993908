import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withFirebase } from '../../../Firebase';
import * as ROUTES from '../../../../constants/routes';
import FacilitatorItem from './FacilitatorItem'
import MarketingItem from './MarketingItem'
import {getSelectDays} from '../../../helpers'

class FacilitatorList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      users: [],
      marketing:[]
    };
  }

  componentDidMount() {
    this.setState({ loading: true });

    this.props.firebase.users().where('status','==','active').where('roles.member','==','facilitator').onSnapshot(snapshot=> {
      let users = [];
      
      snapshot.forEach(doc =>{
        let selectedDays = []
        let result = doc.data()
        if (result.selectedDays !== [] && result.selectedDays !== undefined ) {
          result.selectedDays.map((c) => {
            let dateFormat = getSelectDays(c)
            selectedDays.push(dateFormat);
          })
        }
          users.push({ ...result, uid: doc.id, selectedDays })
      });
      
      this.setState({
        users,
        loading: false,
      });
    });

    // this.props.firebase.users().where('status','==','active').where('roles.member','==','marketing').onSnapshot(snapshot=> {
    //   let marketing = [];
      
    //   snapshot.forEach(doc =>{
    //     let selectedDays = []
    //     let result = doc.data()
    //     if (result.selectedDays !== [] && result.selectedDays !== undefined ) {
    //       result.selectedDays.map((c) => {
    //         let dateFormat = getSelectDays(c)
    //         selectedDays.push(dateFormat);
    //       })
    //     }
    //       marketing.push({ ...result, uid: doc.id, selectedDays })
    //   });
      
    //   this.setState({
    //     marketing,
    //     loading: false,
    //   });
    // });

  }

  render() {
    const { users, loading, marketing } = this.state;
    // console.log(this.state)
    return (
      <>
      <div>
        <h2>Facilitator</h2>
        
        <div className="userListFrame" style={{textAlign:"center"}}>
        <table>
          <thead>
            <tr>
              <th>번호</th>
              <th>메일</th>
              <th>이름</th>
              <th>관리</th>
            </tr>
          </thead>
          <tbody>
          {loading && <tr><td colSpan='4'>Loading ...</td></tr>}
          {users.map((user, id) => (
            <tr key={user.uid}>
              <FacilitatorItem user={user} idx={id+1}/>
            </tr>
          ))}
          </tbody>
        </table>
        </div>
      </div>
      {/* <hr/><hr/>
      <div>
        <h2>외부 영업회원</h2>
        
        <div className="userListFrame" style={{textAlign:"center"}}>
        <table>
          <thead>
            <tr>
              <th>번호</th>
              <th>메일</th>
              <th>이름</th>
              <th>관리</th>
            </tr>
          </thead>
          <tbody>
          {loading && <tr><td colSpan='4'>Loading ...</td></tr>}
          {marketing.map((user, id) => (
            <tr key={user.uid}>
              <MarketingItem user={user} idx={id+1}/>
            </tr>
          ))}
          </tbody>
        </table>
        </div>
      </div> */}
      </>
    );
  }
}

export default withFirebase(FacilitatorList);

import React, {useState, useEffect} from 'react';
import {getTimeString} from '../../helpers'
import {withFirebase} from '../../Firebase'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import './CreateCoupons.css'
dayjs.locale('ko')


const DateLimit = props => {
    const [codeArr, setcodeArr] = useState(props.codeArr)
    const [codeName, setcodeName] = useState('')
    // const [minQuantity, setminQuantity] = useState(1)
    // const [discountType, setdiscountType] = useState('')
    const [discountCondition, setdiscountCondition] = useState(0)
    // console.log(discountCondition)
    const discountedCondition = discountCondition.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
    const [discountType, setdiscountType] = useState('anyCondition')
    //parseInt(discountAmount)
    const [discountAmount, setdiscountAmount] = useState(0)
    const discountedAmount = discountAmount.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
    const [expirePeriod, setexpirePeriod] = useState(0)
    let today = dayjs(new Date())
    let todaySubtractMonth = today.add(-1, 'month').format('YYYY-MM-DD')
    let todaySubtractMonthString = getTimeString(`${todaySubtractMonth}T00:00:00`)
    const [expireDate, setexpireDate] = useState(today.format('YYYY-MM-DD'))
    const [expireDay, setexpireDay] = useState(today.format('ddd'))
    const [randomCode, setrandomCode] = useState('')
    const [timeString, settimeString] = useState('')

    useEffect(() => {
        setcodeArr(props.codeArr)
    }, [props.codeArr])

    function getExpireDate (period) {
        setexpirePeriod(period)
        let expiredDate = today.add(period, 'day')
        setexpireDate(expiredDate.format('YYYY-MM-DD'))
        setexpireDay(expiredDate.format('ddd'))
    }

    useEffect(() => {
        let timeString = getTimeString(`${expireDate}T12:00:00`)
        settimeString(timeString)
    }, [expireDate])

    function makeid(){
    var text = "";
    var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    for( var i=0; i < 8; i++ ){
        text += possible.charAt(Math.floor(Math.random() * possible.length))
    };
    let testArr = codeArr.filter(c=> c.randomCode === text)
    if (testArr) {
        text += possible.charAt(Math.floor(Math.random() * possible.length))
        text += possible.charAt(Math.floor(Math.random() * possible.length))
    }
        setrandomCode(text)
        let createdAtDb = getTimeString()

        const newDbObj = {codeName: codeName, discountType:discountType, discountAmount:parseInt(discountAmount),
            discountCondition:parseInt(discountCondition), type:'dateLimit',
            randomCode:text, expireDate:timeString, site:'tlp', target:'self', createdAt : createdAtDb, isUsed: '' }
            
        props.firebase.promotionCodes().add(newDbObj)
        .then(res=> {
            // var uid = res.id
            // newDbObj['uid'] = uid
            // setcodeArr(oldArr => [newDbObj, ...oldArr])
            setdiscountAmount(0)
        })
        .catch(err => {
            alert('다시 시도 하세요.\n에러코드 : ' + err)
        })
    }
    const [inValid, setinValid] = useState(true)
    
    useEffect(() => {
        const isAmount = discountType==='onlyPublished' || discountType==='minPrice'  ? 
        discountCondition ===0 ? false : true : true

        const valid = codeName==='' ||discountType==='' || !isAmount ||
        parseInt(discountAmount) < 1 || expirePeriod < 0  || expirePeriod >31
        setinValid(valid)
        setrandomCode('')
        // console.log(codeName,'-',discountCondition,'-',discountType,'-', discountAmount,'-', isAmount)
    }, [discountType, discountAmount, expirePeriod, discountCondition])
    useEffect(() => {
        setdiscountCondition(0)
    }, [discountType])
    let formattedtoday = parseInt(today.format('YYMMDD'))
    //기본 '코드가 복사되었습니다.' 숨김
    const [copied, setcopied] = useState({display:'none'})
    function alertCopied (){
        // '코드가 복사되었습니다.' 보여짐
        setcopied({})
        // 일정시간 후 '코드가 복사되었습니다.' 숨김
        setTimeout(()=>{setcopied({display:'none'})}, 1500)
    }
    function TempMsg (type, randomCode) {
        return () => {
          switch (type) {
            case 'copiedCP':
              NotificationManager.info(`쿠폰 코드(${randomCode})가 복사되었습니다.`, 'COPIED', 1500, () => {
                alert('callback');
              });
              break;
          }
        };
    };
   
    return (
        <div className="createCouponsFrame">
            <div className="makingCouponBxGroup">
                <div className="couponNameBx">
                    <p>쿠폰 이름</p>
                    <input type='text' name='codeName' value={codeName} 
                    onChange={e=>setcodeName(e.target.value)} required/>
                </div>
                <div className="couponConditionBx">
                    <p>사용 조건 (택 1)</p>
                    <div>
                        <div>
                            <input type='checkbox' name='discountType' onChange={()=> setdiscountType('anyCondition')} checked={discountType === 'anyCondition'} />
                            <span>무조건</span>
                        </div>
                        <div>
                            <input type='checkbox' name='discountType' onChange={()=> setdiscountType('onlyPublished')} checked={discountType === 'onlyPublished'} />
                            <span>책 인쇄</span>
                        </div>
                        <div>
                            <input type='checkbox' name='discountType' onChange={()=> setdiscountType('onlyVrRent')} checked={discountType === 'onlyVrRent'} />
                            <span>기기대여</span>
                        </div>
                        <div>
                            <input type='checkbox' name='discountType' onChange={()=> setdiscountType('onlyVrEvent')} checked={discountType === 'onlyVrEvent'} />
                            <span>기기구매</span>
                        </div>
                        <div>
                            <input type='checkbox' name='discountType' onChange={()=> setdiscountType('minPrice')} checked={discountType === 'minPrice'} />
                            <span>최소 금액</span>
                        </div>
                    </div>
                </div>
                <div className="couponPriceBx">
                    {discountType === 'onlyPublished' ? 
                    <div>
                        <p>최소 인쇄 권수</p>
                        <span>인당</span>
                        <input type='number' min='1' max='100' name='discountCondition' value={discountCondition} 
                        onChange={e=>setdiscountCondition(e.target.value)}/>
                        <span>권</span>
                    </div>    
                    :discountType === 'minPrice' ? 
                    <div>
                        <p>최소 결제 금액</p>
                        <input type='text' name='discountCondition' value={discountedCondition} 
                        onChange={e=>setdiscountCondition(e.target.value.replace(/[^0-9]/g,""))}/>
                        <span>원</span>
                    </div>
                    : null}
                    <div>
                        <p>할인 금액</p>
                        <input type='text' name='discountAmount' value={discountedAmount} 
                        onChange={e=>setdiscountAmount(e.target.value.replace(/[^0-9]/g,""))}/>
                        <span>원</span>
                    </div>
                </div>
                {/* <div className="couponPriceBx">
                    
                </div> */}
                <div className="couponPeriodBx">
                    <p>사용 가능 기한</p>
                    <div>
                        <span>발급일로 부터</span>
                        <input type='number' min='0' max='31' name='expirePeriod' value={expirePeriod} 
                        onChange={e=> getExpireDate(e.target.value)}/> 일까지 사용 가능 (오늘부터 최대 31일 설정 가능)
                    </div>
                </div>
                <div className="couponCompleteBx">
                    <p>쿠폰 생성</p>
                    <div>
                        <span>{expireDate} ({expireDay}) 까지 사용 가능한 {discountAmount}원 할인 쿠폰(일회성)</span>
                        <button onClick={makeid} disabled={inValid}>발급하기</button>
                        {randomCode !== '' && 
                        <CopyToClipboard text={randomCode}
                        onCopy={TempMsg('copiedCP', randomCode)}>
                            <span>{randomCode}</span>
                        </CopyToClipboard>
                        }
                    </div>
                </div>
                <div className="couponGuideText">
                    ※ 발급 된 쿠폰 할인 금액은 프로그램 한 건(1인)마다 각각 적용되는 가격입니다.
                </div>
            </div>

            
            <span style={copied}>코드가 복사되었습니다.</span>

            <div className="madeCouponGroup">
                <span>(최신순)</span>
                <table border='1'>
                    <thead>
                        <tr>
                            <th>쿠폰 이름</th>
                            <th>쿠폰 사용 조건</th>
                            <th>할인 금액</th>
                            <th>만료일</th>
                            <th>쿠폰 코드</th>
                            <th>발행일</th>
                            <th>쿠폰 상태</th>
                            <th>비고</th>
                        </tr>
                    </thead>
                    <tbody>
                    {codeArr && codeArr.map(c=> {
                            let {discountType, createdAt, expireDate, isUsed, randomCode } = c
                            const discounttext = discountType === 'anyCondition' ? '무조건' 
                            : discountType === 'onlyPublished' ? '책 인쇄'
                            : discountType === 'onlyVrRent' ? '기기대여'
                            : discountType === 'onlyVrEvent' ? '기기구매'
                            : discountType === 'minPrice' ? '최소 금액' : ''
                            const conditionType = discountType === 'anyCondition' ? '' 
                            : discountType === 'onlyPublished' ? '권 이상'
                            : discountType === 'onlyVrRent' ? ''
                            : discountType === 'onlyVrEvent' ? ''
                            : discountType === 'minPrice' ? '원 이상' : ''
                            
                            let createdY = createdAt.substr(0,2)
                            let createdM = createdAt.substr(2,2)
                            let createdD = createdAt.substr(4,2)
                            let createdDay = dayjs('20'+createdY+'-'+createdM+'-'+createdD).format('ddd')

                            let expiredY = expireDate.substr(0,2)
                            let expiredM = expireDate.substr(2,2)
                            let expiredD = expireDate.substr(4,2)
                            let expiredDay = dayjs('20'+expiredY+'-'+expiredM+'-'+expiredD).format('ddd')
                            let expired = parseInt(`${expiredY}${expiredM}${expiredD}`)
                            let isexpired = expired-formattedtoday
                            const condition = isUsed !=='' ? '사용 완료'
                            : isUsed === '' && isexpired >=0 ? '미사용'
                            : isUsed === '' && isexpired <0 ? '유효기간 만료' : null
                            // const discountedAmount = c.discountAmount
                            return(
                            <tr key={randomCode}>
                                <td>{c.codeName || '미입력'}</td>
                                <td>{discounttext} {c.discountCondition || ''} {conditionType}</td>
                                <td>{c.discountAmount.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')} 원</td>
                                <td>20{expiredY}-{expiredM}-{expiredD} ({expiredDay})</td>
                                <td>
                                    <CopyToClipboard text={randomCode}
                                    onCopy={TempMsg('copiedCP', randomCode)}>
                                        <span>{randomCode}</span>
                                    </CopyToClipboard>
                                </td>
                                <td>20{createdY}-{createdM}-{createdD} ({createdDay})</td>
                                <td>{condition}</td>
                                <td><button onClick={()=>props.deleteCode(c)}>삭제</button></td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div>
            <NotificationContainer/>
        </div>
    );
};

export default withFirebase(DateLimit);
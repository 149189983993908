import React, { Component } from 'react';
import * as ROUTES from '../../constants/routes';
import {Link} from 'react-router-dom'
import ARappImg from '../images/ARappImg.png'
import downloadicon from "../images/downLoadIcon.png"
import './AppDownloadPage.css'
import './AppDownloadPageTemp.css'

class AppDownloadPageTemp extends Component {
    componentDidMount(){
        try {
          window.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
          });
        } catch (error) {
          window.scrollTo(0, 0);
        };
    }
    render() {
        return (
            <div className='arDownloadWrapper'>
                <div className='arDownloadContainer'>
                    <div className='arDownloadText arDownloadHead'>AR APP (체험용)</div>
                    <div className='arDownloadText arDownloadBody'>가지고 계신 핸드폰에 따라 다운로드해주세요.</div>
                    <Link className="arDownloadText arDownloadTag" to={{pathname: `${ROUTES.GUIDE}`,state : { tab : "Manual"}}} >AR App 사용 방법</Link>
                </div>
                <div className="arDownloadContainerFlexible">
                    <div className="arDownloadContainerRow"><img className='arDownloadImg' src={ARappImg} alt='downloadimg'/></div>
                    {/* <div> */}
                        <div className="arDownloadBtnWrapper">
                            <a className="arDownloadContainerRow arDownloadBtnContainer" href={`https://firebasestorage.googleapis.com/v0/b/xr-book.appspot.com/o/tlp%2FtestAppApk%2Fxrbook_test_apk?alt=media&token=b265b036-3954-4947-b15d-9cca770e75f6`} target='_blank' download="XR_BOOK_체험용">
                                <p className="arDownloadIconText">안드로이드 다운로드</p><img className="arDownloadIcon" src={downloadicon} alt="다운로드아이콘"/>
                            </a>
                            <a href='itms-services://?action=download-manifest&url=https://bit.ly/374tNBo' target='_blank' className="arDownloadContainerRow arDownloadBtnContainer">
                                <p className="arDownloadIconText">아이폰(ios) 다운로드</p><img className="arDownloadIcon" src={downloadicon} alt="다운로드아이콘"/>
                            </a> 
                        </div>
                    {/* </div> */}
                </div>
            </div>
            // <div>
            //     <div className="downloadheight experienceheight">
            //         <h3 className="experiencetitle">
            //             AR APP (체험용)<br/>
            //             <span>가지고 계신 핸드폰에 따라 다운로드 해주세요. &nbsp; <Link className="pc" to={{pathname: `${ROUTES.GUIDE}`,state : { tab : "Manual"}}} > AR App 사용 방법</Link></span>  
            //             <span><Link className="onlymobile" to={{pathname: `${ROUTES.GUIDE}`,state : { tab : "Manual"}}} >AR App 사용 방법</Link> </span>
            //         </h3> 
            //         <div className="downloadAR experienceAR">
            //             <div className="container">
            //                 <div className="downloadleft">
            //                     <img src={ARappImg} alt='downloadimg'/>
            //                 </div>
            //                 <div className="downloadright">
            //                     <a className="experience" href={`https://firebasestorage.googleapis.com/v0/b/xr-book.appspot.com/o/tlp%2FtestAppApk%2Fxrbook_test_apk?alt=media&token=b265b036-3954-4947-b15d-9cca770e75f6`} target='_blank' download="XR_BOOK_체험용">
            //                     <p>안드로이드 다운로드</p><img className="experienceImg" src={downloadicon} alt="다운로드아이콘"/></a>
            //                     <a className="experience"><p>아이폰(ios) 다운로드</p><img className="experienceImg" src={downloadicon} alt="다운로드아이콘"/></a>
            //                 </div> 
            //             </div>
            //         </div>
            //     </div>
            // </div>
        );
    }
}

export default AppDownloadPageTemp;
import React, { Component, useEffect } from 'react';
import { Link } from 'react-router-dom';
import {withAuthorization, withEmailVerification} from '../Session'
import { withFirebase } from '../Firebase';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes';
import * as ROLES from '../../constants/roles';
import './SignUp.css'
import { getTimeString, isEmail } from '../helpers';
import SignUpLoading from '../Utils/SignUpLoading';
import Spinner from '../Utils/Spinner';

const SignUpPage = props => {
  useEffect(() => {
    const authUser = localStorage.getItem('authUser')
    if(authUser) props.history.push(ROUTES.TLP_MAIN)
  }, [])
  return(
  <div className="signBg">
    <div className="signFrame">
      <h1>회원가입</h1>
      <SignUpForm />
    </div>
  </div>
)};

class SignUpFormBase extends Component {
  state={
    authUser:{},
    username: '',
    email: '',
    isEmailValid:false,
    passwordOne: '',
    passwordTwo: '',
    isAdmin: false,
    error: null,
    agree:false,
    loading:false,
    show: false,
    msg:'계정 생성 중입니다.'
  };

  onSubmit = event => {
    
    const { username, email, isEmailValid, passwordOne, passwordTwo, agree } = this.state;
    const roles = {member : 'member'};
    let isCertificated = false
    let certificationInfo = {}
    let createdAt = getTimeString()
    // event.preventDefault();
    // roles[ROLES.MEMBER] = ROLES.MEMBER;
      this.setState({loading:true})
      this.props.firebase
      .doCreateUserWithEmailAndPassword(email, passwordOne, username)
      .then(async authUser => {
        this.setState({msg:'계정 생성되었습니다.'})
        let data = {authUserId : authUser.user.uid, username, email, createdAt}
        // console.log(data)
        if(authUser !== undefined){
          this.setState({msg:'회원 정보를 저장 중입니다.'})
        await fetch("/api/v1/signUp", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        })
          .then((response) => {
            response
              .json()
              .then((res) => {
                // console.log(res)
                if(res.msg === 'success'){
                  // this.setState({loading:false})
                  this.props.history.push({pathname: `${ROUTES.EMAIL_VERIFIEDPAGE}`, state: {username : username}})
                } else {
                  // console.log(res.msg)
                  this.props.firebase.user(authUser.user.uid).set({
                    username,
                    email,
                    status : 'active',
                    roles : {member : 'member'},
                    isCertificated:false,
                    certificationInfo:{},
                    createdAt:getTimeString()
                  })
                  .then(()=>{
                    // this.setState({loading:false})
                    this.props.history.push({pathname: `${ROUTES.EMAIL_VERIFIEDPAGE}`, state: {username : username}})
                  })
                  .catch(err=>console.log(err))
                }
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
        } else {
          alert('다시 시도해 주세요.')
        }
      })
      // .then((res) => {
      //   this.setState({loading:false})
      //   console.log(res)
      //   // return this.props.firebase.doSendEmailVerification();
      //   // this.props.history.push(ROUTES.EMAIL_VERIFIEDPAGE)
      // })
      .catch(error => {
        this.setState({show:false, loading:false})
        if (error.code === 'auth/email-already-in-use') {
          error.message = '이미 가입된 아이디 입니다.';
        } else if (error.code === 'auth/invalid-email') {
          error.message = '유효한 이메일 주소가 아닙니다.';
        } else if (error.code === 'auth/operation-not-allowed') {
          error.message = '탈퇴 혹은 비활성화 된 계정입니다.';
        } else if (error.code === 'auth/weak-password') {
          error.message = '보안성이 낮은 비밀번호 입니다.';
        } else if (error.code === 'auth/email-already-in-use') {
          error.message = '이미 가입된 회원 메일 입니다.';
        } else if(error.code) {
          error.message = `잠시 후 다시 시도해주세요.`
        }

        this.setState({ error, loading:false });
      });
      // event.preventDefault();
  };
  onSubmitCheck = (event) => {
    event.preventDefault();
    const { username, email, isEmailValid, passwordOne, passwordTwo, agree } = this.state;
    if(username ===''){
      alert('이름을 입력하세요.')
    } else if(!isEmailValid){
      alert('아이디(이메일) 주소를 다시 확인해 주세요.')
    } else if (passwordOne.length<8 || passwordOne === '') {
      alert('비밀번호를 8자 이상 작성해 주세요.')
    }else if(passwordOne !== passwordTwo){
      alert('비밀번호가 일치하지 않습니다.')
    }else if (!agree){
      alert('개인 정보 수집 및 활용에 동의해주세요.')
    } else {
      this.setState({
        loading:false, show:true
      })
    }
  };
  onChange = event => {
    this.setState({ [event.target.name]: event.target.value.trim() });
  };
  onEmailChange = (e) => {
    let mailValue = e.target.value.trim()
    if(isEmail(mailValue)){
      this.setState({
        email : mailValue,
        isEmailValid :true
      })
  }else if(!isEmail(mailValue)) {
    this.setState({
      email : mailValue,
      isEmailValid :false
    })
  }
  }
  onChangeCheckbox = () => {
    this.setState({ agree: !this.state.agree });
  };
  
  render() {
    const {
      username, email, isEmailValid, passwordOne, passwordTwo, error, agree, loading, msg, show
    } = this.state;

    const isInvalid =
      passwordOne !== passwordTwo ||
      passwordOne === '' ||
      email === '' ||
      !isEmailValid ||
      username === ''||
      agree === false;
      // console.log(error)
    const isValid = !isInvalid ? 'valid' : 'invalid'
    // console.log(isValid)
    return (
      <React.Fragment>
      {!loading ?
      <React.Fragment>
        <form className="inputGroup" 
        // onSubmit={this.onSubmit}
        >
        <input
          name="username"
          value={username}
          onChange={this.onChange}
          type="text"
          placeholder="이름"
        />
        <input
          name="email"
          value={email}
          onChange={this.onEmailChange}
          type="text"
          placeholder="아이디(메일주소) : xrbook@thehrder.com"
          autoCapitalize='off'
          style={email !=='' ? isEmailValid ? {color:'black', fontWeight:600} : {color:'#c56e6e', fontWeight:600} : {color:'black'}}
        />
        <input
          name="passwordOne"
          value={passwordOne}
          onChange={this.onChange}
          type="password"
          placeholder="비밀번호(8자 이상, 문자/숫자/기호 사용 가능)"
          style={passwordOne !=='' ? passwordOne.length>7 ? {color:'black'} : {color:'#c56e6e'} : {color:'black'}}
        />
        <input
          name="passwordTwo"
          value={passwordTwo}
          onChange={this.onChange}
          type="password"
          placeholder="비밀번호 확인"
          style={passwordTwo !=='' ? passwordTwo ===passwordOne ? {color:'black'} : {color:'#c56e6e'} : {color:'black'}}
        />
        
        {error && <p>{error.message}</p>}
        
        <a className="termsLink" href={ROUTES.POLICIES} target='_blank'>개인정보 활용동의 약관 보기</a>
        
        <div className="checkBx">
          <input type="checkbox" name="agree" checked={agree} onChange={this.onChangeCheckbox}/>
          <span  onClick={this.onChangeCheckbox}>문의 사항 안내를 위한 개인 정보 수집 및 활용에 동의합니다. </span>&nbsp;&nbsp;
          <span onClick={(e)=>this.onSubmitCheck(e)}>&nbsp;&nbsp;</span>
        </div>
        {/* 활성화 클래스네임 : 'valid' 비활성화클래스네임: 'invalid' */}
        <button className={`${isValid}`}
        // disabled={isInvalid}
        //  type="submit"
        // onClick={()=>alert('사이트 정상화 작업 중입니다.(~ 10월 16일까지 예정)\n불편을 드려 죄송합니다.\n문의 사항은 상단 문의하기를 이용해주세요.')}
         onClick={(e)=>this.onSubmitCheck(e)}
         >
          가입하기
        </button>
      </form>
      
      {!show ? null
      : 
      <div className="signConfirmBg">
        <div className="signConfirmBox">
          <ul>
            <li className="signTiteTxt">아래의 정보로 회원가입을 진행하시겠습니까?</li>
            <li className="signInfoTxt">이름 : {username}</li>
            <li className="signInfoTxt">아이디 : {email}</li>
            <li className="signGuideTxt">계정 이름과 비밀번호의 수정 및 추가 정보 등록은 MY PAGE에서 가능합니다.
             아이디로 입력한 메일은 비밀번호 찾기 등에 활용되므로 다시 한번 확인해주시기 바랍니다.</li>
          </ul>
          <div className="signConBtnGroup">
            <span onClick={()=>this.onSubmit()}>확인</span>
            <span onClick={()=>this.setState({show:false, loading:false})}>취소</span>
          </div>
        </div>
      </div>
      }

    </React.Fragment>
    :
    // <SignUpLoading msg={msg}/>
    <Spinner msg={msg}/>
    }
    </React.Fragment>
    );
  }
}

const SignUpLink = () => (
  <p className="signUpLink">
    <Link to={ROUTES.SIGN_UP}>회원가입</Link>
  </p>
);

const condition = authUser => authUser
const SignUpForm = compose(
  withRouter,
  withFirebase,
)(SignUpFormBase);

// const SignUpForm = withAuthorization(condition)(SignUpFormBase);

export default compose(
  withEmailVerification,
  withFirebase,
)(SignUpPage);

export { SignUpForm, SignUpLink };

import React, { Component } from 'react'
import { withFirebase } from '../../../Firebase'
import VideoUpload from '../VideoUpload'
import ProgressBar from 'react-bootstrap/ProgressBar'
import SlideVideoItem from '../SlideVideoItem'

class GallerySection extends Component {0
    // Class = 배경이미지1, (이미지2, 텍스트 2, 순서) * n, section
    // Self = 배경이미지1,  (이미지2, 텍스트 2, 순서) * n, section
    // XR BOOK = 배경동영상1,  (이미지2, 텍스트 2, 순서) * n, section
    // 갤러리 - (배경동영상-긴텍스트) *4, section
    // 고객센터 - { 카테고리(배송, 반품/교환, 취소, FAQ),
    //                  텍스트 1, 긴텍스트 1, show여부? }* n, section
    state={
        bgVideoUrl:'',
        slideList:[],
        text1:'',
        text2:'',
        section : 'gallery',
        site:'tlp',
        totalBytes : 0,
        bytesTransferred : 0
}
componentDidMount(){
    this.getgalleryInfo()
}
onPropsChange = (name, value) => {
    this.setState({
        [name] : value
    })
}
onStateChange = e => {
    this.setState({
        [e.target.name] : e.target.value
    })
}
addSlideListItem = () => {
    const {bgVideoUrl, text1, text2, slideList} = this.state;
    const data = {bgVideoUrl:bgVideoUrl,text1:text1, text2:text2}
    this.setState({
        bgVideoUrl:'', text1:'', text2:'', 
        slideList: slideList.concat(data)
    })
}
editSlideListItem = (item, id) => {
    const {slideList} = this.state
    var newList = slideList.slice()
    newList[id] = item
    this.setState({slideList : newList})
}
deleteSlideListItem = item => {
    const {slideList} = this.state
    var newList = slideList.slice()
    var idx = newList.indexOf(item)
    if (idx >-1) newList.splice(idx, 1)
    this.setState({slideList : newList})
}
getgalleryInfo = () => {
    this.props.firebase.mainSection('gallery').get()
    .then(res => {
        const result = res.data()
        this.setState(result)
    })
    .catch(err=> {
        alert('데이터 불러오기를 실패했습니다. \n에러코드 : '+err)
        // window.location.reload()
    })
}
submitgalleryData = e => {
    e.preventDefault()
    const {slideList, section, site} = this.state;
    const data = {slideList, section, site}
    this.props.firebase.mainSection('gallery').set(data,{merge:true})
    .then(()=> {
        alert('Gallery 정보가 수정되었습니다.')
        this.getgalleryInfo()
        })
    .catch(err=> {
        alert('오류가 발생했습니다. 잠시 후 다시 시도해 주세요.\n에러코드 : '+err)
    })
}

    render() {
        const {bgVideoUrl, text1, text2, section, slideList, totalBytes, bytesTransferred}= this.state
        const isInvalid = bgVideoUrl === '' ||  text1 === '' || text2 === ''
        const isInvalid2 = slideList === []
        const progress = isNaN(bytesTransferred/totalBytes) ? 0 : parseInt((bytesTransferred/totalBytes) * 100)
        const formattedText = text1.replace(/(<br>|<br\/>|<br \/>)/g, '\n')
        return (
             <div>
               <div>Gallery</div>
               <button onClick={this.submitgalleryData} disabled={isInvalid2}>적용</button>
                <div> 
                     <div>슬라이드
                            {/* <div>배경동영상 :
                            <div><ProgressBar now={progress} label={`${progress}%`} style={{width:'50vw'}}/></div>
                            <VideoUpload 
                                onUpdate={this.onPropsChange}
                                itemName={'bgVideoUrl'}
                                fileName={'main/gallery'}
                                />
                            </div> */}
                            <div>텍스트 1 :
                                <textarea name='text1' value={formattedText} 
                                onChange={e=> this.setState({text1: e.target.value.replace(/(?:\r\n|\r|\n)/g, '<br />')}) }/>
                            </div>
                        
                            <div>텍스트 2 :
                                <input type='text' name='text2' value={text2} onChange={this.onStateChange}/>
                            </div>
                            <button onClick={this.addSlideListItem} disabled={isInvalid}>등록</button>
                    </div>
                    <div>{section}</div>
                    
                    <div >
                    {/* {slideList && slideList.map((c, i)=> {
                        return(
                        <SlideVideoItem 
                        key={c.bgVideoUrl} item={c} onDelete={this.deleteSlideListItem} onEdit={this.editSlideListItem} idx={i}
                        />
                        )
                    })
                    } */}
                    </div>

                </div>

            </div>
        )
    }
}
export default withFirebase(GallerySection)
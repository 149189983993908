import React, { Component } from 'react'
import { isEmail, phoneFormatter, sideTrim, getTimeString } from '../../helpers'
import {withFirebase} from '../../Firebase'
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import Spinner from '../../Utils/Spinner'
import * as ROUTES from '../../../constants/routes'
import '../Order.css'
import {Link} from 'react-router-dom'

class OrderProgramPage extends Component {
   state = {
        programData: this.props.selectedProgram,
        programID : 0,
        participants : '',
        groupTitle :'',
        manager : this.props.authUser.username,
        email : this.props.authUser.email,
        number: this.props.authUser.number||'',
        contents: '',
        agree:'',
        location:'',
        book:'워크북',
        vrRent : true,
        tabletRent : false,
        type:'견적 문의',
        isEmailValid: true,
        loading : false
    }
    validateEmail = e => {
        const emailEntered = e.target.value
        if (isEmail(this.state.email)) {
          this.setState({
            isEmailValid: true,
            email : emailEntered
          });
        } else {
          this.setState({
            isEmailValid: false,
            email : emailEntered
          });
        }
      };
      stateChange = (e) => {
        const targetName = e.target.name
        const targetValue= e.target.value
        // console.log(targetName, targetValue)
        if(targetName === 'contents'){
            // console.log(targetName, targetValue)
            this.setState({
                [targetName] : targetValue.replace(/(?:\r\n|\r|\n)/g, '<br />')
            })
        } else {
        this.setState({
            [targetName] : targetValue
        })
    }
    }
    onSubmit = async(e) => {
        
        e.preventDefault();
        const {programData, programID, participants, groupTitle, manager, location, book, vrRent, type,
            tabletRent, email, number, contents } = this.state;
            let {selectedProgram, authUser} = this.props
        const {title, target, pid, digitalBookID} = selectedProgram
        this.setState({loading : true})
        const createdAt = getTimeString()
        const authUserId = authUser.uid
        const page = 'tlpProgram'
        const progress = 'applied'
        const progressAt = createdAt
        const programInfo = `${pid}_${title}`
        const memo=''; const code=''; const codeAt=''; const selectedDays=[]; const price = ''
        const data = { authUserId, programInfo, participants, groupTitle, manager, tabletRent, title, target,
             email, number, contents, progress, progressAt, createdAt, page, book, vrRent, type, location, digitalBookID,
             memo, code, codeAt, selectedDays, price }
             await fetch("/api/v1/orderAlerts", {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify(data)
            })
            .then(response => { response.json()
                .then((res) => {
                   
                  if(res.msg === 'success') {
                  alert('프로그램 신청이 접수되었습니다.\n영업일 기준 2-3일 이내 회신드리겠습니다.')
                  this.props.history.push(ROUTES.PROGRAM_APPLIEDPAGE)
                } else {
                  alert('다시 시도해주세요. \n오류가 계속되면 관리자에게 문의해주세요. \nxrbook@thehrder.com')
                }
              })
              .catch(err =>  alert(`다시 시도해주세요. \n오류가 계속되면 관리자에게 문의해주세요. \nxrbook@thehrder.com \n에러 : ${err}`))
              })
            .catch(err =>  alert(`다시 시도해주세요. \n오류가 계속되면 관리자에게 문의해주세요. \nxrbook@thehrder.com \n에러 : ${err}`))
            this.setState({loading : false})
    }

    render() {
        const { location, book, vrRent, type, tabletRent,
            participants, groupTitle, manager, email, number, contents, agree, isEmailValid, loading } = this.state;
        const numLeng = number.length
        const {selectedProgram, onUpdate} = this.props
        const {title, target} = selectedProgram
        const isInvalid = 
        participants === '' ||
        groupTitle === '' ||
        manager === '' ||
        numLeng < 9 ||
        isEmailValid === false ||
        email === '' ||
        agree === '' ||
        contents === ''
        const enteredContents =  contents.replace(/(<br>|<br\/>|<br \/>)/g, '\n')
        return (
            <React.Fragment>
            {!loading ? <div className="orderPFrame">

           
                <form onSubmit={this.onSubmit} method='post'>
                    <ul>
                        <li>
                            <span className="cate">신청자</span> 
                            <span>
                                <span>{manager} &nbsp;|&nbsp; {email}</span>
                                <input className="phoneNumber" type='text' name='number' value={number}
                                onChange={event => this.setState({number: phoneFormatter(event.target.value.replace(/[^0-9]/g,""))})}/>
                            </span>
                        </li>
                        <li>
                            <span className="cate">회사/기관 명</span>  
                            <input type='text' name='groupTitle' value={groupTitle}
                            onChange={event => this.setState({groupTitle: sideTrim(event.target.value)})}/>
                        </li>
                        <li>
                            <span className="cate">프로그램 명</span>  
                            <span>{title} | Off-line Learning Program | {target} 대상</span>
                        </li>
                        <li>
                            <span className="cate">교육 인원</span>  
                            <input type='text' name='participants' value={participants}
                            onChange={event => this.setState({participants: event.target.value.replace(/[^0-9]/g,"")})}/>
                        </li>
                        <li>
                            <span className="cate">교육 예정 지역</span>  
                            <select name = 'location' value={location} onChange={e=> this.setState({location : e.target.value})}>
                                <option>미정인 경우 '기타'로 선택</option>
                                <option value='경기'>경기</option>
                                <option value='서울'>서울</option>
                                <option value='강원'>강원</option>
                                <option value='충청'>충청</option>
                                <option value='전라'>전라</option>
                                <option value='경상'>경상</option>
                                <option value='제주'>제주</option>
                                <option value='기타'>기타</option>
                            </select>
                        </li>
                        <li className="checkInputBx">
                            <span className="cate">교재</span>
                            <span>
                                <input type='checkbox' name='book' checked={book==='워크북'} onChange={()=>this.setState({book:'워크북', tabletRent:false})}/>
                                <span className="checkBxName" onClick={()=>this.setState({book:'워크북', tabletRent:false})}>워크북</span>
                                <input type='checkbox' name='book' checked={book==='디지털북'} onChange={()=>this.setState({book:'디지털북', tabletRent:true})}/>
                                <span className="checkBxName" onClick={()=>this.setState({book:'디지털북', tabletRent:true})}>디지털 북(태블릿 기기 필요)</span>
                            </span>
                        </li>
                        { book==='디지털북' && 
                            <React.Fragment>
                            <li className="checkInputBx tabletRental">
                                <span className="cate"></span>
                                <span>
                                    디지털북 선택 시 개별 태블릿을 지참하셔야 합니다.
                                    {/* <input type='checkbox' name='tabletRent' checked={tabletRent} onChange={()=>this.setState({tabletRent:true})}/> 
                                    <span className="checkBxName" onClick={()=>this.setState({tabletRent:true})}>태블릿 대여</span>
                                    <input type='checkbox' name='tabletRent' checked={!tabletRent} onChange={()=>this.setState({tabletRent:false})}/> 
                                    <span className="checkBxName" onClick={()=>this.setState({tabletRent:false})}>기기 보유(대여 안함)</span> */}
                                </span>
                            </li>
                            </React.Fragment>
                            }
                        <li className="checkInputBx">
                            <span className="cate">기기대여</span>
                            <span>
                                <input type='checkbox' name='vrRent' checked={vrRent} onChange={()=>this.setState({vrRent:true})}/> 
                                <span className="checkBxName" onClick={()=>this.setState({vrRent:true})}>오큘러스 고 대여</span>
                                <input type='checkbox' name='vrRent' checked={!vrRent} onChange={()=>this.setState({vrRent:false})}/> 
                                <span className="checkBxName" onClick={()=>this.setState({vrRent:false})}>기기 보유(대여 안함)</span>
                            </span>
                        </li>
                            
                        <li className="checkInputBx">
                            <span className="cate">요청 사항</span>
                            <span>
                                <input type='checkbox' name='type' checked={type==='견적 문의'} onChange={()=>this.setState({type:'견적 문의'})}/> 
                                <span className="checkBxName" onClick={()=>this.setState({type:'견적 문의'})}>견적 문의</span>
                                <input type='checkbox' name='type' checked={type==='미팅 요청'} onChange={()=>this.setState({type:'미팅 요청'})}/> 
                                <span className="checkBxName" onClick={()=>this.setState({type:'미팅 요청'})}>미팅 요청</span>
                            </span>
                        </li>
                        <li>
                            <span className="cate">내용</span>  
                            <textarea name='contents' placeholder='주제 / 교육 시기 / 대상 / 요청사항 등을 작성해 주세요.' value={enteredContents} onChange={this.stateChange}/>
                        </li>
                        <li className="policiesCate">
                            <span className="cate">개인정보 처리방침</span>  
                            <span>
                                <input type='checkbox' name='agree' checked={agree} onChange={this.stateChange}/>
                                <span className="checkBxName">동의합니다.</span>
                                <span className="policiesLink"><Link to={ROUTES.POLICIES}>개인정보 처리방침 내용보기</Link></span>
                            </span>
                        </li>
                        <li className="bottomBtnGroup">
                            <div onClick={()=>onUpdate()}>뒤로가기</div>
                            <button disabled={isInvalid} type='submit'>신청하기</button>
                        </li>
                    </ul>
                </form>
            </div>
            : <Spinner msg={`프로그램 신청 중입니다.\n약 1~2분이 소요될 수 있습니다.`}/>}
            </React.Fragment>
        )
    }
}
export default compose(
    withRouter,
    withFirebase,
  )(OrderProgramPage);
  
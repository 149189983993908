import React, { Component } from "react";
import {getTimeDayjs} from '../../../../helpers'
import dayjs from "dayjs";

export default class ProgramCanceled extends Component {
  state = {
    canceledList: this.props.canceledList,
    detailShow: "none",
  };

  onListStatechange = (e, list) => {
    const targetName = e.target.name;
    // console.log(targetName)
    const targetValue = e.target.value;
    if (targetName === "memo") {
      list[targetName] = targetValue.replace(/(?:\r\n|\r|\n)/g, "<br />");
    } else if (targetName === "price") {
      list[targetName] = targetValue.replace(/[^0-9]/g, "");
    } else {
      list[targetName] = targetValue;
    }
    this.setState({
      canceledList: list,
    });
  };
  onStateChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  editProgramInfo = (uid) => {
    const { canceledList } = this.state;
    const { oneditList } = this.props;
    oneditList(uid, canceledList, "canceled");
  };
  onToggle = () => {
    const { detailShow } = this.state;
    if (detailShow === "none") {
      this.setState({
        detailShow: "show",
      });
    } else if (detailShow === "show") {
      this.setState({
        detailShow: "none",
      });
    }
  };
  render() {
    // const { oneditList} = this.props
    const { canceledList, detailShow } = this.state;
    const {
      title,
      progressAt,
      memo,
      contents,
      manager,
      groupTitle,
      participants,
      selectedDays,
      price,
      progress,
      email,
      number,
      uid,
      target,
      location,
      vrRent,
      tabletRent,
      book,
      type,
    } = canceledList;
    const programTitle = `${title} : ${target}대상`;
    const isProgressAt = getTimeDayjs(progressAt)
    const enteredMemo = memo.replace(/<br\s*[\/]?>/gi, `\n`);
    const enteredContents = contents.replace(/<br\s*[\/]?>/gi, `\n`);
    const isPrice = price.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
    const days = selectedDays;
    const dayLeng = days.length;
    // console.log(days, dayLeng)
    const vrRental = vrRent ? "오큘러스 고" : "";
    const tabletRental = tabletRent ? (vrRent ? " / 태블릿" : "태블릿") : "";
    const rental = vrRental === "" && tabletRental === "" ? "안함" : "";
    return (
      <div className="programListFrame">
        <ul className="programListCont">
          <li>{manager}</li>
          <li>{groupTitle}</li>
          <li>{participants}</li>
          <li>{location}</li>
          <li>{programTitle}</li>
          <li>
            <select
              name="progress"
              value={progress}
              onChange={(e) => this.onListStatechange(e, canceledList)}
            >
              <option value="consulting">담당자 배정</option>
              <option value="finished">교육 종료</option>
              <option value="canceled">취소</option>
            </select>
          </li>

          <li>
            {isProgressAt}
          </li>
          <li>
            <div onClick={this.onToggle}>
              {detailShow === "none"
                ? `상세보기`
                : detailShow === "show"
                ? `상세닫기`
                : `상세정보`}
            </div>
            <button onClick={() => this.editProgramInfo(uid)}>적용</button>
          </li>
        </ul>

        <ul className={`${detailShow} detailBox`}>
          <li>
            <span>연락처</span>
            <span>{email}</span>
            <span>{number}</span>
          </li>
          <li>
            <span>신청 내용</span>
            <span>교재({book})</span>
            <span>
              기기대여({vrRental} {tabletRental} {rental} )
            </span>
            <span>요청사항({type})</span>
          </li>
          <li className="ReguireText">
          {contents &&
              contents.split("<br />").map((c, id) => {
                return (
                  <span key={id}>
                    {c}
                  </span>
                );
              })}
          </li>
          <li className="manegeText">
            <span>담당자/상세내용</span>
            <textarea
              name="memo"
              value={enteredMemo}
              onChange={(e) => this.onListStatechange(e, canceledList)}
            />
          </li>
          <li>
            <span>가격</span>
            <span>{isPrice || `미입력`}</span>
          </li>
          <li>
            <span>기간</span>
            {dayLeng < 0 ? (
              <span>미입력</span>
            ) : dayLeng === 1 ? (
              <span>{dayjs(days[0]).format("YYYY-MM-DD")}</span>
            ) : dayLeng > 1 ? (
              <span>
                {dayjs(days[0]).format("YYYY-MM-DD")}~
                {dayjs(days[dayLeng - 1]).format("YYYY-MM-DD")}
              </span>
            ) : (
              <span>미입력</span>
            )}
          </li>
          <li>
            <span>총 기간</span>
            <span>{dayLeng || 0} 일</span>
          </li>
        </ul>
      </div>
    );
  }
}

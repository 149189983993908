import React, { Component } from 'react'
import {withFirebase} from '../Firebase'
import {getTimeDayjs} from '../helpers'

class ClassInquiries extends Component {
    state = {
        programList : []
    }
    componentDidMount(){
        this.getProgramList()
    }
    getProgramList =()=> {
        let {authUser}=this.props
        this.props.firebase.tlpPrograms().where('authUserId','==', authUser['uid'] ).get()
        .then(result => {
            var programList = []
            result.forEach(res => {
                var listData = res.data()
                var pid = res.id
                programList.push({pid, ...listData})
            })
            this.setState({programList})
        })
    }
    render() {
        let {
            programList
        }= this.state
        return (
            <React.Fragment>
                {programList.length>0 ? programList.map(c=> {
                    const {groupTitle, participants, title, target, price, code, vrRent,tabletRent,
                            progress, progressAt, selectedDays, isPayUrl, payUrl, urlPaymentData} = c
                            // console.log(c)
                    const userPrice = price ==='' ? 0 : price.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
                    const userParticipants= participants.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
                    const userCode = code ==='' ? '-' : code
                    const userStatus = progress ==='applied' ? '신청 완료' :
                            progress === 'consulting' ? '담당자 배정' :
                            progress === 'finished' ? '교육 종료' :
                            progress === 'canceled' ? '신청 취소' :
                            progress === 'request' ? '결제요청':
                            progress === 'paid' ? '결제완료' : ''
                    let isProgressAt = getTimeDayjs(progressAt)
                    const daysLeng = selectedDays ? selectedDays.length : 0
                    const startDate = selectedDays ? selectedDays[0] : ''
                    let isStartDate = getTimeDayjs(startDate)
                    const endDate = selectedDays ? 
                    daysLeng > 0 ? `${selectedDays[daysLeng-1]}` : ''
                    :''
                    let isEndDate = getTimeDayjs(endDate)
                return (
                    <ul className="myProgramFrame" key={c.pid}>
                        <li><span>회사/학교 명</span>{groupTitle}</li>
                        <li><span>참여 인원</span>{userParticipants}</li>
                        <li><span>프로그램 명</span>{title}({target}대상)</li>
                        <li><span>결제 가격</span>{userPrice}원 
                        {!isPayUrl&&payUrl&&!urlPaymentData ? <span><a href={payUrl}>카드 결제 URL</a></span>
                        :urlPaymentData ? 
                        urlPaymentData.status === 20 ? <span>URL {urlPaymentData.remain_price.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,")}원 결제 ({(urlPaymentData.price-urlPaymentData.remain_price).toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,")}원 취소)</span>
                        :  urlPaymentData.status === 1 ? <span>URL {urlPaymentData.price.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,")}원 결제 완료</span>
                        :<span>관리자 문의</span>:''
                        } </li>
                        <li><span>교육 기간</span>{startDate !== undefined ? isStartDate : ''} - {endDate !== '' ? isEndDate:''}</li>
                        <li><span>코드 번호</span>{userCode}</li>
                        <li><span>진행 상황</span>{userStatus}</li>
                        <li><span>일자</span>{isProgressAt}</li>
                        <li><span>비고</span>{vrRent ? tabletRent ? `오큘러스고/태블릿 대여`:`오큘러스고 대여` : tabletRent ? `태블릿 대여` : `-`}</li>
                    </ul>
                    )
                })
            : <div className="noDataText">Off-line Learming Program에 대한 문의 내용이 없습니다.</div>}
        </React.Fragment>
        )
    }
}
export default 
withFirebase (ClassInquiries)
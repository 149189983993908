import React, { Component } from 'react'
import { compose } from 'recompose';
import {withAuthentication, withAuthorization} from '../../Session'
import DigitalbookToolPage from './DigitalbookToolPage/index'
import XRBOOKLogo from '../../Digitalbook/images/XRBOOKIcon.png'
import { faSyncAlt, faTrashAlt, faCopy } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import '../AdminDigitalbookPage.js/DigitalbookToolPage/DigitalbookToolPage.css'
import '../AdminDigitalbookPage.js/DigitalbookToolPage/DigitalbookToolPageResp.css'
class AdminDigitalbookPage extends Component {
    state = {
        selectedDigitalbook : [],
        digitalbookList : [],
        setData:''
    }
    componentDidMount(){
        this.getDigitalbookList()
    }
    // componentDidUpdate(prevProps, prevState){
    //     if(prevState.digitalbookList !== this.state.digitalbookList){
    //     }
    // }
    getDigitalbookList = () => {
        this.props.firebase.libraries().where('site','==','tlp').where('writerRole','==','admin').get()
        .then(result=>{
            let digitalbookList = []
            result.forEach((doc) => {
                var bid = doc.id
                var listData= doc.data();
                digitalbookList.push({bid, ...listData})
                })
            this.setState({
                digitalbookList 
            })
        })
    }
      onSelectDigitalbook = digitalBook => {
        this.setState({
            setData: digitalBook.bid,
            selectedDigitalbook : digitalBook            
        })
    }
    onCopyDigitalbook = digitalBook => {
        let result = window.confirm('선택한 디지털북을 복사하시겠습니까? 복사 후 꼭 디지털북을 저장하세요.')
        if(result){
        let tempDigitalbook = Object.assign({}, digitalBook)
        delete tempDigitalbook.bid
        tempDigitalbook.bookTitle = tempDigitalbook.bookTitle+'-복사본'
        // console.log(tempDigitalbook)
        this.setState({
            selectedDigitalbook : tempDigitalbook,
            setData:''         
        })
        }
    }
    onSubmitDigitalbook = (pageList,bookTitle,iconUrl, bookTarget) => {
        if(bookTitle === '' || bookTitle.replace(/-복사본/gi, "") ===''|| bookTitle.replace(/ /gi, "") ===''){
            alert('책 제목을 설정하세요.')
        } else {
        if (this.state.setData === '' || this.state.setData === undefined ){
            let site = 'tlp'
            let authUser = JSON.parse(localStorage.getItem('authUser'))
            let writerId = authUser.uid
            let writerRole = authUser.roles.member
            this.props.firebase.libraries().add({pageList,bookTitle, bookTarget, iconUrl, site, writerId, writerRole})
            .then(res=> {
             let bid = res.id
             let tempList = []
             tempList.push({pageList ,bookTitle, bookTarget, iconUrl, bid, site, writerId, writerRole})
            this.setState({
                setData : bid
            })
             alert('디지털북이 저장되었습니다..')
               this.getDigitalbookList()
                    
            })
            .catch(err=> alert('잠시 후 다시 시도해 주세요.\n에러 : '+err))
        }else {
        this.props.firebase.library(this.state.setData).set({pageList,bookTitle, bookTarget, iconUrl}, {merge : true})
        .then(()=> {
        alert('디지털북이 저장되었습니다.')
        this.getDigitalbookList()
        })
        .catch( () => this.onSubmit(pageList))
        }
    }
    }
    onSubmit = pageList => {
        let site = 'tlp'
        let authUser = JSON.parse(localStorage.getItem('authUser'))
        let writerId = authUser.uid
        let writerRole = authUser.roles.member
        this.props.firebase.libraries().add({pageList, site, writerId, writerRole})
        .then(res=> {
         let bid = res.id
         this.setState({
            setData : bid,
            digitalbookList : this.state.getDigitalbookList.push({pageList, bid, site, writerId, writerRole})
        })
         alert('디지털북이 저장되었습니다.')
        //    this.getDigitalbookList()
                
        })
        .catch(err=> alert('잠시 후 다시 시도해 주세요.\n에러 : '+err))
    }
    onSetData = bid => {
        // console.log('bid :'+bid)
        if(bid === '') {
            this.setState({
                selectedDigitalbook : [],
                setData : bid
            })
        } else {
        this.setState({
            setData : bid
        })}
    }
    deleteDigitalbook = (id, bid) => {
        let tempList = this.state.digitalbookList.slice()
        let result = window.confirm('선택하신 디지털북을 삭제하시겠습니까?\n영구 삭제되어 복구할 수 없습니다.')
        if(result){
        this.props.firebase.library(bid).delete()
        .then(()=> {
            tempList.splice(id, 1)
            this.setState({
                digitalbookList : tempList
            })
            alert('디지털북이 삭제되었습니다.')
        })
        .catch(err=> alert('새로고침 후 다시 시도하세요.\n에러 : '+err))}
        
    }
    render() {
        let {digitalbookList, selectedDigitalbook, setData} = this.state
        // console.log(this.state)
        return (
            <React.Fragment>
                
                <DigitalbookToolPage digitalbook={selectedDigitalbook}
                getDigitalbookList={this.getDigitalbookList}
                onSubmitDigitalbook={(pageList, bookTitle, iconUrl, bookTarget)=>this.onSubmitDigitalbook(pageList,bookTitle,iconUrl, bookTarget)}
                onSetData={this.onSetData}
                />
                <br/><br/><br/><br/><br/><br/><br/>
                <div className="managementFrameTitle">
                    <h5>디지털 북 관리 ({setData})</h5>
                    <span onClick={()=>this.getDigitalbookList()}><FontAwesomeIcon icon={faSyncAlt} /></span>
                </div>
                <div className="managementFrame">
                        {digitalbookList&&digitalbookList.map((c, id)=> {
                        return( 
                            <div key={id} >  
                                <span  onClick={()=>this.onSelectDigitalbook(c)}>
                                    <span style={c.bid=== this.state.setData ? {border:'2px solid black'} : {border:'1px solid gray'}}>
                                        {c.iconUrl === '' ? 
                                        <img 
                                        // style={c.bid=== this.state.setData ? {border:'2px solid black'} : {border:'1px solid gray'}}
                                            src={XRBOOKLogo} alt='아이콘'/>
                                        :<img 
                                        // style={c.bid=== this.state.setData ? {border:'2px solid black'} : {border:'1px solid gray'}}
                                            src={c.iconUrl} alt='아이콘'/>}
                                    </span>
                                    <p  style={c.bid=== this.state.setData ? {fontWeight:'bold'} : {color:'gray'}}>{c.bookTitle}<br/>{c.bookTarget}</p>
                                </span>
                                <div className="manageBtnGroup">
                                    <div className="bookCopyBtn" onClick={()=>this.onCopyDigitalbook(c)}><FontAwesomeIcon icon={faCopy} /></div>
                                    <div className="bookDelBtn" onClick={()=>this.deleteDigitalbook(id, c.bid)}><FontAwesomeIcon icon={faTrashAlt} /></div>
                                </div>
                            </div>
                            )
                        })}
                </div>
            </React.Fragment>
        )
    }
}
const condition = authUser =>
authUser
export default
compose(
    withAuthentication,
withAuthorization(condition))
(AdminDigitalbookPage);
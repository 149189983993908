import React, { Component } from 'react'
import { withFirebase } from '../../../Firebase'
import Player from '@vimeo/player';
import { faVolumeMute, faVolumeUp } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import $ from 'jquery'
import './intro.css'
import './introRes.css'


class Intro extends Component {
    state={ 
        videoUrl:'',
        videoUrl2:'',
        text1:'',
        text2:'',
        text3:'',
        section : '',
        showIntroText : false,
        isMuteP : true,
        isMuteM : true,
    }
    componentDidMount(){
        this.getIntroInfo()
    }
    getIntroInfo = () => {
        this.props.firebase.mainSection('intro').get()
        .then(res => {
            const result = res.data()
            this.setState(result)
        })
        .catch(err=> {
            console.log(err)
            // window.location.reload()
        })
    }
    onControlMutedMobile = () => {
        var player = new Player($("#mainBgVideoM")[0]);
        var player2 = new Player($("#mainBgVideoP")[0]);
        player.getMuted().then(function(muted) {
            let mute = !muted
            player.setMuted(mute).then(function() {
                // this.setState({isMuteM : mute})
                // if(mute===true){player2.setMuted(true)}
            }).catch(function(error) {
                // an error occurred
            });
        }).catch(function(error) {
            // an error occurred
        });
        this.setState({isMuteM : !this.state.isMuteM})
    }
    onControlMutedPC = () => {        
        var player = new Player($("#mainBgVideoP")[0]);
        var player2 = new Player($("#mainBgVideoM")[0]);
        player.getMuted().then(function(muted) {
            let mute = !muted
            player.setMuted(mute).then(function() {
                // this.setState({isMuteP : mute})
                // if(mute===true){player2.setMuted(true)}
            }).catch(function(error) {
                // an error occurred
            });
        }).catch(function(error) {
            // an error occurred
        });
        this.setState({isMuteP : !this.state.isMuteP})
    }
    render() {
        const {videoUrl, videoUrl2, text3, isMuteP, isMuteM} = this.state
        // console.log(this.state)
        // const formattedInquiries = text3.replace(/(<br>|<br\/>|<br \/>)/g, '\n')
        return (
            <div className="introOutFrame">
                <div className="bgVideoFrame PCbgVideoFrame ">
                    <iframe title='intro'
                            src={videoUrl}
                            frameBorder="0"
                            allow="autoplay"
                            id='mainBgVideoP'>
                    </iframe>
                    <div className='titleTextGroup'>
                        <ul>
                            <li className="subTitle">
                                <span className={`${isMuteP ? 'muteIcon' : 'volumeIcon'}`} onClick={()=>this.onControlMutedPC()}>
                                    {isMuteP ?<FontAwesomeIcon icon={faVolumeMute} />:<FontAwesomeIcon icon={faVolumeUp} />}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className="bgVideoFrame MobgVideoFrame">
                    <iframe title='intro'
                            src={videoUrl2}
                            frameBorder="0"
                            allow="autoplay"
                            id='mainBgVideoM'>
                    </iframe>
                    <div className='titleTextGroup'>
                        <ul>
                            <li className="subTitle">
                                <span  className={`${isMuteM ? 'muteIcon' : 'volumeIcon'}`} onClick={()=>this.onControlMutedMobile()}>
                                    {isMuteM ?<FontAwesomeIcon icon={faVolumeMute} />:<FontAwesomeIcon icon={faVolumeUp} />}
                                </span>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        )
    }
}
export default withFirebase(Intro)


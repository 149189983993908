import React, { useState, useEffect } from 'react';
import DrawCoverApp from '../Utils/DrawCoverApp'
import SunEditor from 'suneditor-react';
import '../../../constants/suneditor.min.css';
import './pageItem.css'
import { faShareSquare, faPhotoVideo, faVideoSlash } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

const PageCheckItem = props => {
    let {pageItem, handleChange, drawingChange, setBookmark, setSharemark, totalPageNum, isChanged, pageId, mybookId, showBookmark} = props
    const [iframeHeight, setiframeHeight] = useState(window.innerWidth < 834 ? 2 : 5)
    const size = useWindowSize();
    function useWindowSize() {
        // Initialize state with undefined width/height so server and client renders match
        // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
        const [windowSize, setWindowSize] = useState({
          width: window.innerWidth,
          height: window.innerHeight,
        });

        useEffect(() => {
          // Handler to call on window resize
          function handleResize() {
            // Set window width/height to state
            setWindowSize({
              width: window.innerWidth,
              height: window.innerHeight,
            });
            setiframeHeight(window.innerWidth < 834 ? 2 : 5)
          }
          
          // Add event listener
          window.addEventListener("resize", handleResize);
          
          // Call handler right away so state gets updated with initial window size
          handleResize();
          
          // Remove event listener on cleanup
          return () => window.removeEventListener("resize", handleResize);
        }, []); // Empty array ensures that effect is only run on mount
      
        return windowSize;
      }
    return (
        <div className="userBookContentFrame userCheckFrame">
            {pageItem !== undefined &&
            <React.Fragment>
                {showBookmark === 'front' ?
                <div style={{display:'flex', justifyContent:'space-between' , borderBottom:'1px solid #dedede'}}>
                    <div style={{display:'flex', flexDirection:'row'}}>
                        <div className="bookMark" style={{background:'white', border:'none'}}
                            onClick={setBookmark}></div>
                        <div className="shareMark" style={{display:'none'}}
                        onClick={setSharemark}><FontAwesomeIcon icon={faShareSquare} /></div>
                    </div>
                    <div style={{display:'flex', flexDirection:'row'}}>
                    {pageItem.isShared ? <div className="shareMark"
                            style={pageItem['shareMark']?{color : 'orange'}:{color:'#dedede'}}
                            onClick={setSharemark}><FontAwesomeIcon icon={faShareSquare} /></div>
                        :<div className="shareMark" style={{backgroundColor:'white', color:'white', border:'1px solid transparent'}}><FontAwesomeIcon icon={faShareSquare} /></div>}
                        <div className="bookMark" style={pageItem['bookmark']?{background : 'orange'}:{background:'white'}}
                            onClick={setBookmark}></div>
                    </div>
                </div>

                : showBookmark === 'back' ?
                 <div style={{display:'flex', justifyContent:'space-between' , borderBottom:'1px solid #dedede'}}>
                    <div style={{display:'flex', flexDirection:'row'}}>
                        <div className="bookMark" style={pageItem['bookmark']?{background : 'orange'}:{background:'white'}}
                            onClick={setBookmark}></div>
                    {pageItem.isShared ? <div className="shareMark"
                            style={pageItem['shareMark']?{color : 'orange'}:{color:'#dedede'}}
                            onClick={setSharemark}><FontAwesomeIcon icon={faShareSquare} /></div>
                        :<div className="shareMark" style={{backgroundColor:'white', color:'white', border:'1px solid transparent'}}><FontAwesomeIcon icon={faShareSquare} /></div>}
                    </div>
                    <div style={{display:'flex', flexDirection:'row'}}>
                        <div className="shareMark" style={{display:'none'}}
                        onClick={setSharemark}><FontAwesomeIcon icon={faShareSquare} /></div>
                        <div className="bookMark" style={{background:'white', border:'none'}}
                            onClick={setBookmark}></div>
                        </div> 
                    </div>
                : null}

                {/* <div>{totalPageNum}</div> */}
                <div className="bookContentFrame">
                {pageItem !== undefined &&
                    <div  style={{  height:'100%', width:'100%',
                                    backgroundSize : 'cover, cover',
                                    backgroundRepeat : 'no-repeat, no-repeat',
                                    backgroundPosition : 'bottom, right bottom',
                                    backgroundImage: "url("+pageItem.pageBgImgUrl+")",
                                    backgroundColor : pageItem.pageBgImgColor}}>
                    {pageItem !== undefined &&
                        <div className="makingPaperFrame" style={{
                            height: '100%', width: '100%',
                            backgroundSize: 'cover, cover',
                            backgroundRepeat: 'no-repeat, no-repeat',
                            backgroundPosition: 'bottom, right bottom',
                            backgroundImage: "url(" + pageItem.pageBgImgUrl + ")",
                            backgroundColor: pageItem.pageBgImgColor }}>
                    {pageItem.userSection !== undefined && pageItem.userSection.map((val, idx) => {
                    let catId = `cat-${idx}`, ageId = `age-${idx}`
                    return (
                    <div key={idx}>
                        {val.type === 'inputText' ?
                            <div className="bookContFrame inputTextFrame">
                                <p id={ageId}>{val.textTitle}</p>
                                <div>{val.writingValue}</div>
                            </div>
                            : val.type === 'textarea' ?
                                <div className="bookContFrame textareaFrame">
                                    <p id={ageId}>{val.textTitle}</p>
                                    {/* 높이 조정해야함 */}
                                    <div>
                                        {val.writingValue && val.writingValue.split('<br />').map((c, id)=> {
                                                return(
                                                            <span key={id}>{c}<br/></span>
                                                        )})}
                                    </div>
                                    </div> :
                                val.type === 'drawingTool' ?
                                    <div className="bookContFrame drawingToolFrame">
                                        <p id={ageId}>{val.textTitle}</p>
                                        <DrawCoverApp contentHeight={val.contentHeight}
                                            userSectionId={idx} contents={val.writingValue} bgColor={val.bgColor}
                                            />
                                    </div>
                                    : val.type === 'textEditor' ?
                                        <div className="bookContFrame textEditorFrame" style={{ backgroundColor: val.editorBgColor }}>
                                            <SunEditor lang="ko" height='auto' setOptions={{ 
                                                resizingBar: false,
                                                defaultStyle:'font-size:1.6rem',
                                             }}
                                                showToolbar={false} disable={true} setContents={val.textTitle} />
                                            
                                        </div>
                                        : val.type === 'image' ?
                                            <div className="bookContFrame imageFrame">
                                                <React.Fragment>
                                                    {val.srcUrl != '' ?
                                                        <div style={{
                                                            width: '100%', display: 'flex', flexDirection: `${val.textDirection}`,
                                                            justifyContent: `${val.textJustify}`, alignItems: 'center'
                                                        }}>

                                                            <img src={val.srcUrl} alt={`image${idx}`} width={`${val.contentWidth}%`} />
                                                            <span>{val.textarea && val.textarea.split('<br />').map((c, id) => {
                                                                return (
                                                                    <span key={id}>{c}<br /></span>
                                                                )
                                                            })}
                                                            </span>
                                                        </div>
                                                        : <p>이미지를 먼저 업로드 해주세요.</p>}
                                                </React.Fragment>
                                                
                                            </div>
                                            : val.type === 'video' ?
                                                <div className="bookContFrame videoFrame" 
                                                    style={{justifyContent: `${val.textJustify}`, height: `${val.contentWidth / iframeHeight}vw`}}>
                                                        <p>프로그램이 종료되어 영상 시청이 제한됩니다</p>
                                                    
                                                </div>
                                                : val.type === 'emptySpace' ?
                                                    <div className="bookContFrame emptySpaceFrame" >
                                                        <div></div>
                                                        
                                                    </div>
                                                    : <span>none</span>}
                                            </div>
                                        )
                                    })
                                    }
                        </div>}
                    </div>}
                </div>
            </React.Fragment>}
        </div>
    );
};

export default PageCheckItem;
import React, {useState, useEffect} from 'react';
import {withFirebase} from '../../Firebase'
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import BootPay from "bootpay-js"
import dayjs from 'dayjs'

const Certificate = props => {
let {number, isCertificated, uid, onPropsChange, authUser} = props
	function onClickPayment(){
        BootPay.request({
            price: 0, // 0으로 해야 한다.
            application_id: process.env.REACT_APP_WEB_ID,
            name: '본인인증', //결제창에서 보여질 이름
            pg: 'danal',
            method: 'auth', // 빌링키를 받기 위한 결제 수단
            show_agree_window: 0, // 부트페이 정보 동의 창 보이기 여부
            order_id: uid, //고유 주문번호로, 생성하신 값을 보내주셔야 합니다.
            // params: {callback1: '그대로 콜백받을 변수 1', callback2: '그대로 콜백받을 변수 2', customvar1234: '변수명도 마음대로'},
            user_info: {
                phone: number
            },
            // extra: {
            //     carrier: '[ SKT, KT, LGT ] 고정할 통신사명',
            //     age_limit: '[ 제한할 최소 나이 ex) 20 -> 20살 이상만 인증이 가능 ]'
            // }
        }).error(function (data) {
            // 본인인증 진행시 에러가 발생하면 수행됩니다.
            // console.log(data);
        }).cancel(function (data) {
            // 본인인증이 취소되면 수행됩니다.
            // console.log(data);
        }).done(function (data) {

            alert('본인인증이 완료되었습니다.')
            props.firebase.user(uid).set({
               number : number,
               isCertificated : true,
               certificationInfo : data
            },{merge:true})
            .then(()=> {
                onPropsChange('isCertificated', true)
            })
            .then(()=> {
                onPropsChange('certificationInfo', data)
            })
            .then(()=>{
                authUser['isCertificated'] = true
                authUser['number'] = number
                authUser['certificationInfo'] = data
            localStorage.removeItem('authUser')
            localStorage.setItem('authUser', JSON.stringify(authUser))
            onPropsChange('authUser', authUser)
            // console.log(data);
            })
        });
		
	}	
    return (
      <span>
      {isCertificated ? <button disabled>인증완료</button>
        :  <button onClick={(onClickPayment)}>본인인증</button>}
      </span>
    );
}

export default compose(
    withRouter,
    withFirebase,
  )(Certificate);
import React, {useState, useEffect} from 'react';
import { compose } from 'recompose';
import { AuthUserContext, withAuthorization, withEmailVerification } from '../Session';
import OrderProgram from './OrderProgram/index'
import OrderSales from './OrderSales/index'
import OrderXrMaking from './OrderXrMaking'
import * as ROUTES from '../../constants/routes';
import './Order.css'
import './OrderResp.css'
import $ from "jquery";


const OrderPage = props => {
  useEffect(() => {
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo( 0,0);
    }
    let authUser= JSON.parse(localStorage.getItem('authUser'))
        let userRef = props.firebase.user(authUser.uid)
        userRef.get()
        .then(doc => {
            let res = doc.data()
                if(res.username===''){
                  props.history.push({pathname: `${ROUTES.USER}`})
                }
          });
    getClassList()
    getsalesList()
    // getvrEventList()

  }, [])
  const [one, onOne] = useState(props.location.state  !== undefined ? props.location.state.tab ==='program'? 'order_show' :' order_hidden': 'order_hidden');
  const [two, onTwo] = useState(props.location.state  !== undefined ? props.location.state.tab ==='sales'? 'order_show' :' order_hidden': 'order_show');
  const [three, onThree] = useState('order_hidden');
  const [show, onShow] = useState( props.location.state  !== undefined ? props.location.state.tab : '');

  const [classList, onclassList] = useState([]);
  const [salesList, onsalesList] = useState([]);
  const [vrEventList, onvrEventList] = useState({});
  // console.log(salesList)
  function First() {
    onShow('program')
    onOne('order_show')
    onTwo('order_hidden')
    onThree('order_hidden')
  }
  function Second() {
    onShow('sales')
    onOne('order_hidden')
    onTwo('order_show')
    onThree('order_hidden')
  }
  function Third() {
  onShow('xrMaking')
  onOne('order_hidden')
  onTwo('order_hidden')
  onThree('order_show')
  }
  function getClassList (){
    props.firebase.programs().orderBy('order').get()
    .then(result => {
        var programList = []
        result.forEach(res => {
            var listData = res.data()
            var pid = res.id
            programList.push({pid, ...listData})
        })
        onclassList(programList)
    })
}
function getsalesList (){
  props.firebase.sales().orderBy('order').get()
  .then(result => {
      var programList = []
      result.forEach(res => {
          var listData = res.data()
          var pid = res.id
          programList.push({pid, ...listData})
      })
      onsalesList(programList)
  })
}
function getvrEventList () {
  props.firebase.sale('vrEvent').get()
  .then(result => {
      var vrEventList = {}
      var listData = result.data()
      var pid = result.id
      vrEventList={pid, ...listData}
      onvrEventList(vrEventList)
  })
}
  return (
    <AuthUserContext.Consumer>
        {authUser => (<div className="orderFrame">
        {/* <h1>Order Page</h1> */}
        {show === '' ? 
              <div className="linkFrame">
                <div className="classLinkBx">
                  <div className="linkTextGroup">
                    <h5>Off-line Learning Program</h5>
                    <p>
                      오프라인에서
                      <br/>워크북/디지털북을 사용하여 
                      <br/>집합 교육으로 이루어지는 
                      <br/>XR Reflection Writing Program
                    </p>
                  </div>
                  <div className="linkBtn" onClick={()=>First()}>Off-line Learning 신청</div>
                </div>
                <div className="onlineLinkBx">
                  <div className="linkTextGroup">
                    <h5>On-line Learning Program</h5>
                    <p>
                      온라인에서
                      <br/>디지털북을 사용하여 
                      <br/>언제 어디서나 수강할 수 있는
                      <br/>XR Reflection Writing Program
                    </p>
                  </div>
                  <div className="linkBtn"onClick={()=>Second()}>On-line Learning 신청</div>
                </div>
              </div>
              :
          <div className="tabFrame">
            {/* 탭 부분 */}
            {/* 선택된 탭 클래스 네임 order_show, 미선택시 order_hidden입니다. */}
                  <ul className="tabMenuFrame">
                  <li className={`${one}`} onClick={()=>First()}>Off-line Learning 신청</li>
                    <li className={`${two}`} onClick={()=>Second()} >On-line Learning 신청</li>
                    {/* <li className={`${three}`} onClick={()=>Third()}>XR 제작 서비스 의뢰하기</li> */}
                  </ul>
            {/* 탭에서 선택된 내용 부분 */}
            <div className="contFrame">
              {show === 'program' ? <OrderProgram authUser={authUser} classList={classList} /> :
              show === 'sales' ? <OrderSales authUser={authUser} salesList={salesList} vrEventList={vrEventList}/> : 
              show === 'xrMaking' ? <OrderXrMaking authUser={authUser}/> : null}
            </div>
          </div>}
        </div>)}

    </AuthUserContext.Consumer>
  )};
  
  const condition = authUser =>
  authUser
  
  export default compose(
    withEmailVerification,
    withAuthorization(condition),
  )(OrderPage);
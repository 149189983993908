import React, { Component } from 'react';
import { withFirebase } from '../../../Firebase'
import {getTimeDayjs} from '../../../helpers'
import dayjs from 'dayjs'

class UserDetail extends Component {
    state = {
        uid:this.props.userInfo.uid,
        programInquiries:[],
        contactInquiries:[],
        salesInquiries:[],
        mybooks:[]
    }
    componentDidMount(){
        this.getProgramInquiries()
        this.getContactInquiries()
        this.getSalesInquiries()
        this.getMybooks()
    }
    getProgramInquiries = () => {
        let uid = this.props.userInfo.uid
        this.props.firebase.tlpPrograms().where('authUserId','==',uid).get()
        .then(result=>{
            let programInquiries = []
            result.forEach((doc) => {
                var pid = doc.id
                var listData= doc.data();
                programInquiries.push({pid, ...listData})
                })
                this.setState({programInquiries})
        })
          .catch(err=> alert(`잠시 후 다시 시도 해주세요.\n에러 : ${err}`) )
    }
    getContactInquiries = () => {
        let uid = this.props.userInfo.uid
        this.props.firebase.inquiries().where('authUserId','==',uid).get()
        .then(result=>{
            let contactInquiries = []
            result.forEach((doc) => {
                var pid = doc.id
                var listData= doc.data();
                contactInquiries.push({pid, ...listData})
                })
                this.setState({contactInquiries})
        })
          .catch(err=> alert(`잠시 후 다시 시도 해주세요.\n에러 : ${err}`) )
    }
    getSalesInquiries = () => {
        let uid = this.props.userInfo.uid
        this.props.firebase.tlpSales().where('authUserId','==',uid).get()
        .then(result=>{
            let salesInquiries = []
            result.forEach((doc) => {
                var pid = doc.id
                var listData= doc.data();
                salesInquiries.push({pid, ...listData})
                })
                this.setState({salesInquiries})
        })
          .catch(err=> alert(`잠시 후 다시 시도 해주세요.\n에러 : ${err}`) )
    }
    getMybooks = () => {
        let uid = this.props.userInfo.uid
        this.props.firebase.tlpSales().where('authUserId','==',uid).get()
        .then(result=>{
            let mybooks = []
            result.forEach((doc) => {
                var pid = doc.id
                var listData= doc.data();
                mybooks.push({pid, ...listData})
                })
                this.setState({mybooks})
        })
          .catch(err=> alert(`잠시 후 다시 시도 해주세요.\n에러 : ${err}`) )
    }
    render() {
        let {programInquiries, contactInquiries, salesInquiries, mybooks} = this.state
        let {username, number, email, createdAt, status, withdrawAt} = this.props.userInfo
        let isCreatedAt = getTimeDayjs(createdAt)
        let isWithdrawAt = getTimeDayjs(withdrawAt)
        return (
            <tr>
                <td>{username}</td>
                <td>{number || '미입력'}</td>
                <td>{email}</td>
                <td>{programInquiries.length}</td>
                <td>{salesInquiries.length}</td>
                <td>{mybooks.length}</td>
                <td>{contactInquiries.length}</td>
                <td>{isCreatedAt}</td>
                <td>{status === 'withdraw' ? isWithdrawAt : ''}</td>
            </tr>
        );
    }
}

export default withFirebase(UserDetail);
import React, { Component } from 'react'
import {withFirebase} from '../../../Firebase'
import dayjs from 'dayjs'
import {getPeriod, getTimeDayjs, getTimeString, getTimeDayjsOrigin} from '../../../helpers'
import DayPicker, { DateUtils } from 'react-day-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import 'react-day-picker/lib/style.css';
import SendSMSModal from '../../SendSMSModal'



class PaidRentList extends Component {
    constructor(props) {
        super(props);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleDayMouseEnter = this.handleDayMouseEnter.bind(this);
        this.handleResetClick = this.handleResetClick.bind(this);
        this.handleDayChange = this.handleDayChange.bind(this);
        this.state = this.getInitialState();
      }
      getInitialState() {
        return {
        from: null,
        to: null,
        enteredTo: null, // Keep track of the last day for mouseEnter.
        selectedDay: undefined,
        vrRentpaidList : this.props.vrRentpaidList,
        userInfo:{},
        modalOpen : false,
        modalText : '',
        vrRentStart : this.props.vrRentpaidList.vrRentStart === undefined ? '' : this.props.vrRentpaidList.vrRentStart,
        vrRentFinish: this.props.vrRentpaidList.vrRentFinish === undefined ? '' : this.props.vrRentpaidList.vrRentFinish,
        vrRentReturn : this.props.vrRentpaidList.vrRentReturn === undefined ? '' : this.props.vrRentpaidList.vrRentReturn,
        vrRentStatus : this.props.vrRentpaidList.vrRentStatus === undefined ? '' : this.props.vrRentpaidList.vrRentStatus,
        vrRentBetween : this.props.vrRentpaidList.vrRentBetween === undefined ? 0 : this.props.vrRentpaidList.vrRentBetween,
        periodModalOpen:false,
        number : '',
        isModalOpen : false,
        receiver: '',
        authBookid:''
        };
      }
    componentDidMount(){
        this.props.firebase.user(this.props.vrRentpaidList.codeUser).get()
        .then(res=>{
            let uid = res.id
            let data = res.data()
            this.setState({
                userInfo : {...data, uid}
            })
        })
    }
    componentDidUpdate(prevProp, prevState){
        if(prevState.from !== this.state.from){
            let vrRentStart = dayjs(this.state.from).format('YYYY-MM-DD')
            this.setState({vrRentStart})
        }
        if(prevState.to !== this.state.to){
            let vrRentFinish = dayjs(this.state.to).format('YYYY-MM-DD')
            let vrRentBetween = getPeriod(this.state.vrRentStart, vrRentFinish)
            this.setState({vrRentFinish, vrRentBetween })
        }
        if(prevState.selectedDay !== this.state.selectedDay){
            let vrRentReturn = dayjs(this.state.selectedDay).format('YYYY-MM-DD')
            this.setState({vrRentReturn })
        }
    }
    handleDayChange(day) {
        this.setState({ selectedDay: day });
      }
      isSelectingFirstDay(from, to, day) {
        const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
        const isRangeSelected = from && to;
        return !from || isBeforeFirstDay || isRangeSelected;
      }
      handleResetClick() {
        this.setState({
            from: null,
            to: null,
            enteredTo: null
        });
      }
      handleDayClick(day) {
        const { from, to } = this.state;
        if (from && to && day >= from && day <= to) {
          this.handleResetClick();
          return;
        }
        if (this.isSelectingFirstDay(from, to, day)) {
          this.setState({
            from: day,
            to: null,
            enteredTo: null,
          });
        } else {
          this.setState({
            to: day,
            enteredTo: day,
            periodModalOpen : false
          });
        }
      }
    
      handleDayMouseEnter(day) {
        const { from, to } = this.state;
        
        if (!this.isSelectingFirstDay(from, to, day)) {
            let vrRentStart = dayjs(from).format('YYYY-MM-DD')
            let vrRentEnter = dayjs(day).format('YYYY-MM-DD')
            let vrRentBetween = getPeriod(vrRentStart, vrRentEnter) 
          this.setState({
            enteredTo: day,
            vrRentBetween
          });
        }
      }
    onSubmit = (vrRentpaidId) => {
        let {vrRentStart, vrRentFinish, vrRentBetween, vrRentReturn, vrRentStatus} = this.state
        const data = {vrRentStart, vrRentFinish, vrRentBetween, vrRentReturn, vrRentStatus}
        this.props.firebase.mybook(vrRentpaidId).set(data,{merge:true})
        .then(()=> alert('적용되었습니다.'))
        .catch(err=> alert(`잠시 후 다시 시도해 주세요\n`+err))
    }
    onPropsChange = (type, number, receiver, vrRentpaidId) => {
        
        if(type && number !== undefined){
            let stringNumber = number.split('-').join("")
            this.setState({
                number : stringNumber,
                isModalOpen : true,
                receiver: receiver,
                vrRentpaidId
        })
        // console.log(type, stringNumber, receiver)
        } else if(!type && number !== undefined) {
            this.setState({
                number : '',
                isModalOpen : false,
                receiver: '',
                vrRentpaidId:''
        })
    } else if( number === undefined) {
        alert('해당 유저가 번호를 입력하지 않았거나 오류가 발생했습니다.\n잠시 후 다시 시도해주세요.\n오류가 계속되면 개발자에게 문의하세요.')
        this.setState({
            number : '',
            isModalOpen : false,
            receiver: '',
            vrRentpaidId:''
    })
    }
    }
    render() {
        let {vrRentpaidList} = this.props
        let {userInfo, modalOpen, modalText, vrRentStart, vrRentFinish, periodModalOpen,
            from, to, enteredTo, vrRentBetween, vrRentReturn, vrRentStatus,
             isModalOpen, number, receiver} = this.state
        let dateRentReturn = vrRentReturn ? vrRentReturn : ''
        const modifiers = { start: from, end: enteredTo };
        const disabledDays = { before: this.state.from };
        const selectedDays = [from, { from, to: enteredTo }]
        let { userAddress, rName, vrRentpaidId } = vrRentpaidList
        return (
            <React.Fragment>
              <tbody>
                <tr className="salesListDetailCont">
                  <td>{userInfo.username}</td>
                  <td>{userInfo.number}</td>
                  <td>{userInfo.email}</td>
                  <td><button onClick={()=>this.setState({ modalOpen : true, modalText : userAddress})}>확인</button></td>
                  <td>
                    {/* {vrRentFinish === '' ? '체험 기간 미설정' :  */}
                    <span onClick={()=>this.setState({ periodModalOpen : true})}>
                      <span>{vrRentStart} - {vrRentFinish}</span>
                      <span style={vrRentBetween>9 ?{color:'red'}:{}}>({vrRentBetween+1}일)</span>
                    </span>
                     {/* } */}
                  </td>
                  <td >
                    {/* <DayPickerInput onDayChange={this.handleDayChange}/> */}
                    {/* {vrRentReturn==='' ?  */}
                    <DayPickerInput onDayChange={this.handleDayChange} placeholder={vrRentReturn}/>
                    {/*   : vrRentReturn } */}
                  </td>
                  <td>
                    <select value={vrRentStatus} onChange={(e)=>this.setState({vrRentStatus:e.target.value})}>
                        <option value='ready'>배송 준비 중</option>
                        <option value='shipment'>체험 진행 중</option>
                        <option value='finish'>반납 완료</option>
                        <option value='finishYet'>반납 연체 중</option>
                        <option value='finishLate'>연체 반납</option>
                    </select>
                  </td>
                  <td><button onClick={()=>this.onSubmit(vrRentpaidId)}>적용</button></td>
                  <td><button onClick={()=>this.onPropsChange(true, userInfo.number, `${userInfo.username}${userInfo.username !==rName ? `(${rName})` : null}`, vrRentpaidList.vrRentpaidId)}>문자전송</button></td>
                </tr>
              </tbody>
            
              {modalOpen && 
              <div className="salesDetailPopBg">
                <div className="salesDetailPopFrame">
                  <div className="salesDetailPopTitle">
                    <h5>배송지 확인</h5>
                    <div onClick={()=>this.setState({modalOpen:false, modalText:''})}>×</div>
                  </div> 
                  <p>
                    <span>{userInfo.username}</span>
                    <span>{modalText}</span>
                  </p>
                </div>
              </div>}

              {periodModalOpen && 
              <div className="salesDetailPopBg">
                <div className="salesDetailPopFrame">
                  <div className="salesDetailPopTitle">
                    <h5>체험 기간 설정</h5>
                    <div onClick={()=>this.setState({periodModalOpen:false})}>×</div>
                  </div>
                  <p>{vrRentBetween+1}일</p>
                  <DayPicker
                        className="Range"
                        numberOfMonths={2}
                        fromMonth={from}
                        selectedDays={selectedDays}
                        disabledDays={disabledDays}
                        modifiers={modifiers}
                        onDayClick={this.handleDayClick}
                        onDayMouseEnter={this.handleDayMouseEnter}
                      />
                </div>
              </div>}
                {isModalOpen && <SendSMSModal number = {number} onUpdate = {this.onPropsChange} receiver={receiver} mybookId={vrRentpaidId}/>}
            </React.Fragment>
        )
    }
}
export default withFirebase(PaidRentList)


import React, { Component } from "react";
import { withFirebase } from "../../../../Firebase";
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import { getTimeDotString, getTimeString, isEmail, getTimeDayjs } from "../../../../helpers";
import CreatLink from '../../CreatLink'

class ProgramConsulting extends Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
  }

  state = {
    excelUrl: `https://firebasestorage.googleapis.com/v0/b/xr-book.appspot.com/o/tlp%2Ffile%2F200706202818_%EB%94%94%EC%A7%80%ED%84%B8%EB%B6%81%EC%9D%B4%EC%9A%A9%EC%9E%90%EB%93%B1%EB%A1%9D%ED%8F%BC.xlsx?alt=media&token=45d07b52-d6fa-4931-aa10-84aaef94ef1b`,
    selectedDays: this.props.consultingList.selectedDays,
    consultingList: this.props.consultingList,
    detailShow: "none",
    code: this.props.consultingList.code || "-",
    isEnroll: false,
    readerName: "",
    readerEmail: "",
    isEmailValid: "",
    readerList: [],
    showList: [],
    order:'등록순',
    linkModal:false
  };
  // componentDidMount(){
  //     if(this.state.consultingList !==[]){
  //     const {uid, programInfo}= this.state.consultingList
  //     this.getReaderList(uid, programInfo)
  // }
  // }
  handleDayClick(day, { selected }) {
    const { selectedDays, consultingList } = this.state;
    const list = consultingList;
    if (selected) {
      const selectedIndex = selectedDays.findIndex((selectedDay) =>
        DateUtils.isSameDay(selectedDay, day)
      );
      selectedDays.splice(selectedIndex, 1);
    } else {
      selectedDays.push(day);
    }
    list["selectedDays"] = selectedDays;
    this.setState({ selectedDays, consultingList: list });
    // console.log(selectedDays)
  }

  onListStatechange = (e, list) => {
    const targetName = e.target.name;
    // console.log(targetName)
    const targetValue = e.target.value;
    if (targetName === "memo") {
      list[targetName] = targetValue.replace(/(?:\r\n|\r|\n)/g, "<br />");
    } else if (targetName === "price") {
      list[targetName] = targetValue.replace(/[^0-9]/g, "");
    } else if( targetName === "digitalBookID"){
      if(targetValue === ''){
        list['book'] = '워크북'
        list[targetName] = targetValue;
      } else {
        list['book'] = '디지털북'
        list[targetName] = targetValue;
      }
    }else {
      list[targetName] = targetValue;
    }
    this.setState({
      consultingList: list,
    });
  };
  onStateChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  editProgramInfo = (uid) => {
    const { consultingList } = this.state;
    const { oneditList } = this.props;
    oneditList(uid, consultingList, "applied");
  };
  onToggle = () => {
    const { detailShow } = this.state;
    if (detailShow === "none") {
      this.setState({
        detailShow: "show",
      });
    } else if (detailShow === "show") {
      this.setState({
        detailShow: "none",
      });
    }
  };
  onStateToggle = (name, value) => {
    const { uid, programInfo } = this.state.consultingList;
    this.setState({
      [name]: value,
    });
    if (name === "isEnroll" && value === true) {
      this.getReaderList(uid, programInfo);
    }
  };
  getCode = async () => {
    const { consultingList } = this.state;
    this.setState({ code: "코드 재 발급 중." });
    const list = consultingList;
    const data = {
      userId: consultingList["authUserId"],
      createdAt: consultingList["createdAt"],
      page: consultingList["page"],
    };
    // console.log(consultingList)
    await fetch("/api/v1/createCode", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    })
      .then((response) => {
        response
          .json()
          .then((res) => {
            const code = res["code"];
            const codeAt = res["codeAt"];
            list["code"] = code;
            list["codeAt"] = codeAt;
            this.setState({ code, consultingList: list });
          })
          .catch((err) => console.log(err));
      })
      .catch((err) => console.log(err));
  };

  handleEmailInput = (e) => {
    // e.preventDefault()
    let value = e.target.value;
    if (isEmail(value)) {
      this.setState({
        readerEmail: value,
        isEmailValid: true,
      });
    } else {
      this.setState({
        readerEmail: value,
        isEmailValid: false,
      });
    }
  };
  putReader = (uid, programInfo) => {
    const { readerName, readerEmail, readerList } = this.state;
    let programId = programInfo.split("_")[0];
    let tlpProgramId = uid;
    let data = {
      createdAt : getTimeString(),
      name: readerName.trim(),
      email: readerEmail.trim(),
      programId: programId,
      tlpProgramId: tlpProgramId,
    };
    this.props.firebase
      .nonAuthReaders()
      .add(data)
      .then(() => {
        alert("등록되었습니다.");
        let readerTemp = readerList;
        readerTemp.unshift(data);
        this.setState({
          readerName: "",
          readerEmail: "",
          isEmailValid: false,
          readerList: readerTemp,
          showList:readerTemp,
          order:'등록순'
        });
        //리스트 추가
      })
      .catch((err) => alert(`잠시 후 다시 시도해주세요.\n에러코드 : ${err}`));
  };
  deleteReader = (data, idx) => {
    const { rid, name, email } = data;
    let result = window.confirm(`(${idx+1}. ${name}/${email})을 삭제하시겠습니까?`)
    const { readerList } = this.state;
    if(result){
    this.props.firebase
      .nonAuthReader(rid)
      .delete()
      .then(() => {
        let readerTemp = readerList;
        let index = readerTemp.indexOf(data);
        readerTemp.splice(index, 1);
        this.setState({ 
          readerList: readerTemp,
          showList:readerTemp,
          order:'등록순'
         });
        alert("삭제되었습니다.");
      })
      .catch((err) => alert(`잠시 후 다시 시도해주세요.\n에러코드 : ${err}`));}
  };
  deleteAllReader = (uid, programInfo) => {
    let programId = programInfo.split("_")[0];
    let tlpProgramId = uid;
    let result = window.confirm(`등록된 디지털북 이용자 전체를 삭제하시겠습니까?`)
    if(result){
    let batch = this.props.firebase.db.batch();
    let docRef = this.props.firebase
      .nonAuthReaders()
      .where("tlpProgramId", "==", tlpProgramId);

    docRef
      .get()
      .then((snapshot) => {
        snapshot.docs.forEach((doc) => {
          batch.delete(doc.ref);
        });
        return batch.commit();
      })
      .then(() =>
        this.props.firebase
          .tlpProgram(uid)
          .update({
            filename: this.props.firebase.fieldValue.delete(),
          })
          .then(() => {
            this.setState({ 
              readerList: [],
              showList:[],
              order:'등록순' });
            alert("삭제되었습니다.");
          })
          .catch((err) =>
            alert(`잠시 후 다시 시도해주세요.\n에러코드 : ${err}`)
          )
      )
      .catch((err) => alert(`잠시 후 다시 시도해주세요.\n에러코드 : ${err}`));
    }
  };
  getReaderList = (uid, programInfo) => {
    let programId = programInfo.split("_")[0];
    let tlpProgramId = uid;
    let readerList = [];
    this.props.firebase
      .nonAuthReaders()
      .where("tlpProgramId", "==", tlpProgramId)
      .where("programId", "==", programId)
      .orderBy('createdAt', 'desc')
      .get()
      .then((res) => {
        res.forEach((doc) => {
          var rid = doc.id;
          var listData = doc.data();
          readerList.push({ ...listData, rid });
        });
        this.setState({ readerList, showList: readerList, order:'등록순' });
      })
      .catch((err) => alert(`잠시 후 다시 시도해주세요.\n에러코드 : ${err}`));
  };
 
  render() {
    // console.log(this.state.readerList)
    const {
      excelUrl, consultingList, detailShow, selectedDays, code, isEnroll,
       readerName, readerEmail, isEmailValid, readerList, order, linkModal, showList} = this.state;
    let { digitalbookList } = this.props;
    const {
      title, programInfo, progressAt, memo, contents, manager, groupTitle, participants, progress, email,
       number, uid, target, price, location, filename, vrRent, tabletRent, book, type, digitalBookID} = consultingList;
    const programTitle = `${title} : ${target}대상`;
    // console.log(readerList)
    const isProgressAt = getTimeDayjs(progressAt)
    const enteredMemo = memo.replace(/<br\s*[\/]?>/gi, `\n`);
    const enteredContents = contents.replace(/<br\s*[\/]?>/gi, `\n`);
    const isPrice = price.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
    const days = selectedDays;
    const dayLeng = days.length;
    const vrRental = vrRent ? "오큘러스 고" : "";
    const tabletRental = tabletRent ? (vrRent ? " / 태블릿" : "태블릿") : "";
    const rental = vrRental === "" && tabletRental === "" ? "안함" : "";
    const isDigital = book === "디지털북" ? true : false;
    return (
      <div className="programListFrame">
        <ul className="programListCont">
          <li>{manager}</li>
          <li>{groupTitle}</li>
          <li>{participants}</li>
          <li>{location}</li>
          <li>{programTitle}</li>
          <li>
            <select
              name="progress"
              value={progress}
              onChange={(e) => this.onListStatechange(e, consultingList)}
            >
              <option value="consulting">담당자 배정</option>
              {price !== "" && (
                <React.Fragment>
                  <option value="request">결제요청</option>
                  <option value="paid">결제완료</option>
                </React.Fragment>
              )}
              <option value="finished">교육 종료</option>
              <option value="canceled">취소</option>
            </select>
          </li>
          <li>
            {isProgressAt}
          </li>
          <li>
            <div onClick={this.onToggle}>
              {detailShow === "none"
                ? `상세보기`
                : detailShow === "show"
                ? `상세닫기`
                : `상세정보`}
            </div>
            <button onClick={() => this.editProgramInfo(uid)}>적용</button>
          </li>
        </ul>

        <ul className={`${detailShow} detailBox`}>
          <li>
            <span>연락처</span>
            <span>{email}</span>
            <span>{number}</span>
          </li>
          <li>
            <span>신청 내용</span>
            <span>교재({book})</span>
            <span>
              기기대여({vrRental} {tabletRental} {rental} )
            </span>
            <span>요청사항({type})</span>
          </li>
          <li className="ReguireText">
          {contents &&
              contents.split("<br />").map((c, id) => {
                return (
                  <span key={id}>
                    {c}
                  </span>
                );
              })}
          </li>
          <li className="manegeText">
            <span>담당자/상세내용</span>
            <textarea
              name="memo"
              value={enteredMemo}
              onChange={(e) => this.onListStatechange(e, consultingList)}
            />
          </li>
          <li>
            <span>가격</span>
            <input
              type="text"
              name="price"
              value={isPrice}
              onChange={(e) => this.onListStatechange(e, consultingList)}
            />
            {price !=='' && price>=1000 ?
            <span className="corporateCardLink" onClick={()=>this.setState({linkModal:true})}>법인카드 결제 링크</span>
          : <span className="corporateCardLink" style={{color:'#dedede'}}>법인카드 결제 링크</span>}         
             {  linkModal&&
              <React.Fragment>
                <div className="corporateCardBg">
                  <div className="corporateCardFrame">
                    <div className="corporateCardTitle">
                      <h5>법인카드 결제 링크 생성</h5>
                      <div onClick={()=>this.setState({linkModal:false})}>×</div>
                    </div>
                    <CreatLink infoList = {consultingList}/>
                  </div>
                </div>
              </React.Fragment>
               }
          </li>
          <li className="periodSelect">
            <span>기간</span>
            <DayPicker selectedDays={days} onDayClick={this.handleDayClick} />
          </li>
          <li>
            <span>총 기간</span>
            <span>{dayLeng || 0} 일</span>
          </li>
          <li></li>
          <li className="warning">
            <span></span>
            <span>※ 코드 생성/디지털북 이용자 등록은 사용자 결제가 완료된 후 진행하십시오.</span>
            </li>
          <li className="userInfoUpload">
            <span>디지털북<br/>이용 정보</span>
            <div className="userInfoUloadContFrame">
              <ul className="userInfoBtnGroup">
              <li className="xrbookSelect">
                  <select
                    name="digitalBookID"
                    value={book === '워크북' ? '' : digitalBookID}
                    onChange={(e) => this.onListStatechange(e, consultingList)}>
                    <option value="">디지털북 이용 안함</option>
                    {digitalbookList.length > 0 &&
                      digitalbookList.map((c) => {
                        // console.log(c)
                        return (
                          <option key={c.bid} value={c.bid}>
                            {c.bookTitle === "" ? `이름 없음` : c.bookTitle}
                          </option>
                        );
                      })}
                  </select>
              </li>
                {book === '디지털북' &&
                <li>
                  <span
                    className="infoUploadBtn"
                    onClick={() => this.onStateToggle("isEnroll", !isEnroll)}
                  >
                    디지털 북 이용자 관리
                    {/* {`: ${filename || ""}`} */}
                  </span>
                  <span className="fileDownloadBtn">
                    <a href={excelUrl} target="_blank">
                      디지털북 등록 전용 excel파일 다운
                    </a>
                  </span>
                  {readerList.length > 0 && (
                    <span
                      className="fileDeleteBtn"
                      onClick={() => this.deleteAllReader(uid, programInfo)}
                    >
                      리스트 전체 삭제
                    </span>
                  )}
                </li>}
              </ul>

              {isEnroll ? (
                <div className="userInfoContGroup">
                  
                  {isDigital && (
                    <div className=" dBookUserInfoGroup">
                      {/* {!filename && ( */}
                        <Enrollment
                          onStateToggle={this.onStateToggle}
                          fieldname={`${programInfo}_${uid}`}
                        />
                      {/* )} */}
                      <div>
                        <input
                          type="text"
                          name="readerName"
                          value={readerName}
                          placeholder="* 이름"
                          onChange={this.onStateChange}
                        />
                        <input
                          type="text"
                          name="readerEmail"
                          value={readerEmail}
                          placeholder="* 메일주소"
                          onChange={this.handleEmailInput}
                        />
                        <span onClick={() => readerName!=='' && readerEmail !== "" && isEmailValid ? this.putReader(uid, programInfo) : alert('올바른 정보를 입력하세요.')}>
                          개별 등록
                        </span>
                        {readerEmail !== "" && isEmailValid ? (
                          <p>O</p>
                        ) : readerEmail !== "" && !isEmailValid ? (
                          <p>유효한 메일 주소가 아닙니다.</p>
                        ) : null}
                      </div>
                      <div>* 동일한 이름/메일을 중복으로 등록하는 경우 등록순으로 하나의 이름/메일만 사용 가능합니다.</div>
                      <div>
                        {readerList.length > 0 && (
                          <table border="1">
                            <thead>
                              <tr>
                                <th><span style={{color : '#0075ff' , cursor:'pointer'}}
                                onClick={()=>this.setState({showList : readerList.sort((a,b)=>a['createdAt']-b['createdAt']), order:'등록순'})}>순번</span></th>
                                <th><span style={{color : '#0075ff' , cursor:'pointer'}}
                                onClick={()=>this.setState({showList : readerList.sort((a,b)=>a['name']<b['name'] ? -1 : a['name']>b['name'] ? 1 : 0), order:'이름순'})}>이름</span></th>
                                <th><span style={{color : '#0075ff' , cursor:'pointer'}}
                                onClick={()=>this.setState({showList : readerList.sort((a,b)=>a['email']<b['email'] ? -1 : a['email']>b['email'] ? 1 : 0), order:'메일순'})}>이메일</span></th>
                                <th>
                                <span style={{color : '#0075ff' , cursor:'pointer'}}
                                onClick={()=>this.setState({showList : readerList.sort((a,b)=>a['createdAt']-b['createdAt']), order:'등록순'})}>진행 여부</span><strong>{` ( `}</strong>
                                <span style={{color : '#0075ff' , cursor:'pointer'}}
                                onClick={()=>this.setState({showList : readerList.filter(c => c.bookData !==undefined && c.isFinished), order:'완료'})}>완료</span><strong> / </strong>
                                <span style={{color : '#0075ff' , cursor:'pointer'}}
                                onClick={()=>this.setState({showList : readerList.filter(c => c.bookData !==undefined && !c.isFinished), order:'미완료'})}>미완료</span><strong> / </strong>
                                <span style={{color : '#0075ff' , cursor:'pointer'}}
                                onClick={()=>this.setState({showList : readerList.filter(c => c.bookData ===undefined), order:'미실행'})}>미실행</span><strong>{` ) `}</strong></th>
                                <th>삭제</th>
                              </tr>
                            </thead>
                            <tbody>
                              {showList.map((c, id) => {
                                return (
                                  <tr key={id}>
                                    <td>{id + 1}</td>
                                    <td>{c.name}</td>
                                    <td>{c.email}</td>
                                    <td>{c.bookData ===undefined ? '미실행' : c.isFinished ? '완료' : '미완료'}</td>
                                    <td>
                                      <span onClick={() => this.deleteReader(c, id)}>
                                        ×
                                      </span>
                                    </td>
                                  </tr>
                                );
                              })}
                            </tbody>
                          </table>
                        )}
                        {showList.length > 0 &&<div>({order})</div>}
                        <div className="refreshExcelBtn" onClick={() => this.getReaderList(uid, programInfo)}>
                          새로고침
                        </div>
                      </div>
                    </div>
                  )}
                  
                </div>
              ) : null}
            </div>
          </li>
          <li className="warning">
            <span></span>
            <span>※ 오큘러스 고, AR app 이용 시 ID : 회사이름  / PW : 코드 </span>
          </li>
          <li>
            <span>코드</span>
            <span>{code}</span>
            <button
              className="createCodeBtn"
              onClick={this.getCode}
              disabled={!selectedDays.length}
            >
              코드 생성
            </button>
          </li>
          <li>
            <span></span>
            <button onClick={() => this.editProgramInfo(uid)}> 변경사항 적용하기</button>
          </li>
        </ul>
      </div>
    );
  }
}

export default withFirebase(ProgramConsulting);

const Enrollment = (props) => {
  const { onStateToggle, fieldname } = props;
  return (
    <form
      action="/api/v1/convert"
      method="post"
      encType="multipart/form-data"
      target="fileUpload"
    >
      <div className="excelUploadGroup">
        <input type="file" name={`${fieldname}`} id="filexlsx" />
        <button type="submit">저장</button> (5~7초 소요)
        <div onClick={() => onStateToggle("isEnroll", false)}>내용 접기</div>
      </div>
      <iframe
        name="fileUpload"
        title="fileUpload"
        //호스팅 후 수정
        src="https://xr-book.firebaseapp.com/target"
      ></iframe>

    </form>
  );
};

import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import * as ROUTES from '../../constants/routes';
import { withFirebase } from '../Firebase';
import { getTimeString } from '../helpers'
import './ImagePopup.css'
import $ from 'jquery'

class ImagePopup extends Component {
    state={
        eventList:[],
        loading : this.props.loading
    }
    componentDidMount(){
        this._ismounted = true;
        if(this._ismounted){
        
        // this.getCookieMobile()
    }   
    }
    componentWillUnmount() {
        this._ismounted = false;
     }
     componentDidUpdate(prevProps, prevState){
         if(prevState.eventList !== this.state.eventList && this.state.eventList.length>0){
            $('html, body').css("overflow", "hidden");
        }
        if(prevProps.loading !== this.props.loading && this.props.loading=== false){
            this.getCookieMobile()
            // console.log(this.props.loading)
        }
     }
    getpopup = () => {
        const Today = parseInt(getTimeString()/1000000)
        const {onUpdate} = this.props
        this.props.firebase.popups().where('endDate','>',Today-1).get()
        .then((result) => {
          var eventList = [];
          result.forEach((doc) => {
              var childData = doc.data();
              if(childData.startDate-Today<1){
              eventList.push(childData)
            } 
          })
          this.setState({eventList})
        //  this.setState({})
      })
      .catch(function(err) {
          console.log(err)
      });
      }
    hidePopup = () => {
        $('.mainImagePopup').hide()
        $('html, body').css("overflow", "inherit")
    }
    setCookieMobile =( name, value, expiredays ) =>{
        var todayDate = new Date();
        todayDate.setDate( todayDate.getDate() + expiredays );
        document.cookie = name + "=" + escape( value ) + "; path=/; expires=" + todayDate.toGMTString() + ";"
        this.hidePopup()
        
    }
    getCookieMobile = () => {
        var cookiedata = document.cookie;
        let cValue = ''
        if ( cookiedata.indexOf("XRBOOKpopupCookie=done") < 0 ){
            this.getpopup()
            cValue = cookiedata

        }
        return unescape(cValue);
    }
    render() {
        const {eventList} = this.state
        return (
            <>
            {eventList.length >0 &&
                <div className="mainImagePopup" key={eventList[0].createdAt}>
                    <div className="mainImagePopupContents ">
                         {/* <Link > */}
                         <div><div className="CloseBtn" onClick={()=>this.hidePopup()}>X</div></div> 
                         <img src={eventList[0].imgUrl} alt="eventImg" />
                        {/* <img src={eventList[0].mobileImgUrl} alt="eventImg" /> */}
                          
                        {/* </Link> */}
                        <div><div className="todayCloseBtn" onClick={()=>this.setCookieMobile('XRBOOKpopupCookie', 'done', 1 )}>오늘 하루 보지 않기</div></div>  
                    </div>               
                   
                    <div>
                    {/* <input type="button" value="쿠키 보기" onClick={()=>alert(this.getCookieMobile('test'))}/>
                    <input type="button" value="쿠키 삭제" onClick={()=>this.setCookieMobile('test', '', -1)}/> */}
                    </div>
                </div>
            }       
        </>
           
        )
    }
}
export default withFirebase(ImagePopup)

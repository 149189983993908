import React, {Component} from 'react';
import {withFirebase} from '../../Firebase'
import { withRouter, Link } from 'react-router-dom';
import { compose } from 'recompose';
import {isEmail, getTimeString, sideTrim, phoneFormatter} from '../../helpers';
import * as ROUTES from '../../../constants/routes'
// import RequestPay from '../../Iamport/requestPay'
import RequestPay from '../bootPay/requestPay'
import ImageUploadNR from '../../Utils/ImageUploadNR'
// import Certificate from '../bootPay/Certificate'
import CertificateI from '../../Iamport/Certificate'
import VrRent from '../../Guide/VrRent'
import $ from 'jquery'

class PaymentPage extends Component {
    state = {
        data : this.props.data,
        authUser: this.props.data.authUser,
        number: this.props.data.authUser.number || '',
        isCertificated: this.props.data.authUser.isCertificated || false,
        agree : false,
        itemList :this.props.data.itemList || [], 

        method: '',
        isCoupon: false,
        coupon: '',
        couponID:'',
        couponInfo:[],
        couponBefore:[],
        couponAvaliable:[],
        discountPrice: 0,

        anyVrRent:this.props.data.anyVrRent || false,
        anyVrEvent:this.props.data.anyVrEvent || false,
        imgUrl1:'',
        imgUrl2:'',
        
        certificationInfo: this.props.data.authUser.certificationInfo || {},
        
        isName:[],
        isNumber:[],
        isEmail:[],
        isItemListValid : false,

        rentalPolicy:false
    }
    componentDidMount(){
        // document.addEventListener('DOMContentLoaded', function() {
        //     BootPay.startTrace({
        //       items: [
        //         {
        //           item_name: '판매하는 상품1', // 판매하는 상품의 이름
        //           item_img: 'https://image.com/image1.png', // 판매하는 상품의 이미지 경로
        //           unique: 'item_unique_1', // 판매하는 상품의 고유 KEY
        //           price: '10000', // 판매하는 상품의 가격
        //           cat1: '상품 카테고리 1', // 상품 카테고리 1
        //           cat2: '상품 카테고리 2', // 상품 카테고리 2
        //           cat3: '상품 카테고리 3' // 상품 카테고리 3
        //         },
        //         {
        //           item_name: '판매하는 상품2', // 판매하는 상품의 이름
        //           item_img: 'https://image.com/image2.png', // 판매하는 상품의 이미지 경로
        //           unique: 'item_unique_2', // 판매하는 상품의 고유 KEY
        //           price: '10000', // 판매하는 상품의 가격
        //           cat1: '상품 카테고리 1', // 상품 카테고리 1
        //           cat2: '상품 카테고리 2', // 상품 카테고리 2
        //           cat3: '상품 카테고리 3' // 상품 카테고리 3
        //         }
        //       ]
        //     });
        //   });
          
    }
    componentDidUpdate(prevProps, prevState){
        let {number, agree, anyVrRent, imgUrl1, imgUrl2, couponInfo, itemList, isCertificated } = this.state
        if(prevState.itemList !== this.state.itemList
            ||prevState.agree !== this.state.agree
            ||prevState.isCertificated !== this.state.isCertificated
            ||prevState.imgUrl1 !== this.state.imgUrl1
            ||prevState.imgUrl2 !== this.state.imgUrl2){
            const isAllTrue = (currentValue) => currentValue ===true ;
            let isRent = anyVrRent === true ? imgUrl1!=='' && imgUrl2 !=='' ? true : false : true
            let isNumber = []
            let isName = []
            let isEmail = []
            for(let i in itemList){
                itemList[i].rNumber !== undefined ? 
                        (itemList[i].rNumber).length <9 ? isNumber.push(false) :isNumber.push(true)
                        :isNumber.push(false)
                itemList[i].rName !==undefined ?
                itemList[i].rName !=='' ? isName.push(true) : isName.push(false) : isName.push(false)
                isEmail.push(itemList[i].isEmailValid)
            // console.log(isName, isNumber, isEmail,)
                this.setState({
                    isName, isNumber, isEmail, isItemListValid:false
                })
            }
            if(!isCertificated || number.length < 9 || !isRent || isName === [] || !isName.every(isAllTrue)
             ||isNumber === [] || !isNumber.every(isAllTrue) || isEmail === [] || !isEmail.every(isAllTrue)
             ||!agree){
                this.setState({
                    isItemListValid : false
                })
            } else {
                this.setState({ isItemListValid : true})
            }
        }
        if(this.props.data.authUser.number !== this.state.number && this.state.isCertificated !==false){
            this.setState({isCertificated:false})
        }
        if(this.props.data.authUser.number === this.state.number
            && prevState.number !== this.state.number
            && this.state.isCertificated ===false){
            this.setState({isCertificated:true})
        }
        if(prevProps.data !== this.props.data){
            this.setState({
                data:this.props.data,
                itemList :this.props.data.itemList || [],
            })
        }
        if(prevState.couponBefore !== this.state.couponBefore){
            this.couponTest()
        }
        if(prevState.couponInfo !== this.state.couponInfo && this.state.couponInfo !== []){
            this.useLimitCoupon()
            this.onSubmit()
        }
    }
    onInvalid = () => {
        let {anyVrRent, imgUrl1, imgUrl2, itemList, number, agree, isCertificated, isName, isNumber, isEmail, couponBefore, couponInfo} = this.state
        const isAllTrue = (currentValue) => currentValue ===true ;
        let isRent = anyVrRent === true ? imgUrl1!=='' && imgUrl2 !=='' ? true : false : true
        
        if(!isCertificated) {
            alert('본인인증이 되지 않았습니다.')
        } else 
        if (number.length < 9){
            alert('신청자 연락처가 올바르지 않습니다.')
        } else if(!isRent){
            alert('기기대여 관련 파일이 업로드 되지 않았습니다.')
        } else if (couponBefore.length>0 && couponInfo.length!==couponBefore.length ){
            alert('쿠폰이 모두 적용되지 않았습니다.')
        } else if(isName === [] || !isName.every(isAllTrue)){
            alert('프로그램 대상의 이름이 입력되지 않았습니다.')
        } else if (isNumber === [] || !isNumber.every(isAllTrue)){
            alert('프로그램 대상의 휴대폰 번호가 올바르게 입력되지 않았습니다.')
        } else if (isEmail === [] || !isEmail.every(isAllTrue)){
            alert('프로그램 대상의 메일 주소가 올바르게 입력되지 않았습니다.')
        } else if (!agree){
            alert('주문내역확인 동의가 되지 않았습니다.')
        } 
     }
    pushCoupon = () => {
        const {coupon, data, couponBefore} = this.state;
        let couponTrim = sideTrim(coupon)
        let today = getTimeString()
        today *= 1
        let uid = data.authUser.uid
        let list = []
        
        this.props.firebase.promotionCodes().where('randomCode','==',couponTrim).get()
            .then(result=> {
                if (result.size >0) {
                result.forEach((doc) => {
                    var cid = doc.id
                    var couponData= doc.data()
                    list.push({cid, ...couponData})
                })
                let cItem= list[0]
                let arr = couponBefore.filter(c=> c.cid === list[0].cid )
                let expireDate = cItem.expireDate
                expireDate *= 1
                if(expireDate +120000 < today ){
                    alert('유효기간이 지난 쿠폰입니다.')
                } else if (arr.length>0){
                    alert('이미 적용된 쿠폰입니다.')
                } else if(cItem.type==='countLimit'){
                    if(cItem.ResCount<1){
                        alert('선착순 물량이 소진된 쿠폰입니다.')
                    } else {
                        this.setState(prevState => {
                            let list = [...prevState.couponBefore, cItem]
                            return {
                                couponBefore: list,
                                coupon:''
                        }})  
                    }
                } 
            } else {
                    alert('유효하지 않은 쿠폰입니다.')
                }
            }).catch(() => {
                alert('다시 시도해주세요.')
            })
    } 
    couponTest =()=> {
        const {data, couponBefore} = this.state;
        this.setState({ loading: true})
        let cart = data.cart
        for(let i in cart){
            for(let j in couponBefore){
                let { discountType, discountCondition, discountRatio } = couponBefore[j]
                let totalcountDiscount= couponBefore[j].totalcountDiscount !==undefined ? couponBefore[j].totalcountDiscount : 0
                if(!cart[i].couponStatus||cart[i].couponStatus === undefined){
                        if(discountType === 'anyCondition'){
                            totalcountDiscount = (cart[i].totalPrice*(discountRatio/100)) * cart[i].quantity
                        } else if(discountType === 'onlyProgram'){
                            if(!cart[i].vrRent && !cart[i].vrEvent && cart[i].published<1){
                                totalcountDiscount = (cart[i].totalPrice*(discountRatio/100)) * cart[i].quantity
                            } 
                        }else if(discountType === 'onlyPublished'){
                            if(cart[i].published>=discountCondition){
                                totalcountDiscount = (cart[i].totalPrice*(discountRatio/100)) * cart[i].quantity
                            } 
                        } else if(discountType === 'onlyVrRent'){
                            if(cart[i].vrRent){
                                totalcountDiscount = (cart[i].totalPrice*(discountRatio/100)) * cart[i].quantity
                            }
                        } else if(discountType === 'onlyVrEvent'){
                            if(cart[i].vrEvent){
                                totalcountDiscount = (cart[i].totalPrice*(discountRatio/100)) * cart[i].quantity
                            }
                        } else if(discountType === 'minPrice' ){
                            if(cart[i].totalPrice >= discountCondition){
                                totalcountDiscount = (cart[i].totalPrice*(discountRatio/100)) * cart[i].quantity
                            }
                        }
                    }
                couponBefore[j].totalcountDiscount = totalcountDiscount
            }
            
        } 
        this.setState({
            couponBefore : couponBefore.sort((a,b)=>b.totalcountDiscount-a.totalcountDiscount),
        })
    }
        useLimitCoupon =()=> {
            const {data, couponInfo} = this.state;
            this.setState({ loading: true})
            let cart = data.cart
            let totalAmount = data.totalAmount
            let itemListDiscount = 0
            let cartDiscount = 0
            for(let j in couponInfo){
            let {discountType, discountCondition, discountRatio  } = couponInfo[j]
            couponInfo[j].used = 0                
            for(let i in cart){
                    //  if((!cart[i].couponStatus||cart[i].couponStatus === undefined)){
                        let isAble = !cart[i].couponStatus || cart[i].couponStatus === undefined
                        if(isAble){
                            let discountAmount =  (cart[i].originPrice*(discountRatio/100))
                            if(discountType === 'anyCondition'){
                                cart[i].couponInfo = couponInfo[j]
                                cart[i].couponStatus = true
                                cart[i].discountAmount = discountAmount
                                cart[i].totalPrice = cart[i].totalPrice-(discountAmount) < 0 ? 0 : cart[i].totalPrice-(discountAmount)
                                cartDiscount += discountAmount
                                couponInfo[j].used +=cart[i].quantity
                            } else if(discountType === 'onlyProgram'){
                                if(!cart[i].vrRent && !cart[i].vrEvent && cart[i].published<1){
                                    cart[i].couponInfo = couponInfo[j]
                                    cart[i].couponStatus = true
                                    cart[i].discountAmount = discountAmount
                                    cart[i].totalPrice = cart[i].totalPrice-(discountAmount) < 0 ? 0 : cart[i].totalPrice-(discountAmount)
                                    cartDiscount += discountAmount
                                    couponInfo[j].used +=cart[i].quantity
                                } 
                            } else if(discountType === 'onlyPublished'){
                                if(cart[i].published>=discountCondition){
                                    cart[i].couponInfo = couponInfo[j]
                                    cart[i].couponStatus = true
                                    cart[i].discountAmount = discountAmount
                                    cart[i].totalPrice = cart[i].totalPrice-(discountAmount) < 0 ? 0 : cart[i].totalPrice-(discountAmount)
                                    cartDiscount += discountAmount
                                    couponInfo[j].used +=cart[i].quantity
                                } 
                            } else if(discountType === 'onlyVrRent'){
                                if(cart[i].vrRent){
                                    cart[i].couponInfo = couponInfo[j]
                                    cart[i].couponStatus = true
                                    cart[i].discountAmount = discountAmount
                                    cart[i].totalPrice = cart[i].totalPrice-(discountAmount) < 0 ? 0 : cart[i].totalPrice-(discountAmount)
                                    cartDiscount += discountAmount
                                    couponInfo[j].used +=cart[i].quantity
                                }
                            } else if(discountType === 'onlyVrEvent'){
                                if(cart[i].vrEvent){
                                    cart[i].couponInfo = couponInfo[j]
                                    cart[i].couponStatus = true
                                    cart[i].discountAmount = discountAmount
                                    cart[i].totalPrice = cart[i].totalPrice-(discountAmount) < 0 ? 0 : cart[i].totalPrice-(discountAmount)
                                    cartDiscount += discountAmount
                                    couponInfo[j].used +=cart[i].quantity
                                }
                            } else if(discountType === 'minPrice' ){
                                if(cart[i].totalPrice >= discountCondition){
                                    cart[i].couponInfo = couponInfo[j]
                                    cart[i].couponStatus = true
                                    cart[i].discountAmount =  discountAmount
                                    cart[i].totalPrice = cart[i].totalPrice-(discountAmount) < 0 ? 0 : cart[i].totalPrice-(discountAmount)
                                    cartDiscount +=  discountAmount
                                }
                            } else {
                                alert('쿠폰을 다시 한번 확인하세요.\n같은 알림이 계속되면 관리자에게 문의하세요.')
                            } 
                        }
                    }   
            this.couponAlert(cartDiscount)
            }
        }
    couponAlert = (cartDiscount) => {
        let { data } = this.state
        let uid = data.authUser.uid
        if( cartDiscount === 0 ){
            alert('적용 가능한 프로그램이 없습니다.')
            this.setState({ loading: false})
            // this.resetCoupon()
        }
        else if(cartDiscount > 0){
            //  this.props.firebase.promotionCode(cItem.cid).set({isUsed:uid},{merge:true})
            data.totalDiscountAmount = cartDiscount
            data.totalAmount = data.originPrice - cartDiscount
            this.setState(prevState => {
                // let list = [...prevState.couponInfo, cItem]
                return {
                    discountPrice: cartDiscount,
                    // couponInfo: list,
                    // coupon:'',
                    loading: false
            }})  
        }
        
    }
    resetCoupon =()=>{
        let {data} = this.state
        let cart = data.cart
        for(let i in cart){
            cart[i].couponInfo = undefined
            cart[i].couponStatus = false
            cart[i].totalPrice = cart[i].originPrice
            cart[i].discountAmount = 0
        }
        this.setState(prevState => {
            let data = prevState.data
            data.totalAmount = data.originPrice
            data.totalDiscountAmount = 0
            return{
               couponInfo : [],
               data,
               discountPrice:0
            }})
    }
    cancelCoupon = (c) => {
        let index = this.state.couponBefore.indexOf(c)
        let list = this.state.couponBefore.slice()
        list.splice(index,1)
        this.setState({
            couponBefore:list
        })
    }
    onPropsChange = (name, value) => {
        this.setState({
            [name] : value
        })
    }
    handleItemList = (index, rName, value) => {
    // e.preventDefault()
        let cartItem = this.state.itemList.slice()
        const { username, email} = this.state.data.authUser
        const { number } = this.state
        if(rName==='rName'){
            cartItem[index].rName = value;

        } else if (rName==='rNumber'){
            cartItem[index].rNumber = phoneFormatter((value).replace(/[^0-9]/g,""))

        } else if(rName==='rEmail'){
            if(isEmail(value)){
                cartItem[index].rEmail = value;
                cartItem[index].isEmailValid = true;
            }else if(!isEmail(value)) {
                cartItem[index].rEmail = value;
                cartItem[index].isEmailValid = false;
            }

        } else if (rName === 'myInfo'){
                cartItem[index].rName = username;
                cartItem[index].rNumber = number;
                cartItem[index].rEmail = email;
                cartItem[index].isEmailValid = true;
        }
        // console.log(cartItem)
        this.setState({
            itemList : cartItem,
        });            
    }
    modalOpen = (status) => {
        if(status === true){
            $('html, body').css("overflow", "hidden");
            
        } else {
            $('html, body').css("overflow", "inherit")
        }
        this.setState({rentalPolicy: status,})
      }
  onSubmit = () => {
    let {cart} = this.state.data
    let { processType, programType } = this.props;
    let itemList = [];
    let id = 0;
    for (var i in cart) {
      let {
        title,
        target,
        published,
        period,
        totalPrice,
        originPrice,
        quantity,
        vrRent,
        vrEvent,
        pid,
        digitalBookID,
        createdAt,
        periodAmount,
        printPrice,
        rentPrice,
        eventPrice,
        discountAmount
      } = cart[i];
      
      let itemPrice = totalPrice / quantity;
      let digitalBookId = digitalBookID === undefined ? "" : digitalBookID;
      let site = "tlp";
      // let bookCode = digitalBookId!== '' ? : null
      let data = {
        item_name: `${title} ${target}대상${
          published > 0 ? "(출판)" : ""
        } - ${period}일`, //어린 왕자, 나를만나다_성인대상_90
        qty: quantity,
        unique: pid,
        //makeItemId({programType, processType, period, title, target, digitalBookID}),
        price: itemPrice,
        itemPrice: itemPrice,
        cat1: programType, //카테고리 상위 : Online
        cat2: target, //카테고리 중위 : 성인
        cat3: processType, //카테고리 하위 : self
        programType,
        processType,
        title,
        target,
        period,
        published,
        vrRent,
        vrEvent,
        digitalBookID: digitalBookId,
        createdAt,
        periodAmount,
        printPrice,
        rentPrice,
        eventPrice,
        originPrice,
        discountAmount,
        site,
      };
      let j = 0;
      while (j < quantity) {
        itemList.push({ ...data, id });
        id++;
        j++;
      }
    }
    itemList.sort((a, b) => {
      if (a.target === b.target) {
        if (a.period === b.period) {
          return a.published - b.published;
        } else {
          return a.period - b.period;
        }
      } else {
        return a.target - b.target;
      }
    });
    // console.log(itemList)
    this.setState({
      itemList: itemList,
    });
  };
  getgoingBack=()=>{
    let result = window.confirm('페이지를 벗어나면 적용한 쿠폰이 모두 취소됩니다')
    if(result){
        this.resetCoupon()
        this.props.isOpen('ordering')
    }
    }
    render() {
    const {isOpen} = this.props
    const {number, data, agree, discountPrice, method, couponInfo, isCoupon, coupon, anyVrRent, anyVrEvent, couponBefore,
        imgUrl1, imgUrl2, certificationInfo, isCertificated, itemList, isItemListValid, authUser,
        couponMsg, rentalPolicy
    } = this.state;
    const totalQuantity= data.totalQuantity
    //상품 금액 (부가세 별도)
    // console.log(data)
    const formattedOriginAmount = data.originPrice.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
    //할인금액
    const formattedDiscountPrice = data.totalDiscountAmount ? data.totalDiscountAmount.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,') : 0
    //상품결제금액
    const paymentPrice = data.totalDiscountAmount>0? data.totalAmount - data.totalDiscountAmount : data.totalAmount
    //총 결제금액 (부가세 포함)
    const totalPrice = (paymentPrice) <1 ? 0 : Math.floor(paymentPrice)
    const formattedTotalAmount = data.totalAmount.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
    let uid = data.authUser.uid
    let today = getTimeString()
    let validCouponList = couponInfo.filter(c=>c.used>0)
    const paymentData = {
        //주문자 정보
        uid : uid,
        username : data.authUser.username,
        addr : data.authUser.address || '',
        email : data.authUser.email,
        cart : data.cart,
        itemList : itemList,
        number : number,
        anyVrRent : anyVrRent,
        anyVrEvent : anyVrEvent,
        certificationInfo : certificationInfo,
        number : number,
        programType : data.programType,
        processType : data.processType,
        //결제정보
        totalPrice:data.totalAmount,
        discountPrice:discountPrice,
        totalQuantity:totalQuantity,
        createdAt : today,
        couponInfo:validCouponList.length>0 ? validCouponList : false,
        imgUrl1, imgUrl2
    }
    let { processType, programType } = this.props;
    // console.log(paymentData) 
    let couponValid = couponBefore.length>0 ? couponInfo.length !== couponBefore.filter(c=> c.totalcountDiscount !== 0).length ? false : true : true
    return (
        <div className="paymentContFrame">
            <ul className="authInfo">
                <li>
                    <span className="cate">신청자</span> 
                    <span>{data.authUser.username} &nbsp;|&nbsp; {data.authUser.email}</span>
                </li>
                {anyVrRent && 
                <li>
                    <span className="cate">파일첨부</span>
                    <ul className="uploadInputGroup">
                        <li>
                            <div>
                                <span className="fileKinds">신분증</span>
                                <ImageUploadNR 
                                        onUpdate={this.onPropsChange}
                                        itemName={'imgUrl1'}
                                        fileName={`certification/vrRent/${uid}/`}
                                        /> 
                            </div>
                            {imgUrl1 ? <span>( 업로드 되었습니다 )</span> : <span> ( 파일 선택 후 업로드 버튼을 눌러주세요 )</span>}
                        </li>
                        <li> 
                            <div>
                                <span className="fileKinds">통장 사본</span>
                                <ImageUploadNR 
                                        onUpdate={this.onPropsChange}
                                        itemName={'imgUrl2'}
                                        fileName={`certification/vrRent/${uid}/`}
                                        /> 
                            </div>
                            {imgUrl2 ? <span>( 업로드 되었습니다 )</span> : <span>( 파일 선택 후 업로드 버튼을 눌러주세요 )</span>}
                        </li>
                        <li className="guideTextGroup">
                            <p>체험 기기의 보증을 위해 휴대폰 사용자 이름과 일치한 신분증 사본과 통장 사본을 각각 업로드 해 주세요.</p>
                            <p>이름이 동일하지 않을 시 본인 확인이 불가능하여 체험 기기가 발송 되지 않습니다.</p>
                            {/* <p>무통장 입금, 실시간 계좌이체의 경우 보증금의 환불은 제출한 통장에 명시된 계좌로 이뤄집니다.</p> */}
                            <p>파일 업로드/결제 진행 시 <span className="rentPolicyPopBtn" onClick={()=>this.modalOpen(true)}>기기 관련 규정 사항</span>에 사용자 역시 동의한 것으로 간주합니다.</p>
                        </li>
                    </ul>
                </li>
                }

                {rentalPolicy&&
                <div className="rentalPolicyBg">
                    <div className="rentalPolicyFrame">
                        <div className="rentalPolicyPopTitle">
                            <h5>기기 관련 규정</h5>
                            <div onClick={()=>this.modalOpen(false)}>×</div>
                        </div>
                        <VrRent headShow={false}/>
                    </div>
                </div>
                }

                <li>
                    <span className="cate">신청자 연락처</span> 
                    <ul>
                        <li>
                            <input type='text' name='number' value={number}
                            onChange={event => this.setState({number: phoneFormatter(event.target.value.replace(/[^0-9]/g,""))})}/>
                            <CertificateI number={number} isCertificated={isCertificated} authUser={authUser} uid={uid} onPropsChange={this.onPropsChange} />
                            {/* <Certificate number={number} isCertificated={isCertificated} authUser={authUser} uid={uid} onPropsChange={this.onPropsChange}/> */}
                        </li>
                        <li className="guideText">
                            <p>신청자 이름 및 메일은 <Link to={{pathname: `${ROUTES.USER}`, state: {tab : 'myInfo'}}} >MY PAGE</Link>에서 수정할 수 있습니다.</p>
                        </li>
                    </ul>
                </li>
                
            </ul>

            
            <ul className="orderInfo">
                <li>
                    <span className="cate">주문 상품</span>
                    <ul>
                        <li>On-line Learning Program - 어린 왕자, 나를 만나다</li>
                        {data.cart && data.cart.sort((a,b) => {
                            if(a.target === b.target){
                                if(a.period ===b.period){
                                return a.published - b.published
                                }
                                else {
                                return  a.period - b.period
                                }
                            } else {
                                return a.target - b.target
                            }
                            }).map((c, id)=> {
                                // console.log(c)
                        const periodPrice = c.period === '180'? 4000 : c.period === '365' ? 10000 : 0
                        const itemPrice = (c.price + periodPrice).toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
                        const isPrintOut = c.published > 0 ? `인쇄(${c.published}부)` : "인쇄안함";
                        const isRent = c.vrRent ? `기기 체험` : c.vrEvent ? '기기구매' : `체험 불포함`;
                        return(
                            <li key={id}>
                                <span>
                                    {c.target}대상 - {c.period}일 - {isPrintOut} -{" "}
                                    {/* {isRent} -  */}
                                    {c.quantity}건
                                    {/* {c.originPrice.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')} -  */}
                                    {c.couponStatus ? 
                                    ` - ${c.discountAmount.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')} 원 할인!` : ''}
                                    {/* {c.totalPrice.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')} */}
                                </span>
                            </li>
                        )
                        })}
                    </ul>
                </li>
                <li>
                    <span className="cate">총 상품 수량</span>
                    <span> {totalQuantity}건</span>
                </li>
                <li>
                    <span className="cate">총 상품 금액</span>
                    <span>{formattedOriginAmount}원</span>
                </li>
                <li className="orderCouponApply">
                    <span className="cate">쿠폰 할인</span>
                    <div>
                        <input type='text' name='coupon' value={coupon} onChange={e=> this.setState({coupon :e.target.value})}
                        disabled={couponInfo.length>0}/>

                        <button onClick={this.pushCoupon} disabled={coupon.length<8}>쿠폰 추가</button>
                    </div>
                </li>
                <li className="orderCouponApply orderCouponApplyList">
                    <span className="cate">쿠폰 내역</span>
                    <div>
                        {couponInfo.length>0 ? 
                        <div>
                            {/* <div>적용 쿠폰</div> */}
                        {couponInfo.filter(c=>c.used>0).map((c, id)=>{
                            let { discountType, discountCondition, randomCode, discountRatio, codeName } = c
                            const type = discountType === 'anyCondition' ? '무조건' 
                            : discountType === 'onlyPublished' ? '책 인쇄'
                            : discountType === 'onlyProgram' ? '프로그램만'
                            : discountType === 'onlyVrRent' ? '기기 대여'
                            : discountType === 'onlyVrEvent' ? '기기 구매'
                            : discountType === 'minPrice' ? '최소 금액' : ''
                            const conditionType = discountType === 'anyCondition' ? '' 
                            : discountType === 'onlyPublished' ? ` ${discountCondition}권 이상`
                            : discountType === 'onlyVrRent' ? ''
                            : discountType === 'minPrice' ? ` ${discountCondition}원 이상` : ''
                            return(
                                <div style={{color:"green"}} key={id}>
                                    {randomCode} - {codeName} ({discountRatio}%) 쿠폰
                                </div>
                            )
                        })}
                        </div>
                        :couponBefore.length>0 ? 
                        <div>
                            {/* <div>입력한 쿠폰</div> */}
                        {couponBefore.map((c, id)=>{
                            let { discountType, discountCondition, randomCode, discountRatio, codeName } = c
                            const type = discountType === 'anyCondition' ? '무조건' 
                            : discountType === 'onlyPublished' ? '책 인쇄'
                            : discountType === 'onlyProgram' ? '프로그램만'
                            : discountType === 'onlyVrRent' ? '기기 대여'
                            : discountType === 'onlyVrEvent' ? '기기 구매'
                            : discountType === 'minPrice' ? '최소 금액' : ''
                            const conditionType = discountType === 'anyCondition' ? '' 
                            : discountType === 'onlyPublished' ? `${discountCondition}권 이상`
                            : discountType === 'onlyVrRent' ? ''
                            : discountType === 'minPrice' ? `${discountCondition}원 이상` : ''
                            return(
                                <div style={{color:"black"}} key={id}>
                                    {randomCode} - {codeName} ({discountRatio}%) 쿠폰
                                    <span style={{color:"black"}} onClick={()=>this.cancelCoupon(c)}> × </span>
                                </div>
                            )
                        })}
                        </div>
                        :''}
                        <div>
                        {couponBefore.length>0 && couponInfo.length>0 ? 
                        <span>
                            <button onClick={()=>this.resetCoupon()}> 사용 취소 </button>
                            <span>쿠폰 사용 조건과 일치한 프로그램 1건 당 할인 금액이 가장 높은 1개의 쿠폰이 적용됩니다. (중복 가능)</span>
                        </span>
                        :<span><button onClick={()=> this.setState({
                            couponInfo : couponBefore.filter(c=> c.totalcountDiscount !== 0)
                        })}> 쿠폰 사용 </button></span>}
                        </div>
                    </div>
                </li>
                <li>
                    <span className="cate">할인 금액</span> 
                    <span>{formattedDiscountPrice}원</span>
                </li>
                <li>
                    <span className="cate">최종 결제 금액</span>
                    <span>{formattedTotalAmount}원 (부가세포함)</span>
                </li>
            </ul>
            <div className="programInfo">
                <span className="cate">프로그램 별 대상자 입력</span>
            
                
                <ul>
                    <li className="guideTextGroup">
                        <p>※ 쿠폰 적용 후 대상자를 입력해주세요! 쿠폰이 변경되면 대상자 정보가 사라질 수 있습니다.</p>
                        <p>각 프로그램의 일수, 인쇄 및 기기대여에 해당하는 프로그램 등록 코드를 받을 이름/휴대폰번호/메일을 정확히 기입해주세요.</p>
                        <p>결제 완료 시 해당 이메일을 통해 디지털북 등록코드와 안내 메일이, SMS로 기기/인쇄관련 사항이 발송됩니다.</p>
                        <p>자세한 사항은 프로그램 상세정보를 참고하세요.</p>
                    </li>
                    {itemList && itemList.map((c, id)=> {
                        const periodPrice = c.period === '180'? 4000 : c.period === '365' ? 10000 : 0
                        const isPrintOut = c.published>0 ? `인쇄(${c.published}부)` : '인쇄안함'
                        const inRent = c.vrRent ? `기기 체험` : c.vrEvent ? `기기구매` : `체험 불포함`
                        return(
                            <li key={c.id}>
                                <span className="contentOrder">{id+1}. {c.target}대상-{c.period}일-{isPrintOut}
                                {/* -{inRent} */}
                                </span>
                                <span className="contentInput">
                                    <input type='text' name='rName' value={c.rName || ''} placeholder='이름'
                                    onChange={e=>this.handleItemList(id, 'rName', e.target.value)}/>

                                    <input type='text' name='rNumber' value={c.rNumber !== undefined && c.rNumber !== '' ? c.rNumber : ''} placeholder='휴대폰번호'
                                    onChange={e=>this.handleItemList(id, 'rNumber', e.target.value)}/>

                                    <input type='text' name='rEmail' value={c.rEmail || ''} placeholder="메일주소"
                                    onChange={e=>this.handleItemList(id, 'rEmail', e.target.value)} 
                                    className={
                                        c.rEmail !=='' || c.rEmail !==undefined?
                                        c.isEmailValid ===undefined ? `empty` :
                                            !c.isEmailValid  && c.isEmailValid !==undefined ? `texting`: `finishing`
                                    : null

                                    }/>
                                <span onClick={()=>this.handleItemList(id, 'myInfo', null)}>내 정보 입력</span>
                            </span>
                            </li>
                        )
                    })}
                    
                </ul>
            </div>
            <div className="confirmInfo">
                <span className="cate"><span>주문내역</span><span>확인동의(필수)</span></span>
                <div>
                    <span>주문할 상품의 상품명, 가격, 배송정보 등을 최종 확인하였으며, 구매에 동의하십니까? (전자상거래법 제 8조 2항)</span>
                    <span><input type='checkbox' name='agree' checked={agree} onChange={()=>this.setState({agree:!agree})}/>
                    <span onClick={()=>this.setState({agree:!agree})}>동의합니다.</span></span>
                </div>
            </div>
            <div className="bottomBtnGroup">
                <button onClick={()=> this.getgoingBack()}>뒤로 가기</button>
                <RequestPay isItemListValid={isItemListValid && couponValid} payReqData={paymentData}
                couponInfo={couponInfo} onInvalid={this.onInvalid}/>
            </div>
        </div>
    );
};
}
export default compose(
    withRouter,
    withFirebase,
  )(PaymentPage);


  
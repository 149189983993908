export const getTimeDotString = (time) => {

let sysdate = new Date(time) || new Date()
    function date_to_str(format)
        {
            var fullyear = format.getFullYear().toString();
            var year = fullyear.substr(2,2)
            var month = format.getMonth() + 1;
            if(month<10) month = '0' + month;
            var date = format.getDate();
            if(date<10) date = '0' + date;
            var hour = format.getHours();
            if(hour<10) hour = '0' + hour;
            var min = format.getMinutes();
            if(min<10) min = '0' + min;
            var sec = format.getSeconds();
            if(sec<10) sec = '0' + sec;
            return year+'.'+month +'.'+ date +'.'+ hour +'.'+ min +'.'+ sec;
        }
        sysdate = date_to_str(sysdate);
    return sysdate
    }

    export const getTimeString = (time) => {

        let sysdate = time? new Date(time) :new Date()
            function date_to_str(format)
                {
                    var fullyear = format.getFullYear().toString();
                    var year = fullyear.substr(2,2)
                    var month = format.getMonth() + 1;
                    if(month<10) month = '0' + month;
                    var date = format.getDate();
                    if(date<10) date = '0' + date;
                    var hour = format.getHours();
                    if(hour<10) hour = '0' + hour;
                    var min = format.getMinutes();
                    if(min<10) min = '0' + min;
                    var sec = format.getSeconds();
                    if(sec<10) sec = '0' + sec;
                    return `${year}${month}${date}${hour}${min}${sec}`;
                }
                sysdate = date_to_str(sysdate);
            return sysdate
            }

export const getTimeStringNotime = (time) => {

                let sysdate = time? new Date(time) :new Date()
                    function date_to_str(format)
                        {
                            var fullyear = format.getFullYear().toString();
                            var year = fullyear.substr(2,2)
                            var month = format.getMonth() + 1;
                            if(month<10) month = '0' + month;
                            var date = format.getDate();
                            if(date<10) date = '0' + date;
                            return `${year}${month}${date}000000`;
                        }
                        sysdate = date_to_str(sysdate);
                    return sysdate
                    }

export const getServerTimeString = () => {
let sysdate = new Date()
    sysdate.setHours( sysdate.getHours()+9);
    function date_to_str(format)
        {
            var month = format.getMonth() + 1;
            if(month<10) month = '0' + month;
            var date = format.getDate();
            if(date<10) date = '0' + date;
            var hour = format.getHours();
            if(hour<10) hour = '0' + hour;
            var min = format.getMinutes();
            if(min<10) min = '0' + min;
            var sec = format.getSeconds();
            if(sec<10) sec = '0' + sec;
            return month + date + hour + min + sec;
        }
        sysdate = date_to_str(sysdate);
    return sysdate
    }

export const getDateString = () => {
    let sysdate = new Date()
        sysdate.setHours( sysdate.getHours()+9);
        function date_to_str(format)
            {
                var fullyear = format.getFullYear().toString();
                var year = fullyear.substr(2,2)
                var month = format.getMonth() + 1;
                if(month<10) month = '0' + month;
                var date = format.getDate();
                if(date<10) date = '0' + date;
                return year + month + date;
            }
            sysdate = date_to_str(sysdate);
        return sysdate
        }
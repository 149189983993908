import React, {useState, useEffect} from 'react';
import {getTimeString, getTimeDayjs} from '../../helpers'
import {withFirebase} from '../../Firebase'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import dayjs from 'dayjs'
import 'dayjs/locale/ko'
import './CreateCoupons.css'
dayjs.locale('ko')


const CountLimit = props => {
    const [codeArr, setcodeArr] = useState(props.codeArr)
    const [codeName, setcodeName] = useState('')
    // const [minQuantity, setminQuantity] = useState(1)
    // const [discountType, setdiscountType] = useState('')
    const [discountCondition, setdiscountCondition] = useState(0)
    // console.log(discountCondition)
    const discountedCondition = discountCondition.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
    const [discountType, setdiscountType] = useState('anyCondition')
    //parseInt(discountRatio)
    const [discountRatio, setdiscountRatio] = useState(0)
    const discountedAmount = discountRatio.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
    const [limitCount, setlimitCount] = useState(0)
    let today = dayjs(new Date())
    let todaySubtractMonth = today.add(-1, 'month').format('YYYY-MM-DD')
    let todaySubtractMonthString = getTimeString(`${todaySubtractMonth}T00:00:00`)
    const [randomCode, setrandomCode] = useState('')
    const [editableCode, seteditableCode] = useState(false)
    const [timeString, settimeString] = useState('')

    useEffect(() => {
        setcodeArr(props.codeArr)
    }, [props.codeArr])

    function makeid(){
        if(!editableCode){
            alert('코드를 중복검사하세요.')
        } else {
        let createdAt = getTimeString()

        const newDbObj = {codeName: codeName, discountType:discountType, discountRatio:parseInt(discountRatio),
            discountCondition:parseInt(discountCondition), type:'countLimit',
            randomCode:randomCode, ResCount:parseInt(limitCount), limitCount:parseInt(limitCount), site:'tlp', target:'self', createdAt, }
            // console.log(newDbObj)
        props.firebase.promotionCodes().add(newDbObj)
        .then(res=> {
            var uid = res.id
            newDbObj['uid'] = uid
            setdiscountRatio(0)
            setrandomCode('')
            seteditableCode(false)
            setcodeName('')
            setlimitCount(0)
        }).catch(err => {
            alert('다시 시도 하세요.\n에러코드 : ' + err)
        })}
    }
    const [inValid, setinValid] = useState(true)
    
    useEffect(() => {
        const isAmount = discountType==='onlyPublished' || discountType==='minPrice'  ? 
        discountCondition >0 ? true : false : true

        const valid = codeName==='' || discountType==='' || !isAmount || discountRatio > 100 || discountRatio < 1 || limitCount <1 || randomCode===''
        setinValid(valid)
        // setrandomCode('')
    }, [codeName, discountType, discountRatio, discountCondition, limitCount, randomCode, ])
    useEffect(() => {
        setdiscountCondition(0)
    }, [discountType])
    let formattedtoday = parseInt(today.format('YYMMDD'))
    //기본 '코드가 복사되었습니다.' 숨김
    const [copied, setcopied] = useState({display:'none'})
    function TempMsg (type, randomCode) {
        return () => {
          switch (type) {
            case 'copiedCP':
              NotificationManager.info(`쿠폰 코드(${randomCode})가 복사되었습니다.`, 'COPIED', 1500, () => {
                alert('callback');
              });
              break;
          }
        };
    };
    function makeRandomCode (){
        var text = "";
        var possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
        for( var i=0; i < 8; i++ ){
            text += possible.charAt(Math.floor(Math.random() * possible.length))
        };
        let testArr = codeArr.filter(c=> c.randomCode === text)
        if (testArr) {
            text += possible.charAt(Math.floor(Math.random() * possible.length))
            text += possible.charAt(Math.floor(Math.random() * possible.length))
        }
        seteditableCode(true)
        setrandomCode(text)
        // return text
        }
    function checkRandomCode (e){
        e.preventDefault()
        if(randomCode !=='' && randomCode.length>7 && !editableCode){
            props.firebase.promotionCodes().where('randomCode','==',randomCode ).get()
            .then(res=> {
                if(res.size>0){
                    alert('중복된 코드입니다.')
                } else {
                    seteditableCode(true)
                }
            }).catch(err => {
                alert('다시 시도 하세요.\n에러코드 : ' + err)
            })
        } else {
            alert('코드를 랜덤 생성하거나 8자 이상 직접 입력하세요.')
        }
        }
   function textCode (e){
    setrandomCode(e.target.value.trim())
    seteditableCode(false)
   }
    return (
        <div className="createCouponsFrame" style={{padding:"0"}}>
            <div className="makingCouponBxGroup">
                <div className="couponNameBx">
                    <p>쿠폰 이름</p>
                    <input type='text' name='codeName' value={codeName} 
                    onChange={e=>setcodeName(e.target.value)} required/>
                </div>
                <div className="couponNameBx">
                    <p>쿠폰 코드</p>
                    <input type='text' name='randomCode' value={randomCode} 
                    onChange={e=>textCode(e)} required placeholder='대소문자구분, 숫자 등 8자 이상'/>
                    {editableCode ? <button className='randomcodeBtn' disabled={true}> 체크 완료 </button> :<button className='randomcodeBtn' onClick={(e)=>checkRandomCode(e)}> 중복 체크 </button>}<button onClick={(e)=>makeRandomCode(e)}> 랜덤 생성 </button>
                </div>
                <div className="couponConditionBx">
                    <p>사용 조건 (택 1)</p>
                    <div>
                        <div>
                            <input type='checkbox' name='discountType' onChange={()=> setdiscountType('anyCondition')} checked={discountType === 'anyCondition'} />
                            <span>무조건</span>
                        </div>
                        <div>
                            <input type='checkbox' name='discountType' onChange={()=> setdiscountType('onlyProgram')} checked={discountType === 'onlyProgram'} />
                            <span>프로그램</span>
                        </div>
                        <div>
                            <input type='checkbox' name='discountType' onChange={()=> setdiscountType('onlyPublished')} checked={discountType === 'onlyPublished'} />
                            <span>책 인쇄</span>
                        </div>
                        {/* <div>
                            <input type='checkbox' name='discountType' onChange={()=> setdiscountType('onlyVrRent')} checked={discountType === 'onlyVrRent'} />
                            <span>기기대여</span>
                        </div>
                        <div>
                            <input type='checkbox' name='discountType' onChange={()=> setdiscountType('onlyVrEvent')} checked={discountType === 'onlyVrEvent'} />
                            <span>기기구매</span>
                        </div> */}
                        <div>
                            <input type='checkbox' name='discountType' onChange={()=> setdiscountType('minPrice')} checked={discountType === 'minPrice'} />
                            <span>최소 금액</span>
                        </div>
                    </div>
                </div>
                <div className="couponPriceBx">
                    {discountType === 'onlyPublished' ? 
                    <div>
                        <p>최소 인쇄 권수</p>
                        <span>인당</span>
                        <input type='number' min='1' name='discountCondition' value={discountCondition} 
                        onChange={e=>setdiscountCondition(e.target.value)}/>
                        <span>권</span>
                    </div>    
                    :discountType === 'minPrice' ? 
                    <div>
                        <p>최소 결제 금액</p>
                        <input type='text' name='discountCondition' value={discountedCondition} 
                        onChange={e=>setdiscountCondition(e.target.value.replace(/[^0-9]/g,""))}/>
                        <span>원</span>
                    </div>
                    : null}
                    <div>
                        <p>할인 비율</p>
                        <input type='number' min='1' max='100' name='discountRatio' value={discountRatio} 
                        onChange={e=>setdiscountRatio(e.target.value)}/>
                        <span>%</span>
                    </div>
                </div>
                {/* <div className="couponPriceBx">
                    
                </div> */}
                <div className="couponPeriodBx">
                    <p>사용 가능 횟수</p>
                    <div>
                        <input type='number' name='limitCount' value={limitCount} 
                        onChange={e=> setlimitCount(e.target.value)}/> 회
                    </div>
                </div>
                <div className="couponCompleteBx">
                    <p>쿠폰 생성</p>
                    <div>
                        <span>{limitCount}회 사용 가능한 {discountRatio}% 할인 쿠폰(일회성)</span>
                        <button onClick={makeid} disabled={inValid}>발급하기</button>
                        {/* {randomCode !== '' && 
                        <CopyToClipboard text={randomCode}
                        onCopy={TempMsg('copiedCP', randomCode)}>
                            <span>{randomCode}</span>
                        </CopyToClipboard>
                        } */}
                    </div>
                </div>
                {/* <div className="couponGuideText">
                    ※ 발급 된 쿠폰 할인 금액은 프로그램 한 건(1인)마다 각각 적용되는 가격입니다.
                </div> */}
            </div>

            
            <span style={copied}>코드가 복사되었습니다.</span>

            <div className="madeCouponGroup">
                <span>(최신순)</span>
                <table border='1'>
                    <thead>
                        <tr>
                            <th>쿠폰 이름</th>
                            <th>쿠폰 사용 조건</th>
                            <th>할인 비율</th>
                            <th>사용 현황</th>
                            <th>쿠폰 코드</th>
                            <th>발행일</th>
                            {/* <th>남은 횟수</th> */}
                            <th>비고</th>
                        </tr>
                    </thead>
                    <tbody>
                    {codeArr && codeArr.map(c=> {
                            let {discountType, createdAt, expireDate, isUsed, randomCode } = c
                            // console.log(c)
                            const discounttext = discountType === 'anyCondition' ? '무조건' 
                            : discountType === 'onlyProgram' ? '프로그램'
                            : discountType === 'onlyPublished' ? '책 인쇄'
                            : discountType === 'onlyVrRent' ? '기기대여'
                            : discountType === 'onlyVrEvent' ? '기기구매'
                            : discountType === 'minPrice' ? '최소 금액' : ''
                            const conditionType = discountType === 'anyCondition' ? ''
                            : discountType === 'onlyProgram' ? '' 
                            : discountType === 'onlyPublished' ? '권 이상'
                            : discountType === 'onlyVrRent' ? ''
                            : discountType === 'onlyVrEvent' ? ''
                            : discountType === 'minPrice' ? '원 이상' : ''
                            
                            let isCreatedAt = getTimeDayjs(createdAt)
                            let createdDay = dayjs(isCreatedAt).format('ddd')
                            // const discountedAmount = c.discountRatio
                            return(
                            <tr key={randomCode}>
                                <td>{c.codeName || '미입력'}</td>
                                <td>{discounttext} {c.discountCondition || ''} {conditionType}</td>
                                <td>{c.discountRatio.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')}%</td>
                                <td>{c.ResCount} / {c.limitCount}회</td>
                                <td>
                                    <CopyToClipboard text={randomCode}
                                    onCopy={TempMsg('copiedCP', randomCode)}>
                                        <span>{randomCode}</span>
                                    </CopyToClipboard>
                                </td>
                                <td>{isCreatedAt} ({createdDay})</td>
                                {/* <td>{c.ResCount}회</td> */}
                                <td><button onClick={()=>props.deleteCode(c)}>삭제</button></td>
                            </tr>)
                        })}
                    </tbody>
                </table>
            </div>
            <NotificationContainer/>
        </div>
    );
};

export default withFirebase(CountLimit);
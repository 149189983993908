import React, { Component } from 'react'
import {Link} from 'react-router-dom'
import * as ROUTES from '../../../constants/routes';
import { compose } from 'recompose';
import { withAuthorization, withEmailVerification } from '../../Session';
import Digitalbook_PC from './Digitalbook_PC.jsx'
import Digitalbook_Mobile from './Digitalbook_Mobile.jsx'
import XRBOOKLogo from '../images/XRBOOKLogo.png'
import dBookImagePc1 from '../../images/dBookImagePc1.png'
import dBookImagePc2 from '../../images/dBookImagePc2.png'
import dBookImagePc3 from '../../images/dBookImagePc3.png'
import dBookImagePc4 from '../../images/dBookImagePc4.png'
import dBookImagePc5 from '../../images/dBookImagePc5.png'
import dBookImageTaMo1 from '../../images/dBookImageTaMo1.png'
import dBookImageTaMo2 from '../../images/dBookImageTaMo2.png'
import dBookImageTaMo3 from '../../images/dBookImageTaMo3.png'
import dBookImageTaMo4 from '../../images/dBookImageTaMo4.png'
import dBookImageTaMo5 from '../../images/dBookImageTaMo5.png'
import { getTimeString } from '../../helpers';
import Manual from '../../Guide/Manual'
import './AuthReader.css'
import './AuthReaderResp.css'
import $ from "jquery";
import { faInfo } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


class AuthReader extends Component {
    // constructor(props) {
    //     super(props);
    //     this.onUnload = this.onUnload.bind(this); // if you need to bind callback to this
    // }
    state={
        bookData : 
        // this.props.location.state !== undefined ? this.props.location.state.bookData :
        {},
        seenPageId : this.props.location.state !== undefined ? this.props.location.state.seenPageId :0,
        lastPageId : 0,
        bookFinishAt  : this.props.location.state !== undefined ? this.props.location.state.bookFinishAt :'0',
        published  : this.props.location.state !== undefined ? this.props.location.state.published :0,
        review  : this.props.location.state !== undefined ? this.props.location.state.review :false,
        bookTitle  : this.props.location.state !== undefined ? this.props.location.state.bookTitle :'',
        
        windowWidth: window.innerWidth,
        windowHeight: window.innerHeight,
        pageId:0,
        pcPageId:0,
        authUser : JSON.parse(localStorage.getItem('authUser')),
        titlebyAuth: this.props.location.state  !== undefined ? this.props.location.state.title : '',
        programTitle: this.props.location.state  !== undefined ? this.props.location.state.programTitle : '',
        mybookId : this.props.location.state !== undefined ? this.props.location.state.mybookId :'',
        isChanged : false,
        publishedCover:{}
    }
    componentDidMount(){
        this.onGetDigitalbook()
        window.addEventListener('resize', this.updateDimensions);

        // window.history.pushState(null, '', location.href);
        // window.onpopstate = () => {
        // history.go(1);
        // };

        // window.onpageshow = function(event) {
        //     if (event.persisted) {
        //         document.location.reload();
        //     }
        // };

        if(this.props.location.state  === undefined) {
            alert('유효하지 않은 접근입니다.')
            this.props.history.push(ROUTES.USER)
        // } else if(this.props.location.state.bookData  === undefined){
        //     alert('북 데이터가 존재하지 않습니다.')
        //     this.setState({bookData : {}}, () => this.props.history.push(ROUTES.USER))   
        } else if (parseInt(this.props.location.state.bookFinishAt)<parseInt(getTimeString())){
            alert('유효한 날짜가 아닙니다.')
            this.setState({bookFinishAt : {}}, () => this.props.history.push(ROUTES.USER)) 
        }
        
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }
    updateDimensions = () => {
        this.setState({ windowWidth: window.innerWidth, windowHeight: window.innerHeight });
      };
    onUnload (e) {
        e.preventDefault();
        let confirmationMessage = '정말 닫으시겠습니까?'
            e.returnValue = confirmationMessage    // Gecko, Trident, Chrome 34+
            return confirmationMessage    // Gecko, WebKit, Chrome < 34
    }
    componentDidUpdate(prevProps, prevState){
        let {pcPageId, pageId} = this.state
        if(this.state.authUser['uid'] !== this.props.location.state.authUser['uid']){
            alert('유효하지 않은 사용자입니다.')
        }
        if(prevState.pageId !== this.state.pageId){
            this.setState({
                // pageId:pageId,
                // pcPageId:parseInt(pageId/2),
                seenPageId: this.state.seenPageId < pageId ?  pageId : this.state.seenPageId
            })
        }
        // if(prevState.bookData !== this.state.bookData){
        //     console.log(this.state.bookData.pageList[13])
        // }
        // else if(prevState.pcPageId !== this.state.pcPageId){
        //     //pcPageId +1? 체크
        //     this.setState({
        //         // pageId:pcPageId*2,
        //         // pcPageId:pcPageId,
        //         seenPageId: this.state.seenPageId < pcPageId*2 ?  pcPageId*2 : this.state.seenPageId
        //     })
        // }
        if(prevState.lastPageId !== this.state.lastPageId && this.state.pageId!==0){
            let {mybookId} = this.state
            this.props.firebase.mybook(mybookId).update({
                lastPageId : this.state.pageId
            })
        }
        if(prevState.seenPageId < this.state.seenPageId){
            let {mybookId, pageList, seenPageId} = this.state
            this.props.firebase.mybook(mybookId).update({
                seenPageId
            })
            // .then(()=>alert('저장되었습니다.'))
            // .catch((err)=>console.log(err))
        }
    }
    onPageListChange = (name, value) => {
        if(name === 'pageList'){
            this.setState(prevState => ({
                bookData: {
                    ...prevState.bookData,
                    pageList : value
                },
            }))
            this.onStopIframeVideo()
        } else if (name === "pageIdPC"){
            this.setState(prevState => ({
                // pcPageId: prevState.pcPageId+value,
                pageId:prevState.pageId+(value*2)
            }))
            this.onStopIframeVideo()
        } else if (name === "pageIdMobile"){
            this.setState(prevState => ({
                pageId: prevState.pageId+value,
                // pcPageId:parseInt((prevState.pageId+value)/2)
            }))
            // console.log(value)
            this.onStopIframeVideo()
        } else if (name === "pageId"){
            this.setState({
                pageId: value
            })
            this.onStopIframeVideo()
        } else {
            this.setState({
                [name]:value
            })
        }
        }
        onStopIframeVideo = () => {
            $("iframe").each(function() { 
                var src= $(this).attr('src');
                $(this).attr('src',src);  
        });
        }
    onGetDigitalbook = () => {
            let mybookId = this.props.location.state !== undefined ? this.props.location.state.mybookId : ''
            if(mybookId !==''  ){
            this.props.firebase.mybook(mybookId).get()
            .then((res)=>{
                let result = res.data()
                this.setState(result)
                // let {
                //     bookFinishAt,
                //     lastPageId,
                //     seenPageId,
                //     bookData,
                //     isFinished,
                //     review,
                //     target,
                //     site,
                //     unique,
                //     digitalBookID,
                //     userAddress,
                //     isTutorial,
                //     publishedCover,
                //     isFinishedAt,
                //     title
                // } = result
                // this.setState({
                //     bookFinishAt,
                //     // lastPageId,
                //     seenPageId,
                //     bookTitle : bookData.bookTitle,
                //     bookData,
                //     isFinished,
                //     review,
                //     target,
                //     site,
                //     programId: unique,
                //     digitalBookID,
                //     userAddress,
                //     isTutorial,
                //     publishedCover,
                //     isFinishedAt,
                //     programTitle:title
                // })
                let {isFinished, review, publishedCover, isTutorial, lastPageId, bookData,
                digitalBookID, username, target, programId, programTitle} = result
                if(isFinished){
                    if(!review){
                        this.props.history.push({
                            pathname: ROUTES.XRBOOK_NONAUTH_REVIEW,
                            state: 
                            {
                                bookTitle: bookData.bookTitle,
                                mybookId: mybookId,
                                digitalBookID: digitalBookID,
                                username : username,
                                target : target,
                                programId : programId,
                                bookData: bookData,
                                programTitle
                            }
                          })
                    }else if(!publishedCover){
                        this.props.history.push({
                            pathname: ROUTES.XRBOOK_NONAUTH_COVER,
                            state: 
                            {
                                bookTitle:bookData.bookTitle,
                                mybookId:mybookId,
                                username :username,
                                digitalBookID:digitalBookID,
                                target :target,
                                programId :programId,
                                bookData:bookData,
                                programTitle
                            }
                          })
                    }
                } else if(isTutorial !== false){
                    $(".bookGuideBg").show();
                    $(".bookGuideBtn2").hide()
                } 
                else if(lastPageId !== 0 ){
                    let result = window.confirm('마지막으로 작성한 페이지로 이동하시겠습니까?')
                    // console.log(this.state)
                    let lastPage = lastPageId%2 === 0 ? lastPageId : lastPageId+1
                    if(result) {
                        this.setState({lastPage})
                        this.onPageListChange('pageId', this.state.lastPage)
                    }}
            })
            .catch((err)=>console.log(err))
    }
        }
    onSaveDigitalbook = () => {
        let {mybookId, bookData} = this.state
        this.props.firebase.mybook(mybookId).update({bookData})
        .then(()=>alert('저장되었습니다.'))
        .catch((err)=>console.log(err))

    }
    
    onFinishDigitalbook = () => {
        let {mybookId, bookData, authUser, isFinishedAt} = this.state
        let sharingList = []
        let createdAt = getTimeString()
        let type = 'digitalbook'
        let authUserEmail = authUser['email']
        let authUserName = authUser['username']
        let authUserUid = authUser['uid']
        for(let i in bookData.pageList){
            if(bookData.pageList[i].isShared && bookData.pageList[i].shareMark){
                //챕터 구별 수정 예정
                let chapter = bookData.pageList[i].chapter || 0
                let chapterKey = `Totalchapter${chapter}`
                let isShowing = true
                this.props.firebase.sharingPages().add({pageId : i, page: bookData.pageList[i], createdAt, type, chapter,
                    mybookId, authUserEmail, authUserName, authUserUid, isShowing })
                this.props.firebase.tlpStatistics().update({
                            [chapterKey] : this.props.firebase.fieldValue.increment(1)
                        })
                
            }
        } 
        this.props.firebase.mybook(mybookId).update({bookData, isFinished : true})
        if(!isFinishedAt){
        this.props.firebase.mybook(mybookId).set({
           isFinishedAt : getTimeString()
        },{merge:true})
    }
    }
    onBookGuide = (type) => {
        let {mybookId} = this.state
        if(type ==='never'){
            this.props.firebase.mybook(mybookId).set({isTutorial : false},{merge:true})
            .then(()=> $(".bookGuideBg").hide())
        } else if (type === 'again'){
            $(".bookGuideBg").hide();
        } else if (type === 'show'){
            $(".bookGuideBg").show();
            $(".bookGuideBtn1").hide();
            $(".bookGuideBtn2").show();
            
        }
    }
    onManualGuide = (type) => {
        if(type ==='hidden'){
            $(".manualBg").hide();
        } else if (type === 'show'){
            $(".manualBg").show();
            
        }
    }
    setBookGuideSlidePrev = () => {
        var lastChild = $(".bookGuideFrame>ul>li").last();
        $(".bookGuideFrame>ul").stop().animate({"margin-left":"0"}, 500, function(){
            $(".bookGuideFrame>ul").prepend(lastChild).css("margin-left", "-100%");
        })
    }
    setBookGuideSlideNext = () => {
        var firstChild = $(".bookGuideFrame>ul>li").eq(0);
        $(".bookGuideFrame>ul").stop().animate({"margin-left":"-200%"}, 500, function(){
            $(".bookGuideFrame>ul").append(firstChild).css("margin-left", "-100%");
        })
    }
    isMobile=()=> {
        return /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
   }
    saveAlert = (e, type) => {
        e.preventDefault()
        let result = window.confirm('디지털북 작성 중인 경우 저장하지 않고 페이지를 이동할 경우 작성하신 내용이 저장되지 않습니다.')
        if(result && type==='user'){
            this.props.history.push({pathname: `${ROUTES.USER}`, state: {tab : 'library'}})
        } else if (result && type==='main'){
            this.props.history.push({pathname: `${ROUTES.TLP_MAIN}`})
        } else{

        }
    }
    render() {
        let {authUser, bookData, titlebyAuth, pageId,pcPageId, mybookId, seenPageId, lastPageId, publishedCover,
             review, published, bookTitle, target, site, programId, digitalBookID, userAddress, windowWidth, windowHeight,programTitle} = this.state
        let pageLeng = bookData.pageList ? bookData.pageList.length : 0
        return (
            <div className="authReaderFrame disabledDrag">
                <div className="bookGuideBg">
                    <div className="bookGuideFrame">
                        <ul>
                            <li className="bookGuide5">
                                <ul className="bookGuideGroup">
                                    <li>
                                        <img className="dbookPcImage" src={dBookImagePc5} alt="디지털 북 사용 가이드 PC 이미지4"/>
                                        <img className="dbookTaMoImage" src={dBookImageTaMo5} alt="디지털 북 사용 가이드 반응형 이미지4"/>
                                    </li>
                                    <li>작성 완료 후 표지 선택과 배송지 정보를 꼭 확인해 주세요</li>
                                    <li>
                                        <span className="textBorder">썸네일 이미지</span>를 선택하여 표지를 적용해 보세요.
                                        인쇄를 진행하는 경우 표지 선택을 완료하기 전에 배송지 정보를 확인하고, 주소가 올바르지 않을 경우
                                        <span className="textBorder">주소 입력/수정</span> 버튼을 클릭하여 주소를 다시 입력해 주세요.
                                        <span className="textBorder">표지 선택 완료</span>버튼을 누르면 나의 XR BOOK이 완성됩니다.
                                    </li>
                                </ul>
                            </li>
                            <li className="bookGuide1">
                                <ul className="bookGuideGroup">
                                    <li>
                                        <img className="dbookPcImage" src={dBookImagePc1} alt="디지털 북 사용 가이드 PC 이미지1"/>
                                        <img className="dbookTaMoImage" src={dBookImageTaMo1} alt="디지털 북 사용 가이드 반응형 이미지1"/>
                                    </li>
                                    <li>목차를 확인하고 페이지를 이동해 보세요</li>
                                    <li>
                                        <span className="textBorder">목차</span>
                                        버튼을 눌러 디지털 북의 목차를 확인해 보세요. 목차 제목을 선택하여 원하는 페이지로 이동할 수 있습니다.
                                        <span className="textBorder">이전</span>,<span className="textBorder">다음</span> 
                                        버튼을 눌러 페이지를 넘겨 보세요. 사용자 작성 공간이 있는 페이지는 작성을 완료해야 다음 페이지로 이동할 수 있습니다.
                                    </li>
                                </ul>
                            </li>
                            <li className="bookGuide2">
                                <ul className="bookGuideGroup">
                                    <li>
                                        <img className="dbookPcImage" src={dBookImagePc2} alt="디지털 북 사용 가이드 PC 이미지2"/>
                                        <img className="dbookTaMoImage" src={dBookImageTaMo2} alt="디지털 북 사용 가이드 반응형 이미지2"/>
                                    </li>
                                    <li>내 생각을 공유하고 원하는 페이지를 책갈피 해 두세요</li>
                                    <li>
                                        <span className="textBorder">공유</span>
                                        버튼을 눌러 XR BOOK 사이트에 나의 생각을 공유할 수 있습니다. my page의 Reflection Gallery 이용 현황 메뉴에서 내가 공유한 이미지들을 관리, 삭제할 수 있습니다. 
                                        상단의 <span className="textBorder">책갈피</span> 
                                        버튼을 눌러 기억 하고 싶은 페이지를 표시해 두세요. 하단의 책갈피 모음 버튼을 클릭하여 그 동안 표시 해 놓은 페이지들을 한 번에 확인할 수 있습니다.
                                    </li>
                                </ul>
                            </li>
                            <li className="bookGuide3">
                                <ul className="bookGuideGroup">
                                    <li>
                                        <img className="dbookPcImage" src={dBookImagePc3} alt="디지털 북 사용 가이드 PC 이미지3"/>
                                        <img className="dbookTaMoImage" src={dBookImageTaMo3} alt="디지털 북 사용 가이드 반응형 이미지3"/>
                                    </li>
                                    <li>나의 생각을 글로 적어보고, 그림으로 표현해 보세요</li>
                                    <li>
                                        <span className="textBorder">글 입력란</span>
                                        의 경우 정해진 칸을 초과하여 작성된 부분은 인쇄 시 내용이 잘릴 수 있습니다. 그림으로 표현해 볼 수 있는 사용자 작성 공간에서는 
                                        오른쪽 상단의 드로잉 <span className="textBorder">툴 박스</span>를 눌러 원하는 배경/붓 색상을 선택할 수 있습니다.
                                    </li>
                                </ul>
                            </li>
                            <li className="bookGuide4">
                                <ul className="bookGuideGroup">
                                    <li>
                                        <img className="dbookPcImage" src={dBookImagePc4} alt="디지털 북 사용 가이드 PC 이미지4"/>
                                        <img className="dbookTaMoImage" src={dBookImageTaMo4} alt="디지털 북 사용 가이드 반응형 이미지4"/>
                                    </li>
                                    <li>작성한 내용은 꼭 저장해 주세요</li>
                                    <li>
                                        페이지를 나가기 전 
                                        <span className="textBorder">저장하기</span>
                                        버튼을 꼭 눌러주세요. 저장하기 버튼을 누르지 않고 페이지를 나가면 작성한 내용이  저장되지 않습니다.
                                    </li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                    <div className="bookGuidePager">
                        <div className="bookGuidePrev" onClick={()=>this.setBookGuideSlidePrev()}>&#60;</div>
                        <div className="bookGuideNext" onClick={()=>this.setBookGuideSlideNext()}>&#62;</div>
                    </div>
                    <div className="bookGuideBtn">
                        <div className="bookGuideBtn1" onClick={()=>this.onBookGuide('never')}>다시 보지 않기</div>
                        <div className="bookGuideBtn1" onClick={()=>this.onBookGuide('again')}>바로 시작하기</div>
                        <div className="bookGuideBtn2" onClick={()=>this.onBookGuide('again')}>닫기</div>
                    </div>
                </div>
                <div className="manualBg" style={{display:'none'}}>
                    <div className="manualDelBtn" onClick={()=>this.onManualGuide('hidden')}>×</div>
                    <Manual headShow={false}/>
                </div>


                <div className="bookTop">
                    <div className="booInfoGroup">
                        <div className="iconFrame">
                            <img src={bookData.iconUrl || XRBOOKLogo } alt='icon'/>
                        </div>
                        <span>{titlebyAuth}</span>
                    </div>
                    <div className="linkGroup">
                        <div className="delinkBtnPc" onClick={()=>this.onManualGuide('show')}>[ 기기 사용법 ]</div>
                        {/* <div>{windowWidth} / {windowHeight}</div> */}
                        <div className="infoLinkBtnMobile" onClick={()=>this.onBookGuide('show')}><FontAwesomeIcon icon={faInfo}/></div>
                        <div className="infoLinkBtnPc" onClick={()=>this.onBookGuide('show')}>[ 도움말 ]</div>
                        <span onClick={(e)=>this.saveAlert(e, 'user')} >MY PAGE</span> 
                        <span onClick={(e)=>this.saveAlert(e, 'main')}>HOME</span>
                    </div>
                </div>
                <div className="bookMiddle">
                    <p>
                        XR BOOK 작성 중
                    </p>
                </div>
                {windowWidth >834 ? 
                <Digitalbook_PC
                 digitalbook={bookData} onPageListChange={this.onPageListChange}
                 pageId={pageId} pcPageId={pcPageId} mybookId={mybookId} seenPageId={seenPageId}
                 lastPageId={lastPageId} windowWidth={windowWidth}
                 onSaveDigitalbook={this.onSaveDigitalbook}/>
                :       
                <Digitalbook_Mobile
                 digitalbook={bookData} onPageListChange={this.onPageListChange}
                 pageId={pageId} mybookId={mybookId} seenPageId={seenPageId} lastPageId={lastPageId}
                 onSaveDigitalbook={this.onSaveDigitalbook} windowWidth={windowWidth}
                 />
                 }
                <div className="bookBottom">
                    <button onClick={()=>this.onSaveDigitalbook()}>저장하기</button>
                    {seenPageId >= pageLeng || pageId >= pageLeng ? 
                    !review ?
                        <Link to={{pathname: `${ROUTES.XRBOOK_REVIEW}`,
                        state: {
                            bookTitle:bookTitle,
                            mybookId: mybookId,
                            digitalBookID:digitalBookID,
                            username : authUser.username,
                            published : published,
                            target : target,
                            site: site,
                            programId : programId,
                            bookData: bookData,
                            userAddress:userAddress,
                            programTitle:programTitle
                            }}} 
                        onClick={()=> this.onFinishDigitalbook()}
                            >
                                프로그램 완료 (리뷰 작성)
                        </Link>
                    : publishedCover === false ?
                        <Link to={{pathname: `${ROUTES.XRBOOK_COVER}`,
                        state: {
                            bookTitle:bookTitle,
                            mybookId: mybookId,
                            username : authUser.username,
                            published : published,
                            digitalBookID:digitalBookID,
                            target : target,
                            site: site,
                            programId : programId,
                            bookData: bookData,
                            userAddress:userAddress,
                            programTitle:programTitle
                            }}} 
                            onClick={()=> this.onFinishDigitalbook()}>
                                프로그램 완료 (표지 선택)
                        </Link>
                    : <Link to={{pathname: `${ROUTES.USER}`, state: {tab : 'library'}}}  onClick={()=> this.onFinishDigitalbook()}>프로그램 완료</Link> : null}
                {/* <div onClick={()=> this.onFinishDigitalbook()}>
                                프로그램</div> */}
                </div>

            </div>
        )
    }
}


const condition = authUser => authUser
// && authUser.roles[ROLES.MEMBER] === ROLES.ADMIN;

export default compose(
    withEmailVerification,
    withAuthorization(condition),
  )(AuthReader);
import React, { Component } from 'react'
import {withFirebase} from '../../../Firebase'
import {Link} from 'react-router-dom'
import dayjs from 'dayjs'
import Published from './Published'

class Publishing extends Component {
    state = {
        myBookList : [],
        myBookListAfter : []
    }
    componentDidMount(){
        this.getMyBookListPrint()
    }
    getMyBookListPrint =()=> {
        // this.props.firebase.mybook('npQXId9DSzYegaDSIlXj')
        this.props.firebase.mybooks()
        .where('isFinished','==',true).where('publishedStatus','in',['ready','shipment', 'noCover','printing']).orderBy('isFinishedAt', "desc")
        .get()
        .then(result => {
            var myBookList = []
            result.forEach(res => {
                var listData = res.data()
                var mybookId = res.id
                myBookList.push({mybookId, ...listData})
            })
            this.setState({myBookList})
        })
    }
    getMyBookList =()=> {
        this.props.firebase.mybooks().where('isFinished','==',true).where('publishedStatus','==',['ready','shipment', 'noCover','printing']).orderBy('isFinishedAt', "desc").limit(20).get()
        .then(result => {
            var lastVisible = result.docs[result.docs.length-1];
            var myBookListAfter = []
            result.forEach(res => {
                var listData = res.data()
                var mybookId = res.id
                myBookListAfter.push({mybookId, ...listData})
            })
            this.setState({myBookListAfter, lastVisible})
            if(myBookListAfter.length<1) alert('배송/인쇄 완료된 건수가 없습니다.')
        })
        .catch(()=>{
            alert('배송/인쇄 완료된 건수가 없습니다.')
        })
    }
    getNextMyBookList =()=> {
        let scrollPointX = window.scrollX
        let scrollPointY = window.scrollY
        let {myBookListAfter, lastVisible} = this.state
        if(lastVisible !==undefined){
        this.props.firebase.mybooks().where('isFinished','==',true).where('publishedStatus','==','finish').orderBy('isFinishedAt', "desc").startAfter(lastVisible).limit(20).get()
        .then(result => {
            var lastVisible = result.docs[result.docs.length-1];
            result.forEach(res => {
                var listData = res.data()
                var mybookId = res.id
                myBookListAfter.push({mybookId, ...listData})
            })
            this.setState({myBookListAfter, lastVisible})
            window.scrollTo(scrollPointX, scrollPointY)
        })
    } else {
            alert('최신 데이터 입니다.')
        }
        
        
    }
    render() {
        let {myBookList, myBookListAfter} = this.state
        let {totalNum} = this.props
        // console.log(this.state.lastVisible)
        return (
            <React.Fragment>
                <p>디지털 북 인쇄/배송 현황 {totalNum}건</p>
                
                <ul className="salesListCate salesList4Cate">
                    <li>등록인
                        {/* (수신인) */}
                    </li>
                    <li>인쇄도서</li>
                    <li>수량</li>
                    <li>표지 선택</li>
                    <li>문자 안내</li>
                    <li>진행 상태</li>
                </ul>
                    {myBookList.length>0 ? myBookList.map(c=> {
                        const {publishedStatus } = c
                        
                    return (
                        <React.Fragment key={c.mybookId}>
                            <Published myBook={c} publishedStatus={publishedStatus} codeUser={c.codeUser}/>
                        </React.Fragment>
                        )
                    })
                : <div>인쇄/배송 진행 중인 현황이 없습니다.</div>}
                {myBookListAfter.length>0 && myBookListAfter.map(c=> {
                    const {publishedStatus} = c   
                    return (
                        <React.Fragment key={c.mybookId}>
                            <Published myBook={c} publishedStatus={publishedStatus} codeUser={c.codeUser}/>
                        </React.Fragment>
                        )
                    })
                }
                {myBookListAfter.length<1 ?<button className="viewMoreListBtn" onClick={()=>this.getMyBookList()}>인쇄/배송 종료 건 20건 보기</button>
                          : <button className="viewMoreListBtn" onClick={()=>this.getNextMyBookList()}>인쇄/배송 종료 건 20건 더보기</button>}
            </React.Fragment>
        )
    }
}
export default withFirebase(Publishing)
import React, { Component } from 'react'
import { withFirebase } from '../../Firebase'

class CreatLink extends Component {
    state = {
        modal : false,
        payUrl:this.props.infoList.payUrl || ''
    }
    createLink = () => {
        let {infoList} = this.props
        let {price, title, target, programInfo, uid, groupTitle} = infoList
        let programId = programInfo.split('_')[0]
        let data = {
            order_id : uid,
            name : `오프라인교육 : ${title} ${target}대상`,
            price : price,
            return_url: 'https://www.xrbook.net/xrbook/userPage',
            params : {type:'CLASS', groupTitle, uid, }
        }
        fetch("/api/v1/payments/createLink", {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify(data),
        })
          .then((response) => {
            response
              .json()
              .then((res) => {
                  this.props.firebase.tlpProgram(uid).update({isPayUrl : false, payUrl:res.url, price})
                  .then(()=>this.setState({payUrl : res.url}))
              })
              .catch((err) => console.log(err));
          })
          .catch((err) => console.log(err));
      }
    render() {
        let {infoList} = this.props
        let {price, title, target, programInfo, uid, urlPaymentData} = infoList
        let {payUrl} = this.state
        const isPrice = price.replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
        let allCancelled = urlPaymentData!==undefined &&  urlPaymentData.remain_price===0 || urlPaymentData!==undefined &&  urlPaymentData.status===1
        let paidPrice = urlPaymentData!==undefined ? urlPaymentData.price.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,"): 0
        let remainPrice = urlPaymentData!==undefined ? urlPaymentData.remain_price.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,"): 0
        let cancelledPrice = urlPaymentData!==undefined ? (urlPaymentData.price-urlPaymentData.remain_price).toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,"): 0
        return (
            <React.Fragment>    
                <div className="corporateCardCont">
                    <ul>
                        <li>
                            <span>가격</span>
                            <span>{isPrice}</span>
                        </li>
                        <li>
                            <span>결제 창에 노출된 상품 명</span>
                            <span>{title} - {target}</span>
                        </li>
                        <li>
                            <span>결제 링크</span>
                            <span>{payUrl ? `${payUrl} (해당 회사/학교의 MY PAGE에서 확인 가능)` : '링크를 생성하세요.'}</span>
                        </li>
                        <li>
                            <span>결제 상태</span>
                            <span>{urlPaymentData!==undefined ? urlPaymentData.status ===1 ? `${paidPrice}원 결제 완료` 
                            : urlPaymentData.status===20 ? `${remainPrice}원 결제 (${cancelledPrice}원 취소)` : urlPaymentData.status
                            : '미진행'}</span>
                        </li>
                        <li>
                            결제 링크를 생성한 적이 없거나(생성), 이미 URL을 통해 결제된 금액이 모두 취소된 경우(갱신) 링크 생성이 가능합니다.
                            결제 취소를 요청한 경우 KCP 상점 관리자에서 결제 취소 후 부트페이에 해당 거래를 취소상태로 변경하세요.
                        </li>
                        <li>
                            <span></span>
                            <span><button disabled={!allCancelled} onClick={()=>this.createLink()}>결제 링크 생성</button></span>
                        </li>
                    </ul>
                </div>
            </React.Fragment>
        )
    }
}

export default withFirebase(CreatLink)
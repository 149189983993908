import React, { Component, useLayoutEffect } from 'react'
import DrawPreviewApp from '../../../Digitalbook/Utils/DrawPreviewApp'
import SunEditor from 'suneditor-react';
import '../../../../constants/suneditor.min.css';
import './DigitalbookToolPage.css'
import './DigitalbookToolPageResp.css'

class DigitalbookPageList extends Component {
    state = {
        pageList: this.props.pageList,
        // page:[],
        pageId: this.props.pageId,
        prevBtn: 'invalidBtn',
        nextBtn: 'invalidBtn',
        bookWidth: 0,
        bookHeight: 0,
        iframeWidth: 5,
        isSafari:false
    }
    componentDidMount() {
        let iframeWidth =  window.innerWidth >834 ? 5 : 1
        this.setState({ bookWidth: window.innerWidth, bookHeight: window.innerHeight, });
        window.addEventListener('resize', this.updateDimensions);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
      }
    updateDimensions = () => {  
        let iframeWidth =  window.innerWidth >834 ? 5 : 1
        this.setState({ bookWidth: window.innerWidth, bookHeight: window.innerHeight, });
      };
    componentDidUpdate(prevProps, prevState) {
        if (prevProps.pageList !== this.props.pageList) {
            if (this.props.pageList.length === 1) {
                this.setState({ prevBtn: 'invalidBtn', nextBtn: 'invalidBtn' })
            }
            else if (this.props.pageList.length > 1 && this.props.pageId < this.props.pageList.length - 1) {
                this.setState({ prevBtn: 'invalidBtn', nextBtn: 'validBtn' })
            }
        }
        if (prevProps.pageId !== this.props.pageId) {
            this.setState({ pageId: this.props.pageId })
            if (this.props.pageList.length === 2 && this.props.pageId === 1) {
                this.setState({ prevBtn: 'validBtn', nextBtn: 'invalidBtn' })
            }
            else if (this.props.pageList.length === 2 && this.props.pageId === 0) {
                this.setState({ prevBtn: 'invalidBtn', nextBtn: 'validBtn' })
            }
            else if (this.props.pageId === 0 && this.props.pageList.length > 2) {
                this.setState({ prevBtn: 'invalidBtn', nextBtn: 'validBtn' })
            }
            else if (this.props.pageList.length > 2 && this.props.pageId === this.props.pageList.length - 1) {
                this.setState({ prevBtn: 'validBtn', nextBtn: 'invalidBtn' })
            } else {
                this.setState({
                    nextBtn: 'validBtn',
                    prevBtn: 'validBtn'
                })
            }
        }
    }

    onPageOrder = (type, idx) => {
        let { onUpdate, pageId } = this.props
        let tempPageList = this.props.pageList.slice()
        let pageListLeng = tempPageList.length
        if (type == 'up' && idx > 0) {
            let copyList = tempPageList.splice(idx, 1)
            tempPageList.splice(idx - 1, 0, copyList[0])
        } else if (type == 'down' && idx < pageListLeng - 1) {
            let copyList = tempPageList.splice(idx, 1)
            tempPageList.splice(idx + 1, 0, copyList[0])
        } else if (type === 'delete') {
            tempPageList.splice(idx, 1)
            if (idx <= pageId) {
                onUpdate('pageId', pageId - 1)
            }
        }
        onUpdate('pageList', tempPageList)
    }
    editSectionOrder = (type, userSectionId) => {
        let { onUpdate, pageId } = this.props
        let tempPageList = this.props.pageList.slice()
        let tempUserSection = tempPageList[pageId].userSection

        let userSectionListLeng = tempPageList[pageId].userSection.length

        if (type == 'up' && userSectionId > 0) {
            let copyList = tempUserSection.splice(userSectionId, 1)
            tempUserSection.splice(userSectionId - 1, 0, copyList[0])
        } else if (type == 'down' && userSectionId < userSectionListLeng - 1) {
            let copyList = tempUserSection.splice(userSectionId, 1)
            tempUserSection.splice(userSectionId + 1, 0, copyList[0])
        } else if (type === 'delete') {
            tempUserSection.splice(userSectionId, 1)
        }
        tempPageList[pageId].userSection = tempUserSection

        onUpdate('pageList', tempPageList)
    }
    onPropsChange = (name, value) => {
        this.setState({
            [name]: value
        })
    }
    deleteEditPage = (idx) => {
        let { pageId, pageList, onUpdate } = this.props
        let currentPageList = pageList.slice()
        currentPageList[pageId].userSection.splice(idx, 1)
        onUpdate('pageList', currentPageList)
    }
    

    render() {
        let { prevBtn, nextBtn, iframeWidth } = this.state
        let { pageId, pageList, onUpdate } = this.props
        let pageListLeng = this.props.pageList.length
        let currentPage = pageList[pageId]
        let canvasWidth = window.innerWidth > 834 ? window.innerWidth * 0.3 : window.innerWidth * 0.8547
        let canvasHeight = canvasWidth * 1.414
        return (
            <div className="output2Frame">
                <form onSubmit={this.handleSubmit} onChange={() => this.handleChange} >
                    <div>
                        <div>
                            {currentPage !== undefined &&
                                <div className="makingPaperFrame" style={{
                                    height: '100%', width: '100%',
                                    backgroundSize: 'contain, contain',
                                    backgroundRepeat: 'no-repeat, no-repeat',
                                    backgroundPosition: 'bottom, right bottom',
                                    backgroundImage: "url(" + currentPage.pageBgImgUrl + ")",
                                    backgroundColor: currentPage.pageBgImgColor
                                }}>
                                    {currentPage.userSection.map((val, idx) => {
                                        let catId = `cat-${idx}`, ageId = `age-${idx}`
                                        return (
                                            <div key={idx}>
                                                {val.type === 'inputText' ?
                                                    <div className="bookContFrame inputTextFrame">
                                                        <p id={ageId}>{val.textTitle}</p>
                                                        <input
                                                            type="text"
                                                            name={catId}
                                                            data-id={idx}
                                                            // id={catId}
                                                            value={currentPage.userSection[idx].writingValue}
                                                            className="writingValue"
                                                            readOnly
                                                        />
                                                    <span style={{display:'flex'}}>
                                                        <span onClick={() => this.editSectionOrder("delete", idx)}>×</span>
                                                        <span onClick={() => this.editSectionOrder("up", idx)}>↑</span>
                                                        <span onClick={() => this.editSectionOrder("down", idx)}>↓</span>
                                                    </span>
                                                    </div>
                                                    : val.type === 'textarea' ?
                                                        <div className="bookContFrame textareaFrame">
                                                            <p id={ageId}>{val.textTitle}</p>
                                                            <textarea
                                                                type="text"
                                                                name={catId}
                                                                data-id={idx}
                                                                style={{resize:'none', overflow:"hidden"}}
                                                                rows={val.rowsLimit}
                                                                value={currentPage.userSection[idx].writingValue.replace(/<br\s*[\/]?>/gi, `\n`)}
                                                                className="writingValue"
                                                                readOnly
                                                            />
                                                            <span style={{display:'flex'}}>
                                                                <span onClick={() => this.editSectionOrder("delete", idx)}>×</span>
                                                                <span onClick={() => this.editSectionOrder("up", idx)}>↑</span>
                                                                <span onClick={() => this.editSectionOrder("down", idx)}>↓</span>
                                                            </span>
                                                            </div> :
                                                        val.type === 'drawingTool' ?
                                                            <div className="bookContFrame drawingToolFrame">
                                                                <p id={ageId}>{val.textTitle}</p>
                                                                <DrawPreviewApp contentHeight={val.contentHeight} bgColor={val.bgColor} />
                                                            <span style={{display:'flex'}}>
                                                                <span onClick={() => this.editSectionOrder("delete", idx)}>×</span>
                                                                <span onClick={() => this.editSectionOrder("up", idx)}>↑</span>
                                                                <span onClick={() => this.editSectionOrder("down", idx)}>↓</span>
                                                            </span>
                                                            </div>
                                                            : val.type === 'textEditor' ?
                                                                <div className="bookContFrame textEditorFrame" style={{ backgroundColor: val.editorBgColor }}>
                                                                    <SunEditor lang="ko" height='auto' setOptions={{ 
                                                                        resizingBar: false,
                                                                        // defaultStyle:'font-size:1vw',
                                                                        defaultStyle:'font-size:1.6rem',
                                                                     }}
                                                                        showToolbar={false} disable={true} setContents={val.textTitle} />
                                                                    <span style={{display:'flex'}}>
                                                                        <span onClick={() => this.editSectionOrder("delete", idx)}>×</span>
                                                                        <span onClick={() => this.editSectionOrder("up", idx)}>↑</span>
                                                                        <span onClick={() => this.editSectionOrder("down", idx)}>↓</span>
                                                                    </span>
                                                                </div>
                                                                : val.type === 'image' ?
                                                                    <div className="bookContFrame imageFrame">
                                                                        <React.Fragment>
                                                                            {val.srcUrl != '' ?
                                                                                <div style={{
                                                                                    width: '100%', display: 'flex', flexDirection: `${val.textDirection}`,
                                                                                    justifyContent: `${val.textJustify}`, alignItems: 'center'
                                                                                }}>

                                                                                    <img src={val.srcUrl} alt={`image${idx}`} width={`${val.contentWidth}%`} />
                                                                                    <span>{val.textarea && val.textarea.split('<br />').map((c, id) => {
                                                                                        return (
                                                                                            <span key={id}>{c}<br /></span>
                                                                                        )
                                                                                    })}
                                                                                    </span>
                                                                                </div>
                                                                                : <p>이미지를 먼저 업로드 해주세요.</p>}
                                                                        </React.Fragment>
                                                                        <span style={{display:'flex'}}>
                                                                            <span onClick={() => this.editSectionOrder("delete", idx)}>×</span>
                                                                            <span onClick={() => this.editSectionOrder("up", idx)}>↑</span>
                                                                            <span onClick={() => this.editSectionOrder("down", idx)}>↓</span>
                                                                        </span>
                                                                    </div>
                                                                    : val.type === 'video' ?
                                                                        <div className="bookContFrame videoFrame" style={{ 
                                                                            // border:'1px solid gray',
                                                                            justifyContent: `${val.textJustify}`, height: `${val.contentWidth / iframeWidth}vw`
                                                                        }}>
                                                                            {val.srcUrl && !val.isSafari ?
                                                                                <div style={{ width: `${val.contentWidth}%` }}>
                                                                                    <iframe src={val.srcUrl} style={{ width: `100%`, height: `100%`, border: 'none' }}
                                                                                     frameBorder="0"  allowFullScreen/>
                                                                                </div>
                                                                                : val.srcUrl2 && val.isSafari ?
                                                                                <div style={{ width: `${val.contentWidth}%` }}>
                                                                                    <iframe src={val.srcUrl2} style={{ width: `100%`, height: `100%`, border: 'none' }}
                                                                                     frameBorder="0"  allowFullScreen/>
                                                                                </div>
                                                                                : <p>영상 url을 먼저 입력 해주세요.</p>}
                                                                            <span style={{display:'flex'}}>
                                                                                <span onClick={() => this.editSectionOrder("delete", idx)}>×</span>
                                                                                <span onClick={() => this.editSectionOrder("up", idx)}>↑</span>
                                                                                <span onClick={() => this.editSectionOrder("down", idx)}>↓</span>
                                                                            </span>
                                                                        </div>
                                                                        : val.type === 'emptySpace' ?
                                                                            <div className="bookContFrame emptySpaceFrame" >
                                                                                <div></div>
                                                                                <span style={{display:'flex'}}>
                                                                                    <span onClick={() => this.editSectionOrder("delete", idx)}>×</span>
                                                                                    <span onClick={() => this.editSectionOrder("up", idx)}>↑</span>
                                                                                    <span onClick={() => this.editSectionOrder("down", idx)}>↓</span>
                                                                                </span>
                                                                            </div>
                                                                            : <span>none</span>}
                                                                            
                                            </div>
                                        )
                                    })
                                    }
                                </div>}
                        </div>
                    </div>
                </form>
            </div>
        )
    }
}
export default DigitalbookPageList
import React, { Component } from 'react'
import {withFirebase} from '../../../Firebase'
import * as ROUTES from '../../../../constants/routes'
import {Link} from 'react-router-dom'
import dayjs from 'dayjs'
import {getTimeDayjs} from '../../../helpers'
import PaidEventList from './PaidEventList'

class VrEventPayment extends Component {
    state = {
        vrEventStatus : this.props.vrEventStatus,
        imgUrl:'',
        deposit : 0,
        vrEventpaidOpen: false,
        vrEventpaidList : [],
        username :  this.props.params !==undefined ?  this.props.params['username'] : '',
        phone :  this.props.params !==undefined ?  this.props.params['phone'] : '',
    }
    componentDidMount(){
        this.props.firebase.user(this.props.params['uid']).get()
        .then(res=>{
            let userData = res.data()
            this.setState({
                username : userData.username,
                phone : userData.number
            })
        })
    }
    getPaidEventList = (order_id, codeUser) => {
        let {vrEventpaidOpen} = this.state
        if(!vrEventpaidOpen) {
            this.props.firebase.mybooks().where('order_id','==',order_id).where('vrEvent','==', true).get()
        .then(result => {
            var vrEventpaidList = []
            result.forEach(res => {
                var listData = res.data()
                var vrEventpaidId = res.id
                vrEventpaidList.push({vrEventpaidId, ...listData})
            })
            this.setState({vrEventpaidList, vrEventpaidOpen:true})
        })
        .catch(err=> alert('잠시 후 다시 시도해 주세요\n'+err))
        } else {
            this.setState({vrEventpaidOpen:false})
        }
        
    }
    render() {
        let { vrEventpayment, vrEventListLeng} = this.props

        let {vrEventpaidList, vrEventpaidOpen, username, phone} = this.state
        // console.log(vrEventpaidList)
        const {params, imgUrl1, imgUrl2, vrEventpaymentId, returnDeposit, order_id, codeUser } = vrEventpayment
        let {createdAt, email} = params
            var iscreatedAtFormatted2 = getTimeDayjs(createdAt)
            // let returnDepositFormatted = deposit.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
        return (
            <div className="salesListFrame">
                <ul className="salesListCont salesList6Cont">
                    <li>{username}</li>
                    <li>{phone}</li>
                    <li>{email}</li>
                    <li>{vrEventListLeng}</li>
                    <li>{iscreatedAtFormatted2}</li>
                    <li>
                        <button onClick={()=>this.getPaidEventList(order_id, codeUser)}>{!vrEventpaidOpen ? `구매리스트 보기` : `구매리스트 닫기`}</button>
                    </li>
                </ul>
                {vrEventpaidOpen && 
                <div>
                {vrEventpaidList &&vrEventpaidList.length>0 ?
                <React.Fragment>
                    <div className="salesListDetailGroup">
                        <table>
                            <thead>
                                <tr className="salesListDetailCate">
                                    <th>사용자</th>
                                    <th>연락처</th>
                                    <th>이메일</th>
                                    <th>배송지</th>
                                    <th>일자</th>
                                    <th>배송 상태</th>
                                    <th>적용</th>
                                    <th>문자</th>
                                </tr>
                            </thead>
                            {vrEventpaidList.map(c=>{
                                return(
                                    <React.Fragment key={c.vrEventpaidId}>
                                        <PaidEventList vrEventpaidList={c}/>
                                    </React.Fragment>
                                )
                            })}
                        </table>
                    </div>
                </React.Fragment>
                :<p>코드를 등록한 사람이 없습니다.</p>}
                </div>}
            </div>
        )
    }
}
export default withFirebase(VrEventPayment)

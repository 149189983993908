import React, {Component} from 'react';
import {Link} from 'react-router-dom'
import { withEmailVerification, withAuthorization } from '../Session';
import { compose } from 'recompose';
import * as ROUTES from '../../constants/routes'
import './Landing.css'
import './LandingResp.css'

class OnlineInquiriesPage extends Component {
    componentDidUpdate() {
        try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    };
      }
    render() {
    return (
        <div className="landingBg" >
            <ul className="landingFrame">
            
                <li className="pwResetTitle">대량 신청 문의가 완료되었습니다.</li>
                <li className="landingBtn">
                    <Link to={ROUTES.TLP_MAIN}>홈으로 돌아가기</Link>
                    <Link to={{pathname: `${ROUTES.USER}`, state: {tab : 'sales'}}} >
                    신청 현황 바로 가기</Link>
                </li>
            </ul>
        </div>

    );
};
}
const condition = authUser => authUser;

export default compose(
  withEmailVerification,
  withAuthorization(condition),
)(OnlineInquiriesPage);
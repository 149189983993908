import React, { Component } from 'react'
import { withFirebase } from '../../../Firebase'
import ImageUpload from '../ImageUpload'
import VideoUpload from '../VideoUpload'
import ProgressBar from 'react-bootstrap/ProgressBar'
import SlideImageItem from '../SlideImageItem'

class XrMakingSection extends Component {
    // Class = 배경이미지1, (이미지2, 텍스트 2, 순서) * n, section
    // Self = 배경이미지1,  (이미지2, 텍스트 2, 순서) * n, section
    // XR BOOK = 배경동영상1,  (이미지2, 텍스트 2, 순서) * n, section
    // 갤러리 - (배경동영상-긴텍스트) *4, section
    // 고객센터 - { 카테고리(배송, 반품/교환, 취소, FAQ),
    //                  텍스트 1, 긴텍스트 1, show여부? }* n, section
    state={
            bgImgUrl:'',
            slideList:[],
            pcImgUrl:'',
            mobileImgUrl:'',
            text1:'',
            text2:'',
            section : 'xrMaking',
            site:'',
            totalBytes : 0,
            bytesTransferred : 0
    }
    componentDidMount(){
        this.getxrMakingInfo()
    }
    onPropsChange = (name, value) => {
        this.setState({
            [name] : value
        })
    }
    onStateChange = e => {
        this.setState({
            [e.target.name] : e.target.value
        })
    }
    addSlideListItem = () => {
        const {pcImgUrl, mobileImgUrl, text1, text2, slideList} = this.state;
        const data = {pcImgUrl:pcImgUrl, mobileImgUrl:mobileImgUrl, text1:text1, text2:text2}
        this.setState({
            pcImgUrl:'', mobileImgUrl:'', text1:'', text2:'', 
            slideList: slideList.concat(data)
        })
    }
    editSlideListItem = (item, id) => {
        const {slideList} = this.state
        var newList = slideList.slice()
        newList[id] = item
        this.setState({slideList : newList})
    }
    deleteSlideListItem = item => {
        const {slideList} = this.state
        var newList = slideList.slice()
        var idx = newList.indexOf(item)
        if (idx >-1) newList.splice(idx, 1)
        this.setState({slideList : newList})
    }
    getxrMakingInfo = () => {
        this.props.firebase.mainSection('xrMaking').get()
        .then(res => {
            const result = res.data()
            this.setState(result)
        })
        .catch(err=> {
            alert('데이터 불러오기를 실패했습니다. \n에러코드 : '+err)
            // window.location.reload()
        })
    }
    submitxrMakingData = e => {
        e.preventDefault()
        const {bgImgUrl, slideList, section, site} = this.state;
        const data = {bgImgUrl, slideList, section, site}
        this.props.firebase.mainSection('xrMaking').set(data,{merge:true})
        .then(()=> {
            alert('Xr Book 제작 정보가 수정되었습니다.')
            this.getxrMakingInfo()
            })
        .catch(err=> {
            alert('오류가 발생했습니다. 잠시 후 다시 시도해 주세요.\n에러코드 : '+err)
        })
    }
    render() {
        const {bgImgUrl, pcImgUrl, mobileImgUrl, text1, text2, section, slideList, totalBytes, bytesTransferred}= this.state
        const isInvalid = 
        pcImgUrl === '' || mobileImgUrl === '' || text1 === '' || text2 === ''
        const isInvalid2 = 
        bgImgUrl === '' || slideList === []
        const progress = isNaN(bytesTransferred/totalBytes) ? 0 : parseInt((bytesTransferred/totalBytes) * 100)
        return (
            <React.Fragment>
                <div className="manageTitle">
                    <h5>XR Making Service Section</h5>
                    <button onClick={this.submitxrMakingData} disabled={isInvalid2}>적용</button>
                </div>
                <div className="adminXrMakingManage">
                    <div> 
                        {/* 배경 영상 업로드 :
                        <div><ProgressBar now={progress} label={`${progress}%`} style={{width:'50vw'}}/></div>
                        <div>
                            <VideoUpload 
                            onUpdate={this.onPropsChange}
                            itemName={'bgImgUrl'}
                            fileName={'main/xrMaking'}
                            />
                        </div>
                        {bgImgUrl !=='' && 
                        <video autoPlay="autoplay" loop="loop" muted width='400px' >
                            <source src={bgImgUrl} type="video/mp4" />
                            Your browser does not support the video tag.
                        </video>} */}
                        <div className="makingSectContent">
                            <div className="sectTextBx">
                                <div>
                                    <p>Title Text</p>
                                    <input type='text' name='text1' value={text1} placeholder='제목을 입력해 주세요' onChange={this.onStateChange}/>
                                </div>
                            
                                <div>
                                    <p>Content Text</p>
                                    <textarea name='text2' value={text2.replace(/(<br>|<br\/>|<br \/>)/g, '\n')} placeholder='내용을 입력해 주세요' onChange={e=> this.setState({text2: e.target.value.replace(/(?:\r\n|\r|\n)/g, '<br />')}) }/>
                                </div>
                            </div>
                            <div className="sectImageBx">
                                <div>
                                    <p>Image (PC)</p>
                                    <ImageUpload 
                                    onUpdate={this.onPropsChange}
                                    itemName={'pcImgUrl'}
                                    fileName={'main/xrMaking'}
                                    />
                                </div>
                                <div>
                                    <p>Image (mobile)</p>
                                    <ImageUpload 
                                    onUpdate={this.onPropsChange}
                                    itemName={'mobileImgUrl'}
                                    fileName={'main/xrMaking'}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="addContentBtn">
                            <button onClick={this.addSlideListItem} disabled={isInvalid}>추가 하기</button>
                        </div>
                        {/* <div>{section}</div> */} 
                        <div className="addedContentPreview">
                        {slideList && slideList.map((c, i)=> {
                            return(
                            <SlideImageItem key={c.pcImgUrl} item={c} onDelete={this.deleteSlideListItem} onEdit={this.editSlideListItem} idx={i}/>
                            )
                        })
                        }
                        {/* <List colors={slideList} onDelete={this.deleteSlideListItem} onUpdate={this.onPropsChange}/> */}
                        </div>

                    </div>

                </div>
            </React.Fragment>
        )
    }
}
export default withFirebase(XrMakingSection)



import React, { Component } from 'react'
import {withFirebase} from '../../Firebase'
import { getTimeDayjs, getPeriod } from '../../helpers'
import dayjs from 'dayjs'
import DayPicker, { DateUtils } from 'react-day-picker';
import { CopyToClipboard } from 'react-copy-to-clipboard'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import SendSMSModal from '../SendSMSModalEvent'

class EventApplicants extends Component {
    constructor(props) {
        super(props);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.handleDayMouseEnter = this.handleDayMouseEnter.bind(this);
        this.handleResetClick = this.handleResetClick.bind(this);
        this.state = this.getInitialState();
      }
      getInitialState() {
        return {
        from: null,
        to: null,
        enteredTo: null, // Keep track of the last day for mouseEnter.
        vrRentStart : this.props.applicantList.vrRentStart === undefined ? '' : this.props.applicantList.vrRentStart,
        vrRentFinish: this.props.applicantList.vrRentFinish === undefined ? '' : this.props.applicantList.vrRentFinish,
        vrRentReturn : this.props.applicantList.vrRentReturn === undefined ? '' : this.props.applicantList.vrRentReturn,
        vrRentStatus : this.props.applicantList.vrRentStatus === undefined ? '대여 예정' : this.props.applicantList.vrRentStatus,
        vrRentBetween : this.props.applicantList.vrRentBetween === undefined ? 0 : this.props.applicantList.vrRentBetween,
        periodModalOpen:false,
        isModalOpen:false
        };
      }
      componentDidMount(){
        if(this.props.applicantList.vrRentStart !== undefined){
            let from = new Date(`${this.props.applicantList.vrRentStart}T12:00:00`)
            this.setState({from})
        }
        if(this.props.applicantList.vrRentFinish !== undefined){
            let to = new Date(`${this.props.applicantList.vrRentFinish}T12:00:00`)
            this.setState({to, enteredTo:to})
        }
      }
      componentDidUpdate(prevProp, prevState){
        if(this.state.from !== null &&prevState.from !== this.state.from){
            let vrRentStart = dayjs(this.state.from).format('YYYY-MM-DD')
            this.setState({vrRentStart})
        }
        if(this.state.to !== null && prevState.to !== this.state.to){
            let vrRentFinish = dayjs(this.state.to).format('YYYY-MM-DD')
            let vrRentBetween = getPeriod(this.state.vrRentStart, vrRentFinish)
            this.setState({vrRentFinish, vrRentBetween }, () =>  this.onSubmit())
        }
        if(prevProp.applicantList !== this.props.applicantList){
            this.setState({
                vrRentStart : this.props.applicantList.vrRentStart === undefined ? '' : this.props.applicantList.vrRentStart,
                vrRentFinish: this.props.applicantList.vrRentFinish === undefined ? '' : this.props.applicantList.vrRentFinish,
                vrRentReturn : this.props.applicantList.vrRentReturn === undefined ? '' : this.props.applicantList.vrRentReturn,
                vrRentStatus : this.props.applicantList.vrRentStatus === undefined ? '' : this.props.applicantList.vrRentStatus,
                vrRentBetween : this.props.applicantList.vrRentBetween === undefined ? 0 : this.props.applicantList.vrRentBetween,
            })
        }
        
    }
      isSelectingFirstDay(from, to, day) {
        const isBeforeFirstDay = from && DateUtils.isDayBefore(day, from);
        const isRangeSelected = from && to;
        return !from || isBeforeFirstDay || isRangeSelected;
      }
      handleResetClick() {
        this.setState({
            from: null,
            to: null,
            enteredTo: null
        });
      }
      handleDayClick(day) {
        const { from, to } = this.state;
        if (from && to && day >= from && day <= to) {
          this.handleResetClick();
          return;
        }
        if (this.isSelectingFirstDay(from, to, day)) {
          this.setState({
            from: day,
            to: null,
            enteredTo: null,
          });
        } else {
          this.setState({
            to: day,
            enteredTo: day,
            periodModalOpen : false
          });
         
        }
      }
    
      handleDayMouseEnter(day) {
        const { from, to } = this.state;
        
        if (!this.isSelectingFirstDay(from, to, day)) {
            let vrRentStart = dayjs(from).format('YYYY-MM-DD')
            let vrRentEnter = dayjs(day).format('YYYY-MM-DD')
            let vrRentBetween = getPeriod(vrRentStart, vrRentEnter) 
          this.setState({
            enteredTo: day,
            vrRentBetween
          });
        }
      }
      onSubmit = () => {
          let {vrRentStart, vrRentFinish, vrRentBetween, vrRentStatus} = this.state;
          this.props.firebase.tlpEvent(this.props.applicantList.uid).update({
            vrRentStart : vrRentStart === 'Invalid Date' ? false : vrRentStart,
            vrRentFinish : vrRentFinish === 'Invalid Date' ? false : vrRentFinish,
            vrRentBetween : vrRentBetween === NaN ? 0 : vrRentBetween,
            vrRentStatus
          })
      }
      TempMsg = () => {
        NotificationManager.info(`주소가 복사되었습니다.`, 'COPIED', 1500, () => {
          alert('callback');
        })
};
onPropsChange = (name, value) => {
    this.setState({
        [name]:value
    })
}
    render() {
        let {applicantList, idx} = this.props
        let {event, applicant, groupTitle, number, route, userAddress, createdAt, vrRentBetween, uid} = applicantList
        let {vrRentStart, vrRentFinish, periodModalOpen, vrRentStatus, isModalOpen,
            from, to, enteredTo,} = this.state
        const modifiers = { start: from, end: enteredTo };
        const disabledDays = { before: this.state.from };
        const selectedDays = [from, { from, to: enteredTo }]
        return (
            <React.Fragment>
                <React.Fragment>
                <td>{idx+1}</td>
                <td>{event}</td>
                <td>{applicant}</td>
                <td>{groupTitle}</td>
                <td>{number}</td>
                {/* <td>{route}</td> */}
                <td>{userAddress ? 
                <CopyToClipboard text={userAddress}
                        onCopy={this.TempMsg}>
                    <span>{userAddress}</span>
                    </CopyToClipboard>
                    :<span>미입력</span>}</td>
                <td>{getTimeDayjs(createdAt)}</td>
                <td><span onClick={()=>this.setState({periodModalOpen:true})}>{vrRentStart ? `${vrRentStart} ~ ${vrRentFinish}` : '대여기간 설정'}</span></td>
                <td>
                    <select value={vrRentStatus} onChange={(e)=>this.setState({vrRentStatus:e.target.value}, ()=>this.onSubmit() )}>
                        <option value='대여 예정'>대여 예정</option>
                        <option value='발송 시작'>발송 시작</option>
                        <option value='대여 중'>대여 중</option>
                        <option value='반납 완료'>반납 완료</option>
                        <option value='연체 중'>연체 중</option>
                    </select>
                </td>
                <td><button onClick={()=>this.setState({isModalOpen:true})}>문자</button></td>
            </React.Fragment>
            {periodModalOpen && 
                <div className="salesDetailPopBg">
                  <div className="salesDetailPopFrame">
                    <div className="salesDetailPopTitle">
                      <h5>대여기간 설정</h5>
                      <div onClick={()=>this.setState({periodModalOpen:false})}>×</div>
                    </div>
                    <p>{vrRentBetween ? vrRentBetween+1 : 0}일</p>
                    <DayPicker
                          className="Range"
                          numberOfMonths={2}
                          fromMonth={from}
                          selectedDays={selectedDays}
                          disabledDays={disabledDays}
                          modifiers={modifiers}
                          onDayClick={this.handleDayClick}
                          onDayMouseEnter={this.handleDayMouseEnter}
                        />
                  </div>
                </div>}
                {isModalOpen && <SendSMSModal number = {number} onUpdate = {this.onPropsChange} receiver={applicant} mybookId={uid}/>}
                <NotificationContainer/>
                </React.Fragment>
        )
    }
}

export default withFirebase(EventApplicants)
import React, { Component, useEffect, useState } from 'react';
import { withRouter } from 'react-router-dom';
import { compose } from 'recompose';
import {withAuthorization} from '../Session'
import * as firebase from "firebase";
import { getTimeDayjs } from '../helpers';
import { SignUpLink } from '../SignUp';
import { PasswordForgetLink } from '../PasswordForget';
import { withFirebase } from '../Firebase';
import './SignIn.css'
import * as ROUTES from '../../constants/routes';
import BootPay from "bootpay-js"
import dayjs from "dayjs"


class SignInPage extends Component {
  componentDidUpdate() {
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    };
  }
  render() {
    return (
      <div className="signBg">
        <div className="signFrame">
          <h1>LOGIN</h1>
          <span></span>
          <SignInForm />
          {/* <SignInGoogle /> */}
          <div className="linkGoup">
            <SignUpLink />
            <PasswordForgetLink />
          </div>
        </div>
      </div>
    )
  }
}


const INITIAL_STATE = {
  email: '',
  password: '',
  error: null,
};

const ERROR_CODE_ACCOUNT_EXISTS =
  'auth/account-exists-with-different-credential';

const ERROR_MSG_ACCOUNT_EXISTS = `
  An account with an E-Mail address to
  this social account already exists. Try to login from
  this account instead and associate your social accounts on
  your personal account page.
`;
//The user account has been disabled by an administrator.
class SignInFormBase extends Component {
  constructor(props) {
    super(props);

    this.state = { ...INITIAL_STATE };
  }
//로그인 오류
  onSubmit = event => {
    const { email, password } = this.state;
    this.props.firebase
      .doSignInWithEmailAndPassword(email, password)
      .then(authUser => {
        // console.log(authUser)
        let userRef = this.props.firebase.user(authUser.user.uid)
        let transaction = this.props.firebase.db.runTransaction(t => {
          return t.get(userRef)
            .then(doc => {
              if(!doc.exists){
              t.set(userRef, {
                email :authUser.user.email,
                roles:{member:'member'},
                isCertificated:false,
                certificationInfo:{},
                username:'',
                status : 'active'
               },{merge:true});
              } else {
                let res = doc.data()
                let {username, certificationInfo, isCertificated, number, email} = res
                let birth = isCertificated ? certificationInfo.birth : undefined
                var isCreatedAtFormatted2 = getTimeDayjs(birth)
                BootPay.startLoginSession({
                  id: doc.id,
                  username: username,
                  birth: birth !== undefined ? isCreatedAtFormatted2 : null ,
                  phone: number ? number : null,
                  email: email,
                  gender: null,
                  area: null
              });
                if(res.username===''){
                  // alert('추가 정보를 기입해주세요.')
                  this.props.history.push({pathname: `${ROUTES.USER}`})
                }
              }
          });
        })
        // return this.props.firebase.user(authUser.user.uid).set({
        //   // token: token
        // }, {merge:true});
      })
      .then(() => {
        this.setState({ ...INITIAL_STATE });
        this.props.history.goBack(2)
      })
      .catch(error => {
        if (error.code === 'auth/invalid-email') {
          error.message = '가입된 메일 주소가 아니거나 사용 가능한 메일이 아닙니다.';
        } else if (error.code === 'auth/user-disabled') {
          error.message = '탈퇴 혹은 비활성화 된 계정입니다.';
        } else if (error.code === 'auth/user-not-found') {
          error.message = '유효한 사용자가 아닙니다.';
        } else if (error.code === 'auth/wrong-password') {
          error.message = '비밀번호가 일치하지 않습니다.';
        } else if(error.code)  {
          error.message = `잠시 후 다시 시도해주세요.`
        }
        this.setState({ error });
      });

    event.preventDefault();
  };

  onChange = event => {
    this.setState({ [event.target.name]: event.target.value.trim() });
  };

  render() {
    const { email, password, error } = this.state;
    const isInvalid = password === '' || email === '';

    return (
      <form className="inputGroup" onSubmit={this.onSubmit}>
        <input
          name="email"
          value={email}
          onChange={this.onChange}
          type="text"
          placeholder="아이디 : address@example.com"
          autoCapitalize='off'
        />
        <input style={{color:'black'}}
          name="password"
          value={password}
          onChange={this.onChange}
          type="password"
          placeholder="비밀번호"
        />

        {error && <p>{error.message}</p>}

        <button disabled={isInvalid} type="submit">
          로그인
        </button>

    
      </form>
    );
  }
}


const SignInForm = compose(
  withRouter,
  withFirebase,
)(SignInFormBase);

// const SignInGoogle = compose(
//   withRouter,
//   withFirebase,
// )(SignInGoogleBase);

const condition = authUser => !authUser;
export default compose(
  withAuthorization(condition),
)(SignInPage);

export { SignInForm
  // , SignInGoogle
 };

import React, { Component } from 'react'
import { withFirebase } from '../../Firebase'
import {getTimeDayjs} from '../../helpers'
import dayjs from 'dayjs'

class reviewItem extends Component {
    state = {
        isShowing : this.props.isShowing
    }
    setShowing = (type, sharingPageId) => {
        if (type === 'showing'){
            let result = window.confirm('해당 리뷰를 보이기 처리하시겠습니까?')
            if(result){
                this.props.firebase.review(sharingPageId).set({show : true},{merge:true})
                this.setState({isShowing : true})
            }
        } else if( type === 'hidden') {
            let result = window.confirm('해당 리뷰를 숨기기 처리하시겠습니까?')
            if(result){
                this.props.firebase.review(sharingPageId).set({show : false},{merge:true})
                this.setState({isShowing : false})
            }
        }
    }

    render() {
        let {isShowing} = this.state
        let {item, deleteReviewItem, idx, modalOpen} = this.props
        let {reviewId, target, reviewTitle, username ,createdAt
        } = item
     var isCreatedAtFormatted2 = getTimeDayjs(createdAt)
        return (
               <React.Fragment>
                    <td>{target}용</td>
                    <td>{username}</td>
                    <td><span className="adminReviewsContText" onClick={()=>modalOpen(true, item)}>{reviewTitle}</span></td>
                    <td>{isCreatedAtFormatted2}</td>
                    <td>
                        <div className="adminReviewsBtnBx">
                            {isShowing ? <div onClick={()=>this.setShowing('hidden', reviewId)}>숨기기</div>
                            : <div onClick={()=>this.setShowing('showing', reviewId)}>보이기</div>}
                            <div onClick={()=>deleteReviewItem(reviewId, idx)}>삭제</div>
                        </div>
                    </td>
                </React.Fragment>
        )
    }
}
export default withFirebase(reviewItem)

import React, { Component } from "react";
import { withFirebase } from "../../Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import OrderProgramPage from "./OrderProgramPage";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css";
import $ from "jquery";
import ServiceCenter from '../../ServiceCenter'
import ClassInfo from "../../Guide/ClassInfo";
import XRBOOKIconOpacity0 from '../../images/XRBOOKIconOpacity0.png'

class OrderProgram extends Component {
  state = {
    programData: this.props.classList,
    programID: 0,
    show: true,

    showTab: "info",
  };
  componentDidMount() {
    try {
      // trying to use new API - https://developer.mozilla.org/en-US/docs/Web/API/Window/scrollTo
      window.scroll({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    } catch (error) {
      // just a fallback for older browsers
      window.scrollTo(0, 0);
    };
    const { programData, programID } = this.state;
    // console.log(programData[programID])
    if (programData[programID] === undefined) {
      this.getClassList();
    }
  }
  getClassList = () => {
    this.props.firebase
      .programs()
      .orderBy("order")
      .get()
      .then((result) => {
        var programData = [];
        result.forEach((res) => {
          var listData = res.data();
          var pid = res.id;
          programData.push({ pid, ...listData });
        });
        this.setState({ programData });
      });
  };
  onStatetoggle = () => {
    this.setState({
      show: !this.state.show,
    });
  };
  changeTab = (data) => {
    this.setState({
      showTab: data,
    });
  };

  render() {
    const { programData, programID, show, showTab } = this.state;
    const showingItem =
      programData[programID] !== undefined ? programData[programID] : "";
    const title = showingItem !== undefined ? showingItem.title : "";
    const target = showingItem !== undefined ? showingItem.target : "";
    const progressTime =
      showingItem !== undefined ? showingItem.progressTime : "";
    const mainUrl = showingItem !== undefined ? showingItem.mainUrl : "";
    const pcUrl = showingItem !== undefined ? showingItem.pcUrl : "";
    const mobileUrl = showingItem !== undefined ? showingItem.mobileUrl : "";
    const content = showingItem !== undefined ? showingItem.content : "";

    // $(".orderTabGroup>li").first().addClass("activeBtn");
    // $(".orderTabCont>div").hide();
    // $(".orderTabCont>div.orderTab01").show();
    // $(".orderTabGroup>li").click(function(){
    //     var selTab = $(this).attr("rel");
    //     console.log(selTab);
    //     $(".orderTabCont>div[class^='orderTab0']").hide();
    //     $("."+selTab).show();
    //     $(".orderTabGroup>li").removeClass("activeBtn");
    //     $(this).addClass("activeBtn");
    // });

    return (
      <div>
        {show ? (
          <div>
            <div className="orderIntroFrame">
              <div className="rightFrame">
                <h5>{title}</h5>
                <div className="orderMainImgBx"> 
                <img src={!mainUrl || mainUrl ==='' || mainUrl ===undefined ? XRBOOKIconOpacity0: mainUrl} alt="상품 이미지" />
                </div>
                <div className="thumbGroup">
                  {programData &&
                    programData.map((c, id) => {
                      return (
                        <div
                          className="thumbBtn"
                          key={id}
                          style={
                            c.target === target
                              ? { border: "1.5px solid black" }
                              : null
                          }
                          onClick={() =>
                            c.status
                              ? this.setState({ programID: id })
                              : alert(`프로그램 준비중입니다.`)
                          }
                        >
                          {c.target}대상
                        </div>
                      );
                    })}
                </div>
              </div>
              <div className="leftFrame">
                <h5>
                  {target}대상 <span>Off-line Learning Program</span>
                </h5>
                <ul className="textGroup clpTextGroup">
                  <li>
                    <span className="cate">대상자</span>
                    <span className="cont">{target}</span>
                  </li>
                  <li>
                    <span className="cate">교육 기간</span>
                    <span className="cont">1일</span>
                  </li>
                  <li>
                    <span className="cate">러닝 타임</span>
                    <span className="cont">{progressTime}시간</span>
                  </li>
                  {/* <li>
                    <span className="cate">시범 학습</span>
                    <a href="">프로그램 맛보기</a>
                  </li> */}
                </ul>
                <div
                  className="applyBtn"
                  onClick={() => this.setState({ show: !show })}
                >
                  신청문의
                </div>
              </div>
            </div>

            <ul className="orderTabGroup">
              <li
                className={`tabBtn01 ${
                  showTab === "info" ? "activeBtn" : null
                }`}
                onClick={() => this.changeTab("info")}
              >
                프로그램 상세 정보
              </li>
              <li
                className={`tabBtn03 ${
                  showTab === "ClassInfo" ? "activeBtn" : null
                }`}
                onClick={() => this.changeTab("ClassInfo")}
              >
                수강 가이드
              </li>
              <li
                className={`tabBtn02 ${
                  showTab === "shipment" ? "activeBtn" : null
                }`}
                onClick={() => this.changeTab("shipment")}
              >
                배송/취소/환불 규정
              </li>
            </ul>
            <div className="orderTabCont">
              {showTab === "info" ? (
                <div className="orderTab01">
                  {/* <SunEditor lang="ko" 
                            disable={true}
                            height='100%'
                            showToolbar={false}
                            name="my-editor" 
                            setContents={content}
                            /> */}
                  <img className="contPcImage" src={pcUrl} alt="상품 이미지" />
                  <img
                    className="contMobileImage"
                    src={mobileUrl}
                    alt="상품 이미지"
                  />
                </div>
              ) : showTab === "shipment" ? (
                <ServiceCenter isOrder={true}/>
              ) : showTab === "ClassInfo" ? 
              <ClassInfo headShow={false}/>
              :null}
            </div>
          </div>
        ) : (
          <OrderProgramPage
            selectedProgram={showingItem}
            onUpdate={this.onStatetoggle}
            authUser={this.props.authUser}
          />
        )}
      </div>
    );
  }
}
export default compose(withRouter, withFirebase)(OrderProgram);

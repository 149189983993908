import React, { Component } from 'react';
import {getTimeString, getTimeDayjs} from '../../helpers'
import PageCheckItem from '../BookItem/PageCheckItem'
// import {sendTempMsg} from '../Utils/sendTempMsg'
import {NotificationContainer, NotificationManager} from 'react-notifications';
import 'react-notifications/lib/notifications.css';
import '../DObook.css'
import '../DObookResp.css'
import $ from "jquery";

class Digitalbook_PC_Check extends Component {
    state={
        pageList: [],
        pageListFront:[],
        pageListBack:[],
        pageHeaderList: [],
        bookmarkList : [],
        pageId:0,
        pcPageId:0,
        lastPageId: 0,
        prevBtn : 'invalidBtn',
        nextBtn : 'invalidBtn',
        invalidFrontBtn:false,
        invalidBackBtn:false,
        isPageHeader : false,
        isBookmark : false
    }
    componentDidMount = ()=> {
        this.setState({
            pageList : this.props.digitalbook.pageList,
            pageId : this.props.pageId,
            // pcPageId :parseInt((this.props.pageId+1)/2),
        }
        // ,()=> this.onTextToPage(this.props.pageId)
        )
        // console.log('mountP :'+this.props.pageId + ',' + parseInt((this.props.pageId+1)/2))
    }
    componentDidUpdate(prevProps, prevState){
        if(prevProps.digitalbook !== this.props.digitalbook){
            this.setState({
                pageList : this.props.digitalbook.pageList
            })
        }
        if(prevState.pageList !== this.state.pageList){
            
        let pageListFront = this.state.pageList.filter((c,id)=>id%2===0)
        let pageListBack = this.state.pageList.filter((c,id)=>id%2===1)
        let pageHeaderList = this.state.pageList.filter(c=> c.pageHeader !== 'hidden')
        let bookmarkList = this.state.pageList.filter(c=> c.bookmark === true)
        // console.log(pageListFront)
        // console.log(pageListBack)
            if(pageListBack.length === pageListFront.length){
                pageListBack.push({userSection:[], pageTitle:'', pageHeader:'hidden',pageBgImgUrl:'',pageBgImgColor:'white'})
            }
            this.setState({
                pageListFront, pageListBack, pageHeaderList, bookmarkList
            },()=> this.onTextToPage(this.props.pageId))

        this.props.onPageListChange('pageList', this.state.pageList)
        }
    if(prevProps.lastPageId !== this.props.lastPageId){
        this.setState({
            lastPageId:this.props.lastPageId
        })
    } 
    if(prevProps.pageId !== this.props.pageId){
        let id = this.props.pageId
        // console.log(this.props.pageId)
        this.setState({
            pageId : id,
            // pcPageId :parseInt((this.props.pageId+1)/2),
        }
        , () =>
        this.onTextToPage(id)
        // console.log('props '+prevProps.pageId, this.props.pageId)
        )
        
    }  

    if(this.state.lastPageId !== 0 && prevState.lastPageId !== this.state.lastPageId
        && this.state.lastPageId < this.state.pageListFront.length){
        let id = this.state.lastPageId
        // console.log('here!')
        // console.log(prevState.lastPageId, this.state.lastPageId)
    }
}
handleFrontChange = (e) => {
    if (["writingValue"].includes(e.target.className) ) {
      let currentPageList = this.state.pageList.slice()
      currentPageList[this.state.pageId].userSection[e.target.dataset.id][e.target.className] = e.target.value
      this.setState({
           pageList: currentPageList, 
        })
    } else if(["textarea"].includes(e.target.className)) {
        let currentPageList = this.state.pageList.slice()
      currentPageList[this.state.pageId].userSection[e.target.dataset.id]['writingValue'] = e.target.value.replace(/(?:\r\n|\r|\n)/g, '<br />')
      this.setState({
           pageList: currentPageList, 
        })
    }
    this.props.onPageListChange('lastPageId',this.state.pageId)
  }
  onPageChange = type => {
    $(".headingTextBox").removeClass("showHeading");
    $(".bookMarkbox").removeClass("show").addClass("hidden");
    let {pageId, pageList, pcPageId}=this.state
    let pcId = parseInt((pageId+1)/2)
    let halfLeng = parseInt((pageList.length+1)/2)
    if (type ==='prev' && pcId >0){
        this.props.onPageListChange('pageIdPC',-1)
        //이전페이지 함수 추가
    } else if (type ==='next' && pcId < halfLeng) {
        this.props.onPageListChange('pageIdPC',1)
    }
}
setBookmark = (pageId) => {
    $(".headingTextBox").removeClass("showHeading");
    $(".bookMarkbox").removeClass("show").addClass("hidden");
        let currentPageList = this.state.pageList.slice()
        if(currentPageList[pageId]['bookmark'] === undefined || currentPageList[pageId]['bookmark'] === false ){
            currentPageList[pageId]['bookmark'] = true
            currentPageList[pageId]['bookmarkAt'] = getTimeString()
            this.sendTempMsg('checkedBM')
            this.setState({
                pageList: currentPageList,  
             }, this.sendTempMsg('checkedBM'))
             this.props.onSaveDigitalbookPage(currentPageList)
        }else if(currentPageList[pageId]['bookmark'] === true){
            currentPageList[pageId]['bookmark'] = false
            currentPageList[pageId]['bookmarkAt'] = ''
            this.setState({
                pageList: currentPageList, 
             }, this.sendTempMsg('unCheckedBM'))
             this.props.onSaveDigitalbookPage(currentPageList)
        }
        
       
}
deleteBookmark = page => {
    $(".headingTextBox").removeClass("showHeading");
    $(".bookMarkbox").removeClass("show").addClass("hidden");
    let currentPageList = this.state.pageList.slice()
    let idx = currentPageList.indexOf(page)
    currentPageList[idx]['bookmark'] = false
    this.setState({
        pageList: currentPageList, 
     }, this.sendTempMsg('unCheckedBM'))
     this.props.onSaveDigitalbookPage(currentPageList)
}
setSharemark = (pageId) => {
    $(".headingTextBox").removeClass("showHeading");
    $(".bookMarkbox").removeClass("show").addClass("hidden");
    let currentPageList = this.state.pageList.slice()
    if(currentPageList[pageId]['shareMark'] === undefined || currentPageList[pageId]['shareMark'] === false ){
        currentPageList[pageId]['shareMark'] = true
        currentPageList[pageId]['shareMarkAt'] = getTimeString() 
        this.setState({
            pageList: currentPageList, 
         }, this.sendTempMsg('checkedSM'))
        this.props.onSaveSharingPage(pageId, currentPageList[pageId], currentPageList)
    }else if(currentPageList[pageId]['shareMark'] === true) {
        alert('페이지 공유 취소는 MY PAGE - Reflection Gallery 이용 현황에서 가능합니다.')
    }
    
    
}
sendTempMsg = (type) => {
    return () => {
      switch (type) {
        case 'checkedBM':
          NotificationManager.info('Notification', '책갈피 저장', 1500, () => {
            alert('callback');
          });
          break;
        case 'unCheckedBM':
            NotificationManager.error('Notification', '책갈피 삭제', 1500, () => {
                alert('callback');
              });
          break;
          case 'checkedSM':
          NotificationManager.info('Notification', '페이지 공유', 1500, () => {
            alert('callback');
          });
          break;
        case 'unCheckedSM':
            NotificationManager.error('Notification', '페이지 공유 취소', 1500, () => {
                alert('callback');
              });
          break;
      }
    };
};
onPropsChange = (name, value) => {
this.setState({
    [name] : value
})
}
onTextToPage = (id) => {
    $(".headingTextBox").removeClass("showHeading");
    $(".bookMarkbox").removeClass("show").addClass("hidden");
    $(".bookMarkList").removeClass("listShow");
    if(id%2 === 0){
        let lastPage=parseInt((id)/2)-1
        if(parseInt((id)/2) >= parseInt(this.state.pageList.length/2)){       
            $('.bookPagePC').removeClass('ready flipped active pointerEventsNone pageShow');
            $(".bookPagePC"+parseInt((id)/2)).addClass("active");
            $(".bookPagePC"+lastPage).prevAll().addClass("flipped pointerEventsNone");
            $(".bookPagePC"+lastPage).addClass("flipped");
            $(".bookPagePC"+lastPage).prev().prev().addClass("pageShow");
        } else {
            $('.bookPagePC').removeClass('ready flipped active pointerEventsNone pageShow');
            $(".bookPagePC"+parseInt((id)/2)).addClass("active");
            $(".bookPagePC"+parseInt((id)/2)).prevAll().addClass("flipped pointerEventsNone");
            $(".bookPagePC"+lastPage).prevAll().addClass("flipped pointerEventsNone");
            $(".bookPagePC"+lastPage).removeClass("pointerEventsNone");
            $(".bookPagePC"+lastPage).addClass("flipped");
            $(".bookPagePC"+lastPage).prev().addClass("pageShow");
            $(".bookPagePC"+parseInt((id)/2)).next().addClass("ready");
        }
    } else {  
        let lastPage=parseInt((id+1)/2)-1
        if(parseInt((id+1)/2) === parseInt(this.state.pageList.length/2)){
            $('.bookPagePC').removeClass('ready flipped active pointerEventsNone pageShow');
            $(".bookPagePC"+lastPage).prevAll().addClass("flipped pointerEventsNone");
            $(".bookPagePC"+lastPage).addClass("flipped");
            $(".bookPagePC"+lastPage).prev().addClass("pageShow");
        } else {
            $('.bookPagePC').removeClass('ready flipped active pointerEventsNone pageShow');
            $(".bookPagePC"+parseInt((id+1)/2)).addClass("active");
            $(".bookPagePC"+parseInt((id+1)/2)).prevAll().addClass("flipped pointerEventsNone");
            $(".bookPagePC"+lastPage).prevAll().addClass("flipped pointerEventsNone");
            $(".bookPagePC"+lastPage).removeClass("pointerEventsNone");
            $(".bookPagePC"+lastPage).addClass("flipped");
            $(".bookPagePC"+lastPage).prev().addClass("pageShow");
            $(".bookPagePC"+parseInt((id+1)/2)).next().addClass("ready");
        }
    }
}
onTextBox = () => {
    $(".headingTextBox").toggleClass("showHeading");
    $(".bookMarkbox").removeClass("show").addClass("hidden");
    $(".bookMarkList").removeClass("listShow");
}
onBookMarkBox = () => {
    $(".headingTextBox").removeClass("showHeading");
    if($(".bookMarkbox").hasClass("show")){
        $(".bookMarkbox").removeClass("show").addClass("hidden");
        $(".bookMarkList").removeClass("listShow");
    } else if ($(".bookMarkbox").hasClass("hidden")){
        $(".bookMarkbox").removeClass("hidden").addClass("show");
        $(".bookMarkList").addClass("listShow");
    }
}
onMoveto = (pageNum) => {
    this.props.onPageListChange('pageId',parseInt(((pageNum+1)/2))*2)
    if(this.props.pageId===parseInt(((pageNum+1)/2))*2){
        $(".headingTextBox").removeClass("showHeading");
    }
}
    render() {
        let {prevBtn, nextBtn, pageId, pageList, pcPageId, pageListFront, pageListBack,
            isPageHeader, isBookmark, pageHeaderList, bookmarkList, invalidFrontBtn, invalidBackBtn } = this.state
        let totalPageNum = parseInt((pageId+1)/2)*2
        let pcId = parseInt((pageId+1)/2)
        // console.log(this.state.pageId, this.props.pageId)
        return (
            <React.Fragment>
                <div className="previewFrame pcPreviewFrame">    
                    {/* 목차 보이기/안보이기 클래스명 변경은 아래에서 하시면 됩니다. */}
                    <div className="bookPageFrame">
                        <div className="headingTextBox">
                            {/* <div className={isPageHeader ? 'showHeadingText':'hiddenHeadingText'}> */}
                            {pageHeaderList&& pageHeaderList.map((c, id)=> {
                                let pageNum = this.state.pageList.indexOf(c)
                                let isValidPage = pageNum > this.props.seenPageId
                                return(
                                    <div
                                    onClick={()=> !isValidPage ? 
                                        this.onMoveto(pageNum)
                                    : alert('선택된 페이지 이전에 작성되지 않은 항목이 있어 열람할 수 없습니다.')}
                                    key={id}
                                    className={c.pageHeader === 'header1' ?   `heading1`
                                            : c.pageHeader === 'header2' ? `heading2`
                                            : c.pageHeader === 'header3' ?  `heading3`
                                            : c.pageHeader === 'hidden' ?  `noHeading`
                                            : `heading5`}
                                    // style={Object.assign( !isValidPage? {textDecoration:'underline'} : {textDecoration:'none'}, c.pageHeader === "header1" ? {color:'red'}
                                    // : c.pageHeader === "header2" ? {color:'orange'}
                                    // : c.pageHeader === "header3" ? {color:'green'}
                                    // :{color:'black'})}
                                    >{c.pageTitle}</div>
                                )
                            })
                            }
                            {/* </div> */}
                        </div>
                        <div className="scene">
                            <div className="bookPageGroup">
                                {pageListFront && pageListFront.map((c,id) => {
                                    return (
                                    <div key={id}
                                    className={id === 0 ?   `bookPagePC bookPagePC${id} active`
                                                        : `bookPagePC bookPagePC${id}`}>
                                    
                                    <form onSubmit={this.handleSubmit} className="frontPageBox">
                                            <PageCheckItem pageItem={c} handleChange={this.handleFrontChange} drawingChange={this.drawingFrontChange} isChanged={()=>this.props.onPageListChange('lastPageId',this.state.pageId)}
                                            setBookmark={()=>this.setBookmark((id*2))} totalPageNum={`${(id*2)} / ${totalPageNum}`} pageId={(id*2)}
                                            mybookId={this.props.mybookId} showBookmark='front' setSharemark={()=>this.setSharemark(id*2)} />
                                        </form>
                                        <form onSubmit={this.handleSubmit}className="backPageBox">                      
                                            <PageCheckItem pageItem={pageListBack[id]} handleChange={this.handleBackChange} drawingChange={this.drawingBackChange} isChanged={()=>this.props.onPageListChange('lastPageId',this.state.pageId)}
                                            setBookmark={()=>this.setBookmark((id*2)+1)} totalPageNum={`${(id*2)+1>totalPageNum ? '-' : (id*2)+1} / ${totalPageNum}`} pageId={(id*2)+1}
                                            mybookId={this.props.mybookId} showBookmark='back' setSharemark={()=>this.setSharemark((id*2)+1)}/>
                                        </form>   
                                    </div>
                                    )})
                                }
                            </div>
                        </div>
                    </div>
                    <div className="utilityBtnGroup">
                        <div className="headingBtnBox">
                            <span className="headingBtn"
                            onClick={()=>this.onTextBox()}
                            // onClick={()=>this.onPropsChange('isPageHeader', !isPageHeader)}
                            >목차</span>
                        </div>
                        <div className="bookMarkListBox">
                            <div className="bookMarkList">
                                <span className="bookMarkbox hidden">
                                {bookmarkList.length>0 ? bookmarkList.map((c, id)=> {
                                    let pageNum = this.state.pageList.indexOf(c)
                                    let isBookmarkAt = getTimeDayjs(c.bookmarkAt)
                                    return(
                                    <ul key={id}>
                                        <li onClick={()=>this.onMoveto(pageNum)}>{c.pageTitle}</li>
                                        <li>
                                            <span>p.{pageNum}</span>
                                            <span>{isBookmarkAt}</span>
                                            <span  onClick={()=> this.deleteBookmark(c)}>×</span>
                                        </li>
                                    </ul>
                                    )
                                }) : <ul><li>저장된 책갈피가 없습니다.</li></ul>
                                }
                                </span>
                            </div>
                            <div className="bookMarkListBtn" onClick={()=>this.onBookMarkBox()}>책갈피</div>
                        </div>
                        <div className="bookBtnBox">
                            <span className="curPageSign">{pcId} / {pageListFront.length}</span>
                            <div>
                                <span className={`${prevBtn} prevBtn`} onClick={()=>this.onPageChange('prev')}>이전</span>
                                <span className={`${nextBtn} nextBtn`} onClick={()=>this.onPageChange('next')}>다음</span>
                            </div>
                        </div>
                    </div>
                </div>
            {/* 페이지 하단 메뉴*/}
            <NotificationContainer/>
         </React.Fragment>
        )
    }
}
export default Digitalbook_PC_Check
import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { withFirebase } from '../../../Firebase';
import * as ROUTES from '../../../../constants/routes';
import DayPicker, { DateUtils } from "react-day-picker";
import "react-day-picker/lib/style.css";
import { getTimeString } from '../../../helpers';

class FacilitatorItem extends Component {
    state = {
      user: this.props.user,
      selectedDays: this.props.user.selectedDays,
      show:false
    };
    componentDidUpdate(prevProps, prevState){
      if(prevProps.user !== this.props.user){
        this.setState({
          selectedDays : this.props.user.selectedDays || []
        })
      }
    }
  onPropsChange = (name, value) => {
    this.setState({
      [name]:value
    })
  }

  render() {
    const { user, show, selectedDays } = this.state;
    let idx = this.props.idx
    // console.log(this.state.user)
    return (
      <React.Fragment>
        <td>
          {idx}
        </td>
              <td>
                {user.email}
              </td>
              <td>
                {user.username}
              </td>
              <td>
              <button onClick={()=>this.setState({show:!show})}>강의 날짜 관리</button>
              {show && <DateItem onPropsChange={this.onPropsChange} selectedDays={selectedDays} uid = {user.uid} firebase={this.props.firebase}/>}
              </td>
      </React.Fragment>
    );
  }
}

export default withFirebase(FacilitatorItem);

class DateItem extends Component {
  constructor(props) {
    super(props);
    this.handleDayClick = this.handleDayClick.bind(this);
  }
  state = {
    selectedDays: this.props.selectedDays,
    uid: this.props.uid,
  }

  handleDayClick(day, { selected }) {
    const { selectedDays } = this.state;
    if (selected) {
      const selectedIndex = selectedDays.findIndex((selectedDay) =>
        DateUtils.isSameDay(selectedDay, day)
      );
      selectedDays.splice(selectedIndex, 1);
    } else {
      selectedDays.push(day);
    }
    this.setState({ selectedDays });
    // console.log(selectedDays)
  }
  onSubmit = () => {
    let {uid, selectedDays} = this.state
    let days = selectedDays.map(c=>getTimeString(c))
    this.props.firebase.user(uid).update({selectedDays: days})
    .then(()=>{
      alert('변경사항이 저장되었습니다.')
      this.props.onPropsChange('selectedDays',selectedDays)
      this.props.onPropsChange('show', false)
    })
  }
  
  render() {
    let {selectedDays} = this.state
    let {onPropsChange} = this.props
    // console.log(this.state)
    return (
      <div className='rolesChangeBg'>
        <div className='rolesChangeBx'>
          <button onClick={()=>onPropsChange('show',false)}>X</button>
          <div className='rolesChangeInfo'>
          <DayPicker selectedDays={selectedDays} onDayClick={this.handleDayClick} />
          </div>
          <div className="btnGroup">
            <button onClick={()=>this.onSubmit()}>저장</button>
            <button onClick={()=>onPropsChange('show',false)}>취소</button>
          </div>
        </div>
      </div>
    )
  }
}

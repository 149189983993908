import React, { Component } from "react";
import { withFirebase } from "../../Firebase";
import { withRouter } from "react-router-dom";
import { compose } from "recompose";
import "../../Order/Order.css";
import PaymentPage from "./PaymentPage";
import "suneditor/dist/css/suneditor.min.css";
import OrderSelfPage from "./OrderSelfPage";
import ServiceCenter from '../../ServiceCenter'
import VrRent from '../../Guide/VrRent'
import SelfInfo from "../../Guide/SelfInfo";
import XRBOOKIconOpacity0 from '../../images/XRBOOKIconOpacity0.png'
import eventImage from '../../images/event2.png'
import BootPay from "bootpay-js"
import $ from 'jquery'
import Manual from "../../Guide/Manual";


class OrderSales extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    authUser: this.props.authUser,
    totalQuantity: 0,
    bookData: this.props.salesList,
    mainUrl : XRBOOKIconOpacity0,
    // vrEventList: this.props.vrEventList,
    cart: [],
    itemList: [],

    showingItem:
      this.props.salesList[0] !== undefined ? this.props.salesList[0] : "",
    selectedPeriod: "14",
    selectedQuantity: 1,
    vrEventQuantity:0,
    periodAmount: 0,
    bookQuantity: 0,
    isPublished: false,
    vrRent: false,
    vrEvent:false,


    programType:
      this.props.salesList[0] !== undefined
        ? this.props.salesList[0].programType
        : "Online",
    processType: "self",

    cartId: 0,
    totalAmount: 0,
    isAddressOpen: false,
    isWholeSale: false,
    ordering: false,
    anyVrRent: false,
    anyVrEvent: false,

    show:
      this.props.location.state !== undefined
        ? this.props.location.state.tab
        : "info",
    pageYOffset: 0,
    rentalPolicy:false,
    vrDevice:false,
    eventModal:false
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const { bookData, showingItem, programType, processType } = this.state;
    if (bookData === undefined || showingItem === "") {
      this.getsalesList();
    }
    if (this.props.salesList[0] !== undefined ) {
      this.setState({
        mainUrl : this.props.salesList[0].mainUrl
      })
    }
    const itemList = new Array(bookData.length)
    for (var i = 0; i < bookData.length; i++) {
      let {title, mainUrl, pid, target, price} = bookData[i]
      itemList[i] = {
        item_name: title, // 판매하는 상품의 이름
        item_img: mainUrl, // 판매하는 상품의 이미지 경로
        unique: pid, // 판매하는 상품의 고유 KEY
        price: price, // 판매하는 상품의 가격
        cat1: programType, // 상품 카테고리 1
        cat2: target, // 상품 카테고리 2
        cat3: processType // 상품 카테고리 3
      }
    }
    BootPay.startTrace({
        items: itemList
      });
    // document.addEventListener('DOMContentLoaded', function() {
      // BootPay.startTrace({
      //   items: itemList
      // });
    // });
  }
  componentDidUpdate(prevProps, prevState) {
    if (prevState.cart !== this.state.cart) {
      this.sumTotalAmount();
    }
    if (prevState.cartId !== this.state.cartId) {
      const { bookData, cartId } = this.state;
      this.setState({
        showingItem: bookData[cartId],
        selectedPeriod: "7",
        selectedQuantity: 1,
      });
    }
    // if (prevState.vrEvent !== this.state.vrEvent || prevState.vrRent !== this.state.vrRent) {
    //   this.setState({selectedQuantity : 0})
    // }
  }

  getsalesList = () => {
    this.props.firebase.sales().orderBy("order").get()
      .then((result) => {
        var bookData = [];
        result.forEach((res) => {
          var listData = res.data();
          var pid = res.id;
          bookData.push({ pid, ...listData });
        });
        this.setState({
          bookData,
          showingItem: bookData[0],
        });
      });
  };

  onStateChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  onPropsChange = (name, value) => {
    // console.log(this.state.bookData)
    let selectedBookdata = this.state.bookData[value];
    // selectedBookdata.quantity = 0
    this.setState({
      [name]: value,
      selectedCart: selectedBookdata,
      periodAmount: 0,
      isPublished: false,
      bookQuantity: 0,
      vrRent:false,
      // vrEvent:false
    });
  };
  onStateToggle = (data) => {
    if (data === "ordering") {
      this.setState({
        ordering: !this.state.ordering,
      });
    } else if (data === "isPublished") {
      this.setState({
        isPublished: !this.state.isPublished,
        bookQuantity: this.state.isPublished ? 0 : 2,
      });
    } else if (data === "vrRent") {
      this.setState({
        vrRent: true,
        vrEvent: false,
      });
    } else if (data === "vrEvent") {
      this.setState({
        vrEvent: true,
        vrRent: false,
      })
    } else if (data === "vrCancel") {
      this.setState({
        vrRent: false,
        vrEvent: false,
      })
    }else {
      this.setState({
        isAddressOpen: !this.state.isAddressOpen,
      });
    }
  };

  //장바구니에 선택한 물품을 추가하는 method
  handleAddToCart = (showingPrice) => {
    // console.log(showingPrice)
    // e.preventDefault()
    const {
      cart,
      showingItem,
      selectedQuantity,
      selectedPeriod,
      periodAmount,
      bookQuantity,
      vrRent,
      vrEvent,
      // vrEventList
    } = this.state;
    // let {
    //   vrEventPrice, vrEventRatio
    // } = vrEventList
    if (vrRent) this.setState({ anyVrRent: true });
    if (vrEvent) this.setState({ anyVrEvent: true });
    let selectedProducts = Object.assign({}, showingItem);
    selectedProducts.quantity = selectedQuantity;
    if(selectedPeriod.indexOf('a')>-1){
      let formattedPeriod = selectedPeriod.replace('a','')
      selectedProducts.period = Number(formattedPeriod);
    } else {
      selectedProducts.period = Number(selectedPeriod);
    }
    
    selectedProducts.periodAmount = periodAmount;
    selectedProducts.published = bookQuantity;
    selectedProducts.vrRent = vrRent;
    selectedProducts.vrEvent = vrEvent;
    // totalPrice = cart에 담긴 동일 item 묶음 총 가격. 1개 개별가격은 나누기 quantity
    selectedProducts.totalPrice = showingPrice;
    selectedProducts.originPrice = showingPrice;
    let cartItem = this.state.cart.slice();
    // console.log(this.checkSelectedProduct(selectedProducts))
    if (this.checkSelectedProduct(selectedProducts)) {
      // console.log(cartItem)
      let included = cartItem.filter(
        (item) =>
          item.title === selectedProducts.title &&
          item.target === selectedProducts.target &&
          item.period === selectedProducts.period &&
          item.published === selectedProducts.published &&
          item.vrRent === selectedProducts.vrRent &&
          item.vrEvent === selectedProducts.vrEvent
      );
      // console.log(included)
      let index = cartItem.indexOf(included[0]);
      // console.log(index)
      cartItem[index].quantity += selectedQuantity;
      cartItem[index].originPrice += showingPrice;
      cartItem[index].periodAmount = periodAmount;
      cartItem[index].discountAmount = 0;
      cartItem[index].totalPrice = cartItem[index].originPrice;
      cartItem[index].couponInfo = ''
      cartItem[index].couponStatus = false
      // console.log('already')
      cartItem.filter((c) => {
        delete c.content;
        delete c.mainUrl;
        delete c.mobileUrl;
        delete c.pcUrl;
        return c;
      });
      this.setState({
        cart: cartItem,
        selectedQuantity: 1,
        // selectedPeriod:'7',
        // periodAmount: 0,

        bookQuantity: 0,
        isPublished: false,
        // vrRent: true,
        vrEvent: false
        // showingItem : bookData[cartId]
      });
    } else {
      // console.log(selectedProducts)
      cartItem.push(selectedProducts);
      cartItem.filter((c) => {
        delete c.content;
        delete c.mainUrl;
        delete c.mobileUrl;
        delete c.pcUrl;
        return c;
      });
      // console.log('yet')
      // console.log(cartItem)
      this.setState({
        cart: cartItem,
        selectedQuantity: 1,
        // selectedPeriod:'7',
        // periodAmount: 0,

        isPublished: false,
        bookQuantity: 0,

        // vrRent: true,
        vrEvent:false
      });
    }

    this.setState({ selectedQuantity: 1 });
  };

  //장바구니에 이미 제품이 있는지 확인하는 method
  checkSelectedProduct(selectedProduct) {
    let cart = this.state.cart;
    return cart.some((item) => {
      return (
        item.title === selectedProduct.title &&
        item.target === selectedProduct.target &&
        item.period === selectedProduct.period &&
        item.published === selectedProduct.published &&
        item.vrRent === selectedProduct.vrRent &&
        item.vrEvent === selectedProduct.vrEvent 
      );
    });
  }

  // 장바구니에 담긴 물품들의 가격 총합을 구하는 method
  sumTotalAmount() {
    let cart = this.state.cart;
    let totalAmount = 0;
    let totalQuantity = 0;
    // selectedPeriod, periodAmount, bookQuantity, vrRent
    for (let i = 0; i < cart.length; i++) {
      // totalAmount += Number(cart[i].quantity||0) * Number(cart[i].price+ cart[i].periodAmount)
      totalAmount += Number(cart[i].originPrice);
      totalQuantity += Number(cart[i].quantity || 0);
    }
    this.setState({
      totalAmount,
      totalQuantity,
    });
  }
  onChangePeriod = (e) => {
    let targetValue = e.target.value
    // console.log(targetValue)
    let index = targetValue.indexOf('a')
    let period = e.target.value.replace('a','');
    let periodAmount = targetValue === "21" ? 30000 : 0;
    this.setState({
      selectedPeriod: period,
      selectedPerioda: targetValue,
      // selectedQuantity: 1,
      periodAmount: periodAmount,

      // isPublished: false,
      // bookQuantity: 0,
      // vrEvent:false,
      // vrRent: index > -1 ? false : true,
    });
  };
  onSubtractCart = (data) => {
    let cart = this.state.cart;
    // console.log(cart)
    let index = cart.indexOf(data);
    cart.splice(index, 1);
    let rentList = []
    let eventList = []
    for(let i in cart){
      if(cart[i].vrRent){
        rentList.push(true)
      }
      if(cart[i].vrEvent){
        eventList.push(true)
        this.setState({vrEventQuantity : this.state.vrEventQuantity-cart[i].quantity })
      }
    }
      this.setState({
        cart,
        anyVrRent : rentList.length>0 ? true : false,
        anyVrEvent : eventList.length>0 ? true : false,
      });
      this.sumTotalAmount();
  };
  onCheckSubmit = (e) => {
    // let result = window.confirm(`현재 결제 PG사 적용 중입니다. \n관련 문의는 페이지 하단 문의사항을 이용해주세요.\n\n진행하시겠습니까?`)
    // if(result){
    this.onSubmit(e);
    // }
  };
  onSubmitN = (e)=>{
    e.preventDefault()
    alert(`${this.state.vrEventList.vrEventTitle} 잔여 대수에 맞게 신청 프로그램 수량을 조정해주세요.`)
  }
  onSubmit = (e) => {
    // e.preventDefault()
    let { cart, processType, programType } = this.state;
    let itemList = [];
    let id = 0;
    for (var i in cart) {
      let {
        title,
        target,
        published,
        period,
        totalPrice,
        originPrice,
        quantity,
        vrRent,
        vrEvent,
        pid,
        digitalBookID,
        createdAt,
        periodAmount,
        printPrice,
        rentPrice,
        eventPrice
      } = cart[i];
      let itemPrice = totalPrice / quantity;
      let digitalBookId = digitalBookID === undefined ? "" : digitalBookID;
      let site = "tlp";
      // let bookCode = digitalBookId!== '' ? : null
      let data = {
        item_name: `${title} ${target}대상${
          published > 0 ? "(출판)" : ""
        } - ${period}일`, //어린 왕자, 나를만나다_성인대상_90
        qty: quantity,
        unique: pid,
        //makeItemId({programType, processType, period, title, target, digitalBookID}),
        price: itemPrice,
        itemPrice: itemPrice,
        cat1: programType, //카테고리 상위 : Online
        cat2: target, //카테고리 중위 : 성인
        cat3: processType, //카테고리 하위 : self
        programType,
        processType,
        title,
        target,
        period,
        published,
        vrRent,
        vrEvent,
        digitalBookID: digitalBookId,
        createdAt,
        periodAmount,
        printPrice,
        rentPrice,
        eventPrice,
        site,
      };
      let j = 0;
      while (j < quantity) {
        itemList.push({ ...data, id });
        id++;
        j++;
      }
    }
    itemList.sort((a, b) => {
      if (a.target === b.target) {
        if (a.period === b.period) {
          return a.published - b.published;
        } else {
          return a.period - b.period;
        }
      } else {
        return a.target - b.target;
      }
    });
    this.setState({
      itemList: itemList,
      // ordering : !this.state.ordering
    });
    this.setState({ ordering: true });
  };
  changeTab = (data) => {
    this.setState({
      show: data,
    });
  };
  moveToInquiry = () => {
    let inquiryLocation = document.querySelector("#orderSelfELeaingInquiry")
      .offsetTop;
    window.scroll({ top: inquiryLocation, left: 0, behavior: "smooth" });
  };
  modalOpen = (name, status) => {
    if(status === true){
        $('html, body').css("overflow", "hidden");
        
    } else {
        $('html, body').css("overflow", "inherit")
    }
    this.setState({[name]: status,})
  }
  modalEventOpen = (status) => {
    if(status === true){
        $('html, body').css("overflow", "hidden");
        
    } else {
        $('html, body').css("overflow", "inherit")
    }
    this.setState({eventModal: status})
  }
  // getvrEventList = () => {
  //   this.props.firebase.sale('vrEvent').onSnapshot(
  //     {includeMetadataChanges:true},
  //     (result)=>{
  //       var vrEventList = {}
  //       var listData = result.data()
  //       var pid = result.id
  //       vrEventList={pid, ...listData}
  //       this.setState({vrEventList})
  //       // var source = result.metadata.hasPendingWrites ? "Local" : "Server";
  //       // console.log(source, " data: ", result.data());
  //     }
  //   )
  // }
  controlQuantity = (data) => {
    let { vrEvent, selectedQuantity, vrEventQuantity, vrEventList } = this.state
    // if(vrEvent){
      // if(vrEventQuantity<vrEventList.vrEventRestCapa){
        if(data === 'plus'){
          this.setState({
            selectedQuantity : selectedQuantity+1,
            vrEventQuantity : vrEventQuantity+1
          })
        } else if (data === 'minus') {
          this.setState({
            selectedQuantity : selectedQuantity===0 ? selectedQuantity :selectedQuantity-1,
            vrEventQuantity : vrEventQuantity===0 ? vrEventQuantity :vrEventQuantity-1
          })
        }
        return true
  }
  render() {
    
    const {
      totalAmount, totalQuantity, ordering, authUser, anyVrRent, anyVrEvent, showingItem, programType, processType,
      cart, bookData, selectedPeriod, selectedQuantity, periodAmount, itemList, bookQuantity, isPublished, vrEventQuantity,
      vrRent, vrEvent, show, rentalPolicy, vrDevice, eventModal, vrEventList, selectedPerioda} = this.state;
      // console.log(selectedPeriod)
    const paymentPageData = {
      originPrice : totalAmount,
      totalAmount,
      totalQuantity,
      cart,
      itemList,
      authUser,
      ordering,
      bookQuantity,
      isPublished,
      anyVrRent,
      anyVrEvent,
      programType,
      processType,
    };
    // console.log(paymentPageData)
    const isInValid = totalAmount === 0 || totalQuantity === 0;
    const isValid = isInValid ? "valid" : "inValid";
    // const showingItem = bookData[cartId]
    const title = showingItem !== undefined ? showingItem.title : "";
    const target = showingItem !== undefined ? showingItem.target : "";
    const mainUrl = showingItem !== undefined ? showingItem.mainUrl : "";
    const pcUrl = showingItem !== undefined ? showingItem.pcUrl : "";
    const mobileUrl = showingItem !== undefined ? showingItem.mobileUrl : "";
    const exampleBookId = showingItem !== undefined ? showingItem.exampleBookId : "";
    const productPrice = showingItem !== undefined ? showingItem.price : 0;
    const printPrice = showingItem !== undefined ? showingItem.printPrice : 0;
    const rentPrice = showingItem !== undefined ? showingItem.rentPrice : 0;
    const rentStatus = showingItem !== undefined ? showingItem.rentStatus : true;
    const eventPrice = showingItem !== undefined ? showingItem.eventPrice : 0;
    const eventStatus = showingItem !== undefined ? showingItem.eventStatus : true;
    // const vrEventPrice = Number(selectedPeriod)===30 && eventStatus ? vrEventList.vrEventPrice : 0
    // const vrEventRatio = Number(selectedPeriod)===30 && eventStatus ? vrEventList.vrEventRatio : 0

    // let rentPriceFormatted =
    //   rentPrice !== undefined
    //     ? rentPrice.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,")
    //     : "50,000";
    // let eventPriceFormatted =
    // eventPrice !== undefined
    //       ? eventPrice.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,")
    //       : "50,000";
    const content = showingItem !== undefined ? showingItem.content : "";
    const formattedprice2 = (productPrice + 0)
      .toString()
      .replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
    const formattedprice6 = (productPrice + 10000)
      .toString()
      .replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
    const formattedprice12 = (productPrice + 20000)
      .toString()
      .replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
    const formattedtotalAmount = totalAmount
      .toString()
      .replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
    const formattedprintPrice = (printPrice * 2)
      .toString()
      .replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
    const bookPrice = isPublished ? bookQuantity * printPrice : 0;
    const vrPrice = vrRent ? Number(selectedPeriod)>7 ? rentPrice*2 : rentPrice : 0;
    const vrEventPrice = vrEvent ? Number(eventPrice) : 0;
    // const formattedvrEventTotalPrice = eventPrice.toString().replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,')
    const showingPrice =
      selectedQuantity !== 0
        ? (
            selectedQuantity *
            (productPrice + periodAmount + vrPrice + bookPrice+ vrEventPrice)
          )
        : 0;
    const showingPriceString =
      selectedQuantity !== 0
        ? (
            selectedQuantity *
            (productPrice + periodAmount + vrPrice + bookPrice+ vrEventPrice)
          )
            .toString()
            .replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,")
        : 0;
      // console.log(showingPrice)  
    return (
      <React.Fragment>
        {!ordering ? (
          // <div className="orderPFrame productSaleFrame">
          <div>
            <div className="orderIntroFrame">
              <div className="rightFrame">
                <h5>{title}</h5>
                <div className="orderMainImgBx"> 
                  <img src={!mainUrl || mainUrl ==='' || mainUrl ===undefined ? XRBOOKIconOpacity0: mainUrl} alt="상품 이미지" />
                </div>
                <div className="thumbGroup">
                  {bookData &&
                    bookData.map((c, id) => {
                      return (
                        <div
                          className="thumbBtn"
                          key={id}
                          style={
                            c.target === target
                              ? { border: "1.5px solid black" }
                              : null
                          }
                          onClick={() =>
                            c.status && c.digitalBookID !== '' ? 
                              this.onPropsChange("cartId", id)
                              : alert(`프로그램 준비중입니다.`)
                          }
                        >
                          {c.target}대상
                        </div>
                      );
                    })}
                    {/* <div className="thumbBtn" >
                          {c.target}대상
                        </div> */}
                </div>
                {/* <div className="thumbGroup"> 
                  <div className="thumbBtn" style={{width:'100%'}}
                    // onClick={()=>this.modalEventOpen(true)}
                    > 
                    ★ 오큘러스 고 증정 이벤트 ★
                  </div> 
                </div>  */}
                {eventModal&& 
                <div className="eventPopBg"> 
                    <div className="eventPopFrame"> 
                        <div className="eventPopTitle"> 
                            {/* <h5>오큘러스 고 증정 이벤트</h5>  */}
                            <h5>카드보드 증정</h5>
                            <div onClick={()=>this.modalEventOpen(false)}>×</div> 
                        </div> 
                        <ul>
                            <li> 
                              <span></span> 
                              <span></span> 
                            </li>
                          <li>
                            <span></span>
                            <span>프로그램 결제 후 [내서재]에 코드 등록 시 입력한 주소로<br/>
                            {/* 디지털북이 그대로 인쇄되어 손글씨로 작성 가능한 '워크북'과<br/> */}
                            VR 기기 없이 스마트폰으로 VR 콘텐츠 체험이 가능한 '카드보드' 증정!</span>
                          </li>
                          {/* <li> 
                            <span>기간</span> 
                            <span>2021.04.01 ~ 재고 소진 시</span> 
                          </li>  */}
                          <li> 
                            <span>
                              {/* 대상자 */}
                              </span> 
                            <span>
                              {/* www.xrbook.net 사이트 회원 */}
                              </span> 
                          </li> 
                          <li> 
                            <span>
                              {/* 참여 방법 */}
                              </span> 
                            <ul> 
                              <li> 
                                1. 어린 왕자, 나를 만나다  [On-line Learning 신청]에서 프로그램 결제. 
                              </li> 
                              <li> 
                                2. 결제 시 프로그램 별 대상자 정보에 입력한 메일에서 나의 북코드 확인.   
                              </li> 
                              <li> 
                                3. 메일에 안내된 순서로 코드를 입력하면서 '카드보드'를 받을 주소 입력 
                                {/* '이정도느림' +  */}
                                
                              </li> 
                            </ul> 
                          </li>
                          <li>
                            <span>카드보드 사용 방법은 신청화면 내 [VR기기/AR App 사용 방법]을 참고하세요.</span>
                          </li>
                          {/* <li>"프로그램 5건 결제하고 오큘러스 고 5개 받자!"</li> 
                          <li> 
                            <span>기간</span> 
                            <span>2020.10.00 ~ 2020.11.00</span> 
                          </li> 
                          <li> 
                            <span>대상자</span> 
                            <span>xrbook.net 사이트 회원</span> 
                          </li> 
                          <li> 
                            <span>참여 방법</span> 
                            <ul> 
                              <li> 
                                1. 어린 왕자, 나를 만나다  [On-line Learning 신청]에서  
                                수강하려는 프로그램 5개를 [담아두기]. 
                              </li> 
                              <li> 
                                2. 기간, 출판 인쇄, 신청 인원을 자유롭게 선택.  
                                (단, 오큘러스 고 옵션은 "대여/구매 안함"으로 체크) 
                              </li> 
                              <li> 
                                3. 대상별 프로그램 구분 없이 신청 프로그램의  
                                총 수량이 5건일 경우 결제 시 오큘러스 고 증정! 
                                </li> 
                            </ul> 
                          </li> 
                          <li> 
                            <span> 
                              ※ 유의 사항 : 본 이벤트는 할인 쿠폰 적용이 불가한 이벤트입니다. 결제 후 수강코드를 등록하여 배송지 정보를 입력해야 기기 배송이 시작됩니다.  
                              연락처 및 주소와 같은 인적사항 오류로 인한 이벤트 상품 배송 착오가 없도록 코드 제출 시 한번 더 확인바랍니다. 
                            </span> 
                          </li>  */}
                        </ul> 
                           
                          {/* **********이벤트 내용 이미지로 대체 시 →***************<img src={eventImage} alt="이벤트 설명 이미지"/>  */}
                    </div> 
                </div> 
                }
              </div>
              <div className="leftFrame">
                <h5>
                  {target}대상 <span>On-line Learning Program</span>
                </h5>
                <ul className="textGroup">
                  <li>
                    <span className="cate">학습 대상</span>
                    <span className="cont">{target} &nbsp;</span>
                    <span><a href={`https://www.xrbook.net/digitalBook/example/${exampleBookId}`} target='_blank'> ( 프로그램 미리보기 )</a></span>
                  </li>
                  {/* <li>
                    <span className="cate"></span>
                    <a href={`http://localhost:3000/digitalBook/example/${exampleBookId}`} target='_blank'>프로그램 맛보기</a>
                  </li> */}
                  <li>
                    <span className="cate">학습 기간</span>
                    <span className="cont">
                      <select
                        name="period"
                        value={selectedPerioda}
                        onChange={this.onChangePeriod}
                      >
                        {/* <option value="7"> 7일 </option> */}
                        <option value="14"> 14일</option>
                        {/* <option value="1a"> 14일 (기기 체험 불포함)</option> */}
                        {/* <option value='80'>80일 ({formattedprice12}원)</option> */}
                      </select>
                    </span>
                  </li>
                  <li className="checkInputBx">
                    <span className="cate">출판 인쇄 (양장본)</span>
                    <ul className="bookPublishing">
                      <li className="productSelect">
                        
                        
                        <span>
                          <input
                            type="radio"
                            name="isPublished"
                            checked={!isPublished}
                            onChange={() => this.onStateToggle("isPublished")}
                          />
                          <span
                            onClick={() => {
                              this.setState({
                                isPublished: false,
                                bookQuantity: 0,
                              });
                            }}
                          >
                            &nbsp;인쇄 안 함
                          </span>
                        </span>
                        <span>
                          <input
                            type="radio"
                            name="isPublished"
                            checked={isPublished}
                            onChange={() => this.onStateToggle("isPublished")}
                          />
                          <span
                            onClick={() => {
                              this.setState({
                                isPublished: true, 
                                bookQuantity: 2,
                              });
                            }}
                          >
                            &nbsp;인쇄 ({formattedprintPrice}원/2권)
                          </span>
                        </span>
                      </li>
                      {isPublished && (
                        <li className="productQuantity">
                          <input
                            type="number"
                            min="2"
                            value={bookQuantity}
                            onChange={(e) =>
                              this.setState({ bookQuantity: Number(e.target.value)})
                            }
                          />
                          &nbsp; 부
                          <span
                            className="plusMinusBtn"
                            onClick={() =>
                              this.setState({ bookQuantity: bookQuantity + 1 })
                            }
                          >
                            {" "}
                            +{" "}
                          </span>
                          <span
                            className="plusMinusBtn"
                            onClick={() =>
                              bookQuantity > 2
                                ? this.setState({
                                    bookQuantity: bookQuantity - 1,
                                  })
                                : this.setState({ bookQuantity: 2 })
                            }
                          >
                            {" "}
                            -{" "}
                          </span>
                        </li>
                      )}
                    </ul>
                  </li>
                  {/* <li className="checkInputBx guideText">
                    <span className="cont">
                      {" "}
                      *프로그램 종료 일에 따라 서비스제공일 이후 인쇄 및 배송이 진행될 수 있습니다.
                    </span>
                  </li> */}
                   
                  {rentStatus  ?
                    <li className="checkInputBx rentDevice">
                    <span className="cate">오큘러스 고 체험</span>
                    <span className="cont">
                    
                      <span>
                        <input
                          type="radio"
                          name="vr"
                          checked={!vrRent && !vrEvent}
                          onChange={() => this.onStateToggle("vrCancel")}
                        />
                        <span
                          onClick={() => {
                            this.onStateToggle("vrCancel")
                          }}
                        >
                          &nbsp;체험 불포함
                        </span>
                      </span>
                      {rentStatus &&
                      <span>
                        <input
                          type="radio"
                          name="vrRent"
                          checked={vrRent}
                          onChange={() => this.onStateToggle("vrRent")}
                        />
                        <span
                          onClick={() => {
                            this.onStateToggle("vrRent")
                          }}
                        >
                          &nbsp;기기 체험
                        </span>
                      </span>}
                       {/* {eventStatus && 
                      <span>
                        <input
                          type="radio"
                          name="vrEvent"
                          checked={vrEvent}
                          onChange={() => this.onStateToggle("vrEvent")}
                        />
                        <span
                          onClick={() => {
                            this.onStateToggle("vrEvent")
                          }}
                        >
                          &nbsp;기기 구매
                          ({eventPriceFormatted}원)
                        </span>
                      </span>} */}
                    </span>
                  </li> : null} 
                  {rentalPolicy&&
                <div className="rentalPolicyBg" >
                    <div className="rentalPolicyFrame">
                        <div className="rentalPolicyPopTitle">
                            <h5>기기 체험 관련 규정</h5>
                            <div onClick={()=>this.modalOpen('rentalPolicy', false)}>×</div>
                        </div>
                        <VrRent headShow={false}/>
                        {/* <Manual /> */}
                        <div className="rentalPolicyPopTitle">
                            <h5> </h5>
                            <div onClick={()=>this.modalOpen('rentalPolicy', false)}>×</div>
                        </div>
                    </div>
                </div>
                }
                {vrDevice&&
                <div className="rentalPolicyBg" >
                    <div className="rentalPolicyFrame">
                        <div className="rentalPolicyPopTitle">
                        <h5>VR 기기 / AR App 사용 방법</h5>
                            <div onClick={()=>this.modalOpen('vrDevice', false)}>×</div>
                        </div>
                        {/* <VrRent headShow={false}/> */}
                        <Manual />
                        <div className="rentalPolicyPopTitle">
                        <h5> </h5>
                            <div onClick={()=>this.modalOpen('vrDevice', false)}>×</div>
                        </div>
                    </div>
                </div>
                }
                {/* {rentStatus ||  eventStatus ? ( */}
                  <li className="checkInputBx guideText" style={{alignItems:"flex-start", height:"auto"}}> 
                  <span className="cate">참고</span> 
                     
                      <span className="cont" style={{marginLeft:"0"}}> 
                         
                        {" "} 
                        VR 3D 영상, AR/VR 어린왕자 컨텐츠,
                        <br/>
                        카드보드 기본 제공
                        {/* { rentStatus && eventStatus ? 
                        <React.Fragment> 
                        <br /> 
                        * 프로그램 하나 당 구매/대여 택 1 가능합니다. 
                        </React.Fragment> 
                         : null}  */}
                        {rentStatus && <span className="contLink" className="rentPolicyPopBtn" onClick={()=>this.modalOpen('rentalPolicy', true)}>기기체험 관련 규정</span> }
                        <span className="contLink" className="rentPolicyPopBtn" onClick={()=>this.modalOpen('vrDevice', true)}>VR 기기 / AR App 사용 방법</span> 
                      </span> 
                     
                  </li>
                  {/* ):null}  */}
                  <li>
                    <span className="cate">신청 인원</span>
                    <span className="cont">
                      <span className="productQuantity">
                        <input
                          type="number"
                          min="0"
                          value={selectedQuantity}
                          onChange={(e) =>
                            this.setState({ selectedQuantity: Number(e.target.value) })
                          }
                        />
                        &nbsp; 명
                      </span>
                      <span
                        className="plusMinusBtn"
                        onClick={() =>
                          this.controlQuantity('plus')
                        }
                      >
                        {" "}
                        +{" "}
                      </span>
                      <span
                        className="plusMinusBtn"
                        onClick={() =>
                          this.controlQuantity('minus')
                        }
                      >
                        {" "}
                        -{" "}
                      </span>
                    </span>
                  </li>
                  <li className="programPrice">
                    <span className="cate">가격</span>
                    <span className="cont">{showingPriceString}</span>
                    <button
                      disabled={!selectedQuantity || selectedQuantity === 0}
                      onClick={() => this.handleAddToCart(showingPrice)}
                    >
                      담아 두기
                    </button>
                  </li>
                </ul>
              </div>
            </div>
            <form className="selectedBox">
              <p className="bxTitle">신청 프로그램</p>
              <span className="horiVar"></span>
              <ul className="selProgramBx">
                {cart &&
                  cart.map((c, id) => {
                    const itemPrice = c.originPrice
                      .toString()
                      .replace(/(\d)(?=(?:\d{3})+(?!\d))/g, "$1,");
                    const isPrintOut =
                      c.published > 0 ? `인쇄(${c.published}부)` : "인쇄안함";
                    const isRent = c.vrRent ? `기기체험` : `체험 불포함`
                    // : c.vrEvent ? '기기구매' : `대여/구매안함`;
                    return (
                      <li key={id}>
                        <span>
                          {c.target}대상 - {c.period}일 - {isPrintOut} -{" "}
                          {/* {isRent} -  */}
                          {c.quantity}건 - {itemPrice}
                        </span>
                        <span onClick={() => this.onSubtractCart(c)}> × </span>
                      </li>
                    );
                  })}
              </ul>
              <span className="horiVar"></span>
              <ul className="orderConfirm">
                <li>
                  <span className="cate">상품 금액</span>{" "}
                  <span className="cont">{formattedtotalAmount}</span>
                </li>
                <li>
                  <span className="cate">총 수량</span>{" "}
                  <span className="cont">{totalQuantity}</span>
                </li>
              </ul>
              {/* <p>30명 이상 신청하실 경우 결제 전 문의하기를 이용해주세요.</p> */}
              <div className="orderBtnGroup">
                <div onClick={() => this.moveToInquiry()}>
                  {/* 30명 이상 신청 */}
                  신청 문의
                </div>
                <button
                  className={`${isValid}`}
                  disabled={isInValid}
                  onClick={(e) => 
                   this.onSubmit(e)}>
                  결제하기
                </button>
              </div>
            </form>

            <div>
              <ul className="orderTabGroup">
                <li
                  className={`tabBtn01 ${show === "info" ? "activeBtn" : null}`}
                  onClick={() => this.changeTab("info")}
                >
                  프로그램 상세 정보
                </li>
                <li
                  className={`tabBtn02 ${
                    show === "SelfInfo" ? "activeBtn" : null
                  }`}
                  onClick={() => this.changeTab("SelfInfo")}
                >
                  수강 가이드
                </li>
                <li
                  className={`tabBtn02 ${
                    show === "shipment" ? "activeBtn" : null
                  }`}
                  onClick={() => this.changeTab("shipment")}
                >
                  배송/취소/환불 규정
                </li>
              </ul>
              <div className="orderTabCont">
                {show === "info" ? (
                  <div className="orderTab01">
                    <img
                      className="contPcImage"
                      src={pcUrl}
                      alt="상품 이미지"
                    />
                    <img
                      className="contMobileImage"
                      src={mobileUrl}
                      alt="상품 이미지"
                    />
                    {/* <SunEditor lang="ko" 
                        disable={true}
                        height='100%'
                        showToolbar={false}
                        name="my-editor" 
                        setContents={content || ''}
                        /> */}
                  </div>
                ) : show === "shipment" ? (
                  <ServiceCenter isOrder={true}/>
                ) : show === "SelfInfo" ?
                <SelfInfo headShow={false}/>
                : null}
              </div>
            </div>
            <div id="orderSelfELeaingInquiry">
              <OrderSelfPage
                selectedProgram={showingItem}
                salesList={bookData}
                isPublished={isPublished}
                vrRent={vrRent}
                vrEvent={vrEvent}
                bookQuantity={bookQuantity}
                authUser={authUser}
                selectedPeriod={selectedPeriod}
                
              />
            </div>
          </div>
        ) : (
          <PaymentPage
            data={paymentPageData}
            isOpen={this.onStateToggle}
            onSubmit={this.onSubmit}
            processType={processType} programType={programType}
          />
        )}
      </React.Fragment>
    );
  }
}
export default compose(withRouter, withFirebase)(OrderSales);
